import React from 'react';
import { Link } from 'react-scroll';

interface IScrollLink {
  to?: string
  text: string
}

ScrollLink.defaultProps = {
  to: '',
};

function ScrollLink(props: IScrollLink) {
  const { to, text } = props;
  return (
    <Link
      to={to || text}
      activeClass="tab_active"
      offset={-110}
      className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
      spy
    >
      {text}
    </Link>
  );
}

export default ScrollLink;
