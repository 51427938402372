const Constant = {
  noSubmodelText: 'No Submodel',
  defaultErrorMessage: 'Something went wrong, please try again!',
  loginErrorMessage: 'Please enter a valid credentials',
  emailRequiredValidationError: 'Please enter your email address',
  emailValidationError: 'Please enter a valid email address',
  passwordRequiredValidationError: 'Please enter your password',
  invalidPassword: 'Please provide valid password',

  idRequiredValidationError: 'Please provide a valid ID of maximum length 15',
  formValidationError: 'Please provide valid parameters',

  serviceNameRequired: 'Product / service name is required',
  newPasswordRequiredError: 'Enter your new password',
  currentPasswordRequiredError: 'Enter your current password',
  confirmPasswordRequiredError: 'Please confirm your password',
  passwordMatchError: 'Password do not match!',
  passwordValidationError: 'Password must be minimum of 6 charecter',
  oldNewPasswordSameError: 'Old password and new password should not be same',
  parkedDateError: 'Please choose the parked date with in the start date and due date',
  myfatoorahErrorEmail: 'Please enter valid email. MyFatoorah does not accept email with special characters except \'.@\'',
  priceZeroErr: 'Prize must be greater than zero',
  hardDeleteClientMsg: 'You are about to wipe the account permanently from the database. Are you sure you want to continue?',
  hardDeleteHeading: 'Delete Account Permanently',
  hardDeletePlanHeading: 'Delete Plan Permanently',
  vehicleImageRequiredError: 'Vehicle image is required.',
  closedDateError: 'Closed date should be grater than due date.',
  vehicleRequired: 'Vehicle is required.',
  passwordMinLength: 6,
  maxNameLength: 50,
  MaxColorLength: 20,
  fieldRequiredValidation: 'This field is required.',
  renewDaysInMilliseconds: 86400000,
  maximumFileSize: 3, // in mb
  daysInMilliSeconds: 86400000,

  organizationName: 'Travancore Analytics',

  employeePerPage: 15,
  clientPerPage: 15,
  facilityPerPage: 15,
  defaultPageNumber: 1,
  modelPerPage: 10,
  contractPerPage: 15,
  productPerPage: 15,
  orderPerPage: 15,
  approvalsPerPage: 8,
  clientDetailsPage: 8,
  memberTablePerPage: 15,
  planPerPage: 6,
  serviceRequest: 15,
  quickService: 15,
  activityPerPage: 10,
  notificationPerPage: 10,
  defaultSliderValue: 0,
  defaultMileageValue: 20,
  defaultTirePressureValue: {
    wheel_1: 30,
    wheel_2: 30,
    wheel_3: 30,
    wheel_4: 30,
  },
  defaultAttachment: false,
  userActivityPerPage: 8,
  facilityLog: 8,
  categoryPerPage: 15,
  variantsPerPage: 10,

  debaounceTime: 500,

  planTypeRequired: 'Plan type required.',
  nameRequired: 'First name is required.',
  facilityNameRequired: 'Name is required.',
  lastNameRequired: 'Last name is required.',
  genderRequired: 'Gender is required.',
  dateRequired: 'Date is required.',
  timeRequired: 'Time is required.',
  monthRequired: 'Month is required.',
  yearRequired: 'Year is required.',
  emailRequired: 'Email is required.',
  phoneRequired: 'Phone is required.',
  countryRequired: 'Country is required.',
  stateRequired: 'State is required.',
  cityRequired: 'City is required.',
  zipcodeRequired: 'Post code is required.',
  profileRequired: 'Profile is required.',
  splOrNumberOnlyError: 'Special characters and numericals are not allowed.',
  specialCharectorError: 'Special characters not allowed.',
  specialCharaOrNumberOnlyError: 'Only special characters or numericals are not allowed.',
  invalidData: 'Please provide a valid data.',
  invalidDate: 'Not a valid date.',
  invalidMonth: 'Not a valid month.',
  invalidYear: 'Not a valid year.',
  invalidEmail: 'Not a valid email.',
  invalidPhone: 'Not a valid phone number.',
  zipMinimumError: 'Please provide post-code minimum of 4 numbers.',
  vehicleCategoryRequired: 'Vehicle category required.',
  vehicleModelRequired: 'Model is required.',
  vehicleMakeRequired: 'Make is required.',
  vehicleSubModelRequired: 'Sub Model is required.',
  vehicleYearRequired: 'Year is required.',

  vehicleCompanyRequired: 'Make required.',
  manufactureYearRequired: 'Year required.',
  vehicleColorRequired: 'Vehicle color required.',
  vehicleColorNameRequired: 'Vehicle color name required.',
  fileExistError: 'File exists with same name.',
  addressLine1Required: 'Address line 1 is required.',
  addressLine2Required: 'Address line 2 is required.',
  invalidAddressLine1: 'Invalid address line 1.',
  invalidAddressLine2: 'Invalid address line 2.',
  contractPeriodRequired: 'Plan period required.',
  startDateRequired: 'Start date required.',
  closedDateRequired: 'Closed date required.',
  expiryDateRequired: 'Expiry date required.',
  vehicleNoRequired: 'Vehicle number required.',
  chassisNoRequired: 'Chassis number required.',
  facilityRequired: 'Facility required.',
  parkedDateRequired: 'Parked date required.',
  invalidVehicleNumber: 'Invalid vehicle number.',
  invalidChassisNumber: 'Invalid chassis number.',
  invalidTirePressure: 'Please provide valid tire pressure',
  invalidMileage: 'Please provide valid mileage',
  nameFieldRequired: 'Name is required.',
  codeRequired: 'Code is required.',
  priceRequired: 'Price is required.',
  categoryRequired: 'Please choose a category.',
  descriptionRequired: 'Description is required.',
  invalidDescription: 'Please provide a valid description.',
  invalidName: 'Please provide a valid name.',
  invalidSize: 'Please provide a valid size.',
  sizeRequired: 'Size is required.',
  invalidQuantity: 'Please provide a valid quantity.',
  quantityRequired: 'Quantity is required.',
  invalidColor: 'Please provide a valid color.',
  colorRequired: 'Color is required.',
  colorNameRequired: 'Color name is required.',
  productRequired: 'Please choose a product.',
  variantRequired: 'Please choose a variant.',
  customerRequired: 'Please choose a customer.',
  invalidFuelQuantity: 'Please provide a valid fuel quantity.',
  invalidPrice: 'Please provide a valid unit price.',
  invalidAmount: 'Please provide a valid price.',
  planRequired: 'Plan is required.',
  deliveryPriceRequired: 'Delivery Charge is required.',

  modelDeleteMsg: 'Delete [category] data first to delete the model',
  verticalImageRequired: 'Vertical image is required.',
  horizontalImageRequired: 'Horizontal image is required.',

  userAccess: {
    SUPER_ADMIN: 1,
    EMPLOYEE: 2,
  },

  deliveryCharges: {
    STANDARD: 'STANDARD',
    EXPRESS: 'EXPRESS',
  },

  userTypeText: new Map([
    [1, 'Super admin'],
    [2, 'Employee'],
  ]),

  employeeRequest: {
    CLIENT_DELETE: 'CLIENT_DELETE',
    MODEL_DELETE: 'MODEL_DELETE',
    FACILITY_DELETE: 'FACILITY_DELETE',
  },

  unAuthorizedError: 'You are not authorized to access this website.',

  token: 'token',
  refreshToken: 'refreshToken',

  passwordLength: 20,
  invalidLocation: 'Please provide a valid location.',
  addressRequired: 'Location is required.',
  capacityRequired: 'Facility capacity is required.',
  onlyNumbers: 'Only numbers are allowed.',
  onlyIntegerNumbers: 'Only numbers without decimals are allowed.',
  invalidQRCode: 'Invalid QR code.',
  contractTypes: {
    ACTIVE: 'active',
    EXPIRED: 'expired',
    EXPIRING: 'expiring',
    PAYMENT_PENDING: 'payment_pending',
  },

  customerContract: {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    ACTIVE: 'ACTIVE',
    HOLD: 'HOLD',
    CLOSED: 'CLOSED',
  },
  requestActions: {
    REJECT: 'REJECT',
    APPROVE: 'APPROVE',
    CANCELED: 'CANCELED',
  },
  requestStatus: {
    OPEN: 'OPEN',
    CANCELED: 'CANCELED',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
  },
  vehicleDocTypes: {
    REGISTRATION: 'REGISTRATION',
    INSURANCE: 'INSURANCE',
  },

  VAULT_CONTRACT: 'VAULT_CONTRACT',
  SERVICE_CONTRACT: 'SERVICE_CONTRACT',

  MEMBER_REQUEST_STATUS: {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  },

  modelPublishStatus: {
    PUBLISHED: 'PUBLISHED',
    NEW: 'NEW',
  },

  plan_type: {
    VAULT: 'VAULT',
    RESIDENTIAL: 'RESIDENTIAL',
  },

  duration: {
    30: '1 month',
    60: '2 months',
    90: '3 months',
    180: '6 months',
    360: '12 months',
  },

  psi: 'psi',
  bar: 'bar',
  psiToBar: 1 / 14.5038,
  barToPsi: 14.5038,

  KM: 'KM',
  MI: 'MI',
  mileageMilesMaximumValue: 621372.1152770701,
  mileageKiloMeterMaximumValue: 999999,

  MIToKM: 1.60934,
  KMToMI: 1 / 1.60934,

  serviceRequestStatus: {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    DECLINED: 'DECLINED',
  },

  serviceVisitReqStatus: {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
  },

  serviceRequestActions: {
    START: 'START',
    COMPLETE: 'COMPLETE',
    CANCEL: 'CANCEL',
    PAYMENT_DONE: 'PAYMENT_DONE',
    DECLINE: 'DECLINE',
  },

  SERVICES: new Map([
    ['WASH', 'Wash'], ['COVER', 'Cover'], ['MILEAGE_CHECK', 'Mileage Check'], ['BATTERY_TENDER', 'Battery Tender'],
    ['FLAT_STOPPERS', 'Flat Stoppers'], ['TIRE_PRESSURE_CHECK', 'Tire Pressure Check'], ['FUEL_CHECK', 'Fuel Check'],
    ['ENGINE', 'Engine'], ['FLUID', 'Fluid'], ['WINDSHIELD', 'Windshield'], ['EXTRA_WASH_STANDARD', 'Extra Wash Standard'],
    ['EXTRA_WASH_PREMIUM', 'Extra Wash Premium'], ['FUEL_FILL', 'Fuel Fill'], ['STANDARD_INTERIOR', 'Standard Interior'],
    ['STANDARD_EXTERIOR', 'Standard Exterior'], ['STANDARD_WASH', 'Standard Wash'], ['DETAIL_WASH', 'Detail Wash'],
  ]), // remove extra wash and interior wash and keep detail and standard wash

  SERVICE_VISIT_ACTIVITY: 'Service Visit',

  FUEL_FILL_TYPE: new Map([
    ['ULTRA', 'Ultra'], ['SUPER', 'Super'], ['PREMIUM', 'Premium'],
  ]),

  vehicleActivities: new Map([
    ['VEHICLE_SWAP', 'Swap'], ['VEHICLE_PARK', 'Park'], ['VEHICLE_UNPARK', 'Unpark'], ['VEHICLE_ADD', 'Add Vehicle'],
    ['VEHICLE_REMOVE', 'Remove Vehicle'],
  ]),

  ACTIVITIES: new Map([
    ['SERVICE_REQUEST', 'Requested'],
    ['SERVICE_CANCEL', 'Cancelled'],
    ['SERVICE_DECLINE', 'Declined'],
    ['SERVICE_PAYMENT', 'Paid'],
    ['SERVICE_START', 'Started'],
    ['SERVICE_COMPLETE', 'Completed'],
    ['SERVICE_VISIT', 'Created'],
    ['SERVICE_VISIT_UPDATE', 'Rescheduled'],
    ['SERVICE_VISIT_START', 'Started'],
    ['SERVICE_VISIT_CANCEL', 'Cancelled'],
    ['SERVICE_VISIT_COMPLETE', 'Completed'],
    ['SERVICE_VISIT_PAYMENT', 'Paid'],
  ]),

  ServiceReqStatusText: new Map([
    ['OPEN', 'Not initiated'],
    ['IN_PROGRESS', 'In progress'],
    ['COMPLETED', 'Closed'],
    ['PAYMENT_PENDING', 'Payment pending'],
  ]),

  quickServiceStatusText: new Map([
    ['COMPLETED', 'Payment done'],
    ['PAYMENT_PENDING', 'Payment pending'],
  ]),

  serviceVisitStatus: new Map([
    ['OPEN', 'OPEN'],
    ['IN_PROGRESS', 'IN PROGRESS'],
    ['COMPLETED', 'COMPLETED'],
    ['PAYMENT_PENDING', 'PAYMENT PENDING'],
  ]),

  serviceVisitCardStatus: new Map([
    ['OPEN', 'Open'],
    ['IN_PROGRESS', 'In progress'],
    ['COMPLETED', 'Completed'],
    ['PAYMENT_PENDING', 'Payment pending'],
  ]),

  ServiceVisitStatusClass: new Map([
    ['OPEN', 'label_warning'],
    ['IN_PROGRESS', 'label_success'],
    ['COMPLETED', 'label_green'],
    ['PAYMENT_PENDING', 'label_warning'],
  ]),

  ServiceReqStatusClass: new Map([
    ['OPEN', 'label_warning'],
    ['IN_PROGRESS', 'label_success'],
    ['COMPLETED', 'label_inactive'],
    ['PAYMENT_PENDING', 'label_warning'],
  ]),
  quickServiceStatusClass: new Map([
    ['COMPLETED', 'label_success'],
    ['PAYMENT_PENDING', 'label_danger'],
  ]),
  statusImage: new Map([
    ['ENGINE', '#engine'],
    ['FUEL_CHECK', '#fuel'],
    ['FUEL_FILL', '#fuel'],
    ['COVER', '#cover'],
    ['BATTERY_TENDER', '#battery'],
    ['FLAT_STOPPERS', '#flatstopper'],
    ['WASH', '#wash'],
    // remove extra wash and interior wash
    ['EXTRA_WASH_STANDARD', '#extrawash'],
    ['EXTRA_WASH_PREMIUM', '#extrawash'],
    ['STANDARD_INTERIOR', '#extrawash'],
    ['STANDARD_EXTERIOR', '#extrawash'],
    // keep detail and standard wash
    ['DETAIL_WASH', '#extrawash'],
    ['STANDARD_WASH', '#extrawash'],
    ['MILEAGE_CHECK', '#mileage'],
    ['WINDSHIELD', '#windshield'],
    ['TIRE_PRESSURE_CHECK', '#tire'],
    ['FLUID', '#fluid'],
  ]),
  statusImageClass: new Map([
    ['ENGINE', 'icon_bg'],
    ['FUEL_CHECK', 'icon_bg'],
    ['FUEL_FILL', 'icon_bg'],
    ['COVER', 'primary_bg white'],
    ['BATTERY_TENDER', 'primary_bg white'],
    ['FLAT_STOPPERS', 'primary_bg white'],
    ['WASH', 'icon_bg'],
    // remove extra wash and interior wash
    ['EXTRA_WASH_STANDARD', 'icon_bg'],
    ['EXTRA_WASH_PREMIUM', 'icon_bg'],
    ['STANDARD_INTERIOR', 'icon_bg'],
    ['STANDARD_EXTERIOR', 'icon_bg'],
    // keep detail and standard wash
    ['DETAIL_WASH', 'icon_bg'],
    ['STANDARD_WASH', 'icon_bg'],
    ['MILEAGE_CHECK', 'icon_bg'],
    ['WINDSHIELD', 'icon_bg'],
    ['TIRE_PRESSURE_CHECK', 'icon_bg'],
    ['FLUID', 'icon_bg'],
  ]),

  contractStatusColor: new Map([
    ['REQUESTED', 'label_warning'],
    ['ACTIVE', 'label_success'],
    ['APPROVED', 'label_success'],
    ['EXPIRED', 'label_danger'],
    ['REJECTED', 'label_danger'],
    ['CLOSED', 'label_inactive'],
    ['CANCELLED', 'label_inactive'],
  ]),

  facilityLogAction: new Map([
    ['IN', 'IN'],
    ['OUT', 'OUT'],
  ]),

  facilityLogActionClassName: new Map([
    ['IN', 'label_success'],
    ['OUT', 'label_warning'],
  ]),

  productClassName: new Map([
    [true, 'label_success'],
    [false, 'label_warning'],
  ]),

  productStatusText: new Map([
    [true, 'Published'],
    [false, 'Unpublished'],
  ]),

  orderStatusText: new Map([
    [true, 'Paid'],
    [false, 'Pending'],
  ]),

  orderClassName: new Map([
    [true, 'label_success'],
    [false, 'label_warning'],
  ]),

  order_statusText: new Map([
    ['DELIVERED', 'Fulfilled'],
    ['CREATED', 'Unfulfilled'],
    ['CANCELLED', 'New'],
    ['PREPARE_ORDER', 'Preparing'],
    ['IN_TRANSIT', 'In-transit'],
  ]),

  order_statusClassName: new Map([
    ['DELIVERED', 'label_success'],
    ['CREATED', 'label_inactive'],
    ['CANCELLED', 'label_warning'],
    ['PREPARE_ORDER', 'label_warning'],
    ['IN_TRANSIT', 'label_warning'],
  ]),

  request_statusText: new Map([
    ['CLOSED', 'Completed'],
    ['IN_PROGRESS', 'In Progress'],
    ['OPEN', 'New'],
  ]),
  request_statusClassName: new Map([
    ['CLOSED', 'label_success'],
    ['IN_PROGRESS', 'label_warning'],
    ['OPEN', 'label_inactive'],
  ]),

  wheels: new Map([
    ['car', 4],
    ['others', 4],
    ['motorbike', 2],
    ['marine', 0],
  ]),

  userStatusClassName: new Map([
    ['NOT_INVITED', 'label_inactive'],
    ['PENDING_SIGNUP', 'label_warning'],
    ['DELETED', 'label_danger'],
    ['DISABLED', 'label_warning'],
  ]),

  userStatusText: new Map([
    ['NOT_INVITED', 'Not inivited'],
    ['PENDING_SIGNUP', 'Pending sign up'],
    ['DELETED', 'Deleted'],
    ['DISABLED', 'Disabled'],
  ]),

  activity_type: {
    PLAN_ACTIVITY: 'PLAN_ACTIVITY',
    SERVICE_ACTIVITY: 'SERVICE_ACTIVITY',
    SERVICE_VISIT_ACTIVITY: 'SERVICE_VISIT_ACTIVITY',
    PARKING_UNPARKING: 'PARKING_UNPARKING',
  },

  activities: {
    PLAN_REQUEST: 'PLAN_REQUEST',
    PLAN_PAYMENT: 'PLAN_PAYMENT',
    PLAN_CANCEL: 'PLAN_CANCEL',
    PLAN_CLOSE: 'PLAN_CLOSE',
    PLAN_APPROVE: 'PLAN_APPROVE',
    PLAN_HOLD: 'PLAN_HOLD',
    PLAN_REJECT: 'PLAN_REJECT',
    SERVICE_REQUEST: 'SERVICE_REQUEST',
    SERVICE_CANCEL: 'SERVICE_CANCEL',
    SERVICE_PAYMENT: 'SERVICE_PAYMENT',
    SERVICE_START: 'SERVICE_START',
    SERVICE_COMPLETE: 'SERVICE_COMPLETE',
    VEHICLE_STATUS_UPDATE: 'VEHICLE_STATUS_UPDATE',
    VEHICLE_PARK: 'VEHICLE_PARK',
    VEHICLE_UNPARK: 'VEHICLE_UNPARK',
    VEHICLE_SWAP: 'VEHICLE_SWAP',
    SERVICE_VISIT: 'SERVICE_VISIT',
  },

  services: {
    FACILITY_SLOT: 'FACILITY_SLOT',
    WASH: 'WASH',
    COVER: 'COVER',
    MILEAGE_CHECK: 'MILEAGE_CHECK',
    BATTERY_TENDER: 'BATTERY_TENDER',
    FLAT_STOPPERS: 'FLAT_STOPPERS',
    TIRE_PRESSURE_CHECK: 'TIRE_PRESSURE_CHECK',
    FUEL_CHECK: 'FUEL_CHECK',
    ENGINE: 'ENGINE',
    FLUID: 'FLUID',
    WINDSHIELD: 'WINDSHIELD',
    FUEL_FILL: 'FUEL_FILL',
  },

  payment_mode: {
    GATEWAY: 'GATEWAY',
    CASH: 'CASH',
    POS: 'POS',
  },

  parkUnparkActions: new Map([
    ['park', 'park'],
    ['unPark', 'unPark'],
    ['swap', 'swap'],
  ]),

  ContactMode: {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
  },

  QRcodeTypes: {
    VEHICLE: 'VEHICLE',
    CLIENT: 'CLIENT',
  },

  vehicleActions: {
    PARK: 'PARK',
    UNPARK: 'UNPARK',
    SWAP: 'SWAP',
  },
  approvalActions: new Map([
    ['APPROVE', 'Accepted'],
    ['REJECT', 'Rejected'],
  ]),

  paymentType: new Map([
    ['PLAN_PURCHASE', 'Purchase'],
    ['PLAN_RENEW', 'Renew'],
    ['PLAN_OVERDUE', 'Penalty'],
    ['SERVICE_REQUEST', 'Service request'],
  ]),

  colors: {
    yellow: '#f1aa1d',
    red: '#ed093b',
    green: '#26d65b',
    white: '#ffffff',
    black: '#000000',
    blue: '#00b1ff',
    grey: '#bbb',
    darkGrey: '#777',
  },

  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,20}$/,

  notificationTypes: {
    PLAN_REQUEST: 'PLAN_REQUEST', // client(to PORTAL)
    PLAN_CLOSED: 'PLAN_CLOSED',
    PLAN_CANCEL: 'PLAN_CANCEL',

    GATE_OPEN_REQUEST: 'GATE_OPEN_REQUEST', // client (to PORTAL)

    DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST', // employee (to PORTAL)
    DELETE_MODEL_REQUEST: 'DELETE_MODEL_REQUEST', // employee (to PORTAL)
    DELETE_FACILITY_REQUEST: 'DELETE_FACILITY_REQUEST', // employee (to PORTAL)

    DELETE_CLIENT_APPROVAL: 'DELETE_CLIENT_APPROVAL', // admin (to PORTAL)
    DELETE_MODEL_APPROVAL: 'DELETE_MODEL_APPROVAL', // admin (to PORTAL)
    DELETE_FACILITY_APPROVAL: 'DELETE_FACILITY_APPROVAL', // admin (to PORTAL)

    DELETE_CLIENT_REJECTION: 'DELETE_CLIENT_REJECTION', // admin (to PORTAL)
    DELETE_MODEL_REJECTION: 'DELETE_MODEL_REJECTION', // admin (to PORTAL)
    DELETE_FACILITY_REJECTION: 'DELETE_FACILITY_REJECTION', // admin (to PORTAL)

    SERVICE_REQUEST: 'SERVICE_REQUEST', // client (to PORTAL)

    SERVICE_REQUEST_CANCELLED: 'SERVICE_REQUEST_CANCELLED',

    SERVICE_VISIT_CREATED: 'SERVICE_VISIT_CREATED',
    SERVICE_VISIT_CANCELLED: 'SERVICE_VISIT_CANCELLED',
    SERVICE_VISIT_RESCHEDULED: 'SERVICE_VISIT_RESCHEDULED',

    ORDER_CREATE: 'ORDER_CREATE',
    ORDER_CANCEL: 'ORDER_CANCEL',
  },

  orderStatus: {
    Orders: 'ORDERS',
    CANCELLED: 'CANCELLED',
    RETURN: 'RETURN',
  },
  menuAccess: {
    PLAN_MANAGEMENT: 'PLAN_MANAGEMENT',
    CLIENT_MANAGEMENT: 'CLIENT_MANAGEMENT',
    SERVICE_MANAGEMENT: 'SERVICE_MANAGEMENT',
  },
  myfatoorahSupportedCountries: ['KW', 'SA', 'BH', 'AE', 'QA', 'OM', 'JO', 'EG'],

  fulfillCurrentDot: new Map([
    ['CREATED', 0],
    ['PREPARE_ORDER', 1],
    ['IN_TRANSIT', 2],
  ]),

  cancelOrderCurrentDot: new Map([
    ['OPEN', 0],
    ['IN_PROGRESS', 1],
  ]),

  returnCurrentDot: new Map([
    ['OPEN', 0],
    ['REQUEST_APPROVED', 1],
    ['SCHEDULE_PICKUP', 2],
    ['PRODUCT_APPROVED', 3],
    ['REFUND', 4],
  ]),

  returnQuickOrderCurrentDot: new Map([
    ['OPEN', 0],
    ['PRODUCT_APPROVED', 1],
    ['REFUND', 2],
  ]),

  returnGuestOrderCurrentDot: new Map([
    ['OPEN', 0],
    ['PRODUCT_APPROVED', 1],
    ['REFUND', 2],
  ]),

  activityDescription: new Map([
    ['ORDER_CREATE', 'created the order'],
    ['PAYMENT_DONE', 'paid the order [mode]'],
    ['PAYMENT_LINK_SENT', 'shared the payment link'],
    ['PREPARE_ORDER_START', 'started preparing the order'],
    ['PREPARE_ORDER_UNDO', 'undo the preparing order'],
    ['IN_TRANSIT_START', 'marked the order as In transit'],
    ['IN_TRANSIT_UNDO', 'undo the order In transit'],
    ['ORDER_DELIVERED', 'fulfilled the order'],
    ['CANCEL_ORDER_REQUESTED', 'canceled the order and requested refund'],
    ['CANCEL_ORDER_APPROVED', 'approved the cancel order request'],
    ['CANCEL_ORDER_REJECTED', 'rejected the cancel order request'],
    ['ORDER_CANCELLED', 'completed the order'],
    ['REFUND_SUCCESS', 'completed the order request'],
    ['REFUND_INITIATED', 'initiated refund for the order'],
    ['REFUND_FAILED', 'Refund failed'],
    ['RETURN_ORDER_REQUESTED', 'initiated a return for this order'],
    ['RETURN_ORDER_APPROVED', 'approved the return request'],
    ['RETURN_ORDER_REJECTED', 'rejected the return request'],
    ['SCHEDULE_PICKUP', 'scheduled the pickup on [from] at [to]'],
    ['RESCHEDULE_PICKUP', 'rescheduled the pickup to  [date] at [from] - [to]'],
    ['VERIFY_PRODUCT_APPROVED', 'collected the product'],
    ['VERIFY_PRODUCT_REJECTED', 'rejected the return'],
    ['RETURN_REQUEST_CANCELLED', 'cancelled the return'],
    ['CANCEL_REQUEST_CANCELLED', 'cancelled the cancel request'],
    ['ORDER_RETURNED', 'completed the return'],
    ['ORDER_DELETE', 'deleted the order'],
  ]),

  payment_mode_text: new Map([
    ['CASH', 'as cash'],
    ['POS', 'as POS'],
    ['GATEWAY', 'through link'],
  ]),

  orderCancel: new Map([
    ['approve', 'REQUEST_APPROVED'],
    ['reject', 'REQUEST_REJECTED'],
    ['refund', 'REFUND'],
    ['complete', 'COMPLETE'],
    ['cancel', 'REQUEST_CANCELLED'],
  ]),

  orderReturn: new Map([
    ['approve', 'REQUEST_APPROVED'],
    ['reject', 'REQUEST_REJECTED'],
    ['refund', 'REFUND'],
    ['complete', 'COMPLETE'],
    ['product_approved', 'PRODUCT_APPROVED'],
    ['product_rejected', 'PRODUCT_REJECTED'],
    ['schedule', 'SCHEDULE_PICKUP'],
    ['cancel', 'REQUEST_CANCELLED'],
  ]),

  order_status: {
    ORDER_CANCELED: 'ORDER_CANCELED',
    ORDER_RETURNED: 'ORDER_RETURNED',
    DELIVERED: 'DELIVERED',
    PREPARE_ORDER: 'PREPARE_ORDER',
    IN_TRANSIT: 'IN_TRANSIT',
  },

  order_request_status: {
    CLOSED: 'CLOSED',
    IN_PROGRESS: 'IN_PROGRESS',
    OPEN: 'OPEN',
  },

  order_request_action: {
    REQUEST_REJECTED: 'REQUEST_REJECTED',
    REQUEST_APPROVED: 'REQUEST_APPROVED',
    SCHEDULE_PICKUP: 'SCHEDULE_PICKUP',
    REQUEST_CANCELLED: 'REQUEST_CANCELLED',
    PRODUCT_APPROVED: 'PRODUCT_APPROVED',
    PRODUCT_REJECTED: 'PRODUCT_REJECTED',
    REFUND: 'REFUND',
    COMPLETE: 'COMPLETE',
  },

  durationTypeConvertion: new Map([
    ['DAY', 1],
    ['WEEK', 7],
    ['MONTH', 30],
    ['YEAR', 360],
  ]),

  durationTypeName: new Map([
    ['DAY', 'Day'],
    ['WEEK', 'Week'],
    ['MONTH', 'Month'],
    ['YEAR', 'Year'],
  ]),

  variantQuantityMax: 9999999999,
};
export default Constant;
