/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import {
  Card, Col, Layout,
  Row, Spin, Typography,
} from 'antd';
import moment from 'moment';

import classNames from 'classnames';
import {
  Icon, Toast, EmployeeForms,
} from '../../Components';
import Routes from '../../Global/Routes';
import { EmployeeForm } from '../../Global/interface';

import { UserStoreProps } from '../../Store/userStore';
import { AuthStoreProps } from '../../Store/authStore';
import { MasterStoreProps } from '../../Store/masterStore';

import './UpdateProfile.less';

const { Title, Paragraph, Link } = Typography;
const { Sider } = Layout;

interface UpdateProfileProps {
  userStore: UserStoreProps,
  authStore: AuthStoreProps,
  masterStore: MasterStoreProps
}

function UpdateProfile(props: UpdateProfileProps) {
  const { userStore, masterStore, authStore } = props;

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const id = authStore?.currentUser?.id;
  const [collapsed, setCollapsed] = useState(false);

  const updateEmployee = async (employee: any) => {
    try {
      const params: any = employee;
      setIsLoading(true);
      params.dob = moment(employee?.dob)?.format('YYYY-MM-DD');
      params.profile = employee?.profile || null;
      params.documents = employee?.documents;
      if (id) await userStore.updateUser(id, params);
      setIsLoading(false);
      gotoProfile();
    } catch (err:any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const fetchEmployeesData = async (userId: string) => {
    try {
      setIsLoading(true);
      await userStore.getLoggedUser(userId);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const onFinish = (values: EmployeeForm) => updateEmployee(values);
  const gotoProfile = () => history.push(Routes.profile);

  async function getCountryData() {
    setIsLoading(true);
    await masterStore?.getCountryData()
      .then(() => setIsLoading(false))
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  }

  useEffect(() => {
    getCountryData();
    if (id) {
      fetchEmployeesData(id);
    }
  }, []);

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-lg-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content" onClick={gotoProfile}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Link>
            <Typography.Title className="m-0 header_main mb-2 pb-3">
              Update Profile
            </Typography.Title>
            <Card className="create_card">
              <EmployeeForms
                isUpdate
                isLoading={isLoading}
                onCancel={gotoProfile}
                onFinish={onFinish}
                employeeDetails={userStore?.loggedUser}
              />
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>Update profile info</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('userStore', 'masterStore', 'authStore')(observer(UpdateProfile));
