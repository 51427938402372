/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  action, makeObservable, observable,
} from 'mobx';
import Constant from '../Global/Constant';
import { QuickService, newServiceProps } from '../Global/interface';
import quickServiceService from '../Services/quickServiceService';
import serviceVisitService from '../Services/serviceVisitSevices';

export interface IQuickServiceStore{
  services: Array<QuickService>,
  service: QuickService,
  total: number,

  createService: (data: newServiceProps) => Promise<any>
  listServices: (queryParams?: any) => any,
  getServiceById: (id: string) => any
  deleteService: (id: string) => any
  downloadInvoice: (id: string) => any
  checkServiceExist: (queryParams: any) => any

  initialize: Function
}

class ServiceVisitStore {
  services: any = []

  service: QuickService | null = null;

  total: number = 0

  constructor() {
    makeObservable(this, {
      services: observable,
      service: observable,
      total: observable,

      createService: action,
      listServices: action,
      downloadInvoice: action,
      deleteService: action,
    });
    this.initialize();
  }

    initialize = () => {
      this.services = [];
      this.total = 0;
    }

    createService = async (data: newServiceProps) => {
      try {
        const response = await quickServiceService.createService(data);
        return (response);
      } catch (error: any) {
        return Promise.reject(
          error?.response?.data?.error?.message || Constant.defaultErrorMessage,
        );
      }
    }

  listServices = async (queryParams?: any) => {
    try {
      const response = await quickServiceService.listServices(queryParams);
      if (response && response?.data) {
        this.services = response?.data?.quick_services;
        this.total = response?.data?.total[0]?.counts;
      }
      return (null);
    } catch (error:any) {
      return (error?.response?.data?.error?.message);
    }
  }

  downloadInvoice = async (id: any) => {
    try {
      const response = await quickServiceService.downloadInvoice(id);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  checkServiceExist = async (query: any) => {
    try {
      const response = await quickServiceService.checkServiceExist(query);
      return Promise.resolve(response?.data?.exist);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  deleteService = async (id: string) => {
    try {
      await quickServiceService.deleteService(id);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(
        error?.response?.data?.error?.message || Constant.defaultErrorMessage,
      );
    }
  }
}

export default new ServiceVisitStore();
