/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import classNames from 'classnames';
import './ColorPicker.less';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import Utility from '../../Global/Utility';

interface Props {
  onSelect: (colorCode: string) => void;
  defaultColor: string|undefined;
  placeholder: string
  inputRules: any
  colorRules: any
  disabled?: boolean
  inputName?: string,
  pickerName?: string,
  label?: string
}

const ColorPicker = (props: Props) => {
  const wrapperRef = useRef(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState<string|undefined>('');
  useOnClickOutside(wrapperRef, () => setShowColorPicker(false));
  const {
    onSelect, defaultColor, placeholder, inputRules, colorRules, disabled, label,
    inputName, pickerName,
  } = props;
  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);
  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);
  const handleChangeComplete = (colorObject: any) => {
    setColor(colorObject?.hex);
    onSelect(colorObject?.hex);
  };
  return (
    <div ref={wrapperRef}>
      <div>
        <Form.Item label={label} normalize={Utility.trimSpacesInValues} className="mandatory mb-0" name={inputName} rules={inputRules}>
          <Input placeholder={placeholder} className="inp_style pe-5" type="text" maxLength={15} disabled={disabled} />
        </Form.Item>
        <div className="color_picker_icn position-absolute cursor-pointer" onClick={toggleColorPicker}>
          <span className={classNames('color_circle d-inline-block ms-3 rounded-circle', { rainbow_gradient: !color })} style={{ backgroundColor: color }} />
        </div>
      </div>
      <div className="position-relative cursor-pointer">
        <div className="position-absolute" style={{ zIndex: 1 }}>
          <Form.Item className={classNames('mandatory mb-0', { color_picker_none: !showColorPicker }, { color_picker_show: showColorPicker })} name={pickerName} rules={colorRules}>
            {showColorPicker && !disabled && (
            <SketchPicker
              disableAlpha
              color={color}
              onChangeComplete={handleChangeComplete}
            />
            )}
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

ColorPicker.defaultProps = {
  label: 'name',
  disabled: false,
  inputName: 'color_name',
  pickerName: 'color: code',
};

export default ColorPicker;
