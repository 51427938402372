/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable } from 'mobx';
import searchService from '../Services/searchService';
import Constant from '../Global/Constant';
import { Client, Employee, VehicleData } from '../Global/interface';

export interface ISearchStore{
    getSearchResult: (keyword: string) => any,
    initialize: Function,
    clients: Array<Client>,
    employees: Array<Employee>,
    vehicles: Array<VehicleData>
}

class SearchStore {
  clients: Array<Client> | null = null;

  employees: Array<Employee> | null = null;

  vehicles: Array<VehicleData> | null = null;

  constructor() {
    makeObservable(this, {
      clients: observable,
      employees: observable,
      vehicles: observable,
    });
    this.initialize();
  }

  initialize = () => {
    this.clients = null;
    this.employees = null;
    this.vehicles = null;
  }

  getSearchResult = async (keyword: string) => {
    try {
      const response = await searchService.getSearchResult(keyword);
      if (response && response?.data) {
        this.clients = response?.data?.clients;
        this.employees = response?.data?.employees;
        this.vehicles = response?.data?.vehicles;
      }
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new SearchStore();
