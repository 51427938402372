/* eslint-disable import/no-unresolved */
import { Button, Tooltip } from 'antd';
import React from 'react';
import { Icon, RenderItem } from '..';

interface IButtonIcon {
    text: any
    tooltipText?: string
    onClick: () => any
    disableBtn?: boolean
    showIcon?: boolean
    iscolor?: any
    className?: string
}

ButtonIcon.defaultProps = {
  tooltipText: '',
  disableBtn: false,
  showIcon: true,
  iscolor: '',
  className: 'd-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto',
};

function ButtonIcon(props: IButtonIcon) {
  const {
    onClick, text, tooltipText, disableBtn, showIcon, iscolor, className,
  } = props;
  return (
    <Tooltip title={tooltipText || text}>
      <Button
        className={className}
        onClick={onClick}
        disabled={disableBtn}
        style={{ color: iscolor }}
      >
        {text}
        <RenderItem isTrue={showIcon}>
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </RenderItem>
      </Button>
    </Tooltip>
  );
}

export default ButtonIcon;
