/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  vehicle: [{
    required: true,
    message: constant.vehicleRequired,
  }],
};

export default rule;
