/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { requestQueryParams } from '../Global/interface';
import api from './api';

class RequestService {
  createEmployeeRequest = async (data: any) => {
    try {
      const response = await api.post('/employee-request/', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updatePlanRequestStatus = async (id: string, data: any) => {
    try {
      const response = await api.put(`/customer-contracts/change-status/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  updateEmpRequestStatus = async (id: string, data: any) => {
    try {
      const response = await api.put(`/employee-request/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  getEmployeeRequest = (status: string, order?: string, orderBy?: number,
    page?: number, limit?: number, keyword?: string|null) => {
    const query = queryString.stringify({
      status,
      order,
      orderBy,
      keyword,
      limit,
      page,
    }, { skipNull: true });
    const url = `/employee-request?${query}`;
    return api.get(url);
  }

  getPlanRequests = (queryParams: requestQueryParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/customer-contracts/requests?${query}`;
    return api.get(url);
  }

  getPlanReqCount = async (status: string) => {
    try {
      const query = queryString.stringify({ status }, { skipNull: true });
      const url = `/customer-contracts/requests/count?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  getEmployeeReqCount = async (status: string) => {
    try {
      const query = queryString.stringify({ status }, { skipNull: true });
      const url = `/employee-request/count?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  getServiceReqCount = async (status: string) => {
    try {
      const query = queryString.stringify({ status }, { skipNull: true });
      const url = `/services/request/count?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };
}
export default new RequestService();
