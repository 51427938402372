/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col, Layout, Row, Typography, Divider,
  Breadcrumb, Form, Radio, Button, Card, Spin, Select,
} from 'antd';
import debounce from 'lodash.debounce';

import { useForm } from 'antd/lib/form/Form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  Icon, Toast, AddressDetails, Info,
} from '../../Components';
import Constant from '../../Global/Constant';
import {
  Client, Variants,
} from '../../Global/interface';
import { ICategoryStoreProps } from '../../Store/category';
import { IProductStoreProps } from '../../Store/productStore';
import OrderList from './OrderList';
import AddProductModal from './AddProductModal';
import rules from './AddProduct.validation';
import { IOrderStore } from '../../Store/orderStore';
import Routes from '../../Global/Routes';
import AddressSelection from './AddressSelection';
import { ISettingsStore } from '../../Store/settingsStore';
import { ICustomerStore } from '../../Store/customerStore';
import Utility from '../../Global/Utility';

interface ICreateOrder {
  customerStore: ICustomerStore
  categoryStore: ICategoryStoreProps
  productStore: IProductStoreProps
  orderStore: IOrderStore
  settingsStore: ISettingsStore
}

interface IproductDetails {
  category: string
  product: string
  variant: Variants
  quantity: number
  image: string
}

const { STANDARD, EXPRESS } = Constant.deliveryCharges;
const { Option } = Select;

function CreateOrder(props: ICreateOrder) {
  const [form] = useForm();
  const history = useHistory();
  const {
    customerStore, categoryStore, productStore, orderStore, settingsStore,
  } = props;
  const { customers } = customerStore;
  const { categories } = categoryStore;
  const { products, product } = productStore;
  const { settings } = settingsStore;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState<Array<IproductDetails>>([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<any>(-1);
  const [selectedAddress, setSelectedAddress] = useState<any>();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>();

  const location = useLocation();
  const customer: string = queryString.parse(location?.search)?.customer as string;

  const fetchClient = async (keyword: string) => {
    setIsLoading(true);
    try {
      await customerStore.listCustomers({ keyword });
    } catch (error:any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      await categoryStore.getCategories({});
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => setIsModalVisible(false);

  const searchDebouncing = useCallback(
    debounce((nextValue: string) => {
      fetchClient(nextValue);
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (value: any) => {
    searchDebouncing(value);
  };

  const categoryChange = (value:string) => getProduct(value);

  const getProduct = async (category: string) => {
    setIsLoading(true);
    try {
      await productStore.getProducts({ category });
    } catch (error:any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const productChange = (value:string) => {
    getProductById(value);
  };

  const getProductById = async (productId: string) => {
    setIsLoading(true);
    try {
      await productStore.getProductById(productId);
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const createOrder = async (values: any) => {
    setIsLoading(true);
    try {
      await orderStore.createOrder(values);
      history.goBack();
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const createOrderList = (v: any) => {
    setIsModalVisible(false);
    setProductDetails([...productDetails, v]);
  };

  const removeFromOrderList = (variant: string, sku: string) => {
    form.setFieldsValue({ [sku]: 1 });
    setProductDetails(productDetails?.filter(
      (item: IproductDetails) => item?.variant?._id !== variant,
    ));
  };

  const finishHandler = (values: any) => {
    if (!productDetails?.length) {
      Toast.error('Please choose a product to order');
      return;
    }
    // TODO: Use configue api to get delivery amount
    if (!isExpressDeliveryAmount && values?.delivery_mode === EXPRESS) {
      Toast.error('Express delivery amount is not configured.');
      return;
    }

    const qunatityNotPresent = productDetails?.find((item) => item?.quantity < 1);
    if (qunatityNotPresent) {
      Toast.error('Please enter quantity for all products');
      return;
    }

    const order_item = productDetails?.map(
      (item) => ({ variant: item?.variant?._id, quantity: item?.quantity }),
    );
    const data = {
      delivery_mode: values?.delivery_mode,
      order_item,
      customer: values?.customer,
      address: selectedAddress,
    };
    createOrder(data);
  };

  const quantityChangeHandler = (quantity: number, variant: string) => {
    const temp = productDetails?.map((item) => {
      if (item?.variant?._id === variant) return { ...item, quantity };
      return item;
    });
    setProductDetails(temp);
  };

  const cancelHandler = () => history?.goBack();

  const handleClientSelect = async (clientId: any) => {
    try {
      await customerStore?.getCustomerById(clientId);
      setSelectedAddress(customerStore?.customer?.address);
      setSelectedClient(clientId);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const chooseAddress = (address: any, index: number) => {
    setSelectedAddressIndex(index);
    setSelectedAddress(address);
  };

  useEffect(() => {
    getCategories();
    fetchClient('');
    if (!settings?.order_express_delivery_amount) getSettings();
    if (customer) {
      form.setFieldsValue({ customer });
      handleClientSelect(customer);
    }
  }, []);

  const clientMultipleAddresses = customerStore?.customer?.client_addresses?.length > 0;

  const isExpressDeliveryAmount = settings?.order_express_delivery_amount >= 0;
  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between">
              {!customer && (
              <Breadcrumb className="secondary text-uppercase r-medium">
                <Breadcrumb.Item>
                  <Link to={Routes.orders}>ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>CREATE ORDER</Breadcrumb.Item>
              </Breadcrumb>
              )}
              {customer && (
              <Breadcrumb className="secondary text-uppercase r-medium">
                <Breadcrumb.Item>
                  <Link to={Routes.customers}>CUSTOMERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`${Routes.customer_details}/${customer}`}>{customerStore?.customer?.client_code}</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>CREATE ORDER</Breadcrumb.Item>
              </Breadcrumb>
              )}
            </div>
            <Divider className="mt-3 mb-3 border-top" />
            <Typography.Title className="m-0 header_main fs-xs-24 mb-4">
              Create Order
            </Typography.Title>
            <Card className="create_card">
              <Form form={form} onFinish={finishHandler} initialValues={{ delivery_mode: STANDARD }} layout="vertical" validateTrigger={['onBlur', 'onChange', 'onSubmit']} scrollToFirstError>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col xs={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Customer
                    </Typography.Title>
                  </Col>
                  <Col xs={24} lg={20}>
                    <Typography.Text className="m-0 create_para">
                      Choose the customer for the order
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="customer" className="mandatory" name="customer" rules={rules?.customer}>
                          <Select
                            showSearch
                            defaultOpen={false}
                            filterOption={false}
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Select customer"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onSearch={handleSearchTextChange}
                            onChange={handleClientSelect}
                            disabled={!!customer}
                          >
                            {customers?.map((client: Client) => (
                              <Option key={client?._id} value={client?._id}>
                                {Utility.getUserName(client)}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {selectedClient && customerStore?.customer?.address
                      && (
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Form.Item label="CHOOSE BILLING ADDRESS" className="flex-nowrap">
                          <Row gutter={[24, 16]}>
                            <Col lg={8} md={12} sm={12} xs={24}>
                              <div className="border-color border-radius p-3">
                                <div style={{ minHeight: '150px' }}>
                                  <AddressDetails addressInfo={customerStore?.customer?.address} />
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  { clientMultipleAddresses ? (
                                    <div className="off-white-bg secondary rounded-pill px-2 py-1 d-flex align-items-center justify-content-center fs-16">
                                      Default
                                    </div>
                                  ) : ''}
                                  <AddressSelection
                                    index={selectedAddressIndex}
                                    localIndex={-1}
                                    address={customerStore?.customer?.address}
                                    chooseAddress={chooseAddress}
                                    clientMultipleAddresses={clientMultipleAddresses}
                                  />
                                </div>
                              </div>
                            </Col>

                            {clientMultipleAddresses
                              ? (
                                customerStore?.customer?.client_addresses
                                  ?.map((address: any, index: number) => (
                                    <Col lg={8} md={12} sm={12} xs={24} style={{ minHeight: '150px' }}>
                                      <div className="border-color border-radius p-3">
                                        <div style={{ minHeight: '150px' }}>
                                          <AddressDetails addressInfo={address} />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                          <div className="px-2 py-1 d-flex align-items-center justify-content-center fs-16" />
                                          <AddressSelection
                                            index={selectedAddressIndex}
                                            localIndex={index}
                                            address={address}
                                            chooseAddress={chooseAddress}
                                            clientMultipleAddresses
                                          />
                                        </div>
                                      </div>
                                    </Col>

                                  ))
                              ) : ''}
                          </Row>
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Product
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Typography.Text className="m-0 create_para me-2 me-sm-0">
                        Choose the product for the order
                      </Typography.Text>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0" type="link" onClick={showModal}>
                        Choose Product
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    <Row>
                      <Col xxl={20} lg={24} md={24} sm={24} xs={24}>
                        <ul className="order_product_list list-unstyled">
                          {productDetails?.map((item: IproductDetails, index: number) => (
                            <OrderList
                              index={index + 1}
                              category={item?.category}
                              product={item?.product}
                              image={item?.image}
                              variant={item?.variant?.color?.code}
                              variantSku={item?.variant?.sku}
                              variantSize={item?.variant?.size}
                              price={item?.variant?.price}
                              key={item?.variant?._id}
                              max={item?.variant?.quantity}
                              quantity={item?.quantity}
                              removeFromOrderList={
                                () => removeFromOrderList(item?.variant?._id, item?.variant?.sku)
                              }
                              quantityChangeHandler={(quantity: number) => quantityChangeHandler(
                                quantity, item?.variant?._id,
                              )}
                            />
                          ))}
                        </ul>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Delivery
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="mb-3 d-block create_para">
                      Choose the type of delivery
                    </Typography.Text>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item name="delivery_mode" className="mandatory">
                          <Radio.Group className="cust_radio_btn">
                            <Radio className="radio_inp primary" value={STANDARD}>Standard</Radio>
                            <Radio className="radio_inp primary" value={EXPRESS} disabled={!isExpressDeliveryAmount}>
                              <span
                                className="d-flex"
                              >Express ({isExpressDeliveryAmount ? `${settings?.order_express_delivery_amount} KD`
                                : <div className="r-bold yellow">Not configured</div>} )
                              </span>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                    <div className="d-flex mt-4 ps-lg-0">
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        Create Order
                      </Button>
                      <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelHandler}>
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
                <AddProductModal
                  isModalVisible={isModalVisible}
                  categories={categories?.categories}
                  products={products?.products}
                  variants={product?.variants}
                  categoryChange={categoryChange}
                  handleCancel={handleCancel}
                  productChange={productChange}
                  createOrderList={createOrderList}
                  productDetails={productDetails}
                />
              </Form>
            </Card>
          </Col>
          <Info
            setCollapsed={setCollapsed}
            infoDetail="Create custom order"
          >
            <></>
          </Info>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('customerStore', 'categoryStore', 'productStore', 'orderStore', 'settingsStore')(observer(CreateOrder));
