/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { Card, Typography } from 'antd';
import React, { useState } from 'react';
import Utility from '../../Global/Utility';
import Icon from '../Icon';
import QRCodeModal from '../QRCodeModal';
import RenderItem from '../RenderItem';

interface VehicleDisplayProps {
    modelName: string,
    vehicleCode: string,
    vehicleImage: string,
    isLoading: boolean,
    qrimage: string,
    facilityName?: string
    category: string,
    vehicleNo: string
    vehicleId: string
}

VehicleDisplay.defaultProps = {
  facilityName: '',
};

function VehicleDisplay(props: VehicleDisplayProps) {
  const {
    modelName, vehicleCode, vehicleNo, vehicleId, vehicleImage, facilityName,
    isLoading, qrimage, category,
  } = props;

  const [isQRcodeVisible, setIsQRcodeVisible] = useState<boolean>(false);

  const showQRCodeModal = () => setIsQRcodeVisible(true);

  const closeQRCodeModal = () => setIsQRcodeVisible(false);

  return (
    <Card loading={isLoading}>
      <div className="d-flex">
        <img src={qrimage} width="44px" className="h-100" alt="qr_code" style={{ cursor: 'pointer' }} onClick={showQRCodeModal} />
        <div className="ms-3 w-75">
          <Typography.Title level={5} className="text-uppercase mb-0 primary text-truncate w-100">
            {modelName}
          </Typography.Title>
          <Typography.Paragraph className="mb-0 secondary">
            {vehicleCode}
          </Typography.Paragraph>
        </div>
      </div>
      <div className="car_image vehicle_img_size d-flex justify-content-center m-auto">
        <img className="img-fluid" src={vehicleImage || Utility.defaultModalImage(category)} alt="vehicleimage" />
      </div>
      <RenderItem isTrue={!!facilityName}>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div>
            <Typography.Title level={5} className="text-capitalize mb-0">
              Personal Facility
            </Typography.Title>
            <Typography.Paragraph className="mb-0 d-flex align-items-center car_location">
              <Icon className="icon-13 me-2" iconRef="#location" />
              {/* Manīfah */}
              {facilityName}
            </Typography.Paragraph>
          </div>
        </div>
      </RenderItem>
      <QRCodeModal
        vehicleNo={vehicleNo || vehicleCode}
        id={vehicleId}
        isQRCodeModal={isQRcodeVisible}
        closeQRCodeModal={closeQRCodeModal}
      />
    </Card>
  );
}

export default VehicleDisplay;
