/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable } from 'mobx';
import { AxiosError, AxiosResponse } from 'axios';
import contractService from '../Services/contractService';
import Constant from '../Global/Constant';
import { Contract, ContractRes } from '../Global/interface';

export interface IContractStore{
  contracts: ContractRes,
  contract: Contract|null,
  noOfContracts : number,
  planHistory: ContractRes,
  total: number,
  activities: any,
  activityTotal: number,
  orderId: string,
  getData: (order: string, orderBy:number, contractType: string) => any
  getContractList: (
    order: string, orderBy:number, contractType: string, pageNumber: number, keyword: string
  ) => any
  getPlanHistory: (order: string,
    orderBy:number, pageNumber: number, keyword: string, contract_request_status: string) => any
  getContractById: (id: string) => any
  getContractActivities:(queryParams: any) => any
  renewContract:(id:string, contactMode:any) =>any
  addVehicleToPlan: (data: any) => any
  makePayment: (id: string, data: any) => any
}

class ContractStore {
  contracts: ContractRes | null = null;

  contract: Contract|null = null;

  noOfContracts : number = 0;

  planHistory: ContractRes | null = null;

  total: number = 0;

  activities: any = null;

  activityTotal: number = 0;

  orderId: string = '';

  constructor() {
    makeObservable(this, {
      contract: observable,
      contracts: observable,
      planHistory: observable,
      total: observable,
      activities: observable,
      activityTotal: observable,
      orderId: observable,
    });
    this.initialize();
  }

  initialize = () => {
    this.contracts = null;
    this.total = 0;
    this.activities = null;
    this.activityTotal = 0;
  }

  getContractList = async (
    order: string,
    orderBy:number,
    contractType: string | Array<string>,
    pageNumber: number,
    keyword: string,
  ) => {
    try {
      const response = await contractService.getContracts(order, orderBy,
        pageNumber, Constant.contractPerPage, contractType, keyword);
      this.contracts = {
        data: response?.data?.contracts[0]?.contract,
        total: response?.data?.contracts[0]?.total[0]?.counts,
        page: pageNumber,
        order,
        orderBy,
        keyword,
      };
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getContractById = async (id: string) => new Promise((resolve, reject) => {
    contractService.getContractById(id)
      .then((response: AxiosResponse) => {
        if (response?.data) {
          this.contract = response?.data?.contract;
          this.noOfContracts = response?.data?.noOfContracts;
        }
        resolve(null);
      })
      .catch((error : AxiosError) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getPlanHistory = async (order: string,
    orderBy:number, pageNumber: number, keyword: string, contract_request_status: string) => {
    try {
      const response = await contractService.getContracts(order, orderBy,
        pageNumber, Constant.contractPerPage, undefined, keyword, contract_request_status);
      this.planHistory = {
        data: response?.data?.contracts[0]?.contract,
        total: response?.data?.contracts[0]?.total[0]?.counts,
      };
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getContractActivities = async (queryParams: any) => {
    try {
      const response = await contractService.getContractActivities(queryParams);
      this.activities = response?.data?.activities;
      this.activityTotal = response?.data?.total;
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  renewContract = async (id: string, data: any) => new Promise((resolve, reject) => {
    contractService.renewContract(id, data)
      .then((response: any) => {
        this.orderId = response?.data?.orderId;
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  makePayment = async (id: string, data: any) => {
    try {
      const response = await contractService.makePayment(id, data);
      this.orderId = response?.data?.orderId;
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  addVehicleToPlan = async (data: any) => {
    try {
      await contractService.addVehicleToPlan(data);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new ContractStore();
