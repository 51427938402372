/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/Form';
import {
  Button, Card, Col, Divider, Form, Input, InputNumber, Row, Select, Typography,
} from 'antd';
import utility from '../../Global/Utility';
import './CreateProduct.less';
import { Icon, PreviewButton } from '../../Components';
import 'react-phone-number-input/style.css';
import { Product, ProductCategory } from '../../Global/interface';
import rules from './manageProductForm.validation';
import DescriptionEditor from './DescriptionEditor';

const { Option } = Select;

interface IBasicInfo {
  categories: Array<ProductCategory>
  createProduct: (values: any) => void
  isLoading: boolean
  isUpdate: boolean
  updateProduct: (values: any) => void
  product: Product
  goToProductListing: () => void
  productImage: boolean
  masterImage: string
  showPreviewPublishBtn: boolean
  viewPublishConfirnModal: () => void
}

function BasicInfo(props: IBasicInfo) {
  const {
    categories, createProduct, isLoading, isUpdate, masterImage, showPreviewPublishBtn,
    updateProduct, product, goToProductListing, viewPublishConfirnModal,
    productImage,
  } = props;
  const [form] = useForm();
  const [content, setContent] = useState('');

  const onFinish = async (values: any) => {
    if (isUpdate) updateProduct(values);
    else createProduct(values);
  };

  const setFormData = () => {
    form.setFieldsValue({
      category: product?.category?._id,
      name: product?.name,
      price: product?.price,
      description: product?.description,
    });
  };

  useEffect(() => {
    if (product && isUpdate) {
      setFormData();
    }
  }, [product]);

  return (
    <Card className="create_card">
      <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Row
          gutter={{
            xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
          }}
          className="m-0"
        >
          <Col lg={4} md={24} sm={24} xs={24}>
            <Typography.Title className="m-0" level={5}>
              Basic
            </Typography.Title>
          </Col>
          <Col lg={20} md={24} sm={24} xs={24}>
            <Typography.Text className="m-0 create_para">
              Add your product basic informations
            </Typography.Text>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="PRODUCT CATEGORY" className="mandatory" name="category" rules={rules.category}>
                  <Select
                    suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                    placeholder="Choose"
                    className="slct_style"
                    dropdownClassName="slct_drp_dwn border-radius"
                  >
                    {categories?.map((category: ProductCategory) => (
                      <Option value={category?._id}> {category?.name} </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="PRODUCT NAME" rules={rules.product_name} name="name" className="mandatory" required={false} normalize={utility.trimSpacesInValues}>
                  <Input placeholder="Enter product name" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="PRODUCT PRICE" rules={rules.product_price} name="price" className="mandatory" required={false}>
                  <InputNumber placeholder="Enter product price" className="inp_style w-100 inp_num_style pe-5 shadow-none" min={0} maxLength={15} max={999999999999999} />
                </Form.Item>
                <span className="position-absolute top-0 end-0 bottom-0 inactive me-4 d-flex align-items-center pe-2">KD</span>
              </Col>
            </Row>
            <Divider className="m-0 border-top" />
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
          }}
          className="m-0 mt-3"
        >
          <Col xs={24} lg={4}>
            <Typography.Title className="m-0" level={5}>
              Description
            </Typography.Title>
          </Col>
          <Col xs={24} lg={20}>
            <Typography.Text className="m-0 create_para">
              Add your product description
            </Typography.Text>
            <Row gutter={16} className="mt-3">
              <Col xs={24} lg={24} xl={24} xxl={20}>
                <DescriptionEditor
                  description={product?.description}
                  setContents={setContent}
                />
              </Col>
            </Row>
            <Divider className="m-0 border-top" />
            <div className="d-sm-flex justify-content-between mt-sm-4">
              <div className="d-sm-flex">
                <Button disabled={isLoading} htmlType="submit" className="main_btn me-3 fs-16 r-bold mt-3 mt-sm-0">
                  { isUpdate ? 'Update' : 'Create Product'}
                </Button>
                <Button disabled={isLoading} onClick={goToProductListing} type="text" className="cancel_btn fs-16 r-bold mt-3 mt-sm-0">
                  Cancel
                </Button>
              </div>
              <PreviewButton
                visibility={showPreviewPublishBtn}
                viewPublishConfirnModal={viewPublishConfirnModal}
                publishButtonText={product?.published ? 'Unpublish Product' : 'Publish Product'}
                buttonColor={product?.published ? 'yellow' : 'green'}
                showPreview={!!content}
                description={content}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default observer(BasicInfo);
