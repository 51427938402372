/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Routes from '../Global/Routes';
import authStore from '../Store/authStore';

const AccessRoute = ({
  component: Component, exact, access, path,
}: any) => {
  const { currentUser } = authStore;
  const isAccessible = currentUser?.access.some((a: number) => access.includes(a));
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        isAccessible
          ? <Component />
          : <Redirect to={Routes.notFound} />)}
    />
  );
};

export default AccessRoute;
