/* eslint-disable  */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Breadcrumb,
    Button,
    Modal,
    Spin,
    Table,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { Icon, Search, SortTh, Toast } from '..';
import { IProductStoreProps } from '../../Store/productStore';
import { Variants } from '../../Global/interface';
import debounce from 'lodash.debounce';
import Constant from '../../Global/Constant';

interface ICompactableProduct {
    isModalVisible: boolean
    breadCrumbItems: any
    productStore?: IProductStoreProps
    selectedVariants: Array<string>
    isLoading: Boolean
    linkProductHandler: (variantList: any, breadCrumbList: any) => any
    handleCancel: () => void
}

CompactableProducts.defaultProps = {
    productStore: undefined,
};

function CompactableProducts(props: ICompactableProduct) {
    const {
        isModalVisible, breadCrumbItems, productStore, selectedVariants, isLoading,
        handleCancel, linkProductHandler
    } = props;

    const { variants } = productStore as IProductStoreProps;

    const [selectedVariantId, setselectedVariantId] = useState<any>(selectedVariants); 
    const [isVariantLoading, setIsVariantLoading] = useState<boolean>(false);

    useEffect(() => {
        if(selectedVariants) {
            setselectedVariantId(selectedVariants)
        }
    }, [selectedVariants])

    const [orderBy, setOrderBy] = useState<string>('createdAt');
    const [order, setOrder] = useState<number>(-1);
    const [keyword, setKeyword] = useState('');

    const getVariants = async (
    sort: number, sortBy: string, searchKeyword?: string
    ) => {
        try {
            setIsVariantLoading(true);
            await productStore?.getVariants({
                keyword: searchKeyword,
                order: sort,
                orderBy: sortBy,
                published: true
            });
            setIsVariantLoading(false);
        } catch (error: any) {
            setIsVariantLoading(false);
            Toast.error(error);
        }
    };

    const handleSaveHandler = () => {
        const variantList = selectedVariantId?.map((item: any) => ({variant: item}))
        linkProductHandler(variantList, breadCrumbItems[breadCrumbItems?.length - 1])
    }

    const sortTable = (dataIndex: string) => {
        let sort = -1;
        if (order === -1 && dataIndex === orderBy) sort = 1;
        else if (dataIndex !== orderBy) setOrderBy(dataIndex);
        setOrder(sort);
        getVariants(sort, dataIndex, keyword);
      };

    const compatibleColumns = [
        {
            title: <SortTh title="Category" dataIndex="category.name" orderBy={order} order={orderBy} />,
            dataIndex: 'category',
            onHeaderCell: () => ({
                onClick: () => {
                  sortTable('category.name');
                },
            }),
            ellipsis: true,
        },
        {
            title: 'Product',
            dataIndex: 'product',
            ellipsis: true,
        },
        {
            title: 'Variant',
            dataIndex: 'variant',
            ellipsis: true,
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            ellipsis: true,
        },
    ];
    const compatibleDataSource = variants?.map((variant: Variants) => (
        {
            key: variant?._id,
            category: variant?.category?.name,
            product: variant?.product?.name,
            variant: variant?.color?.name,
            sku: variant?.sku,
        }))

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setselectedVariantId(selectedRowKeys)
        },
    };

    const searchDebouncing = useCallback(
        debounce((
           nextValue: string, sort: number, sortBy: string,
        ) => {
          getVariants(sort, sortBy, nextValue);
        }, Constant.debaounceTime),
        [],
      );
    
      const handleSearchTextChange = (event: any) => {
        const { value: nextValue } = event.target;
        setKeyword(nextValue);
        searchDebouncing(
          nextValue, order, orderBy,
        );
      };

    const onCancel = () => {
        setselectedVariantId(selectedVariants)
        setOrder(-1)
        setOrderBy('createdAt')
        handleCancel()
        setKeyword('')
    }

    useEffect(() => {
        if (isModalVisible) getVariants(order, orderBy);
    }, [isModalVisible]);

    return (
        <Modal
            destroyOnClose
            title={(
                <div className="d-flex justify-content-between align-items-sm-center mb-3">
                    <div className="d-sm-flex align-items-center flex-fill">
                        <h4 className="mb-0 table-header r-bold">Choose Compatible Products</h4>
                        <Search
                            placeholder="Search"
                            titleToolTip="Search Products"
                            size="small"
                            onChange={handleSearchTextChange}
                        />
                    </div>
                </div>
            )}
            maskClosable={!isLoading || !isVariantLoading}
            centered
            footer={null}
            className="doc_modal_wrapper cmn_modal_wrapper"
            visible={isModalVisible}
            onCancel={onCancel}
            bodyStyle={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
            width={1116}
            closeIcon={(
                <Button disabled={!!isLoading || !!isVariantLoading} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
                    <Icon className="icon-10" iconRef="#close" />
                </Button>
            )}
        >
            <Spin spinning={!!isLoading || !!isVariantLoading}>
            <Breadcrumb className="primary text-capitalize comp_modal_p">
                {
                    breadCrumbItems?.map((item: any) => (
                        <Breadcrumb.Item>
                            {item?.title}
                        </Breadcrumb.Item>
                    ))
                }
            </Breadcrumb>
            <Table
                rowClassName="cursor-pointer"
                columns={compatibleColumns}
                dataSource={compatibleDataSource}
                pagination={false}
                size="small"
                scroll={{ x: '1000px' }}
                className="vehicle_db_table compatible_modal_table"
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedVariantId,
                    ...rowSelection,
                }}
            />
            <div className="d-flex mt-4 align-items-center comp_modal_p">
                <Button className="main_btn r-bold" onClick={handleSaveHandler}>
                    Save Changes
                </Button>
                <div className="w-100">
                    <Button type="text" className="fs-16 r-bold bg-transparent">
                        {selectedVariantId?.length} Products selected
                    </Button>
                </div>
            </div>
            </Spin>
        </Modal>
    );
}

export default inject('productStore')(observer(CompactableProducts));
