/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

import { newServiceProps } from '../Global/interface';

class QuickService {
  createService = async (data : newServiceProps) => {
    try {
      const response = await api.post('/quick-service', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  listServices = (queryParams?: any) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/quick-service/list?${query}`;
    return api.get(url);
  }

  downloadInvoice = async (id: any) => {
    try {
      const response = await api.get(`/quick-service/invoice/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  checkServiceExist = async (queryParams: any) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const response = await api.get(`/quick-service/check-exist?${query}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  deleteService = async (id: any) => {
    try {
      const response = await api.delete(`/quick-service/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new QuickService();
