/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Card, Col, Row, Typography,
} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, RenderItem } from '..';
import map from '../../Assets/Images/map.svg';

interface IClientCountCard {
    showCard: boolean
    isLoading: boolean
    route: any
    thisMonth: number
    total: number
}

function ClientCountCard(props: IClientCountCard) {
  const {
    showCard, isLoading, route, thisMonth, total,
  } = props;

  return (
    <RenderItem isTrue={showCard}>
      <Card bordered={false} className="dash_card_resp_fnt ms-0" loading={isLoading}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography.Title level={3} className="r-bold">Clients</Typography.Title>
          <Link to={route} className="d-flex ml-auto r-bold align-items-center p-0 link_text secondary">
            View
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle secondary_bg">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Link>
        </div>
        <Row gutter={16} className="align-items-center justify-content-between mt-4 map_wrapper">
          <Col lg={16} md={16} sm={16} xs={24} className="mb-5 mb-sm-0 h-100">
            <img className="w-100 h-100" src={map} alt="map" />
          </Col>
          <Col lg={7} md={7} sm={7} xs={24}>
            <Typography.Title className="text-uppercase r-medium secondary" level={5}>This Month</Typography.Title>
            <Typography.Title className="r-bold green mt-0" level={1}>{thisMonth}</Typography.Title>
            <Typography.Title className="text-uppercase r-medium secondary" level={5}>Total</Typography.Title>
            <Typography.Title className="r-bold primary mt-0" level={1}>{total}</Typography.Title>
          </Col>
        </Row>
      </Card>
    </RenderItem>
  );
}

export default ClientCountCard;
