/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, {
  useEffect, useState, Suspense, lazy,
} from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Layout,
  Row,
  Skeleton,
} from 'antd';
import { useHistory } from 'react-router-dom';
import './Dashboard.less';
import {
  ClientCountCard,
  Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';

const RequestCountCard = lazy(() => import('../../Components/RequestCountCard'));
const PlanCountCard = lazy(() => import('../../Components/PlanCountCard'));
const FacilityCount = lazy(() => import('../../Components/FacilityCount'));
const { EXPIRING } = Constant.contractTypes;

const { colors } = Constant;

function Dashboard(props: any) {
  const { dashboardStore, authStore, tenantStore } = props;
  const { currentUser } = authStore;
  const { tenantAccess } = tenantStore;
  const isSuperAdmin = currentUser?.access?.includes(Constant?.userAccess.SUPER_ADMIN);
  const history = useHistory();
  const [facilityPage, setFacilityPage] = useState(1);

  const goToFacilityDetails = () => history.push(`${Routes.facility_detail}/${facility._id}`);
  const gotoApprovalPage = () => history.push(Routes.approval);
  const gotoWaitingApprovalPage = () => history.push(`${Routes.approval}?waiting=true`);
  const gotoExpiringPlanPage = () => history.push(`${Routes.contracts}?tab=${EXPIRING}`);
  const gotoServiceRequestPage = () => history.push(Routes.request);

  useEffect(() => {
    if (isSuperAdmin) {
      setIsLoading(true);
      dashboardStore.getFacilityDetails({ page: facilityPage, limit: 1 });
      setIsLoading(false);
    }
  }, [facilityPage]);
  const facility: any = dashboardStore?.facility?.facility?.length
  && dashboardStore?.facility?.facility[0];

  const facilityTotal : any = dashboardStore?.facility?.total;

  const showNextFacility = () => {
    if (facilityPage + 1 > facilityTotal) setFacilityPage(1);
    else setFacilityPage(facilityPage + 1);
  };

  const showPrevFacility = () => {
    if (facilityPage - 1 < 1) setFacilityPage(facilityTotal);
    else setFacilityPage(facilityPage - 1);
  };
  const expiredPlanCount = dashboardStore?.expiredPlans?.count;
  const expiringPlanCount = dashboardStore?.expiringPlans?.count;
  const {
    planReqCount, employeeReqCount, clientCount, serviceReqCount, waitingReqCount,
    planReqUrgentCount, serviceReqUrgentCount, employeeReqUrgentCount,
  } = dashboardStore;
  const [isLoading, setIsLoading] = useState(false);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      const queryParams: any = {
        reqQuery: Constant.requestStatus.OPEN,
        expiredQuery: { expired: true, limit: 3, status: 'APPROVED' },
        expiringQuery: { expiring: true, limit: 3, status: 'APPROVED' },
      };
      if (isSuperAdmin) {
        queryParams.waitingQuery = Constant.requestStatus.PENDING;
        await dashboardStore.getDashboardData(queryParams);
      } else {
        await dashboardStore.getEmployeeDashboardData(queryParams);
      }
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const totalClient = clientCount?.count?.map(
    (item: any) => item?.count,
  ).reduce((prev: any, curr:any) => prev + curr, 0);

  useEffect(() => {
    getDashboardData();
  }, []);

  const planRequestColor = planReqUrgentCount ? colors.red : colors.yellow;
  const serviceRequestColor = serviceReqUrgentCount ? colors.red : colors.yellow;
  const employeeReqUrgentColor = employeeReqUrgentCount ? colors.red : colors.yellow;

  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col md={24} lg={24} sm={24} xs={24}>
          <Row gutter={[16, 16]}>
            { isSuperAdmin && (
            <Col
              xxl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              xl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              lg={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              md={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              sm={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              xs={24}
            >
              <Suspense fallback={<Skeleton />}>
                <RequestCountCard
                  title="Plan Requests"
                  count={planReqCount && `${planReqCount}`.padStart(2, '0')}
                  color={planReqCount ? colors.white : colors.green}
                  boxColor={planReqCount ? planRequestColor : colors.white}
                  textColor={planReqCount ? colors.white : colors.black}
                  buttonColor={planReqCount ? colors.white : colors.darkGrey}
                  loading={isLoading}
                  routeHandler={gotoApprovalPage}
                  key="Plan Requests"
                />
              </Suspense>
            </Col>
            )}
            { isSuperAdmin && (
            <Col
              xxl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              xl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              lg={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              md={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              sm={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              xs={24}
            >
              <Suspense fallback={<Skeleton />}>
                <RequestCountCard
                  title="Waiting List"
                  count={waitingReqCount && `${waitingReqCount}`.padStart(2, '0')}
                  color={colors.yellow}
                  boxColor={colors.white}
                  textColor={colors.black}
                  loading={isLoading}
                  routeHandler={gotoWaitingApprovalPage}
                  key="Waiting List"
                />
              </Suspense>
            </Col>
            )}
            { tenantAccess?.includes('SERVICE_MANAGEMENT') && (
            <Col
              xxl={isSuperAdmin ? 6 : 8}
              xl={isSuperAdmin ? 6 : 8}
              lg={isSuperAdmin ? 6 : 8}
              md={12}
              sm={isSuperAdmin ? 12 : 24}
              xs={24}
            >
              <Suspense fallback={<Skeleton />}>
                <RequestCountCard
                  isSuperAdmin={isSuperAdmin}
                  title="Service Requests"
                  count={serviceReqCount && `${serviceReqCount}`.padStart(2, '0')}
                  color={serviceReqCount && isSuperAdmin ? colors.white : colors.blue}
                  boxColor={serviceReqCount && isSuperAdmin ? serviceRequestColor : colors.white}
                  buttonColor={serviceReqCount && isSuperAdmin ? colors.white : colors.darkGrey}
                  textColor={serviceReqCount && isSuperAdmin ? colors.white : colors.black}
                  loading={isLoading}
                  routeHandler={gotoServiceRequestPage}
                  isViewButtonDisabled={!tenantAccess?.includes('SERVICE_MANAGEMENT')}
                  key="Service Requests"
                />

              </Suspense>
            </Col>
            )}
            { isSuperAdmin && (
            <Col
              xxl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              xl={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              lg={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 6 : 8}
              md={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              sm={tenantAccess?.includes('SERVICE_MANAGEMENT') ? 12 : 8}
              xs={24}
            >
              <Suspense fallback={<Skeleton />}>
                <RequestCountCard
                  title="Employee Requests"
                  count={employeeReqCount && `${employeeReqCount}`.padStart(2, '0')}
                  color={employeeReqCount ? colors.white : colors.grey}
                  boxColor={employeeReqCount ? employeeReqUrgentColor : colors.white}
                  textColor={employeeReqCount ? colors.white : colors.black}
                  buttonColor={employeeReqCount ? colors.white : colors.darkGrey}
                  loading={isLoading}
                  routeHandler={gotoApprovalPage}
                  key="Employee Requests"
                />
              </Suspense>
            </Col>
            )}
            {isSuperAdmin && (
            <Col lg={16} md={16} sm={24} xs={24}>
              <ClientCountCard
                isLoading={isLoading}
                route={Routes.clients}
                showCard={isSuperAdmin}
                thisMonth={clientCount?.thisMonth}
                total={totalClient}
              />
            </Col>
            )}
            { isSuperAdmin && (
            <Col lg={8} md={8} sm={24} xs={24}>
              <div className="box_shadow border-radius bg-white h-100">
                <Suspense fallback={<Skeleton />}>
                  <FacilityCount
                    nextItem={showNextFacility}
                    prevItem={showPrevFacility}
                    capacity={facility?.total_capacity}
                    totalFacilityCount={dashboardStore?.facility?.total}
                    index={facilityPage}
                    cardName="Facility Capacity"
                    location={facility?.address}
                    name={facility?.name}
                    routeHandler={goToFacilityDetails}
                    loading={isLoading}
                    usedCapacity={facility?.used_capacity ? facility?.used_capacity : 0}
                    key="Facility Capacity"
                  />
                </Suspense>
              </div>
            </Col>
            )}
            <Col lg={isSuperAdmin ? 12 : 8} md={12} sm={24} xs={24}>
              <Suspense fallback={<Skeleton />}>
                <PlanCountCard
                  color="#ff4141"
                  routeHandler={gotoExpiringPlanPage}
                  title="Expired Plans"
                  count={expiredPlanCount && `${expiredPlanCount}`.padStart(2, '0')}
                  plans={dashboardStore?.expiredPlans?.contracts}
                  isLoading={isLoading}
                  key="Expired Plans"
                  isSuperAdmin={isSuperAdmin}
                />
              </Suspense>
            </Col>
            <Col lg={isSuperAdmin ? 12 : 8} md={12} sm={24} xs={24}>
              <Suspense fallback={<Skeleton />}>
                <PlanCountCard
                  color="#f1aa1d"
                  routeHandler={gotoExpiringPlanPage}
                  title="Expiring Plans"
                  count={expiringPlanCount && `${expiringPlanCount}`.padStart(2, '0')}
                  plans={dashboardStore?.expiringPlans?.contracts}
                  isLoading={isLoading}
                  key="Expiring Plans"
                  isSuperAdmin={isSuperAdmin}
                />
              </Suspense>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default inject('dashboardStore', 'authStore', 'tenantStore')(observer(Dashboard));
