/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, makeObservable, runInAction, action,
} from 'mobx';
import Constant from '../Global/Constant';
import {
  Category, Client, GetOrdersQuery, ListOrderActivitiesQuery,
  Order, OrderQueryParams, OrderRequest, Product, updateOrderStatus, Variants,
} from '../Global/interface';
import orderService from '../Services/orderService';

export interface IOrderStore {
      orders: Array<Order>
      requests: Array<OrderRequest>
      request: OrderRequest
      order: Order
      total: number
      customer: Client
      category: Category
      product: Product
      variant: Variants
      activities: any
      getOrders: (queryParams: GetOrdersQuery) => Promise<any>
      createOrder: (order: Order) => any
      getOrderById: (orderId: string) => any
      getCancelRequestById: (requestId: string) => any
      getReturnRequestById: (requestId: string) => any
      purchaseOrder: (orderId: string, data: any) => any
      updateOrderStatus: (orderId: string, data: updateOrderStatus) => Promise<any>
      updateCancelRequestStatus: (requestId: string, data: updateOrderStatus) => Promise<any>
      updateReturnStatus: (requestId: string, data: updateOrderStatus) => Promise<any>
      rescheduleOrder: (requestId: string, data: updateOrderStatus) => Promise<any>
      uploadFile: (formData: any) => any
      listOrderActivities: (queryParams: ListOrderActivitiesQuery) => Promise<any>
      createCancelOrderRequest: (orderId: string) => Promise<any>
      createReturnOrderRequest: (orderId: string, params: any) => Promise<any>
      getCancelOrderRequests: (queryParams: any) => any
      getReturnOrderRequests: (queryParams: any) => any
      deleteOrder: (orderId: string) => Promise<any>
      downloadInvoice: (orderId: string) => Promise<any>
      createQuickOrder: (orderItems: any) => Promise<any>
      initialize: Function
}

class OrderStore {
         orders: Order | null = null

         order: Order | null = null

         requests: Array<OrderRequest> | null = null;

         request: OrderRequest| null = null;

         customer: Client | null = null

         category: Category | null = null

         product: Product | null = null

         variant: Variants | null = null

         activities: any = null

         total: number = 0

         constructor() {
           makeObservable(this, {
             orders: observable,
             order: observable,
             total: observable,
             requests: observable,
             request: observable,
             customer: observable,
             category: observable,
             product: observable,
             variant: observable,
             activities: observable,
             getOrders: action,
             getCancelOrderRequests: action,
             getReturnOrderRequests: action,
             createOrder: action,
             getOrderById: action,
             purchaseOrder: action,
             updateOrderStatus: action,
             updateCancelRequestStatus: action,
             listOrderActivities: action,
             createCancelOrderRequest: action,
             createReturnOrderRequest: action,
             deleteOrder: action,
             downloadInvoice: action,
             createQuickOrder: action,
           });
           this.initialize();
         }

          initialize = () => {
            this.orders = null;
            this.total = 0;
            this.requests = null;
            this.request = null;
            this.customer = null;
            this.activities = null;
            this.category = null;
            this.product = null;
            this.variant = null;
          }

        getOrders = async (queryParams: OrderQueryParams) => {
          try {
            const response: any = await orderService.getOrders(queryParams);
            if (response && response?.data) {
              runInAction(() => {
                this.orders = response?.data?.orders;
                this.total = response?.data?.total;
              });
            }
            return (null);
          } catch (error : any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        getCancelOrderRequests = async (queryParams: OrderQueryParams) => {
          try {
            const response: any = await orderService.getCancelOrderRequests(queryParams);
            if (response && response?.data) {
              runInAction(() => {
                this.requests = response?.data?.cancelOrders;
                this.total = response?.data?.total;
              });
            }
            return (null);
          } catch (error : any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        getReturnOrderRequests = async (queryParams: OrderQueryParams) => {
          try {
            const response: any = await orderService.getReturnOrderRequests(queryParams);
            if (response && response?.data) {
              runInAction(() => {
                this.requests = response?.data?.returnOrders;
                this.total = response?.data?.total;
              });
            }
            return (null);
          } catch (error : any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        createOrder = async (order: any) => {
          try {
            await orderService.createOrder(order);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        getOrderById = async (orderId: string) => {
          try {
            const response: any = await orderService.getOrderById(orderId);
            this.order = response?.data?.order;
            this.category = response?.data?.order?.category;
            this.product = response?.data?.order?.product;
            this.variant = response?.data?.order?.variant;
            this.customer = response?.data?.order?.customer;
            return null;
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        getCancelRequestById = async (requestId: string) => {
          try {
            const response: any = await orderService.getCancelRequestById(requestId);
            const cancelOrder = response?.data?.cancelOrder;
            runInAction(() => {
              this.request = cancelOrder;
              this.order = cancelOrder?.order;
              this.category = cancelOrder?.category;
              this.product = cancelOrder?.product;
              this.variant = cancelOrder?.variant;
              this.customer = cancelOrder?.customer;
            });
            return null;
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        getReturnRequestById = async (requestId: string) => {
          try {
            const response: any = await orderService.getReturnRequestById(requestId);
            const returnOrder = response?.data?.returnOrder;
            runInAction(() => {
              this.request = returnOrder;
              this.order = returnOrder?.order;
              this.category = returnOrder?.category;
              this.product = returnOrder?.product;
              this.variant = returnOrder?.variant;
              this.customer = returnOrder?.customer;
            });
            return null;
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        purchaseOrder = async (orderId: string, data: any) => {
          try {
            await orderService.purchaseOrder(orderId, data);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        updateOrderStatus = async (orderId: string, data: any) => {
          try {
            const response = await orderService.updateOrderStatus(orderId, data);
            if (this.order) this.order.order_status = response?.data?.order_status;
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        updateCancelRequestStatus = async (requestId: string, data: any) => {
          try {
            await orderService.updateCancelRequestStatus(requestId, data);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        updateReturnStatus = async (requestId: string, data: any) => {
          try {
            await orderService.updateReturnStatus(requestId, data);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        rescheduleOrder = async (requestId: string, data: any) => {
          try {
            await orderService.rescheduleOrder(requestId, data);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        uploadFile = async (formData: any) => {
          try {
            const response = await orderService.uploadFile(formData);
            return Promise.resolve(response?.data);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        listOrderActivities = async (queryParams: any) => {
          try {
            const response = await orderService.listOrderActivities(queryParams);
            this.activities = response?.data?.activities;
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        };

        createCancelOrderRequest = async (orderId: string) => {
          try {
            await orderService.createCancelOrderRequest(orderId);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        createReturnOrderRequest = async (orderId: string, params: any) => {
          try {
            await orderService.createReturnOrderRequest(orderId, params);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        deleteOrder = async (orderId: string) => {
          try {
            await orderService.deleteOrder(orderId);
            return (null);
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        downloadInvoice = async (orderId: string) => {
          try {
            const response = await orderService.downloadInvoice(orderId);
            return Promise.resolve(response?.data?.url);
          } catch (error : any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }

        createQuickOrder = async (orderItem: any) => {
          try {
            const response = await orderService.createQuickOrder(orderItem);
            return response?.data;
          } catch (error: any) {
            return Promise.reject(
              error?.response?.data?.error?.message || Constant.defaultErrorMessage,
            );
          }
        }
}

export default new OrderStore();
