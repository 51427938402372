/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import {
  Card,
  Col,
  Divider,
  Form,
  Layout,
  Row,
  Spin,
  Typography,
  Input,
  Radio,
  Button,
  Tooltip,
  Breadcrumb,
  Dropdown,
  Menu,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  ConfirmModal, Counter, Icon, RenderItem, ServiceIcon, Toast,
} from '../../Components';
import rules from './createPlan.validation';
import Utility from '../../Global/Utility';
import CreateTermModal from './CreateTermModal';
import AddServiceModal from './AddServiceModal';
import { MasterStoreProps } from '../../Store/masterStore';
import { IPlanStore } from '../../Store/planStore';
import { PlanTerm } from '../../Global/interface';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import { IServiceStore } from '../../Store/serviceRequestStore';
import ticket from '../../Assets/Images/ticket.svg';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface CreatePlanProps {
  masterStore: MasterStoreProps
  planStore: IPlanStore
  serviceStore: IServiceStore
}

function CreatePlan(props: CreatePlanProps) {
  const { masterStore, planStore, serviceStore } = props;

  const [createPlanForm] = useForm();

  const [showAddPlanTermModal, setShowAddPlanTermModal] = useState(false);
  const [editTermDetails, setEditTermDetails] = useState<PlanTerm|null>(null);
  const [planType, setPlanType] = useState<string>('PARKING_SERVICE');
  const [planDetails, setPlanDetails] = useState<any>();
  const [planTerms, setPlanTerms] = useState<Array<PlanTerm>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [visitCount, setVisitCount] = useState<number>();
  const [serviceList, setServiceList] = useState<any>([]);
  const [serviceShow, setServiceShow] = useState<any>([]);

  const { id } = useParams<any>();
  const history = useHistory();

  const isParkingPlan = planType === 'PARKING_SERVICE';

  const visitCountChangeHandler = (count: number) => {
    setVisitCount(count);
    createPlanForm.setFieldsValue({
      visit_count: count,
    });
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      masterStore.getFacilities();
      setIsLoading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  }, []);

  const getServices = async (query: any) => {
    setIsLoading(true);
    try {
      await serviceStore?.getServices(query);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getServices({ active: true });
  }, []);

  useEffect(() => {
    setServiceList(serviceStore?.services?.map((service: any) => service));
  }, [serviceStore?.services]);

  const populatePlanDetails = async () => {
    try {
      setIsLoading(true);
      await planStore?.getPlanById(id);
      setVisitCount(planStore?.plan?.visit_count);
      setPlanDetails(planStore?.plan);
      setPlanTerms(planStore?.planTerms);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  useEffect(() => {
    if (id) populatePlanDetails();
  }, [id]);

  useEffect(() => {
    if (planDetails) {
      const serviceType = planDetails?.plan_type === 'VAULT' ? 'PARKING_SERVICE' : 'HOME_SERVICE';
      createPlanForm.setFieldsValue({
        plan: planDetails?.plan,
        plan_type: serviceType,
        visit_count: planDetails?.visit_count,
      });
      setPlanType(serviceType);
    }
  }, [planDetails]);

  const showAddPlanTermModalHandler = () => setShowAddPlanTermModal(true);

  const handleModelClose = () => {
    setShowAddPlanTermModal(false);
    setEditTermDetails(null);
  };

  const addPlanTerm = (values: any) => {
    const tempPlan = [...planTerms];
    tempPlan.push(values);
    setPlanTerms(tempPlan);
    handleModelClose();
  };

  const editPlanTerm = (termDetails: PlanTerm) => {
    setEditTermDetails(termDetails);
    setShowAddPlanTermModal(true);
  };

  const updatePlanTerm = (values: any) => {
    const tempPlan = planTerms.map((item: any) => {
      if (item?.facility?._id === values?.facility?._id) {
        return values;
      }
      return item;
    });
    setPlanTerms(tempPlan);
    handleModelClose();
  };

  const handleTypeChange = (e: any) => {
    setPlanType(e?.target?.value);
    setEditTermDetails(null);
    setPlanTerms([]);
  };

  const onFinishPlan = (values: any) => {
    const planTermFinal: any = [];
    planTerms.forEach((item: any) => {
      item?.termDetails?.forEach((termDetail: any) => {
        planTermFinal.push({
          facility: item?.facility?._id,
          ...termDetail,
        });
      });
    });
    if (planType === 'HOME_SERVICE' && !visitCount) return Toast.error('Visit count is required.');
    if (!serviceShow.length) return Toast?.error('Please add atleast 1 plan service.');

    if (planTermFinal?.length) {
      const data = {
        plan: values?.plan,
        plan_type: planType,
        plan_term: planTermFinal,
        visit_count: visitCount,
        features: serviceShow?.map((serviceInfo: any) => (
          { feature: serviceInfo?.service, free_service: serviceInfo?.free_service })),
      };
      if (id) {
        updatePlan(data);
      } else {
        createPlan(data);
      }
    } else {
      Toast?.error('Please add atleast 1 plan term');
    }
  };

  const createPlan = async (data: any) => {
    try {
      setIsLoading(true);
      await planStore?.createPlan(data);
      gotoPlanListing();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const updatePlan = async (data: any) => {
    try {
      setIsLoading(true);
      await planStore?.updatePlan(id, data);
      setIsLoading(false);
      gotoPlanListing();
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Plan', 'Do you want to delete this plan?', deletePlan);
  };

  const deletePlan = async () => {
    try {
      setIsLoading(true);
      await planStore?.deletePlan(id);
      setIsLoading(false);
      gotoPlanListing();
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const removeTermFromList = (selectedTerm: any) => setPlanTerms(planTerms?.filter(
    (item: any) => item !== selectedTerm,
  ));

  const gotoPlanListing = () => history?.push(Routes?.plan_settings);

  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);

  const showModal = () => {
    setIsServiceModalVisible(true);
  };

  const handleCancel = () => {
    setIsServiceModalVisible(false);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0" onClick={viewDeleteConfirmModal}>
          Delete Plan
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (serviceList) { setServiceDataDetails(); }
  }, [serviceList, planDetails]);

  const setServiceDataDetails = () => {
    const selectedServices = serviceList?.filter(
      (serviceDetail: any) => planDetails?.features?.find(
        (detail: any) => detail?.feature === serviceDetail?.service,
      ),
    );

    const showData = selectedServices?.map((item: any) => {
      const existingService = planDetails?.features?.find(
        (detail: any) => detail?.feature === item?.service,
      );

      return {
        service: item?.service,
        free_service: !!existingService?.free_service || item?.price <= 0,
        service_checked: true,
        price: item?.price,
      };
    });
    setServiceShow(showData);
  };

  const onFinishService = (servicesToUpdate: any) => {
    setServiceShow(servicesToUpdate?.filter(
      (serviceDetail: any) => serviceDetail?.service_checked,
    ));
    setIsServiceModalVisible(false);
  };

  return (
    <Layout.Content>
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.plan_settings}>PLANS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {id ? 'Update Plan' : 'Create Plan'}
                </Breadcrumb.Item>
              </Breadcrumb>
              {id && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              ) }
            </div>
            <Typography.Title level={1} className="mb-4 fs-xs-24">
              {id ? 'Update Plan' : 'Create Plan'}
            </Typography.Title>
            <Card className="create_card">
              <Form layout="vertical" initialValues={{ plan_type: 'PARKING_SERVICE' }} onFinish={onFinishPlan} form={createPlanForm} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>Plan name</Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="mb-3 d-block create_para">Enter the plan name</Typography.Text>
                    <Row>
                      <Col lg={8} sm={20} xs={24}>
                        <Form.Item
                          label="PLAN NAME"
                          name="plan"
                          className="mandatory mb-0"
                          required={false}
                          normalize={Utility.trimSpacesInValues}
                          rules={rules.plan}
                        >
                          <Input placeholder="Enter plan name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="border-top mt-3 mb-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>Plan Type</Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="mb-3 d-block create_para">Choose the type of plan</Typography.Text>
                    <Row>
                      <Col md={24} sm={24} xs={24} lg={24}>
                        <div className="d-md-flex flex-wrap">
                          <Form.Item name="plan_type" className="mandatory mb-0">
                            <Radio.Group className="cust_radio_btn" onChange={handleTypeChange} disabled={id}>
                              <Radio className="radio_inp" value="PARKING_SERVICE">Parking Service</Radio>
                              <Radio className="radio_inp" value="HOME_SERVICE">Home Service</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <RenderItem isTrue={!isParkingPlan}>
                            <div className="position-relative plan_type_counter ms-md-4 d-flex flex-wrap align-items-center">
                              <Counter
                                name="visit_count"
                                rule={rules.visit_count}
                                defaultValue={visitCount}
                                maxLength={2}
                                min={0}
                                changeHandler={visitCountChangeHandler}
                              />
                              <Typography.Text className="ms-3 visit_text">Visit per month</Typography.Text>
                            </div>
                          </RenderItem>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="border-top mt-3 mb-3" />
                  </Col>
                </Row>

                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>Plan Terms</Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="d-block create_para">Add the plan terms</Typography.Text>
                      {!(!isParkingPlan && planTerms?.length)
                      && (
                      <Button className="m-0 border-0 bg-transparent h-max d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={showAddPlanTermModalHandler}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                      )}
                    </div>
                    {
                  planTerms.map((terms: any) => (
                    <Card className="off-white-bg my-2 shadow-none ticket_card">
                      <div className="text-capitalize d-flex justify-content-between mb-2">
                        <Typography.Text className="r-bold">
                          { isParkingPlan ? `Plan Term - ${terms?.facility?.name} - ${terms?.facility?.address}` : '' }
                        </Typography.Text>
                        <Button onClick={() => editPlanTerm(terms)} className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-max">
                          Edit
                          <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Button>
                      </div>
                      <div className="d-flex flex-wrap">
                        {
                        terms?.termDetails?.map((facilityDetails:any) => (
                          <Card className="shadow-none plan_ticket me-2 mb-2 bg-transparent" bodyStyle={{ padding: 12 }}>
                            <img src={ticket} alt="ticketimage" className="position-absolute top-0 start-0" />
                            <Typography.Title level={3} className="text-capitalize mb-0 white position-relative text-truncate">
                              {`${Utility?.reverseDuration(facilityDetails?.duration, facilityDetails?.duration_type)} ${Constant?.durationTypeName?.get(facilityDetails?.duration_type)}`}
                            </Typography.Title>
                            <Typography.Text className="white position-relative">
                              {facilityDetails?.price} KD
                            </Typography.Text>
                          </Card>
                        ))
                      }
                      </div>
                    </Card>
                  ))
                }
                    <Divider className="border-top mt-3 mb-3" />
                  </Col>
                </Row>

                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>Plan Services</Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="mb-3 d-block create_para">Add the plan services</Typography.Text>
                      <RenderItem isTrue={!serviceShow?.length}>
                        <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-max" onClick={showModal}>
                          Add
                          <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Button>
                      </RenderItem>
                    </div>
                    <RenderItem isTrue={serviceShow?.length}>
                      <Card className="off-white-bg mb-2 shadow-none" bodyStyle={{ padding: 20 }}>
                        <div className="mb-2 text-capitalize d-flex justify-content-between">
                          <Typography.Text className="r-bold">Plan Services</Typography.Text>
                          <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-max" onClick={showModal}>
                            Edit
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                        </div>
                        <div className="d-flex flex-wrap">
                          {serviceShow?.map((
                            item: any,
                          ) => <ServiceIcon service={item?.service} />)}
                        </div>
                      </Card>
                    </RenderItem>
                    <Divider className="border-top mt-3 mb-3" />
                    <div className="d-flex mt-4 justify-content-right">
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        {id ? 'Update Plan' : 'Create Plan'}
                      </Button>
                      <Button type="text" className="me-3 fs-16 r-bold cancel_btn" onClick={gotoPlanListing}>
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{id ? 'Update plan' : 'Set new plan'}</Paragraph>
            </Typography>
            {id && (
            <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              <Tooltip title="Delete Plan">
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto" onClick={viewDeleteConfirmModal}>
                  Delete Plan
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            )}
          </Sider>
        </Row>
      </Spin>
      <CreateTermModal
        isModalVisible={showAddPlanTermModal}
        onClose={handleModelClose}
        handleOnCreate={(values: any) => addPlanTerm(values)}
        handleOnUpdate={(values: any) => updatePlanTerm(values)}
        planType={planType}
        facilities={masterStore.facilities}
        choosenTermDetails={editTermDetails}
        existingTerms={planTerms}
        removeTerm={removeTermFromList}
        planId={id}
      />
      <AddServiceModal
        handleCancel={handleCancel}
        isModalVisible={isServiceModalVisible}
        serviceList={serviceList}
        planFeatures={serviceShow}
        isEditMode={serviceShow?.length}
        onFinishService={onFinishService}
      />
    </Layout.Content>
  );
}

export default inject('masterStore', 'planStore', 'serviceStore')(observer(CreatePlan));
