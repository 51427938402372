/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Col, Form, Row, Modal, Button, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Icon, SelectServiceBox } from '../../Components';
import { Features } from '../../Global/interface';

interface Props {
   isModalVisible: boolean,
   handleCancel: () => any,
   serviceList: Array<any>,
   planFeatures: Array<Features>,
   isEditMode: boolean,
   onFinishService: any
}

function AddServiceModal(props: Props) {
  const {
    isModalVisible, handleCancel, serviceList, planFeatures, isEditMode, onFinishService,
  } = props;

  const [serviceData, setServiceData] = useState<any>([]);

  const setServiceDetails = () => {
    setServiceData(serviceList?.map((listDetail: any) => {
      const serviceDetails: any = planFeatures?.find(
        (planDetails: any) => planDetails?.service === listDetail?.service,
      );
      return {
        service: listDetail?.service,
        service_checked: !!serviceDetails,
        free_service: !!serviceDetails?.free_service || listDetail?.price <= 0,
        price: listDetail?.price,
      };
    }));
  };

  useEffect(() => {
    setServiceDetails();
  }, [serviceList, planFeatures]);

  const closeModal = () => {
    setServiceDetails();
    handleCancel();
  };

  const [form] = useForm();

  const handleServiceCheck = (service: string, isChecked: boolean) => {
    setServiceData(serviceData?.map((serviceDetail: any) => {
      if (serviceDetail?.service === service) {
        serviceDetail.service_checked = isChecked;
      }
      return serviceDetail;
    }));
  };
  const handleFreeCheck = (service: string, isChecked: boolean) => {
    setServiceData(serviceData?.map((serviceDetail: any) => {
      if (serviceDetail?.service === service) {
        serviceDetail.free_service = isChecked;
      }
      return serviceDetail;
    }));
  };

  const submitValue = () => {
    onFinishService(serviceData);
  };

  return (
    <Modal
      title="Manage Plan Services"
      centered
      footer={null}
      className="cmn_modal_wrapper"
      visible={isModalVisible}
      onCancel={closeModal}
      destroyOnClose
      width={800}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={closeModal}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                        )}
    >
      <Form layout="vertical" form={form} onFinish={submitValue}>
        <Typography.Title level={5} className="r-medium secondary fs-14">CHOOSE PLAN SERVICES</Typography.Title>
        <Row gutter={8}>
          {serviceData?.map((serviceDetails: any) => (
            <Col xs={24} sm={12} md={8} lg={6}>
              <SelectServiceBox
                service={serviceDetails?.service}
                serviceChecked={serviceDetails?.service_checked as boolean}
                freeChecked={serviceDetails?.free_service as boolean}
                handleServiceCheck={handleServiceCheck}
                handleFreeCheck={handleFreeCheck}
                price={serviceDetails?.price}
              />
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-between">
          <div className="d-flex mt-4 justify-content-right">
            <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
              {isEditMode ? 'Update' : 'Add' }
            </Button>
            <Button type="text" className="cancel_btn fs-16 r-bold" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default AddServiceModal;
