/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Typography,
} from 'antd';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;

interface IServiceList{
  service: string
    price: number
    unitPrice: number
    value: any
    requestedInfo: any
}

function ServiceList(props: IServiceList) {
  const {
    service, price, unitPrice, value, requestedInfo,
  } = props;

  const isFuelFill = service === Constant.services.FUEL_FILL;
  const isTirePressure = service === Constant.services.TIRE_PRESSURE_CHECK;

  const filledPrice = isFuelFill ? unitPrice * value : 0;
  const totalAmount = price + filledPrice;

  const getTitle = () => {
    const serviceText = Constant.SERVICES.get(service) as string;
    if (isFuelFill) {
      const fuelLevel = Utility.getFuelLevel(requestedInfo?.requested_fuel_level);
      const fuelFillDetails = `${Constant.FUEL_FILL_TYPE.get(requestedInfo?.requested_fuel_type)} - ${fuelLevel} - ${value}L`;
      return `${serviceText} (${fuelFillDetails})`;
    }
    if (isTirePressure) {
      const tirePrText = requestedInfo !== 'REGULAR' ? '(custom)' : '';
      return `${serviceText}${tirePrText}`;
    }
    return serviceText;
  };
  const title = getTitle();

  return (
    <Typography className="d-flex justify-content-between align-items-center border-bottom mb-2 pb-2">
      <Title level={5} className="primary r-regular m-0">{title}</Title>
      <Paragraph className="primary m-0">{totalAmount} KD</Paragraph>
    </Typography>
  );
}

export default ServiceList;
