/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import {
  Layout,
  Form,
  Input,
  Button,
  Typography,
  Tooltip,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Footer } from 'antd/lib/layout/layout';

import { inject, observer } from 'mobx-react';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';

import './ForgotPassword.less';
import Toast from '../../Components/Toast';
import Utility from '../../Global/Utility';
import { Icon } from '../../Components';
import { ITenantStore } from '../../Store/tenantStore';

interface IForgotPassword {
  tenantStore: ITenantStore
}

function ForgotPassword(props: IForgotPassword) {
  const { tenantStore } = props;

  const [loading, setLoading] = useState(false);
  const [emailSend, setEmailSend] = useState(false);
  const history = useHistory();
  const sendEmailHandler = (values: any) => {
    setLoading(true);
    authStore.forgotPasswordRequest(values?.email, (err?: any) => {
      setLoading(false);
      if (err) {
        Toast.error(err);
        return;
      }
      setEmailSend(true);
    });
  };

  const returnHandler = () => {
    history.push(Routes.login);
  };
  return (
    <Layout.Content id="forgot-password" className="d-sm-flex align-items-center">
      <div className="login-box forgot_wrapper w-100">
        <div className="text-center mb-5">
          <img src={tenantStore?.tenantLogo} style={{ marginBottom: '20px' }} width="80px" alt={tenantStore?.tenantName} />
        </div>
        <Typography.Title className="mb-3" level={4}>
          Forgot Password
        </Typography.Title>
        <div className="">
          {
            !emailSend ? (
              <Form layout="vertical" className="login-form forgot_form position-relative m-0" onFinish={sendEmailHandler} validateTrigger={['onBlur', 'onSubmit', 'onChange']}>
                <Form.Item
                  label="EMAIL"
                  name="email"
                  className="mb-2"
                  normalize={Utility.trimSpacesInValues}
                  rules={[
                    {
                      type: 'email',
                      message: Constant.emailValidationError,
                    },
                    {
                      required: true,
                      message: Constant.emailRequiredValidationError,
                    },
                  ]}
                >
                  <Input placeholder="Enter email address" className="email_inp_wrap login_input email_input" allowClear />
                </Form.Item>
                <div className="position-absolute email_icons d-flex">
                  <Tooltip title="Submit">
                    <Button className="login_arw forgot_arrow d-flex align-items-center justify-content-center text-center p-0" type="primary" shape="circle" htmlType="submit" loading={loading} block>
                      <Icon className="icon-10" iconRef="#arrow_right" />
                    </Button>
                  </Tooltip>
                </div>
                <Form.Item className="forg_pass_content">
                  A password reset link will be sent to this email for resetting the password
                </Form.Item>
                <Form.Item className="forg_pass">
                  <Link to={Routes.login} className="d-flex ml-auto mb-3 login-form-forgot r-bold align-items-center">
                    Sign In
                    <div className="forgot_arw text-white ms-2 d-flex justify-content-center align-items-center text-center p-0 rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Link>
                </Form.Item>
              </Form>
            )
              : (
                <div className="">
                  <Typography.Paragraph className="font-weight-bold mb-3">
                    Check your email for a link to reset your password.
                  </Typography.Paragraph>
                  <Button type="primary" className="w-100" onClick={returnHandler}>
                    Return to login
                  </Button>
                </div>
              )
          }
        </div>
      </div>
      <Footer className="text-center">VMS Portal • Copyright © 2021 • <a href={Routes.dashboard}>Terms And Conditions</a> • <a href="/privacyPolicy.html">Privacy Policy</a></Footer>
    </Layout.Content>
  );
}

export default inject('tenantStore')(observer(ForgotPassword));
