/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  name: [{
    required: true,
    message: constant.nameFieldRequired,
  }, {
    type: 'string',
    pattern: /^[a-zA-Z0-9 -!@$%&*()+.,]*[a-zA-Z][a-zA-Z0-9 -!@$%&*()+.,]*$/,
    message: constant.specialCharectorError,
  }],
  description: [{
    required: true,
    message: constant.descriptionRequired,
  }, {
    type: 'string',
    pattern: /([A-Za-z])/,
    message: constant.invalidDescription,
  }],
  code: [{
    required: true,
    message: constant.codeRequired,
  }],
  vertical_image: [{
    required: true,
    message: constant.verticalImageRequired,
  }],
  horizontal_image: [{
    required: true,
    message: constant.horizontalImageRequired,
  }],
};

export default rule;
