/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Col,
  Radio,
  Row,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Action, Icon, RenderItem, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

interface ServiceAttachmentProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  checkboxHandler: (value: any, service: string) => void
  service: string,
  checkboxValue: boolean,
  completed: boolean,
  savedValue: any,
  completeService: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string
}

function ServiceAttachment(props: ServiceAttachmentProps) {
  const {
    isInfoRequired,
    requestInfo,
    started,
    saved,
    checkboxHandler,
    service,
    checkboxValue,
    savedValue,
    completed,
    completeService,
    vehicleStatus,
    visitStatus,
  } = props;

  const [showAction, setShowAction] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const [boxChecked, setBoxChecked] = useState<boolean>(false);

  useEffect(() => {
    setValue(vehicleStatus?.value);
  }, [vehicleStatus]);

  const handleAccept = () => {
    if (value === null || value === undefined) return Toast.error('Please add value.');
    return completeService(value, service);
  };

  const handleReject = () => {
    setBoxChecked(false);
    setShowAction(false);
    setValue(null);
  };

  const handleCheckbox = (checked: boolean) => {
    setBoxChecked(checked);
    setShowAction(checked);
  };

  const handleValue = (val: any) => setValue(val);
  const getAttachmentStatus = (existingValue: any) => {
    if (existingValue === true) {
      return 'DETACH';
    } if (existingValue === false) {
      return 'ATTACH';
    }
    return 'ATTACH';
  };

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <Checkbox
              className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
              defaultChecked={checkboxValue}
              disabled={completed}
              onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
            />
            <div className="icn_bg_44 white rounded-circle d-flex align-items-center justify-content-center me-3 primary_bg">
              <Icon className="icon-20" iconRef="#flatstopper" />
            </div>
            {Constant?.SERVICES?.get(service)}
            <RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15} xs={24} className="d-flex flex-column">
          <div className="mt-3 mt-sm-0 order-2 order-sm-1">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Paragraph className="fs-16 mb-0 inactive">
                {vehicleStatus?.last_updated_time
                  ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                  : ''}
              </Typography.Paragraph>
              <RenderItem isTrue={started && saved && showAction && !completed}>
                <Action onAccept={handleAccept} onReject={handleReject} />
              </RenderItem>
            </div>
            <RenderItem isTrue={
              ((started && saved)
               || (isInfoRequired && !requestInfo))
              && !completed
}
            >
              <Typography.Text className="primary r-medium text-uppercase d-block mb-2 mt-2">SERVICE REQUEST</Typography.Text>
              <Typography.Paragraph className="fs-16 mb-0 r-bold primary">{getAttachmentStatus(vehicleStatus?.value)}</Typography.Paragraph>
            </RenderItem>
          </div>
          <RenderItem isTrue={(started && saved) || (isInfoRequired && !requestInfo)}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Status</Typography.Text>
              <Radio.Group
                className="cust_radio_btn d-sm-flex"
                disabled={completed || visitStatus === 'OPEN'}
                value={!!value}
                defaultValue={!!value}
                onChange={(e: any) => handleValue(e?.target?.value)}
              >
                <Radio className="radio_inp" value disabled={getAttachmentStatus(vehicleStatus?.value) === 'DETACH'}>Attached</Radio>
                <Radio className="radio_inp" disabled={getAttachmentStatus(vehicleStatus?.value) === 'ATTACH'} value={false}>Detached</Radio>
              </Radio.Group>
              <Checkbox
                disabled={completed || visitStatus === 'OPEN'}
                defaultChecked={completed}
                checked={boxChecked || completed}
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                onChange={(e) => handleCheckbox(e?.target?.checked)}
              >
                {Constant?.SERVICES?.get(service)} status updated
              </Checkbox>
            </div>
          </RenderItem>
        </Col>
      </Row>
    </div>
  );
}
export default ServiceAttachment;
