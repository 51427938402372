/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Card, Typography,
} from 'antd';
import ModalThumbnailImage from '../ModalThumbnailImage';

import Utility from '../../Global/Utility';

const { Meta } = Card;

interface Props {
  category: String,
  image: any;
  company: string,
  model: string,
  color: string,
  updatedAt: Date,
  dateText: string,
  onClick: () => void,
}

function ModelCard(props: Props) {
  const {
    image, company, model, color, updatedAt, dateText, onClick, category,
  } = props;
  return (
    <Card
      className="model_card p-0 bg-transparent shadow-none"
      bodyStyle={{ padding: 0 }}
      cover={(
        <ModalThumbnailImage
          imageUrl={image?.fileUrl}
          category={category}
          onClick={onClick}
          allowClick
        />
      )}
    >
      <Meta
        title={(
          <Typography.Title
            level={5}
            className="d-flex align-items-center primary mb-0 cursor-pointer"
            onClick={onClick}
          ><span className="text-ellipsis text-capitalize" title={`${company}  ${model}`}>{company}  {model}</span>
            <span className="veh_color d-inline-block ms-3 rounded-circle" style={{ backgroundColor: color }} />
          </Typography.Title>
                                )}
        description={<Typography.Paragraph className="secondary fs-16">{dateText} {Utility.getRelativeDate(updatedAt)} </Typography.Paragraph>}
      />
    </Card>
  );
}

export default ModelCard;
