/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  useParams, Link, useHistory, useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';

import {
  UserCard, ContactCard, Address, Icon, Pagination, UserActivities, Toast, ConfirmModal,
} from '../../Components';
import userService from '../../Services/userService';
import Routes from '../../Global/Routes';
import { UserStoreProps } from '../../Store/userStore';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

import './Employees.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface iEmployeeDetails{
  userStore: UserStoreProps
}

function EmployeeDetails(props: iEmployeeDetails) {
  const { userStore } = props;
  const [employeeDetails, setEmployeeDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(-1);
  const { id } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const [collapsed, setCollapsed] = useState(false);

  const sortActivity = () => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    getUserActivity(pageNumber, nextSort);
  };

  const getEmployeeById = async (eid: string) => {
    setIsLoading(true);
    await userService.getUserById(eid)
      .then((response) => setEmployeeDetails(response && response.data && response.data.user))
      .catch((error:any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
    setIsLoading(false);
  };

  const getUserActivity = async (page: number, sort: number) => {
    setIsLoading(true);
    try {
      await userStore.getUserActivity({
        employee: id,
        page,
        limit: Constant.userActivityPerPage,
        order: 'createdAt',
        orderBy: sort,
        activity_type: [
          Constant.activity_type.SERVICE_ACTIVITY, Constant.activity_type.SERVICE_VISIT_ACTIVITY,
        ],
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployeeById(id);
    setOrderBy(-1);
    setPageNumber(Constant.defaultPageNumber);
  }, [id]);

  useEffect(() => {
    getUserActivity(pageNumber, orderBy);
  }, [pageNumber]);

  const goToEmployeEdit = () => history.push(`/create_employee/${id}?searchText=${queryParams?.searchText || ''}&pageNumber=${queryParams?.pageNumber}`);

  const downloadFile = async (url: string, fileName: string) => {
    try {
      const downloadUrl = await Utility.createObjectUrlFromUrl(url);
      Utility.downloadFile(downloadUrl, fileName);
    } catch (error) {
      Toast.error(Constant.defaultErrorMessage);
    }
  };

  const resentInvitationHandler = () => {
    ConfirmModal('Re-invite', 'Are you sure want to re-invite this employee?', () => resendInvitation(), 'Re-invite');
  };

  const resendInvitation = async () => {
    try {
      setIsLoading(true);
      await userService.resendInvitation(id);
      Toast.success('Invitation mail has been sent to the employee');
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Account', 'Do you want to delete this employee?', deleteEmployee);
  };

  const deleteEmployee = async () => {
    await userService.deleteUser(id)
      .then(() => {
        history.push(`${Routes.employees}?searchText=${queryParams?.searchText}&pageNumber=${queryParams?.pageNumber}`);
      })
      .catch((error:any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      {!employeeDetails?.active
            && (
            <Menu.Item key="0">
              <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={resentInvitationHandler}>
                Re-invite
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Button>
            </Menu.Item>
            )}
      <Menu.Item key="1">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0" onClick={viewDeleteConfirmModal}>
          Delete Account
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={`${Routes.employees}?searchText=${queryParams?.searchText}&pageNumber=${queryParams?.pageNumber}`}>Employees</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{employeeDetails?.emp_id}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                    {employeeDetails?.emp_id}
                  </Typography.Title>
                  {!employeeDetails?.active && <div className="pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16  ms-2 ms-sm-3 label_warning ps-2 pe-2 fs-xs-14">Pending sign up</div>}
                </div>
                <Button className="main_btn fs-16 r-bold mt-2 mt-sm-0" onClick={goToEmployeEdit}>
                  Edit Information
                </Button>
              </div>
              <Row gutter={[16, 16]}>
                <Col xxl={7} lg={8} md={8} sm={24} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={employeeDetails?.profile?.fileUrl}
                      name={(employeeDetails?.first_name && employeeDetails?.last_name) ? `${employeeDetails?.first_name} ${employeeDetails?.middle_name || ''} ${employeeDetails?.last_name}` : ''}
                      cardName="Employee"
                      id={employeeDetails?.emp_id}
                    />
                  </Card>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard contactDetails={employeeDetails} />
                  </Card>
                  <Card bordered={false} className="detail_card_pad">
                    <Address addressInfo={employeeDetails?.address} />
                  </Card>
                  {employeeDetails?.documents?.length
                    ? (
                      <Card bordered={false} className="detail_card_pad mt-3">
                        <Typography.Title level={4}>Documents</Typography.Title>
                        {employeeDetails?.documents?.map((document: any, dataIndex: number) => (
                          <div key={document?._id} className="d-flex justify-content-between align-items-center fs-16 secondary doc_download_brd">
                            <span className="text-truncate me-3" title={document.fileName}>{dataIndex + 1}.{document.fileName}</span>
                            <Tooltip title="download document">
                              <Button onClick={() => downloadFile(document?.fileUrl, document.fileName)} className="small_icon_bg icn_32 rounded-circle d-flex justify-content-center align-items-center p-0">
                                <Icon className="icon-14" iconRef="#download" />
                              </Button>
                            </Tooltip>
                          </div>
                        ))}

                      </Card>
                    ) : null}
                </Col>
                <Col xxl={17} lg={16} md={16} sm={24} xs={24}>
                  <div className="emp_detail_activities">
                    <UserActivities
                      userActivities={userStore?.userActivity}
                      isLoading={isLoading}
                      orderBy={orderBy}
                      sortActivity={sortActivity}
                    />
                  </div>
                  <Pagination
                    total={userStore?.activityTotal}
                    pageSize={Constant.userActivityPerPage}
                    currentPage={pageNumber}
                    onChange={(page: number) => setPageNumber(page)}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the employee</Paragraph>
            </Typography>
            <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              {!employeeDetails?.active
            && (
              <Tooltip title="Re-invite">
                <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={resentInvitationHandler}>
                  Re-invite
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            )}
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0" onClick={viewDeleteConfirmModal}>
                Delete Account
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Button>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('userStore')(observer(EmployeeDetails));
