/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Col,
  Row,
  Typography,
} from 'antd';

import productimg from '../../Assets/Images/flatstopper.png';

const { Title, Text } = Typography;
interface OrderItemProps {
  category: string,
  product: string,
  variant: string,
  quantity: Number,
  amount: number,
  image: string,
}
function OrderItem(props: OrderItemProps) {
  const {
    category, product, variant, quantity, amount, image,
  } = props;
  return (
    <ul className="order_product_list list-unstyled">
      <li className="position-relative">
        <Row gutter={[16, 16]} className="justify-content-between">
          <Col xxl={10} xl={14} lg={14} md={14} sm={24} xs={24}>
            <div className="d-sm-flex flex-sm-grow-1 flex-sm-shrink-1">
              <div className="d-flex mb-3 mb-sm-0">
                <div className="product_img_box border-color mx-sm-3 ms-sm-0 ms-md-3 border-radius p-2 d-flex align-items-center justify-content-center">
                  <img src={image || productimg} alt="product" className="w-100 h-100 object-fit-contain" />
                </div>
              </div>
              <Typography className="flex-sm-grow-1 w-0 w-xs-inherit">
                <Text className="secondary text-uppercase r-medium">{category}</Text>
                <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>{product}</Title>
                <Title className="primary text-capitalize m-0 r-regular" level={5}>{variant}</Title>
              </Typography>
            </div>
          </Col>
          <Col xxl={4} xl={5} lg={5} md={5} sm={10} xs={24} className="ml-0">
            <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">quantity</Typography.Text>
            <Typography.Paragraph className="primary fs-16 text-lg-center">{quantity}</Typography.Paragraph>
          </Col>
          <Col xxl={4} xl={5} lg={5} md={5} sm={10} xs={20}>
            <Typography>
              <Text className="secondary text-uppercase r-medium">Unit Price</Text>
              <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>{amount} KD</Title>
            </Typography>
          </Col>
        </Row>
      </li>
    </ul>
  );
}

export default OrderItem;
