/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import {
  Col, Form, Input, Row, Select, Modal, Button, Divider, Typography, InputNumber,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import Rules from './createPlan.validation';
import { ConfirmModal, Icon, Toast } from '../../Components';
import { PlanTerm } from '../../Global/interface';
import Utility from '../../Global/Utility';
import { IPlanStore } from '../../Store/planStore';

const { Option } = Select;

interface Props {
    isModalVisible: boolean;
    planType: string;
    choosenTermDetails: PlanTerm | null,
    existingTerms: Array<PlanTerm> | null,
    onClose: () => void;
    handleOnCreate: (values: any) => void;
    handleOnUpdate: (values: any) => void;
    removeTerm: (selectedFacility: any) => void,
    facilities: any,
    planId: string
    planStore?: IPlanStore
}

function CreateTermModal(props: Props) {
  const {
    isModalVisible, onClose, handleOnUpdate, planStore, planId,
    handleOnCreate, removeTerm, facilities, choosenTermDetails, existingTerms, planType,
  } = props;

  const [terms, setTerms] = useState<any>([]);
  const [form] = useForm();
  // commented for dynamic terms
  // const durationTypes = [
  // { label: 'Day', key: 'DAY' },
  // { label: 'Week', key: 'WEEK' },
  // { label: 'Month', key: 'MONTH' },
  // { label: 'Year', key: 'YEAR' },
  // ];

  useEffect(() => {
    if (choosenTermDetails) {
      form.setFieldsValue({ facility: choosenTermDetails?.facility?.name });
      const termDetails = choosenTermDetails?.termDetails;
      setTerms(JSON.parse(JSON.stringify(termDetails)));
      termDetails?.map((item: any) => {
        form.setFieldsValue(
          {
            [`${item?.key}_price`]: item?.price,
            [`${item?.key}_duration_type`]: item?.duration_type,
            [`${item?.key}_duration_value`]: Utility?.reverseDuration(item?.duration, item?.duration_type),
          },
        );
      });
    } else if (!terms?.length) handleAddTerm();
  }, [choosenTermDetails]);

  const checkTermExist = (duration: any, durationType: any, key: any) => {
    const actualDuration = Utility?.getDuration(duration, durationType);
    let durationExist = false;
    terms?.forEach((element: any) => {
      if (element?.duration === actualDuration
        && element?.duration_type === durationType && element?.key !== key) {
        durationExist = true;
      }
    });
    return durationExist;
  };

  const handlePriceChange = (value: any, key: any) => {
    setTerms(terms?.map((item: any) => {
      if (item?.key === key) {
        item.price = value || null;
      }
      return item;
    }));
  };

  // const handleDurationTypeChange = (value: any, key: any) => {
  //   const durationValue = form.getFieldValue(`${key}_duration_value`);
  //   if(checkTermExist(durationValue, value, key)) {
  //     Toast.error('Duration Exist');
  //     form?.resetFields([`${key}_duration_value`])
  //   } else {
  //     setTerms(terms?.map((item: any) => {
  //       if (item?.key === key) {
  //         item.duration_type = value || null;
  //         item.duration = Utility.getDuration(durationValue, value);
  //       }
  //       return item;
  //     }));
  //   }
  // };

  const handleDurationChange = (value: any, key: any) => {
    setTerms(terms?.map((item: any) => {
      if (item?.key === key) {
        item.duration = Utility.getDuration(value, item?.duration_type) || null;
      }
      return item;
    }));
  };

  const removeFromTerms = (key: any) => {
    form.resetFields([`${key}_duration_value`, `${key}_price`]);
    setTerms(terms?.filter((term: any) => term?.key !== key));
  };

  const checkTermDuplicate = (termData: any) => {
    const durationList = termData?.map((item: any) => item?.duration);
    return termData.length !== new Set(durationList).size;
  };

  const handleSubmit = (values: any) => {
    const finalValue = terms?.filter((item: any) => item?.price).map((item: any) => ({
      duration: item?.duration,
      duration_type: /* item?.duration_type */ 'MONTH',
      price: item?.price,
      key: item?.key,
    }));
    if (!finalValue?.length) {
      Toast.error('Please choose atleast 1 Term.');
    } else if (checkTermDuplicate(finalValue)) {
      Toast.error('Plan term already exist.');
    } else {
      const facilityId = choosenTermDetails?.facility?._id || values?.facility;
      const facilityDetails = facilities.find((item: any) => item._id === facilityId);
      const finalData = {
        facility: facilityDetails,
        termDetails: finalValue,
      };

      resetTerms();
      form.resetFields();
      if (choosenTermDetails) {
        handleOnUpdate(finalData);
      } else {
        handleOnCreate(finalData);
      }
    }
  };

  const resetTerms = () => setTerms([{ price: null, key: 1, duration_type: 'MONTH' }]);

  const handleCancel = () => {
    form.resetFields();
    resetTerms();
    onClose();
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Remove Term', 'Are you sure to remove this term?', getPlanCount);
  };

  const getPlanCount = async () => {
    if (planId) {
      await planStore?.getPlanCount(
        planId, { facility: choosenTermDetails?.facility?._id || null },
      );
    }
    if (planStore?.planCount) Toast.error(`Plan term is used in ${planStore?.planCount} plans.`);
    else removeTermFromList();
  };

  const removeTermFromList = () => {
    handleCancel();
    removeTerm(choosenTermDetails);
  };

  const handleAddTerm = () => {
    const termsTemp = [...terms];
    let keyTemp = 1;
    terms?.forEach((element: any) => {
      if (element?.key > keyTemp) keyTemp = element?.key;
    });
    termsTemp.push({
      price: null, key: keyTemp + 1, duration_type: 'MONTH',
    });
    setTerms(termsTemp);
  };

  return (
    <Modal
      title={choosenTermDetails ? 'Edit Plan Terms' : 'Add Plan Terms'}
      centered
      footer={null}
      className="cmn_modal_wrapper"
      visible={isModalVisible}
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={false}
      width={935}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Form layout="vertical" onFinish={handleSubmit} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        {planType === 'PARKING_SERVICE'
        && (
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={8} xxl={8}>
            <Form.Item
              label="CHOOSE FACILITY"
              className="mandatory custom_label mb-0"
              name="facility"
              required={false}
              rules={Rules.facility}
            >
              <Select
                disabled={!!choosenTermDetails}
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose"
                className="slct_style"
                dropdownClassName="slct_drp_dwn border-radius"
              >
                {facilities?.map((facility: any) => {
                  if (!existingTerms?.filter((term: PlanTerm) => term?.facility?._id
                  === facility?._id).length) {
                    return (
                      <Option value={facility._id}>
                        {facility?.name}<br />
                        <span className="text-uppercase secondary r-regular d-flex align-items-center">{facility?.address}
                          <Icon className="icon-12 ms-2" iconRef="#location" />
                        </span>
                      </Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        )}
        <Divider className="border-top mt-3 mb-3" />
        <div className="d-flex justify-content-between mb-2">
          <Typography.Text className="d-block secondary text-uppercase r-medium">PLAN TERMS</Typography.Text>
          <Button className="m-0 border-0 bg-transparent h-max d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={handleAddTerm}>
            Add
            <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </div>
        <Row gutter={[12, 12]}>
          {terms.map((term: any) => (
            <Col xs={24} sm={12} md={8} lg={6}>
              <div className="p-2 border off-white-bg border-radius border-0">

                <Row gutter={8}>
                  <Col sm={18}>
                    <div className="d-flex align-items-center position-relative">
                      <Form.Item name={`${term?.key}_duration_value`} rules={Rules?.duration} className="mb-0">
                        <InputNumber
                          max={99}
                          maxLength={2}
                          placeholder="Duration"
                          className="white_bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                          onChange={(value: number) => handleDurationChange(value, term?.key)}
                        />
                      </Form.Item>
                      <Typography.Text className="position-absolute inactive plan_unit h-max">Month</Typography.Text>
                      {/* <Form.Item name={`${term?.key}_
                      duration_type`} className="mb-0 position-absolute top-0 end-0"> */}
                      {/* commented for dynamic term */}
                      {/* <Select onChange={(durationType: string) =>
                      handleDurationTypeChange(durationType, term?.key)}
                      defaultValue="MONTH" className="select-after"
                      suffixIcon={<Icon className="icon-10 white"
                      iconRef="#arrow_down" />}>
                            {durationTypes?.map((durationType: any) => (
                              <Option value={durationType?.key}>
                                {durationType?.label}
                              </Option>
                            ))}
                          </Select> */}
                      {/* </Form.Item> */}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <Button onClick={() => { removeFromTerms(term?.key); }} className="item_delete icn_32 p-0 border-radius red d-flex align-items-center justify-content-center border-0 ms-1">
                      <Icon className="icon-16" iconRef="#delete" />
                    </Button>
                  </Col>
                </Row>
                <div className="position-relative">
                  <Form.Item
                    className="mb-0 mt-3 custom_label"
                    label="PRICE"
                    required={false}
                    rules={Rules?.price}
                    name={`${term?.key}_price`}
                  >
                    <InputNumber
                      className="white_bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                      placeholder="Price"
                      maxLength={10}
                      max={9999999999}
                      onChange={(value) => handlePriceChange(value, term?.key)}
                    />
                  </Form.Item>
                  <Typography.Text className="position-absolute inactive plan_unit h-max top-39">KD</Typography.Text>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex mt-4 justify-content-right">
            <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
              {choosenTermDetails ? 'Update Term' : 'Add Term'}
            </Button>
            <Button type="text" className="cancel_btn fs-16 r-bold" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
          {choosenTermDetails && planType === 'PARKING_SERVICE'
          && (
          <div className="mt-4">
            <Button type="text" className="main_btn red_bg fs-16 r-bold" onClick={viewDeleteConfirmModal}>
              Remove Term
            </Button>
          </div>
          )}
        </div>
      </Form>
    </Modal>
  );
}

export default inject('planStore')(observer(CreateTermModal));
