import React from 'react';

interface IStatus {
    text: string
    className: string
    defaultClass?: string
}

StatusLabel.defaultProps = {
  defaultClass: 'pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16 ps-2 pe-2 fs-xs-14',
};

function StatusLabel(props: IStatus) {
  const { className, text, defaultClass } = props;
  return (
    <>
      { text && (
        <div className={`${className} ${defaultClass}`}>
          {text}
        </div>
      )}
    </>
  );
}

export default StatusLabel;
