/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { FacilityLogQueryParams } from '../Global/interface';
import api from './api';

class FacilityService {
    updateFacility = async (id: string, facility : any) => {
      try {
        const response = await api.put(`/facilities/${id}`, facility);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    }

  createFacility = async (facility : any) => {
    try {
      const response = await api.post('/facilities', facility);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

    getFacilities = (queryParams: any) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/facilities?${query}`;
      return api.get(url);
    }

    getFacilityById = async (id?: String) => {
      try {
        const response = await api.get(`/facilities/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    deleteFacility = async (id: string) => {
      try {
        const response = await api.delete(`/facilities/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    getFacilityLog = (queryParams: FacilityLogQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/facilities/parking-log?${query}`;
      return api.get(url);
    }
}

export default new FacilityService();
