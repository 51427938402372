/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  name: [{
    required: true,
    message: constant.nameFieldRequired,
  }, {
    type: 'string',
    pattern: /^[A-Za-z][A-Za-z .-]*$/,
    message: constant.splOrNumberOnlyError,
  }],
  code: [{
    required: true,
    message: constant.codeRequired,
  }],
};

export default rule;
