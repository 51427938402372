/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { observer } from 'mobx-react';
import {
  Button, Card, Tabs, Typography,
} from 'antd';
import { Icon, VehicleImageDisplay } from '..';
import Constant from '../../Global/Constant';

const { TabPane } = Tabs;
const { PARK, UNPARK, SWAP } = Constant?.vehicleActions;

export interface VehicleImageSelectorProps {
    tabChangeHandle: (activeTab: string) => void,
    parkVehicleImages: any,
    unparkVehicleImages: any,
    removeFileInPark: (index: number) => Promise<void>,
    removeFileInUnpark: (index: number) => Promise<void>,
    action: string,
    showImagePopup: () => void
}

function VehicleImageSelector(props: VehicleImageSelectorProps) {
  const {
    tabChangeHandle,
    parkVehicleImages,
    unparkVehicleImages,
    action,
    removeFileInPark,
    removeFileInUnpark,
    showImagePopup,
  } = props;

  return (
    <Card className="detail_card_pad mt-3 mt-sm-0 min-ht-450 position-relative">
      <Typography.Title level={4}>Vehicle Images</Typography.Title>
      {action === SWAP
    && (
    <Tabs defaultActiveKey="IN" className="custom_tab_info custom_tab_pad park_img_tab" onChange={tabChangeHandle}>
      <TabPane tab="Parking" key="IN">
        <VehicleImageDisplay
          images={parkVehicleImages}
          removeImageFromList={removeFileInPark}
          emptyImageText="Add parking vehicle images"
        />
      </TabPane>
      <TabPane tab="Swapping" key="OUT">
        <VehicleImageDisplay
          images={unparkVehicleImages}
          removeImageFromList={removeFileInUnpark}
          emptyImageText="Add swapping vehicle images"
        />
      </TabPane>
    </Tabs>
    ) }
      {action === PARK
    && (
    <VehicleImageDisplay
      images={parkVehicleImages}
      removeImageFromList={removeFileInPark}
      emptyImageText="Add parking vehicle images"
    />
    )}
      {action === UNPARK
      && (
      <VehicleImageDisplay
        images={unparkVehicleImages}
        removeImageFromList={removeFileInUnpark}
        emptyImageText="Add unparking vehicle images"
      />
      )}
      <Button
        className="upload_park_unpark_icon position-absolute icn_bg_44 primary_bg border-0 rounded-circle text-white d-flex align-items-center justify-content-center"
        onClick={showImagePopup}
      >
        <Icon className="icon-14" iconRef="#add" />
      </Button>
    </Card>
  );
}
export default observer(VehicleImageSelector);
