/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../Global/Constant';
import api from './api';

class SettingsService {
    getSettings = async () => {
      try {
        const response = await api.get('/settings');
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    updateSettings = async (data: any) => {
      try {
        const response = await api.put('/settings', data);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(
          error?.response?.data?.error?.message || Constant.defaultErrorMessage,
        );
      }
    }
}

export default new SettingsService();
