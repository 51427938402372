/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  List,
} from 'antd';
import moment from 'moment';

import { Icon } from '../index';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface IActivityCard {
    dataSource: any
    currentUser: any
}

function ActivityCard(props: IActivityCard) {
  const { dataSource, currentUser } = props;

  const getActor = (actor: any, activity_type: string) => {
    if (['REFUND_FAILED'].includes(activity_type)) return '';
    if (actor?._id === currentUser?.id) return 'You ';
    if (actor?._id && actor?.first_name) return `${Utility.getUserName(actor)} `;
    if (actor?._id && !actor?.first_name) return 'Guest ';
    return '';
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item: any) => (
        <List.Item className="t_card_list position-relative border-bottom-0">
          <List.Item.Meta
            avatar={(
              <div className="icn_bg_44 p-0 rounded-circle d-flex align-items-center justify-content-center secondary">
                {item?.action_by?.profile?.fileUrl
                  ? <img className="h_user_btn p-0 rounded-circle overflow-hidden activity_user_icon" src={item?.action_by?.profile?.fileUrl} alt="User" />
                  : <Icon className="icon-20" iconRef="#User" /> }
              </div>
                            )}
            title={Utility?.getRelativeDay(item?.createdAt)}
            description={(
              <div className="d-sm-flex align-items-center">
                <div className="fs-16 primary">
                  {getActor(item?.action_by, item?.activity_type)}
                  {Constant.activityDescription.get(item?.activity_type)
                    ?.replace('[mode]', Constant.payment_mode_text.get(item?.reference?.payment_mode) as string)
                    ?.replace('[date]', Utility?.getFormattedDate(item?.activity_info?.scheduled_pickup_time?.scheduled_from)as string)
                    ?.replace('[from]', Utility.getFormattedTime(item?.activity_info?.scheduled_pickup_time?.scheduled_from) as string)
                    ?.replace('[to]', Utility.getFormattedTime(item?.activity_info?.scheduled_pickup_time?.scheduled_to) as string) }
                </div>
                <div className="fs-14 r-medium ms-sm-3 inactive text-capitalize">{ moment.utc(item?.createdAt).fromNow()}</div>
              </div>
                        )}
          />
        </List.Item>
      )}
    />
  );
}

export default ActivityCard;
