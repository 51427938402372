/* eslint-disable import/extensions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import { useHistory } from 'react-router-dom';
import {
  Button, Card, Divider, Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { Icon } from '..';
import Routes from '../../Global/Routes';
import NoData from '../NoData';

interface PlanCountCardProps {
  title: string,
  count: any,
  color: string,
  plans: any,
  isLoading: boolean,
  routeHandler: () => void,
  isSuperAdmin: boolean
}

function PlanCountCard(props: PlanCountCardProps) {
  const {
    title, count, color, plans, routeHandler, isLoading, isSuperAdmin,
  } = props;

  const history = useHistory();

  const gotoPlanDetails = (planId: string) => history.push(`${Routes.contract_detail}/${planId}`);

  return (
    <Card loading={isLoading} bordered={false} className="dash_card_resp_fnt" style={{ minHeight: !isSuperAdmin ? 315 : 'unset' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Typography.Title level={3} className="r-bold mb-0">{title}</Typography.Title>
        <Button onClick={routeHandler} className="d-flex ml-auto r-bold align-items-center p-0 link_text secondary border-0 shadow-none">
          View
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle secondary_bg">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </div>
      <Typography.Title className="r-bold lh-1 mb-0 num_fnt_size" style={{ color }}>{count || (isSuperAdmin ? '0' : <NoData subTitle={title} size={105} />)}</Typography.Title>

      {plans?.map((plan : any) => (
        <div key={plan?._id}><Divider className="border-top mt-2 mb-2" />
          <Button className="d-flex align-item-center justify-content-between border-0 w-100 shadow-none p-0" onClick={() => gotoPlanDetails(plan?._id)}>
            <Typography.Title level={5} className="r-bold mb-0 text-capitalize text-truncate me-2">{`${plan?.client?.first_name} ${plan?.client?.last_name}`}</Typography.Title>
            <Typography.Paragraph className="secondary mb-0">{plan?.due_date && moment(plan?.due_date).format('DD/MM/YYYY')}</Typography.Paragraph>
          </Button>
        </div>
      ))}
    </Card>
  );
}

export default PlanCountCard;
