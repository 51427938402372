/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import {
  Modal,
} from 'antd';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import Routes from '../../Global/Routes';
import { Toast } from '..';
import Constant from '../../Global/Constant';

const { QRcodeTypes } = Constant;

interface Props {
  isQRScannerModal: boolean
  closeQRScannerModal: () => void
}

function QRScanner(props: Props) {
  const { isQRScannerModal, closeQRScannerModal } = props;
  const qrRef = useRef(null);
  const history = useHistory();

  const onError = (err: any) => Toast.error(err?.message);

  const onScan = (payload: any) => {
    try {
      if (payload) {
        const { subject, target } = JSON.parse(payload);
        let route = '';
        if (subject === QRcodeTypes?.VEHICLE) route = Routes.vehicleDetails;
        else if (subject === QRcodeTypes?.CLIENT) route = Routes.client_detail;
        history.push(`${route}/${target}`);
        closeQRScannerModal();
      }
    } catch (error: any) {
      Toast.error(Constant.invalidQRCode);
    }
  };

  return (
    <Modal title="Scan QR" visible={isQRScannerModal} onCancel={closeQRScannerModal} footer={null} destroyOnClose>
      <QrReader
        ref={qrRef}
        delay={300}
        style={{ width: '100%' }}
        onError={onError}
        onScan={onScan}
      />
    </Modal>
  );
}

export default observer(QRScanner);
