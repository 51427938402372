/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import Constant from '../Global/Constant';
import {
  serviceRequest, serviceRequestData, serviceRequestParams, serviceRequestProps, ServiceVisit,
} from '../Global/interface';
import serviceVisitService from '../Services/serviceVisitSevices';

export interface IServiceVisitStore{
  visits: any,
  total: number,
  scheduledTimes: [number]
  getServices: (queryParams?: any) => any,
  serviceVisit: ServiceVisit,
  getRequests: (queryParams: serviceRequestParams) => any,
  serviceRequest: serviceRequestData,
  getServiceVisitById: (id: string) => any
  updateService: (id: string, data: any) => any
  createServicesVisits: (data: any) => any
  sendPaymentLink: (id: string, data : any) => any
  updateServiceVisitStatus: (id: string, data: any) => any
  downloadInvoice: (id: string) => any
  initialize: Function
}

class ServiceVisitStore {
  visits: any = []

  total: number = 0

  serviceVisit: ServiceVisit | null = null;

  serviceRequest: serviceRequestData | null = null;

  scheduledTimes: [number] | null= null;

  constructor() {
    makeObservable(this, {
      visits: observable,
      serviceVisit: observable,
      total: observable,
      scheduledTimes: observable,
      getServices: action,
    });
    this.initialize();
  }

    initialize = () => {
      this.visits = [];
      this.total = 0;
      this.serviceVisit = null;
      this.scheduledTimes = null;
    }

  getServices = async (queryParams?: any) => {
    try {
      const response = await serviceVisitService.getServiceVisits(queryParams);
      if (response && response?.data) {
        this.visits = response?.data?.service_visits;
        this.total = response?.data?.total?.counts;
        this.scheduledTimes = response?.data?.scheduled_times;
      }
      return (null);
    } catch (error:any) {
      return (error?.response?.data?.error?.message);
    }
  }

  createServicesVisits = async (data: any) => {
    try {
      const response = await serviceVisitService.createServiceVisit(data);
      return (response);
    } catch (error: any) {
      return Promise.reject(
        error?.response?.data?.error?.message || Constant.defaultErrorMessage,
      );
    }
  }

  getServiceVisitById = async (id: string) => {
    try {
      const response = await serviceVisitService.getServiceVisitById(id);
      if (response && response?.data) {
        this.serviceVisit = response?.data?.service_visit;
      }
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message
   || Constant.defaultErrorMessage);
    }
  }

  updateServiceVisit = async (id: string, data: any) => {
    try {
      await serviceVisitService.updateServiceVisit(id, data);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(
        error?.response?.data?.error?.message || Constant.defaultErrorMessage,
      );
    }
  }

  sendPaymentLink = async (id: string, data : any) => {
    try {
      const response = await serviceVisitService.sendPaymentLink(id, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message
         || Constant.defaultErrorMessage);
    }
  }

  downloadInvoice = async (id: any) => {
    try {
      const response = await serviceVisitService.downloadInvoice(id);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  updateServiceVisitStatus = async (id: string, data: any) => {
    try {
      await serviceVisitService.updateServiceVisitStatus(id, data);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(
        error?.response?.data?.error?.message || Constant.defaultErrorMessage,
      );
    }
  }
}

export default new ServiceVisitStore();
