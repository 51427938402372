/* eslint-disable no-case-declarations */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable class-methods-use-this */
import moment, { Moment } from 'moment';
import { phone } from 'phone';
import { parsePhoneNumber } from 'react-phone-number-input'; // phone update
import Constant from './Constant';
import defaultModelCar from '../Assets/Images/modelCar.png';
import defaultModelBike from '../Assets/Images/modelBike.png';
import defaultModelMarine from '../Assets/Images/modelMarine.png';
import defaultModelOthers from '../Assets/Images/modelOthers.png';
import {
  Client, Employee, Order, OrderRequest, RequestedData, Terms,
} from './interface';

const { CLIENT_DELETE, MODEL_DELETE, FACILITY_DELETE } = Constant.employeeRequest;

const { CLOSED, IN_PROGRESS, OPEN } = Constant.order_request_status;
const {
  REQUEST_REJECTED, REQUEST_CANCELLED, REFUND, SCHEDULE_PICKUP, PRODUCT_APPROVED, PRODUCT_REJECTED,
} = Constant.order_request_action;

class Utility {
  parseQueryString(path: string) {
    const parsedQueryString: any = {};

    const queryString = path?.split('?')[1];
    const queryStringList = queryString?.split('&');

    queryStringList?.forEach((item) => {
      const key = item.split('=')[0];
      const value = item.split('=')[1];
      parsedQueryString[decodeURIComponent(key)] = this.decodeValue(value);
    });

    return parsedQueryString;
  }

  decodeValue(value: any) {
    const decodedValue = decodeURIComponent(value || '');
    try {
      return JSON.parse(decodedValue);
    } catch (e: any) {
      return decodedValue;
    }
  }

  parseJwt(token: string) {
    try {
      if (token) {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
        return JSON.parse(jsonPayload);
      }
      return null;
    } catch (err: any) {
      return null;
    }
  }

  getRefreshTokenTime = () => {
    if (localStorage?.refreshToken) {
      const refreshToken = this.parseJwt(localStorage?.refreshToken);
      return refreshToken?.exp;
    }
    return null;
  };

  trimSpacesInValues(value: string) {
    if (value !== undefined && value !== null) {
      return value?.trimLeft()?.replace(/( )\1+/g, '$1');
    }
    return '';
  }

  getFormattedDate = (date: Date | number) => {
    if (date) {
      return moment(date).format('DD MMM YYYY');
    }
    return null;
  }

  getStatusText = (orderData: Order) => {
    if (orderData?.is_return_requested) return 'Return Requested';
    if (orderData?.is_cancel_requested) return 'Cancel Requested';
    return Constant.order_statusText.get(orderData?.order_status);
  };

  getStatusClassName = (orderData: Order) => {
    if (orderData?.is_return_requested) return 'label_warning';
    if (orderData?.is_cancel_requested) return 'label_warning';
    return Constant.order_statusClassName.get(orderData?.order_status);
  };

  getUserName = (client: Client | Employee) => (client ? `${client?.first_name} ${client?.middle_name || ''} ${client?.last_name}` : '');

  getFormattedTime = (date: Date | number) => {
    if (date) {
      return moment(date).format('hh:mm A');
    }
    return null;
  }

  getRelativeDate = (date: Date | number) => {
    if (date) {
      const timeDifference = this.calculateDateDifference(date, Date.now());
      if (timeDifference <= 2) {
        return moment.utc(date).fromNow();
      }
      return moment(date).format('DD/MM/YYYY');
    }
    return null;
  }

  getRelativeDay = (date: Date | number) => {
    if (date) {
      const timeDifference = this.calculateDateDifference(date, moment().endOf('day'));
      if (timeDifference < 2) {
        return timeDifference === 0 ? 'TODAY' : 'YESTERDAY';
      }
      return moment(date).format('DD/MM/YYYY');
    }
    return null;
  }

  calculateDateDifference = (startDate: any, endDate: any) => moment(endDate).diff(moment(startDate), 'days')

  getPercentageColor = (percentage: number) => {
    if (percentage >= 50) {
      return '#26D65B';
    }
    if (percentage > 20 && percentage < 50) {
      return '#FFC041';
    }
    return '#FF0000';
  };

  getRequestText = (request: string) => {
    const requests = new Map([[CLIENT_DELETE, 'Client Delete'], [FACILITY_DELETE, 'Facility Delete'], [MODEL_DELETE, 'Model Delete']]);
    return requests.get(request);
  }

  getContractTypeText = (contractType: string) => {
    const contractTypes = new Map([[Constant.VAULT_CONTRACT, 'Vault Contract'], [Constant.SERVICE_CONTRACT, 'Service Contract']]);
    return contractTypes.get(contractType);
  }

  disabledFutureDates = (current: Moment): boolean => current && current > moment();

  getMonthFromDays = (days: number) => {
    const month = days / 30;
    if (month <= 1) return `${month} month`;
    return `${month} months`;
  }

  downloadFile = async (url: any, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
  };

  // Create blob link from url to download
  createObjectUrlFromUrl = async (url: string) => {
    const blob = await fetch(url).then((file: any) => file.blob());
    const objectUrl = window.URL.createObjectURL(blob);
    return objectUrl;
  };

  // Create blob link from buffer to download
  createObjectUrlFromData = (data: any) => window.URL.createObjectURL(new Blob([data]))

  // phone update
  phoneOptionalValidator = (field : any, value : any, callback : Function) => {
    if (value && parsePhoneNumber(value)?.nationalNumber) {
      if (!phone(value, { country: '' })?.isValid) {
        callback(Constant.invalidPhone);
      }
    }
    callback();
  }

  // phone update
  phoneRequiredValidator = (field:any, value:any, callback:Function) => {
    if (!phone(value, { country: '' })?.isValid) {
      if (!value) callback(Constant.phoneRequired);
      else callback(Constant.invalidPhone);
    }
    callback();
  };

  defaultModalImage = (categories:any) => {
    switch (categories) {
      case 'car':
        return defaultModelCar;
      case 'motorbike':
        return defaultModelBike;
      case 'marine':
        return defaultModelMarine;
      default:
        return defaultModelOthers;
    }
  };

  saveMileagePreference = (unit: string) => {
    localStorage.setItem('mileageUnit', unit);
  }

  getOrderRequestStatus = (request: OrderRequest) => {
    switch (request?.status) {
      case OPEN:
        return { text: 'New', className: 'label_inactive' };
      case IN_PROGRESS:
        let text;
        if (request?.action === REFUND) text = 'Refund Initiated';
        else if (request?.action === SCHEDULE_PICKUP) text = 'Scheduled';
        else if (request?.action === PRODUCT_APPROVED) text = 'Collected';
        else text = 'Approved';

        return { text, className: 'label_warning' };
      case CLOSED:
        if (request?.action === REQUEST_REJECTED) return { text: 'Rejected', className: 'label_danger' };
        if (request?.action === PRODUCT_REJECTED) return { text: 'Rejected', className: 'label_danger' };
        if (request?.action === REQUEST_CANCELLED) return { text: 'Cancelled', className: 'label_success' };
        return { text: 'Completed', className: 'label_success' };
      default:
        return '';
    }
  }

  // phone update
  phoneParser = (phoneNumber: any) => {
    if (phoneNumber && parsePhoneNumber(phoneNumber)?.nationalNumber) {
      return phoneNumber;
    }
    return null;
  };

  getRelativeDays = (date: Date | number) => {
    if (date) {
      const endDate = moment(date).utc();
      const currentDate = moment().utc().startOf('day');
      const days = Math.abs(endDate.diff(currentDate, 'days'));

      return `${days} ${days > 1 ? 'Days' : 'Day'}`;
    }
    return null;
  };

  calculateDatePercentage = (
    startDate: number|undefined, dueDate: number|undefined, isPlanClosed?: boolean,
  ) => {
    if (isPlanClosed) return 0;
    const totalContractDays = this.calculateDateDifference(startDate, dueDate);
    const contractDaysLeft = this.calculateDateDifference(moment(Date.now()), dueDate);
    const percentage = ((contractDaysLeft) / (totalContractDays)) * 100;
    return percentage > 10 ? percentage : 10;
  };

  getPlanType = (planType: string) => (planType === 'VAULT' ? 'PARKING SERVICE' : 'HOME SERVICE')

  getContractPlanTerm(
    isVaultPlan: boolean, terms: [Terms] | undefined,
    planDuration: number, termType: string, facilityId?: string,
  ) {
    let termDetails;
    if (isVaultPlan) {
      termDetails = terms?.find(
        (term) => term?.facility === facilityId && term?.duration === planDuration
        && term?.duration_type === termType,
      );
    } else {
      termDetails = terms?.find(
        (term) => term?.duration === planDuration && term?.duration_type === termType,
      );
    }
    return termDetails || null;
  }

  getDuration(duration: any, durationType: any) {
    const days = Constant?.durationTypeConvertion?.get(durationType);
    const actualDays = days && (days * duration as number);
    return actualDays;
  }

  reverseDuration(duration: any, durationType: any) {
    const days = Constant?.durationTypeConvertion?.get(durationType);
    const count = days && (duration / days as number);
    return count;
  }

  getUnitPrice = (quantity: number, totalAmount: number, deliveryCharge?: number) => {
    let totalVariantPrice = totalAmount;
    if (deliveryCharge) totalVariantPrice -= deliveryCharge;
    return ((totalVariantPrice) / quantity) || 0;
  }

  getFullDuration(duration: any, durationType: any) {
    const durationString = Constant?.durationTypeName.get(durationType);
    return `${this.reverseDuration(duration, durationType)} ${durationString}`;
  }

  getLocation(contractType: string, request: RequestedData) {
    if (contractType === Constant.plan_type.VAULT) {
      return `${request?.facility[0]?.name} - ${request?.facility[0]?.address} - (${request?.facility[0]?.used_capacity}/${request?.facility[0]?.total_capacity})`;
    }
    return request?.vehicle?.address?.state;
  }

  getFuelLevel = (value: number) => {
    if (value === null || value === undefined) return '';
    if (value > 6) return 'Full';
    if (value > 3) return 'Half';
    return 'Low';
  };

  getContractStatus = (
    dueDate: any, paymentStatus: boolean|undefined, contractStatus: String|undefined,
  ) => {
    if (paymentStatus) {
      return dueDate > moment(Date.now())
        ? Constant?.customerContract?.ACTIVE : Constant?.customerContract?.EXPIRED;
    }
    return contractStatus;
  };

  sliderColor = (sliderValue: number) => {
    if (sliderValue < 4) return '#FF4141';
    if (sliderValue >= 4 && sliderValue < 7) return '#F1AA1D';
    return '#26D65B';
  };

   levelIndicator = (sliderValue: number) => {
     if (!sliderValue || sliderValue < 4) return 'Low';
     if (sliderValue >= 4 && sliderValue < 7) return 'Medium';
     return 'High';
   };

   checkTirePressureValid = (value: any, wheelNo: number) => {
     if (wheelNo === 1) {
       return !value?.wheel_1;
     }
     if (wheelNo === 2) {
       return !(value?.wheel_1 && value?.wheel_2);
     }
     if (wheelNo === 3) {
       return !(value?.wheel_1 && value?.wheel_2 && value?.wheel_3);
     }
     if (wheelNo === 4) {
       return !(value?.wheel_1 && value?.wheel_2 && value?.wheel_3 && value?.wheel_4);
     }
     return true;
   };

   getTotalAmount = (price: number, deliveryCharge: number, additionalAmount?: number) => (
     (price || 0) + (deliveryCharge || 0) + (additionalAmount || 0)
   )

   checkIfToday = (startDate: any) => startDate.toDateString() === new Date().toDateString();

   validateText = (value: any) => value && !(/^[a-zA-Z0-9 ]*[a-zA-Z0-9 @&()?,.'"!?#$:/`-]*$/.test(value))

   getDueDate = (startDate: any, planDuration: any) => moment(startDate).add(JSON.parse(planDuration), 'days')

   checkIfClosedDateLess = (startDate: any, closedDate: any, planDuration: any) => {
     const dueDate = this.getDueDate(startDate, planDuration);
     if (closedDate < dueDate) return true;
     return false;
   }
}

export default new Utility();
