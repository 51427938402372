/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Tooltip,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';

import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Routes from '../../Global/Routes';

import './SetPassword.less';
import Toast from '../../Components/Toast';
import Utility from '../../Global/Utility';
import { Icon } from '../../Components';
import { ITenantStore } from '../../Store/tenantStore';

interface IForgotPassword {
  tenantStore: ITenantStore
}

function SetPassword(props: IForgotPassword) {
  const { tenantStore } = props;

  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();
  const { token } = useParams<any>();

  const setPasswordHandler = (values: any) => {
    setLoading(true);
    authStore.setPassword(values?.password, token, (err?: Error) => {
      setLoading(false);
      if (err) {
        Toast.error(err?.message);
        return;
      }
      history.push(Routes.login);
    });
  };

  return (
    <Layout.Content id="reset-password" className="d-flex align-items-center">
      <div className="login-box set-wrapper w-100">
        <div className="text-center mb-5">
          <img src={tenantStore?.tenantLogo} style={{ marginBottom: '20px' }} width="80px" alt={tenantStore?.tenantName} />
        </div>
        <Typography.Title className="mb-4" level={4}>
          Set Password
        </Typography.Title>
        <Form layout="vertical" className="login-form set_form" form={form} onFinish={setPasswordHandler} validateTrigger={['onBlur', 'onSubmit', 'onChange']}>
          <div className="position-relative">
            <Form.Item
              label="PASSWORD"
              name="password"
              className="mb-2"
              normalize={Utility.trimSpacesInValues}
              rules={[
                {
                  required: true,
                  message: Constant.newPasswordRequiredError,
                },
                {
                  type: 'string',
                  pattern: Constant.passwordRegex,
                  message: Constant.invalidPassword,
                },
                () => ({
                  validator(rule, value) {
                    if (!value || value.trim().length >= Constant.passwordMinLength) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Constant.passwordValidationError));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Create a password" maxLength={Constant.passwordLength} className="login_input" allowClear />
            </Form.Item>
            {/* <Button onClick={() => form.setFieldsValue({ password: '' })}
            className="login_close btn_set_close d-flex justify-content-center align-items-center
            text-center p-0 position-absolute" type="link" shape="circle">
              <span className="icon-10">
                <svg>
                  <use xlinkHref="#close" />
                </svg>
              </span>
            </Button> */}
          </div>

          <div className="position-relative">
            <Form.Item
              label="CONFIRM PASSWORD"
              name="confirm_password"
              className="mb-2"
              normalize={Utility.trimSpacesInValues}
              rules={[
                {
                  required: true,
                  message: Constant.confirmPasswordRequiredError,
                },
                {
                  type: 'string',
                  pattern: Constant.passwordRegex,
                  message: Constant.invalidPassword,
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(Constant.passwordMatchError));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm your password" maxLength={Constant.passwordLength} className="pe-5 login_input" allowClear />
            </Form.Item>
            <Typography.Paragraph className="secondary fs-16">
              Use minimum 6 characters, include uppercase, lowercase and special characters.
            </Typography.Paragraph>
            <Tooltip title="Submit">
              <Button htmlType="submit" loading={loading} block className="login_arw d-flex align-items-center justify-content-center text-center p-0 position-absolute btn_set_pwsd" type="primary" shape="circle">
                <Icon className="icon-10" iconRef="#arrow_right" />
              </Button>
            </Tooltip>
          </div>
        </Form>
      </div>
      <Footer className="text-center position-absolute">VMS Portal • Copyright © 2021 • <a href={Routes.dashboard}>Terms And Conditions</a> • <a href="/privacyPolicy.html">Privacy Policy</a></Footer>
    </Layout.Content>
  );
}

export default inject('tenantStore')(observer(SetPassword));
