/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';

import {
  UserCard, ContactCard, Address, Icon, Toast,
  OrderItem, RefundModal, ActivityCard, StatusLabel,
} from '../../Components';
import CancelSteps from './CancelSteps';
import { IOrderStore } from '../../Store/orderStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const { CLOSED } = Constant?.order_request_status;
const { ORDER_CANCELED, ORDER_RETURNED } = Constant?.order_status;
const { CASH, GATEWAY } = Constant?.payment_mode;

interface OrderCancelDetailsProps {
  orderStore: IOrderStore
}

function OrderCancelDetails(props: OrderCancelDetailsProps) {
  const { orderStore } = props;
  const {
    order, category, product, variant, customer, activities, request,
  } = orderStore;

  const [isRefundModalVisible, setIsRefundModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

  const showCancel = request?.status !== CLOSED;

  const showViewOrder = order?.order_status !== ORDER_CANCELED
  && order?.order_status !== ORDER_RETURNED && !order?.deleted;

  const history = useHistory();

  const { id } = useParams<any>();
  const getCancelRequestById = async () => {
    try {
      setIsLoading(true);
      await orderStore.getCancelRequestById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getCancelOrderActivities = async () => {
    try {
      setIsLoading(true);
      await orderStore.listOrderActivities({ reference: id });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const updateCancelStatus = async (action: string, payment_mode?: string) => {
    try {
      setIsUpdateLoading(true);
      const data : any = {
        action: Constant.orderCancel.get(action),
        payment_mode,
      };
      await orderStore.updateCancelRequestStatus(id, data);
      await getCancelRequestById();
      await getCancelOrderActivities();
      setIsUpdateLoading(false);
    } catch (error: any) {
      setIsUpdateLoading(false);
      Toast.error(error);
    }
  };
  const openRefundModal = () => setIsRefundModalVisible(true);
  const closeRefundModal = () => setIsRefundModalVisible(false);

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const S3Url = await orderStore?.downloadInvoice(order?._id);
      const downloadUrl = await Utility.createObjectUrlFromUrl(S3Url);
      Utility.downloadFile(downloadUrl, `Invoice-${order?.order_code}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const handleRefund = async (option: number) => {
    const payment_mode = option === 1 ? CASH : GATEWAY;
    await updateCancelStatus('refund', payment_mode);
    closeRefundModal();
  };

  useEffect(() => {
    getCancelRequestById();
    getCancelOrderActivities();
  }, [id]);

  const goToOrderDetails = () => history.push(`${Routes.order_details}/${order?._id}`);

  const statusData: any = Utility?.getOrderRequestStatus(request);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto" onClick={goToOrderDetails}>
          View Order
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const amount: any = Utility.getUnitPrice(
    order?.order_quantity, order?.total_amount, order?.delivery_charge,
  );

  return (
    <Spin spinning={isLoading}>
      <Layout.Content className="detail_wrapper clnt_detail">
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.orders}>ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`${Routes.orders}?tab=${Constant.orderStatus.CANCELLED}`}>CANCELLED ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{request?.request_code}</Breadcrumb.Item>
              </Breadcrumb>
              {showViewOrder && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              )}
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center flex-wrap">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24 me-sm-3 me-1">
                    {request?.request_code}
                  </Typography.Title>
                  <StatusLabel className={statusData?.className} text={statusData?.text} />
                </div>
              </div>
              <Row gutter={[16, 16]}>
                <Col lg={16} md={16} xs={24} className="mt-3 mt-md-0">
                  <Card>
                    <Typography.Title level={3}>Order Item</Typography.Title>
                    <Row>
                      <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                        <OrderItem
                          category={category?.name}
                          product={product?.name}
                          variant={variant?.color?.name}
                          quantity={order?.order_quantity}
                          amount={amount}
                          image={variant?.images[0]}
                        />
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                    <div className="d-flex justify-content-end align-items-center fs-16 r-bold secondary mt-4">
                      Total Amount
                      <Typography.Text className="primary fs-24 ms-3">{order?.total_amount} KD</Typography.Text>
                    </div>
                    <Divider className="my-3 border-top" />
                    {order?.payment_status
                    && (
                    <div className="d-flex align-items-center">
                      <Button className="primary gray_bg h-40 border-radius border-0 fs-16 r-bold" onClick={downloadInvoice}>
                        Download Invoice
                      </Button>
                    </div>
                    )}
                  </Card>
                  {showCancel
                  && (
                  <CancelSteps
                    currentDot={
                      Constant?.cancelOrderCurrentDot?.get(request?.status) as number
                    }
                    payment_status={order?.payment_status}
                    updateCancelStatus={updateCancelStatus}
                    handleRefund={openRefundModal}
                    isRefundDisabled={order?.refund_status === 'INITIATED'}
                  />
                  )}
                  <Card className="timeline_card mt-3" loading={isLoading}>
                    <Typography.Title level={3}>Timeline</Typography.Title>
                    {activities && activities?.length
                      ? (
                        <ActivityCard
                          dataSource={activities}
                          currentUser={authStore?.currentUser}
                        />
                      ) : 'No activity'}
                  </Card>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={customer?.profile?.fileUrl}
                      name={`${customer?.first_name ? Utility.getUserName(customer) : ''} `}
                      cardName="Customer"
                      id={customer?.client_code}
                      path={`${Routes.customer_details}/${customer?._id}`}
                      ordersCount={customer?.order_count}
                    />
                  </Card>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard
                      contactDetails={
                      {
                        email: customer?.email,
                        phone: customer?.phone,
                      }
}
                    />
                  </Card>
                  <Card bordered={false} className="detail_card_pad mb-3">
                    <Address title="Billing Address" addressInfo={order?.billing_address} />
                  </Card>
                  <Card bordered={false} className="detail_card_pad">
                    <Address title="Delivery Address" addressInfo={order?.shipping_address} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the order</Paragraph>
            </Typography>
            {showViewOrder && (
            <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              <Tooltip title="View Order">
                <Button className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto" onClick={goToOrderDetails}>
                  View Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            )}
          </Sider>
        </Row>

        {/* Refund Modal on cancel order detail */}
        <RefundModal
          isVisible={isRefundModalVisible}
          isLoading={isUpdateLoading}
          handleCancel={closeRefundModal}
          onOk={handleRefund}
          isGatewayDisabled={order?.payment_mode !== GATEWAY}
        />

      </Layout.Content>
    </Spin>
  );
}

export default inject('orderStore')(observer(OrderCancelDetails));
