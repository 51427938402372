/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Typography,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Icon, Pagination, RenderItem, Toast, VisitList,
} from '..';
import { IServiceVisitStore } from '../../Store/serviceVisitStore';
import rule from './scheduleVisit.form.validation';
import ScheduleNextVisit from '../ScheduleNextVisit';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';
import { IContractStore } from '../../Store/contractStore';

interface IVisit{
    isResidentialPlan: boolean
    serviceVisitStore?: IServiceVisitStore
    contractStore?: IContractStore
    planId: string
    due_date: number
    client: string
    limit?: number
    contractStatus: string
    paymentStatus: boolean
    scheduledTime?: number
    visitStatus?: string
    visit_id?: string
    moveToDetailPage?: boolean
    fetchData?: boolean
}

ScheduleVisit.defaultProps = {
  serviceVisitStore: undefined,
  limit: 5,
  visitStatus: '',
  scheduledTime: 0,
  visit_id: '',
  moveToDetailPage: false,
  contractStore: undefined,
  fetchData: false,
};

const {
  OPEN, IN_PROGRESS, COMPLETED, PAYMENT_PENDING,
} = Constant.serviceVisitReqStatus;

function ScheduleVisit(props: IVisit) {
  const {
    isResidentialPlan, serviceVisitStore, planId, due_date, client, limit,
    contractStatus, paymentStatus, scheduledTime, visitStatus, visit_id, moveToDetailPage,
    contractStore, fetchData,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCreateVisitModal, setShowCreateVisitModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);

  const [form] = useForm();

  const history = useHistory();

  const goToVisitDetails = (visitId: string) => {
    history.push(`${Routes?.service_visit_detail}/${visitId}`);
    // window.location.reload();
  };

  const getServicesVisits = async (page: number) => {
    try {
      setIsLoading(true);
      await serviceVisitStore?.getServices({
        contract: planId,
        order: 'scheduled_time',
        orderBy: 1,
        limit,
        page,
        return_scheduled_time: true,
        status: [OPEN, IN_PROGRESS, COMPLETED, PAYMENT_PENDING],
      });
      getContractById();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const getContractById = async () => {
    try {
      setIsLoading(true);
      await contractStore?.getContractById(planId);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const createServicesVisits = async (scheduled_time: number) => {
    try {
      setIsLoading(true);
      await serviceVisitStore?.createServicesVisits({ contract: planId, scheduled_time, client });
      getServicesVisits(pageNumber);
      visitModalClose();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const createBtnHandler = () => setShowCreateVisitModal(true);

  const visitModalClose = () => {
    setShowCreateVisitModal(false);
    form.resetFields();
  };

  const visitCount = (contractStore?.contract?.total_visit_count || 0) - (
    contractStore?.contract?.used_visit_count || 0);

  const contractCurrentStatus = Utility.getContractStatus(due_date, paymentStatus, contractStatus);
  const isActiveContract = contractCurrentStatus === Constant.customerContract.ACTIVE;
  const showCard = isActiveContract ? true : !!serviceVisitStore?.total;

  const scheduledTimeList: any = serviceVisitStore?.scheduledTimes?.map((item: number) => moment(item).format('YYYY-MM-DD'));

  useEffect(() => {
    if ((isResidentialPlan && planId) || fetchData) {
      getServicesVisits(pageNumber);
    }
  }, [isResidentialPlan, planId, fetchData]);

  return (
    <RenderItem isTrue={showCard && isResidentialPlan}>
      <Card bordered={false} className="detail_card_pad mb-3" loading={isLoading}>
        <div className="d-sm-flex justify-content-between text-secondary">
          <Typography.Title level={4}>Service Visits</Typography.Title>
          <RenderItem isTrue={visitCount > 0 && isActiveContract}>
            <Button
              className="d-flex align-items-center h-max ml-auto p-0 r-bold align-items-centerback_link primary"
              type="link"
              onClick={createBtnHandler}
            >
              Create ({visitCount} more)
              <div className="ms-2 d-flex justify-content-center align-items-center
                            text-centerp-0 small_icon rounded-circle"
              >
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Button>
          </RenderItem>
        </div>
        {
          serviceVisitStore?.visits?.map((visit: any, index: number) => (
            <VisitList
              status={Constant.serviceVisitCardStatus.get(
                visit_id === visit?._id ? visitStatus : visit?.status,
              ) as string}
              index={((pageNumber - 1) * (limit as number)) + (index + 1)}
              visitCode={visit?.visit_code}
              scheduled_time={visit_id === visit?._id ? scheduledTime : visit?.scheduled_time}
              goToVisitDetails={() => goToVisitDetails(visit?._id)}
              key={visit?._id}
              moveToDetailPage={moveToDetailPage}
            />
          ))
        }
        <Pagination
          total={serviceVisitStore?.total as number}
          currentPage={pageNumber}
          pageSize={limit as number}
          onChange={(page: number) => {
            setPageNumber(page);
            getServicesVisits(page);
          }}
        />
      </Card>
      <ScheduleNextVisit
        isVisible={showCreateVisitModal}
        onCancel={visitModalClose}
        createServicesVisits={createServicesVisits}
        dateRule={rule?.dateRule}
        timeRule={rule?.timeRule}
        due_date={due_date}
        form={form}
        scheduledTimeList={scheduledTimeList}
        isLoading={isLoading}
      />
    </RenderItem>
  );
}

export default inject('serviceVisitStore', 'contractStore')(observer(ScheduleVisit));
