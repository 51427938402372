/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Row, Select, Col, Input, Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Icon } from '../../Components';
import utility from '../../Global/Utility';
import rules from './create-customer-form.validation';

interface AddAddressProps{
    isVisible: boolean,
    handleCancel: () => void,
    handleOk: (values: any) => void,
    countryData: any,
    isAddressUpdate: boolean,
    address: any
}

const { Option } = Select;
function AddAddress(props: AddAddressProps) {
  const {
    isVisible, handleCancel, handleOk, countryData, isAddressUpdate, address,
  } = props;

  const [selectedCountryName, setSelectedCountryName] = useState<any>(null);
  const [selectedStateName, setSelectedStateName] = useState<any>(null);

  const [form] = useForm();

  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };
  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  const beforeOk = (values: any) => {
    form.resetFields();
    handleOk(values);
  };

  const beforeCancel = () => {
    form.resetFields();
    handleCancel();
  };

  useEffect(() => {
    if (address && isAddressUpdate) {
      form.setFieldsValue({
        country: address?.country,
        state: address.state,
        city: address.city,
        zip_code: address.zip_code,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
      });
    }
  }, [address, isAddressUpdate]);

  return (
    <Modal
      title={isAddressUpdate ? 'Update Address' : 'Add Address'}
      visible={isVisible}
      onCancel={beforeCancel}
      destroyOnClose
      footer={null}
      className="cmn_modal_wrapper"
      width={800}
      centered
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
              )}
    >
      <Form layout="vertical" onFinish={beforeOk} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Row gutter={16} className="mt-3">
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="country" className="mandatory" name="country" rules={rules.country}>
              <Select
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose country"
                className="slct_style"
                dropdownClassName="slct_drp_dwn border-radius"
                onChange={countryChanged}
              >
                {countryData && Array.from(countryData?.keys())
                  ?.map((country:any) => <Option value={country}>{country}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="state" className="mandatory" name="state" rules={rules.state}>
              <Select
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose State"
                className="slct_style"
                dropdownClassName="slct_drp_dwn border-radius"
                onChange={stateChanged}
              >
                {selectedCountryName
                            && countryData?.get(selectedCountryName)?.keys()
                            && Array?.from(countryData?.get(selectedCountryName)?.keys())
                              ?.map((state:any) => <Option value={state}>{state}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="city" className="mandatory" name="city" rules={rules.city}>
              <Select
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose City"
                className="slct_style"
                dropdownClassName="slct_drp_dwn border-radius"
              >
                {selectedStateName
                            && countryData?.get(selectedCountryName)?.keys()
                            && countryData.get(selectedCountryName).get(selectedStateName)
                              ?.cities?.map((state:any) => <Option value={state}>{state}</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="POST CODE" name="zip_code" normalize={utility.trimSpacesInValues} rules={rules.zip_code}>
              <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="Address Line 1" name="address_line_1" normalize={utility.trimSpacesInValues} rules={rules.addressLine1}>
              <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Form.Item label="Address Line 2" name="address_line_2" normalize={utility.trimSpacesInValues} rules={rules.addressLine2}>
              <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex mt-4">
          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
            {isAddressUpdate ? 'Update Address' : 'Add Address'}
          </Button>
          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={beforeCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddAddress;
