/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, action, makeObservable } from 'mobx';
import facilityService from '../Services/facilityService';
import Constant from '../Global/Constant';
import {
  Facility, FacilityLog, FacilityLogQueryParams, faciltyQuery,
} from '../Global/interface';

export interface IFacilityStoreProps {
  facilities: Array<Facility>,
  facility: Facility,
  historyTotal: number,
  total: number,
  facilityLogs: Array<FacilityLog>,
  facilityLogTotal: number,
  initialize: Function
  getFacilityLog: (queryParms: FacilityLogQueryParams) => any
  getFacilityById: (id: string) => any
  createFacility: (facility: any) => void
  updateFacility: (facility: any) => void
}

class FacilityStore {
  facilities: Array<Facility>|null = null;

  facility: Facility|null = null;

  facilityHistory: Array<Facility> | null = null;

  historyTotal: number = 0;

  total: number = 0;

  facilityLogs: FacilityLog | null = null;

  facilityLogTotal: number = 0;

  constructor() {
    makeObservable(this, {
      facilities: observable,
      facility: observable,
      facilityHistory: observable,
      historyTotal: observable,
      facilityLogs: observable,
      facilityLogTotal: observable,
      total: observable,
      getFacilities: action,
    });
    this.initialize();
  }

  initialize = () => {
    this.facilities = null;
    this.total = 0;
    this.facilityHistory = null;
    this.historyTotal = 0;
    this.facilityLogs = null;
    this.facilityLogTotal = 0;
  }

  createFacility = async (facility: any) => {
    try {
      const response = await facilityService.createFacility(facility);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getFacilities = async (
    keyword?: string | null, pageNumber?: number, count?: number, order?: string, orderBy?: number,
  ) => new Promise((resolve, reject) => {
    facilityService.getFacilities({
      keyword, page: pageNumber, limit: count, order, orderBy,
    })
      .then((response: any) => {
        if (response && response.data) {
          this.facilities = response.data.facility;
          this.total = response.data.total;
        }
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getFacilityById = async (id: string) => new Promise((resolve, reject) => {
    facilityService.getFacilityById(id)
      .then((response: any) => {
        if (response && response.data) {
          this.facility = response.data.facility;
        }
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  updateFacility = async (id: string, facility: any) => new Promise((resolve, reject) => {
    facilityService.updateFacility(id, facility)
      .then(() => {
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getFacilityHistory = async (queryParams: faciltyQuery) => new Promise((resolve, reject) => {
    facilityService.getFacilities(queryParams)
      .then((response: any) => {
        if (response && response?.data) {
          this.facilityHistory = response?.data?.facility;
          this.historyTotal = response?.data?.total;
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getFacilityLog = async (queryParams: FacilityLogQueryParams) => new Promise((resolve, reject) => {
    facilityService.getFacilityLog(queryParams)
      .then((response: any) => {
        if (response && response?.data) {
          this.facilityLogs = response?.data?.parkingLogs?.parkingLog;
          this.facilityLogTotal = response?.data?.parkingLogs?.total[0]?.counts;
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })
}

export default new FacilityStore();
