/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import {
  Button, Form, Modal, Select,
} from 'antd';
import React from 'react';
import { Icon } from '..';
import Rule from './vehicle.select.validation';

interface VehicleSelectorProps {
    vehicleList: any,
    isModalVisible: boolean,
    className?: string,
    handleCancel: any,
    handleOk: (values: any) => void,
    isLoading: boolean,
    contractId?: string,
    current_vehicle?: string
    showSwapVehicle: boolean
}

VehicleSelector.defaultProps = {
  className: 'doc_modal_wrapper cmn_modal_wrapper',
  contractId: '',
  current_vehicle: '',
};

function VehicleSelector(props: VehicleSelectorProps) {
  const {
    vehicleList, isModalVisible, className, handleCancel, handleOk, isLoading, contractId,
    showSwapVehicle, current_vehicle,
  } = props;
  const { Option } = Select;
  return (
    <Modal
      title={showSwapVehicle ? 'Swap Vehicle' : 'Add Vehicle'}
      centered
      footer={null}
      className={className}
      visible={isModalVisible}
      maskClosable={!isLoading}
      onCancel={handleCancel}
      destroyOnClose
      width={500}
      closeIcon={(
        <Button disabled={isLoading} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                    )}
    >
      <Form layout="vertical" onFinish={handleOk} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Form.Item label="VEHICLE" className="mandatory" name="vehicle" rules={Rule.vehicle}>
          <Select
            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
            placeholder="Choose Vehicle"
            className="slct_style"
            dropdownClassName="slct_drp_dwn border-radius"
          >
            {vehicleList?.length && vehicleList?.map((vehicle:any) => (
              <Option
                className="varient_option"
                disabled={vehicle?.contractPlan?.plan_type === 'VAULT' || vehicle?.contract?._id === contractId || vehicle?._id === current_vehicle}
                value={vehicle?._id}
              >
                <div className="d-flex align-items-center">
                  {`${vehicle?.model?.company} ${vehicle?.model?.model}`}
                </div>
                <div>{vehicle?.vehicle_no ? vehicle?.vehicle_no : vehicle?.vehicle_code}</div>

              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="d-flex mt-4">
          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
            {showSwapVehicle ? 'Swap Vehicle' : 'Add Vehicle'}
          </Button>
          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
export default VehicleSelector;
