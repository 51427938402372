import React from 'react';
import { Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';

interface SidebarInfoProps {
  title: string,
  infoText: string
}

function SidebarInfo(props: SidebarInfoProps) {
  const { infoText, title } = props;
  return (
    <Typography>
      <Title className="text-uppercase r-medium r-side-info" level={5}>{title}</Title>
      <Paragraph>{infoText}</Paragraph>
    </Typography>
  );
}

export default SidebarInfo;
