/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Radio,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import './Contract.less';
import classNames from 'classnames';
import moment from 'moment';
import Toast from '../../Components/Toast';
import {
  Icon, NoData, Pagination, PlanCardView, Search,
} from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import SortTh from '../../Components/SortTh';
import Routes from '../../Global/Routes';
import { ContractData } from '../../Global/interface';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
const {
  ACTIVE, EXPIRING, PAYMENT_PENDING, EXPIRED,
} = Constant.contractTypes;

function Contracts(props: any) {
  const { contractStore, settingsStore } = props;
  const { contracts } = contractStore;
  const { settings } = settingsStore;

  const history = useHistory();
  const location = useLocation();

  const queryParams: any = queryString.parse(location?.search);
  const defaultPlanType = queryParams?.tab && queryParams?.tab !== 'undefined' && queryParams?.tab !== EXPIRED
    ? queryParams?.tab : EXPIRING;

  const getOrderBYTab = (planType:any) => (planType === PAYMENT_PENDING ? 'contract_code' : 'due_date');
  const getOrderByBYTab = (planType:any) => (planType === PAYMENT_PENDING ? 1 : 1);

  const defaultOrder = getOrderBYTab(defaultPlanType);
  const defaultOrderBy = getOrderByBYTab(defaultPlanType);

  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setorderBy] = useState(defaultOrderBy);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [contractStatus, setContractStatus] = useState(defaultPlanType);
  const [collapsed, setCollapsed] = useState(false);

  const getSettings = async () => {
    try {
      await settingsStore.getSettings();
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const fetchContract = async (sort: string,
    sortBy: number, contract_status: string, page: number, planKeyword: string) => {
    setIsLoading(true);
    try {
      let planStatus : string | Array<string> = contract_status;
      if (contract_status === EXPIRING) {
        planStatus = [EXPIRING, EXPIRED];
      }
      await contractStore.getContractList(sort, sortBy, planStatus, page, planKeyword);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const Columns = [
    {
      title: <SortTh title="Plan ID" dataIndex="contract_code" orderBy={orderBy} order={order} />,
      show: true,
      dataIndex: 'contract_code',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      show: true,
      dataIndex: 'plan_type',
      key: 'plan_type',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      show: true,
      dataIndex: 'plan_term',
      key: 'plan_term',
      ellipsis: true,
    },
    {
      title: 'Client',
      show: true,
      dataIndex: 'name',
      key: 'client',
      ellipsis: true,
    },
    // {
    //   title: 'Client Type',
    //   dataIndex: 'client_type',
    //   key: 'client_type',
    //   ellipsis: true,
    // },
    {
      title: <SortTh title="Due Date" dataIndex="due_date" orderBy={orderBy} order={order} />,
      dataIndex: 'due_date',
      show: contractStatus !== PAYMENT_PENDING,
      key: 'due_date',
      ellipsis: true,
      render: (value:any) => ({
        props: { 'data-heading': 'Due Date' },
        children: value,
      }),
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      show: contractStatus === EXPIRING || contractStatus === ACTIVE,
      key: 'status',
      ellipsis: true,
      render: (value:any) => ({
        props: { 'data-heading': 'Status' },
        children: value,
      }),
    },
  ].filter((row: any) => row.show);

  const sortTable = (dataIndex: any) => {
    let sortBy = -1;
    if (orderBy === -1 && dataIndex === order) sortBy = 1;
    else if (dataIndex !== order) setOrder(dataIndex);
    setorderBy(sortBy);
    fetchContract(dataIndex, sortBy, contractStatus, pageNumber, keyword);
  };

  const pageHandler = (page: number) => {
    setPageNumber(page);
    fetchContract(order, orderBy, contractStatus, page, keyword);
    window.scrollTo(0, 0);
  };

  const getExpirationStatus = (
    dueDate: any, paymentStatus: boolean|undefined, contract_status: String|undefined,
  ) => {
    if (paymentStatus) {
      return dueDate > Date.now()
        ? EXPIRING : EXPIRED;
    }
    return contract_status;
  };

  const DataSource = contracts?.data?.map((contract: any) => {
    const status = getExpirationStatus(
      contract?.due_date, contract?.payment_status, contract?.contract_status,
    );
    return {
      contract_code: contract?.contract_code,
      plan_type: contract?.contract[0]?.plan,
      name: <div className="text-capitalize text-truncate">{contract?.client[0]?.first_name} {contract?.client[0]?.middle_name || ''} {contract?.client[0]?.last_name}</div>,
      plan_term: Utility.getFullDuration(contract?.duration, contract?.duration_type),
      due_date: Utility.getFormattedDate(contract?.due_date),
      rowKey: contract?._id,
      status: (
        <Tooltip overlayClassName="text-capitalize" title={status}>
          <div className={classNames('rounded-pill ps-2 pe-2 w-xl-100 d-inline-block ellipse align-items-center justify-content-center fs-16 mr-3',
            {
              label_danger: status === EXPIRED,
              label_warning: status === EXPIRING,
            })}
          >{Utility.getRelativeDays(contract?.due_date)}
          </div>
        </Tooltip>),
    };
  });

  // FREE lot ui
  //  <div className="label_success
  // rounded-pill ps-2 pe-2 w-100 d-inline-block ellipse text-center"
  // title="Pending sign up">Free Lot</div>

  // BOOKED lot UI
  // <div className="label_inactive rounded-pill ps-2 pe-2 w-100 d-inline-block
  //  ellipse text-center" title="Pending sign up">10-56465</div>

  const planDebouncedSave = useCallback(
    debounce((nextValue: string, sort:string, contractType: string, sortBy:number) => {
      setPageNumber(Constant.defaultPageNumber);
      fetchContract(
        sort,
        sortBy,
        contractType, Constant.defaultPageNumber, nextValue,
      );
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (event: any) => {
    const { value: nextValue } = event.target;
    setKeyword(nextValue);
    planDebouncedSave(nextValue, order, contractStatus, orderBy);
  };
  const goToContractDetails = (record: any) => { history.push(`${Routes.contract_detail}/${record?.rowKey}?tab=${contractStatus}&from=PLAN`); };

  useEffect(() => {
    fetchContract(order, orderBy, contractStatus, pageNumber, keyword);
  }, [contractStatus]);

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="Plans" />
    ),
  };

  const filterHandler = (event: any) => {
    setContractStatus(event.target.value);
    setOrder(getOrderBYTab(event.target.value));
    setorderBy(getOrderByBYTab(event.target.value));
    setPageNumber(Constant.defaultPageNumber);
    history.push(`${Routes.contracts}?tab=${event.target.value}`);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={Routes.plan_history} className="d-flex primary ml-auto r-bold align-items-center existing_clnt_link p-0">
          Plan History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const getPlanStatus = (
    dueDate: any, paymentStatus: boolean|undefined,
  ) => {
    if (dueDate < moment(Date.now())) return EXPIRED;

    const isExpiringContract = dueDate - Date.now()
     <= settings?.plan_expiring_time * Constant.daysInMilliSeconds;
    if (isExpiringContract) return EXPIRING;

    if (paymentStatus) return ACTIVE;

    return PAYMENT_PENDING;
  };

  const goToPlanDetails = (planId: string) => history.push(`${Routes.contract_detail}/${planId}?tab=${contractStatus}&from=PLAN`);

  useEffect(() => {
    contractStore?.initialize();
    getSettings();
  }, []);
  return (
    <Layout.Content className="listing_wrapper plan_listing">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
                  Plans
                </Typography.Title>
              </div>
              <div className="d-flex flex-fill justify-content-between justify-content-sm-end align-items-center">
                <Radio.Group
                  onChange={filterHandler}
                  defaultValue={contractStatus}
                  buttonStyle="solid"
                  className="radio_tab client_plan_tab d-flex gray_bg w-100 border-radius ms-xxl-4 ms-xl-0 ms-sm-4 mt-xxl-0 mt-xl-3 mt-sm-0 mt-3"
                >
                  <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={EXPIRING}>EXPIRING</Radio.Button>
                  <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={ACTIVE}>ACTIVE</Radio.Button>
                  <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={PAYMENT_PENDING}>PENDING</Radio.Button>
                </Radio.Group>
                <div className="action_resp d-xl-none act_position ms-sm-3">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="contract_listing">
              <div className="" id={contractStatus}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-sm-flex align-items-center flex-fill">
                    <h4 className="mb-0 table-header text-capitalize r-bold">{contractStatus === PAYMENT_PENDING ? 'Pending' : contractStatus} Plans</h4>
                    <Search
                      onChange={handleSearchTextChange}
                      value={keyword}
                      titleToolTip={`Search ${contractStatus === PAYMENT_PENDING ? 'Pending' : contractStatus}`}
                      placeholder="Search"
                      size="small"
                    />
                  </div>
                </div>
                <div className="d-none d-sm-block">
                  <Table
                    rowClassName="cursor-pointer"
                    columns={Columns}
                    dataSource={DataSource}
                    pagination={false}
                    size="small"
                    scroll={{ x: '800px' }}
                    onRow={(record) => ({ onClick: () => goToContractDetails(record) })}
                    className="table_wrapper"
                    locale={locale}
                  />
                  <Pagination
                    total={contracts?.total}
                    currentPage={pageNumber}
                    pageSize={Constant.contractPerPage}
                    onChange={pageHandler}
                  />
                </div>
                <div className="d-sm-none">
                  {contracts?.data?.map((contract: ContractData) => {
                    const {
                      _id, due_date, start_date, contract_code, contract: contractPlan,
                      model, last_penalty_date, duration, payment_status, client, active_vehicle,
                      duration_type,
                    } = contract;
                    return (
                      <PlanCardView
                        key={_id}
                        dueDate={due_date}
                        isLoading={isLoading}
                        startDate={start_date}
                        plan_code={contract_code}
                        plan={contractPlan[0]?.plan}
                        modelCategory={model?.category}
                        activeVehicle={active_vehicle[0]}
                        modelImage={model?.image?.fileUrl}
                        last_penalty_date={last_penalty_date}
                        planType={contractPlan[0]?.plan_type}
                        dueAmount={settings?.vault_plan_due_amount}
                        goToPlanDetails={() => goToPlanDetails(_id)}
                        duration={Utility.getFullDuration(duration, duration_type)}
                        status={getPlanStatus(due_date, payment_status)}
                        planlabelColor={contractStatus === EXPIRING ? 'white' : 'secondary'}
                        client={`${client[0]?.first_name} ${client[0]?.middle_name || ''} ${client[0]?.last_name}`}
                      />
                    );
                  })}
                  <Pagination
                    total={contracts?.total}
                    currentPage={pageNumber}
                    pageSize={Constant.contractPerPage}
                    onChange={pageHandler}
                  />
                  {!contracts?.data?.length && <NoData subTitle="Plans" /> }
                </div>
              </div>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the Plans</Paragraph>
            </Typography>
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
              <Tooltip title="Plan history">
                <Link to={Routes.plan_history} className="d-flex primary ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                  Plan History
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('contractStore', 'settingsStore')(observer(Contracts));
