/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import Button from 'antd/lib/button';
import { Col, Row } from 'antd';
import fileSize from 'filesize';
import docicon from '../../Assets/Images/ic_delete_mini.svg';

interface Props {
    documents: any;
    size?:number;
    removeFile: (index: number) => void
}

const UploadDocuments = (props: Props) => {
  const { documents, removeFile, size } = props;

  return (
    documents.map((document : any, index : number) => (
      <Row className="mb-3" key={document?._id}>
        <Col sm={size || 20} xs={24}>
          <div className="document_wrapper">
            <div className="d-flex d-res-block justify-content-between border-bottom pt-2 pb-2 fs-16 align-items-center">
              <div className="document_list_wrap d-flex align-items-center w-75 w-res-100">
                <span className="text-truncate" title={document?.fileName}>{index + 1}. {document?.fileName}</span>
                <Button className="doc_icon ms-2 d-flex justify-content-end align-items-center p-0 pe-2 border-0 shadow-none" onClick={() => { removeFile(index); }}>
                  <img src={docicon} alt="doc_close" />
                </Button>
              </div>
              <p className="mb-0 text-truncate" title={document?.fileSize && fileSize(document?.fileSize)}>{document?.fileSize && fileSize(document?.fileSize)}</p>
            </div>
          </div>
        </Col>
      </Row>
    )));
};

export default UploadDocuments;
