/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd';
import './DashboardShop.less';
import debounce from 'lodash.debounce';
import { useForm } from 'antd/lib/form/Form';
import { Action, Icon, Toast } from '../../Components';
import productStore from '../../Store/productStore';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import Rules from './create-order-form-validation';
import orderStore from '../../Store/orderStore';
import { Product, Variants } from '../../Global/interface';

const columns = [
  { title: '', dataIndex: 'product', width: '29%' },
  { title: '', dataIndex: 'varient', width: '29%' },
  { title: '', dataIndex: 'quantity', width: '13%' },
  { title: '', dataIndex: 'action' },
  { title: '', dataIndex: 'amount' },
];
function DashboardShop(props: any) {
  const { getOrderCount } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [data, setData] = useState<any>([]);
  const [selectedProductDetails, setSelectedProductDetails] = useState<any>(null);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState<any>(null);
  const [deleteIndex, setDeleteIndex] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [invoiceDetails, setInvoiceDetails] = useState<any>([]);
  const [showAccept, setShowAccept] = useState(false);
  const onProductSearch = (searchText: string) => {
    searchDebouncing(searchText);
  };

  const searchDebouncing = useCallback(
    debounce((nextValue: string) => {
      getProduct(nextValue);
    }, Constant.debaounceTime),
    [],
  );

  const onSelectProduct = async (option: string) => {
    form.setFieldsValue({ variant: null, quantity: null });
    await productStore?.getProductById(option);
    setSelectedProductDetails(productStore?.product);
    setShowAccept(true);
  };

  const getProduct = async (keyword?: string) => {
    try {
      setIsLoading(true);
      await productStore.getProducts({ keyword });
      setIsLoading(false);
    } catch (error:any) {
      Toast.error(error);
      setIsLoading(false);
    }
  };

  const onSelectVariant = (variantId: string) => setSelectedVariantDetails(
    productStore?.product?.variants?.find((item: any) => item?._id === variantId),
  );

  const validateQuantity = () => {
    if (form.getFieldValue('quantity') <= 0) {
      form.resetFields(['quantity']);
    }
    if (form.getFieldValue('quantity') > selectedVariantDetails?.quantity) {
      Toast.error(`Quantity exceeded maximum ${selectedVariantDetails?.quantity}`);
      form.resetFields(['quantity']);
    }
  };

  const onAccept = async () => {
    const quantity = form.getFieldValue('quantity');
    setTotalAmount(totalAmount as number + (selectedVariantDetails?.price * quantity));
    if (quantity === 0) {
      Toast.error('Plese choose a valid quantity');
    }
    setInvoiceDetails([...invoiceDetails, {
      product: selectedProductDetails,
      variant: selectedVariantDetails,
      quantity,
    }]);
    resetFields();
  };

  const onReject = () => resetFields();

  const resetFields = () => {
    setSelectedProductDetails(null);
    setSelectedVariantDetails(null);
    form.resetFields();
    setShowAccept(false);
  };

  const setInvoiceTableData = () => {
    setData(invoiceDetails?.map((invoice: any, index: number) => ({
      product: `${index + 1}. ${invoice?.product?.name}`,
      varient: `${invoice?.variant?.color?.name} - ${invoice?.variant?.size}`,
      quantity: invoice?.quantity,
      action:
  <Button className="item_delete p-0 border-radius red d-flex align-items-center justify-content-center border-0" onClick={() => beforeDelete(index)}>
    <Icon className="icon-16" iconRef="#delete" />
  </Button>,
      amount: `${invoice?.variant?.price * invoice?.quantity} KD`,
    })));
  };

  useEffect(() => {
    setInvoiceTableData();
  }, [invoiceDetails]);

  const beforeDelete = (index: number) => setDeleteIndex(index);

  useEffect(() => {
    if (deleteIndex !== undefined) {
      deleteItem(deleteIndex as number);
      setDeleteIndex(undefined);
    }
  }, [deleteIndex]);

  const deleteItem = (index: number) => {
    const deletedInvoice = invoiceDetails[index];
    invoiceDetails?.splice(index, 1);
    setInvoiceDetails(invoiceDetails);
    setInvoiceTableData();
    setTotalAmount(
      totalAmount as number - (deletedInvoice?.variant?.price * deletedInvoice?.quantity),
    );
  };

  const downloadInvoice = async (url: string, fileName: string) => {
    const downloadUrl = await Utility.createObjectUrlFromUrl(url);
    await Utility.downloadFile(downloadUrl, `Invoice-quickOrder-${fileName}.pdf`);
  };

  const printInvoice = async () => {
    try {
      setIsLoading(true);
      const invoiceData = invoiceDetails.map((invoice: any) => ({
        variant: invoice?.variant?._id,
        quantity: invoice?.quantity,
      }));
      const response: any = await orderStore?.createQuickOrder({ order_item: invoiceData });
      await downloadInvoice(response?.invoiceUrl, response?.groupId);
      clearInvoice();
      resetFields();
      getOrderCount();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const clearInvoice = () => {
    setTotalAmount(0);
    setInvoiceDetails([]);
  };

  useEffect(() => {
    getProduct();
    setTotalAmount(0);
  }, []);

  const filteredProduct = productStore?.products?.products?.filter((
    product: Product,
  ) => product?.variants?.length);

  const variantFilter = (input: any, option: any) => (
    option?.children[0]?.props?.children[2]?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
    || `${option?.children[1]?.props?.children[0]}`?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0);

  return (
    <Layout.Content>
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col md={24} lg={24} sm={24} xs={24}>
            <Typography.Title level={5} className="text-uppercase fs-14 mb-4 primary r-medium">QUICK ORDER</Typography.Title>
            <Card bodyStyle={{ padding: 0 }} className="py-3">
              <Typography.Title level={3} className="r-bold px-3">CREATE QUICK ORDER</Typography.Title>
              <Form layout="vertical" form={form} onFinish={onAccept} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row gutter={[16, 16]} className="px-3">
                  <Col lg={7} md={7} sm={12} xs={12}>
                    <Typography.Text className="r-medium secondary text-uppercase">product</Typography.Text>
                    <Form.Item name="product" className="mandatory" normalize={Utility.trimSpacesInValues} rules={Rules.product}>
                      <Select
                        showSearch
                        defaultOpen={false}
                        filterOption={false}
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        placeholder="Select product"
                        className="slct_style"
                        dropdownClassName="slct_drp_dwn border-radius"
                        onSearch={onProductSearch}
                        onChange={onSelectProduct}
                      >
                        {filteredProduct?.map((product: Product) => (
                          <Select.Option key={product?._id} value={product?._id}>
                            {product?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={7} md={7} sm={12} xs={12}>
                    <Typography.Text className="r-medium secondary text-uppercase">VARIANT</Typography.Text>
                    <Form.Item name="variant" className="mandatory" normalize={Utility.trimSpacesInValues} rules={Rules.variant}>
                      <Select
                        showSearch
                        disabled={!selectedProductDetails}
                        defaultOpen={false}
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        placeholder="Select variant"
                        className="slct_style  w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        onChange={onSelectVariant}
                        filterOption={variantFilter}
                      >
                        {productStore?.product?.variants.map((variant: Variants) => (
                          <Select.Option
                            className="varient_option"
                            key={variant?._id}
                            value={variant?._id}
                            disabled={
                            invoiceDetails?.findIndex(
                              (item: any) => item?.variant?._id === variant?._id,
                            ) !== -1 || !variant?.quantity
                          }
                          >
                            <div className="d-flex align-items-center"> <span
                              className="veh_color d-inline-block me-2 mt-0 rounded-circle v_color_display"
                              style={{ backgroundColor: variant?.color?.code }}
                            />
                              {variant?.size}
                            </div>
                            <div>{variant?.price} KD</div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={3} md={3} sm={12} xs={12}>
                    <Typography.Text className="r-medium secondary text-uppercase">QUANTITY</Typography.Text>
                    <Form.Item name="quantity" className="mandatory" normalize={Utility.trimSpacesInValues} rules={Rules.quantity}>
                      <Input
                        placeholder="Enter quantity"
                        className="inp_style"
                        type="number"
                        maxLength={5}
                        disabled={!selectedVariantDetails}
                        max={selectedVariantDetails?.quantity}
                        min={1}
                        onChange={validateQuantity}
                      />
                    </Form.Item>
                  </Col>
                  {showAccept ? (
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <Typography.Text className="r-medium secondary text-uppercase ms-2">ACTION</Typography.Text>
                      <div className="d-flex h-40 align-items-center">
                        <Action
                          onAccept={() => {}}
                          onReject={onReject}
                          onWait={() => {}}
                          formType
                        />
                      </div>
                    </Col>
                  ) : '' }
                </Row>
              </Form>
              {data?.length
                ? (
                  <Table
                    columns={columns}
                    dataSource={[...data]}
                    className="table_style_inherit"
                    pagination={false}
                    scroll={{ x: '800px' }}
                    locale={{ emptyText: <></> }}
                  />
                ) : ''}
              {invoiceDetails?.length
                ? (
                  <div className="d-sm-flex justify-content-between align-items-center mt-4 flex-wrap px-3 flex-sm-row-reverse">
                    <div className="fs-16 r-bold secondary d-flex justify-content-between align-items-center mb-3 mb-sm-0">
                      Total Amount
                      <Typography.Text className="primary ms-3">{totalAmount} KD</Typography.Text>
                    </div>
                    <div className="d-flex">
                      <Button htmlType="submit" disabled={isLoading} className="main_btn me-3 fs-16 r-bold" onClick={printInvoice}>
                        Print Invoice
                      </Button>
                      <Button type="text" className="cancel_btn fs-16 r-bold px-0 secondary" onClick={clearInvoice}>
                        Clear
                      </Button>
                    </div>
                  </div>
                ) : ''}
            </Card>
          </Col>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default observer(DashboardShop);
