/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
import { observable } from 'mobx';
import Constant from '../Global/Constant';
import authService from '../Services/authService';
import Utility from '../Global/Utility';
import { Toast } from '../Components';
import { getFirebaseToken } from '../firebase';

type userType = {
  id: string,
  name: string,
  email : string,
  expiryTime: number,
  access: Array<any>;
}

const getCurrentUser = () => {
  if (localStorage.token) {
    return Utility.parseJwt(localStorage?.token);
  }
  return null;
};

export interface AuthStoreProps {
  error: string,
  authListener: ((user:any) => void) | null,
  currentUser: userType | null,
  addAuthListener: (callbackListener : (user:any) => void) => void,
  containsRequiredUserRoles: () => void,
  signIn: ()=> void,
  signOut: ()=> void,
  forgotPasswordRequest: ()=> void,
  resetPassword: ()=> void,
  setPassword: ()=> void
}

class AuthStore {
    error : string = '';

    authListener: ((user:any) => void) | null = null;

    currentUser : userType | null = null;

    addAuthListener(callbackListener : (user:any) => void) {
      this.authListener = callbackListener;
      this.currentUser = getCurrentUser();

      // check token expire
      if (this.currentUser && (this.currentUser.expiryTime < new Date().getTime())) {
        this.signOut();
      }

      if (this.authListener) {
        this.authListener(this.currentUser);
      }
    }

    containsRequiredUserRoles(userAccess: Array<number>) {
      const {
        SUPER_ADMIN,
        EMPLOYEE,
      } = Constant.userAccess;

      if (
        [
          SUPER_ADMIN,
          EMPLOYEE,
        ].filter((val: any) => userAccess.includes(val)).length > 0
      ) {
        return true;
      }
      return false;
    }

    async signIn(email: string, password: string, callback: any) {
      try {
        const resp = await authService.login({ email, password });
        const { accessToken, refreshToken } = resp.data.tokens;
        const user = Utility.parseJwt(accessToken);

        if (this.containsRequiredUserRoles(user.access)) {
          localStorage.setItem('token', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          this.currentUser = user;
          callback(null);
        } else {
          callback(Constant.unAuthorizedError);
        }
      } catch (err: any) {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      }
    }

    async signOut() {
      try {
        const token = await getFirebaseToken() as string;
        await authService.unsyncToken({ token });

        localStorage.removeItem(Constant.token);
        localStorage.removeItem(Constant.refreshToken);
        this.currentUser = null;
        if (this.authListener) {
          this.authListener(this.currentUser);
        }
      } catch (error: any) {
        Toast.error(error?.response?.data?.error?.message);
      }
    }

    async forgotPasswordRequest(email: string, callback: ((err?: any) => void)) {
      try {
        await authService.forgotPasswordRequest(email);
        callback();
      } catch (err: any) {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      }
    }

    async resetPassword(
      password: string, userId: string, token: string, callback: ((err?: Error) => void),
    ) {
      try {
        const data = { password, token, userId };
        await authService.resetPassword(data);
        callback();
      } catch (err: any) {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(new Error(errorMsg));
      }
    }

    async setPassword(
      password: string, token: string, callback: ((err?: Error) => void),
    ) {
      try {
        const data = { password, token };
        await authService.setPassword(data);
        callback();
      } catch (err: any) {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(new Error(errorMsg));
      }
    }
}

// decorate(AuthStore, {
//   error: observable,
// });

export default new AuthStore();
