/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  InputNumber,
  Layout,
  Menu,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import {
  UserCard, ContactCard, Address, Icon, PaymentMethod, WaitingForPayment, Toast, ConfirmModal,
  OrderItem, StatusLabel,
  ActivityCard,
  RenderItem,
} from '../../Components';

import FullfillSteps from './FulfillSteps';

import { IOrderStore } from '../../Store/orderStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const { ORDER_CANCELED, DELIVERED, ORDER_RETURNED } = Constant?.order_status;

const { GATEWAY } = Constant?.payment_mode;
interface orderDetailsProps {
  orderStore: IOrderStore
}
function OrderDetails(props: orderDetailsProps) {
  const { orderStore } = props;
  const {
    order, category, product, variant, customer, activities,
  } = orderStore;

  const { id } = useParams<any>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isLinkShared, setIsLinkShared] = useState<boolean>(false);
  const [isActivityLoading, setIsActivityLoading] = useState<boolean>(false);
  const [returnQuantity, setReturnQuantity] = useState<any>(1);
  const [returnModelVisible, setReturnModelVisible] = useState<boolean>(false);

  const isOrderActive = order?.order_status !== ORDER_CANCELED && order?.payment_status
  && order?.order_status !== ORDER_RETURNED;
  const showFulfill = order?.order_status !== DELIVERED
                    && isOrderActive && !order?.is_cancel_requested && !order?.is_return_requested;

  const isOrderDelivered = order?.order_status === DELIVERED;
  const isOrderDeleted = order?.deleted;

  const goToCancelDetails = () => history.push(`${Routes.cancel_order_details}/${order?.cancel_request_id}`);

  const goToReturnDetails = () => history.push(`${Routes.return_order_details}/${order?.return_request_id}`);

  const gotoOrders = () => history.push(Routes.orders);

  const maximumQuantity = order?.order_quantity - order?.return_quantity;

  const handleQtyChange = (qty: any) => {
    if (qty < 1 || !qty) Toast.error('Quantity is required.');
    if (qty > maximumQuantity) Toast.error(`Quantity exceeded maximum ${maximumQuantity}.`);
    setReturnQuantity(qty);
  };

  const getOrderDetails = async () => {
    try {
      setIsLoading(true);
      await orderStore.getOrderById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const purchaseOrder = async (details: any) => {
    try {
      setIsLoading(true);
      await orderStore?.purchaseOrder(id, details);
      await getOrderActivities();
      if (details?.payment_mode === GATEWAY) {
        setIsLinkShared(true);
        Toast?.success('Link shared successfully');
      }
      await getOrderDetails();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      Toast?.error(error);
    }
  };

  const getOrderActivities = async () => {
    try {
      setIsActivityLoading(true);
      await orderStore.listOrderActivities({ order_id: id });
      setIsActivityLoading(false);
    } catch (error: any) {
      setIsActivityLoading(false);
      Toast.error(error);
    }
  };

  const updateOrderStatus = async (action: string) => {
    try {
      setIsLoading(true);

      await orderStore.updateOrderStatus(id, { action });
      await getOrderActivities();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      Toast.error(error);
    }
  };

  const cancelOrder = async () => {
    try {
      setIsLoading(true);
      await orderStore?.createCancelOrderRequest(id);
      await getOrderDetails();
      await getOrderActivities();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const closeReturnModal = () => setReturnModelVisible(false);

  const openReturnModal = () => setReturnModelVisible(true);

  const returnOrder = async () => {
    try {
      if (!returnQuantity || returnQuantity <= 0) {
        return Toast.error('Please provide a valid order quantity.');
      }
      if (returnQuantity > maximumQuantity) {
        return Toast.error(`Quantity exceeded maximum ${maximumQuantity}.`);
      }
      setIsLoading(true);
      await orderStore?.createReturnOrderRequest(id, { quantity: returnQuantity });
      closeReturnModal();
      await getOrderDetails();
      await getOrderActivities();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const deleteOrder = async () => {
    try {
      setIsLoading(true);
      await orderStore?.deleteOrder(id);
      await getOrderDetails();
      await getOrderActivities();
      setIsLoading(false);
      Toast.success('Order deleted successfully.');
      await gotoOrders();
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const confirmCancel = () => {
    ConfirmModal('Cancel Order', 'Do you want to cancel this order?', cancelOrder, 'Yes');
  };

  const deleteConfirmText = order?.payment_link_sent && !order?.payment_status ? 'A payment link was sent to the customer. ' : '';

  const confirmDelete = () => {
    ConfirmModal('Delete Order', `${deleteConfirmText}Do you want to delete this order?`, deleteOrder, 'Yes');
  };

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const S3Url = await orderStore?.downloadInvoice(id);
      const downloadUrl = await Utility.createObjectUrlFromUrl(S3Url);
      Utility.downloadFile(downloadUrl, `Invoice-${order?.order_code}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  useEffect(() => {
    getOrderDetails();
    getOrderActivities();
  }, [id]);

  const menu = (
    <Menu className="box_shadow border-radius">
      {/* <Menu.Item key="3">
        <Button className="d-flex ml-auto r-bold align-items-center border-0
         h-auto shadow-none bg-transparent p-0 primary">
          Archive Order
          <div className="ms-2 d-flex justify-content-center
          align-items-center text-center p-0 small_icon rounded-circle"
          >
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item> */}
      {showFulfill
              && (
              <Menu.Item key="0">
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto shadow-none bg-transparent" onClick={confirmCancel}>
                  Cancel Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
      {isOrderDelivered && (order?.order_quantity - order?.return_quantity > 0)
              && (
              <Menu.Item key="0">
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto shadow-none bg-transparent" onClick={openReturnModal}>
                  Return Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
      { order?.is_cancel_requested
              && (
              <Menu.Item key="1">
                <Button className="d-flex ml-auto r-bold align-items-center border-0 h-auto p-0 shadow-none primary bg-transparent" onClick={goToCancelDetails}>
                  Cancel Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
      { order?.is_return_requested
              && (
              <Menu.Item key="2">
                <Button className="d-flex ml-auto r-bold align-items-center border-0 h-auto p-0 shadow-none primary bg-transparent" onClick={goToReturnDetails}>
                  Return Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
      {!isOrderDeleted && order?.order_status === 'CREATED' && !order?.payment_status
      && (
      <Menu.Item key="3">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto shadow-none bg-transparent" onClick={confirmDelete}>
          Delete Order
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      )}
    </Menu>
  );

  const getStatusText = () => {
    if (isOrderDeleted) return { text: 'Deleted', className: 'label_danger' };
    if (!order?.payment_status) return { text: 'Payment Pending', className: 'label_warning' };
    if (order?.order_status === ORDER_CANCELED) return { text: 'Cancelled', className: 'label_success' };
    if (order?.order_status === ORDER_RETURNED) return { text: 'Returned', className: 'label_success' };
    return '';
  };

  const statusData: any = getStatusText();

  const amount: any = Utility.getUnitPrice(
    order?.order_quantity, order?.total_amount, order?.delivery_charge,
  );
  return (
    <Spin spinning={isLoading}>
      <Layout.Content className="detail_wrapper clnt_detail">
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.orders}>ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{order?.order_code}</Breadcrumb.Item>
              </Breadcrumb>
              <RenderItem
                isTrue={(order?.order_quantity - order?.return_quantity > 0) && !isOrderDeleted}
              >
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </RenderItem>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center flex-wrap">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24 me-sm-3 me-1">
                    {order?.order_code}
                  </Typography.Title>
                  <StatusLabel className={statusData?.className} text={statusData?.text} />
                </div>
              </div>
              <Row gutter={[16, 16]}>
                <Col lg={16} md={16} xs={24} className="mt-3 mt-md-0">
                  {(order?.payment_link_sent || isLinkShared) && !order?.payment_status
                && <WaitingForPayment time={moment(order.payment_link_sent_date).toDate()} />}
                  <Card>
                    <Typography.Title level={3}>Order Item</Typography.Title>
                    <Row>
                      <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                        <OrderItem
                          category={category?.name}
                          product={product?.name}
                          variant={variant?.color?.name}
                          quantity={order?.order_quantity}
                          amount={amount}
                          image={variant?.images[0]}
                        />
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                    {order?.delivery_charge >= 0 ? (
                      <div className="d-flex justify-content-end align-items-center fs-16 secondary mt-3">
                        Express Delivery
                        <Typography.Text className="primary fs-16 r-bold ms-3">{order?.delivery_charge} KD</Typography.Text>
                      </div>
                    ) : <></> }
                    {order?.delivery_charge >= 0 ? <Divider className="my-3 border-top" /> : <></> }
                    <div className="d-flex justify-content-end align-items-center fs-16 r-bold secondary mt-4">
                      Total Amount
                      <Typography.Text className="primary fs-24 ms-3">{order?.total_amount} KD</Typography.Text>
                    </div>
                    <Divider className="my-3 border-top" />
                    {order?.payment_status
                    && (
                    <div className="d-flex align-items-center">
                      <Button className="primary gray_bg h-40 border-radius border-0 fs-16 r-bold" onClick={downloadInvoice}>
                        Download Invoice
                      </Button>
                    </div>
                    )}
                  </Card>
                  {!(order?.payment_status || isOrderDeleted)
                && (
                <Row className="mt-3">
                  <PaymentMethod
                    paymentHeading="Choose how you want to pay for the order."
                    finishHandler={purchaseOrder}
                    email={customer?.email}
                    showMethod={false}
                    phone={customer?.phone}
                    okButtonText="Confirm Payment"
                    userType="customer"
                  />
                </Row>
                )}
                  {/* fulfill order steps */}
                  {showFulfill && !isOrderDeleted
                  && (
                  <FullfillSteps
                    currentDot={Constant?.fulfillCurrentDot?.get(order?.order_status) as number}
                    actionFn={updateOrderStatus}
                  />
                  )}
                  <Card className="timeline_card mt-3" loading={isActivityLoading}>
                    <Typography.Title level={3}>Timeline</Typography.Title>
                    {activities && activities?.length
                      ? (
                        <ActivityCard
                          dataSource={activities}
                          currentUser={authStore?.currentUser}
                        />
                      ) : 'No activity'}
                  </Card>
                </Col>
                <Col lg={8} md={8} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={customer?.profile?.fileUrl}
                      name={`${customer?.first_name ? `${customer?.first_name} ${customer?.middle_name ? customer?.middle_name : ''} ${customer?.last_name}` : ''} `}
                      cardName="Customer"
                      id={customer?.client_code}
                      path={`${Routes.customer_details}/${customer?._id}`}
                      ordersCount={customer?.order_count}
                    />
                  </Card>
                  {(customer?.phone || customer?.email)
                  // phone update
                  && (
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard
                      contactDetails={
                      {
                        email: customer?.email,
                        phone: customer?.phone,
                      }
}
                    />
                  </Card>
                  )}
                  <Card bordered={false} className="detail_card_pad mb-3">
                    <Address title="Billing Address" addressInfo={order?.billing_address} />
                  </Card>
                  <Card bordered={false} className="detail_card_pad">
                    <Address title="Delivery Address" addressInfo={order?.shipping_address} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the order</Paragraph>
            </Typography>
            <div className="disabled_btn">
              <RenderItem
                isTrue={(order?.order_quantity - order?.return_quantity > 0) && !isOrderDeleted}
              >
                <h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              </RenderItem>
              {showFulfill
              && (
              <Tooltip title="Cancel Order">
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto mb-2" onClick={confirmCancel}>
                  Cancel Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
              {isOrderDelivered && (order?.order_quantity - order?.return_quantity > 0) && (
                <Tooltip title="Return Order">
                  <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto mb-2" onClick={openReturnModal}>
                    Return Order
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                </Tooltip>
              )}
              {/* <Tooltip title="Archive order">
                <Button className="d-flex ml-auto r-bold align-items-center
                primary bg-transparent p-0 h-auto mb-2">
                  Archive order
                  <div className="ms-2 d-flex justify-content-center align-items-center
                  text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip> */}
              { order?.is_cancel_requested
              && (
              <Tooltip title="Cancel Order Request">
                <Button className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto mb-2" onClick={goToCancelDetails}>
                  Cancel Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
              { order?.is_return_requested
              && (
              <Tooltip title="Return Order Request">
                <Button className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto mb-2" onClick={goToReturnDetails}>
                  Return Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
              { !isOrderDeleted && order?.order_status === 'CREATED' && !order?.payment_status
              && (
              <Tooltip title="Delete Order">
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto" onClick={confirmDelete}>
                  Delete Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
            </div>
          </Sider>
        </Row>
        <Modal
          title="Return Item"
          centered
          visible={returnModelVisible}
          onOk={returnOrder}
          confirmLoading={isLoading}
          onCancel={closeReturnModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          className="cmn_modal_wrapper"
          okText="Return"
          okButtonProps={{ className: 'd-flex h-40 border-radius red_bg ms-0 white border-0 r-bold align-items-center', disabled: isLoading }}
          destroyOnClose
          closeIcon={(
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
              <Icon className="icon-10" iconRef="#close" />
            </Button>
)}
        >
          <span className="secondary">Please enter the quantity to return the item(s)?</span>
          <div className="mt-3">
            <Form.Item
              label={<div className="secondary r-medium">ITEM QUANTITY</div>}
              labelAlign="left"
              className="mandatory mb-0 flex-column d-flex"
              required={false}
            >
              <InputNumber
                className="inp_style inp_num_style"
                name="visit count"
                defaultValue={1}
                maxLength={maximumQuantity.toString().length}
                min={0}
                onChange={handleQtyChange}
              />

            </Form.Item>
          </div>
        </Modal>
      </Layout.Content>
    </Spin>
  );
}

export default inject('orderStore')(observer(OrderDetails));
