/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Layout, Typography, Row, Col, Button, Tooltip, Spin,
} from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  Icon, NoData, Pagination, PlanCard, Toast,
} from '../../Components';
import { MasterStoreProps } from '../../Store/masterStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import { ContractPlan, Features } from '../../Global/interface';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface PlanSettingsProps {
    masterStore: MasterStoreProps
}

function PlanSettings(props: PlanSettingsProps) {
  const { masterStore } = props;
  const { vaultContractPlans, total } = masterStore;
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);

  const history = useHistory();

  const listPlans = async (query: any) => {
    try {
      setIsLoading(true);
      await masterStore.getContractPlans(query);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  useEffect(() => {
    try {
      listPlans({ page: pageNumber, limit: Constant?.planPerPage });
      masterStore.getFacilities();
    } catch (error: any) {
      Toast.error(error);
    }
  }, []);

  useEffect(() => {
    listPlans({ page: pageNumber, limit: Constant?.planPerPage });
  }, [pageNumber]);

  const gotoCreatePlan = () => history.push(Routes?.create_plan);
  const gotoPlanDetails = (id: string) => { history.push(`${Routes.create_plan}/${id}`); };
  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >

            <div className="d-flex align-items-center mb-2 pb-3">
              <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
                Plans
              </Typography.Title>
              <Tooltip title="Create Plan">
                <Button className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle" onClick={gotoCreatePlan}>
                  <Icon className="icon-13" iconRef="#add" />
                </Button>
              </Tooltip>
            </div>
            <Row gutter={[16, 16]}>
              {!vaultContractPlans?.length && !isLoading
              && (
              <NoData
                displayText="Create a plan to get started"
                size={200}
                heading="Empty"
                buttonText="Create"
                buttonHandler={gotoCreatePlan}
              />
              )}
              {vaultContractPlans?.map((plan: ContractPlan) => (
                <Col lg={8} md={12} sm={12} xs={24} key={plan?._id}>
                  <PlanCard
                    planType={plan?.plan_type}
                    planName={plan?.plan}
                    planId={plan?._id}
                    gotoPlanDetails={gotoPlanDetails}
                    servicesList={plan?.features?.map((item?:Features) => item?.feature)}
                  />
                </Col>
              ))}
            </Row>
            <Pagination
              total={total}
              pageSize={Constant.planPerPage}
              currentPage={Number(pageNumber)}
              onChange={(page: number) => setPageNumber(page)}
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See the plan settings</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('masterStore')(observer(PlanSettings));
