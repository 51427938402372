/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col, Dropdown, Layout, Menu, Radio, Row, Skeleton, Table, Typography,
} from 'antd';
import './RequestListing.less';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  Icon,
  Info,
  LinkButton,
  NoData, Pagination, Search, SortTh, Status, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import { serviceRequestData } from '../../Global/interface';
import { IServiceStore } from '../../Store/serviceRequestStore';
import Routes from '../../Global/Routes';

const {
  OPEN, IN_PROGRESS, COMPLETED, PAYMENT_PENDING,
} = Constant.serviceRequestStatus;

interface RequestListingProps{
  serviceStore: IServiceStore
 }

function RequestListing(props: RequestListingProps) {
  const { serviceStore } = props;
  const { servicesRequests } = serviceStore;
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultReqTab = queryParams?.tab && queryParams?.tab !== 'undefined' ? queryParams?.tab : OPEN;
  const [isLoading, setIsLoading] = useState(false);
  const [requestPage, setRequestPage] = useState(Constant.defaultPageNumber);
  const [requestKeyword, setRequestKeyword] = useState<string>('');
  const [requestOrderBy, setRequestOrderBy] = useState(-1);
  const [requestStatus, setRequestStatus] = useState<string>(defaultReqTab);
  const isCompletedReq = requestStatus === COMPLETED;
  const [collapsed, setCollapsed] = useState(false);

  const requestColumn = [
    {
      title: <SortTh title="Request ID" dataIndex="request_code" order="request_code" orderBy={requestOrderBy} />,
      dataIndex: 'request_code',
      onHeaderCell: () => ({
        onClick: () => {
          sortServiceRequest();
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      ellipsis: true,
    },
    {
      title: 'Request',
      dataIndex: 'service',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'vehicle_no',
      ellipsis: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const sortServiceRequest = () => {
    const nextSort = requestOrderBy === -1 ? 1 : -1;
    setRequestOrderBy(nextSort);
    getRequests(requestPage, requestStatus, requestKeyword, nextSort);
  };

  const getRequests = async (
    page: number,
    req_status: string,
    keyword?: string,
    orderBy?: number,
  ) => {
    setIsLoading(true);
    try {
      let status: any = req_status;
      if (req_status === IN_PROGRESS) status = [IN_PROGRESS, PAYMENT_PENDING];
      await serviceStore.getRequests({
        page,
        keyword,
        order: 'request_code',
        orderBy,
        status,
        limit: Constant.serviceRequest,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getLocation = (request: serviceRequestData) => {
    if (request?.address) return request?.address?.city;
    if (request?.facility) return `${request?.facility?.name} - ${request?.facility?.address}`;
    return request?.contract?.address?.city;
  };

  const requestDataSource = servicesRequests?.service_request?.map(
    (request: serviceRequestData) => ({
      request_code: request?.request_code,
      timestamp: Utility.getRelativeDate(request?.createdAt),
      service: Constant.SERVICES.get(request?.service),
      vehicle: !isCompletedReq || request?.vehicle?.deleted
        ? (
          <div className="text-capitalize text-truncate">
            {request?.model?.company} {request?.model?.model}
          </div>
        ) : (
          <Link to={`${Routes.vehicleDetails}/${request?.vehicle?._id}?tab=${requestStatus}&status=false`} className="d-flex ml-auto text-capitalize r-bold align-items-center p-0 link_text">
            <span className="text-truncate text-capitalize">{request?.model?.company} {request?.model?.model}</span>
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Link>
        ),
      vehicle_no: request?.vehicle?.vehicle_no || '-',
      location: getLocation(request),
      status: <Status
        status={request?.status}
        statusClassName={Constant.ServiceReqStatusClass}
        statusText={Constant.ServiceReqStatusText}
      />,
      rowKey: request?.vehicle?._id,
      vehilce_deleted: request?.vehicle?.deleted,
    }),
  );

  const locale = {
    emptyText: isLoading ? (
      <Skeleton loading={isLoading} />
    ) : (
      <NoData subTitle="requests" />
    ),
  };

  const serviceSearchHandler = useCallback(
    debounce((nextValue: string, orderBy: number, status: any) => {
      setRequestPage(Constant.defaultPageNumber);
      getRequests(Constant.defaultPageNumber, status, nextValue, orderBy);
    }, Constant.debaounceTime),
    [],
  );

  const onSearchHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event?.target;
    setRequestKeyword(value);
    serviceSearchHandler(value, requestOrderBy, requestStatus);
  };

  const pageHandler = (page: number) => {
    setRequestPage(page);
    getRequests(page, requestStatus, requestKeyword, requestOrderBy);
  };

  const goToServiceReqDetails = (record: any) => (!isCompletedReq) && history.push(`${Routes.service_request_detail}/${record?.request_code}?vId=${record?.rowKey}&status=${!record?.vehilce_deleted}&tab=${requestStatus}`);

  const filterHandler = (event: any) => {
    setRequestPage(Constant.defaultPageNumber);
    setRequestStatus(event?.target?.value);
    history.push(`${Routes.request}?tab=${event.target.value}`);
  };

  useEffect(() => {
    serviceStore?.initialize();
    getRequests(requestPage, requestStatus, requestKeyword, requestOrderBy);
  }, [requestStatus]);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="2">
        <Link to={Routes.service_req_histroy} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
          Service Request History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper request_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
            {/* <div className="text-center red_bg d-flex
            align-items-center justify-content-center white alert_wrap
            position-fixed border-radius">
              <Icon className="icon-16 me-2" iconRef="#alert" />
              Client is waiting outside, open the gate now
              <Button className="d-flex justify-content-center
              align-items-center text-center p-0 icon_circle
              position-absolute alert_close white" type="link" shape="circle">
                <Icon className="icon-10" iconRef="#close" />
              </Button>
            </div> */}
            {/* yellowalert */}
            {/* <div className="text-center yellow_bg d-flex align-items-center
              justify-content-center
            white alert_wrap position-fixed border-radius">
              <Icon className="icon-16 me-2" iconRef="#Notification" />
              Client will be there soon for parking
              <Button className="d-flex justify-content-center align-items-center
              text-center p-0 icon
              _circle position-absolute alert_close white" type="link" shape="circle">
                <Icon className="icon-10" iconRef="#close" />
              </Button>
            </div> */}
            <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
              Service Requests
            </Typography.Title>
            <Radio.Group
              onChange={filterHandler}
              defaultValue={requestStatus}
              buttonStyle="solid"
              className="radio_tab client_plan_tab d-flex gray_bg w-100 border-radius ms-xxl-4 ms-xl-0 ms-sm-4 mt-xxl-0 mt-xl-3 mt-sm-0 mt-3 no-wrap"
            >
              <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={OPEN}>New</Radio.Button>
              <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={IN_PROGRESS}>In Progress</Radio.Button>
              <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={COMPLETED}>Completed</Radio.Button>
            </Radio.Group>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="request_listing">
            {/* add pb-65 to request_listing class when alert shown */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-sm-flex align-items-center flex-fill">
                <h4 className="mb-0 table-header r-bold">All Requests</h4>
                <Search
                  value={requestKeyword}
                  placeholder="Search"
                  titleToolTip="Search request"
                  size="small"
                  onChange={onSearchHandler}
                />
              </div>
            </div>
            <Table
              rowClassName={!(isCompletedReq) ? 'cursor-pointer' : ''}
              columns={requestColumn}
              dataSource={requestDataSource}
              pagination={false}
              locale={locale}
              size="small"
              scroll={{ x: '1000px' }}
              className="table_wrapper"
              onRow={(record) => ({ onClick: () => goToServiceReqDetails(record) })}
            />
            <Pagination
              total={servicesRequests?.total?.counts}
              currentPage={requestPage}
              pageSize={Constant.serviceRequest}
              onChange={pageHandler}
            />
          </div>
        </Col>
        <Info
          infoDetail="See and manage the service requests"
          setCollapsed={setCollapsed}
        >
          <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Actions</h6>
          <LinkButton route={Routes.service_req_histroy} text="Service Request History" />
        </Info>
      </Row>
    </Layout.Content>
  );
}

export default inject('serviceStore')(observer(RequestListing));
