/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  product_name: [{
    required: true,
    message: constant.nameFieldRequired,
  }, {
    type: 'string',
    pattern: /^[a-zA-Z0-9 -!@$%&*()+.,]*[a-zA-Z][a-zA-Z0-9 -!@$%&*()+.,]*$/,
    message: constant.specialCharectorError,
  }],
  category: [{
    required: true,
    message: constant.categoryRequired,
  }],
  color_name: [{
    required: true,
    message: constant.colorNameRequired,
  }, {
    type: 'string',
    pattern: /^[A-Za-z][A-Za-z ]*$/,
    message: constant.splOrNumberOnlyError,
  }],
  color_code: [{
    required: true,
    message: constant.colorRequired,
  }],
  product_price: [
    {
      validator: (field:any, value:any, callback:Function) => {
        if (!value) callback(constant.priceRequired);
        else if (value < 1) callback(constant.priceZeroErr);
        callback();
      },
    }],
  variant_size: [{
    required: true,
    message: constant.sizeRequired,
  }],
  description: [{
    required: true,
    message: constant.descriptionRequired,
  }, {
    type: 'string',
    pattern: /([A-Za-z])/,
    message: constant.invalidDescription,
  }],
  code: [{
    required: true,
    message: constant.codeRequired,
  }],
  quantity: [{
    required: true,
    message: constant.quantityRequired,
  }],
};

export default rule;
