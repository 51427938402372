/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Card, Col, Layout,

  Row, Spin, Typography,
} from 'antd';
import moment from 'moment';
import queryString from 'query-string';

import classNames from 'classnames';
import {
  Icon, Toast, EmployeeForms,
} from '../../Components';
import Routes from '../../Global/Routes';
import { EmployeeForm } from '../../Global/interface';

import './CreateEmployee.less';
import Utility from '../../Global/Utility'; // phone update

const { Title, Paragraph, Link } = Typography;
const { Sider } = Layout;

function CreateEmployee(props: any) {
  const { userStore, masterStore } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const history = useHistory();
  const { id } = useParams<any>();
  const [collapsed, setCollapsed] = useState(false);

  const createEmployee = async (employee:EmployeeForm) => {
    try {
      const params: EmployeeForm = employee;
      setIsLoading(true);
      params.dob = employee?.dob ? moment(employee?.dob)?.format('YYYY-MM-DD') : null;
      params.phone = Utility.phoneParser(params?.phone); // phone update
      await userStore.createUser(params);
      setIsLoading(false);
      goToEmployees();
    } catch (err:any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateEmployee = async (employee: any) => {
    try {
      const params: any = employee;
      setIsLoading(true);
      params.dob = employee?.dob ? moment(employee?.dob)?.format('YYYY-MM-DD') : null;
      params.profile = employee?.profile || null;
      params.documents = employee?.documents;
      params.phone = Utility.phoneParser(params?.phone); // phone update
      await userStore.updateUser(id, params);
      setIsLoading(false);
      goToEmployees();
    } catch (err:any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const fetchEmployeesData = async (userId: string) => {
    setIsLoading(true);
    await userStore.getUserById(userId)
      .then(() => { setIsLoading(false); })
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const onFinish = (values: EmployeeForm) => {
    if (isUpdate) updateEmployee(values);
    else createEmployee(values);
  };

  const goToEmployees = () => {
    if (isUpdate) history.push(`/employees_detail/${id}?searchText=${queryParams?.searchText}&pageNumber=${queryParams?.pageNumber}`);
    else history.push(`${Routes.employees}?searchText=${queryParams?.searchText}&pageNumber=${queryParams?.pageNumber}`);
  };

  async function getCountryData() {
    setIsLoading(true);
    await masterStore?.getCountryData()
      .then(() => setIsLoading(false))
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  }

  useEffect(() => {
    if (!masterStore?.countries) {
      getCountryData();
    }
    if (id) {
      fetchEmployeesData(id);
      setIsUpdate(true);
    }
  }, []);

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-lg-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content" onClick={goToEmployees}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Link>
            <div className="d-flex align-items-center justify-content-between mb-2 pb-3">
              <Typography.Title className="m-0 header_main fs-xs-24">
                {isUpdate ? 'Edit Employee' : 'Create Employee'}
              </Typography.Title>
            </div>
            <Card className="create_card">
              <EmployeeForms
                isUpdate={isUpdate}
                isLoading={isLoading}
                onCancel={goToEmployees}
                onFinish={onFinish}
                employeeDetails={userStore?.user}
              />
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{isUpdate ? 'See and manage an Employee' : 'Create an Employee'}</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('userStore', 'masterStore')(observer(CreateEmployee));
