/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, makeObservable, runInAction,
} from 'mobx';
import Constant from '../Global/Constant';
import {
  Product, ProductData, ProductQueryParams, VariantQueryParams, Variants,
} from '../Global/interface';
import productService from '../Services/productService';

export interface IProductStoreProps {
    products: ProductData
    product: Product
    variants: Array<Variants>
    total: number
    initialize: Function
    getProducts: (queryParams: ProductQueryParams) => any
    createProduct: (product: Product) => any
    getProductById: (id: string) => any
    updateProduct: (product: Product, id: string) => any
    uploadImages: (formData: any, id: string) => any
    removeImages: (formData: any, id: string) => any
    createVariant: (variant: Variants, id: string) => any
    updateVariant: (variant: Variants, id: string) => any
    publishProduct: (data: any, id: string) => any
    setMasterImage: (data: any, id: string) => any
    getVariants: (queryParams: VariantQueryParams) => any
}

class ProductStore {
       products: ProductData | null = null

       product: Product | null = null

       variants: Array<Variants> | null = null

       total: number = 0

       constructor() {
         makeObservable(this, {
           products: observable,
           product: observable,
           total: observable,
           variants: observable,
         });
         this.initialize();
       }

         initialize = () => {
           this.variants = null;
           this.total = 0;
           this.products = null;
           this.product = null;
         }

      getProducts = async (queryParams: ProductQueryParams) => {
        try {
          const response: any = await productService.getProducts(queryParams);
          if (response && response?.data) {
            runInAction(() => {
              this.products = response?.data;
            });
          }
          return (null);
        } catch (error : any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      createProduct = async (product: Product) => {
        try {
          const response = await productService.createProduct(product);
          if (response && response?.data) {
            runInAction(() => {
              this.product = response?.data?.product;
            });
          }
          return (response);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      updateProduct = async (product: Product, id: string) => {
        try {
          await productService.updateProduct(product, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      uploadImages = async (formData: any, id: string) => {
        try {
          await productService.uploadImages(formData, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      removeImages = async (formData: any, id: string) => {
        try {
          await productService.removeImages(formData, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      getProductById = async (id: string) => {
        try {
          const response: any = await productService.getProductById(id);
          if (response && response?.data) {
            runInAction(() => {
              this.product = response?.data?.product;
            });
          }
          return (null);
        } catch (error : any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      createVariant = async (variant: Variants, id: string) => {
        try {
          const response = await productService.createVariant(variant, id);
          return (response);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      updateVariant = async (variant: Variants, id: string) => {
        try {
          await productService.updateVariant(variant, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      getVariants = async (queryParams: VariantQueryParams) => {
        try {
          const response: any = await productService.getVariants(queryParams);
          if (response && response?.data) {
            runInAction(() => {
              this.variants = response?.data?.variants;
              this.total = response?.data?.total;
            });
          }
          return (null);
        } catch (error : any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      publishProduct = async (data: any, id: string) => {
        try {
          await productService.publishProduct(data, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }

      setMasterImage = async (data: any, id: string) => {
        try {
          await productService.setMasterImage(data, id);
          return Promise.resolve(null);
        } catch (error: any) {
          return Promise.reject(
            error?.response?.data?.error?.message || Constant.defaultErrorMessage,
          );
        }
      }
}

export default new ProductStore();
