/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { clientQuery } from '../Global/interface';
import api from './api';

class CustomerService {
  listCustomers = (queryParams: clientQuery) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/shop/customers/list?${query}`;
    return api.get(url);
  }

  createCustomer = async (customer : any) => {
    try {
      const response = await api.post('shop/customers', customer);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateCustomer = async (id: string, customer : any) => {
    try {
      const response = await api.put(`shop/customers/${id}`, customer);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getCustomerById = async (clientId: String) => {
    try {
      const response = await api.get(`shop/customers/${clientId}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  deleteCustomer = async (id: string) => {
    try {
      const response = await api.delete(`/clients/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  freezeAccount = async (id: string, data: any) => api.put(`shop/customers/freeze-unfreeze/${id}`, data);

  activateAccount = async (id: string, email?: string) => {
    try {
      const response = await api.put(`/clients/activate-account/${id}`, email);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }
}
export default new CustomerService();
