/* eslint-disable camelcase */
import {
  Checkbox, Col, Form, InputNumber, Row, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';

interface CustomPriceProps{
  termAmount: number,
  rule: any,
  form: any,
}

function CustomPrice(props: CustomPriceProps) {
  const { termAmount, rule, form } = props;

  const [isCustomPrice, setIsCustomPrice] = useState<boolean>(false);

  useEffect(() => {
    if (isCustomPrice && termAmount) {
      form.setFieldsValue({ price: termAmount });
    }
  }, [isCustomPrice]);

  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12} xxl={24}>
        {isCustomPrice
          ? (
            <Form.Item
              label="Price"
              className="mandatory mb-0"
              name="price"
              rules={rule}
            >
              <InputNumber placeholder="Enter price" min={0} className="inp_style w-100 inp_num_style pe-5 setting_delivery_amount shadow-none" maxLength={15} />
            </Form.Item>
          )
          : (
            <Form.Item
              label="PRICE"
              name="termPrice"
              className="mb-0"
            >
              <Typography.Title level={5}>
                {termAmount} KD
              </Typography.Title>
            </Form.Item>
          )}
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
        <Form.Item name="custom_amount">
          <Checkbox
            className="chkbox_style primary r-regular fs-16 mt-3"
            onChange={(e: any) => {
              setIsCustomPrice(e?.target?.checked);
              form.resetFields(['price']);
              form.setFieldsValue({ custom_amount: e?.target?.checked });
            }}
            checked={isCustomPrice}
          >
            Custom plan amount
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default CustomPrice;
