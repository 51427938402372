/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  Rate,
  Typography,
} from 'antd';
import { Icon } from '..';
import productimg from '../../Assets/Images/flatstopper.png';

interface IImageCard {
    image: any
    removeImage?: () => void
    paddingLeft?: number
    paddingRight?: number
    showCheckBox?: boolean
    showDeleteButton?: boolean
    checkBoxHandler?: (e: any) => void
    showMasterImage?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    value?: number
    masterImageHandler?: () => void
}

ImageCard.defaultProps = {
  paddingLeft: 0,
  paddingRight: 0,
  showCheckBox: false,
  showDeleteButton: true,
  checkBoxHandler: () => {},
  defaultChecked: false,
  disabled: false,
  showMasterImage: false,
  value: 0,
  masterImageHandler: () => {},
  removeImage: () => {},
};

function ImageCard(props: IImageCard) {
  const {
    image, paddingLeft, paddingRight, defaultChecked, disabled, value, showMasterImage,
    removeImage, showCheckBox, showDeleteButton, checkBoxHandler, masterImageHandler,
  } = props;
  return (
    <Card
      bodyStyle={{
        paddingLeft, paddingRight, width: '100%', height: '100%',
      }}
      className="manage_upload_img product_upload_img border-radius bg-white d-flex align-items-center position-relative mb-2 me-2"
    >
      { showMasterImage && (
      <div className="d-flex align-items-center justify-content-between px-2 position-absolute top-0 start-0 end-0">
        <Typography.Paragraph className="fs-16 text-uppercase mb-0 yellow">
          { value !== 0 && 'master image'}
        </Typography.Paragraph>
        <Rate
          style={{ color: '#f1aa1d' }}
          character={<Icon className="icon-16" iconRef="#star" />}
          value={value}
          count={1}
          onChange={masterImageHandler}
        />
      </div>
      ) }
      <img src={image?.fileUrl || productimg} alt="vehicle_img" className="w-100 px-4 h-100 object-fit-contain" />
      {showCheckBox
      && (
      <Checkbox
        defaultChecked={defaultChecked}
        disabled={disabled}
        className="chkbox_style checkbox_disable_after primary r-regular fs-16 position-absolute bottom-0 start-0 ms-2 mb-2"
        onChange={checkBoxHandler}
      />
      )}
      {showDeleteButton && (
      <Button onClick={removeImage} className="delete_model p-0 position-absolute border-radius red d-flex align-items-center justify-content-center border-0">
        <Icon className="icon-16" iconRef="#delete" />
      </Button>
      )}
    </Card>
  );
}

export default ImageCard;
