/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { constants } from 'buffer';
import { observable, makeObservable } from 'mobx';
import Constant from '../Global/Constant';

import { Contract } from '../Global/interface';
import parkUnparkService from '../Services/parkUnparkService';

export interface ParkUnparkStoreProps {
  available_contracts: any,
  active_contract: any,
  vehicle_details: any,
  vehicle_status: any,
  due_days: number|null,
  due_amount: number|null,
  getVehicleDetails: (vehicleId: string) => void,
}

class ParkUnparkStore {
    available_contracts: Array<Contract>|null = null;

    vehicle_details: any = null;

    active_contract: any = null;

    vehicle_status: any = null;

    due_days: number|null = null;

    due_amount: number|null = null;

    constructor() {
      makeObservable(this, {
        available_contracts: observable,
        vehicle_details: observable,
        active_contract: observable,
        vehicle_status: observable,
        due_days: observable,
        due_amount: observable,
      });
    }

    getVehicleDetails = async (vehicleId: string) => new Promise((resolve, reject) => {
      parkUnparkService.getVehicleById(vehicleId)
        .then((response: any) => {
          this.vehicle_details = response?.data?.vehicleDetails;
          this.active_contract = response?.data?.vehicleDetails?.active_contract;
          this.vehicle_status = response?.data?.vehicleDetails?.vehicle_status;
          this.available_contracts = response?.data?.available_contracts;
          this.due_days = response?.data?.dueDays;
          this.due_amount = response?.data?.invoiceAmount;
          resolve(response?.data?.vehicle);
        })
        .catch((error : any) => {
          reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
        });
    })
}

export default new ParkUnparkStore();
