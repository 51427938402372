/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Col, Dropdown, Layout, Menu, Row, Skeleton, Table, Tooltip, Typography,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './FacilityListing.less';
import classNames from 'classnames';
import {
  Icon, NoData, Pagination, Search,
} from '../../Components/index';
import Constant from '../../Global/Constant';
import Toast from '../../Components/Toast';
import { Facility } from '../../Global/interface';
import SortTh from '../../Components/SortTh';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function FacilityListing(props: any) {
  const { facilityStore, authStore } = props;
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<any>(-1);
  const [order, setOrder] = useState<any>();
  const history = useHistory();
  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);
  const [collapsed, setCollapsed] = useState(false);

  const columns = [
    {
      title: <SortTh title="Facility ID" dataIndex="facility_code" order={order} orderBy={orderBy} />,
      dataIndex: 'facility_code',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Facility Name" dataIndex="name" order={order} orderBy={orderBy} />,
      dataIndex: 'name',
      ellipsis: true,
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
    },
    {
      title: <SortTh title="Facility Location" dataIndex="address" order={order} orderBy={orderBy} />,
      dataIndex: 'address',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Vehicles In Facility',
      dataIndex: 'vehicles_in_facility',
      ellipsis: true,
    },
  ];

  const sortTable = (dataIndex: any) => {
    let sortBy = -1;
    if (orderBy === -1 && dataIndex === order) sortBy = 1;
    else if (dataIndex !== order) setOrder(dataIndex);
    setOrderBy(sortBy);
    fetchFacility(dataIndex, sortBy);
  };

  const fetchFacility = async (dataIndex: any, sortBy: any) => {
    setIsLoading(true);
    await facilityStore.getFacilities(
      keyword, pageNumber, Constant.facilityPerPage, dataIndex, sortBy,
    )
      .then(() => {
        history.push(Routes.facilities);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Toast.error(error);
      });
  };
  useEffect(() => {
    facilityStore?.initialize();
    fetchFacility(order, orderBy);
  }, [pageNumber, keyword]);

  const { total, facilities } = facilityStore;
  const facilityTableData = facilities?.map((facility: Facility) => ({
    facility_code: facility?.facility_code,
    name: facility?.name,
    address: facility?.address,
    phone: facility?.phone,
    rowKey: facility?._id,
    vehicles_in_facility: `${facility?.used_capacity}/${facility?.total_capacity}`,
  }));

  const goToFacilityDetails = (record: any) => { history.push(`${Routes.facility_detail}/${record?.rowKey}`); };

  const handleSearchTextChange = (event: any) => {
    setKeyword(event.target.value);
    setPageNumber(Constant.defaultPageNumber);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="facilities" />
    ),
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={Routes.facility_history} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
          Facility History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const goToFacilityCreate = () => history.push(Routes.create_facility);
  return (

    <Layout.Content className="listing_wrapper client_listing_wrapper facility_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
            <Typography.Title className="m-0 header_main border-bottom-0 fs-xs-24 d-flex align-items-center">
              Facilities
              { isSuperAdmin && (
              <Tooltip title="Create Facility">
                <Button className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle" onClick={goToFacilityCreate}>
                  <Icon className="icon-13" iconRef="#add" />
                </Button>
              </Tooltip>
              )}
            </Typography.Title>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-sm-center mb-3">
            <div className="d-sm-flex align-items-center flex-fill">
              <h4 className="mb-0 table-header r-bold">All Facilities</h4>
              <Search
                value={keyword}
                placeholder="Search"
                titleToolTip="Search Facility"
                size="small"
                onChange={handleSearchTextChange}
              />
            </div>
          </div>
          <Table
            onRow={(record) => ({ onClick: () => goToFacilityDetails(record) })}
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={facilityTableData}
            pagination={false}
            size="small"
            scroll={{ x: '800px' }}
            locale={locale}
            className="table_wrapper"
          />
          <Pagination
            total={total}
            pageSize={Constant.facilityPerPage}
            currentPage={Number(pageNumber)}
            onChange={(page: number) => setPageNumber(page)}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See logs and manage the facilities</Paragraph>
          </Typography>
          <div className="disabled_btn">
            <h6 className="text-uppercase r-medium r-side-info mt-xl-5">Action</h6>
            <Tooltip title="Facility history">
              <Link to={Routes.facility_history} className="d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                Facility History
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('facilityStore', 'authStore')(observer(FacilityListing));
