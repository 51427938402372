/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { ProductCategory, ProductCategoryQueryParams } from '../Global/interface';
import api from './api';

class CategoryService {
    getCategories = (queryParams: ProductCategoryQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/categories?${query}`;
      return api.get(url);
    }

    createCategory = async (category : ProductCategory) => {
      try {
        const response = await api.post('/shop/categories', category);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    updateCategory = async (category : ProductCategory, id: string) => {
      try {
        const response = await api.put(`/shop/categories/${id}`, category);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    deleteCategory = async (id: string) => {
      try {
        const response = await api.delete(`/shop/categories/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    getCategoryById = async (id?: String) => {
      try {
        const response = await api.get(`/shop/categories/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }
}
export default new CategoryService();
