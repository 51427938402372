/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import {
  Button,
  Card,
  Col,
  Divider,
  InputNumber,
  Layout,
  Row,
  Slider,
  Spin,
  Typography,
} from 'antd';
import {
  ButtonIcon, Icon, Info, RenderItem, Toast,
} from '../../Components';
import settingsService from '../../Services/settingsService';
// import docicon from '../../Assets/Images/ic_delete_mini.svg';
import './settings.less';
import { ISettingsStore } from '../../Store/settingsStore';

interface ISettings {
  settingsStore: ISettingsStore
}

function Settings(props: ISettings) {
  const { settingsStore } = props;
  const { settings } = settingsStore;
  const [isLoading, setIsLoading] = useState(false);
  const [editExpireTime, setEditExpireTime] = useState(false);
  const [editPaymentDue, setEditPaymentDue] = useState(false);
  const [dueAmount, setDueAmount] = useState(settings?.vault_plan_due_amount);
  const [expireTime, setExpireTime] = useState(settings?.plan_expiring_time);
  const [collapsed, setCollapsed] = useState(false);
  const [expDeliverCharge, setExpDeliverCharge] = useState<number>(
    settings?.order_express_delivery_amount,
  );
  const [editExpDeliveryCharge, setEditExpDeliveryCharge] = useState(false);

  const isDeliveryCharge = settings?.order_express_delivery_amount >= 0;

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateSettings = async (data: any, type?: string) => {
    try {
      setIsLoading(true);
      await settingsService.updateSettings(data);
      getSettings();
      if (type === 'due') setEditPaymentDue(false);
      else if (type === 'expDelivery') setEditExpDeliveryCharge(false);
      else setEditExpireTime(false);
      Toast.success('Settings updated');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const expireBtnHandler = () => setEditExpireTime(true);
  const cancelExpireTime = () => setEditExpireTime(false);
  const updateExpireTimeSettings = () => updateSettings({ expiring_time: expireTime });
  const sliderChangeHandler = (value: any) => setExpireTime(value);
  const marks = {
    10: '10 Days',
    15: '15 Days',
    20: '20 Days',
  };

  const dueBtnHandler = () => setEditPaymentDue(true);
  const cancelDue = () => setEditPaymentDue(false);
  const updateDueSettings = () => updateSettings({ plan_due_amount: dueAmount }, 'due');
  const onChangeDueHandler = (value: any) => setDueAmount(value);

  const expDeliverChargeHandler = () => {
    setEditExpDeliveryCharge(true);
    setExpDeliverCharge(settings?.order_express_delivery_amount || 0);
  };
  const onChangeExpDeliveryHandler = (value: any) => setExpDeliverCharge(value);
  const updateExpDeliveryAmount = () => updateSettings(
    { express_delivery_amount: expDeliverCharge }, 'expDelivery',
  );
  const cancelExpDelivery = () => setEditExpDeliveryCharge(false);

  useEffect(() => {
    if (isDeliveryCharge) {
      setExpDeliverCharge(
        settings?.order_express_delivery_amount,
      );
    }
  }, [settings]);

  useEffect(() => {
    getSettings();
  }, []);
  return (
    <Layout.Content>
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Typography.Title level={1} className="mb-4 fs-xs-24">
              Settings
            </Typography.Title>
            <Card className="create_card">
              <Typography.Title level={3} className="mb-4 fs-xs-18">
                Shop VMS
              </Typography.Title>
              <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Express<span className="clearfix d-none d-lg-table" /> Delivery
                  </Typography.Title>
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3 w-100">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set express delivery amount
                      </Typography.Paragraph>
                    </div>
                    <RenderItem isTrue={!editExpDeliveryCharge}>
                      <ButtonIcon
                        onClick={expDeliverChargeHandler}
                        text={isDeliveryCharge ? 'Edit' : 'Add'}
                      />
                    </RenderItem>
                  </div>
                  <Row>
                    <Col sm={20} xs={24}>
                      <RenderItem isTrue={!editExpDeliveryCharge}>
                        <RenderItem isTrue={isDeliveryCharge}>
                          <div className="r-bold">{settings?.order_express_delivery_amount} KD</div>
                        </RenderItem>
                        <RenderItem isTrue={!(isDeliveryCharge)}>
                          <div className="r-bold yellow">Not configured</div>
                        </RenderItem>
                      </RenderItem>
                      <RenderItem isTrue={editExpDeliveryCharge}>
                        <div className="secondary r-medium mb-2">Amount</div>
                        <InputNumber
                          defaultValue={expDeliverCharge || settings?.order_express_delivery_amount}
                          value={expDeliverCharge}
                          onChange={onChangeExpDeliveryHandler}
                          min={0}
                          max={999999}
                          maxLength={6}
                          placeholder="Set amount"
                          className="inp_style h-40 custom_inp_number setting_delivery_amount w-100"
                        />
                        <div className="mt-4">
                          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={updateExpDeliveryAmount}>
                            Save changes
                          </Button>
                          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelExpDelivery}>
                            Cancel
                          </Button>
                        </div>
                      </RenderItem>
                    </Col>
                  </Row>
                  <Divider className="border-top mt-3 mb-3" />
                </Col>
              </Row>
              {/* <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Reminder<span className="clearfix d-none d-lg-table" /> Template
                  </Typography.Title>
                </Col>

                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3 w-100">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set a template for abandoned email reminder
                      </Typography.Paragraph>
                    </div>
                    <Button className="m-0 border-0 bg-transparent d-flex align-items-center
                    shadow-none r-bold primary p-0 h-xs-max" onClick={expireBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center
                      text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                  </div>
                  <div className="r-bold secondary">Not configured</div>
                  <Row>
                    <Col sm={20} xs={24}>
                      <Tooltip title="Add Template">
                        <Button className="d-flex justify-content-center align-items-center
                        text-center p-0 icon_circle" type="link" shape="circle">
                          <Icon className="icon-13" iconRef="#add" />
                        </Button>
                      </Tooltip>
                      <div className="d-flex d-res-block justify-content-between
                      border-bottom pt-2 pb-2fs-16 align-items-center flex-wrap">
                        <div className="document_list_wrap
                        d-flex align-items-center w-75 w-res-100">
                          <span className="text-truncate" title="1. Template 1.html">
                          1. Template 1.html</span>
                          <Button className="doc_icon ms-2 d-flex justify-content-end
                          align-items-center p-0 pe-2border-0 shadow-none">
                            <img src={docicon} alt="doc_close" />
                          </Button>
                        </div>
                        <Button className="m-0 border-0 bg-transparent d-flex align-items-center
                        shadow-none r-bold primary p-0 h-xs-max">
                          Set As Default
                          <div className="ms-2 d-flex justify-content-center align-items-center
                          text-center small_icon rounded-circle">
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Button>
                      </div>
                      <div className="d-flex d-res-block justify-content-between border-bottom
                      pt-2 pb-2 fs-16 align-items-center flex-wrap">
                        <div className="document_list_wrap d-flex align-items-center w-75
                        w-res-100">
                          <span className="text-truncate" title="2. Template 2.html">
                          2. Template 2.html</span>
                          <Button className="doc_icon ms-2 d-flex
                          justify-content-end align-items-center p-0 pe-2 border-0 shadow-none">
                            <img src={docicon} alt="doc_close" />
                          </Button>
                        </div>
                        <Button className="m-0 border-0 bg-transparent d-flex align-items-center
                        shadow-none r-bold inactive p-0 h-xs-max">
                          Default
                        </Button>
                      </div>
                      <div className="d-flex d-res-block justify-content-between border-bottom
                      pt-2 pb-2 fs-16 align-items-center flex-wrap">
                        <div className="document_list_wrap d-flex align-items-center w-75
                        w-res-100">
                          <span className="text-truncate" title="2. Template 2.html">
                          2. Template 2.html</span>
                          <div className="off-white-bg secondary rounded-pill px-2 py-1
                          d-flex align-items-center justify-content-center fs-16 ms-2">
                            Default
                          </div>
                        </div>
                        <Button className="m-0 border-0 bg-transparent d-flex align-items-center
                        shadow-none r-bold primary p-0 h-xs-max">
                          <Icon className="icon-16 me-2" iconRef="#eye" />Preview
                        </Button>
                      </div>
                      <div className="pt-4">
                        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold"
                        onClick={updateExpireTimeSettings}>
                          Save changes
                        </Button>
                        <Button type="text" className="cancel_btn fs-16 r-bold"
                        onClick={cancelExpireTime}>
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Divider className="border-top mt-3 mb-3" />
                </Col>
              </Row> */}
              {/* <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Reminder<span className="clearfix d-none d-lg-table" /> Automation
                  </Typography.Title>
                </Col>

                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Manage abandoned email reminder automation
                      </Typography.Paragraph>
                    </div>
                    <Button className="m-0border-0 bg-transparent d-flex align-items-center
                    shadow-none r-bold primary p-0 h-xs-max" onClick={expireBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center
                      text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                  </div>
                  <Row>
                    <Col sm={20} xs={24}>
                      <div className="r-bold">15 Days</div>
                      <div>
                        <Checkbox
                          className="chkbox_style primary r-regular fs-16 mb-3"
                        >Activate
                        </Checkbox>
                        <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                          Choose how many days after the reminder mail should be send
                          to the customer when he add item to the cart and didn&apos;t buy
                        </Typography.Paragraph>
                        <Slider
                          min={10}
                          max={20}
                          step={5}
                          marks={marks}
                          defaultValue={settings?.plan_expiring_time}
                          onChange={sliderChangeHandler}
                          trackStyle={{ background: '#26D65B' }}
                          handleStyle={{ background: '#26D65B' }}
                          className="custom_slider"
                        />
                        <div className="pt-4">
                          <Button htmlType="submit"
                          className="main_btn me-3 fs-16 r-bold" onClick={updateExpireTimeSettings}>
                            Save changes
                          </Button>
                          <Button type="text"
                          className="cancel_btn fs-16 r-bold" onClick={cancelExpireTime}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider className="border-top mt-3 mb-3" /> */}
              <Typography.Title level={3} className="mb-4 fs-xs-18">
                The Vault
              </Typography.Title>
              <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Expiring<span className="clearfix d-none d-lg-table" /> Period
                  </Typography.Title>
                </Col>

                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set how many days before, the plan should flagged as expiring
                      </Typography.Paragraph>

                      { !editExpireTime && <div className="r-bold">{settings?.plan_expiring_time} Days</div>}
                      { editExpireTime && (
                      <div>
                        <Slider
                          min={10}
                          max={20}
                          step={5}
                          marks={marks}
                          defaultValue={settings?.plan_expiring_time}
                          onChange={sliderChangeHandler}
                          trackStyle={{ background: '#26D65B' }}
                          handleStyle={{ background: '#26D65B' }}
                          className="custom_slider"
                        />
                        <div className="pt-4">
                          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={updateExpireTimeSettings}>
                            Save changes
                          </Button>
                          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelExpireTime}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                      )}
                    </div>
                    { !editExpireTime && (
                    <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={expireBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                    )}
                  </div>
                  <Divider className="border-top mt-3 mb-3" />
                </Col>
              </Row>
              <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Penalty<span className="clearfix d-none d-lg-table" /> Amount
                  </Typography.Title>
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set the Penalty amount to be charged when
                        a vehicle is parked without a plan in the facility
                      </Typography.Paragraph>

                      { !editPaymentDue && <div className="r-bold">{settings?.vault_plan_due_amount} KD/Day</div>}
                      { editPaymentDue && (
                      <div>
                        <div className="secondary r-medium mb-2">Amount</div>
                        <InputNumber
                          defaultValue={settings?.vault_plan_due_amount}
                          value={dueAmount}
                          onChange={onChangeDueHandler}
                          min={0}
                          max={999999}
                          maxLength={6}
                          placeholder="due amount"
                          className="inp_style h-40 custom_inp_number setting_inp w-100"
                        />
                        <div className="mt-4">
                          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={updateDueSettings} disabled={!dueAmount}>
                            Save changes
                          </Button>
                          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelDue}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                      )}
                    </div>
                    { !editPaymentDue && (
                    <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={dueBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                    ) }
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Info setCollapsed={setCollapsed} infoDetail="The Vault Settings" />
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('settingsStore')(observer(Settings));
