/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  plan: [{
    required: true,
    message: constant.facilityNameRequired,
  }, {
    type: 'string',
    pattern: /^[a-zA-Z0-9 ~!@#$%^&*()_+-={}:"<>?,./';*-|[\\\]]*[a-zA-Z][ ~!@#$%^&*()_+-={}:"<>?,./';*-|[\\\]]*$/,
    message: constant.specialCharaOrNumberOnlyError,
  }],
  plan_type: [{
    required: true,
    message: 'Plan type is required.',
  }],
  facility: [{
    required: true,
    message: 'Facility required.',
  }],
  price: [{
    validator: (field:any, value:any, callback:Function) => {
      if (!value) callback(constant.priceRequired);
      else if (value < 1) callback(constant.invalidAmount);
      callback();
    },
  }],
  duration: [
    {
      validator: (field:any, value:any, callback:Function) => {
        if (!value) callback('Duration requried.');
        else if (value < 1) callback('Duration requried.');
        else if (value % 1 !== 0) callback('Please provide valid duration.');
        callback();
      },
    }],
  visit_count: [
    {
      validator: (field:any, value:any, callback:Function) => {
        if (!value) callback('Visit per month is required.');
        else if (value < 1 || value > 31) callback('Please provide a valid visit per month.');
        callback();
      },
    }],

};

export default rule;
