/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable } from 'mobx';
import Constant from '../Global/Constant';
import { requestQueryParams, RequestStoreData } from '../Global/interface';
import requestService from '../Services/requestService';

class RequestStore {
    requests!: RequestStoreData;

    constructor() {
      makeObservable(this, {
        requests: observable,
      });
      this.initialize();
    }

    initialize = () => {
      this.requests = {
        employee: {
          data: [], total: 0, page: 0, order: '', orderBy: 0, keyword: '',
        },
        client: {
          data: [], total: 0, page: 0, order: '', orderBy: 0, keyword: '',
        },
      };
    }

    getEmployeeRequest = async (
      status: string, page: number,
      limit?: number, keyword?: string, order?: string, orderBy?:number,
    ) => {
      try {
        const response = await requestService.getEmployeeRequest(
          status, order, orderBy, page, limit, keyword,
        );
        this.requests.employee = {
          data: response?.data?.employee_request,
          total: response?.data?.total[0]?.counts,
          page,
          order,
          orderBy,
          keyword,
        };
        return null;
      } catch (error: any) {
        return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      }
    }

  getPlanRequests = async (queryParams: requestQueryParams) => {
    try {
      const {
        page, order, orderBy, keyword,
      } = queryParams;
      const response = await requestService.getPlanRequests(queryParams);
      this.requests.client = {
        data: response?.data?.contract_request,
        total: response?.data?.total[0]?.counts,
        page,
        order,
        orderBy,
        keyword,
      };
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new RequestStore();
