/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import JoditEditor from 'jodit-react';
import { Form } from 'antd';
import { Image } from '../../Global/interface';
import tenantConfig from '../../config.json';
import Constant from '../../Global/Constant';

function DescriptionEditor(props: any) {
  const { description, setContents } = props;
  const editor = useRef<any>(null);
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    setContent(description);
  }, [description]);

  const toolbarButtons = ['font', 'hr', 'fontsize', 'image', 'paragraph', '|', 'bold', 'italic', 'strikethrough', 'underline', 'link', '|', 'align', 'outdent', 'indent', 'ul', 'ol', '|', 'table', '|', 'undo', 'redo'];

  const unneededButtons = ['symbol', 'brush', 'file', 'selectall', 'preview', 'fullsize', 'print', 'about', 'video'];
  const config = {
    readonly: false,
    placeholder: 'Enter your product description',
    enableDragAndDropFileToEditor: true,
    image: {
      editSrc: true,
      selectImageAfterClose: true,
    },
    uploader: {
      insertImageAsBase64URI: false,
      url: `${process.env.REACT_APP_BASE_URL}/files`,
      withCredentials: false,
      pathVariableName: 'path',
      format: 'json',
      method: 'POST',
      headers: {
        Tenant: tenantConfig?.TENANT_ID,
        Authorization: `Bearer ${localStorage?.getItem(Constant?.token)}`,
      },
      prepareData(this: any, formData: any) {
        let index = 0;
        formData.delete('path');
        formData.delete('source');
        for (const value of formData.values()) {
          if (typeof value === 'object') {
            const file = formData.get(`files[${index}]`);
            if (file) {
              formData.append('document', file);
              formData.delete(`files[${index}]`);
            }
            index += 1;
          }
        }

        return formData;
      },
      isSuccess(this: any, resp: any): boolean { return resp; },
      process(resp: any) { return resp; },
      error(this: any, e: Error) {
        this.j.e.fire('errorMessage', e.message, 'error', 4000);
      },
      defaultHandlerSuccess(this: any, resp: any) {
        const j = this.j || this;
        if (resp.length) {
          resp.forEach((file: Image) => {
            const tagName = 'img';
            const attr = 'src';
            const elm = j.createInside.element(tagName);
            elm.setAttribute(attr, file?.fileUrl);
            j.s.insertImage(
                        elm as HTMLImageElement,
                        null,
                        j.o.imageDefaultWidth,
            );
          });
        }
      },
      defaultHandlerError(this:any, resp: any) {
        this.events.fire('errorPopap', [this.options.uploader.getMsg(resp)]);
      },
    },
    buttons: toolbarButtons,
    buttonsMD: toolbarButtons,
    buttonsSM: toolbarButtons,
    buttonsXS: toolbarButtons,
    removeButtons: unneededButtons,
    hidePoweredByJodit: true,
  };

  return (
    <Form.Item name="description" required={false}>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        onChange={(value: any) => setContents(value)}
      />
    </Form.Item>
  );
}

export default observer(DescriptionEditor);
