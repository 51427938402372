/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import api from './api';

interface Data{
    vehicle: string,
    action: string,
    planId: string,
    clientId: string,
    in_vehicle_images: Array<any>,
    out_vehicle_images: Array<any>
}

class ParkUnparkService {
  parkSwapVehicle = async (data: Data) => {
    try {
      const response = await api.put('/park-unpark', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  getVehicleById = async (id: string) => {
    try {
      const response = await api.get(`/park-unpark/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  unparkVehicle = async (data: any) => {
    try {
      const response = await api.put('/park-unpark/unpark/', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }
}

export default new ParkUnparkService();
