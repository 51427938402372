/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Row,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Action, Icon, RenderItem, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

interface ServiceWashProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  service: string,
  checkboxValue: boolean,
  completed: boolean,
  savedValue: any,
  checkboxHandler: (value: any, service: string) => void
  completeService: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string
}

function ServiceWash(props: ServiceWashProps) {
  const {
    isInfoRequired,
    requestInfo,
    started,
    saved,
    service,
    checkboxValue,
    savedValue,
    completed,
    checkboxHandler,
    completeService,
    vehicleStatus,
    visitStatus,
  } = props;

  const [showAction, setShowAction] = useState<boolean>(false);
  const [value, setValue] = useState<any>(false);

  useEffect(() => {
    // populate value
    if (savedValue) setValue(savedValue?.value);
  }, [savedValue]);

  const handleAccept = () => completeService(true, service);

  const handleReject = () => {
    setValue(false);
    setShowAction(false);
  };
  const handleCheckbox = (checked: boolean) => {
    setValue(checked);
    setShowAction(checked);
  };

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <div>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
              <Checkbox
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
                defaultChecked={checkboxValue}
                disabled={completed}
                onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
              />
              <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
                <Icon className="icon-20" iconRef="#wash" />
              </div>
              {Constant?.SERVICES?.get(service)}<RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
            </div>
          </Col>
          <Col lg={15} md={15} sm={15} xs={24} className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mt-3 mt-sm-0 order-2 order-sm-1">
              <Typography.Paragraph className="fs-16 mb-0 inactive">
                {vehicleStatus?.last_updated_time
                  ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                  : ''}
              </Typography.Paragraph>
              <RenderItem isTrue={started && showAction && !completed && saved}>
                <Action onAccept={handleAccept} onReject={handleReject} />
              </RenderItem>
            </div>
            <RenderItem isTrue={(started && saved) || (isInfoRequired && !requestInfo)}>
              <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Status</Typography.Text>
                <Checkbox
                  disabled={completed || visitStatus === 'OPEN'}
                  defaultChecked={completed}
                  checked={value}
                  className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                  onChange={(e) => handleCheckbox(e?.target?.checked)}
                >
                  Vehicle washed
                </Checkbox>
              </div>
            </RenderItem>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ServiceWash;
