/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Card, Col, DatePicker, Divider, Form, Input, Layout,
  Row, Select, Spin, Typography, Upload,
} from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import './CreateModel.less';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import classNames from 'classnames';
import rules from './CreateModelform.validation';
import Constant from '../../Global/Constant';
import { ModelForm } from '../../Global/interface';
import Routes from '../../Global/Routes';
import Toast from '../../Components/Toast';
import fileService from '../../Services/fileService';
import Utility from '../../Global/Utility';
import { Icon, ColorPicker } from '../../Components';

const {
  Title, Paragraph,
} = Typography;
const { Option } = Select;
const { Sider } = Layout;

function CreateModel(props: any) {
  const { modelStore, vehicleStore } = props;
  const { id } = useParams<any>();
  const [form] = useForm();
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const defaultModelTab = queryParams?.tab;
  const [modelImage, setModelImage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const imageAcceptedExtensions = '.jpeg,.png,.jpg';
  const onFinish = (values: any) => {
    if (id) updateModel(values);
    else createModel(values);
  };
  const [collapsed, setCollapsed] = useState(false);

  const createModel = async (model: ModelForm) => {
    try {
      setIsLoading(true);
      const params: ModelForm = model;
      params.manufacture_year = moment(model.manufactureYear).year();
      if (modelImage) {
        params.image = modelImage;
      }
      params.upload = null;
      const response: any = await modelStore.createModel(params);
      setIsLoading(false);
      history.push(`${Routes.model_detail}/${response?.id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateModel = async (model: ModelForm) => {
    try {
      setIsLoading(true);
      const params: ModelForm = model;
      params.manufacture_year = moment(model.manufactureYear).year();
      params.image = modelImage;
      params.upload = null;
      await modelStore.updateModel(id, params);
      setIsLoading(false);
      history.push(`${Routes.model_detail}/${id}?tab=${defaultModelTab}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const fetchModelData = async (modelId: string) => {
    try {
      setIsLoading(true);
      await modelStore.getModelById(modelId);
      const modelData = modelStore?.model;
      form.setFieldsValue({
        company: modelData?.company,
        category: modelData?.category,
        color: modelData?.color,
        manufactureYear: moment().set({ year: modelData?.manufacture_year }),
        model: modelData?.model,
        color_name: modelData.color_name,
      });
      if (modelData?.image?.fileUrl) {
        setModelImage(modelData?.image);
      }
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const getVehiclesCategory = async () => {
    setIsLoading(true);
    await vehicleStore.getVehicleCategory();
    setIsLoading(false);
  };

  useEffect(() => {
    getVehiclesCategory();
    if (id) {
      fetchModelData(id);
      setIsUpdate(true);
    }
  }, []);
  const getDisabledYear = (current:any) => current && moment(current).year() > moment().year();

  const handleOnChangeImage = (fileInfo: any) => {
    const fileExt = fileInfo?.file?.name?.split('.').pop();
    if (!imageAcceptedExtensions?.includes(fileExt.toLowerCase())) {
      Toast.error(`Only files with following extensions are allowed: ${imageAcceptedExtensions}.`);
      return;
    }
    if ((fileInfo?.file?.size / 1024 / 1024 > Constant.maximumFileSize)) {
      Toast.error(`File size exceeded the limit: ${Constant.maximumFileSize} MB.`);
      return;
    }
    uploadThumbnail(fileInfo);
  };

  const uploadThumbnail = async (fileInfo:any) => {
    if (fileInfo?.fileList?.length) {
      setIsLoading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setModelImage(response?.data[0]);
      setIsLoading(false);
    }
  };

  const removeModelImage = () => {
    setModelImage(null);
  };

  const gotoModelPage = () => {
    if (isUpdate) history.push(`${Routes.model_detail}/${id}?tab=${defaultModelTab}`);
    else history.push(Routes.models);
  };

  const customRequest = () => false;

  const handleColorChange = (color:any) => {
    form.setFieldsValue({ color });
  };

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-xl-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Button className="d-flex ml-auto mb-3 r-bold align-items-center back_link ps-0 pe-0" type="link" onClick={gotoModelPage}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Button>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {isUpdate ? 'Edit Model' : 'Create Model'}
            </Typography.Title>
            <Card className="create_card">
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                validateTrigger={['onBlur', 'onSubmit', 'onChange']}
                scrollToFirstError
              >
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col md={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Details
                    </Typography.Title>
                  </Col>
                  <Col md={24} lg={20} className="create_pad">
                    <Typography.Text className="m-0 create_para">
                      Edit model details
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="vehicle category" className="mandatory" name="category" normalize={Utility.trimSpacesInValues} rules={rules?.category}>
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style veh_cat_slct"
                          >
                            {vehicleStore?.vehicleCategories?.map(
                              (vehicles: any) => (
                                <Option value={vehicles.category}>{vehicles?.name}</Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="manufacturer" className="mandatory" name="company" normalize={Utility.trimSpacesInValues} rules={rules?.company}>
                          <Input placeholder="Enter manufacturer" className="inp_style" maxLength={Constant.maxNameLength} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="model" className="mandatory" name="model" normalize={Utility.trimSpacesInValues} rules={rules?.model}>
                          <Input placeholder="Enter model" className="inp_style" maxLength={Constant.maxNameLength} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="year" className="mandatory" name="manufactureYear" rules={rules?.manufactureYear}>
                          <DatePicker
                            className="inp_style date_picker"
                            picker="year"
                            format="YYYY"
                            disabledDate={getDisabledYear}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item className="mandatory" normalize={Utility.trimSpacesInValues} rules={rules.color}>
                          <ColorPicker
                            onSelect={handleColorChange}
                            defaultColor={isUpdate ? modelStore?.model?.color : ''}
                            placeholder="Pick the color"
                            inputRules={rules.color_name}
                            colorRules={rules?.color}
                            disabled={false}
                            label="vehicle color"
                            pickerName="color"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Thumbnail
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Add the 3D model thumbnail
                      </Typography.Text>
                      <Form.Item name="upload" className="m-0">
                        <Upload
                          className="d-flex ml-auto mb-3 r-bold align-items-center back_link p-0 border-0 upload-action"
                          showUploadList={false}
                          maxCount={1}
                          onChange={handleOnChangeImage}
                          accept={imageAcceptedExtensions}
                          customRequest={customRequest}
                        >
                          Add
                          <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Upload>
                      </Form.Item>
                    </div>
                    <div className="d-flex flex-wrap">
                      {modelImage?.fileUrl
                      && (
                      <Card className="manage_upload_img border-radius off-white-bg d-flex align-items-center position-relative mb-4 overflow-hidden">
                        <img src={modelImage?.fileUrl} alt="vehicle_img" className="w-100 ps-4 pe-4" />
                        <Button className="delete_model p-0 position-absolute border-radius red d-flex align-items-center justify-content-center border-0" onClick={removeModelImage}>
                          <Icon className="icon-16" iconRef="#delete" />
                        </Button>
                      </Card>
                      )}
                    </div>
                    <Divider className="m-0 border-top" />
                    <div className="d-flex mt-4">
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        {isUpdate ? 'Save Changes' : 'Create Model'}
                      </Button>
                      <Button type="text" className="cancel_btn fs-16 r-bold" onClick={gotoModelPage}>
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{isUpdate ? 'See and manage a vehicle model' : 'Create a Model'}</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('vehicleStore', 'modelStore')(observer(CreateModel));
