/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col, Layout, Row, Typography, Tabs, Divider, Breadcrumb, Tooltip, Dropdown, Button, Menu, Spin,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import './CreateProduct.less';
import { Link, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Sider from 'antd/lib/layout/Sider';
import dompurify from 'dompurify';

import BasicInfo from './BasicInfo';
import ProductImages from './ProductImages';
import { Icon, Toast } from '../../Components';
import Varients from './Varients';
// import CompatibleVehicles from './CompatibleVehicles';
import Routes from '../../Global/Routes';
import { IProductStoreProps } from '../../Store/productStore';
import { Product, Variants } from '../../Global/interface';
import { ICategoryStoreProps } from '../../Store/category';
import ConfirmModal from '../../Components/ConfirmModal';
import productService from '../../Services/productService';

const { TabPane } = Tabs;

interface ICreateProduct {
  productStore: IProductStoreProps
  categoryStore: ICategoryStoreProps
}

function CreateProduct(props: ICreateProduct) {
  const { productStore, categoryStore } = props;
  const { categories } = categoryStore;
  const { product } = productStore;

  const { id } = useParams<any>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false);
  const [isDocUploading, setIsDocUploading] = useState<boolean>(false);
  const [newVariantList, setNewVariantList] = useState<any>('');
  const [masterImage, setMasterImage] = useState<string>('');
  const [collapsed, setCollapsed] = useState(false);

  const productImage = isUpdate && !!product?.images.length;
  const disableTab = !productImage || !id || !masterImage;

  const addVariantList = () => setNewVariantList('1');

  const removeVariantList = () => setNewVariantList('');

  const createProduct = async (productData: Product) => {
    setIsLoading(true);
    try {
      productData.description = dompurify.sanitize(productData?.description);
      const response = await productStore.createProduct(productData);
      setIsUpdate(true);
      moveToNextTab('2');
      Toast.success('Product created');
      history.push(`${Routes.create_product}/${response?.data?.product?._id}`);
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const updateProduct = async (productData: Product) => {
    setIsLoading(true);
    try {
      productData.description = dompurify.sanitize(productData?.description);
      await productStore.updateProduct(productData, id);
      getProductById(id);
      Toast.success('Product updated successfully');
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const getCategories = async () => {
    setIsLoading(true);
    try {
      categoryStore.getCategories({});
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getProductById = async (productId: string) => {
    setIsLoading(true);
    try {
      await productStore.getProductById(productId);
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const deleteProduct = async () => {
    setIsLoading(true);
    try {
      await productService.deleteProduct(id);
      history.push(Routes.products);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  const uploadImages = async (documents: any) => {
    try {
      setIsDocUploading(true);
      const fileFormData = new FormData();
      documents.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      await productStore?.uploadImages(fileFormData, product?._id);
      getProductById(id);
      setIsUploadVisible(false);
      setIsDocUploading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const removeImages = async (images: any) => {
    try {
      setIsLoading(true);
      await productStore?.removeImages({ images }, product?._id);
      getProductById(id);
      setIsLoading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const createVariant = async (variant: any) => {
    setIsLoading(true);
    try {
      await productStore.createVariant(variant, id);
      getProductById(id);
      removeVariantList();
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const updateVariant = async (variant: Variants, variantId: string) => {
    setIsLoading(true);
    try {
      await productStore.updateVariant(variant, variantId);
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const deleteVariant = async (variantId: string) => {
    setIsLoading(true);
    try {
      await productService.deleteVariant(variantId);
      getProductById(id);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  const publishProduct = async (publish: boolean) => {
    setIsLoading(true);
    try {
      const data = { publish };
      await productStore.publishProduct(data, id);
      Toast.success(`${publish ? 'Product published' : 'Product unpublished'}`);
      getProductById(id);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const setProductMasterImage = async (image: string) => {
    try {
      const data = { image };
      await productStore.setMasterImage(data, id);
      setMasterImage(image);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const showUploadModal = () => setIsUploadVisible(true);
  const closeUploadModal = () => setIsUploadVisible(false);

  const moveToNextTab = (key: string) => setActiveTabKey(key);

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Product', `Do you want to delete the product ${product?.name}?`, deleteProduct);
  };

  const viewPublishConfirnModal = () => {
    if (product?.published) {
      ConfirmModal('Unpublish Product', `Do you want to unpublish the product ${product?.name}?`, () => publishProduct(false), 'Unpublish', '#000000');
    } else {
      ConfirmModal('Publish Product', `Do you want to publish the product ${product?.name}?`, () => publishProduct(true), 'Publish', '#000000');
    }
  };

  useEffect(() => {
    if (id) {
      getProductById(id);
      setIsUpdate(true);
    }
    getCategories();
  }, []);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button onClick={viewDeleteConfirmModal} className="d-flex ml-auto r-bold align-items-center delete-btn p-0">
          Delete Product
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const goToProductListing = () => history.push(Routes.products);

  useEffect(() => {
    setMasterImage(product?.main_image);
  }, [product]);

  const showPreviewPublishBtn = isUpdate && product?.variants?.length > 0;
  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between">
              <Breadcrumb className="secondary text-uppercase r-medium text-truncate">
                <Breadcrumb.Item>
                  <Link to={Routes.products}>products</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item> { isUpdate ? product?.name : 'create product'}</Breadcrumb.Item>
              </Breadcrumb>
              { isUpdate && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              ) }
            </div>
            <Divider className="mt-3 mb-3 border-top" />
            <div className="d-flex align-items-center mb-4">
              <Typography.Title className="m-0 header_main fs-xs-24 text-truncate">
                { isUpdate ? product?.name : 'Create Product'}
              </Typography.Title>
              { isUpdate && (
              <div
                className={classNames('pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16 ms-3  ps-2 pe-2 fs-xs-14', { label_success: product?.published === true }, { label_warning: product?.published === false })}
              >
                {product?.published ? 'Published' : 'Unpublished'}
              </div>
              ) }
            </div>
            <Tabs className="general_tab create-product-tab" activeKey={activeTabKey} onTabClick={moveToNextTab}>
              <TabPane tab={<Typography.Title level={5} className="mb-0 r-regular">Basic info</Typography.Title>} key="1">
                <BasicInfo
                  categories={categories?.categories}
                  createProduct={createProduct}
                  isLoading={isLoading}
                  isUpdate={isUpdate}
                  updateProduct={updateProduct}
                  product={product}
                  goToProductListing={goToProductListing}
                  productImage={productImage}
                  masterImage={masterImage}
                  viewPublishConfirnModal={viewPublishConfirnModal}
                  showPreviewPublishBtn={showPreviewPublishBtn}
                />
              </TabPane>
              <TabPane disabled={!id} tab={<Typography.Title level={5} className="mb-0 r-regular">Product images</Typography.Title>} key="2">
                <ProductImages
                  product={product}
                  moveToNextTab={() => moveToNextTab('3')}
                  goToProductListing={goToProductListing}
                  uploadImages={uploadImages}
                  closeUploadModal={closeUploadModal}
                  showUploadModal={showUploadModal}
                  removeImages={removeImages}
                  viewPublishConfirnModal={viewPublishConfirnModal}
                  setProductMasterImage={setProductMasterImage}
                  isUploadVisible={isUploadVisible}
                  isDocUploading={isDocUploading}
                  productImage={productImage}
                  masterImage={masterImage}
                  isLoading={isLoading}
                  showPreviewPublishBtn={showPreviewPublishBtn}
                />
              </TabPane>
              <TabPane disabled={disableTab} tab={<Typography.Title level={5} className="mb-0 r-regular">Variants</Typography.Title>} key="3">
                <Varients
                  product={product}
                  newVariantList={newVariantList}
                  addVariantList={addVariantList}
                  removeVariantList={removeVariantList}
                  createVariant={createVariant}
                  updateVariant={updateVariant}
                  goToProductListing={goToProductListing}
                  deleteVariant={deleteVariant}
                  viewPublishConfirnModal={viewPublishConfirnModal}
                  isLoading={isLoading}
                  getProductById={() => getProductById(id)}
                  showPreviewPublishBtn={showPreviewPublishBtn}
                />
              </TabPane>
              {/* <TabPane disabled={disableTab} tab={<Typography.Title level={5}
               className="mb-0 r-regular">Compatible vehicles</Typography.Title>} key="4">
                <CompatibleVehicles />
              </TabPane> */}
            </Tabs>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph> { isUpdate ? 'See and manage product details' : 'Create new product.'}</Paragraph>
              <Paragraph>Size created for a product will be linked to that product
                category and will not be available in other categories.
              </Paragraph>
            </Typography>
            { isUpdate && (
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-xl-5">Action</h6>
              <Tooltip title="Delete Product">
                <Button disabled={isLoading} onClick={viewDeleteConfirmModal} className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0">
                  Delete Product
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            ) }
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('productStore', 'categoryStore')(observer(CreateProduct));
