import React from 'react';
import {
  Typography,
} from 'antd';

interface IPaymentStatus {
  isPaymentPending: boolean
  planlabelColor: string
  dueDate: any
}

function PaymentStatus(props: IPaymentStatus) {
  const { isPaymentPending, dueDate, planlabelColor } = props;

  if (!isPaymentPending) {
    return (
      <Typography.Paragraph className={`${planlabelColor} text-uppercase mb-0`}>
        {dueDate}
      </Typography.Paragraph>
    );
  }
  return (
    <div className="pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16 label_warning ps-2 pe-2 fs-xs-14">
      Payment Pending
    </div>
  );
}

export default PaymentStatus;
