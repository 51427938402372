/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import Routes from '../../Global/Routes';
import facilityimg from '../../Assets/Images/facility.png';
import './Facilities.less';
import qrimage from '../../Assets/Images/comp_qr.svg';
import Toast from '../../Components/Toast';
import facilityService from '../../Services/facilityService';
import {
  Icon, NoData, Pagination, SortTh, Status,
} from '../../Components';
import Constant from '../../Global/Constant';

import requestService from '../../Services/requestService';
import ConfirmModal from '../../Components/ConfirmModal';
import { IFacilityStoreProps } from '../../Store/facilityStore';
import { AuthStoreProps } from '../../Store/authStore';
import { FacilityLog } from '../../Global/interface';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IFacilityDetails {
  facilityStore: IFacilityStoreProps;
  authStore: AuthStoreProps;
}

function FacilityDetails(props: IFacilityDetails) {
  const { facilityStore, authStore } = props;
  const { facility, facilityLogs, facilityLogTotal } = facilityStore;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [order, setOrder] = useState('createdAt');
  const [orderBy, setOrderBy] = useState(-1);
  const [showVehicleCondition, setShowVehicleCondition] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState<any>({});
  const totalCapacity: number = facility?.total_capacity;
  const usedCapacity: number = facility?.used_capacity;
  const lotNotInUse = totalCapacity - usedCapacity;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [vehicleCondition, setVehicleCondition] = useState<any>([]);
  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(
    Constant.userAccess.SUPER_ADMIN,
  );
  const history = useHistory();
  const { id } = useParams<any>();
  const [collapsed, setCollapsed] = useState(false);

  const columns = [
    {
      title: (
        <SortTh
          title="Timestamp"
          dataIndex="createdAt"
          order={order}
          orderBy={orderBy}
        />
      ),
      dataIndex: 'createdAt',
      onHeaderCell: () => ({
        onClick: () => {
          sortServiceRequest('createdAt');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: 'Contract ID',
      dataIndex: 'contractid',
      ellipsis: true,
    },
    {
      title: 'In/Out',
      dataIndex: 'in',
      ellipsis: true,
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      ellipsis: true,
    },
  ];

  const sortServiceRequest = (dataIndex: string) => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrder(dataIndex);
    setOrderBy(nextSort);
    getFacilityLog(pageNumber, order, nextSort);
  };

  const buttonHandler = (facilityLog: FacilityLog) => {
    setVehicleDetails({
      vehicle: facilityLog?.vehicle_no || facilityLog?.vehicle_code,
      model: facilityLog?.vehicle_display_name,
    });
    setVehicleCondition(facilityLog?.vehicle_images);
    setShowVehicleCondition(true);
  };

  const data = facilityLogs?.map((facilityLog: FacilityLog) => ({
    createdAt: `${Utility.getRelativeDate(facilityLog?.createdAt)}`,
    vehicle: <div className="w-100 text-truncate">{facilityLog?.vehicle_display_name}</div>,
    contractid: facilityLog?.contract_code,
    in: (
      <Status
        status={facilityLog?.action}
        statusClassName={Constant.facilityLogActionClassName}
        statusText={Constant.facilityLogAction}
      />
    ),
    condition: (
      <Button
        className="d-flex r-bold align-items-center p-0 primary border-0 shadow-none bg-transparent"
        onClick={() => buttonHandler(facilityLog)}
      >
        View
        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
          <Icon className="icon-8" iconRef="#arrow_right" />
        </div>
      </Button>
    ),
  }));

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getFacilityById = async (fid: string) => {
    setIsLoading(true);
    try {
      await facilityStore.getFacilityById(fid);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  const getFacilityLog = async (page: number, sort: string, sortBy: number) => {
    setIsLoading(true);
    try {
      await facilityStore.getFacilityLog({
        facility: id,
        limit: Constant.facilityLog,
        page,
        order: sort,
        orderBy: sortBy,
      });
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFacilityById(id);
    getFacilityLog(pageNumber, order, orderBy);
  }, []);

  const deleteFacility = async () => {
    try {
      setIsLoading(true);
      await facilityService.deleteFacility(id);
      setIsLoading(false);
      history.push(Routes.facilities);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const viewDeleteConfirm = () => {
    if (usedCapacity === 0) {
      if (isSuperAdmin) {
        ConfirmModal(
          'Delete Facility',
          'Do you want to delete this facility?',
          deleteFacility,
        );
      } else {
        ConfirmModal(
          'Request for Facility Deletion',
          'Do you want to delete this facility?',
          empDeleteRequest,
          'Request',
        );
      }
    } else {
      Toast?.warning(
        `Facility is used in ${usedCapacity} ${
          usedCapacity > 1 ? 'contracts.' : 'contract.'
        }`,
      );
    }
  };

  const empDeleteRequest = async () => {
    await requestService
      .createEmployeeRequest({
        target_id: id,
        request: Constant.employeeRequest.FACILITY_DELETE,
      })
      .then(() => history.push(Routes.facilities))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const gotoFacilityEdit = () => history.push(`${Routes.create_facility}/${id}`);

  const disableDeleteBtn = !!(!isSuperAdmin && facility?.delete_requested);

  const iscolor = disableDeleteBtn ? '#7777' : '';

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getFacilityLog(page, order, orderBy);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="parking logs" />
    ),
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Tooltip title={disableDeleteBtn ? 'Request for deleting the facility is already made' : ''}>
          <Button style={{ color: iscolor }} className="d-flex ml-auto r-bold align-items-center delete-btn resp_req_btn p-0" disabled={disableDeleteBtn} onClick={viewDeleteConfirm}>
            { isSuperAdmin ? 'Delete Facility'
              : 'Request for delete' }
            {!disableDeleteBtn && (
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
            )}
          </Button>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const closeModal = () => {
    setImageIndex(0);
    setShowVehicleCondition(false);
  };

  const onClickHandler = (index: any) => setImageIndex(index);
  return (
    <Layout.Content className="detail_wrapper fac_detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.facilities}>Facilities</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{facility?.facility_code}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {facility?.facility_code}
                </Typography.Title>
                {isSuperAdmin && (
                  <Button
                    className="main_btn fs-16 r-bold"
                    onClick={gotoFacilityEdit}
                  >
                    Edit Information
                  </Button>
                )}
              </div>
              <Row gutter={16}>
                <Col xxl={7} lg={8} md={8} sm={24} xs={24}>
                  {facility?.image?.fileUrl ? (
                    <div className="position-relative fac_img_wrapper mb-3">
                      <img
                        className="w-100"
                        src={facility?.image?.fileUrl}
                        alt=""
                      />
                      <Button
                        className="d-flex justify-content-center align-items-center text-center p-0 fullscreen_icon fac_fullscrn_icon position-absolute"
                        shape="circle"
                        onClick={showModal}
                      >
                        <Icon className="icon-14" iconRef="#fullscreen" />
                      </Button>
                      <Modal
                        centered
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        bodyStyle={{ padding: 0 }}
                        closeIcon={(
                          <Button
                            className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close"
                            type="link"
                            shape="circle"
                            onClick={handleCancel}
                          >
                            <Icon className="icon-10" iconRef="#close" />
                          </Button>
                        )}
                      >
                        <img
                          className="w-100"
                          src={facility?.image?.fileUrl || facilityimg}
                          alt=""
                        />
                      </Modal>
                    </div>
                  ) : (
                    <div className="position-relative fac_img_wrapper mb-3">
                      <img className="w-100" src={facilityimg} alt="" />
                    </div>
                  )}
                  <Card className="mb-3 mb-lg-0">
                    <Typography.Text className="secondary r-medium">
                      Name
                    </Typography.Text>
                    <Typography.Title className="mt-0 primary" level={3}>
                      {facility?.name}
                    </Typography.Title>
                    <Typography.Text className="secondary r-medium">
                      Location
                    </Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold">
                      {facility?.address}
                    </Typography.Paragraph>
                    <Typography.Text className="secondary r-medium">
                      Phone
                    </Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold">
                      {facility?.phone}
                    </Typography.Paragraph>
                    <Typography.Text className="secondary r-medium">
                      Plans
                    </Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold">
                      {usedCapacity}
                    </Typography.Paragraph>
                    <Typography.Text className="secondary r-medium">
                      Lots not in use
                    </Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold">
                      {lotNotInUse}
                    </Typography.Paragraph>
                  </Card>
                </Col>
                <Col xxl={17} lg={16} md={16} sm={24} xs={24}>
                  <Card title="Facility Logs" className="table_card_title">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      locale={locale}
                      size="small"
                      className="fac_detail_listing table_wrapper ins_table_wrapper"
                    />
                  </Card>
                  <Pagination
                    total={facilityLogTotal}
                    currentPage={pageNumber}
                    pageSize={Constant.facilityLog}
                    onChange={pageHandler}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper hidden d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>
                Info
              </Title>
              <Paragraph>See logs and manage the facility</Paragraph>
            </Typography>
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-5">
                Action
              </h6>
              <Tooltip
                title={
                  disableDeleteBtn
                    ? 'Request for deleting the facility is already made'
                    : ''
                }
              >
                <Button
                  style={{ color: iscolor }}
                  className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0"
                  disabled={disableDeleteBtn}
                  onClick={viewDeleteConfirm}
                >
                  {isSuperAdmin ? 'Delete Facility' : 'Request for delete'}
                  {!disableDeleteBtn && (
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  )}
                </Button>
              </Tooltip>
            </div>
          </Sider>
          <Modal
            centered
            visible={showVehicleCondition}
            onCancel={closeModal}
            footer={null}
            width={720}
            closeIcon={(
              <Button
                className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close"
                type="link"
                shape="circle"
                onClick={closeModal}
              >
                <Icon className="icon-10" iconRef="#close" />
              </Button>
            )}
          >
            <div className="d-flex pe-5">
              <img
                src={qrimage}
                width="44px"
                className="h-100"
                alt="qr_code"
                style={{ cursor: 'pointer' }}
              />
              <div className="ms-3 w-75">
                <Typography.Title
                  level={5}
                  className="text-uppercase mb-0 primary text-truncate w-100"
                >
                  {vehicleDetails?.model}
                </Typography.Title>
                <Typography.Paragraph className="mb-0 secondary">
                  {vehicleDetails?.vehicle}
                </Typography.Paragraph>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center mt-5 mb-5">
                <img className="w-100 m_width_518" src={vehicleCondition[imageIndex]?.fileUrl} alt="vehicle_image" />
              </div>
              <div className="d-flex">
                { vehicleCondition?.map((vehicleImage: any, index: number) => (
                  <Button
                    onClick={() => onClickHandler(index)}
                    className={`d-flex justify-content-center align-items-center tab_img_gallery border-radius border-color me-2 ps-1 pe-1 cursor-pointer ${index === imageIndex && ' active_veh_brd '}`}
                  >
                    <img className="w-100" src={vehicleImage?.fileUrl} alt="vehicle_image" />
                  </Button>
                ))}
              </div>
            </div>
          </Modal>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('facilityStore', 'authStore')(observer(FacilityDetails));
