/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Typography } from 'antd';
import RenderItem from '../RenderItem';

interface WheelInfoProps {
    wheelInfo: any,
    wheelNo: Number
}

function WheelInfo(props: WheelInfoProps) {
  const {
    wheelInfo, wheelNo,
  } = props;

  return (
    <div className="d-sm-flex flex-wrap">
      <RenderItem isTrue={wheelInfo?.wheel_1 !== null && wheelNo > 0}>
        <div className="me-sm-3 r-bold fs-16 lh-sm border-right border-right-xs-0 pe-3 mb-3">
          <Typography.Text className="r-medium fs-14 secondary text-uppercase">WHEEL 1</Typography.Text><br />
          {wheelInfo?.wheel_1} PSI
        </div>
      </RenderItem>
      <RenderItem isTrue={wheelInfo?.wheel_2 !== null && wheelNo > 1}>
        <div className="me-sm-3 r-bold fs-16 lh-sm border-right border-right-xs-0 pe-3 mb-3">
          <Typography.Text className="r-medium fs-14 secondary text-uppercase">WHEEL 2</Typography.Text><br />
          {wheelInfo?.wheel_2} PSI
        </div>
      </RenderItem>
      <RenderItem isTrue={wheelInfo?.wheel_3 !== null && wheelNo > 2}>

        <div className="me-sm-3 r-bold fs-16 lh-sm border-right border-right-xs-0 pe-3 mb-3">
          <Typography.Text className="r-medium fs-14 secondary text-uppercase">WHEEL 3</Typography.Text><br />
          {wheelInfo?.wheel_3} PSI
        </div>
      </RenderItem>
      <RenderItem isTrue={wheelInfo?.wheel_4 !== null && wheelNo > 3}>
        <div className="me-3 r-bold fs-16 lh-sm mb-3">
          <Typography.Text className="r-medium fs-14 secondary text-uppercase">WHEEL 4</Typography.Text><br />
          {wheelInfo?.wheel_4} PSI
        </div>
      </RenderItem>
    </div>
  );
}

export default WheelInfo;
