/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Layout, Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Routes from '../../Global/Routes';
import errorimg from '../../Assets/Images/art.svg';
import './NotFound.less';

function NotFound() {
  const history = useHistory();

  return (
    <Layout.Content className="d-flex align-items-center justify-content-center">
      <Result
        icon={<img src={errorimg} alt="error_img" className="w-100" />}
        className="error_wrapper"
        title="Lost ?"
        subTitle="Don't worry, we will help you to get back"
        extra={(
          <Button type="primary" className="main_btn fs-16 r-bold" onClick={() => history.push(Routes.home)}>
            Go Home
          </Button>
        )}
      />
    </Layout.Content>
  );
}

export default NotFound;
