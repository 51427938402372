/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import {
  Button, Divider, Form, InputNumber, Modal, Spin, Typography,
} from 'antd';
import { Icon } from '..';
import Toast from '../Toast';

interface ActionProps {
  onOk: (adminPin: number|null) => void,
  onCancel: () => void,
  isModalVisible: boolean,
  okButtonText?: string,
  cancelButtonText?: string
  title?: string,
  isLoading: boolean,
  description?: any,
  okButtonStyle?: string,
  cancelButtonStyle?: string
}

ConfirmAdminPin.defaultProps = {
  okButtonText: 'OK',
  cancelButtonText: 'Cancel',
  title: 'Confirm Admin Pin',
  description: '',
  okButtonStyle: 'main_btn me-3 fs-16 r-bold',
  cancelButtonStyle: 'cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold',
};

function ConfirmAdminPin(props: ActionProps) {
  const {
    onOk, onCancel, isModalVisible, okButtonText, cancelButtonText, title, isLoading,
    description, okButtonStyle, cancelButtonStyle,
  } = props;

  const [adminPin1, setAdminPin1] = useState<any>(null);
  const [adminPin2, setAdminPin2] = useState<any>(null);
  const [adminPin3, setAdminPin3] = useState<any>(null);
  const [adminPin4, setAdminPin4] = useState<any>(null);

  const [showPin, setShowPin] = useState<boolean>(false);

  const handleOk = () => {
    if (!(adminPin1 && adminPin2 && adminPin3 && adminPin4)) {
      Toast.error('Please provide a valid 4-digit pin');
    } else {
      onOk(Number(`${adminPin1}${adminPin2}${adminPin3}${adminPin4}`));
    }
  };

  const passwordInput1: any = useRef(null);
  const passwordInput2: any = useRef(null);
  const passwordInput3: any = useRef(null);
  const passwordInput4: any = useRef(null);

  const focusNextorPrev = (value: any, next: any, prev: any) => {
    if (value) {
      next?.current?.focus();
    } else {
      prev?.current?.focus();
    }
  };

  const handleCancel = () => {
    setAdminPin1(null);
    setAdminPin2(null);
    setAdminPin3(null);
    setAdminPin4(null);
    onCancel();
  };

  return (

    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      okText={okButtonText}
      cancelText={cancelButtonText}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper modal_heading_pad"
      width={500}
      footer={null}
      destroyOnClose
      maskClosable={!isLoading}
      centered
      closeIcon={(
        <Button
          className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close"
          type="link"
          shape="circle"
          onClick={handleCancel}
          disabled={isLoading}
        >
          <Icon className="icon-10" iconRef="#close" />
        </Button>
    )}
    >
      <Spin spinning={isLoading}>
        <div>
          <Typography.Title level={5} className="secondary r-regular">{description}</Typography.Title>
        </div>
        <Divider className="border-bottom" />
        <Form>
          <div>
            <Typography.Title level={5} className="fs-14 secondary mt-3 r-medium text-uppercase">Enter your PIN</Typography.Title>
            <div className="d-flex">

              <InputNumber
                onChange={(value: number) => {
                  setAdminPin1(value);
                  focusNextorPrev(value, passwordInput2, passwordInput1);
                }}
                value={adminPin1}
                minLength={1}
                maxLength={1}
                autoFocus
                max={9}
                ref={passwordInput1}
                type={showPin ? 'text' : 'password'}
                min={0}
                className="admin_pin_input inp_style me-2"
                size="large"
              />
              <InputNumber
                onChange={(value: number) => {
                  setAdminPin2(value);
                  focusNextorPrev(value, passwordInput3, passwordInput1);
                }}
                value={adminPin2}
                minLength={1}
                maxLength={1}
                ref={passwordInput2}
                type={showPin ? 'text' : 'password'}
                max={9}
                min={0}
                className="admin_pin_input inp_style me-2"
                size="large"
              />
              <InputNumber
                onChange={(value: number) => {
                  setAdminPin3(value);
                  focusNextorPrev(value, passwordInput4, passwordInput2);
                }}
                value={adminPin3}
                ref={passwordInput3}
                minLength={1}
                maxLength={1}
                type={showPin ? 'text' : 'password'}
                max={9}
                min={0}
                className="admin_pin_input inp_style me-2"
                size="large"
              />
              <InputNumber
                onChange={(value: number) => {
                  setAdminPin4(value);
                  focusNextorPrev(value, passwordInput4, passwordInput3);
                }}
                value={adminPin4}
                ref={passwordInput4}
                minLength={1}
                maxLength={1}
                type={showPin ? 'text' : 'password'}
                max={9}
                min={0}
                className="admin_pin_input inp_style"
                size="large"
              />
            </div>
          </div>
        </Form>
        <div className="d-flex mt-4">
          <Button
            className={okButtonStyle}
            disabled={isLoading}
            onClick={handleOk}
          >
            {okButtonText}
          </Button>
          <Button className={cancelButtonStyle} disabled={isLoading} onClick={handleCancel}>
            {cancelButtonText}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
}

export default ConfirmAdminPin;
