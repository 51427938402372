/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button, Modal } from 'antd';
import { Icon } from '..';

const { confirm } = Modal;

const confirmModal = (
  title: string, content: string, action: any, text?: string,
  okBtnColor?: string, className: string = 'del_modal_wrap',
) => confirm({
  title,
  className,
  width: 533,
  content,
  onOk() {
    action();
  },
  okText: text || 'Delete',
  cancelButtonProps: { style: { background: 'transparent', color: '#000', boxShadow: 'none' } },
  okButtonProps: { style: { backgroundColor: okBtnColor } },
  centered: true,
  closeIcon:
  <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
    <Icon className="icon-10" iconRef="#close" />
  </Button>,
  closable: true,
});

export default confirmModal;
