import React from 'react';
import {
  Divider,
} from 'antd';

interface IVehicleImage {
    activeVehicle: boolean
    image: string
}

function VehicleImage(props: IVehicleImage) {
  const { activeVehicle, image } = props;

  if (activeVehicle) {
    return (
      <div className="p-3 d-flex justify-content-center">
        <img src={image} alt="vehicle" className="w-100" />
      </div>
    );
  }
  return (
    <Divider className="border-bottom my-3" />
  );
}

export default VehicleImage;
