/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import constant from '../../Global/Constant';

const rule: any = {
  phone: [{
    required: true,
    message: constant.phoneRequired,
  }, {
    type: 'string',
    pattern: /^\s*(?:\+?(\d{1,3}))?(\d{2,3})(\d{3})(\d{4})(?: *x(\d+))?\s*$/,
    message: constant.invalidPhone,
  }],
  email: [{
    validator: (field:any, value:any, callback:Function) => {
      if (value && !(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value))) {
        callback(constant.myfatoorahErrorEmail);
      }
      callback();
    },
  },
  {
    required: true,
    message: constant.emailRequiredValidationError,
  }],
};

export default rule;
