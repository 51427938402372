/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, action, makeObservable } from 'mobx';
import customerService from '../Services/customerService';
import Constant from '../Global/Constant';
import {
  Client, clientQuery, Contract, Vehicle,
} from '../Global/interface';

export interface ICustomerStore{
  customers: Array<Client>,
  customer: Client,
  vehicles: Vehicle,
  contracts: Contract,
  total: number,
  orderCount: number,
  customerHistory: Array<Client>
  historyTotal: number
  initialize: Function
  createCustomer: (data: any) => Promise<any>,
  getCustomerById: (id: string) => Promise<any>,
  updateCustomer: (customer: any, data: any) => Promise<any>
  listCustomers: (queryParams: clientQuery) => Promise<any>
  deleteCustomer: (id: string) => Promise<any>
  freezeAccount: (id: string, data: any) => Promise<any>
  getCustomerHistory: (queryParams: clientQuery) => Promise<any>
}

class CustomerStore {
  customers: any = [];

  customer: any = null;

  total: number = 0;

  orderCount: number = 0;

  customerHistory: Array<Client> | null = null;

  historyTotal: number = 0

  constructor() {
    makeObservable(this, {
      customers: observable,
      customer: observable,
      total: observable,
      orderCount: observable,
      customerHistory: observable,
      historyTotal: observable,
      createCustomer: action,
      deleteCustomer: action,
    });
    this.initialize();
  }

  initialize = () => {
    this.customers = [];
    this.total = 0;
    this.customerHistory = null;
    this.historyTotal = 0;
  }

  createCustomer = async (customer: any) => {
    try {
      const response = await customerService.createCustomer(customer);
      this.customer = response?.data?.client;
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  updateCustomer = async (id: string, customer: any) => {
    try {
      const response = await customerService.updateCustomer(id, customer);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getCustomerById = async (id: string) => {
    try {
      const response = await customerService.getCustomerById(id);
      if (response && response.data) {
        this.customer = response?.data?.user;
        this.orderCount = response?.data?.orderCount;
      }
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  listCustomers = async (
    queryParams: clientQuery,
  ) => {
    try {
      const response = await customerService.listCustomers(queryParams);
      if (response && response.data) {
        this.customers = response?.data?.customers;
        this.total = response?.data?.total;
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  deleteCustomer = async (id: string) => {
    try {
      const response = await customerService.deleteCustomer(id);
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getCustomerHistory = async (queryParams: clientQuery) => {
    try {
      const response = await customerService.listCustomers(queryParams);
      if (response && response?.data) {
        this.customerHistory = response?.data?.customers;
        this.historyTotal = response?.data?.total?.total;
      }
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  freezeAccount = async (id: string, data: any) => {
    try {
      const response = await customerService.freezeAccount(id, data);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };
}

export default new CustomerStore();
