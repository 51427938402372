/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Button,
  Card, Typography,
} from 'antd';
import React from 'react';
import { Icon } from '..';
import Constant from '../../Global/Constant';
import sricon from '../../Assets/Images/img-sr.svg';
import './RequestCountCard.less';

const { colors } = Constant;
interface RequestCountCardProps {
  title: string,
  count: string|0,
  color: string,
  textColor?: string,
  boxColor?: string,
  buttonColor?: string,
  loading: boolean,
  routeHandler: () => void,
  isSuperAdmin?:boolean,
  isViewButtonDisabled?: boolean,
}

RequestCountCard.defaultProps = {
  textColor: colors.black,
  boxColor: colors.white,
  buttonColor: colors.darkGrey,
  isSuperAdmin: true,
  isViewButtonDisabled: false,
};

function RequestCountCard(props: RequestCountCardProps) {
  const {
    title, count, color, routeHandler, loading, textColor, boxColor, buttonColor, isSuperAdmin,
    isViewButtonDisabled,
  } = props;
  return (
    <Card bordered={false} className={!isSuperAdmin ? 'emp_sr_req dash_card_resp_fnt req_cnt_card emp_card_height' : 'dash_card_resp_fnt req_cnt_card'} loading={loading} style={{ backgroundColor: isSuperAdmin ? boxColor : '#C8EEFF' }}>
      <div className={!isSuperAdmin ? 'd-flex flex-column' : ''}>
        <div className="order-2">
          <Typography.Title className="r-bold lh-1 mb-0 num_fnt_size" style={{ color }}>{count || 0}</Typography.Title>
        </div>
        <div className={!isSuperAdmin ? 'order-1 d-flex justify-content-between' : 'order-1'}>
          <Typography.Title level={3} className="r-bold mb-2 text-truncate" style={{ color: textColor }} title={title}>{title}</Typography.Title>
          <Button disabled={isViewButtonDisabled} onClick={routeHandler} style={{ color: buttonColor }} className="d-flex bg-transparent ml-auto r-bold align-items-center p-0 border-0 shadow-none">
            View
            <div style={{ backgroundColor: buttonColor, color: boxColor }} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 dash_view_btn rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </div>
      </div>
      {!isSuperAdmin && (
        <div className="d-flex justify-content-end">
          <div className="sr_icon">
            <img src={sricon} className="w-100" alt="service_req_icon" />
          </div>
        </div>
      )}
    </Card>
  );
}

export default RequestCountCard;
