/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

class MasterService {
  getContractPlan = async (queryItem?: any) => {
    try {
      const query = queryString.stringify(queryItem, { skipNull: true });
      const response = await api.get(`/contract-plans?${query}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getVehicleCategories = async () => {
    try {
      const response = await api.get('/vehicle-categories');
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new MasterService();
