/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Button, Typography } from 'antd';
import React from 'react';
import { RenderItem } from '../../Components';

interface VisitTimeProps {
    time: string,
    Label : any,
    buttonName: string,
    action: () => void
    showReschedule: boolean
}

function VisitTime(props: VisitTimeProps) {
  const {
    time, Label, buttonName, action, showReschedule,
  } = props;
  return (
    <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
      <div className="d-sm-flex align-items-center">
        <Typography.Title className="text-uppercase mb-0 fs-xs-24">
          {time}
        </Typography.Title>
        {Label}
      </div>
      <RenderItem isTrue={showReschedule}>
        <Button htmlType="button" className="main_btn me-3 fs-16 r-bold" onClick={action}>
          {buttonName}
        </Button>
      </RenderItem>
    </div>
  );
}

export default VisitTime;
