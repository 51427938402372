/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  useParams, useHistory, useLocation,
} from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Layout,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import queryString from 'query-string';
import classNames from 'classnames';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import {
  Icon, PaymentMethod, Toast,
} from '../../Components';
import './RenewPlan.less';
import Routes from '../../Global/Routes';
import contractService from '../../Services/contractService';
import { ISettingsStore } from '../../Store/settingsStore';
import { IContractStore } from '../../Store/contractStore';
import PlanDurationModal from './PlanDurationModal';
import { IPlanStore } from '../../Store/planStore';
import { Terms } from '../../Global/interface';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const { EXPIRED } = Constant.contractTypes;

interface IRenewPlan{
  settingsStore: ISettingsStore
  contractStore: IContractStore
  planStore: IPlanStore
}

function RenewPlan(props: IRenewPlan) {
  const { contractStore, settingsStore, planStore } = props;
  const { contract: contractDetails } = contractStore;
  const { settings } = settingsStore;
  const { planTerms } = planStore;

  const { id } = useParams<any>();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const defaultPlanType = queryParams?.tab || EXPIRED;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLinkShared, setIsLinkShared] = useState<any>(contractDetails?.payment_link_sent);
  const [showDurationModal, setshowDurationModal] = useState<boolean>(false);
  const [selectedTerm, setselectedTerm] = useState<any>(null);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [changedPrice, setChangedPrice] = useState<number|null>(null);

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (contractDetails?.custom_amount) {
      setIsCustom(true);
    }
  }, [contractDetails]);
  const getContractById = async () => {
    try {
      setIsLoading(true);
      await contractStore.getContractById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
      history.replace(`${Routes.contracts}?tab=${defaultPlanType}`);
    }
  };

  useEffect(() => {
    setIsLinkShared(contractDetails?.payment_link_sent);
  }, [contractDetails?.payment_link_sent]);

  useEffect(() => {
    getContractById();
    getSettings();
  }, []);
  const gotoPlanDetails = () => history.push(`${Routes?.contract_detail}/${contractDetails?._id}?tab=${defaultPlanType}`);
  const gotoCLient = () => history.push(`${Routes?.client_detail}/${contractDetails?.client?._id}`);

  const renewContract = async (data: any) => {
    try {
      if (!terms && !Object.keys(selectedTerm).length) return Toast.error('Please change the plan duration.');
      setIsLoading(true);
      const params = data;
      if (selectedTerm?.duration) params.plan_duration = selectedTerm?.duration;
      if (price) params.price = price;
      params.isCustom = isCustom;
      await contractStore?.renewContract(id, data);
      history.push(`${Routes?.renew_plan}/${contractDetails?._id}?orderId=${contractStore?.orderId}&tab=${defaultPlanType}`);
      setIsLoading(false);
      if (data?.payment_mode === Constant?.payment_mode.GATEWAY) {
        setIsLinkShared(true);
        Toast?.success('Link shared successfully');
      }
      await getContractById();
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
    return null;
  };

  const handlePlanPayment = async (data: any) => {
    try {
      setIsLoading(true);
      await contractStore?.makePayment(id, data);
      setIsLoading(false);
      if (data?.payment_mode === Constant?.payment_mode.GATEWAY) {
        setIsLinkShared(true);
        Toast?.success('Link shared successfully');
      }
      await getContractById();
      history.push(`${Routes?.renew_plan}/${contractDetails?._id}?newPlan=true&orderId=${contractStore?.orderId}&tab=${defaultPlanType}`);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const response = await contractService?.downloadInvoice(queryParams?.orderId as string);
      const url = await Utility.createObjectUrlFromUrl(response?.data?.url);
      Utility.downloadFile(url, `Invoice-${queryParams?.orderId}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const dueDate: any = contractDetails?.due_date ? contractDetails?.due_date : false;

  const isRenewableContract = (contractDetails?.due_date as number) - Date.now() <= Constant?.renewDaysInMilliseconds * settings?.plan_expiring_time;
  const isPaymentsuccess = !isLinkShared && queryParams?.orderId && contractDetails?.due_date && contractDetails?.due_date - Date.now() > Constant?.renewDaysInMilliseconds * settings?.plan_expiring_time;
  const isNewPlan = !contractDetails?.due_date;

  const clientName = Utility.getUserName(contractDetails?.client);

  const deletedUser = contractDetails?.client?.deleted;

  const isVaultPlan = contractDetails?.contract?.plan_type === Constant.plan_type?.VAULT;

  const terms = Utility.getContractPlanTerm(isVaultPlan, contractDetails?.contract?.terms, contractDetails?.duration as number, contractDetails?.duration_type as string, contractDetails?.facility?._id as string);

  const fetchPlanDetails = async (planId: string) => {
    try {
      setIsLoading(true);
      await planStore?.getPlanById(planId);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  useEffect(() => {
    if (isVaultPlan) fetchPlanDetails(contractDetails?.contract?._id as string);
  }, [isVaultPlan]);

  const planTermDetails = isVaultPlan ? planTerms?.find((term: any) => term?._id === contractDetails?.facility?._id)?.termDetails : contractDetails?.contract?.terms;

  const planDurationModalHandler = () => setshowDurationModal(!showDurationModal);

  const termChangeHandler = (term: Terms, finalPrice: number, isCustomChecked: boolean) => {
    setChangedPrice(finalPrice);
    setIsCustom(isCustomChecked);
    setselectedTerm(term);
  };

  const duration = selectedTerm?.duration || contractDetails?.upcoming_duration || contractDetails?.duration || 0;

  const duration_type = selectedTerm?.duration_type || contractDetails?.duration_type || 'MONTH';

  const isPaymentLinkSend = (isRenewableContract || isNewPlan) && isLinkShared;

  const updatedContractPrice = changedPrice || selectedTerm?.price || contractDetails?.price;

  const updatedTermPrice = changedPrice || selectedTerm?.price || terms?.price;

  const updatedPrice = isPaymentLinkSend ? updatedContractPrice : updatedTermPrice;

  const price = isCustom ? changedPrice || selectedTerm?.price || contractDetails?.price || 0 : updatedPrice || 0;

  const totalAmount = !isRenewableContract ? contractDetails?.price : (price + (contractDetails?.dueAmount || 0));

  const showAction = !contractDetails?.payment_link_sent && isRenewableContract;

  const onCancel = (data: any) => {
    planDurationModalHandler();
    if (!data) setselectedTerm(null);
  };
  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center primary w-max-content bg-transparent border-0 shadow-none p-0" onClick={gotoPlanDetails}>
                <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_left" />
                </div>
                Back
              </Button>
            </div>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {queryParams?.newPlan ? 'Make Payment' : 'Renew Payment'}
            </Typography.Title>
            {isPaymentsuccess
            && (
            <Card className="green_bg border-radius mb-3 shadow-none">
              <div className="d-sm-flex align-items-center justify-content-between">
                <div className="large_icn_bg green white_bg p-0 rounded-circle justify-content-center d-flex align-items-center cir_outline me-5">
                  <Icon className="icon-40" iconRef="#tick" />
                </div>
                <div className="d-flex align-items-center w-100 lg_icon_info_ht mt-3 mt-sm-0">
                  <Typography className="w-100">
                    <Title level={3} className="text-truncate primary r-bold mb-0 white fs-xs-18" title="Payment Successful">Payment Successful</Title>
                    {(contractDetails?.last_renewed_date || contractDetails?.payment_date) && <Paragraph className="secondary mb-0 white">Paid {Utility?.getRelativeDate(queryParams?.newPlan ? contractDetails.payment_date : contractDetails?.last_renewed_date)}</Paragraph>}
                  </Typography>
                </div>
                <Button className="d-flex align-items-center bg-transparent border-0 p-0 shadow-none white r-bold text-right mt-1" onClick={() => downloadInvoice()}>
                  Download Invoice
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle green white_bg">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </div>
            </Card>
            )}
            {(isRenewableContract || isNewPlan) && isLinkShared
            && (
            <Card className="yellow_bg border-radius mb-3 shadow">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-sm-flex">
                  <div className="large_icn_bg cir_outline yellow white_bg p-0 rounded-circle justify-content-center d-flex align-items-center me-5">
                    <Icon className="icon-40" iconRef="#clock" />
                  </div>
                  <div className="d-flex align-items-center w-100 lg_icon_info_ht mt-3 mt-sm-0">
                    <Typography className="w-100">
                      <Title level={3} className="text-truncate primary r-bold mb-0 white fs-xs-18" title="Waiting for payment">Waiting for payment</Title>
                      {contractDetails?.payment_link_sent_date && <Paragraph className="secondary mb-0 white">Link shared {Utility?.getRelativeDate(contractDetails?.payment_link_sent_date)}</Paragraph>}
                    </Typography>
                  </div>
                </div>
              </div>
            </Card>
            )}
            <div className="mb-3">
              <Row gutter={[16, 16]}>
                <Col lg={16} xs={24}>
                  <Card>
                    <div className="position-relative">
                      <div
                        className={classNames('plan_info_img exo_fnt position-absolute position-sm-relative d-flex align-items-center justify-content-start ps-2', { 'fs-60': (Utility?.reverseDuration(duration, duration_type) as number >= 10) })}
                      >
                        {Utility?.reverseDuration(duration, duration_type)}<sub className="r-medium secondary fs-16 roboto position-absolute">{Constant?.durationTypeName.get(duration_type)}</sub>
                      </div>
                      <div className="plan_info_renew_pad plan_price_wrap_ht mt-3 mt-sm-0 d-sm-flex align-items-center">
                        <div className="border-right brd-r-xl-none brd-r-none renew_plan_txt pe-sm-4 me-sm-4">
                          <Typography.Title level={3} className="mb-0 fs-xs-20 text-truncate">
                            {contractDetails?.contract?.plan} Plan
                          </Typography.Title>
                          {!isNewPlan
                          && <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">{Date.now() > dueDate ? 'Expired' : 'Expiring'} on {Utility.getFormattedDate(dueDate)}</Typography.Paragraph>}
                          {showAction && (
                          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary" onClick={planDurationModalHandler}>
                            Change Term
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          ) }
                        </div>
                        <div className="d-flex">
                          {isRenewableContract
                          && (
                          <div className="mt-3 mt-sm-0 me-3">
                            <Typography.Title level={3} className="mb-0 primary fs-xs-20">
                              {price} KD
                            </Typography.Title>
                            <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">Plan</Typography.Paragraph>
                          </div>
                          )}
                          {isRenewableContract
                            && (
                            <div className="mt-3 mt-sm-0 me-3">
                              <Typography.Title level={3} className="mb-0 primary fs-xs-20">
                                {contractDetails?.dueAmount} KD
                              </Typography.Title>
                              <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">Due</Typography.Paragraph>
                            </div>
                            )}
                          <div className="mt-3 mt-sm-0">
                            <Typography.Title level={3} className="mb-0 red fs-xs-20">
                              {totalAmount} KD
                            </Typography.Title>
                            <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">Total</Typography.Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={8} xs={24}>
                  <Card>
                    <div className="d-flex align-items-center position-relative">
                      <div className="large_icn_bg p-0 rounded-circle justify-content-center d-flex align-items-center sec_info_img position-absolute">
                        <Icon className="icon-40" iconRef="#User" />
                      </div>
                      <div className="sec_info_pad d-flex align-items-center w-100 lg_icon_info_ht">
                        <Typography className="w-100">
                          <Title
                            level={3}
                            className="text-truncate primary r-bold mb-0 text-capitalize"
                            title={clientName}
                          >
                            {clientName}
                          </Title>
                          <Paragraph className="secondary mb-0">Client ID - {contractDetails?.client?.client_code}</Paragraph>
                        </Typography>
                      </div>
                    </div>
                    <div className="sec_info_pad ps_xs_0 d-flex align-items-center justify-content-center justify-content-sm-start">
                      <Button disabled={deletedUser} className="d-flex p-0 r-bold align-items-center primary border-0 shadow-none bg-transparent" onClick={gotoCLient}>
                        View
                        {!deletedUser && (
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                        )}
                      </Button>
                      {deletedUser && (
                        <Tooltip title="User deleted">
                          <div className="lh_0">
                            <Icon className="icon-16 ms-2" iconRef="#info" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </Card>
                </Col>
                {isRenewableContract
                && (
                <PaymentMethod
                  paymentHeading="Choose how you want to renew the plan."
                  finishHandler={renewContract}
                  email={contractDetails?.client?.email}
                  phone={contractDetails?.client?.phone}
                />
                )}
                {isNewPlan
                && (
                <PaymentMethod
                  paymentHeading="Choose how you want to pay for the plan."
                  finishHandler={handlePlanPayment}
                  email={contractDetails?.client?.email}
                  phone={contractDetails?.client?.phone}
                  okButtonText="Make payment"
                />
                )}
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{queryParams?.newPlan ? 'Make payment for plan' : 'Renew the plan'}</Paragraph>
            </Typography>
            {/* {showAction && (
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Actions</Title>
            </Typography>
            )} */}
          </Sider>
        </Row>
        <PlanDurationModal
          visibility={showDurationModal}
          terms={planTermDetails}
          selectedTerm={selectedTerm}
          defaultTerm={selectedTerm || terms}
          termChangeHandler={termChangeHandler}
          onCancel={onCancel}
          onOk={planDurationModalHandler}
          price={price}
          isCustomPrice={isCustom}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('contractStore', 'settingsStore', 'planStore')(observer(RenewPlan));
