/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { VehicleForm } from '../Global/interface';
import api from './api';

class VehicleCategoryService {
  getVehiclesCategory = async () => {
    try {
      const response = await api.get('/vehicle-categories');
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  createVehicle = async (vehicle: VehicleForm) => {
    try {
      const response = await api.post('/vehicles', vehicle);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateVehicle = async (id: string|undefined, vehicle: VehicleForm) => {
    try {
      const response = await api.put(`/vehicles/${id}`, vehicle);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getVehicleById = async (id: String, query?: any) => {
    try {
      const queryParams = queryString.stringify(query, { skipNull: true });
      const response = await api.get(`/vehicles/${id}?${queryParams}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getVehicleStatus = async (vehicleId: String, queryParams?: any) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const response = await api.get(`/vehicles/${vehicleId}/status?${query}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateVehicleStatus = async (id: string, vehicle: VehicleForm) => {
    try {
      const response = await api.put(`/vehicles/${id}/status`, vehicle);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  deleteVehicle = async (id: string) => {
    try {
      const response = await api.delete(`/vehicles/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
export default new VehicleCategoryService();
