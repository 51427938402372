/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col,
  Layout,
  Radio,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Icon, NoData, Pagination, SortTh, Status, StatusLabel, Toast,
} from '../../Components';

import { Order, OrderRequest } from '../../Global/interface';
import { IOrderStore } from '../../Store/orderStore';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import Routes from '../../Global/Routes';

import './Orders.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const order_status = ['CREATED', 'DELIVERED', 'PREPARE_ORDER', 'IN_TRANSIT', 'DELIVERED'];

interface IOrders {
  orderStore: IOrderStore
}

function Orders(props: IOrders) {
  const { orderStore } = props;
  const { orders, total, requests } = orderStore;

  const history = useHistory();
  const location = useLocation();

  const queryParams: any = queryString.parse(location?.search);
  const defaultOrderStatus = queryParams?.tab && queryParams?.tab !== 'undefined'
    ? queryParams?.tab : Constant.orderStatus.Orders;

  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState<any>('createdAt');
  const [order, setOrder] = useState<any>(-1);
  const [orderStatus, setOrderStatus] = useState<any>(defaultOrderStatus);
  const [collapsed, setCollapsed] = useState(false);

  const isOrderStatus = orderStatus === Constant.orderStatus.Orders;

  const showNodata = total === 0;

  const getData = (status: any, page: number, sort: number, sortBy: string) => {
    if (status === Constant.orderStatus.Orders) getOrders(page, sort, sortBy);
    else if (status === Constant.orderStatus.CANCELLED) getCancelOrderRequests(page, sort, sortBy);
    else if (status === Constant.orderStatus.RETURN) getReturnOrderRequests(page, sort, sortBy);
  };

  const filterHandler = (event: any) => {
    const { value } = event?.target;
    setOrderStatus(value);
    setPageNumber(Constant.defaultPageNumber);
    setOrderBy('createdAt');
    setOrder(-1);
    history.push(`${Routes.orders}?tab=${value}`);
    getData(value, Constant.defaultPageNumber, -1, 'createdAt');
  };

  const sortTable = (dataIndex: string) => {
    let sort = -1;
    if (order === -1 && dataIndex === orderBy) sort = 1;
    else if (dataIndex !== orderBy) setOrderBy(dataIndex);
    setOrder(sort);
    getData(orderStatus, pageNumber, sort, dataIndex);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      width: '10%',
      render: (value:any) => ({
        props: { 'data-heading': 'No' },
        children: value,
      }),
    },
    {
      title: 'Order ID',
      dataIndex: isOrderStatus ? 'order_code' : 'request_code',
      ellipsis: true,
      render: (value:any) => ({
        props: { 'data-heading': 'Order ID' },
        children: value,
      }),
    },
    {
      title: <SortTh title="Customer Name" dataIndex={isOrderStatus ? 'customer.first_name' : 'order.customer.first_name'} orderBy={order} order={orderBy} />,
      dataIndex: 'customer_name',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(isOrderStatus ? 'customer.first_name' : 'order.customer.first_name');
        },
      }),
      ellipsis: true,
      render: (value:any) => ({
        props: { 'data-heading': 'Customer Name' },
        children: value,
      }),
    },
    {
      title: <SortTh title="Date" dataIndex="createdAt" orderBy={order} order={orderBy} />,
      dataIndex: 'createdAt',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
      render: (value:any) => ({
        props: { 'data-heading': 'Date' },
        children: value,
      }),
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      render: (value:any) => ({
        props: { 'data-heading': 'Total Amount' },
        children: value,
      }),
    },
  ];

  if (isOrderStatus) {
    const orderStatusColumn: any = [{
      title: 'Payment Status',
      dataIndex: 'payment_status',
      render: (value:any) => ({
        props: { 'data-heading': 'Payment Status' },
        children: value,
      }),
    }, {
      title: 'Order Status',
      dataIndex: 'order_status',
      render: (value:any) => ({
        props: { 'data-heading': 'Order Status' },
        children: value,
      }),
    }];
    columns.splice(4, 0, ...orderStatusColumn);
  } else {
    const itemColumn = {
      title: 'Item',
      dataIndex: 'item',
      render: (value:any) => ({
        props: { 'data-heading': 'Item' },
        children: value,
      }),
    };
    columns.splice(4, 0, itemColumn);
    const requestColumn = {
      title: 'Request Status',
      dataIndex: 'request_status',
      render: (value:any) => ({
        props: { 'data-heading': 'Request Status' },
        children: value,
      }),
    };
    columns.splice(6, 0, requestColumn);
  }

  let data: any;

  const getRequestStatus = (request: OrderRequest) => {
    switch (request?.status) {
      case 'OPEN': return 'New';
      case 'IN_PROGRESS': return 'In Progress';
      case 'CLOSED':
        if (request?.action === 'REQUEST_REJECTED') return 'Rejected';
        if (request?.action === 'PRODUCT_REJECTED') return 'Rejected';
        if (request?.action === 'REQUEST_CANCELLED') return 'Cancelled';
        return 'Completed';
      default:
        return '';
    }
  };

  const getRequestClassName = (request: OrderRequest) => {
    switch (request?.status) {
      case 'OPEN': return 'primary_bg white blink';
      case 'IN_PROGRESS': return 'label_warning';
      case 'CLOSED':
        if (request?.action === 'REQUEST_REJECTED') return 'label_danger';
        if (request?.action === 'PRODUCT_REJECTED') return 'label_danger';
        return 'label_success';
      default:
        return '';
    }
  };

  if (isOrderStatus) {
    data = orders?.map((item: Order, index: number) => ({
      rowKey: item?._id,
      order_type: item?.order_type,
      number: ((pageNumber - 1) * Constant.orderPerPage) + (index + 1),
      order_code: item?.order_code,
      customer_name: item?.customer
        ? item?.customer?.first_name
          ? Utility.getUserName(item?.customer) : 'Guest Order'
        : 'Quick order',
      createdAt: Utility.getFormattedDate(item?.createdAt),
      total_amount: `${item?.total_amount} KD`,
      item: item?.order_quantity,
      payment_status: <Status
        statusClassName={Constant.orderClassName}
        status={item?.payment_status}
        statusText={Constant.orderStatusText}
      />,
      order_status: <StatusLabel
        text={Utility.getStatusText(item) as string}
        className={Utility.getStatusClassName(item) as string}
        defaultClass="rounded-pill ps-2 pe-2 w-xl-100 d-inline-block ellipse text-center"
      />,
    }));
  } else {
    data = requests?.map((item: OrderRequest, index: number) => ({
      rowKey: item?._id,
      order_type: item?.order?.order_type,
      number: ((pageNumber - 1) * Constant.orderPerPage) + (index + 1),
      request_code: item?.request_code,
      customer_name: item?.order?.customer ? Utility.getUserName(item?.order?.customer) : 'Quick order',
      createdAt: Utility.getFormattedDate(item?.createdAt),
      total_amount: `${item?.return_amount ? item?.return_amount : item?.order?.total_amount} KD`,
      item: item?.quantity || item?.order?.order_quantity,
      request_status: <StatusLabel
        className={getRequestClassName(item)}
        text={getRequestStatus(item)}
        defaultClass="rounded-pill ps-2 pe-2 w-xl-100 d-inline-block ellipse text-center"
      />,
    }));
  }

  const getOrders = async (page: number, sort: number, sortBy: string) => {
    setIsLoading(true);
    try {
      await orderStore.getOrders({
        page, limit: Constant.orderPerPage, order: sort, orderBy: sortBy, order_status,
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getCancelOrderRequests = async (page: number, sort: number, sortBy: string) => {
    setIsLoading(true);
    try {
      await orderStore.getCancelOrderRequests({
        page, limit: Constant.orderPerPage, order: sort, orderBy: sortBy,
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getReturnOrderRequests = async (page: number, sort: number, sortBy: string) => {
    setIsLoading(true);
    try {
      await orderStore.getReturnOrderRequests({
        page, limit: Constant.orderPerPage, order: sort, orderBy: sortBy,
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    orderStore?.initialize();
    getData(orderStatus, pageNumber, order, orderBy);
  }, []);

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getData(orderStatus, page, order, orderBy);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="orders" />
    ),
  };

  const goToOrderCreate = () => history.push(Routes.create_order);
  const gotoOrderDetails = (record: any) => {
    let route;
    if (isOrderStatus) {
      if (record?.order_type === 'QUICK') route = Routes.quick_order_details;
      else if (record?.order_type === 'GUEST') route = Routes.guest_order_details;
      else route = Routes.order_details;
    } else if (orderStatus === Constant.orderStatus.CANCELLED) route = Routes.cancel_order_details;
    else if (orderStatus === Constant.orderStatus.RETURN) {
      if (record?.order_type === 'QUICK') route = Routes.return_quick_order_details;
      else if (record?.order_type === 'GUEST') route = Routes.return_guest_order_details;
      else route = Routes.return_order_details;
    } else if (orderStatus === Constant.orderStatus.RETURN) route = record?.order_type === 'GUEST' ? Routes.return_guest_order_details : Routes.return_order_details;
    history.push(`${route}/${record.rowKey}?tab=${orderStatus}`);
  };
  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-sm-flex justify-content-between align-items-center mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
                  Orders
                  <Tooltip title="Create Order">
                    <Button onClick={goToOrderCreate} className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle">
                      <Icon className="icon-13" iconRef="#add" />
                    </Button>
                  </Tooltip>
                </Typography.Title>
              </div>
              <div className="d-flex flex-fill justify-content-between justify-content-sm-end align-items-center">
                <Radio.Group
                  onChange={filterHandler}
                  defaultValue={orderStatus}
                  buttonStyle="solid"
                  className="radio_tab radio_tab_v2 gray_bg w-100 border-radius mt-2 mt-sm-0"
                >
                  <Radio.Button value={Constant.orderStatus.Orders} className="radio_inp bg-transparent border-0 w-33 text-center fs-16 fs-xs-14 ps-1 ps-sm-3 pe-1 pe-sm-3">Orders</Radio.Button>
                  <Radio.Button value={Constant.orderStatus.CANCELLED} className="radio_inp bg-transparent border-0 w-33 text-center fs-16 fs-xs-14 ps-1 ps-sm-3 pe-1 pe-sm-3">Canceled</Radio.Button>
                  <Radio.Button value={Constant.orderStatus.RETURN} className="radio_inp bg-transparent border-0 w-33 text-center fs-16 fs-xs-14 ps-1 ps-sm-3 pe-1 pe-sm-3">Return</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            {!showNodata
            && (
            <Table
              onRow={(record) => ({ onClick: () => gotoOrderDetails(record) })}
              className="table_wrapper order_listing"
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={data}
              pagination={false}
              size="small"
              scroll={{ x: '1100px' }}
              locale={locale}
            />
            )}

            {showNodata && isOrderStatus
            && (
            <div>
              <NoData buttonHandler={goToOrderCreate} heading="Empty" buttonText="Create" shopText="Create your first order" />
            </div>
            )}

            {showNodata && !isOrderStatus
            && (
            <div>
              <NoData heading="Empty" shopText="No orders found" />
            </div>
            )}

            <Pagination
              total={total}
              pageSize={Constant.orderPerPage}
              currentPage={pageNumber}
              onChange={pageHandler}
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the orders</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('orderStore')(observer(Orders));
