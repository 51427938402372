/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col, Dropdown, Layout, Menu, Row, Skeleton, Table, Tooltip, Typography,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  Icon, NoData, Pagination, RenderItem, Search,
} from '../../Components/index';
import Constant from '../../Global/Constant';
import { Client } from '../../Global/interface';
import Toast from '../../Components/Toast';
import SortTh from '../../Components/SortTh';
import './ClientListing.less';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';
import Status from './Status';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function ClientListing(props: any) {
  const { authStore, clientStore } = props;
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<number>(-1);
  const [order, setOrder] = useState<string>('joined_date');
  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);
  const [collapsed, setCollapsed] = useState(false);

  const columns = [
    {
      title: <SortTh title="Client ID" dataIndex="client_code" order={order} orderBy={orderBy} />,
      dataIndex: 'client_code',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Client Name" dataIndex="first_name" order={order} orderBy={orderBy} />,
      dataIndex: 'first_name',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: <SortTh title="Joined Date" dataIndex="joined_date" order={order} orderBy={orderBy} />,
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      dataIndex: 'joined_date',
      ellipsis: true,
    },
    {
      title: 'Plans',
      dataIndex: 'contracts',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'active',
    },
  ];

  const sortTable = (dataIndex: any) => {
    let sortBy = -1;
    if (orderBy === -1 && dataIndex === order) sortBy = 1;
    else if (dataIndex !== order) setOrder(dataIndex);
    setOrderBy(sortBy);
    fetchClient(dataIndex, sortBy);
  };

  const fetchClient = async (dataIndex?: any, sortBy?: any) => {
    setIsLoading(true);
    await clientStore.getClients({
      keyword, page: pageNumber, limit: Constant.clientPerPage, order: dataIndex, orderBy: sortBy,
    })
      .then(() => {
        history.push(`${Routes.clients}`);
        setIsLoading(false);
      })
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error);
      });
  };

  const getClientStatus = (client: Client) => {
    if (client?.disabled) return <Status status="Disabled" statusClassName="label_warning" />;
    if (!client?.is_invitation_send && client?.is_invited) return <Status status="Not invited" statusClassName="label_inactive" />;
    if (client?.active) return <Status status="Active" statusClassName="label_success" />;
    return <Status status="Pending signup" statusClassName="label_warning" />;
  };

  const getClientCode = (client: Client) => {
    const textColor = client?.contractCount ? 'green' : 'gray';
    return <span style={{ color: textColor }}>{client?.client_code}</span>;
  };

  useEffect(() => {
    clientStore?.initialize();
    fetchClient(order, orderBy);
  }, [pageNumber, keyword]);

  const { total, clients } = clientStore;
  const clientTableData = clients?.map((client: Client) => ({
    client_code: getClientCode(client),
    first_name: <div title={`${client?.first_name} ${client?.middle_name || ''} ${client?.last_name}`} className="text-capitalize text-truncate">{client?.first_name} {client?.middle_name || ''} {client?.last_name}</div>,
    joined_date: Utility.getFormattedDate(client?.joined_date || client?.createdAt),
    phone: client?.phone || '-',
    active: getClientStatus(client),
    rowKey: client?._id,
    contracts: client?.contractCount,
  }));

  const goToClientDetails = (record: any) => history.push(`/client_detail/${record?.rowKey}`);

  const handleSearchTextChange = (event: any) => {
    setKeyword(event.target.value);
    setPageNumber(Constant.defaultPageNumber);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="Clients" />
    ),
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <RenderItem isTrue={isSuperAdmin}>
        <Menu.Item key="0">
          <Link to={Routes.create_client} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
            Add Existing Client
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Link>
        </Menu.Item>
      </RenderItem>
      <Menu.Divider />
      <Menu.Item key="2">
        <Link to={Routes.client_history} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
          Client History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper client_listing_wrapper client_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
            <Typography.Title className="m-0 header_main fs-xs-24 border-bottom-0">
              Clients
            </Typography.Title>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-sm-center mb-3">
            <div className="d-sm-flex align-items-center flex-fill">
              <h4 className="mb-0 table-header r-bold">All Clients</h4>
              <Search
                value={keyword}
                placeholder="Search"
                titleToolTip="Search Client"
                size="small"
                onChange={handleSearchTextChange}
              />
            </div>
          </div>
          <Table
            onRow={(record) => ({ onClick: () => goToClientDetails(record) })}
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={clientTableData}
            pagination={false}
            size="small"
            scroll={{ x: '800px' }}
            locale={locale}
            className="table_wrapper"
          />
          <Pagination
            total={total}
            pageSize={Constant.clientPerPage}
            currentPage={Number(pageNumber)}
            onChange={(page: number) => setPageNumber(page)}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the clients</Paragraph>
          </Typography>
          <div><h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
            { isSuperAdmin && (
            <Tooltip title="Add existing client">
              <Link to={`${Routes.create_client}?create=true`} className="d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                Add Existing Client
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Tooltip>
            )}
            <Tooltip title="Client history">
              <Link to={Routes.client_history} className="d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                Client History
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('authStore', 'clientStore')(observer(ClientListing));
