/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  category: [{
    required: true,
    message: constant.vehicleCategoryRequired,
  }],
  company: [{
    required: true,
    message: constant.vehicleCompanyRequired,
  }],
  model: [{
    required: true,
    message: constant.vehicleModelRequired,
  }],
  color: [{
    required: true,
    message: constant.vehicleColorRequired,
  }],
  color_name: [{
    required: true,
    message: constant.vehicleColorNameRequired,
  }],
  manufactureYear: [{
    required: true,
    message: constant.manufactureYearRequired,
  }],

};

export default rule;
