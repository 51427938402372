/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

const rule: any = {
  name: [{
    required: true,
    message: constant.facilityNameRequired,
  }, {
    type: 'string',
    pattern: /^[a-zA-Z0-9 ]*[a-zA-Z][a-zA-Z0-9 ]*$/,
    message: constant.specialCharectorError,
  }],
  address: [{
    required: true,
    message: constant.addressRequired,
  }, {
    pattern: /^.{2,}$/,
    message: constant.invalidLocation,
  }],
  total_capacity: [{
    required: true,
    message: constant.capacityRequired,
  },
  {
    pattern: /^[0-9]\d*$/,
    message: constant.onlyIntegerNumbers,
  },
  ],
  phone: [
    {
      validator: Utility?.phoneRequiredValidator, // phone update
    },
  ],
};

export default rule;
