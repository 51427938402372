/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
} from 'antd';

import Utility from '../../Global/Utility';

interface Props {
    showAddVehicle: () => void
}

function EmptyParking(props: Props) {
  const { showAddVehicle } = props;

  return (
    <div className="mb-3">
      <Card className="info_ht">
        <div className="car_image d-flex justify-content-center m-auto">
          <img className="img-fluid" src={Utility.defaultModalImage('')} alt="vehicleimage" />
        </div>
        <div className="row d-flex mt-4">
          <div className="d-flex justify-content-center">
            Empty parking lot, Click to add vehicle
          </div>
          <div className="d-flex justify-content-center mt-3">

            <Button className="main_btn r-bold fs-16" onClick={showAddVehicle}>Add vehicle</Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default EmptyParking;
