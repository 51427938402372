/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Typography,
} from 'antd';
import { Model, Vehicle } from '../../Global/interface';
import RenderItem from '../RenderItem';

export interface ModelInfoProps {
    vehicle: Vehicle,
    model?: Model
}

ModelInfo.defaultProps = {
  model: null,
};

function ModelInfo(props: ModelInfoProps) {
  const { vehicle, model } = props;
  return (
    <div>
      <RenderItem isTrue={!!vehicle?.model?.company || !!model?.company}>
        <Typography.Text className="secondary r-medium text-uppercase">Make</Typography.Text>
        <Typography.Title className="mt-0 primary text-capitalize" level={3}>{vehicle?.model?.company || model?.company}</Typography.Title>
      </RenderItem>
      <RenderItem isTrue={!!vehicle?.model?.model || !!model?.model}>
        <Typography.Text className="secondary r-medium text-uppercase">Model</Typography.Text>
        <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.model || model?.model}</Typography.Paragraph>
      </RenderItem>
      <RenderItem isTrue={!!vehicle?.model?.sub_model || !!model?.sub_model}>
        <Typography.Text className="secondary r-medium text-uppercase">Sub Model</Typography.Text>
        <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.sub_model || model?.sub_model}</Typography.Paragraph>
      </RenderItem>
      <RenderItem isTrue={!!vehicle?.model?.manufacture_year || !!model?.manufacture_year}>
        <Typography.Text className="secondary r-medium text-uppercase">Year</Typography.Text>
        <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.manufacture_year || model?.manufacture_year }</Typography.Paragraph>
      </RenderItem>
      <RenderItem isTrue={!!vehicle?.color}>
        <Typography.Text className="secondary r-medium text-uppercase">Color</Typography.Text>
        <Typography.Paragraph className="primary fs-16 r-bold">
          <span className="veh_color d-inline-block me-1 rounded-circle align-middle sm" style={{ backgroundColor: vehicle?.color }} />
        </Typography.Paragraph>
      </RenderItem>
      <Typography.Text className="secondary r-medium text-uppercase">Category</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.category || model?.category}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Number</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.vehicle_no ? vehicle?.vehicle_no : '-'}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Chassis Number</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.chassis_no ? vehicle?.chassis_no : '-'}</Typography.Paragraph>
    </div>
  );
}

export default ModelInfo;
