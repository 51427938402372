/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable*/
import React from 'react';
import { Bar } from '@ant-design/plots';

const ProgressBar = (props: any) => {
    const { inventoryDetails } = props;

    const data = inventoryDetails?.map((item: any) => ({
        category: item?.category?.name,
        products: 'Products',
        value: item?.total,
        total: item?.total,
        _id: item?.category?._id
    }))

    const config: any = {
        data,
        xField: 'value',
        yField: 'products',
        seriesField: 'category',
        height:150,
        isPercent: true,
        isStack: true,
        innerHeight:0,
        interactions: [
            {
              type: "active-region",
              enable: false,
            },
          ],
        yAxis: {
            line: {
                style: {
                    strokeOpacity: 0,
                }
            }
        },
        xAxis: {
            grid: {
                line: {
                    style: {
                        strokeOpacity: 0,
                    }
                }
            },
            label: {
                formatter: () => { }
            }
        },
        label: {
            position: 'middle',
            content: (item: any) => item?.total,
            style: {
                fill: '#fff',
            },
        },
        tooltip: {
            formatter: (graphData: any) => {
                const count = inventoryDetails?.find((item: any) => item?.category?.name === graphData?.category)
                return { name: graphData?.category, value: count?.total || 0 };
            },
        },
    };
    return <Bar {...config} />;
};
export default ProgressBar;
