/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  contract: [{
    required: true,
    message: Constant.planRequired,
  }],
  plan_type: [{
    required: true,
    message: Constant.planTypeRequired,
  }],
  duration: [{
    required: true,
    message: Constant.contractPeriodRequired,
  }],
  start_date: [{
    required: true,
    message: Constant.startDateRequired,
  }],
  closed_date: [{
    required: true,
    message: Constant.closedDateRequired,
  }],
  facility: [{
    required: true,
    message: Constant.facilityRequired,
  }],
  parked_date: [{
    required: true,
    message: Constant.parkedDateRequired,
  }],
  vehicle: [{
    required: true,
    message: Constant.vehicleRequired,
  }],
  price: [{
    validator: (field:any, value:any, callback:Function) => {
      if (!value) callback(Constant.priceRequired);
      else if (value < 1) callback(Constant.invalidAmount);
      callback();
    },
  }],

};

export default rule;
