/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { Link as Element } from 'react-scroll';
import classNames from 'classnames';
import queryString from 'query-string';

import debounce from 'lodash.debounce';
import Routes from '../../Global/Routes';
import {
  UserCard,
  ContactCard, Search, NoData, SortTh, Address, Icon, Toast, Pagination,
  Status, ConfirmModal, StatusLabel, ActivateAccount, RenderItem, ButtonIcon,
} from '../../Components';

import './CustomerDetails.less';
import { ICustomerStore } from '../../Store/customerStore';
import { IOrderStore } from '../../Store/orderStore';
import { Order } from '../../Global/interface';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import customerService from '../../Services/customerService';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface CustomerDetailsProps {
  customerStore: ICustomerStore,
  orderStore: IOrderStore
  authStore: any
}

const order_status = ['CREATED', 'DELIVERED', 'PREPARE_ORDER', 'IN_TRANSIT', 'DELIVERED'];

function CustomerDetails(props: CustomerDetailsProps) {
  const { customerStore, orderStore, authStore } = props;
  const { customer, orderCount } = customerStore;
  const { orders, total } = orderStore;

  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState();
  const [orderBy, setOrderBy] = useState<any>('createdAt');
  const [order, setOrder] = useState<any>(-1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isCustomerDeleted = customer?.deleted;
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const isFromCustomerHistory = queryParams?.deleted;

  const { id } = useParams<any>();
  const history = useHistory();

  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);

  const sortTable = (dataIndex: string) => {
    let sort = -1;
    if (order === -1 && dataIndex === orderBy) sort = 1;
    else if (dataIndex !== orderBy) setOrderBy(dataIndex);
    setOrder(sort);
    fetchOrders(pageNumber, sort, dataIndex);
  };

  const pageHandler = (page: number) => {
    setPageNumber(page);
    fetchOrders(page, order, orderBy);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      width: '10%',
    },
    {
      title: <SortTh title="Order ID" dataIndex="order_code" orderBy={order} order={orderBy} />,
      dataIndex: 'order_code',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Date" dataIndex="createdAt" orderBy={order} order={orderBy} />,
      dataIndex: 'createdAt',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
    },
  ];

  const data = orders?.map((item: Order, index: number) => ({
    rowKey: item?._id,
    number: ((pageNumber - 1) * Constant.orderPerPage) + (index + 1),
    order_code: item?.order_code,
    customer_name: Utility.getUserName(item?.customer),
    createdAt: Utility.getFormattedDate(item?.createdAt),
    total_amount: `${item?.total_amount} KD`,
    item: item?.order_quantity,
    payment_status: <Status
      statusClassName={Constant.orderClassName}
      status={item?.payment_status}
      statusText={Constant.orderStatusText}
    />,
    order_status:
  <div className="table_label"><StatusLabel
    text={Utility.getStatusText(item) as string}
    className={Utility.getStatusClassName(item) as string}
  />
  </div>,
  }));

  const getCustomerById = async (cid: string) => {
    try {
      setIsLoading(true);
      await customerStore.getCustomerById(cid);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const sendInvitation = async (values: any) => {
    setIsLoading(true);
    await customerService.activateAccount(id, values)
      .then(() => {
        getCustomerById(id);
        setIsModalVisible(false);
      })
      .catch((error: any) => Toast.error(error));
    setIsLoading(false);
  };

  const handleSearchTextChange = (event: any) => {
    setKeyword(event?.target?.value);
    setPageNumber(Constant.defaultPageNumber);
    searchOrderDebouncing(event?.target?.value);
  };

  const searchOrderDebouncing = useCallback(
    debounce((searchText: string) => {
      fetchOrders(pageNumber, order, orderBy, searchText);
    }, Constant.debaounceTime),
    [],
  );

  const gotoOrderDetails = (record: any) => history.push(`${Routes.order_details}/${record.rowKey}`);

  const fetchOrders = async (page: number, sort: number, sortBy: string, searchText?: string) => {
    try {
      setIsLoading(true);
      await orderStore.getOrders({
        page,
        limit: Constant.orderPerPage,
        order: sort,
        orderBy: sortBy,
        customer: id,
        keyword: searchText,
        order_status,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };
  const gotoUpdateCustomer = () => history.push(`${Routes.create_customer}/${id}`);

  let clientAddresses: any = [];
  if (customer?.client_addresses) {
    clientAddresses = [customer?.address, ...customer?.client_addresses];
  }

  const goToOrderCreate = () => history.push(`${Routes.create_order}?customer=${id}`);

  const noOrderPresent = orderCount === 0;

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} />
        : (
          <NoData
            buttonHandler={goToOrderCreate}
            heading="Empty"
            buttonText={noOrderPresent && !isCustomerDeleted ? 'Create' : ''}
            shopText={`${noOrderPresent && !isCustomerDeleted ? `Create the first order for ${Utility?.getUserName(customer)}` : 'No orders found'} `}
          />
        )
    ),
  };

  const deleteCustomer = async () => {
    try {
      setIsLoading(true);
      await customerStore.deleteCustomer(id);
      setIsLoading(false);
      history.push(Routes?.customers);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Customer', 'Do you want to delete this customer?', deleteCustomer);
  };

  useEffect(() => {
    getCustomerById(id);
  }, [id]);

  const activeBtnHandler = () => setIsModalVisible(true);
  const onCancel = () => setIsModalVisible(false);

  useEffect(() => { fetchOrders(pageNumber, order, orderBy); }, [pageNumber]);

  const getClassName = () => {
    if (isCustomerDeleted) return 'label_danger';
    if (customer?.disabled) return 'label_warning';
    if (customer?.active) return 'label_success';
    return 'label_warning';
  };

  const getStausText = () => {
    if (isCustomerDeleted) return 'Deleted';
    if (customer?.disabled) return 'Frozen';
    if (customer?.active) return 'Active';
    return 'Pending Signup';
  };

  const statusClassName = getClassName();
  const statusText = getStausText();

  const freezeAccount = async (action: any) => {
    try {
      setIsLoading(true);
      await customerStore.freezeAccount(id, action);
      getCustomerById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const showAction = !isLoading && !isCustomerDeleted && (!noOrderPresent || isSuperAdmin);

  const isAccountDisabled = customerStore?.customer?.disabled;

  const freezeBtnHandler = () => {
    if (isAccountDisabled) {
      ConfirmModal('Unfreeze Account', 'Do you want to unfreeze this account?', () => freezeAccount({ action: 'UNFREEZE' }), 'Unfreeze now', '#26D65B');
    } else {
      ConfirmModal('Freeze Account', 'Do you want to freeze this account?', () => freezeAccount({ action: 'FREEZE' }), 'Freeze now', '#F1AA1D');
    }
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      { isSuperAdmin && (
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto resp_req_btn" onClick={viewDeleteConfirmModal}>
          Delete Account
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      ) }
      {isSuperAdmin && !customer?.active && (
      <Menu.Item key="1">
        <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={activeBtnHandler}>
          { customer?.is_invitation_send ? 'Reinvite' : 'Activate account'}
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      )}
      <RenderItem isTrue={isSuperAdmin && !isAccountDisabled}>
        <Menu.Item>
          <ButtonIcon
            onClick={freezeBtnHandler}
            text={`${isAccountDisabled ? 'Unfreeze' : 'Freeze'} Account`}
            className="d-flex ml-auto r-bold align-items-center p-0 h-auto resp_req_btn bg-transparent border-0 shadow-none primary"
          />
        </Menu.Item>
      </RenderItem>
      {!noOrderPresent && (
      <Menu.Item key="2">
        <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={goToOrderCreate}>
          Create Order
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper clnt_detail">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <RenderItem isTrue={!isFromCustomerHistory}>
                    <Link to={Routes.customers}>CUSTOMERS</Link>
                  </RenderItem>
                  <RenderItem isTrue={isFromCustomerHistory}>
                    <Link to={Routes.customer_history}>CUSTOMERS HISTORY</Link>
                  </RenderItem>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{customer?.client_code}</Breadcrumb.Item>
              </Breadcrumb>
              <RenderItem isTrue={showAction}>
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </RenderItem>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center flex-wrap">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24 me-sm-3 me-1">
                    {customer?.client_code}
                  </Typography.Title>
                  <StatusLabel
                    className={statusClassName}
                    text={statusText}
                  />
                </div>
                <RenderItem isTrue={!isCustomerDeleted && isSuperAdmin}>
                  <Button className="main_btn fs-16 r-bold mt-2 mt-sm-0" onClick={gotoUpdateCustomer}>
                    Edit information
                  </Button>
                </RenderItem>
              </div>
              <Row gutter={16}>
                <Col lg={8} md={8} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={customer?.profile?.fileUrl}
                      name={(customer?.first_name && customer?.last_name) ? `${customer?.first_name} ${customer?.middle_name || ''} ${customer?.last_name}` : ''}
                      cardName="Customer"
                      id={customer?.client_code}
                      ordersCount={orderCount}
                    />
                  </Card>
                  {(customer?.phone || customer?.email)
                  // phone update
                  && (
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard contactDetails={customer} />
                  </Card>
                  )}
                  <Card bordered={false} className="detail_card_pad">
                    <Address
                      addressInfo={clientAddresses}
                    />
                  </Card>
                </Col>
                <Col lg={16} md={16} xs={24} className="mt-3 mt-md-0">
                  <RenderItem isTrue={customer?.disabled && isSuperAdmin}>
                    <Card className="yellow_bg border-radius mb-3 shadow-none">
                      <div className="d-sm-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center w-100 mt-3 mt-sm-0">
                          <Icon className="icon-16 min-w-16 me-2 white" iconRef="#alert" />
                          <Typography className="w-100">
                            <Paragraph className="secondary mb-0 white fs-16">
                              Account frozen, unfreeze to let the customer use the account
                            </Paragraph>
                          </Typography>
                        </div>
                        <Button
                          className="d-flex align-items-center
                    bg-transparent border-0 p-0 shadow-none white r-bold text-right"
                          onClick={freezeBtnHandler}
                        >
                          Unfreeze
                          <div className="ms-2 d-flex justify-content-center
                      align-items-center text-center p-0 small_icon rounded-circle white_bg yellow"
                          >
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Button>
                      </div>
                    </Card>
                  </RenderItem>
                  <Element to="" name="Orders">
                    <Card className="table_card_title">
                      <div className="d-xxl-flex d-xl-block d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0 justify-content-between">
                        <div className="d-sm-flex align-items-center srch_p_resp">
                          <h4 className="mb-0 table-header r-bold text-truncate" title="Order">Orders</h4>
                          <Search
                            value={keyword}
                            placeholder="Search"
                            titleToolTip="Search Orders"
                            size="small"
                            onChange={handleSearchTextChange}
                            visibility={!noOrderPresent}
                          />
                        </div>
                      </div>
                      <Table
                        onRow={(record) => ({ onClick: () => gotoOrderDetails(record) })}
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        size="small"
                        className="table_wrapper customer_detail_listing ins_table_wrapper"
                        locale={locale}
                        scroll={{ x: '600px' }}
                      />
                    </Card>
                    <Pagination
                      total={total}
                      pageSize={Constant.orderPerPage}
                      currentPage={pageNumber}
                      onChange={pageHandler}
                    />

                  </Element>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>
                { isCustomerDeleted ? 'See the customer details' : 'See and manage the customer details'}
              </Paragraph>
            </Typography>
            <RenderItem isTrue={showAction}>
              <div className="disabled_btn">
                <h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
                <RenderItem isTrue={isSuperAdmin}>
                  <Tooltip title="Delete Account">
                    <Button className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto" onClick={viewDeleteConfirmModal}>
                      Delete Account
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                  </Tooltip>
                </RenderItem>
                <RenderItem isTrue={isSuperAdmin && !isAccountDisabled}>
                  <ButtonIcon
                    onClick={freezeBtnHandler}
                    text={`${isAccountDisabled ? 'Unfreeze' : 'Freeze'} Account`}
                  />
                </RenderItem>
                {isSuperAdmin && !customer?.active && (
                <Tooltip title={customer?.is_invitation_send ? 'Reinvite' : 'Activate Account'}>
                  <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={activeBtnHandler}>
                    { customer?.is_invitation_send ? 'Reinvite' : 'Activate Account'}
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                </Tooltip>
                )}
                {!noOrderPresent && (
                <Tooltip title="Create Order">
                  <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={goToOrderCreate}>
                    Create Order
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                </Tooltip>
                )}
              </div>
            </RenderItem>
          </Sider>
        </Row>
        <ActivateAccount
          isModalVisible={isModalVisible}
          is_invitation_send={customer?.is_invitation_send}
          onOk={sendInvitation}
          onCancel={onCancel}
          email={customer?.email}
          isLoading={isLoading}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('customerStore', 'orderStore', 'authStore')(observer(CustomerDetails));
