/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

class SearchService {
    getSearchResult = async (keyword: string) => {
      try {
        const query = queryString.stringify({ keyword }, { skipNull: true });
        const url = `/search?${query}`;
        return api.get(url);
      } catch (error: any) {
        return Promise.reject(error);
      }
    };
}
export default new SearchService();
