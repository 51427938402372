/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import Constant from '../Global/Constant';
import api from './api';

class NotificationService {
  getNotifications = async (page?: number, limit?: number) => {
    try {
      const query = queryString.stringify({ limit, page }, { skipNull: true });
      const response = await api.get(`/notifications?${query}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  updateNotificationStatus = async (page?: number, limit?: number) => {
    try {
      const query = queryString.stringify({ limit, page }, { skipNull: true });
      const response = await api.put(`/notifications/updateStatus?${query}`, null);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new NotificationService();
