/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Modal,
  Row,
  TimePicker,
  Typography,
  Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';

import classNames from 'classnames';
import moment from 'moment';
import { Icon, Toast } from '../index';

interface IDateTimeModal {
    isModalVisible: boolean
    title: string
    buttonText: string
    redButton: boolean
    dateRule: any
    timeFromRule: any
    timeToRule: any
    disabledDate: any
    handleCancel: () => any
    handleSubmitBtn: (from: number, to: number) => any
    scheduledTime: any
}

function DateTimeModal(props: IDateTimeModal) {
  const {
    isModalVisible, title, buttonText, redButton, disabledDate,
    timeToRule, timeFromRule, dateRule, handleCancel, handleSubmitBtn, scheduledTime,
  } = props;

  const [form] = useForm();

  const onFinishForm = () => {
    const timeFrom: Date = form.getFieldValue('timeFrom');
    const timeTo: Date = form.getFieldValue('timeto');
    const date = form.getFieldValue('date');
    if (timeFrom > timeTo) return Toast.warning('Time From must be less than Time To');
    const epochTo = new Date(timeTo);
    const scheduled_to = new Date(date).setHours(
      epochTo.getHours(), epochTo.getMinutes(), epochTo.getSeconds(), 0,
    );

    const epochFrom = new Date(timeFrom);
    const scheduled_from = new Date(date).setHours(
      epochFrom.getHours(), epochFrom.getMinutes(), epochFrom.getSeconds(), 0,
    );
    if (scheduled_from < Date.now()) return Toast.warning('Time must be greater than current time');
    handleSubmitBtn(scheduled_from, scheduled_to);
    return '';
  };

  const onCancel = () => {
    if (scheduledTime) setTimes();
    else form.resetFields();
    handleCancel();
  };

  const setTimes = () => {
    form.setFieldsValue(
      {
        date: moment(scheduledTime?.scheduled_from),
        timeFrom: moment(scheduledTime?.scheduled_from),
        timeto: moment(scheduledTime?.scheduled_to),
      },
    );
  };

  useEffect(() => {
    if (scheduledTime) setTimes();
    else if (form.getFieldValue('date')) form.resetFields();
  }, [scheduledTime]);

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
      className="cmn_modal_wrapper"
      width={400}
      centered
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Form
        layout="vertical"
        onFinish={onFinishForm}
        form={form}
        validateTrigger={['onBlur', 'onSubmit', 'onChange']}
        scrollToFirstError
      >

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
            <Typography.Text className="r-medium secondary">DATE</Typography.Text>
            <Form.Item name="date" className="mandatory" rules={dateRule}>
              <DatePicker
                className="inp_style date_picker"
                dropdownClassName="date_picker_drp_dwn"
                showToday={false}
                format="DD-MM-YYYY"
                suffixIcon={(
                  <Icon className="icon-16 primary" iconRef="#calendar" />
                )}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="r-medium secondary">TIME FROM</Typography.Text>
            <Form.Item name="timeFrom" className="mandatory" rules={timeFromRule}>
              <TimePicker
                use12Hours
                className="inp_style w-100 h-40 shadow-none time_picker"
                popupClassName="custom_time_picker"
                suffixIcon={(
                  <Icon className="icon-16 primary" iconRef="#clock" />
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="r-medium secondary">TO</Typography.Text>
            <Form.Item name="timeto" className="mandatory" rules={timeToRule}>
              <TimePicker
                use12Hours
                className="inp_style w-100 h-40 shadow-none time_picker"
                popupClassName="custom_time_picker"
                suffixIcon={(
                  <Icon className="icon-16 primary" iconRef="#clock" />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button
            htmlType="submit"
            block
            type="primary"
            className={classNames('main_btn w-auto border-0 r-bold fs-16', { red_bg: redButton })}
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default DateTimeModal;
