/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import queryString from 'query-string';
import classnames from 'classnames';
import Toast from '../../Components/Toast';
import './ModelDetails.less';
import Routes from '../../Global/Routes';
import { Icon } from '../../Components';
import Constant from '../../Global/Constant';
import requestService from '../../Services/requestService';
import modelService from '../../Services/modelService';
import ConfirmModal from '../../Components/ConfirmModal';
import ModalThumbnailImage from '../../Components/ModalThumbnailImage';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;
const { PUBLISHED } = Constant.modelPublishStatus;

function ModelDetails(props: any) {
  const { modelStore, authStore } = props;
  const { id } = useParams<any>();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const isFromModelHistory = queryParams?.deleted;
  const defaultModelTab = queryParams?.tab || PUBLISHED;
  const history = useHistory();
  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const getModelById = async (cid: string) => {
    setIsLoading(true);
    try {
      if (isFromModelHistory) {
        await modelStore.getModelById(cid, true);
      } else {
        await modelStore.getModelById(cid);
      }
    } catch (error:any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const viewDeleteConfirmModal = () => {
    if (modelStore?.modelInUse === 0) {
      if (isSuperAdmin) {
        ConfirmModal('Delete Model', 'Do you want to delete this model?', deleteModel);
      } else {
        ConfirmModal('Request for Model Deletion', 'Do you want to delete this model?', empDeleteRequest, 'Request');
      }
    } else {
      Toast?.warning(`Model is used in ${modelStore?.modelInUse} ${modelStore?.modelInUse > 1 ? 'vehicles.' : 'vehicle.'}`);
    }
  };

  const publishModel = async () => {
    await modelService.publishModel(id)
      .then(() => {
        history.push(`${Routes.models}?tab=${defaultModelTab}`);
      })
      .catch((error:any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const viewPublishConfirnModal = () => {
    ConfirmModal('Publish Model', 'Do you want to publish this model?', publishModel, 'Publish', '#000000');
  };

  const deleteModel = async () => {
    await modelService.deleteModel(id)
      .then(() => {
        history.push(`${Routes.models}?tab=${defaultModelTab}`);
      })
      .catch((error:any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const empDeleteRequest = async () => {
    await requestService.createEmployeeRequest({
      target_id: id, request: Constant.employeeRequest.MODEL_DELETE,
    })
      .then(() => history.push(`${Routes.models}?tab=${defaultModelTab}`))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const goToModelEdit = () => history.push(`${Routes.create_model}/${id}?tab=${defaultModelTab}`);

  useEffect(() => { getModelById(id); }, []);

  const disableDeleteBtn = !!((!isSuperAdmin
    && modelStore?.model?.delete_requested));

  const iscolor = disableDeleteBtn ? '#7777' : '';
  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Tooltip title={disableDeleteBtn ? 'Request for deleting the model is already made' : 'Delete model'}>
          <Button className="d-flex ml-auto r-bold align-items-center delete-btn resp_req_btn p-0" style={{ color: iscolor }} disabled={disableDeleteBtn} onClick={viewDeleteConfirmModal}>
            { isSuperAdmin ? 'Delete Model' : 'Request for delete' }
            {!disableDeleteBtn && (
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
            )}
          </Button>
        </Tooltip>
      </Menu.Item>
      {isSuperAdmin && !modelStore?.model?.published
          && (
            <div>
              <Menu.Divider />
              <Menu.Item key="2">
                <Button className="d-flex ml-auto r-bold align-items-center p-0 primary border-0 bg-transparent shadow-none" onClick={viewPublishConfirnModal}>
                  Publish
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
            </div>
          )}
    </Menu>
  );
  return (
    <Layout.Content className="detail_wrapper fac_detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  { !isFromModelHistory ? <Link to={`${Routes.models}?tab=${defaultModelTab}`}>Models</Link>
                    : <Link to={Routes.model_history}>Model History</Link>}
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {modelStore?.model?.company} {modelStore?.model?.model}
                </Breadcrumb.Item>
              </Breadcrumb>
              { !isFromModelHistory
            && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            )}
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 text-truncate fs-xs-24" title={`${modelStore?.model?.company} ${modelStore?.model?.model}`}>
                  {modelStore?.model?.company} {modelStore?.model?.model}
                </Typography.Title>
                { isSuperAdmin && !isFromModelHistory && (
                <Button className="main_btn ms-3 fs-16 r-bold" onClick={goToModelEdit}>
                  Edit Information
                </Button>
                )}
              </div>
              <Row gutter={16}>
                <Col lg={8} md={8} sm={24} xs={24}>
                  <Card className="mb-3 mb-lg-0">
                    <Typography.Text className="secondary r-medium">Manufacturer</Typography.Text>
                    <Typography.Title className="mt-0 primary text-truncate" level={3} title={modelStore?.model?.company}>{modelStore?.model?.company}</Typography.Title>
                    <Typography.Text className="secondary r-medium">Model</Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold text-truncate" title={modelStore?.model?.model}>{modelStore?.model?.model}</Typography.Paragraph>
                    <Typography.Text className="secondary r-medium">Year</Typography.Text>
                    <Typography.Paragraph className="primary fs-16 r-bold">{modelStore?.model?.manufacture_year}</Typography.Paragraph>
                    <Typography.Text className="secondary r-medium">Vehicle Color</Typography.Text>
                    <Typography.Paragraph className={classnames('primary fs-16 r-bold pb-3', { 'border-bottom': !isFromModelHistory })}><span className="veh_color d-inline-block me-1 rounded-circle align-middle sm" style={{ backgroundColor: modelStore?.model?.color }} /></Typography.Paragraph>
                    { !isFromModelHistory && <Typography.Text className="secondary r-medium">Model in use</Typography.Text>}
                    { !isFromModelHistory && <Typography.Paragraph className="primary fs-16 r-bold mb-0">{modelStore?.modelInUse}</Typography.Paragraph>}
                  </Card>
                </Col>
                <Col lg={16} md={16} sm={24} xs={24}>
                  <Card className="d-flex align-items-center d-page-model-wrap justify-content-center mb-3 model_card_ht">
                    <div className="detail_page_model w-100">
                      <ModalThumbnailImage
                        imageUrl={modelStore?.model?.image?.fileUrl}
                        category={modelStore?.model?.category}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper hidden d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{ isFromModelHistory ? 'See a vehicle model' : 'See and manage a vehicle model'}</Paragraph>
            </Typography>
            { !isFromModelHistory
            && (
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-xl-5 mt-4">Action</h6>
              <Tooltip title={disableDeleteBtn ? 'Request for deleting the model is already made' : 'Delete model'}>
                <Button className="d-flex ml-auto mb-1 r-bold align-items-center delete-btn p-0" style={{ color: iscolor }} disabled={disableDeleteBtn} onClick={viewDeleteConfirmModal}>
                  { isSuperAdmin ? 'Delete Model' : 'Request for delete' }
                  {!disableDeleteBtn && (
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Publish this model">
                {isSuperAdmin && !modelStore?.model?.published
                && (
                <Button className="d-flex ml-auto r-bold align-items-center p-0 primary" onClick={viewPublishConfirnModal}>
                  Publish
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
                )}
              </Tooltip>
            </div>
            )}
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('modelStore', 'authStore')(observer(ModelDetails));
