/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

class ServiceVisit {
  getServiceVisits = (queryParams?: any) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/service-visit?${query}`;
    return api.get(url);
  }

  createServiceVisit = async (data : any) => {
    try {
      const response = await api.post('/service-visit', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateServiceVisit = async (id: string, data : any) => {
    try {
      const response = await api.put(`/service-visit/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getServiceVisitById = async (id: String) => {
    try {
      const response = await api.get(`/service-visit/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateServiceVisitStatus = async (id: string, data : any) => {
    try {
      const response = await api.put(`/service-visit/status/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  sendPaymentLink = async (id: string, data : any) => {
    try {
      const response = await api.post(`/service-visit/send-payment-link/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  downloadInvoice = async (id: any) => {
    try {
      const response = await api.get(`/service-visit/invoice/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new ServiceVisit();
