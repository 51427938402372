/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Table,
  Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Icon, Pagination, SortTh } from '../../Components';
import './AbandonedCart.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function AbandonedCart() {
  const [collapsed, setCollapsed] = useState(false);
  const columns = [
    {
      title: <SortTh title="No" dataIndex="" orderBy={2} order={' '} />,
      dataIndex: 'number',
      width: '10%',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
    },
    {
      title: 'Last Activity',
      dataIndex: 'last_activity',
    },
    {
      title: 'Items On Cart',
      dataIndex: 'items_on_cart',
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
    },
    {
      title: 'Reminder Status',
      dataIndex: 'reminder_status',
    },
  ];
  const data = [
    {
      number: '1',
      customer: 'Jeniffer Rodger',
      last_activity: 'Today',
      items_on_cart: '2',
      total_amount: '100 KD',
      reminder_status: <div className="label_inactive rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Not send">Not send</div>,
    },
    {
      number: '2',
      customer: 'Jeniffer Rodger',
      last_activity: 'Today',
      items_on_cart: '2',
      total_amount: '100 KD',
      reminder_status: <div className="label_inactive rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Not send">Not send</div>,
    },
  ];

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={' '} className="d-flex ml-auto r-bold align-items-center p-0 link_text">
          Abandoned Settings
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography.Title className="m-0 header_main fs-xs-24 border-0">
              Abandoned Cart
            </Typography.Title>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <Table
            className="table_wrapper abandoned_listing"
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={data}
            pagination={false}
            size="small"
            scroll={{ x: '800px' }}
          />
          <Pagination
            total={50}
            pageSize={4}
            currentPage={1}
            onChange={() => {}}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See abandoned items on customers cart</Paragraph>
          </Typography>
          <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Actions</h6>
          <Link to={' '} className="d-flex ml-auto mb-3 r-bold align-items-center p-0 link_text">
            Abandoned Settings
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Link>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default observer(AbandonedCart);
