/* eslint-disable camelcase */
import { Button, Result } from 'antd';
import React from 'react';
import nodataimg from '../../Assets/Images/img-empty.png';

interface NoDataProps{
  subTitle?: String,
  size?: number,
  image?: any,
  displayText?: string,
  shopText?: string,
  heading?: string,
  buttonText?: string,
  buttonHandler?: () => any
}

NoData.defaultProps = {
  size: 200,
  image: nodataimg,
  subTitle: '',
  displayText: '',
  shopText: '',
  heading: '',
  buttonText: '',
  buttonHandler: () => {},
};

function NoData(props: NoDataProps) {
  const {
    subTitle, size, image, displayText, shopText, heading, buttonText, buttonHandler,
  } = props;

  return (
    <Result
      title={<h4 className="r-bold">{heading}</h4>}
      className="w-100"
      icon={<img src={image || nodataimg} alt="no_data_img" className="w-100 m-auto" style={{ maxWidth: size }} />}
      subTitle={shopText || (displayText || `No ${subTitle} found`)}
      extra={buttonText && (
        <div className="d-flex align-items-center justify-content-center">
          <Button onClick={buttonHandler} className="primary_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 d-flex align-items-center mw-80">{buttonText}</Button>
        </div>
      )}
    />
  );
}

export default NoData;
