/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Breadcrumb,
  Button, Card, Col, Dropdown, Form, Input,
  Layout, Menu, Row, Spin, Tooltip, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';
import Routes from '../../Global/Routes';
import fileService from '../../Services/fileService';
import rules from './manageProductCategoryForm.validation';
import Toast from '../../Components/Toast';
import { ConfirmModal, Icon, UploadImage } from '../../Components';
import { ProductCategory } from '../../Global/interface';
import categoryService from '../../Services/categoryService';
import utility from '../../Global/Utility';

import './CreateProductCategory.less';

const {
  Title, Paragraph,
} = Typography;
const { Sider } = Layout;
const { TextArea } = Input;

function CreateProductCategory(props: any) {
  const { categoryStore } = props;
  const { category } = categoryStore;
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isVerticalImageUploading, setIsVerticalImageUploading] = useState<boolean>(false);
  const [isHorizontalImageUploading, setIsHorizontalImageUploading] = useState<boolean>(false);
  const [verticalImage, setVerticalImage] = useState<any>(null);
  const [horizontalImage, setHorizontalImage] = useState<any>(null);

  const history = useHistory();
  const [form] = useForm();
  const { id } = useParams<any>();
  let categoryData: any = [];
  const [collapsed, setCollapsed] = useState(false);

  const createCategory = async (ProductCategoryFormData: ProductCategory) => {
    try {
      const params: ProductCategory = ProductCategoryFormData;
      if (verticalImage) params.vertical_image = verticalImage;
      if (horizontalImage) params.horizontal_image = horizontalImage;
      setIsLoading(true);
      await categoryStore.createCategory(params);
      setIsLoading(false);
      history.push(`${Routes.manage_category}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };
  const fetchProductCategoryData = async (categoryId: string) => {
    setIsLoading(true);
    try {
      await categoryStore.getCategoryById(categoryId);
      categoryData = categoryStore?.category;
      form.setFieldsValue({
        name: categoryData?.name,
        code: categoryData?.code,
        description: categoryData?.description,
        vertical_image: categoryData?.vertical_image,
        horizontal_image: categoryData?.horizontal_image,
      });
      if (categoryData?.vertical_image?.fileUrl) {
        setVerticalImage(categoryData?.vertical_image);
      }
      if (categoryData?.horizontal_image?.fileUrl) {
        setHorizontalImage(categoryData?.horizontal_image);
      }
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const updateCategory = async (ProductCategoryFormData: ProductCategory) => {
    try {
      const params: ProductCategory = ProductCategoryFormData;
      if (verticalImage) params.vertical_image = verticalImage;
      if (horizontalImage) params.horizontal_image = horizontalImage;
      setIsLoading(true);
      await categoryStore.updateCategory(params, id);
      setIsLoading(false);
      Toast.success('Product Category updated successfully');
      history.push(`${Routes.manage_category}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const goToProductCategory = () => {
    history.push(Routes.manage_category);
  };

  const removeVerticalImage = () => {
    setVerticalImage(null);
    form.setFieldsValue({
      vertical_image: '',
    });
  };

  const removeHorizontalImage = () => {
    setHorizontalImage(null);
    form.setFieldsValue({
      horizontal_image: '',
    });
  };

  const handleOnChangeVerticalImage = async (fileInfo: any) => {
    if (fileInfo?.fileList?.length) {
      setIsVerticalImageUploading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setVerticalImage(response?.data[0]);
      setIsVerticalImageUploading(false);
    }
  };
  const handleOnChangeHorizontalImage = async (fileInfo: any) => {
    if (fileInfo?.fileList?.length) {
      setIsHorizontalImageUploading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setHorizontalImage(response?.data[0]);
      setIsHorizontalImageUploading(false);
    }
  };

  const onFinish = (values: any) => {
    if (id) updateCategory(values);
    else createCategory(values);
  };

  useEffect(() => {
    if (id) {
      fetchProductCategoryData(id);
      setIsUpdate(true);
    }
  }, []);

  const deleteCategory = async () => {
    setIsLoading(true);
    try {
      await categoryService.deleteCategory(id);
      history.push(Routes.manage_category);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
    setIsLoading(false);
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Product Category', `Do you want to delete the product category ${category?.name}?`, deleteCategory);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button onClick={viewDeleteConfirmModal} className="d-flex ml-auto r-bold align-items-center delete-btn p-0">
          Delete Category
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="create_wrapper create_fac_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-lg-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between">
              <Breadcrumb className="secondary text-uppercase r-medium text-truncate">
                <Breadcrumb.Item>
                  <Link to={Routes.manage_category}>Manage Category</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item> { isUpdate ? category?.name : 'create product category'}</Breadcrumb.Item>
              </Breadcrumb>
              { isUpdate && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              ) }
            </div>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {isUpdate ? category?.name : 'Create Product Category'}
            </Typography.Title>
            <Card className="create_card">
              <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col xs={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Basic
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="m-0 create_para">
                      Add your product category basic informations
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="PRODUCT CATEGORY NAME" rules={rules.name} name="name" className="mandatory" required={false} normalize={utility.trimSpacesInValues}>
                          <Input placeholder="Enter product category name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="PRODUCT CATEGORY CODE" rules={rules.code} name="code" className="mandatory" required={false}>
                          <Input placeholder="Enter product category code" className="inp_style w-100 inp_num_style pe-5 shadow-none" min={0} maxLength={15} max={999999999999999} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col xs={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Images
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="m-0 create_para">
                      Add your product category images
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <div className="d-flex mt-3 mb-3 align-items-center">
                          <Form.Item name="vertical_image" rules={rules.vertical_image} className="mb-0 mandatory" label="Vertical Image">
                            <div className="position-relative">
                              <Spin spinning={isVerticalImageUploading}>
                                <UploadImage
                                  handleOnChangeImage={handleOnChangeVerticalImage}
                                  image={verticalImage}
                                  removeImage={removeVerticalImage}
                                  isLoading={isVerticalImageUploading}
                                  className="avatar-uploader pro_pic_avatar"
                                  uploadButtonIcon="#upload"
                                />
                              </Spin>
                            </div>
                          </Form.Item>
                          {verticalImage?.fileUrl
                          && <Button className="d-flex ml-auto ms-3 r-bold align-items-center delete-btn p-0" onClick={removeVerticalImage}>Remove</Button>}
                        </div>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <div className="d-flex mt-3 mb-3 align-items-center">
                          <Form.Item name="horizontal_image" rules={rules.horizontal_image} className="mb-0 mandatory" label="Horizontal Image">
                            <div className="position-relative">
                              <Spin spinning={isHorizontalImageUploading}>
                                <UploadImage
                                  handleOnChangeImage={handleOnChangeHorizontalImage}
                                  image={horizontalImage}
                                  removeImage={removeHorizontalImage}
                                  isLoading={isHorizontalImageUploading}
                                  className="avatar-uploader pro_pic_avatar"
                                  uploadButtonIcon="#upload"
                                />
                              </Spin>
                            </div>
                          </Form.Item>
                          {horizontalImage?.fileUrl
                          && <Button className="d-flex ml-auto ms-3 r-bold align-items-center delete-btn p-0" onClick={removeHorizontalImage}>Remove</Button>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >

                  <Col xs={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Description
                    </Typography.Title>
                  </Col>
                  <Col xs={24} lg={20}>
                    <Typography.Text className="m-0 create_para">
                      Add your product category description
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={20} lg={24} xl={24} xxl={20}>
                        <Form.Item
                          label="Add your product category description"
                          name="description"
                          className="mandatory"
                          required
                          normalize={utility.trimSpacesInValues}
                          rules={rules.description}
                        >
                          <TextArea className="inp_style border-0 shadow-none" maxLength={1000} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="d-sm-flex justify-content-between mt-sm-4">
                      <div className="d-sm-flex">
                        <Button disabled={isLoading} htmlType="submit" className="main_btn me-3 fs-16 r-bold mt-3 mt-sm-0">
                          { isUpdate ? 'Update' : 'Create Product Category'}
                        </Button>
                        <Button disabled={isLoading} onClick={goToProductCategory} type="text" className="cancel_btn fs-16 r-bold mt-3 mt-sm-0">
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper mt-3 mt-xl-0 d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{isUpdate ? 'See and manage product category details' : 'Create a Product Category'}</Paragraph>
            </Typography>
            { isUpdate && (
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-xl-5">Action</h6>
              <Tooltip title="Delete Category">
                <Button disabled={isLoading} onClick={viewDeleteConfirmModal} className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0">
                  Delete Category
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            ) }
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('categoryStore')(observer(CreateProductCategory));
