/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { ModelQueryParams } from '../Global/interface';
import api from './api';

class ModelService {
  getModels = (queryParams : ModelQueryParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/models?${query}`;
    return api.get(url);
  }

  fetchModelVariants = async (queryParams : ModelQueryParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/models/compactable-products?${query}`;
    return api.get(url);
  }

 getModelById = async (id: string, deleted?: boolean) => {
   try {
     const query = queryString.stringify({ deleted }, { skipNull: true });
     const response = await api.get(`/models/${id}?${query}`);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

  createModel = async (model : any) => {
    try {
      const response = await api.post('/models', model);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateModel = async (id: string, model : any) => {
    try {
      const response = await api.put(`/models/${id}`, model);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateModelEntity = async (id: string, data : any) => {
    try {
      const response = await api.put(`/models/entity/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  deleteModel = async (id: string) => {
    try {
      const response = await api.delete(`/models/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  publishModel = async (id: string) => {
    try {
      const response = await api.put(`/models/publish/${id}`, {});
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  deleteModelFromVehicleDb = async (data: any) => {
    try {
      const response = await api.put('/models/delete', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  linkProductHandler = async (data : any) => {
    try {
      const response = await api.put('/models/compatible-products', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new ModelService();
