/* eslint-disable camelcase */
import React from 'react';

interface StatusProps{
    status: string,
    statusClassName: any
}

function Status(props: StatusProps) {
  const { status, statusClassName } = props;
  return (
    <div
      className={`${statusClassName} rounded-pill ps-2 pe-2 w-xl-100 d-inline-block ellipse text-center`}
      title={status}
    >
      {status}
    </div>
  );
}

export default Status;
