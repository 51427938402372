/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, InputNumber, Modal, Radio, Tooltip, Typography,
} from 'antd';

import {
  Icon, RenderItem, Toast,
} from '../../Components';
import Utility from '../../Global/Utility';
import { Terms } from '../../Global/interface';
import Constant from '../../Global/Constant';

interface IPlanDurationModal {
    visibility: boolean
    terms: any,
    selectedTerm: Terms,
    defaultTerm: Terms | null,
    onCancel: (data: any) => any,
    onOk: () => any,
    termChangeHandler: (term: Terms, finalPrice: number, isCustom: boolean) => any,
    price: number,
    isCustomPrice: boolean
}

function PlanDurationModal(props:IPlanDurationModal) {
  const {
    visibility, terms, selectedTerm, defaultTerm, price, isCustomPrice, onCancel,
    onOk, termChangeHandler,
  } = props;

  const [termDetails, setTermDetails] = useState<any>({});
  const [defaultPriceChecked, setDefaultPriceChecked] = useState<boolean>(false);
  const [finalPrice, setFinalPrice] = useState<any>();

  const selectChangeHandler = (event: any) => {
    const termDetail = terms?.find((term: Terms) => term?.duration === event?.target?.value);
    setTermDetails(termDetail);
  };

  useEffect(() => {
    setDefaultPriceChecked(!isCustomPrice);
  }, [isCustomPrice]);

  useEffect(() => {
    if (defaultTerm) { setTermDetails(defaultTerm); }
  }, [defaultTerm]);

  const okHandler = () => {
    if (!finalPrice) {
      Toast.error('Please add a valid price.');
    } else {
      termChangeHandler(termDetails, finalPrice, !defaultPriceChecked);
      onOk();
    }
  };

  useEffect(() => {
    if (price) {
      setFinalPrice(price);
    }
  }, [price]);

  useEffect(() => {
    if (termDetails) {
      setPriceBasedCheckbox(defaultPriceChecked);
    }
  }, [termDetails, defaultPriceChecked]);

  const setPriceBasedCheckbox = (checked: boolean) => {
    if (checked) {
      // get default price from the system if checked
      const termDetail = terms?.find((term: Terms) => term?._id === termDetails?._id);
      const termPrice = termDetail?.price;
      setFinalPrice(termPrice);
    } else if (defaultTerm?.duration === termDetails?.duration) {
      // selected term is the actual term then set actual price
      setFinalPrice(price);
    } else {
      // selected term is not the actual term
      // setFinalPrice(termDetails?.price); if we want to populate the term price for custom price
      setFinalPrice(null);
    }
  };

  useEffect(() => {
    if (terms) {
      setPriceBasedCheckbox(defaultPriceChecked);
    }
  }, [terms]);

  const checkBoxHandler = (checked: boolean) => {
    setDefaultPriceChecked(checked);
    setPriceBasedCheckbox(checked);
  };

  return (
    <Modal
      visible={visibility}
      title="Change Duration and Price"
      onCancel={() => {
        onCancel(selectedTerm);
      }}
      onOk={okHandler}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      destroyOnClose
      footer={null}
      width={465}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={onCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
    )}
    >
      <Typography.Title level={5} className="fs-14 secondary r-medium text-uppercase">CHOOSE TERM FOR RENEWAL</Typography.Title>
      <Radio.Group
        buttonStyle="solid"
        className="d-flex flex-wrap"
        onChange={selectChangeHandler}
        defaultValue={selectedTerm?.duration || defaultTerm?.duration}
      >
        <RenderItem isTrue={terms?.length}>
          {
            terms?.sort(
              (a: any, b: any) => a?.duration - b?.duration,
            )?.map((term: Terms) => (
              <Radio.Button value={term?.duration} className="term_duration me-3 exo_fnt d-flex align-items-center px-2 mt-3">
                {Utility.reverseDuration(term?.duration, term?.duration_type)}
                <sub className="r-medium secondary fs-16 roboto position-absolute">{Constant?.durationTypeName.get(term?.duration_type)}</sub>
              </Radio.Button>
            ))
          }
        </RenderItem>
      </Radio.Group>

      <div>
        <Typography.Title level={5} className="fs-14 secondary mt-3 r-medium text-uppercase">PRICE</Typography.Title>

        <InputNumber placeholder="Enter Custom Price" disabled={defaultPriceChecked} value={finalPrice} onChange={(value) => setFinalPrice(value)} min={0} className="inp_style w-50 inp_num_style setting_delivery_amount pe-5 shadow-none mt-2" maxLength={15} />
        <Tooltip
          title="Check to choose default plan price added in the plan setup, or
        un-check to add a custom amount."
          placement="left"
        >
          <Checkbox
            className="chkbox_style primary r-regular fs-16 mt-3"
            onChange={(e) => { checkBoxHandler(e?.target?.checked); }}
            checked={defaultPriceChecked}
          >Check to select default price
          </Checkbox>
        </Tooltip>
      </div>

      <div className="d-flex mt-4 ps-lg-0 ps-4">
        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={okHandler}>
          Change
        </Button>
        <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default PlanDurationModal;
