interface RenderItemProps{
    isTrue?: boolean
    children: any
}

RenderItem.defaultProps = {
  isTrue: false,
};

function RenderItem(props: RenderItemProps) {
  const { children, isTrue } = props;
  return isTrue ? children : null;
}

export default RenderItem;
