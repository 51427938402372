/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable */
import React from 'react';
import {
  Checkbox, Divider, Form, Tooltip, Typography,
} from 'antd';
import { Icon } from '../index';
import Constant from '../../Global/Constant';

interface SelectServiceBoxProps {
  service: string
  serviceChecked: boolean
  freeChecked: boolean
  handleServiceCheck: any
  handleFreeCheck: any
  price: number
}

function SelectServiceBox(props: SelectServiceBoxProps) {
  const {
    service, serviceChecked, freeChecked, handleServiceCheck, price, handleFreeCheck
  } = props;

  const handleServiceCheckBox = (isChecked: any, serviceKey: string) => {
    handleServiceCheck(serviceKey, isChecked);
  };

  const handleFreeCheckBox = (isChecked: any, serviceKey: string) => {
    handleFreeCheck(serviceKey, isChecked);
  };
  

  return (
    <div className="p-2 mb-3 off-white-bg border-radius" style={{ minHeight: '125px'}}>
      <div className="d-flex justify-content-between">
        <Form.Item name={service} className="mb-0">
          <Checkbox
            onChange={(e: any) => handleServiceCheckBox(e?.target?.checked, service)}
            value={serviceChecked}
            checked={serviceChecked}
            className="chkbox_style r-bold checkbox_disable_after"
          >{Constant?.SERVICES?.get(service)}
          </Checkbox>
        </Form.Item>
        <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
          <div className="icn_32 white_bg primary rounded-circle d-flex align-items-center justify-content-center">
            <Icon className="icon-14" iconRef={Constant?.statusImage?.get(service) as string} />
          </div>
        </div>
      </div>
      <Divider className="border-top mt-2 mb-2" />
      <div className="d-flex justify-content-between align-items-center">
        <Form.Item name={`${service}_free`} className="mb-0">
          <Checkbox
            onChange={(e: any) => handleFreeCheckBox(e?.target?.checked, service)}
            disabled={price <= 0}
            checked={freeChecked}
            className="chkbox_style checkbox_disable_after"
          >Free Service
          </Checkbox>
        </Form.Item>
        <Typography.Text className="d-block r-bold w-33">
        {price <= 0 || freeChecked ? '' : <Tooltip title={price}><div className="d-flex justify-content-end"><span className="text-truncate d-inline-block me-1">{price}</span>{'KD'}</div></Tooltip>}
        </Typography.Text>
      </div>
    </div>
  );
}

export default SelectServiceBox;
