/* eslint-disable */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Area } from '@ant-design/plots';

interface IOrderChart {
  orderDetails?: any
}

const orderData: any = [
  {
    year: 'Jan',
    Order: 0
  },
  {
    year: 'Feb',
    Order: 0
  },
  {
    year: 'Mar',
    Order: 0
  },
  {
    year: 'Apr',
    Order: 0
  },
  {
    year: 'May',
    Order: 0
  },
  {
    year: 'Jun',
    Order: 0
  },
  {
    year: 'Jul',
    Order: 0
  },
  {
    year: 'Aug',
    Order: 0
  },
  {
    year: 'Sep',
    Order: 0
  },
  {
    year: 'Oct',
    Order: 0
  },
  {
    year: 'Nov',
    Order: 0
  },
  {
    year: 'Dec',
    Order: 0
  }
]

function OrderChart(props: IOrderChart)  {
  const { orderDetails } = props;

  const [data, setData] = useState(orderData);

  const getOrderCount = (month: number) => {
    const monthData =  orderDetails?.find((item: any) => item?.month === month)
    return monthData?.orders || 0
  }

  useEffect(() => {
    // let orderData;
    if(orderDetails?.length){
    const updatedOrderData = [
      {
        year: 'Jan',
        Order: getOrderCount(1)
      },
      {
        year: 'Feb',
        Order: getOrderCount(2)
      },
      {
        year: 'Mar',
        Order: getOrderCount(3)
      },
      {
        year: 'Apr',
        Order: getOrderCount(4)
      },
      {
        year: 'May',
        Order: getOrderCount(5)
      },
      {
        year: 'Jun',
        Order: getOrderCount(6)
      },
      {
        year: 'Jul',
        Order: getOrderCount(7)
      },
      {
        year: 'Aug',
        Order: getOrderCount(8)
      },
      {
        year: 'Sep',
        Order: getOrderCount(9)
      },
      {
        year: 'Oct',
        Order: getOrderCount(10)
      },
      {
        year: 'Nov',
        Order: getOrderCount(11)
      },
      {
        year: 'Dec',
        Order: getOrderCount(12)
      }
    ]
   setData(updatedOrderData)
  } else {
    setData(orderData)
  }
  }, [orderDetails])

  const config: any = {
    data,
    xField: 'year',
    yField: 'Order',
    seriesField: 'country',
    color: ['#cccc', '#cb302d', '#e3ca8c'],
    areaStyle: {
      fillOpacity: 0.7,
      // fill: 'red',
      // stroke: 'black',
    },
    // tooltip: {
    //   formatter: (datas: any) => ({ name: datas.year, value: datas.Order }),
    //   showTitle: false,
    // },
    meta: {
      year: {
        nice: true,
        range: [0, 1],
      },
    },
    smooth: true,
    yAxis: {
      label: {
        formatter: (value: any) => value,
      },
    },
  };
  return <Area {...config} />;
};
export default OrderChart;
