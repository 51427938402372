/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

class PlanService {
 createPlan = async (data: any) => api.post('/contract-plans/', data);

 updatePlan = async (id: string|undefined, data: any) => api.put(`/contract-plans/${id}`, data);

 getPlanById = async (id: string|undefined) => api.get(`/contract-plans/term/${id}`);

 deletePlan = async (id: string|undefined) => api.delete(`/contract-plans/${id}`);

 getPlanCount = (id: string, queryParams?: any) => {
   const query = queryString.stringify(queryParams, { skipNull: true });
   const url = `/contract-plans/use/${id}?${query}`;
   return api.get(url);
 }
}

export default new PlanService();
