/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Select,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { Icon } from '../../Components';
import computer from '../../Assets/Images/computer.svg';
import tab from '../../Assets/Images/tablet.svg';
import mobile from '../../Assets/Images/phone.svg';
import './Analytics.less';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
const { Option } = Select;

function Analytics() {
  const [collapsed, setCollapsed] = useState(false);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 link_text">
          Download Revenue Report
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography.Title className="m-0 header_main d-flex align-items-center fs-xs-24 border-bottom-0">
              Analytics
            </Typography.Title>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card>
                <Typography.Title level={3} className="r-bold">Total Revenue</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Typography.Title className="text-uppercase r-medium secondary" level={5}>THE VAULT</Typography.Title>
                    <Typography.Title className="r-bold green mt-0 mb-3 text-ellipsis fs-xs-24" level={1}>875 KD</Typography.Title>
                    <Icon className="icon-80 icon-xs-40 off-white" iconRef="#increase" />
                    <Typography.Paragraph className="secondary mt-4 mb-0">8 % increase this month</Typography.Paragraph>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Typography.Title className="text-uppercase r-medium secondary" level={5}>SHOP VMS</Typography.Title>
                    <Typography.Title className="r-bold red mt-0 mb-3 text-ellipsis fs-xs-24" level={1}>985 KD</Typography.Title>
                    <Icon className="icon-80 icon-xs-40 off-white rotate-180" iconRef="#increase" />
                    <Typography.Paragraph className="secondary mt-4 mb-0">8 % increase this month</Typography.Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card>
                <Typography.Title level={3} className="r-bold">VMS This Month</Typography.Title>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Typography.Title className="text-uppercase r-medium secondary" level={5}>THE VAULT</Typography.Title>
                    <Typography.Title className="r-bold green mt-0 mb-3 text-ellipsis fs-xs-24" level={1}>80 KD</Typography.Title>
                    <Icon className="icon-80 icon-xs-40 off-white" iconRef="#increase" />
                    <Typography.Paragraph className="secondary mt-4 mb-0">8 % increase this month</Typography.Paragraph>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Typography.Title className="text-uppercase r-medium secondary" level={5}>SHOP VMS</Typography.Title>
                    <Typography.Title className="r-bold green mt-0 mb-3 text-ellipsis fs-xs-24" level={1}>12 KD</Typography.Title>
                    <Icon className="icon-80 icon-xs-40 off-white" iconRef="#increase" />
                    <Typography.Paragraph className="secondary mt-4 mb-0">8 % increase this month</Typography.Paragraph>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">Page of Interest</Typography.Title>
                  <Row gutter={[16, 16]} className="w-50 w-xs-100 justify-content-end">
                    <Col lg={19} md={22} sm={15} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                <div className="capacity-bar page_of_int_analytics w-100 d-flex my-3">
                  <div className="product_sz green_bg" />
                  <div className="product_sz purple_bg" />
                  <div className="product_sz blue_bg" />
                </div>
                <Typography>
                  <Title level={5} className="mb-0">60%</Title>
                  <Paragraph className="secondary fs-16 mb-0">Landing page</Paragraph>
                </Typography>
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">Users Devices</Typography.Title>
                  <Row gutter={[16, 16]} className="w-50 w-xs-100 justify-content-end">
                    <Col lg={19} md={22} sm={15} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                <Row gutter={[12, 12]}>
                  <Col lg={8} md={8} sm={8} xs={12}>
                    <div className="device_img_wrap off-white-bg border-radius d-flex justify-content-center align-items-center my-3">
                      <img src={computer} alt="computer_image" />
                    </div>
                    <Typography className="text-center">
                      <Title level={5} className="mb-0">60%</Title>
                      <Paragraph className="secondary fs-16 mb-0 text-ellipsis" title="Use computer">Use computer</Paragraph>
                    </Typography>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={12}>
                    <div className="device_img_wrap off-white-bg border-radius d-flex justify-content-center align-items-center my-3">
                      <img src={tab} alt="computer_image" />
                    </div>
                    <Typography className="text-center">
                      <Title level={5} className="mb-0">15%</Title>
                      <Paragraph className="secondary fs-16 mb-0" title="Use tablet">Use tablet</Paragraph>
                    </Typography>
                  </Col>
                  <Col lg={8} md={8} sm={8} xs={12}>
                    <div className="device_img_wrap off-white-bg border-radius d-flex justify-content-center align-items-center my-3">
                      <img src={mobile} alt="computer_image" />
                    </div>
                    <Typography className="text-center">
                      <Title level={5} className="mb-0">25%</Title>
                      <Paragraph className="secondary fs-16 mb-0" title="Use mobile">Use mobile</Paragraph>
                    </Typography>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">Revenue Trend</Typography.Title>
                  <Row gutter={[16, 16]} className="w-75 w-xs-100 justify-content-end">
                    <Col lg={6} md={8} sm={10} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                    <Col lg={6} md={8} sm={10} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="Yearly"
                      >
                        <Option key={1} value="Yearly">Yearly</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                {/* chart
                <div></div> */}
                <div className="d-flex justify-content-end mt-3">
                  <div className="d-flex align-items-center">
                    <Typography.Title level={3} className="mb-0 me-3">2020</Typography.Title>
                    <div>
                      <Button className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg me-2 primary">
                        <Icon className="icon-12" iconRef="#arrow_left" />
                      </Button>
                      <Button className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg primary">
                        <Icon className="icon-12" iconRef="#arrow_right" />
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">Time of Interaction</Typography.Title>
                  <Row gutter={[16, 16]} className="w-75 w-xs-100 justify-content-end">
                    <Col lg={6} md={8} sm={10} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                {/* Order chart
                <div></div> */}
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">User Locations</Typography.Title>
                  <Row gutter={[16, 16]} className="w-50 w-xs-100 justify-content-end">
                    <Col lg={19} md={22} sm={15} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                {/* Order chart
                <div></div> */}
              </Card>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold mb-sm-0">User Browsers</Typography.Title>
                  <Row gutter={[16, 16]} className="w-50 w-xs-100 justify-content-end">
                    <Col lg={19} md={22} sm={15} xs={24}>
                      <Select
                        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                        className="slct_style w-100"
                        dropdownClassName="slct_drp_dwn border-radius"
                        defaultValue="The Vault"
                      >
                        <Option key={1} value="The Vault">The Vault</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
                {/* Order chart
                <div></div> */}
              </Card>
            </Col>
          </Row>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the performance of VMS</Paragraph>
          </Typography>
          <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Actions</h6>
          <Button className="d-flex ml-auto r-bold align-items-center p-0 link_text border-0 shadow-none">
            <span className="text-start w-75 break-space">Download Revenue Report</span>
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default observer(Analytics);
