/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card, Col, DatePicker, Divider, Form, Input, Layout,
  Radio,
  Row, Select, Spin, Typography,
} from 'antd';
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import PhoneInput from 'react-phone-number-input';
import Routes from '../../Global/Routes';

import utility from '../../Global/Utility';

import { AddressDetails, Icon } from '../../Components';
import Toast from '../../Components/Toast';
import AddressSelection from './AddressSelection';
import AddAddress from './AddAddress';
import rules from './create-customer-form.validation';

import 'react-phone-number-input/style.css';
import { ICustomerStore } from '../../Store/customerStore';
import { MasterStoreProps } from '../../Store/masterStore';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface ICreateCustomer{
  customerStore: ICustomerStore
  masterStore: MasterStoreProps
}

function CreateCustomer(props: ICreateCustomer) {
  const { customerStore, masterStore } = props;
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCountryName, setSelectedCountryName] = useState<any>(null);
  const [selectedStateName, setSelectedStateName] = useState<any>(null);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<any>(0);
  const [isAddressUpdate, setIsAddressUpdate] = useState<boolean>(false);
  const [editAddressIndex, setEditAddressIndex] = useState<any>(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [phone, setPhone] = useState<any>();
  const [customerAddresses, setCustomerAddresses] = useState<any>([]);
  const [form] = useForm();
  const { Option } = Select;

  const countryCodes = masterStore?.contryCodes;
  const countryData = masterStore?.countries;

  const history = useHistory();
  const { id } = useParams<any>();
  const showAddressModal = () => {
    setIsAddressUpdate(false);
    setIsAddressModalVisible(true);
  };

  const closeAddressModal = () => {
    setIsAddressUpdate(false);
    setIsAddressModalVisible(false);
  };

  const gotoCustomers = () => {
    if (isUpdate) history.push(`${Routes.customer_details}/${id}`);
    else history.push(Routes.customers);
  };

  const populateCustomer = async () => {
    try {
      setIsLoading(true);
      await customerStore?.getCustomerById(id);
      setFormData();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const setFormData = () => {
    form.setFieldsValue({
      first_name: customerStore?.customer?.first_name,
      middle_name: customerStore?.customer?.middle_name,
      last_name: customerStore?.customer?.last_name,
      gender: customerStore?.customer?.gender,
      email: customerStore?.customer?.email,
      phone: customerStore?.customer?.phone,
      dob: customerStore?.customer?.dob && moment(customerStore?.customer?.dob),
    });
    if (customerStore?.customer?.address) {
      setCustomerAddresses([customerStore?.customer?.address,
        ...customerStore?.customer?.client_addresses]);
    }
    setSelectedCountryName(customerStore?.customer?.address?.country);
    setSelectedStateName(customerStore?.customer?.address?.state);
  };

  const disabledDateForDob = (current: Moment): boolean => current && current > moment();
  const disableEmail = isUpdate && !!customerStore?.customer?.active;

  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };
  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  const getCountryData = async () => {
    await masterStore?.getCountryData()
      .catch((error:any) => { Toast.error(error?.response?.data?.error?.message); });
  };

  const makeAddressDefault = (address: any, index: number) => setSelectedAddressIndex(index);

  useEffect(() => {
    if (id) {
      populateCustomer();
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [id]);

  const createCustomer = async (customer: any) => {
    try {
      const params: any = customer;
      setIsLoading(true);
      params.dob = customer?.dob ? moment(customer?.dob)?.format('YYYY-MM-DD') : null;
      const response = await customerStore.createCustomer(params);
      setIsLoading(false);
      history.push(`${Routes.customer_details}/${response?.client?._id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateCustomer = async (customer: any) => {
    try {
      const params: any = customer;
      setIsLoading(true);
      const addresses = [...customerAddresses];
      const defaultAddress: any = addresses.splice(selectedAddressIndex, 1)[0];
      params.country = defaultAddress?.country;
      params.state = defaultAddress?.state;
      params.city = defaultAddress?.city;
      params.zip_code = defaultAddress?.zip_code;
      params.address_line_1 = defaultAddress?.address_line_1;
      params.address_line_2 = defaultAddress?.address_line_2;

      params.client_addresses = addresses;
      params.dob = customer?.dob ? moment(customer?.dob)?.format('YYYY-MM-DD') : null;
      await customerStore.updateCustomer(id, params);
      setIsLoading(false);
      history.push(`${Routes.customer_details}/${id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const addAddress = (values: any) => {
    const address = {
      country: values?.country,
      state: values?.state,
      city: values?.city,
      zip_code: values?.zip_code,
      address_line_1: values?.address_line_1,
      address_line_2: values?.address_line_2,
    };
    let addresses;
    if (isAddressUpdate) {
      customerAddresses[editAddressIndex] = address;
      addresses = customerAddresses;
    } else {
      addresses = [...customerAddresses, address];
    }

    setCustomerAddresses(addresses);
    closeAddressModal();
  };

  const updateAddress = (index: number) => {
    setIsAddressUpdate(true);
    setEditAddressIndex(index);
    setIsAddressModalVisible(true);
  };

  const deleteAddress = (index: number) => {
    const address = [...customerAddresses];
    address.splice(index, 1);
    if (index < selectedAddressIndex) {
      setSelectedAddressIndex(selectedAddressIndex - 1);
    }
    setCustomerAddresses(address);
  };

  const onFinish = (customer: any) => {
    if (isUpdate) updateCustomer(customer);
    else createCustomer(customer);
  };

  useEffect(() => {
    if (!masterStore?.countries) {
      getCountryData();
    }
  }, []);

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-lg-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between">
              <Breadcrumb className="secondary text-uppercase r-medium">
                <Breadcrumb.Item>
                  <Link to={Routes.customers}>CUSTOMERS</Link>
                </Breadcrumb.Item>
                {isUpdate && (
                <Breadcrumb.Item>
                  <Link to={`${Routes.customer_details}/${id}`}>{customerStore?.customer?.client_code}</Link>
                </Breadcrumb.Item>
                )}
                <Breadcrumb.Item>{isUpdate ? 'EDIT' : 'CREATE'} CUSTOMER</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <Divider className="mt-3 mb-3 border-top" />
            <Typography.Title className="m-0 header_main fs-xs-24 mb-4">
              {isUpdate ? 'Edit' : 'Create'} Customer
            </Typography.Title>
            <Card className="create_card">
              <Form initialValues={{ gender: 'Male' }} layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col sm={24}>
                    <Typography.Title level={3} className="mb-4">Basic information</Typography.Title>
                  </Col>
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Profile
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="m-0 create_para">
                      {isUpdate ? 'Edit customer basic informations' : 'Enter customer basic informations'}
                    </Typography.Text>
                    <Row gutter={16} className="mt-3">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="FIRST NAME" name="first_name" className="mandatory" required={false} normalize={utility.trimSpacesInValues} rules={rules.name}>
                          <Input placeholder="Enter first name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="MIDDLE NAME" name="middle_name" normalize={utility.trimSpacesInValues} required={false} rules={rules.middle_name}>
                          <Input placeholder="Enter middle name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="LAST NAME" name="last_name" className="mandatory" required={false} normalize={utility.trimSpacesInValues} rules={rules.last_name}>
                          <Input placeholder="Enter last name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="DATE OF BIRTH" name="dob">
                          <DatePicker
                            className="inp_style date_picker"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose date"
                            showToday={false}
                            format="DD-MM-YYYY"
                            disabledDate={disabledDateForDob}
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item name="gender" label="GENDER">
                          <Radio.Group className="cust_radio_btn" defaultValue="Male">
                            <Radio className="radio_inp" value="Male">Male</Radio>
                            <Radio className="radio_inp" value="Female">Female</Radio>
                            <Radio className="radio_inp" value="Other">Other</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="EMAIL" name="email" normalize={utility.trimSpacesInValues} rules={rules.email}>
                          <Input placeholder="Enter email" type="text" className="inp_style" disabled={disableEmail} maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="PHONE NUMBER" name="phone" rules={rules.phone} normalize={utility.trimSpacesInValues}>
                          {countryCodes
                && (
                <PhoneInput
                  defaultCountry="KW"
                  international
                  countries={countryCodes}
                  className="phn_inp_style"
                  countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  maxLength={16}
                />
                )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col xs={24} lg={4}>
                    <Typography.Title className="m-0" level={5}>
                      Address
                    </Typography.Title>
                  </Col>
                  <Col xs={24} lg={20}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Manage customers billing address here
                      </Typography.Text>
                      {isUpdate
                      && (
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={showAddressModal}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                      )}
                    </div>
                    {!isUpdate
                      && (
                      <Row gutter={16} className="mt-3">
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="country" className="mandatory" name="country" rules={rules.country}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose country"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                              onChange={countryChanged}
                            >
                              {countryData && Array.from(countryData?.keys())
                                ?.map((country:any) => <Option value={country}>{country}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="state" className="mandatory" name="state" rules={rules.state}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose State"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                              onChange={stateChanged}
                            >
                              {selectedCountryName
                            && countryData?.get(selectedCountryName)?.keys()
                            && Array?.from(countryData?.get(selectedCountryName)?.keys())
                              ?.map((state:any) => <Option value={state}>{state}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="city" className="mandatory" name="city" rules={rules.city}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose City"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                            >
                              {selectedStateName
                            && countryData?.get(selectedCountryName)?.keys()
                            && countryData.get(selectedCountryName).get(selectedStateName)
                              ?.cities?.map((state:any) => <Option value={state}>{state}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="POST CODE" name="zip_code" normalize={utility.trimSpacesInValues} rules={rules.zip_code}>
                            <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="ADDRESS LINE 1" name="address_line_1" normalize={utility.trimSpacesInValues} rules={rules.addressLine1}>
                            <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="ADDRESS LINE 2" name="address_line_2" normalize={utility.trimSpacesInValues} rules={rules.addressLine2}>
                            <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
                          </Form.Item>
                        </Col>
                      </Row>
                      )}
                    {isUpdate
                      && (
                      <Row gutter={16} className="mt-3">
                        {customerAddresses?.length > 0
                              && customerAddresses?.map((address: any, index: number) => (
                                <Col xxl={8} xl={12} lg={12} md={12} sm={12} xs={24}>
                                  <div className="border-color border-radius p-3 mb-3" style={{ minHeight: '180px' }}>
                                    <Row gutter={[16, 16]} className="d-flex justify-content-between" style={{ minHeight: '150px' }}>
                                      <Col xxl={14} sm={12} xs={24}>
                                        <AddressDetails
                                          addressInfo={address}
                                        />
                                      </Col>
                                      <Col xxl={10} sm={12} xs={24}>
                                        <div className="d-flex justify-content-sm-end">
                                          <Button onClick={() => updateAddress(index)} className="size-40 p-0 border-radius secondary off-white-bg d-flex align-items-center justify-content-center border-0">
                                            <Icon className="icon-14" iconRef="#edit" />
                                          </Button>
                                          {selectedAddressIndex !== index
                                          && (
                                          <Button onClick={() => deleteAddress(index)} className="item_delete size-40 p-0 border-radius red d-flex align-items-center justify-content-center border-0 ms-2">
                                            <Icon className="icon-14" iconRef="#delete" />
                                          </Button>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                    <AddressSelection
                                      index={selectedAddressIndex}
                                      localIndex={index}
                                      address={address}
                                      chooseAddress={makeAddressDefault}
                                    />
                                  </div>
                                </Col>
                              ))}
                      </Row>
                      )}
                    <div className="d-sm-flex justify-content-between mt-sm-4">
                      <div className="d-sm-flex">
                        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                          {isUpdate ? 'Update' : 'Create Account'}
                        </Button>
                        <Button type="text" className="cancel_btn fs-16 r-bold" onClick={gotoCustomers}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>

          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{isUpdate ? 'Edit' : 'Create'} the customer</Paragraph>
            </Typography>
          </Sider>
          <AddAddress
            isVisible={isAddressModalVisible}
            countryData={countryData}
            handleCancel={closeAddressModal}
            handleOk={addAddress}
            isAddressUpdate={isAddressUpdate}
            address={customerAddresses[editAddressIndex]}
          />
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('customerStore', 'masterStore')(observer(CreateCustomer));
