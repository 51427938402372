/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Col, Row, Typography, Select, Button, Modal, Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  Product, ProductCategory, Variants,
} from '../../Global/interface';
import { Icon } from '../../Components';
import Utility from '../../Global/Utility';
import rules from './AddProduct.validation';

interface IAddProductModal {
    isModalVisible: boolean,
    categories: Array<ProductCategory>,
    products: Array<Product>,
    variants: Array<Variants>
    productDetails: any
    handleCancel: () => void,
    categoryChange: (value:string) => void,
    productChange: (value:string) => void
    createOrderList: (value: any) => void
}

function AddProductModal(props: IAddProductModal) {
  const {
    isModalVisible, categories, products, variants, productDetails,
    handleCancel, categoryChange, productChange,
    createOrderList,
  } = props;
  const { Option } = Select;
  const [form] = useForm();

  const onFinish = (values: any) => {
    const product = products?.find((item) => item?._id === values?.product);
    const variant = variants?.find((item) => item?._id === values?.variant);
    const data = {
      category: categories?.find((item) => item?._id === values?.category)?.name,
      product: product?.name,
      variant,
      image: variant?.images?.length ? variant?.images[0] : product?.main_image,
      quantity: 0,
    };
    createOrderList(data);
    form.resetFields();
  };

  const categoryChangeHandler = (category: string) => {
    categoryChange(category);
    form.setFieldsValue({ product: null, variant: null });
  };

  const productChangeHandler = (product: string) => {
    productChange(product);
    form.setFieldsValue({ variant: null });
  };

  const onCancel = () => {
    form.resetFields();
    handleCancel();
  };

  const variantFilter = (input: any, option: any) => (
    option?.children[0]?.props?.children[2]?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
    || `${option?.children[1]?.props?.children[0]}`?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0);

  const productFilter = (
    input: any, option: any,
  ) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;

  return (
    <Modal
      title="Choose Product"
      visible={isModalVisible}
      onCancel={onCancel}
      footer={null}
      className="cmn_modal_wrapper"
      width={800}
      centered
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="secondary text-uppercase r-medium d-block mb-2">CATEGORY</Typography.Text>
            <Form.Item name="category" className="mandatory" normalize={Utility.trimSpacesInValues} rules={rules.category}>
              <Select
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose category"
                className="slct_style w-100"
                dropdownClassName="slct_drp_dwn border-radius"
                onChange={categoryChangeHandler}
              >
                {categories?.map((category: ProductCategory) => (
                  <Option key={category?._id} value={category?._id}>
                    {category?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="secondary text-uppercase r-medium d-block mb-2">PRODUCT</Typography.Text>
            <Form.Item name="product" className="mandatory" normalize={Utility.trimSpacesInValues} rules={rules.product}>
              <Select
                showSearch
                filterOption={productFilter}
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose product"
                className="slct_style w-100"
                dropdownClassName="slct_drp_dwn border-radius"
                onChange={productChangeHandler}
              >
                {form.getFieldValue('category') && products?.map((item: Product) => (
                  <Option key={item?._id} value={item?._id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="secondary text-uppercase r-medium d-block mb-2">VARIANT</Typography.Text>
            <Form.Item name="variant" className="mandatory" normalize={Utility.trimSpacesInValues} rules={rules.variant}>
              <Select
                showSearch
                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                placeholder="Choose variant"
                className="slct_style w-100"
                dropdownClassName="slct_drp_dwn border-radius"
                filterOption={variantFilter}
              >
                {form.getFieldValue('product') && variants?.map((item: Variants) => (
                  <Option
                    className="varient_option"
                    key={item?._id}
                    disabled={productDetails?.findIndex(
                      (product: any) => product?.variant?._id === item?._id,
                    ) !== -1 || !item?.quantity}
                    value={item?._id}
                  >
                    <div className="d-flex align-items-center"> <span
                      className="veh_color d-inline-block me-2 mt-0 rounded-circle v_color_display"
                      style={{ backgroundColor: item?.color?.code }}
                    />
                      {item?.size}
                    </div>
                    <div>{item?.price} KD</div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button htmlType="submit" block type="primary" className="main_btn w-auto border-0 r-bold fs-16">
            Add Now
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddProductModal;
