/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { Layout, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { Icon } from '..';

const { Sider } = Layout;

interface IInfo {
  children?: React.ReactNode,
  setCollapsed: (value: boolean) => void,
  infoDetail: string
}

Info.defaultProps = {
  children: <></>,
};

function Info(props: IInfo) {
  const { children, setCollapsed, infoDetail } = props;

  return (
    <Sider
      className="r_side_wrapper d-none d-xl-block"
      collapsedWidth="0"
      width={230}
      collapsible
      onCollapse={(value) => setCollapsed(value)}
      trigger={
        <Icon className="icon-16" iconRef="#menu" />
            }
    >
      <>
        <Typography>
          <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
          <Paragraph>{infoDetail}</Paragraph>
        </Typography>
        {children}
      </>
    </Sider>
  );
}

export default Info;
