/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, action, makeObservable, runInAction,
} from 'mobx';
import Constant from '../Global/Constant';
import notificationService from '../Services/notificationService';

class NotificationStore {
    notifications: any = [];

    total: number = 0;

    unSeenCount: number = 0;

    constructor() {
      makeObservable(this, {
        notifications: observable,
        unSeenCount: observable,
        getNotifications: action,
      });
    }

    getNotifications = async (page: number, limit: number) => {
      try {
        const response = await notificationService.getNotifications(page, limit);
        if (response && response?.data) {
          if (page === Constant.defaultPageNumber) {
            this.notifications = [];
          }
          runInAction(() => {
            this.notifications = [...this.notifications, ...response?.data?.notifications];
            this.total = response?.data?.total;
            this.unSeenCount = response?.data?.unSeenNotificationCount;
          });
        }
        return null;
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    updateNotificationStatus = async (page: number, limit: number) => {
      try {
        const response = await notificationService.updateNotificationStatus(page, limit);
        if (response && response?.data) {
          this.unSeenCount = response?.data?.unSeenNotificationCount;
        }
        return null;
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    pushNotification = (data: any) => {
      this.unSeenCount += 1;
      const notificationLength = this?.notifications?.length;
      if (notificationLength && (notificationLength % Constant.notificationPerPage === 0)) {
        this.notifications.pop();
      }
      this.notifications?.unshift(data);
      this.total += 1;
    }
}

export default new NotificationStore();
