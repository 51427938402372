/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Button, Card, Col, Divider, Form, Input, Layout, Row, Spin, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames';
import Routes from '../../Global/Routes';
import fileService from '../../Services/fileService';
import rules from './CreateFacilityform.validation';
import Toast from '../../Components/Toast';
import { Counter, Icon, UploadImage } from '../../Components';
import { FacilityForm } from '../../Global/interface';

import './CreateFacility.less';
import Utility from '../../Global/Utility';

const {
  Title, Paragraph,
} = Typography;
const { Sider } = Layout;

function CreateFacility(props: any) {
  const { facilityStore, masterStore } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileUploading, setIsProfileUploading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [phone, setPhone] = useState<any>();
  const countryCodes = masterStore?.contryCodes;
  const history = useHistory();
  const [form] = useForm();
  const { id } = useParams<any>();
  let facilityData: any = [];
  const { facility } = facilityStore;
  const [collapsed, setCollapsed] = useState(false);

  const createFacility = async (facilityFormData: FacilityForm) => {
    try {
      const params: FacilityForm = facilityFormData;
      params.coordinates = ['1213', '8977'];
      if (profile) params.image = profile;
      setIsLoading(true);
      const response: any = await facilityStore.createFacility(params);
      setIsLoading(false);
      history.push(`${Routes.facility_detail}/${response?.id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };
  const fetchFacilityData = async (facilityId: string) => {
    setIsLoading(true);
    try {
      await facilityStore.getFacilityById(facilityId);
      facilityData = facilityStore?.facility;
      form.setFieldsValue({
        name: facilityData?.name,
        address: facilityData?.address,
        phone: facilityData?.phone,
        total_capacity: facilityData?.total_capacity,
      });
      if (facilityData?.image?.fileUrl) {
        setProfile(facilityData?.image);
      }
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const updateFacility = async (facilityFormData: FacilityForm) => {
    try {
      if (facility?.used_capacity > facilityFormData?.total_capacity) {
        Toast?.warning(`Facility is used in ${facility?.used_capacity} ${facility?.used_capacity > 1 ? 'contracts.' : 'contract.'}`);
        return;
      }
      const params: FacilityForm = facilityFormData;
      params.coordinates = ['1213', '8977'];
      if (profile) params.image = profile;
      else params.image = null;
      setIsLoading(true);
      await facilityStore.updateFacility(id, params);
      setIsLoading(false);
      history.push(`${Routes.facility_detail}/${id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const goToFacilityListing = () => {
    if (isUpdate) history.push(`${Routes.facility_detail}/${id}`);
    else history.push(Routes.facilities);
  };

  const uploadProfile = async (fileInfo:any) => {
    if (fileInfo?.fileList?.length) {
      setIsProfileUploading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setProfile(response?.data[0]);
      setIsProfileUploading(false);
    }
  };

  const handleOnChangeProfile = (fileInfo: any) => {
    uploadProfile(fileInfo);
  };

  const removeProfile = () => {
    setProfile(null);
  };

  const onFinish = (values: any) => {
    if (id) updateFacility(values);
    else createFacility(values);
  };

  async function getCountryData() {
    await masterStore?.getCountryData()
      .catch((error:any) => { Toast.error(error?.response?.data?.error?.message); });
  }
  useEffect(() => {
    if (id) {
      fetchFacilityData(id);
      setIsUpdate(true);
    }
    if (!masterStore?.countries) {
      getCountryData();
    }
  }, []);

  return (
    <Layout.Content className="create_wrapper create_fac_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-lg-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Button className="d-flex ml-auto mb-3 r-bold align-items-center back_link p-0" type="link" onClick={goToFacilityListing}>
              <div
                className="me-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle"
              >
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Button>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {isUpdate ? 'Edit Facility' : 'Create Facility'}
            </Typography.Title>
            <Card className="create_card">
              <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Details
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Typography.Text className="m-0 create_para">
                      {isUpdate ? 'Edit facility details' : 'Enter facility details'}
                    </Typography.Text>
                    <div className="d-flex mt-3 mb-3 align-items-center">
                      <Form.Item name="profile" className="mb-0">
                        <div className="position-relative">
                          <Spin spinning={isProfileUploading}>
                            <UploadImage
                              handleOnChangeImage={handleOnChangeProfile}
                              image={profile}
                              removeImage={removeProfile}
                              isLoading={isProfileUploading}
                              className="avatar-uploader pro_pic_avatar"
                              uploadButtonIcon="#facilities"
                            />
                            {/* <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader pro_pic_avatar"
                              showUploadList={false}
                              maxCount={1}
                              onChange={handleOnChangeProfile}
                              onRemove={removeProfile}
                              accept=".jpeg,.png,.jpg"
                              customRequest={customRequest}
                            >
                              {profile?.fileUrl ? (
                                <img
                                  src={profile?.fileUrl}
                                  alt="avatar"
                                  style={{ width: '100%' }}
                                />
                              ) : uploadButton}
                              <div className="img_upload">
                                <div className="create_image p-0
                              rounded-circle justify-content-center d-flex align-items-center"
                                >
                                  <Icon className="icon-40" iconRef="#upload" />
                                </div>
                              </div>
                            </Upload> */}
                          </Spin>
                        </div>
                      </Form.Item>
                      {profile?.fileUrl
                      && <Button className="d-flex ml-auto ms-3 r-bold align-items-center delete-btn p-0" onClick={removeProfile}>Remove</Button>}
                    </div>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="NAME" name="name" className="mandatory" required={false} normalize={Utility.trimSpacesInValues} rules={rules.name}>
                          <Input placeholder="Enter facility name" className="inp_style" maxLength={50} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="LOCATION" name="address" className="mandatory" normalize={Utility.trimSpacesInValues} rules={rules.address}>
                          <Input placeholder="Enter facility location" type="text" className="inp_style" maxLength={50} />
                        </Form.Item>
                        <div className="fac_location_icn position-absolute">
                          <Icon className="icon-13" iconRef="#location" />
                        </div>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="PHONE" className="mandatory" name="phone" normalize={Utility.trimSpacesInValues} rules={rules.phone}>
                          {countryCodes
                            && (
                            <PhoneInput
                              defaultCountry="KW"
                              international
                              countries={countryCodes}
                              className="phn_inp_style"
                              countryCallingCodeEditable={false}
                              placeholder="Enter phone number"
                              value={phone}
                              onChange={(value) => setPhone(value)}
                              maxLength={16}
                            />
                            )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16} className="ps-2 ps-sm-0">
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Counter
                          label="facility capacity"
                          name="total_capacity"
                          rule={rules.total_capacity}
                          min={0}
                          max={999999}
                          maxLength={6}
                        />
                      </Col>
                    </Row>
                    <Typography.Text className="m-0 create_para">
                      Enter the total capacity of the facility
                    </Typography.Text>
                    <Divider className="m-0 border-top mt-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24} />
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex mt-4">
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        {isUpdate ? 'Update' : 'Create' }
                      </Button>
                      <Button type="text" className="cancel_btn fs-16 r-bold" onClick={goToFacilityListing}>
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper mt-3 mt-xl-0 d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{isUpdate ? 'See logs and manage the facility' : 'Create a Facility'}</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('facilityStore', 'masterStore')(observer(CreateFacility));
