/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import api from './api';

class DashboardService {
    getOrderCount = (queryParams: any) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/dashboard/order-count?${query}`;
      return api.get(url);
    }

    getInventoryDetails = () => {
      const url = '/shop/dashboard/inventory';
      return api.get(url);
    }
}
export default new DashboardService();
