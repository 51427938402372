/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable, action } from 'mobx';
import planService from '../Services/planService';
import Constant from '../Global/Constant';

export interface IPlanStore{
  plan: any,
  planTerms: any,
  planCount: number,
  initialize: Function
  createPlan: (data: any) => any,
  updatePlan: (id: string, data: any) => any,
  getPlanById: (id: string) => any,
  deletePlan: (id: string) => any,
  getPlanCount:(id: string, queryParams?: any) => Promise<any>
}

class PlanStore {
  plan: any = null;

  planTerms: any = null;

  planCount: number = 0;

  constructor() {
    makeObservable(this, {
      plan: observable,
      planCount: observable,
      planTerms: observable,
      createPlan: action,
      updatePlan: action,
      getPlanById: action,
      deletePlan: action,
    });
    this.initialize();
  }

   initialize = () => {
     this.plan = null;
     this.planCount = 0;
     this.planTerms = null;
   }

  createPlan = async (data: any) => {
    try {
      await planService.createPlan(data);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  updatePlan = async (id: string, data: any) => {
    try {
      await planService.updatePlan(id, data);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getPlanById = async (id: string) => {
    try {
      const response = await planService.getPlanById(id);
      this.plan = response?.data?.contractPlan?.planDetails;
      this.planTerms = response?.data?.contractPlan?.terms;
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  deletePlan = async (id: string) => {
    try {
      await planService.deletePlan(id);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getPlanCount = async (id: string, queryParams?: any) => {
    try {
      const response = await planService.getPlanCount(id, queryParams);
      this.planCount = response?.data?.planCount;
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new PlanStore();
