/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Typography,
  Slider,
  Row,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import {
  Action, Icon, RenderItem, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

interface ServiceSliderProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  service: string,
  checkboxValue: boolean,
  completed: boolean,
  savedValue: any,
  checkboxHandler: (value: any, service: string) => void
  completeService: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string,

}

function ServiceSlider(props: ServiceSliderProps) {
  const {
    isInfoRequired,
    requestInfo,
    started,
    saved,
    service,
    checkboxValue,
    savedValue,
    completed,
    checkboxHandler,
    completeService,
    vehicleStatus,
    visitStatus,
  } = props;

  const [showAction, setShowAction] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const [boxChecked, setBoxChecked] = useState<boolean>(false);

  useEffect(() => {
    setValue(vehicleStatus?.value);
  }, [vehicleStatus]);

  const handleAccept = () => {
    if (!value) return Toast.error('Please add value.');
    return completeService(value, service);
  };

  const handleReject = () => {
    setShowAction(false);
    setBoxChecked(false);
    setValue(null);
  };
  const handleCheckbox = (checked: boolean) => {
    setShowAction(checked);
    setBoxChecked(checked);
  };
  const handleValue = (val: any) => setValue(val);

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <Checkbox
              className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
              defaultChecked={checkboxValue}
              disabled={completed}
              onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
            />
            <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
              <Icon className="icon-20" iconRef="#fluid" />
            </div>
            {Constant?.SERVICES?.get(service)}<RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15} xs={24} className="ps-sm-2 pe-sm-2 d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mt-3 mt-sm-0 order-2 order-sm-1">
            <Typography.Paragraph className="fs-16 mb-0 inactive">
              {vehicleStatus?.last_updated_time
                ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                : ''}
            </Typography.Paragraph>
            <RenderItem isTrue={started && saved && showAction && !completed}>
              <Action onAccept={handleAccept} onReject={handleReject} />
            </RenderItem>
          </div>
          <RenderItem isTrue={(started && saved) || (isInfoRequired && !requestInfo)}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2"> {Constant?.SERVICES?.get(service)} STATUS</Typography.Text>
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">LEVEL - {Utility?.levelIndicator(value)}</Typography.Text>
              <Slider
                trackStyle={{ height: '12px', background: Utility?.sliderColor(value), borderRadius: '25px' }}
                handleStyle={{
                  width: '24px', height: '24px', marginTop: '-6px', background: '#F2F2F2', boxShadow: '0px 2px 2px #00000029', border: '1px solid #E3E3E3',
                }}
                onChange={(val: any) => { if (!(completed || visitStatus === 'OPEN')) handleValue(val); }}
                value={value}
                // disabled={completed || visitStatus === 'OPEN'}
                step={1}
                defaultValue={0}
                max={10}
                tipFormatter={null}
                className={classNames('cmn_slider w-50', { 'slider-disabled': (completed || visitStatus === 'OPEN') === true })}
              />
              <Checkbox
                disabled={completed || visitStatus === 'OPEN'}
                defaultChecked={completed}
                checked={boxChecked || completed}
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                onChange={(e) => handleCheckbox(e?.target?.checked)}
              >
                {Constant?.SERVICES?.get(service)} status checked
              </Checkbox>
            </div>
          </RenderItem>
        </Col>
      </Row>
    </div>
  );
}
export default ServiceSlider;
