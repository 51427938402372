/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Card,
  Progress,
  Typography,
} from 'antd';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import { ActiveVehicle } from '../../Global/interface';
import VehicleImage from './VehicleImage';
import PaymentStatus from './PaymentStatus';

const { Title, Paragraph } = Typography;

interface IPlanCardView {
  planType: string
  duration: string
  plan_code: string
  planlabelColor: string
  startDate: number
  dueDate: number
  last_penalty_date: number
  status: string
  client: string
  modelImage: string
  isLoading: boolean
  dueAmount: number
  plan: string
  activeVehicle: ActiveVehicle
  modelCategory: string
  goToPlanDetails: () => void
}

const {
  EXPIRING, PAYMENT_PENDING, EXPIRED,
} = Constant.contractTypes;

function PlanCardView(props: IPlanCardView) {
  const {
    planType, duration, plan_code, planlabelColor, dueDate, startDate, status, client,
    modelCategory, modelImage, isLoading, last_penalty_date, dueAmount, plan, activeVehicle,
    goToPlanDetails,
  } = props;

  const calculateOverDue = () => {
    let start_date = dueDate;
    if (last_penalty_date) {
      start_date = (dueDate > last_penalty_date) ? dueDate : last_penalty_date;
    }
    let dueDays = 0;
    if (planType === Constant.plan_type?.VAULT) {
      if (activeVehicle && dueDate < Date.now()) {
        const now = Date.now();
        const endOfDay = (now - (now % 86400000)) + 86400000 - 1000;
        dueDays = Utility.calculateDateDifference(
          start_date, endOfDay,
        );
      }
    }
    return dueDays;
  };

  const getPercentageColor = () => {
    switch (status) {
      case EXPIRED:
        return '#ff4141';
      case EXPIRING:
        return '#f1aa1d';
      default:
        return '#004225';
    }
  };

  const getCardColor = () => {
    if (isLoading) return '';
    switch (status) {
      case EXPIRED:
        return 'red_bg';
      case EXPIRING:
        return 'yellow_bg';
      default:
        return '';
    }
  };

  const percentage = Utility.calculateDatePercentage(startDate, dueDate, false);
  const percentColor = getPercentageColor();
  const cardColor = getCardColor();
  const dueDays = calculateOverDue();
  const overDue = dueDays * dueAmount;
  const overDueText = overDue ? ` (OVERDUE - ${overDue}KD)` : '';

  return (
    <Card className={`${cardColor} border-0 mb-3`} loading={isLoading} onClick={goToPlanDetails}>
      <div className="d-flex justify-content-between">
        <Typography>
          <Title level={5} className="primary r-medium text-capitalise mb-0">{plan} Plan ({duration})</Title>
          <Paragraph className={`fs-12 ${planlabelColor} text-uppercase r-medium mb-0`}>{plan_code}{overDueText}</Paragraph>
        </Typography>
        { status !== PAYMENT_PENDING
        && (
        <Progress
          className="custom_plan_progress"
          percent={percentage}
          size="small"
          showInfo={false}
          strokeWidth={11}
          strokeColor={percentColor}
          trailColor="#E3E3E3"
        />
        )}
      </div>
      <VehicleImage
        activeVehicle={!!activeVehicle}
        image={modelImage || Utility.defaultModalImage(modelCategory)}
      />
      <div className="d-flex justify-content-between">
        <Typography.Title level={5} className="primary r-medium text-truncate text-capitalise mb-0" style={{ maxWidth: '65%' }}>
          {client}
        </Typography.Title>
        <PaymentStatus
          dueDate={Utility.getFormattedDate(dueDate)}
          isPaymentPending={status === PAYMENT_PENDING}
          planlabelColor={planlabelColor}
        />
      </div>
    </Card>
  );
}

export default PlanCardView;
