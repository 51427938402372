/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form, Layout, Row, Spin, Switch, Table, Typography,
} from 'antd';
import classNames from 'classnames';
import {
  Action,
  EditableCell, Icon, NoData, Pagination, RenderItem, SortTh, Toast,
} from '../../Components';
import { IServiceStore } from '../../Store/serviceRequestStore';
import Constant from '../../Global/Constant';
import { serviceRequestProps } from '../../Global/interface';
import rules from './manageCategoryForm.validation';
import './ServiceListing.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IServices{
    serviceStore: IServiceStore
}

function ServiceS(props: IServices) {
  const { serviceStore } = props;
  const { services, total } = serviceStore;

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [editingKey, setEditingKey] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState<string>('services');
  const [order, setOrder] = useState<number>(-1);

  const pageHandler = (page: number) => {
    setPageNumber(page);
  };

  const showNodata = total <= 0 && !isLoading;

  const isEditing = (record: any) => record?.key === editingKey;

  const sortTable = (dataIndex: string) => {
    if (editingKey === '') {
      let sort: number = -1;
      if (order === -1 && dataIndex === orderBy) sort = 1;
      else if (dataIndex !== orderBy) setOrderBy(dataIndex);
      setOrder(sort);
      getServices(sort);
    }
  };

  const editButtonHandler = (record: any) => {
    form.setFieldsValue({
      price: record?.price,
      delivery_charge: record?.delivery_charge,
      plan_required: record?.plan_required === 'Yes',
    });
    setEditingKey(record?.key);
  };

  const updateService = async (editing_key: any, service: any) => {
    setIsLoading(true);
    try {
      await serviceStore.updateService(editing_key, service);
      getServices(order);
      initializeFormField();
    } catch (err: any) {
      Toast.error(err);
      getServices(order);
    }
    setIsLoading(false);
  };

  const onAccept = async () => {
    form.validateFields().then((service: any) => updateService(editingKey, service));
  };

  const columns = [
    {
      title: editingKey === '' ? <SortTh title="Service" dataIndex="services" orderBy={order} order={orderBy} /> : 'Service',
      dataIndex: 'services',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
      editable: false,
    },
    {
      title: 'Service Price',
      dataIndex: 'price',
      placeholder: 'price',
      inputType: 'number',
      ellipsis: true,
      editable: true,
      maxLength: 15,
      min: 0,
      max: 999999999999999,
      rules: rules?.price,
    },
    {
      title: 'Delivery Charge',
      dataIndex: 'delivery_charge',
      inputType: 'number',
      placeholder: 'delivery charge',
      editable: true,
      maxLength: 15,
      min: 0,
      max: 999999999999999,
      rules: rules?.delivery_charge,
    },
    {
      title: 'Plan Required',
      dataIndex: 'plan_required',
      inputType: 'radio',
      radioData: [{ value: false, displayText: 'No' }, { value: true, displayText: 'Yes' }],
      placeholder: 'plan required',
      ellipsis: true,
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return (
          <div className="d-flex align-items-center">
            <RenderItem isTrue={editable}>
              <div className="d-flex align-items-center">
                <Action
                  onAccept={onAccept}
                  onReject={initializeFormField}
                  disabled={isLoading}
                  acceptToolTipText="Save"
                  rejectToolTipText="Cancel"
                />
              </div>
            </RenderItem>
            <RenderItem isTrue={!editable}>
              <Switch
                defaultChecked={record?.active}
                checked={record?.active}
                className="me-3 custom_switch"
                size="small"
                onChange={(checked: any) => updateService(record?.key, { active: checked })}
              />
              <RenderItem isTrue={record?.active}>
                <div className="d-flex">
                  <Button
                    className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0"
                    onClick={() => editButtonHandler(record)}
                    disabled={editingKey !== ''}
                  >
                    Edit
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                </div>
              </RenderItem>
            </RenderItem>
          </div>
        );
      },
    },
  ];

  const mergedColumns: any = columns.map((col: any) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col?.inputType,
        dataIndex: col?.dataIndex,
        title: col?.title,
        editing: isEditing(record),
        maxLength: col?.maxLength,
        min: col?.min,
        max: col?.max,
        rules: col?.rules,
        placeholder: col?.placeholder,
        radioData: col?.radioData,
      }),
    };
  });

  const getServices = async (sortBy: number) => {
    setIsLoading(true);
    try {
      await serviceStore?.getServices({ orderBy: sortBy, order: 'name' });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getServices(order);
  }, []);

  useEffect(() => {
    const serviceData = services?.map(
      (service: serviceRequestProps) => ({
        services:
  <div className="d-flex align-items-center">
    <div
      className={`icn_32 primary rounded-circle d-flex align-items-center justify-content-center me-2 ${Constant?.statusImageClass?.get(service?.service)}`}
    >
      <Icon className="icon-14" iconRef={Constant.statusImage.get(service?.service) as string} />
    </div>
    {service?.name}
  </div>,
        price: service?.price || 0,
        delivery_charge: service?.delivery_charge || 0,
        plan_required: service?.is_contract_required ? 'Yes' : 'No',
        key: service?._id,
        active: service?.active,
      }),
    );
    setData(serviceData);
  }, [services]);

  const initializeFormField = () => {
    form.setFieldsValue({
      delivery_charge: '',
      price: '',
      plan_required: '',
    });
    setEditingKey('');
  };

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24 d-flex align-items-center border-bottom-0">
              Services
            </Typography.Title>
            { !showNodata
              && (
              <Form form={form} component={false}>
                <Table
                  className="table_wrapper service_listing vertical_align_table"
                  rowClassName="editable-row"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={data}
                  columns={mergedColumns}
                  pagination={false}
                  size="small"
                  scroll={{ x: '800px' }}
                />
              </Form>
              )}
            <Pagination
              total={total}
              pageSize={Constant.categoryPerPage}
              currentPage={pageNumber}
              onChange={pageHandler}
              disabled={editingKey !== ''}
            />
            { showNodata
            && (
            <div>
              <NoData
                heading="Empty"
                displayText="No Services"
              />
            </div>
            )}
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
              }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>Setup and manage services</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('serviceStore')(observer(ServiceS));
