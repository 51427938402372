/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Breadcrumb,
  Col,
  Divider,
  Layout,
  Row,
  Table,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { Icon, Pagination, SortTh } from '../../Components';
import './ArchivedListing.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function ArchivedListing() {
  const [collapsed, setCollapsed] = useState(false);
  const columns = [
    {
      title: <SortTh title="No" dataIndex="" orderBy={2} order={' '} />,
      dataIndex: 'number',
      width: '10%',
    },
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      ellipsis: true,
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
    },
  ];
  const data = [
    {
      number: '1',
      order_id: 'OR10123',
      customer_name: 'Jeniffer Rodger',
      date: '17/11/2021',
      payment_status: <div className="label_success rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Paid">Paid</div>,
      order_status: <div className="label_success rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Fulfilled">Fulfilled</div>,
      total_amount: '85 KD',
    },
    {
      number: '2',
      order_id: 'OR10123',
      customer_name: 'Jeniffer Rodger',
      date: '17/11/2021',
      payment_status: <div className="label_success rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Paid">Paid</div>,
      order_status: <div className="label_success rounded-pill ps-2 pe-2 w-lg-100 d-inline-block ellipse text-center" title="Fulfilled">Fulfilled</div>,
      total_amount: '85 KD',
    },
  ];

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between">
            <Breadcrumb className="secondary text-uppercase r-medium">
              <Breadcrumb.Item>
                <a href={' '}>ORDERS</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>ARCHIVED ORDERS</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Divider className="mt-3 mb-3 border-top" />
          <Typography.Title className="m-0 header_main fs-xs-24 mb-4 border-0">
            Archived orders
          </Typography.Title>
          <Table
            className="table_wrapper archived_listing"
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={data}
            pagination={false}
            size="small"
            scroll={{ x: '800px' }}
          />
          <Pagination
            total={50}
            pageSize={4}
            currentPage={1}
            onChange={() => {}}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the archived orders</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default observer(ArchivedListing);
