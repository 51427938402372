/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  password: [
    {
      required: true,
      message: Constant.passwordRequiredValidationError,
    },
    {
      type: 'string',
      pattern: Constant.passwordRegex,
      message: Constant.invalidPassword,
    },
  ],
  email: [
    {
      type: 'email',
      message: Constant.emailValidationError,
    },
    {
      required: true,
      message: Constant.emailRequiredValidationError,
    },
  ],
};

export default rule;
