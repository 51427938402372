/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { Provider } from 'mobx-react';
import { Layout } from 'antd';
import authStore from './Store/authStore';
import userStore from './Store/userStore';
import tenantStore from './Store/tenantStore';
import store from './Store';
import './App.less';
import './Assets/less/style.less';
import ApplicationRoutes from './Routing/ApplicationRoutes';
import ScrollToTop from './ScrollToTop';

export class App extends React.Component {
  constructor(props: any) {
    super(props);
    authStore.addAuthListener((user:any) => {
      authStore.currentUser = user;
      this.forceUpdate();
    });
  }

  componentDidMount() {
    tenantStore.getTenantData();
    if (!tenantStore?.tenantAccess) {
      tenantStore.getTenantAccess();
    }
    if (authStore?.currentUser?.id) {
      userStore.getLoggedUser(authStore?.currentUser?.id);
    }
  }

  render() {
    return (
      <Provider {...store}>
        <Router>
          <Layout style={{ minHeight: '100vh', backgroundColor: '#d8e8fe' }}>
            <ScrollToTop />
            <ApplicationRoutes />
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
