/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-unresolved
import React from 'react';
import { Typography } from 'antd';
import { Icon } from '..';
import './ContactCard.less';

interface ContactProps {
    phone: number;
    email: String
}

interface ContactCardProps{
    contactDetails: ContactProps|any
}

function ContactCard(props: ContactCardProps) {
  const { contactDetails } = props;
  return (
    <div className="detail_card_wrapper">
      <div>
        <Typography.Title level={4} className="fs-xs-18">Contact</Typography.Title>
        <div>
          {/* phone update */}
          {contactDetails?.phone
        && (
        <div className="d-flex align-items-center">
          <div className="small_icon_bg icn_32 rounded-circle d-flex justify-content-center align-items-center p-0">
            <Icon className="icon-14" iconRef="#phone" />
          </div>
          <Typography.Paragraph className="m-0 ms-2 secondary">{contactDetails?.phone}</Typography.Paragraph>
        </div>
        )}
          { contactDetails?.email && (
          <div className="d-flex align-items-center contact_card_brd position-relative pt-2 mt-2">
            <div className="small_icon_bg icn_32 rounded-circle d-flex justify-content-center align-items-center p-0">
              <Icon className="icon-14" iconRef="#mail" />
            </div>
            <Typography.Paragraph className="m-0 ms-2 secondary text-break">{contactDetails?.email}</Typography.Paragraph>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ContactCard;
