/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  InputNumber,
  Layout,
  Menu,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import {
  Icon, Toast, OrderItem, StatusLabel, ActivityCard, RenderItem,
} from '../../Components';

import { IOrderStore } from '../../Store/orderStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const { ORDER_CANCELED, DELIVERED, ORDER_RETURNED } = Constant?.order_status;

interface orderDetailsProps {
  orderStore: IOrderStore
}
function OrderDetails(props: orderDetailsProps) {
  const { orderStore } = props;
  const {
    order, category, product, variant, activities,
  } = orderStore;

  const { id } = useParams<any>();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isActivityLoading, setIsActivityLoading] = useState<boolean>(false);
  const [returnModelVisible, setReturnModelVisible] = useState<boolean>(false);
  const [returnQuantity, setReturnQuantity] = useState<any>(1);

  const isOrderDelivered = order?.order_status === DELIVERED;
  const isOrderDeleted = order?.deleted;

  const goToReturnDetails = () => history.push(`${Routes.return_quick_order_details}/${order?.return_request_id}`);

  const getOrderDetails = async () => {
    try {
      setIsLoading(true);
      await orderStore.getOrderById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getOrderActivities = async () => {
    try {
      setIsActivityLoading(true);
      await orderStore.listOrderActivities({ order_id: id });
      setIsActivityLoading(false);
    } catch (error: any) {
      setIsActivityLoading(false);
      Toast.error(error);
    }
  };

  const returnOrder = async () => {
    try {
      if (!returnQuantity || returnQuantity <= 0) {
        Toast.error('Please provide a valid order quantity.');
      } else {
        setIsLoading(true);
        await orderStore?.createReturnOrderRequest(id, { quantity: returnQuantity });
        setReturnModelVisible(false);
        await getOrderDetails();
        await getOrderActivities();
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const S3Url = await orderStore?.downloadInvoice(id);
      const downloadUrl = await Utility.createObjectUrlFromUrl(S3Url);
      Utility.downloadFile(downloadUrl, `Invoice-${order?.order_code}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const closeReturnModal = () => setReturnModelVisible(false);

  const openReturnModal = () => setReturnModelVisible(true);

  const handleQtyChange = (qty: any) => setReturnQuantity(qty);

  useEffect(() => {
    getOrderDetails();
    getOrderActivities();
  }, [id]);

  const menu = (
    <Menu className="box_shadow border-radius">
      {/* <Menu.Item key="3">
        <Button className="d-flex ml-auto r-bold align-items-center border-0 h-auto">
          Archive Order
          <div className="ms-2 d-flex justify-content-center
          align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item> */}
      {isOrderDelivered && (order?.order_quantity - order?.return_quantity > 0)
              && (
              <Menu.Item key="0">
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto" onClick={openReturnModal}>
                  Return Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
      { order?.is_return_requested
              && (
              <Menu.Item key="2">
                <Button className="d-flex ml-auto r-bold align-items-center border-0 h-auto" onClick={goToReturnDetails}>
                  Return Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Menu.Item>
              )}
    </Menu>
  );

  const getStatusText = () => {
    if (!order?.payment_status) return { text: 'Payment Pending', className: 'label_warning' };
    if (order?.order_status === ORDER_CANCELED) return { text: 'Cancelled', className: 'label_success' };
    if (order?.order_status === ORDER_RETURNED) return { text: 'Returned', className: 'label_success' };
    return '';
  };

  const statusData: any = getStatusText();

  const amount: any = Utility.getUnitPrice(
    order?.order_quantity, order?.total_amount, order?.delivery_charge,
  );

  return (
    <Spin spinning={isLoading}>
      <Layout.Content className="detail_wrapper clnt_detail">
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.orders}>ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{order?.order_code}</Breadcrumb.Item>
              </Breadcrumb>
              <RenderItem
                isTrue={(order?.order_quantity - order?.return_quantity > 0) && !isOrderDeleted}
              >
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </RenderItem>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center flex-wrap">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24 me-sm-3 me-1">
                    {order?.order_code}
                  </Typography.Title>
                  <StatusLabel className={statusData?.className} text={statusData?.text} />
                </div>
              </div>
              <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xs={24} className="mt-3 mt-md-0">
                  <Card>
                    <Typography.Title level={3}>Order Item</Typography.Title>
                    <Row>
                      <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                        <OrderItem
                          category={category?.name}
                          product={product?.name}
                          variant={variant?.color?.name}
                          quantity={order?.order_quantity}
                          amount={amount}
                          image={variant?.images[0]}
                        />
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                    <div className="d-flex justify-content-end align-items-center fs-16 r-bold secondary mt-4">
                      Total Amount
                      <Typography.Text className="primary fs-24 ms-3">{order?.total_amount} KD</Typography.Text>
                    </div>
                    <Divider className="my-3 border-top" />
                    {order?.payment_status
                    && (
                    <div className="d-flex align-items-center">
                      <Button className="primary gray_bg h-40 border-radius border-0 fs-16 r-bold" onClick={downloadInvoice}>
                        Download Invoice
                      </Button>
                    </div>
                    )}
                  </Card>
                  <Spin spinning={isActivityLoading}>
                    <Card className="timeline_card mt-3" loading={isLoading}>
                      <Typography.Title level={3}>Timeline</Typography.Title>
                      {activities && activities?.length
                        ? (
                          <ActivityCard
                            dataSource={activities}
                            currentUser={authStore?.currentUser}
                          />
                        ) : 'No activity'}
                    </Card>
                  </Spin>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the order</Paragraph>
            </Typography>
            <RenderItem
              isTrue={(order?.order_quantity - order?.return_quantity > 0) && !isOrderDeleted}
            >
              <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
                {isOrderDelivered && (order?.order_quantity - order?.return_quantity > 0) && (
                  <Tooltip title="Return Order">
                    <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto" onClick={openReturnModal}>
                      Return Order
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                  </Tooltip>
                )}
                {/* <Tooltip title="Archive Order">
                <Button className="d-flex ml-auto r-bold align-items-center
                primary bg-transparent p-0 h-auto">
                  Archive Order
                  <div className="ms-2 d-flex justify-content-center
                  align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip> */}
                { order?.is_return_requested
              && (
              <Tooltip title="Return Order Request">
                <Button className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto" onClick={goToReturnDetails}>
                  Return Order Request
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
              </div>
            </RenderItem>
          </Sider>
        </Row>
        <Modal
          title="Return Item"
          centered
          visible={returnModelVisible}
          onOk={returnOrder}
          confirmLoading={isLoading}
          onCancel={closeReturnModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          className="cmn_modal_wrapper"
          okText="Return"
          okButtonProps={{ className: 'd-flex h-40 border-radius red_bg white border-0 r-bold align-items-center', disabled: isLoading }}
          destroyOnClose
          closeIcon={(
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
              <Icon className="icon-10" iconRef="#close" />
            </Button>
)}
        >
          <span className="secondary">Please enter the quantity to return the item(s)?</span>
          <div>
            <Form.Item
              label="ITEM QUANTITY"
              className="mandatory mb-0"
              required={false}
            >
              <InputNumber
                className="inp_style"
                name="visit count"
                defaultValue={1}
                maxLength={3}
                max={order?.order_quantity - order?.return_quantity}
                min={1}
                onChange={handleQtyChange}
              />

            </Form.Item>
          </div>
        </Modal>
      </Layout.Content>
    </Spin>
  );
}

export default inject('orderStore')(observer(OrderDetails));
