/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import queryString from 'query-string';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';

import classnames from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import {
  Icon,
  VehicleInfoCard,
  Toast,
  ServiceChecklist,
  VehicleDisplay,
  SidebarInfo,
  RenderItem,
  UserViewCard,
  NextVisit,
  ScheduleVisit,
  StatusLabel,
  VehicleSelector,
} from '../../Components';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import contractService from '../../Services/contractService';
import ConfirmModal from '../../Components/ConfirmModal';
import carimage from '../../Assets/Images/BMW X6.svg';
import qrimage from '../../Assets/Images/comp_qr.svg';
import VisitTime from './VisitTime';
import ScheduleNextVisit from '../../Components/ScheduleNextVisit';
import Utility from '../../Global/Utility';
import vehicleService from '../../Services/vehicleService';
import rule from './visitDetails.form.validation';
import contractStore from '../../Store/contractStore';
import clientStore from '../../Store/clientStore';
import EmptyParking from '../../Components/EmptyParking';

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function ServiceVisitDetails(props: any) {
  const {
    serviceVisitStore, authStore, settingsStore, serviceStore,
  } = props;
  const { currentUser } = authStore;
  const { serviceVisit } = serviceVisitStore;

  const [form] = useForm();

  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultVisitTab = queryParams?.tab || 'OPEN';

  const isSuperAdmin = currentUser?.access?.includes(Constant?.userAccess.SUPER_ADMIN);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [checklistOriginal, setChecklistOriginal] = useState<any>(null); // variable that hold checklist from db
  const [checklistShow, setChecklistShow] = useState<any>(null); // variable that shows check list
  const [contractChecklist, setContractChecklist] = useState<any>(null); // variable that hold checklist from enabled features
  const [unrequestedChecklist, setUnrequestedChecklist] = useState<any>(null); // variable that hold checklist not checked
  const [visitStarted, setVisitStarted] = useState<boolean>(true);
  const [serviceUnsaved, setServiceUnsaved] = useState<boolean>(false);
  const [visitFinished, setVisitFinished] = useState<boolean>(false); // visit is finished and show complete button
  const [visitCompleted, setVisitCompleted] = useState<boolean>(false); // service is completed and show payment
  const [showUnsaved, setShowUnsaved] = useState<boolean>(false); // show and hide save unsaved
  const [vehicleStatus, setVehicleStatus] = useState<any>(null);
  const [showAddVehicleModel, setShowAddVehicleModel] = useState<boolean>(false);

  const { id } = useParams<any>();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const initializeServiceVisit = () => {
    if (serviceVisit) {
      const { service_checklist, contract } = serviceVisit;
      setChecklistOriginal([...service_checklist]);
      setContractChecklist(contract?.enabled_features);
      setChecklistShow(service_checklist.map((item: any) => { item.saved = true; return item; })); // add saved true for all showing
      const showChecklistServices = service_checklist?.map((item: any) => item?.service);
      // removes requested services
      setUnrequestedChecklist(contract?.enabled_features?.filter((item: any) => {
        if (item.feature === 'TIRE_PRESSURE_CHECK') {
          return !showChecklistServices?.includes(item?.feature) && wheelNo;
        }
        return !showChecklistServices?.includes(item?.feature);
      }));
    }
    setServiceUnsaved(false);
  };

  useEffect(() => {
    initializeServiceVisit();
  }, [serviceVisit]);

  useEffect(() => {
    setVehicleStatus(serviceVisit?.vehicle_status);
    const status = serviceVisit?.status;
    setVisitCompleted(status === 'PAYMENT_PENDING' || status === 'COMPLETED' || status === 'PAYMENT_DONE');
    setVisitFinished(!(status === 'OPEN'));
  }, [serviceVisit]);

  const handleFinishedStatus = () => {
    const inCompleteExist = checklistShow.find((item: any) => !item?.completed);
    setVisitFinished(!inCompleteExist);
  };

  useEffect(() => {
    const found = checklistShow?.find((item: any) => {
      if (item?.service === 'FUEL_FILL') {
        return (getRequestInfoStatus(item?.service) && !(item?.requested_info?.requested_fuel_type
         && item?.requested_info?.requested_fuel_level !== null));
      }
      if (item?.service === 'TIRE_PRESSURE_CHECK') {
        return (getRequestInfoStatus(item?.service)
        && (Utility?.checkTirePressureValid(item?.requested_info, wheelNo as number) && item?.requested_info !== 'REGULAR'));
      }
      return (getRequestInfoStatus(item?.service) && !item?.requested_info);
    });
    setShowUnsaved(!found);
  }, [checklistShow]);

  useEffect(() => {
    if (checklistShow?.length) {
      // if new item added or existing item removed then set unsaved true
      const showService = checklistShow?.map((item: any) => item.service);
      const NotExist = checklistOriginal?.find((item: any) => !showService?.includes(item?.service));
      const unsaved = checklistShow?.find((item: any) => !item.saved);
      if (NotExist || unsaved) {
        setServiceUnsaved(true);
      } else {
        setServiceUnsaved(false);
      }
      handleFinishedStatus();
    }
  }, [checklistShow]);

  const getVisitById = async () => {
    try {
      setIsLoading(true);
      await serviceVisitStore.getServiceVisitById(id);
      if (serviceVisitStore?.serviceVisit?.status === 'IN_PROGRESS') {
        setVisitStarted(true);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
      history.replace(`${Routes.service_visit}?tab=${defaultVisitTab}`);
    }
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const getServices = async () => {
    try {
      setIsLoading(true);
      await serviceStore.getServices();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const rescheduleVisit = async (scheduled_time: number) => {
    try {
      setIsLoading(true);
      await serviceVisitStore?.updateServiceVisit(id, { scheduled_time });
      getVisitById();
      handleCancel();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  useEffect(() => {
    getSettings();
    getServices();
    getVisitById();
  }, [id]);

  const clientName = Utility.getUserName(serviceVisit?.client);

  const deletedUser = serviceVisit?.client?.deleted;

  const showModal = () => setIsModalVisible(true);

  const handleCancel = () => setIsModalVisible(false);

  const getRequestInfoStatus = (service: string) : boolean => {
    const serviceDetail = serviceStore?.services?.find((item: any) => item?.service === service);
    return serviceDetail?.request_info_required;
  };

  const handleAddService = (service: string) => {
    const serviceExist = checklistOriginal?.find((item: any) => item.service === service);
    let addingServiceDetails;
    if (serviceExist) {
      // if service already requested by client
      addingServiceDetails = serviceExist;
    } else {
      // if service is not requested by client
      const serviceDetail = serviceStore?.services?.find((item: any) => item?.service === service);
      addingServiceDetails = {
        completed: false,
        requested_info: serviceDetail.requested_info,
        saved: false,
        service,
      };
    }
    setChecklistShow([...checklistShow, addingServiceDetails]);
    setUnrequestedChecklist(unrequestedChecklist?.filter((item: any) => item?.feature !== service));
  };

  const handleRemoveService = (service: string) => {
    const serviceDetail = contractChecklist?.find((item: any) => item?.feature === service);
    setUnrequestedChecklist([...unrequestedChecklist, serviceDetail]);
    setChecklistShow(checklistShow?.filter((item: any) => item?.service !== service));
    const serviceExistInOg = checklistOriginal?.find((item: any) => item.service === service);
    if (serviceExistInOg) setServiceUnsaved(true);
  };

  const visitStatus = serviceVisit?.status;
  const showStart = visitStatus === 'OPEN' && !serviceUnsaved && !visitFinished && !visitCompleted && checklistShow?.length;
  const handleSaveCancel = () => initializeServiceVisit();
  const startServiceHandler = async () => {
    await serviceVisitStore?.updateServiceVisitStatus(id, { action: 'START' });
    await getVisitById();
    setVisitStarted(true);
  };

  const updateVehicleStatus = async (statusData: any) => {
    try {
      setIsLoading(true);
      await vehicleService.updateVehicleStatus(serviceVisit?.vehicle?._id, statusData);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const completeService = async (value: any, service: string) => {
    try {
      setIsLoading(true);
      // update the service visit
      const updatedChecklist = checklistOriginal?.map((item: any) => {
        if (item?.service === service) {
          item.completed = true;
          if (service === 'FUEL_FILL') {
            item.unit_price = value?.unitPrice;
            item.value = value?.quantity;
          } else {
            item.value = value;
          }
        }
        return item;
      });
      setChecklistOriginal(updatedChecklist);
      await serviceVisitStore?.updateServiceVisit(id, { service_checklist: updatedChecklist });
      const updateVehicleObject: any = { service, value, service_visit_id: id };
      if (service === 'FUEL_FILL') {
        updateVehicleObject.fuel_filled = value?.quantity;
        const fuelFillDetails = checklistOriginal?.find((item: any) => item.service === service);
        updateVehicleObject.value = fuelFillDetails?.requested_info?.requested_fuel_level;
      }
      await updateVehicleStatus(updateVehicleObject);
      getVisitById();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const saveCheckList = async () => {
    try {
      const checklistShowServices = checklistShow?.map((item: any) => item?.service);
      const checklistOriginalTemp = checklistOriginal?.filter((item: any) => checklistShowServices?.includes(item.service));
      // get unsaved checklist and add to original data
      const unsavedServices = checklistShow?.filter((item: any) => !item.saved).map((item:any) => { item.saved = true; return item; });
      setIsLoading(true);
      await serviceVisitStore?.updateServiceVisit(id, { service_checklist: [...checklistOriginalTemp, ...unsavedServices] });
      setIsLoading(false);
      setChecklistOriginal([...checklistOriginalTemp, ...unsavedServices]);
      setServiceUnsaved(false);
      getVisitById();
      Toast?.success('Checklist saved.');
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const updateRequestedInfo = (value: any, service: string) => {
    setChecklistShow(
      checklistShow?.map((item: any) => {
        if (item?.service === service) {
          item.requested_info = value;
        }
        return item;
      }),
    );
  };

  const gotoVisitCompletedDetails = () => history?.push(`${Routes.service_invoice}/${id}?tab=${defaultVisitTab}`);

  const handleCompleteVisit = async () => {
    try {
      setIsLoading(true);
      await serviceVisitStore?.updateServiceVisitStatus(id, { action: 'COMPLETE' });
      setVisitCompleted(true);
      getVisitById();
      setIsLoading(false);
      gotoVisitCompletedDetails();
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const getLastUpdatedTime = (service: string) => vehicleStatus && vehicleStatus[service];
  const showAddVehicle = () => setShowAddVehicleModel(true);
  const closeAddVehicle = () => setShowAddVehicleModel(false);

  const stautusText = Constant.serviceVisitStatus.get(serviceVisit?.status) as string;
  const statusClassName = Constant.ServiceVisitStatusClass.get(serviceVisit?.status);

  const showReschedule = serviceVisit?.status === Constant.serviceVisitReqStatus.OPEN || serviceVisit?.status === Constant.serviceVisitReqStatus.IN_PROGRESS;

  const addVehicleToPlan = async (value: any) => {
    try {
      setIsLoading(true);
      await contractStore.addVehicleToPlan({
        client: serviceVisit?.client?._id, vehicle: value?.vehicle, planId: serviceVisit?.contract?._id,
      });
      setIsLoading(false);
      setShowAddVehicleModel(false);
      getVisitById();
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  useEffect(() => {
    if (serviceVisit?.client?._id) {
      getClientVehicles();
    }
  }, [serviceVisit?.client?._id]);

  const getClientVehicles = async () => {
    try {
      setIsLoading(true);
      await clientStore.getClientVehicle({
        clientId: serviceVisit?.client?._id, sharedVehicle: false,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(true);
      Toast.error(error);
    }
  };

  const showCancelButton = serviceVisit?.status === 'OPEN' || serviceVisit?.status === 'IN_PROGRESS';

  const gotoPlanDetails = () => history.push(`${Routes.contract_detail}/${serviceVisit?.contract?._id}`);
  const gotoListingPage = () => history.push(`${Routes.service_visit}?tab=${defaultVisitTab}`);
  const cancelVisit = async () => {
    try {
      setIsLoading(true);
      await serviceVisitStore?.updateServiceVisitStatus(id, { action: 'CANCEL' });
      gotoListingPage();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const confirmCancelVisit = () => {
    const warningMsg = serviceVisit?.status === 'IN_PROGRESS'
      ? 'Service visit is in progress!. Are you sure want to cancel this service visit anyway?.'
      : 'Are you sure want to cancel this service visit?';
    ConfirmModal('Cancel Service Visit', warningMsg, cancelVisit, 'Cancel Visit', '#ff4141', 'del_modal_wrap modal_heading_pad');
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="1">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoPlanDetails}>
          View Plan
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <RenderItem isTrue={isSuperAdmin && showCancelButton}>
          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={confirmCancelVisit}>
            Cancel Service Visit
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </RenderItem>
      </Menu.Item>
    </Menu>
  );

  const wheelNo = Constant?.wheels?.get(serviceVisit?.model?.category);
  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >

            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-0">
                <Breadcrumb.Item>
                  <Link to={`${Routes.service_visit}?tab=${defaultVisitTab}`}>service visits</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{serviceVisit?.visit_code}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>

            </div>
            <div className="mb-3">

              <VisitTime
                time={
                  `${Utility?.getFormattedDate(serviceVisit?.scheduled_time) || ''} 
                  ${Utility?.getFormattedTime(serviceVisit?.scheduled_time) || ''}`
                }
                Label={(
                  <StatusLabel
                    className={`${statusClassName} h-max ms-sm-3 mb-3 mb-sm-0 w-max-content`}
                    text={stautusText}
                  />
                )}
                action={showModal}
                buttonName="Reschedule"
                key={id}
                showReschedule={showReschedule}
              />

              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Row gutter={16}>
                    <Col lg={16} xs={24}>
                      <RenderItem isTrue={serviceVisit?.vehicle}>
                        <div className="mb-3">
                          <VehicleDisplay
                            isLoading={false}
                            modelName={`${serviceVisit?.model?.company} ${serviceVisit?.model?.model}`}
                            vehicleCode={serviceVisit?.vehicle?.vehicle_code}
                            qrimage={qrimage}
                            vehicleImage={serviceVisit?.vehicle?.image?.fileUrl}
                            category={serviceVisit?.model?.category}
                            vehicleId={serviceVisit?.vehicle?._id}
                            vehicleNo={serviceVisit?.vehicle?.vehicle_no}
                          />
                        </div>
                      </RenderItem>
                      <RenderItem isTrue={!serviceVisit?.vehicle && (serviceVisit?.status === Constant.serviceVisitReqStatus.OPEN || serviceVisit?.status === Constant.serviceVisitReqStatus.IN_PROGRESS)}>
                        <EmptyParking
                          showAddVehicle={showAddVehicle}
                        />
                      </RenderItem>
                      <RenderItem isTrue={serviceVisit?.vehicle && !visitCompleted}>
                        <Card
                          className="custom_collapse"
                          actions={[
                            <RenderItem isTrue={unrequestedChecklist?.length}>
                              <Collapse>
                                <Panel header="Unrequested services" key="1">
                                  {unrequestedChecklist?.map((serviceDetail: any) => (
                                    <ServiceChecklist
                                      key={serviceDetail?.feature}
                                      service={serviceDetail?.feature}
                                      isInfoRequired={false}
                                      requestInfo={null}
                                      started={false}
                                      saved
                                      savedValue={null}
                                      checkboxValue={false}
                                      completed={false}
                                      checkboxHandler={(value: any, service: any) => handleAddService(service)}
                                      completeService={() => {}}
                                      updateRequestInfo={() => {}}
                                      vehicleStatus={getLastUpdatedTime(serviceDetail?.feature)}
                                      visitStatus={visitStatus}
                                    />
                                  ))}
                                </Panel>
                              </Collapse>
                            </RenderItem>,
                          ]}
                        >
                          <div className="d-flex justify-content-between flex-wrap mb-3 mb-sm-0">
                            <div className="mb-3">
                              <div className="d-sm-flex">
                                <div>
                                  <Typography.Title level={3} className="text-capitalize mb-0 primary text-truncate w-100">
                                    Service Checklist
                                  </Typography.Title>
                                  <Typography.Paragraph className="mb-0 secondary">
                                    {serviceVisit?.visit_code}
                                  </Typography.Paragraph>
                                </div>
                                <RenderItem isTrue={serviceUnsaved}>
                                  <Typography.Text className="ms-sm-3 unsaved_text_lh secondary">Unsaved changes</Typography.Text>
                                </RenderItem>
                              </div>
                            </div>
                            <div className="d-flex ps-lg-0">
                              <RenderItem isTrue={serviceUnsaved}>
                                <div>
                                  <Button type="text" className="cancel_btn fs-16 r-bold order-2 order-sm-1" onClick={handleSaveCancel}>
                                    Cancel
                                  </Button>
                                  <Button
                                    className="yellow_bg white border-radius h-40 border-0 fs-16 r-bold order-1 order-sm-2"
                                    disabled={isLoading || !showUnsaved}
                                    onClick={saveCheckList}
                                  >
                                    Save Checklist
                                  </Button>
                                </div>
                              </RenderItem>
                              <RenderItem isTrue={showStart}>
                                <Button
                                  htmlType="submit"
                                  className="primary_bg text-white black border-radius h-40 border-0 fs-16 r-bold"
                                  disabled={isLoading}
                                  onClick={startServiceHandler}
                                >
                                  Start Service
                                </Button>
                              </RenderItem>
                              <RenderItem isTrue={visitFinished && !serviceUnsaved}>
                                <Button
                                  className="dark_green_bg white border-radius h-40 border-0 fs-16 r-bold"
                                  disabled={isLoading}
                                  onClick={handleCompleteVisit}
                                >
                                  Complete Visit
                                </Button>
                              </RenderItem>
                            </div>
                          </div>
                          {checklistShow?.map((serviceDetail: any) => (
                            <ServiceChecklist
                              key={serviceDetail?.service}
                              service={serviceDetail?.service}
                              isInfoRequired={getRequestInfoStatus(serviceDetail?.service)}
                              requestInfo={serviceDetail?.requested_info}
                              started={visitStarted}
                              saved={serviceDetail?.saved}
                              savedValue={{ value: serviceDetail?.value, unitPrice: serviceDetail?.unit_price }}
                              checkboxValue
                              checkboxHandler={(value: any, service: any) => handleRemoveService(service)}
                              completed={serviceDetail?.completed}
                              completeService={completeService}
                              updateRequestInfo={(value: any, service: string) => updateRequestedInfo(value, service)}
                              vehicleStatus={getLastUpdatedTime(serviceDetail?.service)}
                              visitStatus={visitStatus}
                              wheels={wheelNo}
                            />
                          ))}
                        </Card>
                      </RenderItem>
                      <RenderItem isTrue={visitCompleted}>
                        <Card className="dark_green_bg">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex r-bold align-items-center white">
                              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon white_bg dark_green rounded-circle">
                                <Icon className="icon-8" iconRef="#tick" />
                              </div>
                              Service visit completed
                            </div>
                            <Button className="d-flex p-0 r-bold align-items-center white border-0 shadow-none bg-transparent h-max" onClick={gotoVisitCompletedDetails}>
                              View
                              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon white_bg dark_green rounded-circle">
                                <Icon className="icon-8" iconRef="#arrow_right" />
                              </div>
                            </Button>
                          </div>
                        </Card>
                      </RenderItem>
                    </Col>
                    <Col lg={8} xs={24}>
                      <RenderItem isTrue={serviceVisit?.vehicle}>
                        <div className="mb-3">
                          <VehicleInfoCard
                            vehicle={serviceVisit?.vehicle}
                            location={serviceVisit?.vehicle?.address}
                            model={serviceVisit?.model}
                          />
                        </div>
                      </RenderItem>
                      <div className="mb-3">
                        <UserViewCard
                          clientCode={serviceVisit?.client?.client_code}
                          clientId={serviceVisit?.client?._id}
                          clientName={clientName}
                          deletedUser={deletedUser}
                          profileImageUrl={serviceVisit?.client?.profile?.fileUrl}
                        />
                      </div>
                      <ScheduleVisit
                        isResidentialPlan
                        planId={serviceVisit?.contract?._id}
                        due_date={serviceVisit?.contract?.due_date}
                        client={serviceVisit?.client?._id}
                        contractStatus={serviceVisit?.contract?.contract_status}
                        paymentStatus={serviceVisit?.contract?.payment_status}
                        visitStatus={serviceVisit?.status}
                        scheduledTime={serviceVisit?.scheduled_time}
                        visit_id={id}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <SidebarInfo infoText="See and manage the service visit" title="INFO" />
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
              <Tooltip title="View Plan">
                <Button className="d-flex primary ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0" onClick={gotoPlanDetails}>
                  View Plan
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              <RenderItem isTrue={isSuperAdmin && showCancelButton}>
                <Tooltip title="Cancel Service Visit">
                  <Button className="d-flex primary ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0" onClick={confirmCancelVisit}>
                    Cancel Service Visit
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                </Tooltip>
              </RenderItem>
            </div>
          </Sider>
        </Row>
        <ScheduleNextVisit
          isVisible={isModalVisible}
          onCancel={handleCancel}
          createServicesVisits={rescheduleVisit}
          dateRule={rule?.dateRule}
          timeRule={rule?.timeRule}
          due_date={serviceVisit?.contract?.due_date}
          form={form}
          current_schedule_time={serviceVisit?.scheduled_time}
          title="Reschedule Visit"
          buttonText="Reschedule"
          isLoading={isLoading}
        />
        <VehicleSelector
          isLoading={isLoading}
          isModalVisible={showAddVehicleModel}
          vehicleList={clientStore?.vehicles?.data}
          handleCancel={closeAddVehicle}
          handleOk={addVehicleToPlan}
          showSwapVehicle={false}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('serviceVisitStore', 'authStore', 'settingsStore', 'serviceStore')(observer(ServiceVisitDetails));
