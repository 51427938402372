/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import './Pagination.less';
import { Icon } from '..';

interface PageProps {
  onChange: (page: number) => void
  currentPage: number
  total: number
  pageSize: number
  disabled?: boolean
}

Pagination.defaultProps = {
  disabled: false,
};

function Pagination(props: PageProps) {
  const {
    currentPage, total, pageSize, onChange, disabled,
  } = props;
  const totalPage = Math.ceil(total / pageSize);
  return (
    <div>
      {totalPage > 1
            && (
            <div className="d-flex justify-content-end align-items-center mt-4">
              <span className="r-medium text-uppercase">{currentPage}/{totalPage} pages</span>
              <Button disabled={currentPage === 1 || disabled} onClick={() => onChange(currentPage - 1)} className={classNames('icon_circle text-center p-0 ms-3 me-2', { disable_btn: currentPage === 1 })} type="link" shape="circle">
                <Icon className="icon-13" iconRef="#arrow_left" />
              </Button>
              <Button disabled={currentPage === totalPage || disabled} onClick={() => onChange(currentPage + 1)} className={classNames('icon_circle text-center p-0', { disable_btn: currentPage === totalPage })} type="link" shape="circle">
                <Icon className="icon-13" iconRef="#arrow_right" />
              </Button>
            </div>
            )}
    </div>
  );
}
export default Pagination;
