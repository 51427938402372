/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Progress,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';

import { AxiosError } from 'axios';
import queryString from 'query-string';
import classnames from 'classnames';
import {
  Icon,
  UploadDocument,
  ListDocument,
  VehicleStatusCard,
  VehicleInfoCard,
  ContractActivities,
  Pagination,
  VehicleSelector,
  Toast,
  ScheduleVisit,
  UserViewCard,
  EmptyParking,
  RenderItem,
  ConfirmAdminPin,
  ButtonIcon,
  LinkButton,
  Info,
} from '../../Components';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import './ContractDetails.less';
import fileService from '../../Services/fileService';
import contractService from '../../Services/contractService';
import clientStore from '../../Store/clientStore';
import ConfirmModal from '../../Components/ConfirmModal';
import EditContract from '../../Components/EditPlan';

const { activity_type } = Constant;
const { CLOSED } = Constant.customerContract;
const { EXPIRED } = Constant.contractTypes;

function ContractDetails(props: any) {
  const {
    contractStore, authStore, settingsStore, notificationStore,
  } = props;
  const { currentUser } = authStore;
  const { settings } = settingsStore;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isShowConfirmPin, setIsShowConfirmPin] = useState<boolean>(false);
  const [showAddVehicleModel, setShowAddVehicleModel] = useState<boolean>(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [isDocUploading, setIsDocUploading] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(-1);
  const [collapsed, setCollapsed] = useState(false);
  const [showEditPlan, setShowEditPlan] = useState(false);

  const { id } = useParams<any>();
  const history = useHistory();
  const location = useLocation();

  const isSuperAdmin = currentUser?.access?.includes(Constant?.userAccess.SUPER_ADMIN);
  const contractDetails = contractStore?.contract;
  const start_date = contractDetails?.start_date;
  const due_date = contractDetails?.due_date;
  const payment_status = contractDetails?.payment_status;
  const contract_status = contractDetails?.contract_status;
  const queryParams: any = queryString.parse(location?.search);
  const defaultPlanType = queryParams?.tab || EXPIRED;
  const daysInMilliSeconds = 86400000;

  const handleCancel = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const showConfirmPin = () => setIsShowConfirmPin(true);
  const closeConfirmPin = () => setIsShowConfirmPin(false);

  const removeFileFromUploaded = async (index:number) => {
    try {
      const uploadedDoc = uploadedDocuments.filter(
        (value : any, actualIndex:number) => actualIndex !== index,
      );
      setUploadedDocuments(uploadedDoc);
      await contractService.uploadScannedCopies(id, { scanned_copies: uploadedDoc });
    } catch (error:any) {
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const uploadDocuments = async (selectedDocuments: any) => {
    try {
      setIsDocUploading(true);
      const fileFormData = new FormData();
      selectedDocuments.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      const response = await fileService.uploadFile(fileFormData);
      setUploadedDocuments([...uploadedDocuments, ...response?.data]);
      await contractService.uploadScannedCopies(
        id,
        { scanned_copies: [...uploadedDocuments, ...response?.data] },
      );
      setIsModalVisible(false);
      setIsDocUploading(false);
    } catch (error:any) {
      setIsModalVisible(false);
      setIsDocUploading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const getContractById = async () => {
    try {
      setIsLoading(true);
      await contractStore.getContractById(id);
      setUploadedDocuments(contractStore?.contract?.scanned_copies);
      setIsLoading(false);
    } catch (error:any) {
      setIsLoading(false);
      Toast.error(error);
      history.goBack();
    }
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };
  const isExpiringContract = due_date - Date.now()
  <= settings?.plan_expiring_time * daysInMilliSeconds;
  const isPlanClosed = contractDetails?.contract_status === CLOSED;

  const getContractActivities = async (sort: number) => {
    setIsLoading(true);
    await contractStore.getContractActivities({
      activity_type: [activity_type.SERVICE_ACTIVITY, activity_type.PARKING_UNPARKING],
      contract: id,
      page: pageNumber,
      order: 'createdAt',
      orderBy: sort,
      limit: Constant.activityPerPage,
    })
      .then(() => setIsLoading(false))
      .catch((error:AxiosError) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const sortActivity = () => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    getContractActivities(nextSort);
  };

  useEffect(() => { getContractActivities(orderBy); }, [pageNumber]);

  const getClientVehicles = async () => {
    try {
      setIsLoading(true);
      await clientStore.getClientVehicle({
        clientId: contractDetails?.client?._id, sharedVehicle: false,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(true);
      Toast.error(error);
    }
  };

  useEffect(() => {
    if (contractDetails?.client?._id) {
      getClientVehicles();
    }
  }, [contractDetails?.client?._id]);

  useEffect(() => {
    getContractById();
    getSettings();
    getContractActivities(orderBy);
  }, []);

  const contractStatus = Utility.getContractStatus(due_date, payment_status, contract_status);
  const percentage = Utility.calculateDatePercentage(start_date, due_date, isPlanClosed);
  const percentColor = Utility.getPercentageColor(percentage);
  const isUploadDisabled = uploadedDocuments?.length >= 2;

  const isPaymentPending = !contractDetails?.payment_status
  && contractStatus === Constant.customerContract.APPROVED && !isPlanClosed && !isExpiringContract;

  const gotoRenewPage = () => history.push(`${Routes.renew_plan}/${contractDetails?._id}?tab=${defaultPlanType}`);
  const gotoPaymentPage = () => history.push(`${Routes.payment_plan}/${contractDetails?._id}?newPlan=true&tab=${defaultPlanType}`);

  const isResidentialPlan = contractDetails?.contract?.plan_type === 'RESIDENTIAL';
  const showVehicleCard = !isPlanClosed && contractDetails?.active_vehicle?._id;
  const isPlanExpired = contractStatus === Constant.customerContract.EXPIRED;
  const addVehicle = !isPlanClosed && !isPlanExpired
  && !contractDetails?.active_vehicle
  && isResidentialPlan;

  const expiredText = isPlanExpired ? 'Expired on' : 'Expire on';

  // plan can be closed if plan is expired and (plan is vault and no vehicle or plan is residential)
  const planCloseable = (isPlanExpired && !isPlanClosed)
  && ((!isResidentialPlan && !showVehicleCard) || isResidentialPlan) && isSuperAdmin;

  const closePlanTooltip = !planCloseable ? 'This plan have parked vehicle. Please unpark the vehicle before closing the plan.' : 'Close Plan';

  const vehicleAddress = contractDetails?.contract?.plan_type === Constant.plan_type?.VAULT
    ? null : contractDetails?.active_vehicle?.address;

  const deletedUser = contractDetails?.client?.deleted;

  const showAddVehicle = () => setShowAddVehicleModel(true);
  const closeAddVehicle = () => setShowAddVehicleModel(false);
  const addVehicleToPlan = async (value: any) => {
    try {
      setIsLoading(true);
      await contractStore.addVehicleToPlan({
        client: contractDetails?.client?._id, vehicle: value?.vehicle, planId: contractDetails?._id,
      });
      setIsLoading(false);
      setShowAddVehicleModel(false);
      getContractById();
      getClientVehicles();
      getContractActivities(orderBy);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const closePlan = async () => {
    try {
      setIsLoading(true);
      await contractService.closePlan(id);
      getContractById();
      setFetchData(true);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const gotoPlans = () => {
    const { from, id: referenceId } = queryParams;
    if (from === 'CLIENT') {
      history.replace(`${Routes.client_detail}/${referenceId}`);
    } else if (from === 'PLAN_HISTORY') {
      history.replace(Routes.plan_history);
    } else {
      history.replace(`${Routes.contracts}?tab=${defaultPlanType}`);
    }
  };

  const hardDeletePlan = async (admin_pin: any) => {
    try {
      setIsDeleteLoading(true);
      await contractService.hardDeletePlan(id, { super_admin_pin: admin_pin });
      await notificationStore?.getNotifications(1, Constant.notificationPerPage);
      gotoPlans();
      setIsDeleteLoading(false);
    } catch (error: any) {
      setIsDeleteLoading(false);
      Toast.error(error);
    }
  };

  const closePlanHandler = () => {
    if (!isResidentialPlan && contractDetails?.active_vehicle) return Toast.error('This plan have parked vehicle. Please unpark the vehicle before closing the plan.');
    let confirmModelText = '';
    if (contractDetails?.active_vehicle) {
      confirmModelText = 'Vehicle in this plan will be removed, Are you sure you want to close this plan?';
    } else {
      confirmModelText = 'Are you sure you want to close this plan?';
    }
    ConfirmModal('Close plan', confirmModelText, () => closePlan(), 'Close plan');
    return null;
  };

  const deletingInfo = (
    <Tooltip title="Service Requests, Plan Request, Service Visits, Notifications, Parking Logs, Plan Transactions.">
      <span>
        <Icon className="icon-16" iconRef="#info" />
      </span>
    </Tooltip>
  );

  const hardDeletePlanBody = (
    <div>
      All the data {deletingInfo} associated with this plan will be get removed permanently along with this plan. Are you sure you want to delete this plan?
    </div>
  );

  const clientName = Utility.getUserName(contractDetails?.client);

  const showSwapVehicle = !isPlanClosed && !isPlanExpired && isResidentialPlan && contractDetails?.active_vehicle;
  const showClosePlan = !isPlanClosed && isPlanExpired && isSuperAdmin;
  const showUnparkVehicle = !isResidentialPlan && (!!contractDetails?.active_vehicle);
  const showAction = (showClosePlan || (showUnparkVehicle) || showSwapVehicle) && !isPlanClosed;

  const showUpdateContract = () => setShowEditPlan(true);

  const menu = (
    <Menu className="box_shadow border-radius">
      <RenderItem isTrue={isSuperAdmin}>
        <Menu.Item key="2" className="disabled_btn">
          <Tooltip title="Delete Plan Permanently">
            <Button onClick={showConfirmPin} className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto resp_req_btn">
              <span className="text-truncate w-65">Delete Plan Permanently</span>
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Button>
          </Tooltip>
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={showClosePlan}>
        <Menu.Item key="0" className="disabled_btn">
          <ButtonIcon
            onClick={closePlanHandler}
            text="Close Plan"
            disableBtn={!planCloseable}
            showIcon={planCloseable}
            tooltipText={closePlanTooltip}
            className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto"
          />
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={isSuperAdmin}>
        <Menu.Item key="0" className="disabled_btn">
          <ButtonIcon
            onClick={showUpdateContract}
            text="Update Plan"
            className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto"
          />
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={showUnparkVehicle}>
        <Menu.Item key="1">
          <LinkButton
            route={`${Routes.client_park_unpark}/${contractDetails?.active_vehicle?._id}`}
            text="Unpark Vehicle"
            className="d-flex ml-auto r-bold align-items-center p-0 link_tex"
          />
        </Menu.Item>
      </RenderItem>
      <Menu.Item key="2">
        <RenderItem isTrue={showSwapVehicle}>
          <ButtonIcon
            onClick={showAddVehicle}
            text="Swap Vehicle"
            className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto"
          />
        </RenderItem>
      </Menu.Item>
    </Menu>
  );

  const closeEditContract = () => {
    setShowEditPlan(false);
  };

  const updateContract = async (contractForm: any) => {
    try {
      setIsLoading(true);
      await contractService.updateContract(id, contractForm);
      setIsLoading(false);
      getContractById();
      closeEditContract();
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-0">
                <Breadcrumb.Item>
                  <RenderItem isTrue={!isPlanClosed}>
                    <Link to={`${Routes.contracts}?tab=${defaultPlanType}`}>Plans</Link>
                  </RenderItem>
                  <RenderItem isTrue={isPlanClosed}>
                    <Link to={`${Routes.plan_history}?tab=${defaultPlanType}`}>Plan History</Link>
                  </RenderItem>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{contractDetails?.contract_code}</Breadcrumb.Item>
              </Breadcrumb>
              <RenderItem isTrue={showAction || isSuperAdmin}>
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </RenderItem>
            </div>
            <div className="mb-3">
              {isPlanClosed && (
              <div className="d-flex align-items-center w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {contractDetails?.contract_code}
                </Typography.Title>
                <div className="label_inactive rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">
                  Closed
                </div>
              </div>
              )}
              {!isPlanClosed && (
              <div className="d-flex align-items-center w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {contractDetails?.contract_code}
                </Typography.Title>
                {contractStatus === Constant?.customerContract?.ACTIVE
                      && <div className="label_success rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Active</div>}
                {contractStatus === Constant.customerContract.EXPIRED
                     && <div className="label_danger rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Expired</div>}
                {contractStatus === Constant.customerContract.APPROVED
                     && <div className="label_success rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Payment Pending</div>}
                {contractStatus === Constant.customerContract.REJECTED
                     && <div className="label_warning rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Rejected</div>}
                {contractStatus === Constant.customerContract.REQUESTED
                     && <div className="label_warning rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Requested</div>}
              </div>
              )}
              <Row gutter={[16, 16]}>
                <Col lg={16} xs={24}>
                  <Card className="h-100">
                    <div className="position-relative">
                      {contractDetails?.duration
                      && (
                      <div className={classnames('plan_info_img exo_fnt position-absolute position-xs-relative d-flex align-items-center justify-content-start ps-2', { 'fs-60': (Utility?.reverseDuration(contractDetails?.duration, contractDetails?.duration_type) as number >= 10) })}>
                        {Utility?.reverseDuration(contractDetails?.duration, contractDetails?.duration_type)}<sub className="r-medium secondary fs-16 roboto position-absolute">{Constant?.durationTypeName.get(contractDetails?.duration_type)}</sub>
                      </div>
                      )}
                      <div className="plan_info_pad ps_xs_0 pt-3 pt-sm-0">
                        {contractDetails?.contract?.plan
                        && (
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <Typography.Title level={3} className="mb-0 fs-xs-20 text-truncate me-2">
                            {`${contractDetails?.contract?.plan} Plan`}
                          </Typography.Title>
                          { (!isPlanClosed && isExpiringContract) && isSuperAdmin
                          && !deletedUser && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center primary border-0 shadow-none" onClick={gotoRenewPage}>
                            Renew
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}
                          { isPaymentPending && isSuperAdmin && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center primary border-0 shadow-none" onClick={gotoPaymentPage}>
                            Make payment
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}

                        </div>
                        )}
                        {contractDetails?.payment_status
                  && (
                  <div>
                    <Progress
                      className="contract_progress"
                      percent={percentage}
                      strokeColor={percentColor}
                      trailColor="#f2f2f2"
                      strokeWidth={24}
                      showInfo={false}
                    />
                      { !isPlanClosed && (
                      <div className="d-sm-flex justify-content-sm-between mt-3">
                        <Typography.Paragraph
                          className="secondary fs-16 mb-0 text-truncate me-2"
                          title={`${expiredText} ${Utility.getFormattedDate(contractDetails?.due_date)}`}
                        >{expiredText} {Utility.getFormattedDate(contractDetails?.due_date)}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                          className="secondary fs-16 mb-0 text-sm-end text-truncate"
                          title={`Purchased on ${Utility.getFormattedDate(contractDetails?.start_date)}`}
                        >Purchased on {Utility.getFormattedDate(contractDetails?.start_date)}
                        </Typography.Paragraph>
                      </div>
                      )}
                      { isPlanClosed && (
                      <div className="d-flex justify-content-between mt-3">
                        <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2" title={`Closed on ${Utility.getFormattedDate(contractDetails?.updatedAt)}`}>
                          Closed on {Utility.getFormattedDate(contractDetails?.closed_date || contractDetails?.updatedAt)}
                        </Typography.Paragraph>
                      </div>
                      )}
                  </div>
                  )}
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={8} xs={24}>
                  <UserViewCard
                    clientCode={contractDetails?.client?.client_code}
                    clientId={contractDetails?.client?._id}
                    clientName={clientName}
                    profileImageUrl={contractDetails?.client?.profile?.fileUrl}
                  />
                </Col>
                <Col lg={24} xs={24}>

                  <Row gutter={16}>
                    <Col lg={16} xs={24}>
                      { showVehicleCard && (
                      <div className="mb-3">
                        <VehicleStatusCard
                          vehicleId={contractDetails?.active_vehicle?._id}
                          vehicle={contractDetails?.active_vehicle}
                          facility={contractDetails?.facility}
                          clientEmail={contractDetails?.client?.email}
                          clientPhone={contractDetails?.client?.phone}
                          contract={contractDetails}
                        />
                      </div>
                      )}
                      <RenderItem isTrue={addVehicle}>
                        <EmptyParking
                          showAddVehicle={showAddVehicle}
                        />
                      </RenderItem>
                      <div className="mb-3">
                        <ContractActivities
                          activities={contractStore?.activities}
                          isLoading={isLoading}
                          sortActivity={sortActivity}
                          orderBy={orderBy}
                        />
                        <Pagination
                          total={contractStore?.activityTotal}
                          pageSize={Constant.activityPerPage}
                          currentPage={Number(pageNumber)}
                          onChange={(page: number) => setPageNumber(page)}
                        />
                      </div>
                    </Col>
                    <Col lg={8} xs={24}>
                      { showVehicleCard && (
                        <div className="mb-3">
                          <VehicleInfoCard
                            vehicle={contractDetails?.active_vehicle}
                            location={vehicleAddress}
                          />
                        </div>
                      )}
                      <ScheduleVisit
                        isResidentialPlan={isResidentialPlan}
                        planId={id}
                        due_date={due_date}
                        client={contractDetails?.client?._id}
                        contractStatus={contractDetails?.contract_status}
                        paymentStatus={contractDetails?.payment_status}
                        moveToDetailPage
                        fetchData={fetchData}
                      />
                      <Card bordered={false} className="detail_card_pad">
                        <div className="d-flex justify-content-between text-secondary">
                          <Typography.Title level={4}>Scanned Copies<br /> <span className="secondary fs-14">(Maximum 2 files)</span></Typography.Title>
                          { !isPlanClosed && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center back_link primary" type="link" disabled={isUploadDisabled} onClick={showModal}>
                            Upload
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}
                        </div>
                        { uploadedDocuments?.length > 0
                    && (
                    <ListDocument
                      documents={uploadedDocuments}
                      size={24}
                      removeFile={(index: number) => removeFileFromUploaded(index)}
                    />
                    )}
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Info
            infoDetail="See and manage the plans"
            setCollapsed={setCollapsed}
          >
            <RenderItem isTrue={showAction || isSuperAdmin}>
              <div className="disabled_btn">
                <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
                <RenderItem isTrue={isSuperAdmin}>
                  <ButtonIcon
                    className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto resp_req_btn"
                    onClick={showConfirmPin}
                    text={<span className="text-truncate w-65">Delete Plan Permanently</span>}
                    tooltipText="Delete Plan Permanently"
                  />
                </RenderItem>
                <RenderItem isTrue={isSuperAdmin}>
                  <ButtonIcon
                    onClick={showUpdateContract}
                    text="Update Plan"
                  />
                </RenderItem>
                <RenderItem isTrue={showClosePlan && isSuperAdmin}>
                  <ButtonIcon
                    onClick={closePlanHandler}
                    text="Close Plan"
                    disableBtn={!planCloseable}
                    showIcon={planCloseable}
                    tooltipText={closePlanTooltip}
                  />
                </RenderItem>
                <RenderItem isTrue={showUnparkVehicle}>
                  <LinkButton
                    route={`${Routes.client_park_unpark}/${contractDetails?.active_vehicle?._id}`}
                    text=" Unpark Vehicle"
                  />
                </RenderItem>
                <RenderItem isTrue={showSwapVehicle}>
                  <ButtonIcon
                    onClick={showAddVehicle}
                    text="Swap Vehicle"
                  />
                </RenderItem>
              </div>
            </RenderItem>
          </Info>
        </Row>
        <UploadDocument
          fileMaxCount={2}
          isModalVisible={isModalVisible}
          handleModelCancel={handleCancel}
          uploadDocuments={uploadDocuments}
          uploadedDocuments={uploadedDocuments}
          isLoading={isDocUploading}
          acceptedExtension=".pdf,.doc,.jpeg,.jpg,.png"
        />
        <VehicleSelector
          isLoading={isLoading}
          isModalVisible={showAddVehicleModel}
          vehicleList={clientStore?.vehicles?.data}
          handleCancel={closeAddVehicle}
          handleOk={addVehicleToPlan}
          contractId={id}
          showSwapVehicle={showSwapVehicle}
          current_vehicle={contractDetails?.active_vehicle?._id}
        />
        <ConfirmAdminPin
          isModalVisible={isShowConfirmPin}
          onCancel={closeConfirmPin}
          onOk={hardDeletePlan}
          isLoading={isDeleteLoading}
          title={Constant.hardDeletePlanHeading}
          description={hardDeletePlanBody}
          okButtonText="Continue"
          okButtonStyle="red_bg h-40 border-radius me-3 fs-16 r-bold white border-0"
        />
        <RenderItem isTrue={showEditPlan}>
          <EditContract
            contractId={id}
            isLoading={isLoading}
            isModalVisible={showEditPlan}
            onCancel={closeEditContract}
            onOk={updateContract}
            selectedPlan={contractDetails?.contract?._id}
            clientId={contractDetails?.client?._id}
          />
        </RenderItem>
      </Spin>
    </Layout.Content>
  );
}

export default inject('contractStore', 'authStore', 'settingsStore', 'notificationStore')(observer(ContractDetails));
