/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Tooltip,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { Icon, RenderItem } from '..';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;

interface IUserViewCard{
    clientName: string
    profileImageUrl: string
    deletedUser?: boolean
    clientCode: string
    clientId: string
}

UserViewCard.defaultProps = {
  deletedUser: false,
};

function UserViewCard(props: IUserViewCard) {
  const {
    clientName, profileImageUrl, deletedUser, clientCode, clientId,
  } = props;

  const history = useHistory();

  const gotoCLient = () => history.push(`${Routes.client_detail}/${clientId}`);

  return (
    <Card>
      <div className="d-sm-flex align-items-center position-relative">
        <div className="large_icn_bg p-0 ms-sm-0 m-auto me-sm-0 rounded-circle justify-content-center d-flex align-items-center sec_info_img position-absolute position-xs-relative">
          {profileImageUrl
            ? <img className="large_icn_bg rounded-circle align-items-center position-absolute" src={profileImageUrl} alt="" />
            : <Icon className="icon-40" iconRef="#User" />}

        </div>
        <RenderItem isTrue={!!clientCode}>
          <div className="sec_info_pad d-flex align-items-center w-100 lg_icon_info_ht ps_xs_0 text-center text-sm-start">
            <Typography className="w-100">
              <Title
                level={3}
                className="text-truncate text-capitalize primary r-bold mb-0"
                title={clientName}
              >
                {clientName}
              </Title>
              <Paragraph className="secondary mb-0">Client ID - {clientCode}</Paragraph>
            </Typography>
          </div>
        </RenderItem>
      </div>
      <div className="sec_info_pad ps_xs_0 d-flex align-items-center justify-content-center justify-content-sm-start">
        <Button disabled={deletedUser} className="d-flex p-0 r-bold align-items-center primary border-0 shadow-none bg-transparent" onClick={gotoCLient}>
          View
          <RenderItem isTrue={!deletedUser}>
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </RenderItem>
        </Button>
        <RenderItem isTrue={deletedUser}>
          <Tooltip title="User deleted">
            <div className="lh_0">
              <Icon className="icon-16 ms-2" iconRef="#info" />
            </div>
          </Tooltip>
        </RenderItem>
      </div>
    </Card>
  );
}

export default UserViewCard;
