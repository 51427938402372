/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React from 'react';
import { Card, Typography } from 'antd';

import Utility from '../../Global/Utility';
import { Icon } from '..';

interface WaitingForPaymentProps{
  time: Date,
}

function WaitingForPayment(props: WaitingForPaymentProps) {
  const { time } = props;
  const relativeTime = Utility?.getRelativeDate(time);
  return (
    <Card className="yellow_bg border-radius mb-3 shadow">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-sm-flex">
          <div className="large_icn_bg cir_outline yellow white_bg p-0 rounded-circle justify-content-center d-flex align-items-center me-5">
            <Icon className="icon-40" iconRef="#clock" />
          </div>
          <div className="d-flex align-items-center w-100 lg_icon_info_ht mt-3 mt-sm-0">
            <Typography className="w-100">
              <Typography.Title level={3} className="text-truncate r-bold mb-0 white fs-xs-18" title="Alex Tom">Waiting for payment</Typography.Title>
              <Typography.Paragraph className="white fs-16">Link shared {relativeTime}</Typography.Paragraph>
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default WaitingForPayment;
