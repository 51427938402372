/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import {
  CompactableProducts,
  Icon, RenderItem, Toast,
} from '../../Components';

import './VehicleDatabase.less';
import BreadCrumb from './BreadCrumb';
import modelStore from '../../Store/modelStore';
import Utility from '../../Global/Utility';
import confirmModal from '../../Components/ConfirmModal';
import Constant from '../../Global/Constant';
import vehicleStore from '../../Store/vehicleStore';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function VehicleDatabase() {
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [currentCategory, setCurrentCategory] = useState<any>('type'); // hold the id of last item in the bread crumb
  const [currentCategoryType, setCurrentCategoryType] = useState<any>('type'); // hold the name of last item in the bread crumb

  const [selectedType, setSelectedType] = useState<any>(null); // hold drop down id of initial category
  const [selectedTypeName, setSelectedTypeName] = useState<any>(null); // hold drop down name of initial category

  const [secondColumnTitle, setSecondColumnTitle] = useState<any>('Makes');

  const [category, setCategory] = useState<any>(null);
  const [make, setMake] = useState<any>(null);
  const [model, setModel] = useState<any>(null);
  const [subModel, setSubModel] = useState<any>(null);
  const [year, setYear] = useState<any>(null);

  const [makeName, setMakeName] = useState<any>(null);
  const [modelName, setModelName] = useState<any>(null);
  const [subModelName, setSubModelName] = useState<any>(null);

  const [models, setModels] = useState<any>([]);

  const [itemCount, setItemCount] = useState<any>(0); // hold the count of items present
  const [breadCrumbItems, setBreadCrumbItems] = useState<any>([{ title: 'Type', id: 1, type: 'type' }]);
  const [currentGroup, setCurrentGroup] = useState<any>([]);

  const [showDropDown, setShowDropDown] = useState<boolean>(false);

  const [secondRawName, setSecondRawName] = useState<any>(null);

  const [subOrYear, setSubOrYear] = useState<any>('');

  const [showCreateAction, setShowCreateAction] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<any>(null);

  const [currentProductLink, setCurrentProductLink] = useState<any>(null);

  const [categoryProductCount, setCategoryProductCount] = useState<number>(0);

  const [makeProductCount, setMakeProductCount] = useState<any>([]);

  const [modelProductCount, setModelProductCount] = useState<any>([]);

  const [subModelProductCount, setSubModelProductCount] = useState<any>([]);

  const [yearProductCount, setYearProductCount] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedVariants, setSelectedVariants] = useState<any>([]);

  const [showUpdateModel, setShowUpdateModel] = useState<any>(false);

  const [updateText, setUpdateText] = useState<any>('');

  const [currentCategoryInfo, setCurrentCategoryInfo] = useState<any>('');

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [isModelLoading, setIsModelLoading] = useState<boolean>(false);

  const [categories, setCategories] = useState<any>(null);

  const [otherLoading, setOtherLoading] = useState<any>(false);

  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);


  const getCategories = async () => {
    try {
      setOtherLoading(true);
      await vehicleStore?.getVehicleCategory();
    } catch (error: any) {
      setOtherLoading(false);
      Toast?.error(error);
    }
  }

  useEffect(() => {
    if (vehicleStore?.vehicleCategories) {
      setCategories(vehicleStore?.vehicleCategories?.map((item: any) => ({ name: item?.name, type: item?.category })))
    }
  }, [vehicleStore?.vehicleCategories]);

  useEffect(() => {
    if (categories?.length) {
      setOtherLoading(false);
    }
  }, [categories]);

  useEffect(() => {
    getCategories();
  }, [])
  const handleEntityInput = (e: any) => {
    setUpdateText(Utility.trimSpacesInValues(e?.target?.value));
  }

  const fetchModels = async () => {
    try {
      setIsLoading(true);
      const response = await modelStore?.fetchModels();
      setModels(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  const refreshProductCount = async (currCat: any) => {
    try {
      setCategoryLoading(true);
      if (currCat === 'category') {
        const count: any = await fetchProductModelsCount({ category });
        setCategoryProductCount(count);
      } else if (currCat === 'make') {
        const makeGroup = groupBy(models?.filter((item: any) => item?.category === category), 'company');
        const idArray = []
        const countArray: any = []
        for (const key of Object.keys(makeGroup)) {
          idArray.push(makeGroup[key][0]?.company_details?._id)
        }

        await Promise.all(idArray.map(async (item: any) => {
          const count = await modelStore?.fetchModelVariantsCount({ category, companyId: item });
          countArray.push({ id: item, count: count || 0 });
        }))
        setMakeProductCount(countArray);

      } else if (currCat === 'model') {
        const makeGroup = groupBy(models?.filter((item: any) => !!item?.company && item?.company_details?._id === make), 'model');

        const idArray = []
        const countArray: any = []
        for (const key of Object.keys(makeGroup)) {
          idArray.push(makeGroup[key][0]?.model_details?._id)
        }

        await Promise.all(idArray.map(async (item: any) => {
          const count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: item });
          countArray.push({ id: item, count: count || 0 });
        }))
        setModelProductCount(countArray);

      } else if (currCat === 'subModel') {
        const makeGroup = groupBy(models?.filter((item: any) => item?.category === category && !!item?.company && item?.subModel && item?.company_details?._id === make && item?.model_details?._id === model), 'subModel');
        const idArray = []
        const countArray: any = []
        for (const key of Object.keys(makeGroup)) {
          idArray.push(makeGroup[key][0]?.sub_model_details?._id)
        }

        await Promise.all(idArray.map(async (item: any) => {
          const count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: model, subModelId: item });
          countArray.push({ id: item, count: count || 0 });
        }))
        setSubModelProductCount(countArray);
      } else if (currCat === 'year') {
        let makeGroup;
        if (subModel) {
          makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model && item?.sub_model_details?._id === subModel), 'manufacture_year');

          const idArray = []
          const countArray: any = []
          for (const key of Object.keys(makeGroup)) {
            idArray.push(makeGroup[key][0]?.manufacture_year_details?._id)
          }

          await Promise.all(idArray.map(async (item: any) => {
            const count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: model, subModelId: subModel, yearId: item });
            countArray.push({ id: item, count: count || 0 });
          }))
          setYearProductCount(countArray);

        } else {
          makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model), 'manufacture_year');

          const idArray = []
          const countArray: any = []
          for (const key of Object.keys(makeGroup)) {
            idArray.push(makeGroup[key][0]?.manufacture_year_details?._id)
          }

          await Promise.all(idArray.map(async (item: any) => {
            const count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: model, yearId: item });
            countArray.push({ id: item, count: count || 0 });
          }))
          setYearProductCount(countArray);
        }
      }
      setCategoryLoading(false);
    } catch (error: any) {
      setCategoryLoading(false);
      Toast?.error(error);
    }
  };

  const linkProductHandler = async (variantList: any, breadCrumblist: any) => {
    try {
      setIsModelLoading(true);
      const data = {
        compactible_items: variantList,
        company: make,
        model: model,
        manufacture_year: year,
        category,
        sub_model: subModel,
      };

      if (breadCrumblist?.type === 'category') {
        data.category = breadCrumblist?.id;
      }

      if (breadCrumblist?.type === 'make') {
        data.company = breadCrumblist?.id;
      }

      if (breadCrumblist?.type === 'model') {
        data.model = breadCrumblist?.id;
      }

      if (breadCrumblist?.type === 'subModel') {
        data.sub_model = breadCrumblist?.id;
      }
      if (breadCrumblist?.type === 'year') {
        data.manufacture_year = breadCrumblist?.id;
      }

      await modelStore?.linkProductHandler(data)
      setIsModelLoading(false);
      handleCancel();
      await refreshProductCount(currentCategoryType);
    } catch (error: any) {
      setIsModelLoading(false);
      Toast.error(error);
    }
  };

  const showModal = async (currentItemDetails: any) => {
    try {
      setIsLoading(true);
      const data: any = {
        category,
        companyId: make,
        modelId: model,
        subModelId: subModel,
        yearId: year
      }

      const type = currentItemDetails?.type;
      const itemId = currentItemDetails?.id;
      if (type === 'category') {
        data.category = itemId;
      } else if (type === 'make') {
        data.companyId = itemId;
      } else if (type === 'model') {
        data.modelId = itemId;
      } else if (type === 'subModel') {
        data.subModelId = itemId;
      } else if (type === 'year') {
        data.yearId = itemId;
      }

      const variantList = await modelStore?.fetchModelVariants(data);
      const variantIdArray: any = [];
      variantList?.forEach((item: any) => {
        variantIdArray.push(item?.variant?._id)
      });

      setSelectedVariants(variantIdArray);
      setCurrentProductLink({ title: currentItemDetails?.name, id: currentItemDetails?.id, type: currentItemDetails?.type });
      setIsLoading(false);
      setIsModalVisible(true);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentProductLink(null);
  };

  const groupBy = function (xs: any, key: any) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const { Option } = Select;

  const getCategoryNameFromType = (type: any): string => {
    if (type === 'type') return 'Type';
    if (type === 'make') return 'Makes';
    if (type === 'model') return 'Models';
    if (type === 'subModel') return 'Sub-Models';
    if (type === 'year') return 'Years';
    return 'Type';
  };

  const getCategoryNameFromTypeSingle = (type: any): string => {
    if (type === 'type') return 'Type';
    if (type === 'make') return 'Make';
    if (type === 'model') return 'Model';
    if (type === 'subModel') return 'Sub-Model';
    if (type === 'year') return 'Year';
    return 'Type';
  };

  const getNextBreadCrumbType = (prevType: any) => {
    if (prevType === 'type') return 'category';
    if (prevType === 'category') return 'make';
    if (prevType === 'make') return 'model';
    if (prevType === 'model') return 'subModel';
    if (prevType === 'subModel') return 'year';
  };

  const subOrYearTitle = currentCategoryType === 'subModel' ? 'Sub-Models' : 'Years';
  const firstColumn = {
    title:
      <div className="d-flex justify-content-between align-items-center">
        {/* <SortTh
          title={
            currentCategoryType === 'subModel' || currentCategoryType === 'year' ? subOrYearTitle
              : getCategoryNameFromType(currentCategoryType)

          }
          dataIndex="createdAt"
          orderBy={1}
          order={' '}
        /> */}
        {currentCategoryType === 'subModel' || currentCategoryType === 'year' ? subOrYearTitle
          : getCategoryNameFromType(currentCategoryType)}
        {/* <Button
    className="d-flex justify-content-center align-items-center
      text-center p-0 icon_circle ms-sm-3 mt-2 mt-sm-0"
    type="link"
    shape="circle"
  >
    <Icon className="icon-13" iconRef="#edit" />
  </Button> */}
      </div>,
    dataIndex: 'type',
    ellipsis: true,
  };

  const handleCategoryOnChange = (e: any) => {
    if (e === 'subModel') {
      setSecondColumnTitle('Sub-models');
      setSecondRawName('subModel');
    } else {
      setSecondColumnTitle('Years');
      setSecondRawName('year');
    }
    setSelectedValue(e);
  };

  const getSecondColumnTitle = () => {
    if (currentCategoryType === 'year') {
      return '';
    }
    if (currentCategoryType !== 'model') {
      return secondColumnTitle;
    }
    if (showDropDown) {
      return (
        <Select
          suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
          placeholder={<div className="r-bold primary">Select Category</div>}
          className="slct_style w-100"
          dropdownClassName="slct_drp_dwn border-radius"
          onChange={handleCategoryOnChange}
          value={selectedValue}
        >
          <Option value="subModel">Sub-Model</Option>
          <Option value="year">Year</Option>
        </Select>
      );
    }
    if (subOrYear === 'subModel') return 'Sub-Models';
    if (subOrYear === 'manufacture_year') return 'Years';
  };

  const secondColumn = {
    title:
      <div className="d-flex justify-content-between align-items-center">
        {getSecondColumnTitle()}
      </div>,
    dataIndex: 'secondColumn',
    ellipsis: true,
  };

  const thirdColumn = {
    title:
      <div className="d-flex justify-content-between align-items-center">
        Compatible Products
      </div>,
    dataIndex: 'product',
    ellipsis: true,
  };

  let columns = []
  if (currentCategoryType === 'year') {
    columns = [
      firstColumn,
      thirdColumn,
      {
        title: 'Action',
        dataIndex: 'action',
        ellipsis: true,
      },
    ];
  } else {
    columns = [
      firstColumn,
      secondColumn,
      thirdColumn,
      {
        title: 'Action',
        dataIndex: 'action',
        ellipsis: true,
      },
    ];
  }

  useEffect(() => {
    if (category) {
      handleInitialTypeChange(category);
    }
  }, [category]);

  const handleInitialTypeChange = async (e: any) => {
    try {
      setCategoryLoading(true);
      setSelectedType(e);
      setCurrentCategory(e);
      setCategory(e);
      setCurrentCategoryType('category');
      setSelectedTypeName(categories?.find((item: any) => item?.type === e)?.name);
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === e), 'company');
      setCurrentGroup(makeGroup);
      setItemCount(Object?.keys(makeGroup)?.length || 0);
      const idArray = []
      const countArray: any = []
      for (const key of Object.keys(makeGroup)) {
        idArray.push(makeGroup[key][0]?.company_details?._id)
      }
      const count: any = await fetchProductModelsCount({ category: e });
      setCategoryProductCount(count);
      await Promise.all(idArray.map(async (item: any) => {
        const count = await modelStore?.fetchModelVariantsCount({ e, companyId: item });
        countArray.push({ id: item, count: count || 0 });
      }))
      setMakeProductCount(countArray);
      setCategoryLoading(false);
    } catch (error: any) {
      setCategoryLoading(false);
      Toast?.error(error);
    }

  };

  const handleInputOnchange = (e: any) => {
    if (e?.target?.value?.trim()) {
      setInputValue(Utility.trimSpacesInValues(e?.target?.value));
      setShowCreateAction(true);
    } else {
      setInputValue(null);
      setShowCreateAction(false);
    }
  };

  const addNoSubmodel = () => {
    createModel(true);
  }

  // type selection handling
  const optionalValues = currentCategoryType === 'type' || currentCategoryType === 'category'
    ? (
      <Select
        value={selectedType}
        onChange={handleInitialTypeChange}
        suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
        placeholder={<div className="r-bold primary">Select Type</div>}
        className="slct_style w-100"
        dropdownClassName="slct_drp_dwn border-radius"
      >
        {categories?.map((item: any) => (
          <Option key={item?.type} value={item?.type}>
            <div className="d-flex justify-content-between">
              <div className="text-truncate">
                {item?.name}
              </div>
            </div>
          </Option>
        ))}
      </Select>
    )
    : <>
    <Input
      value={inputValue}
      maxLength={currentCategoryType === 'year' ? 4 : 50}
      onChange={handleInputOnchange}
      placeholder={`Create a ${getCategoryNameFromTypeSingle(currentCategoryType)}`}
      className="inp_style" />
      <div style={{"textAlign": "right"}}>
      <RenderItem isTrue={currentCategoryType === 'subModel'}>
          <Button className='ant-btn ant-btn-text cancel_btn r-bold' onClick={addNoSubmodel}><Typography.Text>+ No Submodel</Typography.Text></Button>
        
      </RenderItem>
          </div>
      </>;

  const setCategoryValuesForDb = (categoryDetail: any) => {
    const type = categoryDetail?.type;
    const catId = categoryDetail?.id;
    if (type === 'make') {
      setMake(catId);
    }
  };

  const handleGoingInside = async (categoryDetail: any) => {
    try {
      setIsLoading(true);
      setInputValue(null);
      setShowCreateAction(false);
      setCategoryValuesForDb(categoryDetail);
      const nextBreadCrumb: any = {
        id: currentCategory,
        title: null,
        type: getNextBreadCrumbType(breadCrumbItems[breadCrumbItems?.length - 1]?.type),
      };
      if (currentCategoryType === 'category') {
        nextBreadCrumb.title = categories?.find((item: any) => item?.type === currentCategory)?.name;
        nextBreadCrumb.type = 'category';
      } else if (currentCategoryType === 'make') {
        const fieldDetails: any = getFieldName();
        const makeArray = models?.filter((item: any) => item?.company_details?._id === categoryDetail?.prevId);
        let submodelExist = false;
        let yearExist = false;
        makeArray.forEach((item: any) => {
          if (item?.subModel) {
            submodelExist = true;
          }
          if (item?.manufacture_year) {
            yearExist = true;
          }
        });

        if (!(submodelExist || yearExist)) {
          setSelectedValue(null);
          setShowDropDown(true);
        } else {
          setShowDropDown(false);
        }

        if (submodelExist) {
          setSubOrYear('subModel');
        } else if (yearExist) {
          setSubOrYear('manufacture_year');
        }

        nextBreadCrumb.title = categoryDetail?.name;
        nextBreadCrumb.type = 'make';
        setMakeName(categoryDetail?.name);
        setMake(categoryDetail?.prevId);
        const makeGroup = groupBy(models?.filter((item: any) => category === item?.category && !!item.model && item[`${fieldDetails?.detailsIn}`]?._id === categoryDetail?.prevId), 'model');
        setCurrentGroup(makeGroup);

        const idArray = []
        const countArray: any = []
        for (const key of Object.keys(makeGroup)) {
          idArray.push(makeGroup[key][0]?.model_details?._id)
        }
        await Promise.all(idArray.map(async (item: any) => {
          const count = await modelStore?.fetchModelVariantsCount({ category, companyId: categoryDetail?.prevId, modelId: item });
          countArray.push({ id: item, count: count || 0 });
        }))
        setModelProductCount(countArray);
      } else if (currentCategoryType === 'model') {
        let submodelExist = false;
        let yearExist = false;

        if (secondRawName === 'subModel') {
          setSecondColumnTitle('Years');
          nextBreadCrumb.title = categoryDetail?.name;
          nextBreadCrumb.type = 'model';
          setCurrentCategoryType('subModel');
          setCurrentCategory(categoryDetail?.prevId);
          const makeGroup = groupBy(models?.filter((item: any) => item?.subModel && item?.model_details?._id === categoryDetail?.prevId), 'subModel');
          setCurrentGroup(makeGroup);
        } else if (secondRawName === 'year') {
          setCurrentCategoryType('year');
          setSecondColumnTitle('');
          const makeGroup = groupBy(models?.filter((item: any) => item?.manufacture_year && item?.model_details?._id === categoryDetail?.prevId), 'manufacture_year');
          setCurrentGroup(makeGroup);
          setCurrentCategory(categoryDetail?.prevId);
          setCurrentCategoryType('year');
        } else {
          submodelExist = models?.filter((item: any) => item?.category === category && item?.company_details?._id === make && !!item?.sub_model)?.length;
          yearExist = models?.filter((item: any) => category && item?.company_details?._id === make && !!item?.manufacture_year)?.length;
          const nextItem = submodelExist ? 'subModel' : 'manufacture_year';
          const makeGroup = groupBy(models?.filter((item: any) => item[`${nextItem}`] && item?.model_details?._id === categoryDetail?.prevId), nextItem);
          setCurrentGroup(makeGroup);

          const idArray = []
          const countArray: any = []
          for (const key of Object.keys(makeGroup)) {
            if (nextItem === 'subModel') {
              idArray.push(makeGroup[key][0]?.sub_model_details?._id)
            } else {
              idArray.push(makeGroup[key][0]?.manufacture_year_details?._id)
            }
          }
          await Promise.all(idArray.map(async (item: any) => {
            let count = '0';
            if (nextItem === 'subModel') {
              count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: categoryDetail?.prevId, subModelId: item });
            } else {
              count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: categoryDetail?.prevId, yearId: item });
            }
            countArray.push({ id: item, count: count || 0 });
          }))
          if (nextItem === 'subModel') {
            setSubModelProductCount(countArray);
          } else {
            setYearProductCount(countArray);
          }

          setCurrentCategory(categoryDetail?.prevId);

          if (submodelExist) {
            setCurrentCategoryType('subModel');
          } else {
            setCurrentCategoryType('year');
            setSecondColumnTitle('');
          }
        }

        nextBreadCrumb.title = categoryDetail?.name;
        nextBreadCrumb.type = 'model';

        setModelName(categoryDetail?.name);
        setModel(categoryDetail?.prevId);

        if (submodelExist) {
          setSecondColumnTitle('Years');
          setSubOrYear('subModel');
        } else if (!submodelExist && yearExist) {
          setSubOrYear('manufacture_year');
          setSecondColumnTitle('');
        }
        setBreadCrumbItems([...breadCrumbItems, nextBreadCrumb]);
        setIsLoading(false);
        return;
      } else if (currentCategoryType === 'subModel') {
        const makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && category === item?.category && item.sub_model_details?._id === categoryDetail?.prevId), 'manufacture_year');
        setCurrentGroup(makeGroup);
        setCurrentCategoryType('year');
        nextBreadCrumb.title = categoryDetail?.name;
        nextBreadCrumb.type = 'subModel';
        setBreadCrumbItems([...breadCrumbItems, nextBreadCrumb]);

        const idArray = []
        const countArray: any = []
        for (const key of Object.keys(makeGroup)) {
          idArray.push(makeGroup[key][0]?.manufacture_year_details?._id)
        }
        await Promise.all(idArray.map(async (item: any) => {
          const count = await modelStore?.fetchModelVariantsCount({ category, companyId: make, modelId: model, subModelId: categoryDetail?.prevId, yearId: item });
          countArray.push({ id: item, count: count || 0 });
        }))
        setYearProductCount(countArray);
        setCurrentCategory(categoryDetail?.prevId);
        setSecondColumnTitle('');
        setSubModel(categoryDetail?.prevId);
      }
      setSecondColumnTitle(getCategoryNameFromType(getNextBreadCrumbType(getNextBreadCrumbType(categoryDetail?.type))));
      setBreadCrumbItems([...breadCrumbItems, nextBreadCrumb]);
      setCurrentCategory(categoryDetail?.prevId);
      setCurrentCategoryType(getNextBreadCrumbType(categoryDetail?.type));
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const getFieldName = (customType?: any) => {
    switch (customType || currentCategoryType) {
      case 'make': return { field: 'company', detailsIn: 'company_details' };
      case 'model': return { field: 'model', detailsIn: 'model_details' };
      case 'subModel': return { field: 'sub_model', detailsIn: 'sub_model_details' };
      case 'year': return { field: 'manufacture_year', detailsIn: 'manufacture_year_details' };
      default: return '';
    }
  };

  const getRowName = (key: string) => {
    if (key === 'subModel') return 'Sub-Models';
    if (key === 'year') return 'Years';
    return '';
  };

  const fetchProductModelsCount = async (params: any) => {
    try {
      setIsLoading(true);
      const query: any = {
        category: params?.category
      };
      const count = await modelStore?.fetchModelVariantsCount(query)
      setIsLoading(false);
      return count as string;
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  }



  const doUpdateCategory = async () => {
    try {
      setIsLoading(true);
      const id = currentCategoryInfo?.id;
      const data: any = {
        category: category,
      }

      if (currentCategoryType === 'make') {
        data.companyName = updateText?.trim();
      } else if (make) {
        data.companyId = make
      }
      if (currentCategoryType === 'model') {
        data.modelName = updateText?.trim();
      } else if (model) {
        data.modelId = model
      }
      if (currentCategoryType === 'subModel') {
        data.subModelName = updateText?.trim();
      } else if (subModel) {
        data.subModelId = subModel
      }
      if (currentCategoryType === 'year') {
        data.yearName = updateText?.trim();
      } else if (year) {
        data.yearId = year
      }

      if (currentCategoryType === 'year') {

        if (isNaN(updateText)) {
          setIsLoading(false);
          return Toast.error('Please enter a valid year.')
        }

        if (parseInt(updateText) === 0) {
          setIsLoading(false);
          return Toast.error('Please enter a valid year.')
        }

        const current_year = new Date().getFullYear();
        if (current_year < updateText?.trim()) {
          setIsLoading(false);
          return Toast.error('Please enter a year less than current year.')
        }
      }

      let matchFound = false;
      for (const key of Object.keys(currentGroup)) {
        if ((key.replace(/\s/g, '').toLowerCase() === updateText.replace(/\s/g, '').toLowerCase()) && key !== currentCategoryInfo?.name) {
          matchFound = true;
        }
      }
      if (matchFound) {
        setIsLoading(false);
        return Toast.error(`${getCategoryNameFromTypeSingle(currentCategoryType)} already exists.`);
      }
      closeUpdate();
      await modelStore?.updateModelEntity(id, data);
      await fetchModels();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  }

  const handleUpdate = (categoryInfo: any) => {
    setCurrentCategoryInfo(categoryInfo);
    setUpdateText(categoryInfo?.name)
    setShowUpdateModel(true);
  }

  const closeUpdate = () => {
    setCurrentCategoryInfo(null);
    setShowUpdateModel(false);
  }


  const deleteSuccess = async (deleteInfo: any) => {
    try {
      setIsLoading(true);
      const data = {
        category,
        companyId: make,
        modelId: model,
        subModelId: subModel,
        yearId: year,
        deleteId: deleteInfo?.id
      }

      const type = deleteInfo?.type;
      const itemId = deleteInfo?.id;
      if (type === 'category') {
        data.category = itemId;
      } else if (type === 'make') {
        data.companyId = itemId;
      } else if (type === 'model') {
        data.modelId = itemId;
      } else if (type === 'subModel') {
        data.subModelId = itemId;
      } else if (type === 'year') {
        data.yearId = itemId;
      }

      await modelStore?.deleteModel(data);
      await fetchModels();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  }

  const handleDelete = (categoryInfo: any) => {
    return confirmModal(`Delete  ${getCategoryNameFromTypeSingle(currentCategoryType)}`, `Do you want delete this ${getCategoryNameFromTypeSingle(currentCategoryType)} ?`, () => deleteSuccess(categoryInfo))
  }

  let modelRaw = [{}];
  if (currentCategoryType === 'type') {
    modelRaw = [{}];
  } else if (currentCategoryType === 'category') {
    modelRaw = [
      {
        type: selectedTypeName,
        secondColumn:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => handleGoingInside({ prevId: currentCategory, type: currentCategoryType, name: categories?.find((item: any) => item?.type === currentCategory) })}
          >
            {itemCount} Makes
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        product:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => showModal({ type: 'category', id: currentCategory, name: categories?.find((item: any) => item?.type === currentCategory)?.name })}
          >
            {categoryProductCount || 0} Products
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
      },
    ];
  } else if (currentCategoryType === 'make') {
    const fieldDetails: any = getFieldName();
    modelRaw = [];
    for (const key of Object.keys(currentGroup)) {
      const itemId = currentGroup[key][0][`${fieldDetails?.detailsIn}`]?._id;
      const { name } = currentGroup[key][0][`${fieldDetails?.detailsIn}`];
      const actualKeys = Object.keys(groupBy(currentGroup[key], 'model'));
      const itemCount = actualKeys?.filter((item: any) => item !== 'undefined' && !!item)?.length
      modelRaw.push({
        type: <div className='text-capitalize'>{key}</div>,
        secondColumn:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => handleGoingInside({ prevId: itemId, type: currentCategoryType, name })}
          >
            {/* get item count based category and handle id above */}
            {itemCount || 0} Models
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        product:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => showModal({ type: 'make', id: itemId, name: name })}
          >
            {makeProductCount?.find((item: any) => item?.id === itemId)?.count || 0} Products
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        action:
          <div className="d-flex">
            <Button title='Edit' onClick={() => handleUpdate({ type: 'make', id: itemId, name })} className="green_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white border-0">
              <Icon className="icon-10" iconRef="#edit" />
            </Button>
            {itemCount && (itemCount > 0) ?
              <Tooltip title={Constant?.modelDeleteMsg?.replace('[category]', getSecondColumnTitle())}>
                <Button disabled onClick={() => handleDelete({ type: 'make', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                  <Icon className="icon-14" iconRef="#delete" />
                </Button>
              </Tooltip>
              :
              <Button title='Delete' onClick={() => handleDelete({ type: 'make', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                <Icon className="icon-14" iconRef="#delete" />
              </Button>
            }
          </div>,
      });
    }
  } else if (currentCategoryType === 'model') {
    const fieldDetails: any = getFieldName();
    modelRaw = [];
    for (const key of Object.keys(currentGroup)) {
      const itemId = currentGroup[key][0].model_details?._id;
      const name = currentGroup[key][0][`${fieldDetails?.detailsIn}`]?.name;

      const makeArray = models?.filter((item: any) => item?.company_details?._id === make);
      let submodelExist = false;
      makeArray.forEach((item: any) => {
        if (item?.subModel) {
          submodelExist = true;
        }
      });

      const subYearCondition = submodelExist ? 'subModel' : 'manufacture_year';

      const actualKeys = Object.keys(groupBy(currentGroup[key], subYearCondition));
      const itemCount = actualKeys?.filter((item: any) => item !== Constant?.noSubmodelText && item !== 'undefined' && !!item)?.length
      modelRaw.push({
        type: <div className='text-capitalize'>{key}</div>,
        secondColumn:
          <>
            {!showDropDown ?
              <RenderItem isTrue={!showDropDown}>
                <Button
                  className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
                  onClick={() => handleGoingInside({ prevId: itemId, type: currentCategoryType, name })}
                >
                  {itemCount || 0} {submodelExist ? 'Sub-Models' : 'Years'}
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <span className="icon-8">
                      <svg>
                        <use xlinkHref="#arrow_right" />
                      </svg>
                    </span>
                  </div>
                </Button>
              </RenderItem>
              :
              <RenderItem isTrue={secondRawName && selectedValue}>
                <Button
                  className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
                  onClick={() => handleGoingInside({ prevId: itemId, type: currentCategoryType, name })}
                >
                  {actualKeys?.filter((item: any) => item !== Constant?.noSubmodelText && item !== 'undefined' && !!item)?.length || 0} {getRowName(secondRawName)}
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <span className="icon-8">
                      <svg>
                        <use xlinkHref="#arrow_right" />
                      </svg>
                    </span>
                  </div>
                </Button>
              </RenderItem>
            }
          </>,
        product:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => showModal({ type: 'model', id: itemId, name: name })}
          >
            {modelProductCount?.find((item: any) => item?.id === itemId)?.count || 0} Products
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        action:
          <div className="d-flex">
            <Button title='Edit' onClick={() => handleUpdate({ type: 'model', id: itemId, name })} className="green_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white border-0">
              <Icon className="icon-10" iconRef="#edit" />
            </Button>
            {itemCount && (itemCount > 0) ?
              <Tooltip title={Constant?.modelDeleteMsg?.replace('[category]', getSecondColumnTitle())}>
                <Button disabled onClick={() => handleDelete({ type: 'model', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                  <Icon className="icon-14" iconRef="#delete" />
                </Button>
              </Tooltip>
              :
              <Button title='Delete' onClick={() => handleDelete({ type: 'model', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                <Icon className="icon-14" iconRef="#delete" />
              </Button>
            }
          </div>,
      });
    }
  } else if (currentCategoryType === 'subModel') {
    modelRaw = [];
    for (const key of Object.keys(currentGroup)) {
      const itemId = currentGroup[key][0].sub_model_details?._id;
      const name = currentGroup[key][0].sub_model_details?.name;
      const actualKeys = Object.keys(groupBy(currentGroup[key], 'manufacture_year'));
      const itemCount = actualKeys?.filter((item: any) => item !== 'undefined' && !!item)?.length;
      modelRaw.push({
        type: <div className='text-capitalize'>{key}</div>,
        secondColumn:

          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => handleGoingInside({ prevId: itemId, type: currentCategoryType, name })}
          >
            {itemCount || 0} Years
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>
        ,
        product:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => showModal({ type: 'subModel', id: itemId, name: name })}
          >
            {subModelProductCount?.find((item: any) => item?.id === itemId)?.count || 0} Products
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        action:
          <div className="d-flex">
            <Button disabled={name === Constant?.noSubmodelText} title='Edit' onClick={() => handleUpdate({ type: 'subModel', id: itemId, name })} className="green_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white border-0">
              <Icon className="icon-10" iconRef="#edit" />
            </Button>
            {itemCount && (itemCount > 0) ?
              <Tooltip title={Constant?.modelDeleteMsg?.replace('[category]', getSecondColumnTitle())}>
                <Button disabled onClick={() => handleDelete({ type: 'subModel', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                  <Icon className="icon-14" iconRef="#delete" />
                </Button>
              </Tooltip>
              :
              <Button title='Delete' onClick={() => handleDelete({ type: 'subModel', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
                <Icon className="icon-14" iconRef="#delete" />
              </Button>
            }
          </div>,
      });
    }
  } else if (currentCategoryType === 'year') {
    modelRaw = [];
    for (const key of Object.keys(currentGroup)) {
      const itemId = currentGroup[key][0][`manufacture_year_details`]?._id;
      const name = currentGroup[key][0].manufacture_year_details?.name;
      modelRaw.push({
        type: key,
        product:
          <Button
            className="d-flex ml-auto bg-transparent r-bold ms-1 align-items-center p-0 link_text border-0 shadow-none"
            onClick={() => showModal({ type: 'year', id: itemId, name: name })}
          >
            {yearProductCount?.find((item: any) => item?.id === itemId)?.count || 0} Products
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <span className="icon-8">
                <svg>
                  <use xlinkHref="#arrow_right" />
                </svg>
              </span>
            </div>
          </Button>,
        action:
          <div className="d-flex">
            <Button title='Edit' onClick={() => handleUpdate({ type: 'year', id: itemId, name })} className="green_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white border-0">
              <Icon className="icon-10" iconRef="#edit" />
            </Button>
            <Button title='Delete' onClick={() => handleDelete({ type: 'year', id: itemId, name })} className="delete_bg icon-32 rounded-circle d-flex justify-content-center align-items-center ms-2 p-0 red border-0">
              <Icon className="icon-14" iconRef="#delete" />
            </Button>
          </div>,
      });
    }
  }

  const isUpdateOrCreate = (inp?: any) => {
    if (currentCategoryType === 'model') {
      const modelExist = models.find((item: any) => item?.category === category && item?.company === makeName && !!item?.model);
      let modelDetails;
      if (!modelExist) modelDetails = models.find((item: any) => item?.category === category && item?.company === makeName);
      return modelExist
        ? { isUpdate: false }
        : {
          isUpdate: true,
          modelDetails,
          data: {
            modelName: inputValue,
          },
        };
    }
    if (currentCategoryType === 'subModel') {
      const modelExist = models.find((item: any) => item?.category === category && item?.company === makeName && item?.model === modelName && !!item?.subModel);
      let modelDetails;
      if (!modelExist) modelDetails = models.find((item: any) => item?.category === category && item?.company === makeName && item?.model === modelName);
      return modelExist
        ? { isUpdate: false }
        : {
          isUpdate: true,
          modelDetails,
          data: {
            subModelName: inp || inputValue,
          },
        };
    }
    if (currentCategoryType === 'year') {
      const modelExist = models.find((item: any) => item?.category === category && item?.company === makeName && item?.model === modelName && !!item?.manufacture_year);
      let modelDetails;
      if (subModelName && !modelExist) {
        modelDetails = models.find((item: any) => item?.category === category && item?.company === makeName && item?.model === modelName && item?.subModel === subModelName);
      } else if (!subModelName && !modelExist) {
        modelDetails = models.find((item: any) => item?.category === category && item?.company === makeName && item?.model === modelName);
      }
      return modelExist ? { isUpdate: false } : { isUpdate: true, modelDetails, data: { yearName: inputValue } };
    }
    return null;
  };

  const createModel = async (createNoSubmodel: boolean = false) => {
    try {
      const inp = createNoSubmodel ? Constant?.noSubmodelText : inputValue;
      setIsLoading(true);
      if (!inp && !createNoSubmodel) {
        setIsLoading(false);
        return Toast.error('Please provide an input.')
      }
      if (currentCategoryType === 'year') {

        if (isNaN(inp)) {
          setIsLoading(false);
          return Toast.error('Please enter a valid year.')
        }
        if (parseInt(inp) === 0) {
          setIsLoading(false);
          return Toast.error('Please enter a valid year.')
        }

        const current_year = new Date().getFullYear();
        if (current_year < inp?.trim()) {
          setIsLoading(false);
          return Toast.error('Please enter a year less than current year.')
        }
      }

      const modelObject = {
        category,
        companyName: currentCategoryType === 'make' ? inp?.trim() : undefined,
        modelName: currentCategoryType === 'model' ? inp?.trim() : undefined,
        subModelName: currentCategoryType === 'subModel' ? inp?.trim() : undefined,
        yearName: currentCategoryType === 'year' ? inp?.trim() : undefined,

        companyId: make || undefined,
        modelId: model || undefined,
        subModelId: subModel || undefined,
        yearId: year || undefined,
      };

      let matchFound = false;
      for (const key of Object.keys(currentGroup)) {
        if ((key.replace(/\s/g, '').toLowerCase() === inp.replace(/\s/g, '').toLowerCase())) {
          matchFound = true;
        }
      }
      if (matchFound) {
        setIsLoading(false);
        if(createNoSubmodel) {
          return Toast.error('Already exists.');
        }
        return Toast.error(`${getCategoryNameFromTypeSingle(currentCategoryType)} already exists.`);
      }

      const status = createNoSubmodel ? isUpdateOrCreate(inp) : isUpdateOrCreate();
      if (status?.isUpdate) {
        await modelStore?.updateModel(status?.modelDetails?._id, status?.data);
      } else {
        await modelStore?.createModel(modelObject);
      }
      await fetchModels();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const refreshTheModels = (currCat: any) => {
    setIsLoading(true);
    if (currCat === 'category') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category), 'company');
      setCurrentGroup(makeGroup);
    } else if (currCat === 'make') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category), 'company');
      setCurrentGroup(makeGroup);
    } else if (currCat === 'model') {
      const makeGroup = groupBy(models?.filter((item: any) => !!item?.company && item?.company_details?._id === make), 'model');
      setCurrentGroup(makeGroup);
    } else if (currCat === 'subModel') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category && !!item?.company && item?.subModel && item?.company_details?._id === make && item?.model_details?._id === model), 'subModel');
      setCurrentGroup(makeGroup);
    } else if (currCat === 'year') {
      let makeGroup;
      if (subModel) {
        makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model && item?.sub_model_details?._id === subModel), 'manufacture_year');
      } else {
        makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model), 'manufacture_year');
      }
      setCurrentGroup(makeGroup);
    }
    setInputValue(null);
    setShowCreateAction(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (currentCategoryType === 'category') {
      // please do nothing
    } else if (currentCategoryType === 'make') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category), 'company');
      setCurrentGroup(makeGroup);
    } else if (currentCategoryType === 'model') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category && !!item?.company && !!item?.model && item?.company_details?._id === make), 'model');
      setCurrentGroup(makeGroup);
    } else if (currentCategoryType === 'subModel') {
      const makeGroup = groupBy(models?.filter((item: any) => item?.category === category && !!item?.company && !!item?.subModel && item?.company_details?._id === make && item?.model_details?._id === model), 'subModel');
      setCurrentGroup(makeGroup);
    } else if (currentCategoryType === 'year') {
      let makeGroup;
      if (subModel) {
        makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model && item?.sub_model_details?._id === subModel), 'manufacture_year');
      } else {
        makeGroup = groupBy(models?.filter((item: any) => !!item?.manufacture_year && item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model), 'manufacture_year');
      }
      setCurrentGroup(makeGroup);
    }
    setInputValue(null);
    setShowCreateAction(false);
    setIsLoading(false);
  }, [models]);

  const handleCreate = () => {
    createModel();
  };

  const cancelCreate = () => {
    setInputValue(null);
    setShowCreateAction(false);
  };

  const creaction = showCreateAction ? (
    <div className="d-flex">
      <Button onClick={handleCreate} className="green_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white me-2 border-0">
        <Icon className="icon-12" iconRef="#tick" />
      </Button>
      <Button onClick={cancelCreate} className="red_bg icon-32 rounded-circle d-flex justify-content-center align-items-center p-0 white border-0">
        <Icon className="icon-10" iconRef="#close" />
      </Button>
    </div>
  ) : null;

  const dataSource = [
    {
      type: optionalValues,
      action: creaction,
    },
    ...modelRaw,
  ];

  const gotoBreadCrumb = (details: any) => {
    if (details?.clickedItemType === 'type') {
      setSelectedType(null);
      setSecondColumnTitle('Makes');
      const breadCrumbDummy: any = [];
      let matchFound = false;
      breadCrumbItems?.forEach((item: any) => { if (!matchFound) breadCrumbDummy.push(item); if (item?.type === details?.clickedItemType && !matchFound) matchFound = true; });
      setBreadCrumbItems(breadCrumbDummy);
      setCurrentCategory('');

      setCategory(null);
      setMake(null); setMakeName(null);
      setModel(null); setModelName(null);
      setSubModel(null); setSubModelName(null);
      setYear(null);
      setSecondRawName(null);
      setCurrentCategoryType('type');
      refreshTheModels('type');
    } else if (details?.clickedItemType === 'category') {
      setMake(null); setMakeName(null);
      setModel(null); setModelName(null);
      setSubModel(null); setSubModelName(null);
      setYear(null);
      setSecondRawName(null);

      refreshTheModels('category');

      setSecondColumnTitle('Models');
      const breadCrumbDummy: any = [];
      let matchFound = false;
      breadCrumbItems?.forEach((item: any) => { if (!matchFound) breadCrumbDummy.push(item); if (item?.type === details?.clickedItemType && !matchFound) matchFound = true; });
      setBreadCrumbItems(breadCrumbDummy);
      setCurrentCategory(currentCategory);
      setCurrentCategoryType('make');
    } else if (details?.clickedItemType === 'make') {
      setModel(null); setModelName(null);
      setSubModel(null); setSubModelName(null);
      setYear(null);
      setSecondRawName(null);
      const makeArray = models?.filter((item: any) => item?.category === category && item?.company_details?._id === make);
      let submodelExist = false;
      let yearExist = false;
      makeArray.forEach((item: any) => {
        if (item?.subModel) {
          submodelExist = true;
        }
        if (item?.manufacture_year) {
          yearExist = true;
        }
      });

      if (!(submodelExist || yearExist)) {
        setShowDropDown(true);
        setSelectedValue(null);
      } else {
        setShowDropDown(false);
      }

      if (submodelExist) {
        setSubOrYear('subModel');
        setSecondColumnTitle('Sub-Model');
      } else if (yearExist) {
        setSubOrYear('manufacture_year');
        setSecondColumnTitle('Year');
      }

      refreshTheModels('model');
      const breadCrumbDummy: any = [];
      let matchFound = false;
      breadCrumbItems?.forEach((item: any) => { if (!matchFound) breadCrumbDummy.push(item); if (item?.type === details?.clickedItemType && !matchFound) matchFound = true; });
      setSecondRawName(null);
      setBreadCrumbItems(breadCrumbDummy);
      setCurrentCategory(details?.clickedItemId);
      setCurrentCategoryType('model');
    } else if (details?.clickedItemType === 'model') {
      setSubModel(null); setSubModelName(null);
      setYear(null);
      setSecondRawName(null);

      const makeArray = models?.filter((item: any) => item?.category === category && item?.company_details?._id === make && item?.model_details?._id === model);

      let submodelExist = false;
      let yearExist = false;
      makeArray.forEach((item: any) => {
        if (item?.subModel) {
          submodelExist = true;
        }
        if (item?.manufacture_year) {
          yearExist = true;
        }
      });

      if (!(submodelExist || yearExist)) {
        setShowDropDown(true);
      } else {
        setShowDropDown(false);
      }

      if (submodelExist) {
        setSubOrYear('subModel');
        setSecondColumnTitle('Years');
        refreshTheModels('subModel');
        setCurrentCategoryType('subModel');
      } else if (yearExist) {
        setSubOrYear('manufacture_year');
        setSecondColumnTitle('');
        refreshTheModels('manufacture_year');
        setCurrentCategoryType('subModel');
      }

      const breadCrumbDummy: any = [];
      let matchFound = false;
      breadCrumbItems?.forEach((item: any) => { if (!matchFound) breadCrumbDummy.push(item); if (item?.type === details?.clickedItemType && !matchFound) matchFound = true; });
      setBreadCrumbItems(breadCrumbDummy);
      setCurrentCategory(details?.clickedItemId);
    }
  };

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading || otherLoading || categoryLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3">
              <div className="d-flex align-items-center justify-content-between">
                <Typography.Title className="m-0 header_main d-flex align-items-center border-0 fs-xs-24">
                  Vehicle Database
                </Typography.Title>
              </div>
            </div>
            <RenderItem isTrue={!!breadCrumbItems}>
              <BreadCrumb
                itemsArray={breadCrumbItems}
                handleOnClick={(id: any, type: string) => {
                  gotoBreadCrumb({ clickedItemId: id, clickedItemType: type });
                }}
              />
            </RenderItem>
            <Table
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size="small"
              scroll={{ x: '1000px' }}
              className="vehicle_db_table"
              bordered
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage vehicle database</Paragraph>
            </Typography>
          </Sider>
        </Row>
        <CompactableProducts
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          isLoading={isModelLoading}
          breadCrumbItems={[...breadCrumbItems, currentProductLink]}
          selectedVariants={selectedVariants}
          linkProductHandler={linkProductHandler}
        />
      </Spin>
      <Modal
        title={`Update ${getCategoryNameFromTypeSingle(currentCategoryType)}`}
        visible={showUpdateModel}
        onCancel={closeUpdate}
        footer={null}
        className="cmn_modal_wrapper"
        width={400}
        centered
        destroyOnClose
        closeIcon={(
          <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
            <Icon className="icon-10" iconRef="#close" />
          </Button>
        )}
      >
        <Input value={updateText} maxLength={currentCategoryType === 'year' ? 4 : 50} onChange={handleEntityInput} placeholder={`Update ${getCategoryNameFromTypeSingle(currentCategoryType)}`} className="inp_style" />
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button onClick={doUpdateCategory} disabled={!updateText} htmlType="submit" block type="primary" className="main_btn w-auto border-0 r-bold fs-16">
            Update
          </Button>
        </div>
      </Modal>
    </Layout.Content>

  );
}

export default inject('modelStore')(observer(VehicleDatabase));
