/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Breadcrumb,
  Button,
  Card,
  Col, Divider, Layout, Radio, Row, Space, Typography,
} from 'antd';
import './ScanManage.less';
import classNames from 'classnames';
import productimg from '../../Assets/Images/flatstopper.png';
import scanimage from '../../Assets/Images/facility.png';
import { Counter, Icon } from '../../Components';

const { Title, Paragraph, Text } = Typography;
const { Sider } = Layout;

function ScanManage() {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Breadcrumb className="secondary text-uppercase r-medium">
            <Breadcrumb.Item>
              <a href={' '}>inventory</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>scan & manage</Breadcrumb.Item>
          </Breadcrumb>
          <Divider className="mt-3 mb-3 border-top" />
          <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24 border-bottom-0">
            Scan & Manage
          </Typography.Title>
          <Card bodyStyle={{ padding: 0 }} className="box-shadow border-radius">
            <Row>
              <Col lg={18} md={18} sm={24} xs={24} className="p-4 border-right scan_list_height order-2 order-md-1">
                <ul className="order_product_list list-unstyled">
                  <li className="position-relative">
                    <Row gutter={[16, 16]} className="justify-content-between">
                      <Col lg={12} md={20} sm={20} xs={24} className="order-1">
                        <div className="d-sm-flex">
                          <div className="d-flex">
                            <Typography.Text className="primary r-bold">1.</Typography.Text>
                            <div className="product_img_box border-color mx-3 border-radius p-2 d-flex align-items-center justify-content-center">
                              <img src={productimg} alt="product" className="w-100" />
                            </div>
                          </div>
                          <Typography className="ml-sm-28">
                            <Text className="secondary text-uppercase r-medium">flat stoppers</Text>
                            <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>Easyrise V</Title>
                            <Title className="primary text-capitalize m-0 r-regular" level={5}>Black</Title>
                          </Typography>
                        </div>
                      </Col>
                      <Col lg={6} md={10} sm={10} xs={24} className="ml-md-28 order-3 order-lg-2">
                        <Typography.Text className="secondary text-uppercase r-medium d-block ml-lg-30 mb-2">quantity</Typography.Text>
                        <Counter
                          label=""
                          name="total_capacity"
                          rule={[]}
                          min={0}
                          max={999999}
                          maxLength={6}
                        />
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6} className="order_list_del order-2 order-lg-3 justify-content-end d-flex">
                        <Button className="item_delete p-0 border-radius red d-flex align-items-center justify-content-center border-0 ms-1">
                          <Icon className="icon-16" iconRef="#delete" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                  <li className="position-relative">
                    <Row gutter={[16, 16]} className="justify-content-between">
                      <Col lg={12} md={20} sm={20} xs={24} className="order-1">
                        <div className="d-sm-flex">
                          <div className="d-flex">
                            <Typography.Text className="primary r-bold">1.</Typography.Text>
                            <div className="product_img_box border-color mx-3 border-radius p-2 d-flex align-items-center justify-content-center">
                              <img src={productimg} alt="product" className="w-100" />
                            </div>
                          </div>
                          <Typography className="ml-sm-28">
                            <Text className="secondary text-uppercase r-medium">flat stoppers</Text>
                            <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>Easyrise V</Title>
                            <Title className="primary text-capitalize m-0 r-regular" level={5}>Black</Title>
                          </Typography>
                        </div>
                      </Col>
                      <Col lg={6} md={10} sm={10} xs={24} className="ml-md-28 order-3 order-lg-2">
                        <Typography.Text className="secondary text-uppercase r-medium d-block ml-lg-30 mb-2">quantity</Typography.Text>
                        <Counter
                          label=""
                          name="total_capacity"
                          rule={[]}
                          min={0}
                          max={999999}
                          maxLength={6}
                        />
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6} className="order_list_del order-2 order-lg-3 justify-content-end d-flex">
                        <Button className="item_delete p-0 border-radius red d-flex align-items-center justify-content-center border-0 ms-1">
                          <Icon className="icon-16" iconRef="#delete" />
                        </Button>
                      </Col>
                    </Row>
                  </li>
                </ul>
              </Col>
              <Col lg={6} md={6} sm={24} xs={24} className="p-4 order-1 order-md-2">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <div className="scan_product_qr overflow-hidden">
                      <img src={scanimage} alt="scan" className="w-100" />
                    </div>
                    <Typography.Paragraph className="secondary text-uppercase r-medium mt-3 text-center">SCAN PRODUCT QR</Typography.Paragraph>
                  </div>
                  <div>
                    <Radio.Group className="cust_radio_btn">
                      <Space direction="vertical">
                        <Radio className="radio_inp" value="Add stock">Add stock</Radio>
                        <Radio className="radio_inp" value="Remove from stock">Remove from stock</Radio>
                      </Space>
                    </Radio.Group>
                    <div className="pt-4">
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        Confirm
                      </Button>
                      <Button type="text" className="cancel_btn fs-16 r-bold">
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>Scan the product qr to add or remove items from the inventory</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default observer(ScanManage);
