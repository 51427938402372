/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Col, Layout, Radio, Row, Skeleton, Table, Typography,
} from 'antd';
import './RequestListing.less';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import classNames from 'classnames';
import {
  Icon,
  NoData, Pagination, RenderItem, Search, SortTh, Status, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import Routes from '../../Global/Routes';
import { IServiceVisitStore } from '../../Store/serviceVisitStore';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
const {
  OPEN, IN_PROGRESS, COMPLETED, PAYMENT_PENDING,
} = Constant.serviceRequestStatus;

interface RequestListingProps{
  serviceVisitStore: IServiceVisitStore
 }

function RequestListing(props: RequestListingProps) {
  const { serviceVisitStore } = props;
  const { visits, total } = serviceVisitStore;
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultReqTab = queryParams?.tab && queryParams?.tab !== 'undefined' ? queryParams?.tab : OPEN;
  const [isLoading, setIsLoading] = useState(false);
  const [requestPage, setRequestPage] = useState(Constant.defaultPageNumber);
  const [requestKeyword, setRequestKeyword] = useState<string>('');
  const [requestOrderBy, setRequestOrderBy] = useState(1);
  const [requestOrder, setRequestOrder] = useState<any>('scheduled_time');
  const [requestStatus, setRequestStatus] = useState<string>(defaultReqTab);
  const [collapsed, setCollapsed] = useState(false);

  const requestColumn = [
    {
      title: <SortTh title="Request ID" dataIndex="visit_code" order={requestOrder} orderBy={requestOrderBy} />,
      dataIndex: 'visit_code',
      onHeaderCell: () => ({
        onClick: () => {
          sortServiceVisitRequest('visit_code');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      ellipsis: true,
    },
    {
      title: <SortTh title="Timestamp" dataIndex="scheduled_time" order={requestOrder} orderBy={requestOrderBy} />,
      dataIndex: 'timestamp',
      onHeaderCell: () => ({
        onClick: () => {
          sortServiceVisitRequest('scheduled_time');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      ellipsis: true,
    },
  ];

  const sortServiceVisitRequest = (dataIndex: string) => {
    const nextSort = requestOrderBy === -1 ? 1 : -1;
    setRequestOrderBy(nextSort);
    setRequestOrder(dataIndex);
    getServiceVisits(requestPage, requestStatus, requestKeyword, dataIndex, nextSort);
  };

  const getServiceVisits = async (
    page: number,
    req_status: string,
    keyword?: string,
    order?: string,
    orderBy?: number,
  ) => {
    setIsLoading(true);
    try {
      let status: any = req_status;
      if (req_status === IN_PROGRESS) status = [IN_PROGRESS, PAYMENT_PENDING];
      await serviceVisitStore.getServices({
        page,
        keyword,
        order,
        orderBy,
        status,
        limit: Constant.serviceRequest,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const requestDataSource = visits?.map(
    (request: any) => ({
      visit_code: request?.visit_code,
      timestamp: `${Utility.getRelativeDate(request?.scheduled_time)}, ${Utility?.getFormattedTime(request?.scheduled_time)}`,
      plan: request?.contractPlan?.plan,
      vehicle: (
        <>
          <RenderItem isTrue={!!request?.model?._id}>
            <RenderItem isTrue={request?.vehicle?.deleted}>
              <div className="text-capitalize text-truncate">
                {request?.model?.company} {request?.model?.model}
              </div>
            </RenderItem>
            <RenderItem isTrue={!request?.vehicle?.deleted}>
              <Link
                to={`${Routes.vehicleDetails}/${request?.vehicle?._id}?tab=${requestStatus}&status=false`}
                className="d-flex ml-auto text-capitalize r-bold align-items-center p-0 link_text"
              >
                <span className="text-truncate text-capitalize">{request?.model?.company} {request?.model?.model}</span>
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </RenderItem>
          </RenderItem>
          <RenderItem isTrue={!request?.model?._id}>
            -
          </RenderItem>
        </>
      ),
      vehicle_no: request?.vehicle?.vehicle_no || '-',
      location: request?.vehicle?.address?.city || '-',
      status: <Status
        status={request?.status}
        statusClassName={Constant.ServiceReqStatusClass}
        statusText={Constant.ServiceReqStatusText}
      />,
      rowKey: request?._id,
      vehilce_deleted: request?.vehicle?.deleted,
      visitStatus: request?.status,
    }),
  );

  const locale = {
    emptyText: isLoading ? (
      <Skeleton loading={isLoading} />
    ) : (
      <NoData subTitle="requests" />
    ),
  };

  const serviceSearchHandler = useCallback(
    debounce((nextValue: string, orderBy: number, order: string, status: any) => {
      setRequestPage(Constant.defaultPageNumber);
      getServiceVisits(Constant.defaultPageNumber, status, nextValue, order, orderBy);
    }, Constant.debaounceTime),
    [],
  );

  const onSearchHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event?.target;
    setRequestKeyword(value);
    serviceSearchHandler(value, requestOrderBy, requestOrder, requestStatus);
  };

  const pageHandler = (page: number) => {
    setRequestPage(page);
    getServiceVisits(page, requestStatus, requestKeyword, requestOrder, requestOrderBy);
  };

  const goToVisitDetails = (record: any) => {
    if (record?.visitStatus !== Constant.serviceVisitReqStatus.PAYMENT_PENDING) history.push(`${Routes.service_visit_detail}/${record?.rowKey}?tab=${requestStatus}`);
    else history.push(`${Routes.service_invoice}/${record?.rowKey}?tab=${requestStatus}`);
  };

  const filterHandler = (event: any) => {
    setRequestPage(Constant.defaultPageNumber);
    setRequestStatus(event?.target?.value);
    history.push(`${Routes.service_visit}?tab=${event.target.value}`);
  };

  useEffect(() => {
    serviceVisitStore?.initialize();
    getServiceVisits(requestPage, requestStatus, requestKeyword, requestOrder, requestOrderBy);
  }, [requestStatus]);

  return (
    <Layout.Content className="listing_wrapper service_visit_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
            <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
              Service Visits
            </Typography.Title>
            <Radio.Group
              onChange={filterHandler}
              defaultValue={requestStatus}
              buttonStyle="solid"
              className="radio_tab client_plan_tab d-flex gray_bg w-100 border-radius ms-xxl-4 ms-xl-0 ms-sm-4 mt-xxl-0 mt-xl-3 mt-sm-0 mt-3 no-wrap"
            >
              <Radio.Button
                className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3"
                value={OPEN}
              >New
              </Radio.Button>
              <Radio.Button
                className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3"
                value={IN_PROGRESS}
              >In Progress
              </Radio.Button>
              <Radio.Button
                className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3"
                value={COMPLETED}
              >Completed
              </Radio.Button>
            </Radio.Group>
          </div>
          <div className="service_visit_listing">
            {/* add pb-65 to request_listing class when alert shown */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-sm-flex align-items-center flex-fill">
                <h4 className="mb-0 table-header r-bold">All Requests</h4>
                <Search
                  value={requestKeyword}
                  placeholder="Search"
                  titleToolTip="Search request"
                  size="small"
                  onChange={onSearchHandler}
                />
              </div>
            </div>
            <Table
              rowClassName="cursor-pointer"
              columns={requestColumn}
              dataSource={requestDataSource}
              pagination={false}
              locale={locale}
              size="small"
              scroll={{ x: '1000px' }}
              className="table_wrapper"
              onRow={(record) => ({ onClick: () => goToVisitDetails(record) })}
            />
            <Pagination
              total={total}
              currentPage={requestPage}
              pageSize={Constant.serviceRequest}
              onChange={pageHandler}
            />
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>
              Info
            </Title>
            <Paragraph>See and manage the service visits</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('serviceVisitStore')(observer(RequestListing));
