/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  product: [{
    required: true,
    message: Constant.productRequired,
  }],
  variant: [{
    required: true,
    message: Constant.variantRequired,
  }],
  quantity: [{
    required: true,
    message: Constant.quantityRequired,
  }],
};

export default rule;
