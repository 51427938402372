/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button, Tooltip } from 'antd';
import { Icon } from '..';

interface ActionProps {
  isWaiting?: boolean;
  onAccept: (value: any) => void
  onReject: (value: any) => void
  onWait?: (value: any) => void
  disabled? : boolean
  acceptToolTipText?: string
  rejectToolTipText?: string
  formType?: boolean
}

Action.defaultProps = {
  isWaiting: false,
  onWait: (value: any) => value,
  disabled: false,
  rejectToolTipText: '',
  acceptToolTipText: '',
  formType: false,
};

function Action(props: ActionProps) {
  const {
    onAccept, onReject, onWait, isWaiting, disabled, acceptToolTipText, rejectToolTipText, formType,
  } = props;
  return (
    <div className="d-flex">
      <Tooltip title={acceptToolTipText || 'Accept'}>
        <Button
          className="green_bg icn_cir_wdt rounded-circle d-flex justify-content-center align-items-center p-0 white me-2 border-0"
          onClick={onAccept}
          disabled={disabled}
          htmlType={formType ? 'submit' : 'button'}
        >
          <Icon className="icon-12" iconRef="#tick" />
        </Button>
      </Tooltip>
      { isWaiting && (
        <Tooltip title="Put on hold">
          <Button
            className="yellow_bg icn_cir_wdt rounded-circle d-flex justify-content-center align-items-center p-0 white me-2 border-0"
            onClick={onWait}
            disabled={disabled}
          >
            <Icon className="icon-12" iconRef="#clock" />
          </Button>
        </Tooltip>
      )}
      <Tooltip title={rejectToolTipText || 'Reject'}>
        <Button
          className="red_bg icn_cir_wdt rounded-circle d-flex justify-content-center align-items-center p-0 white border-0"
          onClick={onReject}
          disabled={disabled}
        >
          <Icon className="icon-10" iconRef="#close" />
        </Button>
      </Tooltip>
    </div>
  );
}

export default Action;
