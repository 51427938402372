/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Link,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import {
  Icon,
} from '../../Components';
import productimg from '../../Assets/Images/flatstopper.png';
import './AbandonedDetails.less';

const { Title, Paragraph, Text } = Typography;
const { Sider } = Layout;

function AbandonedDetails() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={' '} className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto">
          View Customer
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper clnt_detail">
      <Row gutter={16}>
        <Col
          md={24}
          lg={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
            <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
              <Breadcrumb.Item>
                <Link to={' '}>ABANDONED CART</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>JENIFFER RODGER</Breadcrumb.Item>
            </Breadcrumb>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3 flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                <Typography.Title className="text-capitalize mb-0 fs-xs-24">
                  Jeniffer Rodger
                </Typography.Title>
                <div className="rounded-pill d-flex align-items-center justify-content-center fs-16 ms-1 ms-sm-3 label_success ps-2 pe-2 fs-xs-14 my-2 my-sm-0">Reminder send</div>
              </div>
              <Button className="main_btn r-bold" onClick={showModal}>Send Reminder</Button>
            </div>
            <Row gutter={[16, 16]}>
              <Col lg={24} md={24} xs={24} className="mt-3 mt-md-0">
                <Card>
                  <Typography.Title level={3}>Order Items</Typography.Title>
                  <Row>
                    <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                      <ul className="order_product_list list-unstyled">
                        <li className="position-relative">
                          <Row gutter={[16, 16]} className="justify-content-between">
                            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                              <div className="d-sm-flex">
                                <div className="d-flex">
                                  <Typography.Text className="primary r-bold">1.</Typography.Text>
                                  <div className="product_img_box border-color mx-3 border-radius p-2 d-flex align-items-center justify-content-center">
                                    <img src={productimg} alt="product" className="w-100" />
                                  </div>
                                </div>
                                <Typography className="ml-sm-28">
                                  <Text className="secondary text-uppercase r-medium">flat stoppers</Text>
                                  <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>Easyrise V</Title>
                                  <Title className="primary text-capitalize m-0 r-regular" level={5}>Black</Title>
                                </Typography>
                              </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">quantity</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">1</Typography.Paragraph>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">DATE ADDED</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">13/09/2021</Typography.Paragraph>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">LAST VIEWED</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">Today</Typography.Paragraph>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={12} sm={12} xs={20} className="ml-sm-28">
                              <Typography>
                                <Text className="secondary text-uppercase r-medium">amount</Text>
                                <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>85 KD</Title>
                              </Typography>
                            </Col>
                          </Row>
                        </li>
                        <li className="position-relative">
                          <Row gutter={[16, 16]} className="justify-content-between">
                            <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                              <div className="d-sm-flex">
                                <div className="d-flex">
                                  <Typography.Text className="primary r-bold">2.</Typography.Text>
                                  <div className="product_img_box border-color mx-3 border-radius p-2 d-flex align-items-center justify-content-center">
                                    <img src={productimg} alt="product" className="w-100" />
                                  </div>
                                </div>
                                <Typography className="ml-sm-28">
                                  <Text className="secondary text-uppercase r-medium">flat stoppers</Text>
                                  <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>Easyrise V</Title>
                                  <Title className="primary text-capitalize m-0 r-regular" level={5}>Black</Title>
                                </Typography>
                              </div>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">quantity</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">1</Typography.Paragraph>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">DATE ADDED</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">13/09/2021</Typography.Paragraph>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={24} className="ml-sm-28">
                              <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">LAST VIEWED</Typography.Text>
                              <Typography.Paragraph className="primary fs-16 text-lg-center">Today</Typography.Paragraph>
                            </Col>
                            <Col xxl={3} xl={3} lg={4} md={12} sm={12} xs={20} className="ml-sm-28">
                              <Typography>
                                <Text className="secondary text-uppercase r-medium">amount</Text>
                                <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>85 KD</Title>
                              </Typography>
                            </Col>
                          </Row>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Divider className="m-0 border-top" />
                  <div className="d-flex justify-content-end align-items-center fs-16 r-bold secondary mt-4">
                    Total Amount
                    <Typography.Text className="primary fs-24 ms-3">85 KD</Typography.Text>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See details of the abandoned items on customers cart</Paragraph>
          </Typography>
          <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
            <Tooltip title="View Customer">
              <Link to={' '} className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto">
                View Customer
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </Sider>
      </Row>
      <Modal
        title="Remind Customer"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        className="cmn_modal_wrapper"
        width={400}
        centered
        closeIcon={(
          <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
            <Icon className="icon-10" iconRef="#close" />
          </Button>
                      )}
      >
        <Typography.Paragraph className="secondary fs-16">Choose the reminder template</Typography.Paragraph>
        <Radio.Group className="cust_radio_btn w-100">
          <Space direction="vertical" className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <Radio value={1} className="text-wrap w-75 radio_span_wdt">1. Template 1.html</Radio>
              <Icon className="icon-18 primary" iconRef="#eye" />
            </div>
            <Divider className="m-0 border-top" />
            <div className="d-flex justify-content-between align-items-center">
              <Radio value={2} className="text-wrap w-75 radio_span_wdt">2. Template 2.html</Radio>
              <Icon className="icon-18 primary" iconRef="#eye" />
            </div>
          </Space>
        </Radio.Group>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button htmlType="submit" block type="primary" className="primary_bg text-white h-40 w-auto border-0 border-radius r-bold fs-16">
            Send Now
          </Button>
        </div>
      </Modal>
    </Layout.Content>
  );
}

export default (observer(AbandonedDetails));
