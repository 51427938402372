/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Col, DatePicker, Divider, Form, Input,
  Row, Select, Typography, Spin, Layout, Card, Checkbox, Upload, message,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadProps, RcFile, UploadFile } from 'antd/es/upload/interface';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Icon, Toast, UploadDocument, ListDocument, ColorPicker, RenderItem,
} from '../../Components';
import vehicleService from '../../Services/vehicleService';
import fileService from '../../Services/fileService';
import Constant from '../../Global/Constant';
import './CreateVehicle.less';
import CreateModel from '../CreateClient/CreateModel';
import Routes from '../../Global/Routes';
import Rules from './create-vehicle-form.validation';
import Utility from '../../Global/Utility';
import { Model, Vehicle } from '../../Global/interface';

const { REGISTRATION, INSURANCE } = Constant?.vehicleDocTypes;
const { Sider } = Layout;

const groupBy = (xs: any, key: any) => xs?.reduce((rv: any, x: any) => {
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

const getModelData = (modelData: any, type: string) => {
  const model = [];
  for (const item in modelData) {
    if (Object.prototype.hasOwnProperty.call(modelData, item)) {
      let _id;
      let name;
      const data: any = modelData ? modelData[item][0] : {};
      if (type === 'model') {
        _id = data?.model_details?._id;
        name = data?.model;
      } else if (type === 'company') {
        _id = data?.company_details?._id;
        name = data?.company;
      } else if (type === 'sub_model') {
        _id = data?.sub_model_details?._id;
        name = data?.subModel;
      } else if (type === 'year') {
        _id = data?.manufacture_year_details?._id;
        name = data?.manufacture_year;
      }
      if (_id && name) model.push({ _id, name });
    }
  }
  return model;
};

function CreateVehicle(props: any) {
  const {
    masterStore, vehicleStore,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const { client_id, vehicle_id } = useParams<any>();
  const history = useHistory();
  const { vehicleCategories, countries: countryData, models } = masterStore;
  const { Option } = Select;

  const [isDocUploading, setIsDocUploading] = useState<boolean>(false);
  const [regDocuments, setRegDocuments] = useState<any>([]);
  const [InsDocuments, setInsDocuments] = useState<any>([]);
  const [isCreateModelVisible, setIsCreateModelVisible] = useState<boolean>(false);
  const [vehicleDocType, setVehicleDocType] = useState<string>('');
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false);
  const [isVehicleUpdate, setIsVehicleUpdate] = useState<boolean>(false);
  const [selectedCountryName, setSelectedCountryName] = useState<any>(null);
  const [selectedStateName, setSelectedStateName] = useState<any>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isAddressPresent, setIsAddressPresent] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<any>([]);

  const [modelSelectData, setModelSelectData] = useState<any>([]);
  const [subModelSelectData, setSubModelSelectData] = useState<any>([]);
  const [modelImage, setModelImage] = useState<any>(null);
  const [yearSelectData, setYearSelectData] = useState<any>([]);

  const companyData = groupBy(models, 'company');
  const companySelectData = getModelData(companyData, 'company');

  const customRequest = () => false;
  const imageAcceptedExtensions = '.jpeg,.png,.jpg';

  const addressCheckboxHanlder = () => setIsAddressPresent(!isAddressPresent);

  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };

  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  async function getCountryData() {
    masterStore?.getCountryData()
      .catch((error: any) => { Toast.error(error?.response?.data?.error?.message); });
  }

  useEffect(() => {
    if (!masterStore?.countries) {
      getCountryData();
    }
  }, []);

  const createVehicle = async (value: any) => {
    try {
      setIsLoading(true);
      const {
        chassis_no, vehicle_no, insurance_exp_date, registration_exp_date,
        country, state, city, zip_code, address_line_1, address_line_2, color_name, color,
      } = value;

      const vehicleForm: any = {
        chassis_no,
        vehicle_no,
        model: selectedModel?._id,
        insurance_exp_date: insurance_exp_date ? moment(insurance_exp_date).format('YYYY-MM-DD') : undefined,
        registration_exp_date: registration_exp_date ? moment(registration_exp_date).format('YYYY-MM-DD') : undefined,
        registration_doc: regDocuments,
        insurance_doc: InsDocuments,
        clientId: client_id,
        country,
        state,
        city,
        zip_code,
        address_line_1,
        address_line_2,
        color,
        color_name,
      };

      if (modelImage) {
        vehicleForm.image = modelImage;
      }

      const response = await vehicleService.createVehicle(vehicleForm);
      setIsLoading(false);
      gotoVehicleDetails(response?.data?.id);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const gotoVehicleDetails = (vehicleId: string) => history.push(`${Routes.vehicleDetails}/${vehicleId}`);
  const updateVehicle = async (values: any) => {
    try {
      const {
        chassis_no, vehicle_no, insurance_exp_date, registration_exp_date,
        country, state, city, zip_code, address_line_1, address_line_2, color_name, color,
      } = values;

      const vehicleForm: any = {
        chassis_no,
        vehicle_no,
        model: selectedModel?._id,
        insurance_exp_date: insurance_exp_date
          ? moment(insurance_exp_date).format('YYYY-MM-DD') : null,
        registration_exp_date: registration_exp_date
          ? moment(registration_exp_date).format('YYYY-MM-DD') : null,
        registration_doc: regDocuments,
        insurance_doc: InsDocuments,
        clientId: client_id,
        country: country || null,
        state: state || null,
        city: city || null,
        zip_code: zip_code || null,
        address_line_1: address_line_1 || null,
        address_line_2: address_line_2 || null,
        color,
        color_name,
      };

      vehicleForm.image = modelImage;
      await vehicleService.updateVehicle(vehicle_id, vehicleForm);
      gotoVehicleDetails(vehicle_id);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const onFinish = async (values: any) => {
    if (isVehicleUpdate) updateVehicle(values);
    else createVehicle(values);
  };

  const setUploadedDocuments = (documents: any) => {
    if (vehicleDocType === REGISTRATION) {
      setRegDocuments([...regDocuments, ...documents]);
    } else {
      setInsDocuments([...InsDocuments, ...documents]);
    }
  };

  const uploadDocuments = async (documents: any) => {
    setIsDocUploading(true);
    const fileFormData = new FormData();
    documents.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
    const response = await fileService.uploadFile(fileFormData);
    setIsUploadVisible(false);
    setIsDocUploading(false);
    setUploadedDocuments(response?.data);
  };

  const removeFileFromUploaded = (index: number, docType: string) => {
    const vehicleDoc = docType === REGISTRATION
      ? regDocuments.filter((value: any, actualIndex: number) => actualIndex !== index)
      : InsDocuments.filter((value: any, actualIndex: number) => actualIndex !== index);

    if (docType === REGISTRATION) setRegDocuments(vehicleDoc);
    else setInsDocuments(vehicleDoc);
  };

  const populateVehicleDetails = async () => {
    await vehicleStore.getVehicleById(vehicle_id);
    const {
      model,
      registration_exp_date,
      insurance_exp_date,
      chassis_no,
      vehicle_no,
      registration_doc,
      insurance_doc,
      address,
      color,
      color_name,
      image,
    } = vehicleStore?.vehicle as Vehicle;
    await getModels(model?.category);

    const formData: any = {
      category: model?.category,
      model: model?.model_details?._id,
      make: model?.company_details?._id,
      sub_model: model?.sub_model_details?._id,
      year: model?.manufacture_year_details?._id,
      chassis_no,
      vehicle_no,
      color,
      color_name,
    };
    if (address?.country) {
      formData.country = address?.country;
      setSelectedCountryName(address?.country);
    }
    if (address?.state) {
      formData.state = address?.state;
      setSelectedStateName(address?.state);
    }
    if (address?.city) formData.city = address?.city;
    if (address?.zip_code) formData.zip_code = address?.zip_code;
    if (address?.address_line_1) formData.address_line_1 = address?.address_line_1;
    if (address?.address_line_2) formData.address_line_2 = address?.address_line_2;
    if (address?.country || address?.state || address?.city) setIsAddressPresent(true);

    form.setFieldsValue(formData);
    if (registration_exp_date) {
      form.setFieldsValue({
        registration_exp_date: moment(registration_exp_date),
      });
    }
    if (insurance_exp_date) {
      form.setFieldsValue({
        insurance_exp_date: moment(insurance_exp_date),
      });
    }
    setRegDocuments(registration_doc || []);
    setInsDocuments(insurance_doc || []);

    if (image) {
      setModelImage(image);
    }
  };

  useEffect(() => {
    if (isVehicleUpdate) {
      const {
        model,
      } = vehicleStore?.vehicle as Vehicle;

      const filteredData = models?.filter(
        (item: Model) => item?.company_details?._id === model?.company_details?._id,
      );
      const modelData = groupBy(filteredData, 'model');
      const companyModelData: any = getModelData(modelData, 'model');
      setModelSelectData(companyModelData);

      const modelFilteredData = filteredData?.filter((
        item: Model,
      ) => item?.model_details?._id === model?.model_details?._id);
      const isSubmodelPresent = modelFilteredData?.find((item: Model) => item?.sub_model);

      if (isSubmodelPresent) {
        const modelData = groupBy(modelFilteredData, 'sub_model');
        const companyModelData: any = getModelData(modelData, 'sub_model');
        setSubModelSelectData(companyModelData);

        const subFilteredData = modelFilteredData?.filter((
          item: Model,
        ) => item?.sub_model_details?._id === model?.sub_model_details?._id);
        getYearData(subFilteredData);
      } else {
        getYearData(modelFilteredData);
      }

      const selectedModelDetail = models?.find((item: Model) => item?._id === model?._id);
      setSelectedModel(selectedModelDetail);
    }
  }, [models]);

  const getInitials = async () => {
    try {
      setIsLoading(true);
      if (!masterStore?.vehicleCategories) {
        await masterStore.getVehicleCategories();
      }
      if (vehicle_id) {
        setIsVehicleUpdate(true);
        populateVehicleDetails();
      }
      setIsLoading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  useEffect(() => {
    getInitials();
  }, []);

  const closeUploadModal = () => setIsUploadVisible(false);

  const showUploadModal = (docType: string) => {
    setVehicleDocType(docType);
    setIsUploadVisible(true);
  };
  const vehicleCategoryChange = (value: string) => {
    form.resetFields(['make']);
    form.resetFields(['model']);
    form.resetFields(['sub_model']);
    form.resetFields(['year']);
    setModelSelectData([]);
    setSubModelSelectData([]);
    setYearSelectData([]);
    getModels(value);
  };

  const getModels = async (
    category: string, user_created: Boolean = true, client_created: Boolean = true,
  ) => {
    setIsLoading(true);
    await masterStore.getModels({ category, user_created, client_created });
    setIsLoading(false);
  };
  const modelFilterOption = (input: any, option: any) => option?.children?.toLowerCase()
    .indexOf(input?.toLowerCase()) >= 0;

  // const showModelCreate = () => {
  //   setIsCreateModelVisible(true);
  // };

  const closeModelCreate = () => {
    setIsCreateModelVisible(false);
  };

  const onFinishModelCreate = async (model: string, category: string) => {
    closeModelCreate();
    await getModels(category);
    form.setFieldsValue({ category, model });
  };

  const gotoClientDetailsPage = () => history.goBack();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const getYearData = (filteredData: any) => {
    const modelData = groupBy(filteredData, 'manufacture_year');
    const companyModelData: any = getModelData(modelData, 'year');
    setYearSelectData(companyModelData);
  };

  const companyChange = (value: string) => {
    form.resetFields(['model']);
    form.resetFields(['sub_model']);
    form.resetFields(['year']);
    setSubModelSelectData([]);
    setYearSelectData([]);

    const filteredData = models?.filter((item: Model) => item?.company_details?._id === value);
    const modelData = groupBy(filteredData, 'model');
    const companyModelData: any = getModelData(modelData, 'model');

    setModelSelectData(companyModelData);
  };

  const modelChange = (value: string) => {
    form.resetFields(['sub_model']);
    form.resetFields(['year']);
    setYearSelectData([]);
    setSubModelSelectData([]);

    const filteredData = models?.filter((item: Model) => item?.model_details?._id === value
      && item?.company_details?._id === form.getFieldValue('make'));
    const isSubmodelPresent = filteredData?.find((item: Model) => item?.sub_model);

    const isYearPresent = filteredData?.find((item: Model) => item?.manufacture_year);

    if (isSubmodelPresent) {
      const modelData = groupBy(filteredData, 'sub_model');
      const companyModelData: any = getModelData(modelData, 'sub_model');
      setSubModelSelectData(companyModelData);
    } else if (isYearPresent) {
      getYearData(filteredData);
      setSubModelSelectData([]);
    } else {
      setSelectedModel(filteredData[0]);
    }
  };

  const subModelChange = (value: string) => {
    form.resetFields(['year']);

    const filteredData = models?.filter((item: Model) => item?.model_details?._id === form.getFieldValue('model')
      && item?.company_details?._id === form.getFieldValue('make')
      && item?.sub_model_details?._id === value);

    const isYearPresent = filteredData?.find((item: Model) => item?.manufacture_year);

    getYearData(filteredData);
    if (!isYearPresent) setSelectedModel(filteredData[0]);
  };

  const handleColorChange = (color: any) => {
    form.setFieldsValue({ color });
  };

  const onYearChange = (value: any) => {
    const modelDetail: any = models?.find(
      (item: any) => item?.manufacture_year_details?._id === value,
    );
    setSelectedModel(modelDetail);
  };

  const uploadThumbnail = async (fileInfo: any) => {
    if (fileInfo?.fileList?.length) {
      setIsLoading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setModelImage(response?.data[0]);
      setIsLoading(false);
    }
  };

  const handleOnChangeImage = (fileInfo: any) => {
    const fileExt = fileInfo?.file?.name?.split('.').pop();
    if (!imageAcceptedExtensions?.includes(fileExt.toLowerCase())) {
      Toast.error(`Only files with following extensions are allowed: ${imageAcceptedExtensions}.`);
      return;
    }
    if ((fileInfo?.file?.size / 1024 / 1024 > Constant.maximumFileSize)) {
      Toast.error(`File size exceeded the limit: ${Constant.maximumFileSize} MB.`);
      return;
    }
    uploadThumbnail(fileInfo);
  };

  const removeModelImage = () => {
    setModelImage(null);
  };

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-xl-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Button className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content bg-transparent border-0 p-0 shadow-none" onClick={gotoClientDetailsPage}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Button>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {isVehicleUpdate ? 'Edit vehicle' : 'Add vehicle'}
            </Typography.Title>
            <Card className="create_card">
              <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                {/* <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Details
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <Typography.Paragraph className="create_para">
                        Choose or enter model details
                      </Typography.Paragraph>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link
                      p-0 mb-3 primary" type="link" onClick={showModelCreate}>
                        Add new model
                        <div className="ms-2 d-flex justify-content-center align-items-center
                        text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="vehicle category" className="mandatory" name="category"
                        rules={Rules?.vehicle_category}>
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onChange={vehicleCategoryChange}
                          >
                            {vehicleCategories?.map((vehicleCategory: any) => (
                              <Option key={vehicleCategory?._id} value={vehicleCategory.category}>
                                {vehicleCategory.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="Choose Model" className="mandatory" name="model"
                        rules={Rules?.model}>
                          <Select
                            showSearch
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Select a model"
                            className="slct_style w-100"
                            dropdownClassName="slct_drp_dwn border-radius"
                            filterOption={modelFilterOption}
                          >
                            {masterStore?.models?.map((model: any) => (
                              <Option key={model?._id} value={model?._id}>
                                <div className="d-flex justify-content-between">
                                  <div className="text-truncate">
                                    {`${model?.company} ${model?.model}`}<br />
                                    <span className="text-uppercase secondary r-regular d-flex
                                    align-items-center">{model?.manufacture_year}</span>
                                  </div>
                                  <span className="veh_color d-inline-block ms-3 rounded-circle
                                  v_color_display" style={{ backgroundColor: model?.color }} />
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top mb-3" />
                  </Col>
                </Row> */}
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Vehicle
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <Typography.Paragraph className="create_para">
                        Enter or choose client vehicle details
                      </Typography.Paragraph>
                    </div>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="vehicle category" className="mandatory" name="category" rules={Rules?.vehicle_category}>
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onChange={vehicleCategoryChange}
                          >
                            {vehicleCategories?.map((vehicleCategory: any) => (
                              <Option key={vehicleCategory?._id} value={vehicleCategory.category}>
                                {vehicleCategory.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="MAKE" className="mandatory" name="make" rules={Rules?.make}>
                          <Select
                            showSearch
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Select a make"
                            className="slct_style w-100"
                            dropdownClassName="slct_drp_dwn border-radius"
                            filterOption={modelFilterOption}
                            onChange={companyChange}
                          >
                            {companySelectData?.map((item: any) => (
                              <Select.Option
                                key={item?._id}
                                value={item?._id}
                              >
                                {item?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="Model" className="mandatory" name="model" rules={Rules?.model}>
                          <Select
                            showSearch
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Select a model"
                            className="slct_style w-100"
                            dropdownClassName="slct_drp_dwn border-radius"
                            filterOption={modelFilterOption}
                            onChange={modelChange}
                          >
                            {modelSelectData?.map((item: any) => (
                              <Select.Option
                                key={item?._id}
                                value={item?._id}
                              >
                                {item?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <RenderItem isTrue={subModelSelectData?.length > 0}>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="Sub Model" className="mandatory" name="sub_model" rules={Rules?.sub_model}>
                            <Select
                              showSearch
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Select a sub model"
                              className="slct_style w-100"
                              dropdownClassName="slct_drp_dwn border-radius"
                              filterOption={modelFilterOption}
                              onChange={subModelChange}
                            >
                              {subModelSelectData?.map((item: any) => (
                                <Select.Option
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  {item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </RenderItem>
                      <RenderItem isTrue={yearSelectData?.length > 0}>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="YEAR" className="mandatory" name="year" rules={Rules?.year}>
                            <Select
                              showSearch
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Select a model"
                              className="slct_style w-100"
                              dropdownClassName="slct_drp_dwn border-radius"
                              filterOption={modelFilterOption}
                              onChange={onYearChange}
                            >
                              {yearSelectData?.map((item: any) => (
                                <Select.Option
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  {item?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </RenderItem>
                    </Row>
                    <Divider className="m-0 border-top mb-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Color
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <Typography.Paragraph className="create_para">
                        Select vehicle color
                      </Typography.Paragraph>
                    </div>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item className="mandatory" normalize={Utility.trimSpacesInValues} rules={Rules.color}>
                          <ColorPicker
                            onSelect={handleColorChange}
                            defaultColor={isVehicleUpdate && vehicleStore?.vehicle?.color ? vehicleStore?.vehicle?.color : ''}
                            placeholder="Pick the color"
                            inputRules={Rules.color_name}
                            colorRules={Rules?.color}
                            disabled={false}
                            label="vehicle color"
                            pickerName="color"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top mb-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Image
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <Typography.Paragraph className="create_para">
                        Upload vehicle image
                      </Typography.Paragraph>
                      <Form.Item name="upload" className="m-0">
                        <Upload
                          className="d-flex ml-auto mb-3
                          r-bold align-items-center back_link p-0 border-0 upload-action"
                          showUploadList={false}
                          maxCount={1}
                          onChange={handleOnChangeImage}
                          accept={imageAcceptedExtensions}
                          customRequest={customRequest}
                        >
                          Add
                          <div className="ms-2 d-flex justify-content-center
                           align-items-center text-center small_icon rounded-circle"
                          >
                            <Icon className="icon-8" iconRef="#arrow_right" />
                          </div>
                        </Upload>
                      </Form.Item>
                    </div>
                    <div className="d-flex flex-wrap">
                      {modelImage?.fileUrl
                        && (
                          <Card className="manage_upload_img border-radius off-white-bg d-flex align-items-center position-relative mb-4 overflow-hidden">
                            <img src={modelImage?.fileUrl} alt="vehicle_img" className="w-100 ps-4 pe-4" />
                            <Button className="delete_model p-0 position-absolute border-radius red d-flex align-items-center justify-content-center border-0" onClick={removeModelImage}>
                              <Icon className="icon-16" iconRef="#delete" />
                            </Button>
                          </Card>
                        )}
                    </div>
                    <Row gutter={16} />
                    <Divider className="m-0 border-top mb-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Documents
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Add Registration Documents
                      </Typography.Text>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={() => showUploadModal(REGISTRATION)}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    {regDocuments
                      && (
                        <ListDocument
                          documents={regDocuments}
                          removeFile={
                            (index: number) => removeFileFromUploaded(index, REGISTRATION)
                          }
                        />
                      )}
                    {regDocuments?.length !== 0
                      && (
                        <Row gutter={16}>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item
                              label="EXPIRY DATE"
                              name="registration_exp_date"
                            >
                              <DatePicker
                                className="inp_style date_picker primary"
                                dropdownClassName="date_picker_drp_dwn"
                                placeholder="Choose expiry date"
                                showToday={false}
                                format="DD-MM-YYYY"
                                suffixIcon={(
                                  <Icon className="icon-16 primary" iconRef="#calendar" />
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                        </Row>
                      )}
                    <Divider className="m-0 border-top mb-3" />
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Add Insurance Documents
                      </Typography.Text>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={() => showUploadModal(INSURANCE)}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    {InsDocuments
                      && (
                        <ListDocument
                          documents={InsDocuments}
                          removeFile={
                            (index: number) => removeFileFromUploaded(index, INSURANCE)
                          }
                        />
                      )}
                    {InsDocuments?.length !== 0
                      && (
                        <Row gutter={16}>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item
                              label="EXPIRY DATE"
                              name="insurance_exp_date"
                            >
                              <DatePicker
                                className="inp_style date_picker primary"
                                dropdownClassName="date_picker_drp_dwn"
                                placeholder="Choose expiry date"
                                showToday={false}
                                format="DD-MM-YYYY"
                                suffixIcon={(
                                  <Icon className="icon-16 primary" iconRef="#calendar" />
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                        </Row>
                      )}
                    <Divider className="m-0 border-top mb-3" />
                    <Typography.Paragraph className="mt-3 create_para">
                      Enter other vehicle details
                    </Typography.Paragraph>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="VEHICLE NUMBER"
                          name="vehicle_no"
                          required={false}
                          normalize={Utility.trimSpacesInValues}
                        >
                          <Input placeholder="Enter vehicle number" className="inp_style" maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="CHASSIS NUMBER"
                          name="chassis_no"
                          required={false}
                          normalize={Utility.trimSpacesInValues}
                        >
                          <Input placeholder="Enter chassis number" className="inp_style" maxLength={17} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24} />
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Checkbox
                      className="veh_park_chkbox_pad chkbox_style primary r-regular"
                      checked={isAddressPresent}
                      onChange={addressCheckboxHanlder}
                    >
                      Vehicle address
                    </Checkbox>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col md={24} lg={4}>
                    {isAddressPresent && (
                      <Typography.Title className="m-0" level={5}>
                        Address
                      </Typography.Title>
                    )}
                  </Col>
                  <Col md={24} lg={20}>
                    {isAddressPresent && (
                      <Typography.Text className="m-0 create_para">
                        Manage vehicle address here
                      </Typography.Text>
                    )}
                    {isAddressPresent && (
                      <>
                        <Row gutter={16} className="mt-3">
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="country" className="mandatory" name="country" rules={Rules.country}>
                              <Select
                                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                placeholder="Choose country"
                                className="slct_style"
                                dropdownClassName="slct_drp_dwn border-radius"
                                onChange={countryChanged}
                              >
                                {countryData && Array.from(countryData?.keys())
                                  ?.map(
                                    (country: any) => <Option value={country}>{country}</Option>,
                                  )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="state" className="mandatory" name="state" rules={Rules.state}>
                              <Select
                                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                placeholder="Choose State"
                                className="slct_style"
                                dropdownClassName="slct_drp_dwn border-radius"
                                onChange={stateChanged}
                              >
                                {selectedCountryName
                                  && countryData?.get(selectedCountryName)?.keys()
                                  && Array?.from(countryData?.get(selectedCountryName)?.keys())
                                    ?.map((state: any) => <Option value={state}>{state}</Option>)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="city" className="mandatory" name="city" rules={Rules.city}>
                              <Select
                                suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                placeholder="Choose City"
                                className="slct_style"
                                dropdownClassName="slct_drp_dwn border-radius"
                              >
                                {selectedStateName
                                  && countryData?.get(selectedCountryName)?.keys()
                                  && countryData.get(selectedCountryName).get(selectedStateName)
                                    ?.cities?.map(
                                      (state: any) => <Option value={state}>{state}</Option>,
                                    )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="POST CODE" name="zip_code" normalize={Utility.trimSpacesInValues} rules={Rules.zip_code}>
                              <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="Address Line 1" name="address_line_1" normalize={Utility.trimSpacesInValues} rules={Rules.addressLine1}>
                              <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                            <Form.Item label="Address Line 2" name="address_line_2" normalize={Utility.trimSpacesInValues} rules={Rules.addressLine2}>
                              <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider className="m-0 border-top" />
                      </>
                    )}
                    <div>
                      <div className="d-flex mt-4">
                        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
                          {isVehicleUpdate ? 'Edit Vehicle' : 'Add Vehicle'}
                        </Button>
                        <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" disabled={isLoading} onClick={gotoClientDetailsPage}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
            <UploadDocument
              isModalVisible={isUploadVisible}
              handleModelCancel={closeUploadModal}
              uploadDocuments={uploadDocuments}
              uploadedDocuments={vehicleDocType === REGISTRATION ? regDocuments : InsDocuments}
              isLoading={isDocUploading}
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography.Title className="text-uppercase r-medium r-side-info" level={5}>Info</Typography.Title>
            <Typography.Paragraph>{isVehicleUpdate ? 'See and manage the vehicle' : 'Create a new vehicle'}</Typography.Paragraph>
          </Sider>
        </Row>
        <CreateModel
          handleModelCancel={closeModelCreate}
          isModalVisible={isCreateModelVisible}
          handleOnFinish={onFinishModelCreate}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('masterStore', 'vehicleStore')(observer(CreateVehicle));
