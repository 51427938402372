/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';
import Utility from '../../Global/Utility';

interface IVisitList{
    status: string
    index: number
    scheduled_time: number
    visitCode: string
    moveToDetailPage?: boolean
    goToVisitDetails: () => void
}

VisitList.defaultProps = {
  moveToDetailPage: false,
};

function VisitList(props: IVisitList) {
  const {
    status, index, visitCode, scheduled_time, goToVisitDetails, moveToDetailPage,
  } = props;

  const onClickHandler = () => {
    if (moveToDetailPage) goToVisitDetails();
  };
  return (
    <div className={classNames('d-flex justify-content-between border-bottom last_brd_0 pt-3 pb-2', { 'cursor-pointer': moveToDetailPage === true })} onClick={onClickHandler}>
      <div>
        <Typography.Title level={5} className="r-bold primary mb-1">
          {index}. {visitCode}
        </Typography.Title>
        <Typography.Paragraph className="secondary mb-2">
          {Utility.getFormattedDate(scheduled_time)}, {Utility.getFormattedTime(scheduled_time)}
        </Typography.Paragraph>
      </div>
      <Tooltip title={status}>
        <div
          className="label_green h-max rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-1 text-ellipsis"
        >
          <span className="text-ellipsis">{status}</span>
        </div>
      </Tooltip>
    </div>
  );
}

export default VisitList;
