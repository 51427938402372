/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import {
  Button, Col, DatePicker, Form, Modal, Row, TimePicker, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { Icon, Toast } from '..';

interface ScheduleNextVisitProps {
    isVisible: boolean,
    onCancel: () => void
    createServicesVisits: (data: any) => any
    timeRule: any,
    dateRule: any
    due_date: number
    form: any
    isLoading: boolean
    title?: string
    current_schedule_time?: number
    buttonText?: string
    scheduledTimeList?: [number]
}

ScheduleNextVisit.defaultProps = {
  title: 'Schedule Next Visit',
  current_schedule_time: 0,
  buttonText: 'Schedule',
  scheduledTimeList: [],
};

function ScheduleNextVisit(props: ScheduleNextVisitProps) {
  const {
    isVisible, onCancel, createServicesVisits, timeRule, dateRule, due_date, form,
    title, current_schedule_time, buttonText, scheduledTimeList, isLoading,
  } = props;

  const onOk = async () => {
    await form.validateFields();
    const timeTo: Date = form.getFieldValue('time');
    const date = form.getFieldValue('date');
    const epochTo = new Date(timeTo);
    const scheduled_time = new Date(date).setHours(
      epochTo.getHours(), epochTo.getMinutes(), epochTo.getSeconds(), 0,
    );
    if (scheduled_time < Date.now()) return Toast.error('Time must be greater than current time');
    if (scheduled_time > due_date) return Toast.error('Time must be less than expiry date');
    createServicesVisits(scheduled_time);
    return null;
  };

  useEffect(() => {
    if (current_schedule_time) {
      form.setFieldsValue({
        date: moment(current_schedule_time),
        time: moment(current_schedule_time),
      });
    }
  }, [current_schedule_time, isVisible]);

  const disabledDate = (current: any) => moment().add(-1, 'days') >= current || !!scheduledTimeList?.includes(current.format('YYYY-MM-DD'));
  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={onCancel}
      className="cmn_modal_wrapper"
      width={480}
      centered
      destroyOnClose
      confirmLoading={isLoading}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={onOk}
      okText={buttonText}
      okButtonProps={{ className: 'main_btn d-flex r-bold align-items-center', htmlType: 'submit' }}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
              )}
    >
      <Form
        layout="vertical"
        form={form}
        validateTrigger={['onBlur', 'onSubmit', 'onChange']}
        scrollToFirstError
      >
        <Row gutter={16} className="mt-3">
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="r-medium secondary">CHOOSE DATE</Typography.Text>
            <Form.Item name="date" className="mandatory" rules={dateRule}>
              <DatePicker
                className="inp_style date_picker"
                dropdownClassName="date_picker_drp_dwn"
                showToday={false}
                disabledDate={disabledDate}
                format="DD-MM-YYYY"
                suffixIcon={(
                  <Icon className="icon-16 primary" iconRef="#calendar" />
          )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xxl={12}>
            <Typography.Text className="r-medium secondary">CHOOSE TIME</Typography.Text>
            <Form.Item name="time" className="mandatory" rules={timeRule}>
              <TimePicker
                use12Hours
                className="inp_style w-100 h-40 shadow-none time_picker"
                popupClassName="custom_time_picker"
                suffixIcon={(
                  <Icon className="icon-16 primary" iconRef="#clock" />
          )}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <div className="d-flex justify-content-between align-items-center"> */}
        {/* <Button htmlType="submit" block type="primary" className="primary_bg
          text-white h-40 w-auto border-0 border-radius r-bold fs-16">
            {buttonText}
          </Button> */}
        {/* <Button block type="primary" className="red_bg
          text-white h-40 w-auto border-0 border-radius r-bold fs-16" onClick={onCancel}>
            Remove
          </Button> */}
        {/* </div> */}
      </Form>
    </Modal>
  );
}

export default ScheduleNextVisit;
