/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button, Col, Modal, Radio, Typography,
} from 'antd';

import {
  Icon, RenderItem,
} from '../../Components';
import Utility from '../../Global/Utility';
import { Terms } from '../../Global/interface';
import Constant from '../../Global/Constant';

interface IPlanDurationModal {
    visibility: boolean
    terms: any,
    selectedTerm: Terms,
    defaultTerm: Terms | null,
    onCancel: (data: any) => any,
    onOk: () => any,
    termChangeHandler: (term: Terms) => any
}

function PlanDurationModal(props:IPlanDurationModal) {
  const {
    visibility, terms, selectedTerm, defaultTerm, onCancel, onOk, termChangeHandler,
  } = props;

  const [termDetails, setTermDetails] = useState<any>({});

  const selectChangeHandler = (event: any) => {
    const termDetail = terms?.find((term: Terms) => term?.duration === event?.target?.value);
    setTermDetails(termDetail);
  };

  const okHandler = () => {
    termChangeHandler(termDetails);
    onOk();
  };

  return (
    <Modal
      visible={visibility}
      title="Change Term"
      onCancel={() => {
        onCancel(selectedTerm);
      }}
      onOk={okHandler}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      destroyOnClose
      footer={null}
      width={465}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={onCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
    )}
    >
      <Typography.Title level={5} className="fs-14 secondary r-medium text-uppercase">CHOOSE TERM FOR RENEWAL</Typography.Title>
      <Radio.Group
        buttonStyle="solid"
        className="d-flex flex-wrap"
        onChange={selectChangeHandler}
        defaultValue={selectedTerm?.duration || defaultTerm?.duration}
      >
        <RenderItem isTrue={terms?.length}>
          {
            terms?.map((term: Terms) => (
              <Radio.Button value={term?.duration} className="term_duration me-3 exo_fnt d-flex align-items-center px-2 mt-3">
                {Utility.reverseDuration(term?.duration, term?.duration_type)}
                <sub className="r-medium secondary fs-16 roboto position-absolute">{Constant?.durationTypeName.get(term?.duration_type)}</sub>
              </Radio.Button>
            ))
          }
        </RenderItem>
      </Radio.Group>

      <div className="d-flex mt-4 ps-lg-0 ps-4">
        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={okHandler}>
          Change term
        </Button>
        <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default PlanDurationModal;
