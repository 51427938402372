/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Skeleton, Table } from 'antd';
import React from 'react';
import { NoData, SortTh } from '..';
import Constant from '../../Global/Constant';
import { ActivityResponse } from '../../Global/interface';
import Utility from '../../Global/Utility';

interface UserActivitiesProps{
    userActivities: any,
      isLoading: any,
      sortActivity: any,
      orderBy: any,
}

function UserActivities(props: UserActivitiesProps) {
  const {
    userActivities, isLoading, sortActivity, orderBy,
  } = props;
  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="activity" />
    ),
  };

  const columns = [
    {
      title: 'Activity',
      dataIndex: 'activity',
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      dataIndex: 'date',
      title: <SortTh title="Date" dataIndex="date" order="date" orderBy={orderBy} />,
      onHeaderCell: () => ({
        onClick: () => {
          sortActivity();
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      ellipsis: true,
    },
  ];

  const data = userActivities.map((activity: ActivityResponse) => ({
    activity: Constant.SERVICES.get(activity?.service) || `${Constant.SERVICE_VISIT_ACTIVITY} (${activity?.activity_ref?.visit_code})`,
    action: Constant?.ACTIVITIES.get(activity?.activity),
    contract: activity?.contract?.contract_code || '-',
    vehicle: activity?.vehicle?.vehicle_no || activity?.vehicle?.vehicle_code || '-',
    date: Utility.getFormattedDate(activity?.createdAt),
    time: Utility.getFormattedTime(activity?.createdAt),
    key: activity?._id,
  }));

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      locale={locale}
      size="small"
      className="profile_pg_table table_wrapper ins_table_wrapper"
    />
  );
}

export default UserActivities;
