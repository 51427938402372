/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Modal,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Icon, RenderItem, Toast } from '..';
import Utility from '../../Global/Utility';

interface ModalProps {
  modalVisibility: boolean;
  onOk: (comment?: string) => void;
  onCancel: () => void;
  isLoading: boolean,
  title: string
  okButtonText: string
  text?: string
  isCancelRequest?: boolean
  okButtonClassName?: string
}

RequestModal.defaultProps = {
  text: 'Are you sure you want to accept this request?',
  okButtonClassName: '',
  isCancelRequest: false,
};

function RequestModal(props: ModalProps) {
  const [textInput, setTextInput] = useState('');
  const {
    modalVisibility,
    onOk,
    onCancel,
    isLoading,
    title,
    okButtonText,
    isCancelRequest,
    text,
    okButtonClassName,
  } = props;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(Utility.trimSpacesInValues(event?.target?.value));
  };

  const onModelClose = () => {
    setTextInput('');
    onCancel();
  };

  const onModelOk = () => {
    // if (Utility.validateText(textInput)) {
    //   return Toast.error('Special characters are not allowed.');
    // }
    onOk(textInput);
    setTextInput('');
    return '';
  };

  const okBtnClassName = isCancelRequest ? 'd-flex h-40 border-radius red_bg white border-0 r-bold align-items-center' : 'main_btn d-flex r-bold align-items-center';

  return (
    <Modal
      title={title}
      centered
      visible={modalVisibility}
      onOk={onModelOk}
      confirmLoading={isLoading}
      onCancel={onModelClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      okText={okButtonText}
      okButtonProps={{ className: okButtonClassName || okBtnClassName, disabled: isLoading }}
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
)}
    >
      <RenderItem isTrue={isCancelRequest}>
        <div>
          <span className="secondary">{text}</span>
          <div className="mt-3 mb-2 r-medium">NOTE:</div>
          <TextArea
            className="inp_style area_ht"
            placeholder="Add a note"
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={onChange}
            value={textInput}
          />
        </div>
      </RenderItem>

      <RenderItem isTrue={!isCancelRequest}>
        <span className="secondary">{text}</span>
      </RenderItem>

    </Modal>
  );
}

export default observer(RequestModal);
