/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Typography,
} from 'antd';
import { Icon } from '..';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;

interface IPaymentSuccessCard {
    time: number
    downloadInvoice:() => void
}

function PaymentSuccessCard(props: IPaymentSuccessCard) {
  const { time, downloadInvoice } = props;
  return (
    <Card className="dark_green_bg border-radius mb-3 shadow-none">
      <div className="d-sm-flex align-items-center justify-content-between">
        <div className="large_icn_bg dark_green white_bg p-0 rounded-circle justify-content-center d-flex align-items-center cir_outline me-5">
          <Icon className="icon-40" iconRef="#tick" />
        </div>
        <div className="d-flex align-items-center w-100 lg_icon_info_ht mt-3 mt-sm-0">
          <Typography className="w-100">
            <Title level={3} className="text-truncate primary r-bold mb-0 white fs-xs-18" title="Payment Successful">Payment Successful</Title>
            <Paragraph className="secondary mb-0 white">Paid {Utility.getRelativeDate(time)}</Paragraph>
          </Typography>
        </div>
        <Button className="d-flex align-items-center bg-transparent border-0 p-0 shadow-none white r-bold text-right mt-1" onClick={downloadInvoice}>
          Download Invoice
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle dark_green white_bg">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </div>
    </Card>
  );
}

export default PaymentSuccessCard;
