/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { Link } from 'react-router-dom';
import Status from './Status';

import {
  Icon, NoData, Pagination, Search, SortTh, Toast,
} from '../../Components';
import Routes from '../../Global/Routes';

import './Customers.less';
import Constant from '../../Global/Constant';
import { Client } from '../../Global/interface';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function Customers(props: any) {
  const { customerStore } = props;
  const { total, customers } = customerStore;
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<number>(-1);
  const [order, setOrder] = useState<string>('joined_date');
  const [seachFieldPresent, setseachFieldPresent] = useState(false);

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const columns = [
    {
      title: <SortTh title="Customer ID" dataIndex="client_code" order={order} orderBy={orderBy} />,
      dataIndex: 'client_code',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: <SortTh className="srt_icn_position" toolTipClassName="srt_icon_p" title="Customer Name" dataIndex="first_name" order={order} orderBy={orderBy} />,
      dataIndex: 'first_name',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: <SortTh title="Joined Date" dataIndex="joined_date" order={order} orderBy={orderBy} />,
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      dataIndex: 'joined_date',
      ellipsis: true,
    },
    {
      title: 'Orders',
      dataIndex: 'order_count',
    },
    {
      title: 'Status',
      dataIndex: 'active',
    },
  ];

  const gotoCreateCustomer = () => history.push(Routes.create_customer);

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="Customers" />
    ),
  };

  const getCustomerStatus = (client: Client) => {
    if (client?.disabled) return <Status status="Frozen" statusClassName="label_warning" />;
    if (!client?.is_invitation_send && client?.is_invited) return <Status status="Not invited" statusClassName="label_inactive" />;
    if (client?.active) return <Status status="Active" statusClassName="label_success" />;
    return <Status status="Pending signup" statusClassName="label_warning" />;
  };

  const getCustomerCode = (client: Client) => {
    const textColor = client?.contractCount ? 'green' : 'gray';
    return <span style={{ color: textColor }}>{client?.client_code}</span>;
  };

  const customerTableData = customers?.map((customer: Client) => ({
    client_code: getCustomerCode(customer),
    first_name: <div title={`${customer?.first_name} ${customer?.middle_name || ''} ${customer?.last_name}`} className="text-capitalize text-truncate">{customer?.first_name} {customer?.middle_name || ''} {customer?.last_name}</div>,
    joined_date: Utility.getFormattedDate(customer?.joined_date || customer?.createdAt),
    phone: customer?.phone || '-',
    order_count: customer?.order_count,
    active: getCustomerStatus(customer),
    rowKey: customer?._id,
    contracts: customer?.contractCount,
  }));

  const goToCustomerDetails = (record: any) => history.push(`/customer_details/${record?.rowKey}`);

  const fetchCustomers = async (dataIndex?: any, sortBy?: any) => {
    setIsLoading(true);
    await customerStore.listCustomers({
      keyword, page: pageNumber, limit: Constant.clientPerPage, order: dataIndex, orderBy: sortBy,
    })
      .then(() => {
        history.push(`${Routes.customers}`);
        setIsLoading(false);
        setseachFieldPresent(!!keyword);
      })
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error);
      });
  };

  const sortTable = (dataIndex: any) => {
    let sortBy = -1;
    if (orderBy === -1 && dataIndex === order) sortBy = 1;
    else if (dataIndex !== order) setOrder(dataIndex);
    setOrderBy(sortBy);
    fetchCustomers(dataIndex, sortBy);
  };

  const handleSearchTextChange = (event: any) => {
    setKeyword(event.target.value);
    setPageNumber(Constant.defaultPageNumber);
  };

  const showSearch = seachFieldPresent || total !== 0;

  useEffect(() => {
    customerStore?.initialize();
    fetchCustomers(order, orderBy);
  }, [pageNumber, keyword]);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={Routes.customer_history} className="d-flex primary ml-auto r-bold align-items-center existing_clnt_link p-0">
          Customer History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <Typography.Title className="m-0 header_main fs-xs-24 border-0 d-flex align-items-center">
                Customers
                <Tooltip title="Create Customer">
                  <Button className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle" onClick={gotoCreateCustomer}>
                    <Icon className="icon-13" iconRef="#add" />
                  </Button>
                </Tooltip>
              </Typography.Title>
            </div>
            <div className="action_resp d-xl-none act_position ms-sm-3">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-sm-center mb-3">
            <div className="d-sm-flex align-items-center flex-fill">
              <h4 className="mb-0 table-header r-bold">All Customers</h4>
              <Search
                value={keyword}
                placeholder="Search"
                titleToolTip="Search Customer"
                size="small"
                onChange={handleSearchTextChange}
                visibility={showSearch}
              />
            </div>
          </div>
          <Table
            onRow={(record) => ({ onClick: () => goToCustomerDetails(record) })}
            className="table_wrapper customer_listing"
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={customerTableData}
            pagination={false}
            locale={locale}
            size="small"
            scroll={{ x: '800px' }}
          />
          <Pagination
            total={total}
            pageSize={Constant.clientPerPage}
            currentPage={Number(pageNumber)}
            onChange={(page: number) => setPageNumber(page)}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the customers</Paragraph>
          </Typography>
          <div><h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
            <Tooltip title="Customer history">
              <Link to={Routes.customer_history} className="primary d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                Customer History
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Tooltip>
          </div>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('customerStore')(observer(Customers));
