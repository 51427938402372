/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  price: [{
    required: true,
    message: constant.priceRequired,
  },
  {
    validator: (field:any, value:any, callback:Function) => {
      if (value < 0) {
        callback(constant.priceRequired);
      }
      callback();
    },
  }],
  delivery_charge: [{
    required: true,
    message: constant.deliveryPriceRequired,
  }, {
    validator: (field:any, value:any, callback:Function) => {
      if (value < 0) callback(constant.deliveryPriceRequired);
      callback();
    },
  }],
};

export default rule;
