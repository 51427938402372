/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Col, DatePicker, Divider, Form, Modal, Row, Select, Spin, Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { useForm } from 'antd/lib/form/Form';
import { Icon, RenderItem, Toast } from '..';
import Rules from './create-contract-form.validation';
import {
  Contract, Facility,
} from '../../Global/interface';
import Utility from '../../Global/Utility';
import CustomPrice from '../../Pages/CreateClient/CustomPrice';
import Constant from '../../Global/Constant';
import planStore from '../../Store/planStore';
import contractStore from '../../Store/contractStore';
import masterStore from '../../Store/masterStore';
import clientStore from '../../Store/clientStore';

interface AddPlanProps {
    isLoading: boolean,
    isModalVisible: boolean,
    onCancel: () => any,
    onOk: (value : any) => any,
    contractId: string,
    selectedPlan: string,
    clientId: string
}

function EditContract(props: AddPlanProps) {
  const {
    isLoading, isModalVisible, contractId, selectedPlan, clientId,
    onCancel, onOk,
  } = props;

  const [terms, setTerms] = useState<any>(null);
  const [facilities, setFacilities] = useState<Array<Facility>|null>(null);
  const [isPlanPaid, setIsPlanPaid] = useState<boolean>(false);
  const [isPlanClosed, setIsPlanClosed] = useState<boolean>(false);
  const [isCustomChecked, setisCustomChecked] = useState<boolean>(false);
  const [isVehicleInside, setIsVehicleInside] = useState<boolean>(false);
  const [termAmount, setTermAmount] = useState<number>();
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [planTypes, setPlanTypes] = useState<any>(null);
  const [form] = useForm();

  const vehicles = clientStore?.vehicles?.data;
  const { Option } = Select;

  const updateContract = async (values: any) => {
    try {
      setIsModalLoading(true);
      const termDetails = terms?.find((item: any) => item?._id === values?.plan_duration);
      values.plan_duration = termDetails?.duration;
      values.duration_type = termDetails?.duration_type;

      const { contract } = contractStore;
      const {
        facility, plan_duration, start_date, parked_date, plan_type, duration_type,
        custom_amount, price, vehicle, closed_date,
      } = values;

      if (parked_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (parked_date > dueDate || parked_date < start_date) {
          setIsModalLoading(false);
          return Toast.error(Constant.parkedDateError);
        }
      }

      if (closed_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (closed_date < dueDate) {
          setIsModalLoading(false);
          return Toast.error(Constant.closedDateError);
        }
      }

      const startDate = new Date(start_date);

      const contractForm: any = {
        clientId,
        plan_duration,
        duration_type,
        contract_plan: plan_type,
        facility,
        custom_amount,
        price,
      };

      if (isPlanClosed) {
        contractForm.closed_date = new Date(closed_date).getTime();
      }

      if (isPlanPaid) {
        contractForm.start_date = startDate.getTime();
      }

      if (contract?.contract?.plan_type === 'VAULT') {
        if (!isVehicleInside && contract?.active_vehicle) {
          contractForm.is_vehicle_parked = false;
        } else if (isVehicleInside) {
          contractForm.vehicle = vehicle;
          contractForm.parked_date = new Date(parked_date).getTime();
        }
      }

      if (Utility.checkIfToday(startDate)) {
        contractForm.sendPlanInvoice = true;
      }
      onOk(contractForm);
      setIsModalLoading(false);
    } catch (error: any) {
      setIsModalLoading(false);
      Toast.error(error);
    }
  };

  const fetchPlanDetails = async (planId: string) => {
    try {
      setIsModalLoading(true);
      await planStore?.getPlanById(planId);
      if (planStore?.plan?.plan_type === 'VAULT') {
        setFacilities(planStore?.planTerms?.map((item: any) => item?.facility));
      } else {
        setTerms(planStore?.planTerms[0]?.termDetails);
      }
      setIsModalLoading(false);
    } catch (error: any) {
      setIsModalLoading(false);
      Toast?.error(error);
    }
  };

  const getInitialData = async () => {
    try {
      setIsModalLoading(true);
      const promises = [
        masterStore.getContractPlans(),
        contractStore.getContractById(contractId),
        fetchPlanDetails(selectedPlan),
        clientStore.getClientVehicle({ clientId, sharedVehicle: false }),
      ];
      const responses = await Promise.all(promises);
      const planTypeList = responses[0];
      setPlanTypes(planTypeList);
      setIsModalLoading(false);
    } catch (error: any) {
      setIsModalLoading(false);
      Toast.error(error);
    }
  };

  useEffect(() => {
    if (contractId) {
      getInitialData();
    }
  }, [contractId]);

  useEffect(() => {
    if (contractStore?.contract && planStore?.planTerms && planTypes) {
      populatefields();
    }
  }, [contractStore?.contract, planStore?.planTerms, planTypes]);

  const handleFacilityChange = (facilityId: string) => {
    form.resetFields(['plan_duration']);
    const selectedFacility = planStore?.planTerms?.find(
      (item: any) => item?.facility?._id === facilityId,
    );
    setTerms(selectedFacility?.termDetails);
    return selectedFacility?.termDetails;
  };

  const populatefields = async () => {
    setIsModalLoading(true);
    const {
      duration_type, duration, contract: contractPlan, facility, contract_status, closed_date,
      payment_status, active_vehicle, start_date, price, parked_date, custom_amount,
    } = contractStore?.contract as Contract;

    let updatedTerm;
    if (contractPlan?.plan_type === 'VAULT') {
      updatedTerm = handleFacilityChange(facility?._id);
    } else {
      updatedTerm = contractPlan?.terms;
    }
    if (contract_status === 'CLOSED') {
      setIsPlanClosed(true);
    }
    setisCustomChecked(!!custom_amount);
    const activeTerm = updatedTerm?.find((item: any) => item.duration === duration
    && item?.duration_type === duration_type);
    setTermAmount(activeTerm?.price);
    const activeVehicle = contractStore?.contract?.active_vehicle as any;

    setIsPlanPaid(!!payment_status);
    setIsVehicleInside(!!activeVehicle?._id);
    form.setFieldsValue({
      contract_plan: selectedPlan,
      plan_duration: activeTerm?._id,
      facility: facility?._id,
      start_date: moment(start_date),
      closed_date: contract_status === 'CLOSED' && closed_date ? moment(closed_date) : '',
      parked_date: active_vehicle ? moment(parked_date) : '',
      custom_amount,
      price,
      vehicle: activeVehicle?._id,
    });
    setIsModalLoading(false);
  };

  const disabledDateForFuture = (current: Moment): boolean => current && current > moment();

  const isVaultPlan = (plan: any) => {
    const planDetail = planTypes?.find((item: any) => item?._id === plan);
    return planDetail?.plan_type === 'VAULT';
  };

  const handleTermSelection = (value: any) => {
    const termDetails = terms?.filter((term: any) => term?._id === value);
    setTermAmount(termDetails[0]?.price);
  };

  const vehicleCheckboxHandler = () => setIsVehicleInside(!isVehicleInside);

  return (
    <Modal
      title="Update Plan"
      visible={isModalVisible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      maskClosable={!(isModalLoading || isLoading)}
      width={1000}
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={onCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
    )}
    >
      <div>
        <Spin spinning={isLoading || isModalLoading}>
          <Form layout="vertical" onFinish={updateContract} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
            <Row
              gutter={{
                xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
              }}
              className="mt-3"
            >
              <Col lg={4} md={24} sm={24} xs={24}>
                <Typography.Title className="m-0" level={5}>
                  Plan details
                </Typography.Title>
              </Col>
              <Col lg={20} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-between">
                  <Typography.Text className="m-0 create_para">
                    Enter plan details
                  </Typography.Text>
                </div>
                <div className="add_cnt_wrapper">
                  <Row gutter={16} className="mt-3">
                    {/* PLAN TYPE */}
                    <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                      <Form.Item
                        label="Plan type"
                        className="mandatory"
                        name="contract_plan"
                        rules={Rules.plan_type}
                      >
                        <Select
                          suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                          placeholder="Choose plan type"
                          className="slct_style"
                          dropdownClassName="slct_drp_dwn border-radius"
                          disabled
                        >
                          {planTypes?.map((plan: any) => (
                            <Option value={plan?._id}>{plan?.plan}</Option>
                          ))}

                        </Select>
                      </Form.Item>
                    </Col>
                    {/* FACILITY */}
                    {selectedPlan && isVaultPlan(selectedPlan) && (
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="facility"
                          className="mandatory"
                          name="facility"
                          rules={Rules.facility}
                        >
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onChange={handleFacilityChange}
                            disabled={!selectedPlan}
                          >
                            {facilities?.map((facility: any) => (
                              <Option
                                value={facility._id}
                                disabled={
                                facility?.used_capacity >= facility?.total_capacity
                               }
                              >
                                {facility?.name}<br />
                                <span className={classNames('text-uppercase secondary r-regular d-flex align-items-center', { disabled: facility?.used_capacity >= facility?.total_capacity })}>{facility?.address}
                                  <Icon className="icon-12 ms-2" iconRef="#location" />
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                    {/* PLAN DURATION */}
                    <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                      <Form.Item
                        label="Plan period"
                        className="mandatory"
                        name="plan_duration"
                        rules={Rules.duration}
                      >
                        <Select
                          suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                          placeholder="Choose plan period"
                          className="slct_style"
                          dropdownClassName="slct_drp_dwn border-radius"
                          onChange={handleTermSelection}
                        >
                          {terms?.sort(
                            (a: any, b: any) => a?.duration - b?.duration,
                          )?.map((term: any) => (
                            <Option value={term?._id}>
                              {`${Utility?.reverseDuration(term?.duration, term?.duration_type)} ${Constant?.durationTypeName?.get(term?.duration_type)}`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {(termAmount)
                      && (
                      <Col xs={24} sm={24} md={24} lg={24} xxl={10}>
                        {/* <Typography.Title level={5}>
                          Current Plan Price: {currentPrice} KD
                        </Typography.Title> */}
                        <CustomPrice
                          rule={Rules?.price}
                          termAmount={termAmount as number}
                          form={form}
                          defaultCustomPrice={isCustomChecked}
                        />
                      </Col>
                      )}

                  </Row>
                  <RenderItem isTrue={isPlanClosed}>
                    <Row gutter={16} className="mt-3">
                      {/* plan closed check box */}
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Checkbox
                          checked={isPlanClosed}
                          className="chkbox_style primary r-regular pt-0 checkbox_disable_after"
                          disabled
                        >
                          Plan is closed
                        </Checkbox>
                      </Col>
                      {/* PLAN CLOSED DATE */}
                      {isPlanClosed
                      && (
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="closed date"
                          className="mandatory mt-3"
                          name="closed_date"
                          rules={Rules.closed_date}
                        >
                          <DatePicker
                            className="inp_style date_picker primary"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose closed date"
                            showToday={false}
                            disabledDate={disabledDateForFuture}
                            format="DD-MM-YYYY"
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                          )}
                          />
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                  </RenderItem>
                  <Row gutter={16}>
                    {/* PLAN START DATE */}
                    {isPlanPaid
                      && (
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="start date"
                          className="mandatory mt-3"
                          name="start_date"
                          rules={Rules.start_date}
                        >
                          <DatePicker
                            className="inp_style date_picker primary"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose start date"
                            showToday={false}
                            disabledDate={disabledDateForFuture}
                            format="DD-MM-YYYY"
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                          )}
                          />
                        </Form.Item>
                      </Col>
                      )}
                  </Row>
                  <Row gutter={16} className="mt-3">
                    {/* vehicle inside check box */}
                    {isPlanPaid && selectedPlan && isVaultPlan(selectedPlan) && !isPlanClosed
                      && (
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Checkbox
                          checked={isVehicleInside}
                          className="chkbox_style primary r-regular mb-3 checkbox_disable_after"
                          onChange={vehicleCheckboxHandler}
                        >
                          Vehicle is inside the facility now
                        </Checkbox>
                      </Col>
                      )}
                    {/* VEHICLE */}
                    {isVehicleInside && isPlanPaid && selectedPlan && isVaultPlan(selectedPlan)
                      && (
                      <>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item
                            label="Vehicle"
                            className="mandatory"
                            name="vehicle"
                            rules={Rules.vehicle}
                          >
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose Vehicle"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                            >
                              {vehicles?.length && vehicles?.map((vehicle:any) => (
                                <Option
                                  className="varient_option"
                                  disabled={vehicle?.contractPlan}
                                  value={vehicle?._id}
                                >
                                  <div className="d-flex align-items-center">
                                    {`${vehicle?.model?.company} ${vehicle?.model?.model}`}
                                  </div>
                                  <div>
                                    {vehicle?.vehicle_no
                                      ? vehicle?.vehicle_no : vehicle?.vehicle_code}
                                  </div>
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item
                            label="parked date"
                            className="mandatory"
                            name="parked_date"
                            rules={Rules.parked_date}
                          >
                            <DatePicker
                              className="inp_style date_picker primary"
                              dropdownClassName="date_picker_drp_dwn"
                              placeholder="Choose date"
                              showToday={false}
                              format="DD-MM-YYYY"
                              disabledDate={disabledDateForFuture}
                              suffixIcon={(
                                <Icon className="icon-16 primary" iconRef="#calendar" />
                            )}
                            />
                          </Form.Item>
                        </Col>
                      </>
                      )}
                  </Row>
                </div>
                <Divider className="m-0 border-top my-3" />
              </Col>
              <Col lg={4} md={24} sm={24} xs={24} />
              <Col lg={20} md={24} sm={24} xs={24}>
                <div className="d-flex mt-4 ps-lg-0 ps-4">
                  <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
                    Update Plan
                  </Button>
                  <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" disabled={isLoading} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
}

export default EditContract;
