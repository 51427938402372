/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Typography,
} from 'antd';
import './DashboardShop.less';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Icon, OrderChart } from '..';
import Routes from '../../Global/Routes';
import { IDashboardStore } from '../../Store/dashboardShopStore';

interface IOrderChartCard {
    dashboardShopStore?: IDashboardStore
    setCurrentYear: Function
    currentYear: any
}

OrderChartCard.defaultProps = {
  dashboardShopStore: undefined,
};

function OrderChartCard(props: IOrderChartCard) {
  const { dashboardShopStore, currentYear, setCurrentYear } = props;

  return (
    <Card bordered={false} className="dash_card_resp_fnt">
      <div className="d-flex justify-content-between align-items-center">
        <Typography.Title level={3} className="r-bold">Orders</Typography.Title>
        <Link
          to={Routes.orders}
          className="d-flex ml-auto r-bold align-items-center p-0 link_text secondary"
        >
          View
          <div
            className="ms-2 d-flex justify-content-center align-items-center text-center
                      p-0 small_icon rounded-circle secondary_bg"
          >
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </div>
      <OrderChart orderDetails={dashboardShopStore?.orderDetails} />
      <div className="d-sm-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center mb-2 mb-sm-0">
          {/* <Icon className="icon-16 green" iconRef="#arrow_up" /> */}
          <Typography.Title level={3} className="mb-0 mx-3" />
          <Typography.Paragraph className="fs-16 secondary mb-0" />
        </div>
        <div className="d-flex align-items-center">
          <Typography.Title level={3} className="mb-0 me-3">{currentYear}</Typography.Title>
          <div>
            <Button
              className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg me-2 primary"
              disabled={currentYear <= 1}
              onClick={() => setCurrentYear((current: any) => current - 1)}
            >
              <Icon className="icon-12" iconRef="#arrow_left" />
            </Button>
            <Button
              className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg primary"
              onClick={() => setCurrentYear((current: any) => current + 1)}
              disabled={currentYear >= new Date().getFullYear()}
            >
              <Icon className="icon-12" iconRef="#arrow_right" />
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
export default inject('dashboardShopStore')(observer(OrderChartCard));
