/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Divider,
  Radio,
  Row,
  Typography,
} from 'antd';
import {
  Action, Icon, RenderItem, ServiceReqDateStatus, ServiceReqPaymentMethod, StartCancelRequest,
} from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface Props {
  statusName: string
  IconRef: any
  attachmentStatus: any
  request?: any
  updateServiceRequest: (id: string, action: any) => void
  updateVehicleStatus: (statusData: any, id?: string) => void,
  clientEmail?: string
  clientPhone?: string
  date?: number
  price?: number
  isLoading: boolean
  sendPaymentLink?: (requestId: string, data: any) => void
}

VehicleAttachment.defaultProps = {
  request: null,
  price: null,
  clientEmail: null,
  clientPhone: null,
  date: null,
  sendPaymentLink: () => {},
};

const { OPEN, PAYMENT_PENDING } = Constant.serviceRequestStatus;
const { COMPLETE, PAYMENT_DONE } = Constant.serviceRequestActions;
const { GATEWAY } = Constant.payment_mode;

function VehicleAttachment(props: Props) {
  const {
    statusName, IconRef, attachmentStatus, request, updateVehicleStatus,
    updateServiceRequest, clientEmail, clientPhone, date,
    sendPaymentLink, price, isLoading,
  } = props;

  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [isAttached, setIsAttached] = useState(
    attachmentStatus?.value || Constant.defaultAttachment,
  );
  const isRequestStarted = request?.status === OPEN;

  const onConfirm = () => {
    setIsStatusChecked(false);
    updateVehicleStatus(isAttached);
  };

  const onCancel = () => {
    setIsStatusChecked(false);
    setIsAttached(attachmentStatus?.value || Constant.defaultAttachment);
  };

  const radioBtnHandler = () => {
    setIsStatusChecked(!isStatusChecked);
    setIsAttached(!isAttached);
  };

  const updateRequest = (data: any) => updateServiceRequest(request?._id, data);

  const onFinishHandler = () => {
    updateVehicleStatus(isAttached, request?._id);
    updateServiceRequest(request?._id, { action: COMPLETE });
  };

  useEffect(() => {
    setIsAttached(attachmentStatus?.value || Constant.defaultAttachment);
  }, [attachmentStatus?.value]);

  const isPaymentPending = request?.status === PAYMENT_PENDING;

  const completeHandler = async (data: any) => {
    if (data?.payment_mode === GATEWAY) {
      if (sendPaymentLink) sendPaymentLink(request?._id, data);
    } else {
      updateServiceRequest(request?._id,
        { action: PAYMENT_DONE, payment_mode: data?.payment_mode, email: data?.email });
    }
  };

  const totalAmount = Utility.getTotalAmount(request?.price, request?.delivery_charge);

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      { request && (
      <div>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16">
              <div className="icn_bg_44 white rounded-circle d-flex align-items-center justify-content-center me-3 primary_bg">
                <Icon className="icon-24" iconRef={IconRef} />
              </div>
              <div className="lh-sm r-bold fs-16">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                {statusName}
              </div>
            </div>
          </Col>
          <ServiceReqDateStatus
            isLoading={isLoading}
            isRequestStarted={isRequestStarted}
            scheduled_time={request?.scheduled_time}
            updateRequest={updateRequest}
          />
        </Row>
      </div>
      ) }
      <RenderItem isTrue={!isPaymentPending}>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            { !request
          && (
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <div className="icn_bg_44 white rounded-circle d-flex align-items-center justify-content-center me-3 primary_bg">
              <Icon className="icon-24" iconRef={IconRef} />
            </div>
            {statusName}
          </div>
          ) }
          </Col>
          <Col lg={9} md={9} sm={9} xs={24}>
            <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Status</Typography.Text>
            <Radio.Group
              className="cust_radio_btn d-sm-flex"
              disabled={isRequestStarted}
              onChange={radioBtnHandler}
              value={isAttached}
            >
              <Radio className="radio_inp" value>Attached</Radio>
              <Radio className="radio_inp" value={false}>Detached</Radio>
            </Radio.Group>
            {attachmentStatus?.last_updated_time && <Typography.Paragraph className="fs-16 mt-2 mb-0 inactive">Last updated - {Utility.getFormattedDate(attachmentStatus?.last_updated_time)}</Typography.Paragraph>}
            { request && !isRequestStarted
        && (
        <Button
          className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
          disabled={!isStatusChecked}
          onClick={onFinishHandler}
        >Finish Task
        </Button>
        )}
          </Col>
          { !request && isStatusChecked && (
            <Col
              lg={5}
              className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
            ><Action onAccept={onConfirm} onReject={onCancel} />
            </Col>
          )}
        </Row>
      </RenderItem>
      <RenderItem isTrue={isPaymentPending}>
        <ServiceReqPaymentMethod
          paymentHeading={`${statusName} (${totalAmount || price} KD)`}
          email={clientEmail as string}
          finishHandler={completeHandler}
          okButtonText="Complete Request"
          phone={clientPhone as string}
          date={date as number}
        />
      </RenderItem>
    </div>
  );
}
export default VehicleAttachment;
