/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Layout,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import './ClientHistory.less';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import {
  Icon,
  NoData,
  Pagination,
  Search,
  SortTh,
  Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import { Client } from '../../Global/interface';
import Utility from '../../Global/Utility';
import { ICustomerStore } from '../../Store/customerStore';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IClientHistory{
  customerStore: ICustomerStore
}

function ClientHistory(props: IClientHistory) {
  const { customerStore } = props;
  const { customerHistory, historyTotal } = customerStore;
  const [isLoading, setIsLoading] = useState(false);
  const [clientKeyword, setClientKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(-1);
  const [order, setOrder] = useState('client_code');
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();

  const columns = [
    {
      title: <SortTh title="Customer ID" dataIndex="client_code" order={order} orderBy={orderBy} />,
      dataIndex: 'client_code',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Customer Name',
      dataIndex: 'first_name',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      ellipsis: true,
    },
    {
      title: 'Orders',
      dataIndex: 'order_count',
    },
    {
      title: 'Joined Date',
      dataIndex: 'joined_date',
      ellipsis: true,
    },
    {
      title: 'Deleted Date',
      dataIndex: 'updatedAt',
      ellipsis: true,
    },
  ];

  const getCustomerCode = (client: Client) => {
    const textColor = client?.contractCount ? 'green' : 'gray';
    return <span style={{ color: textColor }}>{client?.client_code}</span>;
  };

  const clientTableData = customerHistory?.map((customer: Client) => ({
    client_code: getCustomerCode(customer),
    first_name:
  <div title={Utility.getUserName(customer)} className="text-capitalize text-truncate">
    {Utility.getUserName(customer)}
  </div>,
    joined_date: Utility.getFormattedDate(customer?.joined_date || customer?.createdAt),
    phone: customer?.phone || '-',
    order_count: customer?.order_count,
    rowKey: customer?._id,
    contracts: customer?.contractCount,
    email: customer?.email || '-',
    updatedAt: Utility.getFormattedDate(customer?.updatedAt),
  }));

  const sortTable = (dataIndex: string) => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    setOrder(dataIndex);
    getClientHistory(pageNumber, clientKeyword, dataIndex, nextSort);
  };

  const getClientHistory = async (
    page: number, keyword?: string, sort?: string, sortBy?: number,
  ) => {
    setIsLoading(true);
    customerStore.getCustomerHistory({
      keyword,
      page,
      limit: Constant.clientPerPage,
      order: sort,
      orderBy: sortBy,
      deleted: true,
    }).catch((error: any) => {
      Toast.error(error);
    });
    setIsLoading(false);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Spin spinning /> : <NoData subTitle="customers" />
    ),
  };

  const debounceHandler = useCallback(
    debounce((nextValue: string, page: number, sort: string, sortBy: number) => {
      setPageNumber(page);
      getClientHistory(
        page, nextValue, sort, sortBy,
      );
    }, 1000),
    [],
  );

  const searchHandler = (event: any) => {
    const { value: nextValue } = event.target;
    setClientKeyword(nextValue);
    debounceHandler(nextValue, Constant.defaultPageNumber, order, orderBy);
  };

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getClientHistory(page, clientKeyword, order, orderBy);
  };

  useEffect(() => {
    customerStore?.initialize();
    getClientHistory(Constant.defaultPageNumber);
  }, []);

  const goToClientDetails = (record: any) => history.push(`/customer_details/${record?.rowKey}?deleted=0`);

  return (
    <Layout.Content className="listing_wrapper client_history">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary" to={Routes.customers}>
            <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_left" />
            </div>
            Back
          </Link>
          <Typography.Title className="m-0 header_main mb-2 pb-3 d-flex align-items-center fs-xs-24">
            Customer History
          </Typography.Title>
          <div className="approval_listing">
            <div className="section_off_set" id="active_cnrct">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-sm-flex align-items-center flex-fill">
                  <h4 className="mb-0 table-header r-bold">Deleted Customers</h4>
                  <Search
                    onChange={searchHandler}
                    value={clientKeyword}
                    placeholder="Search"
                    titleToolTip="Search Customers"
                    size="small"
                  />
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={clientTableData}
                pagination={false}
                size="small"
                scroll={{ x: '800px' }}
                className="table_wrapper"
                locale={locale}
                rowClassName="cursor-pointer"
                onRow={(record) => ({ onClick: () => goToClientDetails(record) })}
              />
              <Pagination
                total={historyTotal}
                currentPage={pageNumber}
                pageSize={Constant.clientPerPage}
                onChange={pageHandler}
              />
            </div>
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See the customer history</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('customerStore')(observer(ClientHistory));
