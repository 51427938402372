/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import { Button, Carousel, Typography } from 'antd';
import React, { useState } from 'react';
import classnames from 'classnames';

import { Icon, AddressDetails } from '..';

import './Address.less';

interface AddressProps{
    country: String,
    state: String,
    city: String,
    address_line_1: String,
    address_line_2: String,
    zip_code: Number,
}

interface AddressCardProps{
    addressInfo : AddressProps | Array<AddressProps> | any,
    title?: String
}

Address.defaultProps = {
  title: 'Address',
};

function Address(props: AddressCardProps) {
  const { addressInfo, title } = props;
  const carousel: any = React.createRef();
  const [index, setIndex] = useState<number>(0);
  const moveToNext = () => {
    setIndex(index + 1);
    carousel.current.next();
  };

  const moveToPrev = () => {
    setIndex(index - 1);
    carousel.current.prev();
  };

  const setRef = (node:any) => {
    carousel.current = node;
  };

  const disablePrev = index === 0;
  const disableNext = index >= addressInfo?.length - 1;
  return (
    <div className="detail_card_wrapper">
      {Array.isArray(addressInfo)
        ? (
          <>
            <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
              <div className="d-flex">
                <Typography.Title level={4} className="fs-xs-18">{title}</Typography.Title>
                {index === 0 && addressInfo?.length > 1
                && (
                <div className="off-white-bg secondary rounded-pill px-2
         ms-2 h-32 d-flex align-items-center
         justify-content-center fs-16"
                >
                  Default
                </div>
                )}
              </div>
              {addressInfo?.length > 1
                ? (
                  <div>
                    <Button className={classnames('icn_cir_wdt rounded-circle p-0 border-0 gray_bg me-2 primary', { disabled: disablePrev })} disabled={disablePrev} onClick={moveToPrev}>
                      <Icon className="icon-12" iconRef="#arrow_left" />
                    </Button>
                    <Button className={classnames('icn_cir_wdt rounded-circle p-0 border-0 gray_bg primary', { disabled: disableNext })} onClick={moveToNext} disabled={disableNext}>
                      <Icon className="icon-12" iconRef="#arrow_right" />
                    </Button>
                  </div>
                ) : ''}
            </div>
            <Carousel arrows={false} dots={false} ref={setRef}>

              {addressInfo?.map((address) => (
                <div>
                  <AddressDetails addressInfo={address} />
                </div>
              ))}
            </Carousel>
          </>
        )
        : (
          <div className="align-items-center justify-content-between mb-2">
            <div>
              <Typography.Title level={4} className="fs-xs-18">{title}</Typography.Title>
            </div>
            <div>
              <AddressDetails addressInfo={addressInfo} />
            </div>
          </div>

        )}
    </div>
  );
}

export default Address;
