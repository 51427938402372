/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Steps,
  Tooltip,
  Typography,
} from 'antd';

import classNames from 'classnames';
import { Action, ConfirmModal } from '../../Components';

export interface CancelStepsProps {
    currentDot: number,
    payment_status: boolean,
    updateCancelStatus: (action: string, payment_mode?: string) => Promise<void>,
    handleRefund: () => any
    isRefundDisabled: boolean,
}

function CancelSteps(props: CancelStepsProps) {
  const {
    currentDot, payment_status, updateCancelStatus, handleRefund, isRefundDisabled,
  } = props;

  const { Step } = Steps;

  const acceptRequest = () => {
    ConfirmModal('Accept Request', 'Do you want to accept this request?', () => updateCancelStatus('approve'), 'Accept',
      'Black');
  };

  const rejectRequest = () => {
    ConfirmModal('Reject Request', 'Do you want to reject this request?', () => updateCancelStatus('reject'), 'Reject');
  };

  return (
    <Card className="mt-3">
      <Typography.Title level={3} className="mb-4">Cancel Order</Typography.Title>
      <Steps progressDot current={currentDot} direction="vertical" className="custom_steps">
        <Step
          title={<Typography.Title level={5} className={classNames('primary r-bold mb-0', { disable_clr: currentDot === 0 })}>Approval</Typography.Title>}
          description={(
            <div className="d-sm-flex align-items-center justify-content-between">
              <Typography.Paragraph className={classNames('secondary fs-16 m-0', { disable_clr: currentDot === 0 })}>Approve or reject the refund the order</Typography.Paragraph>
              {currentDot === 0
              && (
              <Action
                onAccept={acceptRequest}
                onReject={rejectRequest}
              />
              )}
            </div>
                      )}
        />
        <Step
          title={(<Typography.Title level={5} className={classNames('primary r-bold mb-0', { disable_clr: currentDot <= 1 })}>Refund Item</Typography.Title>)}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph className={classNames('secondary fs-16 m-0', { disable_clr: currentDot <= 1 })}>Initiate refund and complete the cancel request</Typography.Paragraph>
                <div>
                  {payment_status && currentDot === 1
                  && (
                    <>
                      <Tooltip title={isRefundDisabled ? 'Refund already initiated' : 'Refund'}>
                        <Button disabled={isRefundDisabled} onClick={handleRefund} className={isRefundDisabled ? 'refund_disabled_btn' : 'border-0 shadow-none border-radius text-white r-bold green_bg h-40'}>Refund</Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </div>
                      )}
        />
      </Steps>
    </Card>
  );
}

export default CancelSteps;
