/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card, Col, Divider, Row, Typography,
} from 'antd';
import './CreateProduct.less';
import 'react-phone-number-input/style.css';
import {
  Icon, PreviewButton, Toast,
} from '../../Components';
import { Product, Variants } from '../../Global/interface';
import VariantForm from './VariantForm';

interface IVarients {
  product: Product
  newVariantList: string
  isLoading: boolean
  showPreviewPublishBtn: boolean
  createVariant: (variant: Variants) => any
  updateVariant: (variant: Variants, id: string) => any
  goToProductListing: () => void
  addVariantList: () => void
  removeVariantList: () => void
  deleteVariant: (id: string) => void
  viewPublishConfirnModal: () => void
  getProductById: () => void
}

function Varients(props: IVarients) {
  const {
    product, createVariant, updateVariant, goToProductListing, deleteVariant,
    addVariantList, removeVariantList, newVariantList, viewPublishConfirnModal,
    isLoading, getProductById, showPreviewPublishBtn,
  } = props;
  const [duplicatedData, setDuplicatedData] = useState<any>();
  const [isVariantEditable, setIsVariantEditable] = useState<boolean>(false);

  const updateVariantEditable = (value: boolean) => setIsVariantEditable(value);

  const createDuplicateForm = (data: any) => {
    setDuplicatedData(data);
    addVariantList();
    Toast.success('Variant duplicated');
  };

  const removeVariantForm = () => {
    setDuplicatedData('');
    removeVariantList();
  };

  const handleContinueHandle = () => {
    if (!product?.variants?.length) Toast.warning('Please add a variant.');
  };

  const deleteVariantHandler = (id: string) => {
    if (product?.variants?.length > 1 || !product?.published) deleteVariant(id);
    else if (product?.published) Toast.error('Published product should have atleast one variant.');
  };

  const disableActions = isVariantEditable || !!newVariantList;

  return (
    <Card className="create_card overflow-auto">
      <Row
        gutter={{
          xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
        }}
        className="m-0"
      >
        <Col lg={4} md={24} sm={24} xs={24}>
          <Typography.Title className="m-0" level={5}>
            Variants
          </Typography.Title>
        </Col>
        <Col lg={20} md={24} sm={24} xs={24}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Typography.Text className="m-0 create_para me-2 me-sm-0">
              Add your product variants
            </Typography.Text>
            <Button disabled={disableActions} className="d-flex ml-auto r-bold align-items-center back_link p-0" type="link" onClick={addVariantList}>
              Add
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Button>
          </div>
          <div className="varient-wrap">

            {product?.variants?.map((variant: Variants) => (
              <VariantForm
                key={variant?._id}
                product={product}
                variant={variant}
                createVariant={createVariant}
                updateVariant={(value: Variants) => updateVariant(value, variant?._id)}
                duplicateVariantForm={createDuplicateForm}
                deleteVariant={() => deleteVariantHandler(variant?._id)}
                isLoading={isLoading}
                getProductById={getProductById}
                updateVariantEditable={updateVariantEditable}
                isVariantEditable={disableActions}
              />
            ))}

            {newVariantList
              && (
              <VariantForm
                product={product}
                variant={duplicatedData}
                createVariant={createVariant}
                removeVariantList={removeVariantForm}
                isLoading={isLoading}
                getProductById={getProductById}
                updateVariantEditable={updateVariantEditable}
                isVariantEditable={disableActions}
              />
              )}
          </div>
          <Divider className="mt-3 border-top" />
          <div className="d-sm-flex justify-content-between mt-sm-4">
            <div className="d-sm-flex">
              <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold mt-3 mt-sm-0" onClick={goToProductListing}>
                Done
              </Button>
            </div>
            <PreviewButton
              visibility={showPreviewPublishBtn}
              disablePublishButton={disableActions}
              viewPublishConfirnModal={viewPublishConfirnModal}
              publishButtonText={product?.published ? 'Unpublish Product' : 'Publish Product'}
              buttonColor={product?.published ? 'yellow' : 'green'}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default observer(Varients);
