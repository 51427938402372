/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

const rule: any = {
  phone: [
    {
      validator: Utility?.phoneRequiredValidator, // phone update
    },
  ],
  email: [{
    validator: (field:any, value:any, callback:Function) => {
      if (value && !(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value))) {
        callback(constant.myfatoorahErrorEmail);
      }
      callback();
    },
  },
  {
    required: true,
    message: constant.emailRequiredValidationError,
  }],
};

export default rule;
