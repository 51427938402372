/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import classNames from 'classnames';

import {
  Icon, Toast, OrderItem, ActivityCard, StatusLabel, ConfirmModal,
} from '../../Components';
import ReturnSteps from './ReturnSteps';
import RejectReason from '../OrderReturnDetails/RejectReason';
import { IOrderStore } from '../../Store/orderStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import authStore from '../../Store/authStore';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

const { ORDER_CANCELED, ORDER_RETURNED } = Constant?.order_status;
const { PRODUCT_REJECTED } = Constant.order_request_action;
const { CASH } = Constant?.payment_mode;
const { CLOSED } = Constant?.order_request_status;

interface OrderReturnDetailsProps {
  orderStore: IOrderStore
}
function OrderReturnDetails(props: OrderReturnDetailsProps) {
  const { orderStore } = props;
  const {
    order, category, product, variant, activities, request,
  } = orderStore;

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [isActivityLoading, setIsActivityLoading] = useState<boolean>(false);

  const showReturn = request?.status !== CLOSED;

  const showViewOrder = order?.order_status !== ORDER_CANCELED
  && order?.order_status !== ORDER_RETURNED && !order?.deleted;

  const showRejectReason = request?.action === PRODUCT_REJECTED;

  const { id } = useParams<any>();

  const getReturnOrderDetails = async () => {
    try {
      setIsLoading(true);
      await orderStore.getReturnRequestById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const S3Url = await orderStore?.downloadInvoice(order?._id);
      const downloadUrl = await Utility.createObjectUrlFromUrl(S3Url);
      Utility.downloadFile(downloadUrl, `Invoice-${order?.order_code}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const getReturnOrderActivities = async () => {
    try {
      setIsActivityLoading(true);
      await orderStore.listOrderActivities({ reference: id });
      setIsActivityLoading(false);
    } catch (error: any) {
      setIsActivityLoading(false);
      Toast.error(error);
    }
  };

  const updateReturnStatus = async (action: string, actionDetail: any) => {
    try {
      setIsUpdateLoading(true);
      const data : any = {
        action: Constant.orderReturn.get(action),
      };
      if (actionDetail?.payment_mode) data.payment_mode = actionDetail.payment_mode;
      if (actionDetail?.images) data.images = actionDetail.images;
      if (actionDetail?.comment) data.comment = actionDetail.comment;

      await orderStore.updateReturnStatus(id, data);
      await getReturnOrderDetails();
      await getReturnOrderActivities();
      setIsUpdateLoading(false);
    } catch (error: any) {
      setIsUpdateLoading(false);
      Toast.error(error);
    }
  };

  const handleRefund = async () => {
    ConfirmModal('Refund Order', 'Do you want to refund this order?', () => updateReturnStatus('refund', { payment_mode: CASH }), 'Refund', 'Black');
  };

  const handleRejectSubmit = (images: any, comment: string) => {
    setIsUpdateLoading(true);
    const fileFormData = new FormData();
    images.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
    orderStore.uploadFile(fileFormData).then((response: any) => (
      updateReturnStatus('product_rejected', { images: response, comment })
    ));
  };

  useEffect(() => {
    getReturnOrderDetails();
    getReturnOrderActivities();
  }, [id]);

  const goToOrderDetails = () => history.push(`${Routes.quick_order_details}/${order?._id}`);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto" onClick={goToOrderDetails}>
          View Order
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  const statusData: any = Utility?.getOrderRequestStatus(request);

  return (
    <Spin spinning={isLoading}>
      <Layout.Content className="detail_wrapper clnt_detail">
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.orders}>ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`${Routes.orders}?tab=${Constant.orderStatus.RETURN}`}>RETURN ORDERS</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{request?.request_code}</Breadcrumb.Item>
              </Breadcrumb>
              { showViewOrder && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              )}
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center flex-wrap">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24 me-sm-3 me-1">
                    {request?.request_code}
                  </Typography.Title>
                  <StatusLabel className={statusData?.className} text={statusData?.text} />
                </div>
              </div>
              <Row gutter={[16, 16]}>
                <Col lg={24} md={24} xs={24} className="mt-3 mt-md-0">
                  <Card>
                    <Typography.Title level={3}>Return Item</Typography.Title>
                    <Row>
                      <Col xxl={24} lg={24} md={24} sm={24} xs={24}>
                        <OrderItem
                          category={category?.name}
                          product={product?.name}
                          variant={variant?.color?.name}
                          quantity={request?.quantity}
                          amount={
                            (request?.return_amount as number) / (request?.quantity as number)
                          }
                          image={variant?.images[0]}
                        />
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                    <div className="d-flex justify-content-end align-items-center fs-16 r-bold secondary mt-4">
                      Total Amount
                      <Typography.Text className="primary fs-24 ms-3">{request?.return_amount} KD</Typography.Text>
                    </div>
                    <Divider className="my-3 border-top" />
                    {order?.payment_status
                    && (
                    <div className="d-flex align-items-center">
                      <Button className="primary gray_bg h-40 border-radius border-0 fs-16 r-bold" onClick={downloadInvoice}>
                        Download Invoice
                      </Button>
                    </div>
                    )}
                  </Card>
                  <Spin spinning={isUpdateLoading}>
                    {showReturn
                  && (
                  <ReturnSteps
                    currentDot={
                    Constant?.returnQuickOrderCurrentDot?.get(request?.action || 'OPEN') as number // open state has no action
                  }
                    payment_status={order?.payment_status}
                    isRefundDisabled={request?.refund_status === 'INITIATED'}
                    handleRefund={handleRefund}
                    updateReturnStatus={updateReturnStatus}
                    handleRejectSubmit={handleRejectSubmit}
                  />
                  )}
                  </Spin>
                  {showRejectReason
                   && (
                   <RejectReason
                     comment={request?.comment}
                     images={request?.images}
                     currentUser={authStore?.currentUser?.id as string}
                     actor={request?.actor}
                   />
                   )}
                  <Spin spinning={isActivityLoading}>
                    <Card className="timeline_card mt-3" loading={isLoading}>
                      <Typography.Title level={3}>Timeline</Typography.Title>
                      {activities && activities?.length
                        ? (
                          <ActivityCard
                            dataSource={activities}
                            currentUser={authStore?.currentUser}
                          />
                        ) : 'No activity'}
                    </Card>
                  </Spin>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the order</Paragraph>
            </Typography>
            { showViewOrder && (
            <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              <Tooltip title="View Order">
                <Button className="d-flex ml-auto r-bold align-items-center primary bg-transparent p-0 h-auto" onClick={goToOrderDetails}>
                  View Order
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            ) }
          </Sider>
        </Row>
      </Layout.Content>
    </Spin>
  );
}

export default inject('orderStore')(observer(OrderReturnDetails));
