/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import classNames from 'classnames';
import React from 'react';
import { Icon } from '..';

interface NotificationIconProps {
    isRedBg?: Boolean,
    icon: string
}
NotificationIcon.defaultProps = {
  isRedBg: false,
};
function NotificationIcon(props: NotificationIconProps) {
  const { isRedBg, icon } = props;
  return (
    <div className={classNames('icn_bg_44 primary position-relative rounded-circle justify-content-center d-flex align-items-center', { red_bg: isRedBg })}>
      <Icon className={classNames('icon-18', { white: isRedBg })} iconRef={icon} />
      <span className="position-absolute d-block rounded-circle" />
    </div>
  );
}
export default NotificationIcon;
