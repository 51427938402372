/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  category: [{
    required: true,
    message: constant.categoryRequired,
  }],
  variant: [{
    required: true,
    message: constant.variantRequired,
  }],
  product: [{
    required: true,
    message: constant.productRequired,
  }],
  customer: [{
    required: true,
    message: constant.customerRequired,
  }],
};

export default rule;
