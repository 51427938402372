/* eslint-disable import/no-unresolved */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '..';

function ProfileImage(props: any) {
  const { userStore } = props;
  return (
    <>
      { userStore?.loggedUser?.profile?.fileUrl
        ? <img src={userStore?.loggedUser?.profile?.fileUrl} alt="profile" className="w-100" />
        : <Icon className="icon-20" iconRef="#User" />}
    </>
  );
}

export default inject('userStore')(observer(ProfileImage));
