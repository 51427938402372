/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';

const rule: any = {
  dateRule: [{
    required: true,
    message: constant.dateRequired,
  }],
  timeRule: [{
    required: true,
    message: constant.timeRequired,
  }],
};

export default rule;
