/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Col,
  Layout,
  Row,
  Typography,
} from 'antd';
import './DashboardShop.less';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import {
  ClientCountCard, Icon, OrderChartCard, OrderProgressBar, Toast,
} from '../../Components';
import QuickOrder from './QuickOrder';
import { IDashboardStore } from '../../Store/dashboardShopStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import { AuthStoreProps } from '../../Store/authStore';

interface IDashboardShop{
  dashboardShopStore: IDashboardStore
  authStore: AuthStoreProps
}

function DashboardShop(props: IDashboardShop) {
  const { dashboardShopStore, authStore } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const { clientCount, inventoryDetails } = dashboardShopStore;

  const totalClient = clientCount?.count?.map(
    (item: any) => item?.count,
  ).reduce((prev: any, curr:any) => prev + curr, 0);

  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access?.includes(Constant?.userAccess.SUPER_ADMIN);

  const getDashboardData = async () => {
    setIsLoading(true);
    try {
      await dashboardShopStore.getDashboardData();
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getOrderCount = async (year: any) => {
    try {
      await dashboardShopStore?.getOrderCount({ year });
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const getInventoryDetails = async () => {
    try {
      await dashboardShopStore?.getInventoryDetails();
    } catch (error: any) {
      Toast.error(error);
    }
  };

  useEffect(() => {
    if (currentYear) getOrderCount(currentYear);
  }, [currentYear]);

  useEffect(() => {
    getDashboardData();
    getInventoryDetails();
  }, []);

  return (
    <Layout.Content>
      <Row gutter={16}>
        <Col md={24} lg={24} sm={24} xs={24}>
          <QuickOrder getOrderCount={() => getOrderCount(currentYear)} />
          <Typography.Title level={5} className="text-uppercase fs-14 my-4 primary r-medium">DASHBOARD</Typography.Title>
          <Row gutter={[16, 16]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <ClientCountCard
                isLoading={isLoading}
                route={Routes.customers}
                showCard={isSuperAdmin as boolean}
                thisMonth={clientCount?.thisMonth}
                total={totalClient}
              />
              {/* <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold">Clients</Typography.Title>
                  <Link to={' '} className="d-flex ml-auto r-bold
                  align-items-center p-0 link_text secondary">
                    View
                    <div className="ms-2 d-flex justify-content-center
                    align-items-center text-center p-0 small_icon rounded-circle secondary_bg">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Link>
                </div>
                <Row gutter={16} className="mt-4 map_wrapper">
                  <img className="w-100 h-100" src={map} alt="map" />
                </Row>
              </Card> */}
            </Col>
            {/* <Col lg={6} md={12} sm={12} xs={24}>
              <Card>
                <Typography.Title
                  className="text-uppercase r-medium secondary"
                  level={5}
                >NEW CLIENTS
                </Typography.Title>
                <Typography.Title
                  className="r-bold green mt-0"
                  level={1}
                >37
                </Typography.Title>
                <Typography.Paragraph
                  className="secondary"
                >8 % increase this month
                </Typography.Paragraph>
                <Icon className="icon-80 off-white" iconRef="#increase" />
                Client chart
                <div />
              </Card>
            </Col>
            <Col lg={6} md={12} sm={12} xs={24}>
              <Card>
                <Typography.Title
                  className="text-uppercase r-medium secondary"
                  level={5}
                >TOTAL CLIENTS
                </Typography.Title>
                <Typography.Title className="r-bold primary mt-0" level={1}>5464</Typography.Title>
                <Typography.Paragraph className="secondary">
                  6 % increase this year
                </Typography.Paragraph>
                <Icon className="icon-80 off-white" iconRef="#increase" />
                Client chart
                <div />
              </Card>
            </Col> */}
            <Col lg={24} md={24} sm={24} xs={24}>
              <OrderChartCard setCurrentYear={setCurrentYear} currentYear={currentYear} />
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card bordered={false} className="dash_card_resp_fnt">
                <div className="d-flex justify-content-between align-items-center">
                  <Typography.Title level={3} className="r-bold">Inventory</Typography.Title>
                  <Link
                    to={Routes.inventory}
                    className="d-flex ml-auto r-bold align-items-center p-0 link_text secondary"
                  >
                    View
                    <div
                      className="ms-2 d-flex justify-content-center align-items-center text-center
                                p-0 small_icon rounded-circle secondary_bg"
                    >
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Link>
                </div>
                <OrderProgressBar inventoryDetails={inventoryDetails} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default inject('dashboardShopStore', 'authStore')(observer(DashboardShop));
