/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import {
  Product, ProductQueryParams, VariantQueryParams, Variants,
} from '../Global/interface';
import api from './api';

class ProductService {
    getProducts = (queryParams: ProductQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/products/list?${query}`;
      return api.get(url);
    }

    createProduct = async (product: Product) => {
      try {
        const response = await api.post('/shop/products', product);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    updateProduct = async (product : Product, id: string) => {
      try {
        const response = await api.put(`/shop/products/${id}`, product);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    getProductById = async (id: String) => {
      try {
        const response = await api.get(`/shop/products/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    deleteProduct = async (id: string) => {
      try {
        const response = await api.delete(`/shop/products/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    uploadImages = async (formData : any, id: string) => {
      try {
        const response = await api.post(`/shop/products/upload-image/${id}`, formData);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    removeImages = async (formData : any, id: string) => {
      try {
        const response = await api.put(`/shop/products/delete-image/${id}`, formData);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    createVariant = async (variant: Variants, id: string) => {
      try {
        const response = await api.post(`/shop/products/variant/${id}`, variant);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    updateVariant = async (variant : Variants, id: string) => {
      try {
        const response = await api.put(`/shop/products/variant/${id}`, variant);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    deleteVariant = async (id: string) => {
      try {
        const response = await api.delete(`/shop/products/variant/${id}`);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    getVariants = async (queryParams: VariantQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/products/variant/list?${query}`;
      return api.get(url);
    }

    publishProduct = async (data: any, id: string) => {
      try {
        const response = await api.put(`/shop/products/publish/${id}`, data);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    setMasterImage = async (data: any, id: string) => {
      try {
        const response = await api.put(`/shop/products/master-image/${id}`, data);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }
}
export default new ProductService();
