/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Card,
  Typography,
} from 'antd';

import { Icon } from '../../Components';
import Utility from '../../Global/Utility';
import { Employee, Image } from '../../Global/interface';

interface IRejectionReason{
  comment: string
  images: Array<Image>
  currentUser: string
  actor: Employee
}

function RejectionReason(props: IRejectionReason) {
  const {
    comment, images, currentUser, actor,
  } = props;

  const getActor = () => {
    if (actor?._id === currentUser) return 'You ';
    if (actor?._id) return Utility.getUserName(actor);
    return '';
  };

  const userName = getActor();

  return (
    <Card className="mt-3">
      <Typography.Title level={3} className="mb-4">Reason for rejection</Typography.Title>
      <div className="d-flex">
        <div className="d-flex flex-grow-1 flex-shrink-1">
          <div className="icn_bg_44 p-0 rounded-circle d-flex align-items-center justify-content-center secondary">
            {actor?.profile?.fileUrl
              ? <img className="h_user_btn p-0 rounded-circle overflow-hidden" src={actor?.profile?.fileUrl} alt="User" />
              : <Icon className="icon-20" iconRef="#User" /> }
          </div>
          <div className="ms-3 flex-grow-1 w-0">
            <Typography.Paragraph className="fs-14 secondary mb-0">{userName}</Typography.Paragraph>
            <Typography.Paragraph className="fs-16 r-medium primary mb-2">{comment}</Typography.Paragraph>
            <div className="d-flex flex-wrap">
              { images?.map((image: Image) => (
                <div className="size-82 off-white-bg border-radius d-flex align-items-center justify-content-center p-3 me-2 mb-2">
                  <img src={image?.fileUrl} alt="product" className="w-100" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default RejectionReason;
