import React from 'react';
import { Button } from 'antd';
import defaultModelCar from '../../Assets/Images/modelCar.png';
import defaultModelBike from '../../Assets/Images/modelBike.png';
import defaultModelMarine from '../../Assets/Images/modelMarine.png';
import defaultModelOthers from '../../Assets/Images/modelOthers.png';

interface ModalThumbnailProps {
  category: String,
  imageUrl:any,
  onClick?: () => any,
  allowClick?: boolean
}

ModalThumbnailImage.defaultProps = {
  onClick: () => {},
  allowClick: false,
};

function ModalThumbnailImage(props: ModalThumbnailProps) {
  const {
    category, imageUrl, onClick, allowClick,
  } = props;

  const defaultModalImage = (categories:any) => {
    switch (categories) {
      case 'car':
        return defaultModelCar;
      case 'motorbike':
        return defaultModelBike;
      case 'marine':
        return defaultModelMarine;
      default:
        return defaultModelOthers;
    }
  };
  return (
    <Button className={`d-flex align-items-center veh_card overflow-hidden p-4 border-0 border-radius ${!allowClick ? 'cursor-context-m' : 'cursor-pointer'}`} onClick={onClick}>
      <img src={imageUrl || defaultModalImage(category)} alt="vehicle_img" className="w-100" />
    </Button>
  );
}

export default ModalThumbnailImage;
