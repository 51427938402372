/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Typography,
} from 'antd';
import classNames from 'classnames';
import {
  ConfirmModal,
  Icon, QRCodeModal, RenderItem, Toast, VehicleInfoCard, VehicleStatusCard,
} from '../../Components';
import { VehicleStoreProps } from '../../Store/vehicleStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import vehicleService from '../../Services/vehicleService';
import { AuthStoreProps } from '../../Store/authStore';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface ServiceReqDetailProps {
  vehicleStore: VehicleStoreProps
  authStore: AuthStoreProps
}

function VehicleDetail(props: ServiceReqDetailProps) {
  const { vehicleStore, authStore } = props;
  const { vehicle } = vehicleStore;
  const { currentUser } = authStore;
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isQRCodeModal, setIsQRCodeModal] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const sharedAccess = queryParams?.shared;
  const clientId = sharedAccess || vehicle?.client?._id;
  const showStatus = queryParams?.status;
  const tab = queryParams?.tab;
  const [collapsed, setCollapsed] = useState(false);
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);

  const getVehicleById = async () => {
    setIsLoading(true);
    try {
      await vehicleStore.getVehicleById(id, { payment_done: true });
    } catch (error: any) {
      Toast.error(error);
      history.goBack();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVehicleById();
  }, [id]);

  const showQRCodeModal = () => setIsQRCodeModal(true);
  const closeQRCodeModal = () => setIsQRCodeModal(false);
  const gotoParkUnpark = () => history.push(`${Routes.client_park_unpark}/${id}`);
  const gotoVehicleUpdatePage = () => history.push(`${Routes.client}/${vehicle?.client?._id}${Routes.create_vehicle}/${id}`);

  const isVaultPlan = vehicle?.contractPlan?.plan_type === Constant.plan_type?.VAULT;
  const vehicleAddress = isVaultPlan ? null : vehicle?.address;

  const deleteVehicle = async () => {
    try {
      await vehicleService.deleteVehicle(id);
      history.push(`${Routes.client_detail}/${vehicle?.client?._id}`);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
  };
  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Vehicle', 'Do you want to delete this vehicle?', deleteVehicle);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={`${Routes.client_detail}/${vehicle?.client?._id}`} className="d-flex ml-auto r-bold align-items-center p-0 link_text">
          Client Details
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={showQRCodeModal}>
          Download QR
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="4">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoVehicleUpdatePage}>
          Edit Vehicle
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      <Menu.Item key="5">
        {isSuperAdmin
              && (
                <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto resp_req_btn" onClick={viewDeleteConfirmModal}>
                  Delete Vehicle
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              )}
      </Menu.Item>
    </Menu>
  );

  const onClick = () => {
    if (tab === Constant.serviceRequestStatus.COMPLETED) history.push(`${Routes.request}?tab=${tab}`);
    else history.push(`${Routes.client_detail}/${clientId}`);
  };
  return (
    <Layout.Content className="detail_wrapper fac_detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between">
              <Button
                className="d-flex ml-auto mb-3 r-bold align-items-center back_link p-0 primary"
                type="link"
                onClick={onClick}
              >
                <div
                  className="me-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle"
                >
                  <Icon className="icon-8" iconRef="#arrow_left" />
                </div>
                Back
              </Button>
              {vehicle && (
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
              )}
            </div>
            <div className="mb-3">
              <div className={classNames('d-sm-flex align-items-center w-100 mb-3', { 'justify-content-between': vehicle?.vehicle_no, 'justify-content-end': !vehicle?.vehicle_no })}>
                {vehicle?.vehicle_no && (
                  <div className="d-flex align-items-center w-75">
                    <div className="number_icn primary_bg white r-bold d-flex alig-items-center justify-content-center fs-16 me-2">
                      N
                    </div>
                    <div className="w-100">
                      <Typography.Title className="text-uppercase mb-0 fs-xs-24 text-truncate">
                        {vehicle?.vehicle_no}
                      </Typography.Title>
                    </div>
                  </div>
                )}
                <RenderItem isTrue={!!vehicle}>
                  <Button className="main_btn fs-16 r-bold float-right mt-2 mt-sm-0" onClick={gotoParkUnpark}>Park/Unpark</Button>
                </RenderItem>
              </div>
              <Row gutter={16}>
                <Col lg={16} xs={24}>
                  <VehicleStatusCard
                    clientEmail={vehicle?.client?.email}
                    clientPhone={vehicle?.client?.phone}
                    vehicle={vehicle}
                    facility={vehicle?.contract?.facility}
                    vehicleId={id}
                    contract={vehicle?.contract}
                    showStatus={showStatus}
                  />
                </Col>
                <Col lg={8} xs={24}>
                  <VehicleInfoCard
                    vehicle={vehicle}
                    location={vehicleAddress}
                    isLoading={isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper hidden d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage a vehicle</Paragraph>
            </Typography>
            {vehicle && (
            <div><h6 className="text-uppercase r-medium r-side-info mt-5">Action</h6>
              <Link to={`${Routes.client_detail}/${vehicle?.client?._id}`} className="d-flex ml-auto mb-3 r-bold align-items-center p-0 link_text">
                Client Details
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={showQRCodeModal}>
                Download QR
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Button>
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoVehicleUpdatePage}>
                Edit Vehicle
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Button>
              {isSuperAdmin
              && (
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto" onClick={viewDeleteConfirmModal}>
                  Delete Vehicle
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              )}
            </div>
            ) }
          </Sider>
        </Row>
        <QRCodeModal
          vehicleNo={vehicle?.vehicle_no || vehicle?.vehicle_code}
          id={vehicle?._id}
          isQRCodeModal={isQRCodeModal}
          closeQRCodeModal={closeQRCodeModal}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('serviceStore', 'vehicleStore', 'authStore')(observer(VehicleDetail));
