/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import queryString from 'query-string';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';

import classnames from 'classnames';
import moment from 'moment';
import {
  Icon,
  PaymentSuccessCard,
  RenderItem,
  ScheduleVisit,
  ServiceReqPaymentMethod,
  SidebarInfo,
  StatusLabel,
  Toast,
  UserViewCard,
  WaitingForPayment,
} from '../../Components';
import { IServiceVisitStore } from '../../Store/serviceVisitStore';
import Utility from '../../Global/Utility';
import ServiceList from './serviceList';
import { ServiceCheckList } from '../../Global/interface';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IServiceInvoice {
  serviceVisitStore: IServiceVisitStore
}

function ServiceInvoice(props: IServiceInvoice) {
  const { serviceVisitStore } = props;
  const { serviceVisit } = serviceVisitStore;

  const { id } = useParams<any>();

  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultVisitTab = queryParams?.tab || 'OPEN';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState(false);

  const history = useHistory();

  const clientName = Utility.getUserName(serviceVisit?.client);

  const deletedUser = serviceVisit?.client?.deleted;

  const stautusText = Constant.serviceVisitStatus.get(serviceVisit?.status) as string;
  const statusClassName = Constant.ServiceVisitStatusClass.get(serviceVisit?.status);

  const isVisitCompleted = serviceVisit?.status === Constant.serviceVisitReqStatus.COMPLETED;
  const isPaymentPending = serviceVisit?.status === Constant.serviceVisitReqStatus.PAYMENT_PENDING;
  const totalAmountPresent = !!serviceVisit?.total_amount;

  const getVisitById = async () => {
    try {
      setIsLoading(true);
      await serviceVisitStore.getServiceVisitById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
      history.replace(`${Routes.service_visit}?tab=${defaultVisitTab}`);
    }
  };

  useEffect(() => {
    getVisitById();
  }, []);

  const sendPaymentLink = async (requestId: string, data: any) => {
    setIsLoading(true);
    try {
      const response = await serviceVisitStore.sendPaymentLink(requestId, data);
      if (response && response?.status === 200) {
        getVisitById();
        Toast.success('Link shared successfully');
      }
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const updateServiceVisitStatus = async (data: any) => {
    setIsLoading(true);
    try {
      await serviceVisitStore.updateServiceVisitStatus(id, data);
      Toast.success('Service Visit Completed.');
      getVisitById();
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const finishHandler = (data: any) => {
    if (data?.payment_mode === 'GATEWAY') {
      sendPaymentLink(
        id, { email: data?.email, phone: data?.phone, client: serviceVisit?.client?._id },
      );
    } else {
      updateServiceVisitStatus({ action: 'PAYMENT_DONE', payment_mode: data?.payment_mode, email: data?.email });
    }
  };

  const downloadInvoice = async () => {
    try {
      setIsLoading(true);
      const response = await serviceVisitStore?.downloadInvoice(serviceVisit?.order_ref);
      const url = await Utility.createObjectUrlFromUrl(response?.data?.url);
      Utility.downloadFile(url, `Invoice-${serviceVisit?.order_ref}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const gotoPlanDetails = () => history.push(`${Routes.contract_detail}/${serviceVisit?.contract?._id}`);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="1">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoPlanDetails}>
          View Plan
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">

              <Breadcrumb className="text-uppercase m-0 breadcrumb border-0">
                <Breadcrumb.Item>
                  <Link to={`${Routes.service_visit}?tab=${defaultVisitTab}`}>service visits</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={`${Routes.service_visit_detail}/${id}?tab=${defaultVisitTab}`}>
                    {serviceVisit?.visit_code}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Service Invoice</Breadcrumb.Item>
              </Breadcrumb>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-sm-flex align-items-center">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                    Service Invoice
                  </Typography.Title>
                  <StatusLabel
                    className={`${statusClassName} h-max ms-sm-3 mb-3 mb-sm-0 w-max-content`}
                    text={stautusText}
                  />
                </div>
              </div>
              <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                  <Row gutter={16}>
                    <Col lg={16} xs={24}>
                      <RenderItem isTrue={isVisitCompleted && totalAmountPresent}>
                        <PaymentSuccessCard
                          downloadInvoice={downloadInvoice}
                          time={serviceVisit?.payment_done_date}
                        />
                      </RenderItem>
                      <RenderItem isTrue={isPaymentPending && serviceVisit?.payment_link_sent}>
                        <WaitingForPayment
                          time={moment(serviceVisit?.payment_link_sent_date).toDate()}
                        />
                      </RenderItem>
                      <Card loading={isLoading} className="mb-3 mb-lg-0">
                        <Typography.Title level={3} className="text-capitalize mb-0 primary text-truncate w-100">
                          {serviceVisit?.visit_code}
                        </Typography.Title>
                        <Typography.Title level={5} className="text-capitalize secondary mb-3 text-truncate w-100 fs-14">
                          SERVICES
                        </Typography.Title>
                        <div>
                          {serviceVisit?.service_checklist?.map((
                            checkList: ServiceCheckList,
                          ) => (
                            <ServiceList
                              price={checkList?.price || 0}
                              service={checkList?.service}
                              unitPrice={checkList?.unit_price || 0}
                              value={checkList?.value || 0}
                              requestedInfo={checkList?.requested_info}
                            />
                          ))}
                          <Typography className="d-flex justify-content-between align-items-center border-bottom mb-2 pb-2">
                            <Title level={5} className="primary r-bold m-0">Total Amount</Title>
                            <Paragraph className="primary r-bold m-0">{serviceVisit?.total_amount} KD</Paragraph>
                          </Typography>
                        </div>
                        <RenderItem isTrue={isPaymentPending}>
                          <ServiceReqPaymentMethod
                            finishHandler={finishHandler}
                            email={serviceVisit?.client?.email}
                            phone={serviceVisit?.client?.phone}
                            okButtonText="Confirm Payment"
                            padding={false}
                            greyBg
                          />
                        </RenderItem>
                      </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                      <div className="mb-3">
                        <UserViewCard
                          clientCode={serviceVisit?.client?.client_code}
                          clientId={serviceVisit?.client?._id}
                          clientName={clientName}
                          deletedUser={deletedUser}
                          profileImageUrl={serviceVisit?.client?.profile?.fileUrl}
                        />
                      </div>
                      <ScheduleVisit
                        isResidentialPlan
                        planId={serviceVisit?.contract?._id}
                        due_date={serviceVisit?.contract?.due_date}
                        client={serviceVisit?.client?._id}
                        contractStatus={serviceVisit?.contract?.contract_status}
                        paymentStatus={serviceVisit?.contract?.payment_status}
                        visitStatus={serviceVisit?.status}
                        scheduledTime={serviceVisit?.scheduled_time}
                        visit_id={id}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <SidebarInfo infoText="See and manage the service visit" title="INFO" />
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
              <Tooltip title="View Plan">
                <Button className="d-flex primary ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0" onClick={gotoPlanDetails}>
                  View Plan
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('serviceVisitStore')(observer(ServiceInvoice));
