/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Typography } from 'antd';
import React from 'react';
import ServiceIcon from '../ServiceIcon';

interface ServicesListProps {
 serviceList: Array<any>
}

function ServicesList(props: ServicesListProps) {
  const { serviceList } = props;
  return (
    <div className="p-3 plan_listing_card_btm">
      <Typography.Title level={5} className="fs-14 secondary text-uppercase r-medium">plan service</Typography.Title>
      <div className="d-flex flex-wrap">
        {serviceList?.map((service: any) => <ServiceIcon service={service} />)}
      </div>
    </div>
  );
}

export default ServicesList;
