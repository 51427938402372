/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, action, makeObservable } from 'mobx';
import clientService from '../Services/clientService';
import Constant from '../Global/Constant';
import {
  Client, ClientContractParams,
  clientHistory, clientQuery, ClientVehicleParams, memberParams, sharedAccessParams,
} from '../Global/interface';

export interface IClientStore{
  clients: Array<Client>,
  client: Client|null,
  activeContracts: number,
  vehicles: any,
  contracts: any,
  total: number,
  members: any,
  sharedAccess: any,
  memberCountDetails: any,
  clientHistory: clientHistory,
  clientTransactions: any,
  initialize: Function,
  getClients: (queryParams: clientQuery) => any,
  getClientById: (id: string) => any,
  enableDisableClient: (id: string, data: any) => any
  getClientVehicle: (queryParams: any) => any
  getClientTransactions: (queryParams: any) => any
  getClientContracts: (queryParams: any) => any
  deleteAccountPermanently: (id: string, data: any) => any
}

class ClientStore {
  clients: Array<Client> = [];

  client: Client|null = null;

  activeContracts: number = 0;

  vehicles: any = {};

  contracts: any = {};

  total: number = 0;

  members: any = [];

  sharedAccess: any = [];

  memberCountDetails: any = null;

  clientHistory: clientHistory = { client: [], total: 0 };

  clientTransactions: any;

  constructor() {
    makeObservable(this, {
      clients: observable,
      client: observable,
      total: observable,
      contracts: observable,
      vehicles: observable,
      members: observable,
      sharedAccess: observable,
      memberCountDetails: observable,
      activeContracts: observable,
      clientHistory: observable,
      clientTransactions: observable,
      getClients: action,
      getClientTransactions: action,
    });
    this.initialize();
  }

  initialize = () => {
    this.clients = [];
    this.total = 0;
    this.contracts = {};
    this.vehicles = {};
    this.clientTransactions = {};
    this.clientHistory = { client: [], total: 0 };
    this.members = [];
    this.sharedAccess = [];
  }

  getClients = async (
    queryParams: clientQuery,
  ) => {
    try {
      const response = await clientService.getClients(queryParams);
      if (response && response.data) {
        this.clients = response?.data?.client;
        this.total = response?.data?.total;
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  createClient = async (user: any) => {
    try {
      const response = await clientService.createClient(user);
      this.client = response?.data?.client;
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  updateClient = async (id: string, user: any) => {
    try {
      await clientService.updateClient(id, user);
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getClientById = async (id: string) => {
    try {
      const response = await clientService.getClientById(id);
      if (response && response.data) {
        this.client = response.data?.user;
        this.activeContracts = response.data?.activeContracts;
        this.memberCountDetails = response?.data?.memberCount?.length
          && response?.data?.memberCount[0];
      }
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getClientVehicle = async (queryString: ClientVehicleParams) => {
    try {
      const response = await clientService.getClientVehicle(queryString);
      if (response && response?.data) {
        this.vehicles = {
          data: response?.data?.vehicles?.vehicle,
          total: response?.data?.vehicles?.total[0]?.counts,
        };
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getClientContracts = async (
    queryParams: ClientContractParams,
  ) => {
    try {
      const response = await clientService.getClientContracts(
        queryParams,
      );
      if (response && response?.data) {
        this.contracts = {
          data: response?.data?.contracts[0]?.contract,
          total: response?.data?.contracts[0]?.total[0]?.counts,
        };
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getSharedAccess = async (
    queryParams: sharedAccessParams,
  ) => {
    try {
      const response = await clientService.getSharedAccess(
        queryParams,
      );
      if (response && response?.data) {
        this.sharedAccess = response?.data?.sharedAccess;
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getMembers = async (
    queryParams: memberParams,
  ) => {
    try {
      const response = await clientService.getMembers(
        queryParams,
      );
      if (response && response?.data) {
        this.members = response?.data?.members;
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getClientHistory = async (queryParams: clientQuery) => {
    try {
      const response = await clientService.getClients(queryParams);
      if (response && response?.data) {
        this.clientHistory = response?.data;
      }
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getClientTransactions = async (queryParams: any) => {
    try {
      const response = await clientService.getClientTransactions(queryParams);
      if (response && response?.data) {
        this.clientTransactions = response?.data?.transactions;
      }
      return (null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  enableDisableClient = async (id: string, data: any) => {
    try {
      const response = await clientService.enableDisableClient(id, data);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  deleteAccountPermanently = async (id: string, data: any) => {
    try {
      const response = await clientService.deleteAccountPermanently(id, data);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };
}

export default new ClientStore();
