/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Typography,
  Row,
  InputNumber,
  Radio,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Action, Icon, Toast } from '../../Components';
import Constant from '../../Global/Constant';
import RenderItem from '../RenderItem';
import Utility from '../../Global/Utility';

interface ServiceMileageProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  service: string,
  checkboxValue: boolean,
  completed: boolean,
  savedValue: any,
  checkboxHandler: (value: any, service: string) => void
  completeService: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string
}

function ServiceMileage(props: ServiceMileageProps) {
  const {
    isInfoRequired,
    requestInfo,
    started,
    saved,
    service,
    checkboxValue,
    savedValue,
    completed,
    checkboxHandler,
    completeService,
    vehicleStatus,
    visitStatus,
  } = props;

  const [showAction, setShowAction] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const [boxChecked, setBoxChecked] = useState<boolean>(false);
  const [mileageUnit, setMileageUnit] = useState<string>(localStorage?.getItem('mileageUnit') || Constant.MI);

  useEffect(() => {
    if (mileageUnit === Constant.KM) setValue(vehicleStatus?.value * Constant.MIToKM);
    else if (mileageUnit === Constant.MI) setValue(vehicleStatus?.value);
  }, [vehicleStatus]);

  const handleAccept = () => {
    if (!value) return Toast.error('Please add value.');
    let mileageToUpdate;
    if (mileageUnit === Constant.KM) mileageToUpdate = value * Constant.KMToMI;
    return completeService(mileageToUpdate || value, service);
  };

  const handleReject = () => {
    setValue(null);
    setBoxChecked(false);
    setShowAction(false);
  };

  const handleCheckbox = (checked: boolean) => {
    setShowAction(checked);
    setBoxChecked(checked);
  };

  const handleValue = (val: any) => setValue(val);

  const radioHandler = (event: any) => {
    const val = event?.target.value;
    if (val === Constant.KM) setValue((mileValue: any) => mileValue * Constant.MIToKM);
    else if (val === Constant.MI) setValue((kmValue: any) => kmValue * Constant.KMToMI);
    setMileageUnit(val);
    localStorage?.setItem('mileageUnit', val);
  };

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          <div>
            <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
              <Checkbox
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
                defaultChecked={checkboxValue}
                disabled={completed}
                onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
              />
              <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
                <Icon className="icon-20" iconRef="#mileage" />
              </div>
              Mileage
              <RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
            </div>
            <RenderItem isTrue={started && saved}>
              <div className="metrices_wrap metrices_service_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">METRICS</Typography.Text>
                <Radio.Group
                  buttonStyle="solid"
                  className="radio_tab gray_bg w-100 border-radius p-0"
                  value={mileageUnit}
                  onChange={radioHandler}
                >
                  <Radio.Button value={Constant.KM} className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">KM</Radio.Button>
                  <Radio.Button value={Constant.MI} className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">MI</Radio.Button>
                </Radio.Group>
              </div>
            </RenderItem>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15} xs={24} className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center mt-3 mt-sm-0 order-2 order-sm-1">
            <Typography.Paragraph className="fs-16 mb-0 inactive">
              {vehicleStatus?.last_updated_time
                ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                : ''}
            </Typography.Paragraph>
            <RenderItem isTrue={started && showAction && !completed && saved}>
              <Action onAccept={handleAccept} onReject={handleReject} />
            </RenderItem>
          </div>
          <RenderItem isTrue={(started && saved) || (isInfoRequired && !requestInfo)}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Value</Typography.Text>
              <div className="position-relative max-145">
                <InputNumber
                  className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none w-100"
                  min={0}
                  maxLength={6}
                  disabled={completed || visitStatus === 'OPEN'}
                  value={value}
                  onChange={(val: any) => handleValue(val)}
                />
                <Typography.Text className="position-absolute inactive unit">{mileageUnit}</Typography.Text>
              </div>
              <Checkbox
                disabled={completed || visitStatus === 'OPEN'}
                defaultChecked={completed}
                checked={boxChecked || completed}
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                onChange={(e) => handleCheckbox(e?.target?.checked)}
              >
                Mileage status checked
              </Checkbox>
            </div>
          </RenderItem>
        </Col>
      </Row>
    </div>
  );
}
export default ServiceMileage;
