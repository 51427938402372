/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Col, Row, Typography, Button, Form,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Counter, Icon, Toast } from '../../Components';

interface IOrderList {
 index: number,
 image: string,
 category: string
 product: string
 variant: string
 price: number
 variantSku: string
 max: number
 quantity: number
 variantSize: string
 removeFromOrderList: () => void
 quantityChangeHandler: (value: number) => void
}

function OrderList(props: IOrderList) {
  const {
    index, image, category, product, variant, price, variantSku, max, quantity, removeFromOrderList,
    quantityChangeHandler, variantSize,
  } = props;

  const [form] = useForm();

  const changeHandler = (value: number) => {
    if (value < 1) Toast.error('Quantity is required');
    if (max < value) {
      form.resetFields([variantSku]);
      return Toast.error(`Quantity exceeded maximum ${max}`);
    }
    quantityChangeHandler(value);
    return '';
  };

  const { Title, Text } = Typography;

  return (
    <li className="position-relative">
      <Row gutter={[16, 16]} className="justify-content-between">
        <Col lg={10} md={10} sm={24} xs={24}>
          <div className="d-sm-flex">
            <div className="d-flex">
              <Typography.Text className="primary r-bold">{index}.</Typography.Text>
              <div className="product_img_box border-color mx-3 border-radius p-2 d-flex align-items-center justify-content-center">
                <img src={image} alt="product" className="w-100 h-100 object-fit-contain" />
              </div>
            </div>
            <Typography className="ml-sm-28 flex-sm-grow-1 w-0 w-xs-inherit">
              <Text className="secondary text-uppercase r-medium">{category}</Text>
              <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>{product}</Title>
              <Title className="primary text-capitalize m-0 r-regular" level={5}><span
                className="veh_color d-inline-block me-2 mt-0 rounded-circle v_color_display"
                style={{ backgroundColor: variant }}
              />{variantSize}
              </Title>
            </Typography>
          </div>
        </Col>
        <Col lg={4} md={5} sm={10} xs={24} className="ml-md-28">
          <Typography.Text className="secondary text-uppercase r-medium d-block text-lg-center mb-2">quantity</Typography.Text>
          <Form form={form}>
            <Counter
              name={variantSku}
              maxLength={max.toString().length}
              changeHandler={changeHandler}
              min={0}
            />
          </Form>
        </Col>
        <Col lg={4} md={3} sm={10} xs={20} className="ml-sm-28">
          <Typography>
            <Text className="secondary text-uppercase r-medium">amount</Text>
            <Title className="primary r-bold text-capitalize mt-0 mb-3" level={5}>{price * quantity} KD</Title>
          </Typography>
        </Col>
        <Col lg={2} md={2} sm={4} xs={6} className="order_list_del">
          <Button onClick={removeFromOrderList} className="item_delete border-radius red d-flex align-items-center justify-content-center border-0 ms-1">
            <Icon className="icon-16" iconRef="#delete" />
          </Button>
        </Col>
      </Row>
    </li>
  );
}

export default OrderList;
