/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Carousel,
  Progress,
  Typography,
} from 'antd';
import { Icon, NoData } from '..';
import './FacilityCount.less';

interface Props {
    nextItem: () => void,
    prevItem: () => void,
    routeHandler: () => void,
    cardName: string,
    name: string,
    location: string,
    capacity: number,
    loading: boolean,
    usedCapacity: number,
    totalFacilityCount: number,
    index: number,
}

function FacilityCount(props: Props) {
  const {
    nextItem, prevItem, routeHandler, cardName, name, location, capacity, loading, index,
    usedCapacity, totalFacilityCount,
  } = props;
  const carousel: any = React.createRef();

  const moveToNext = () => {
    carousel.current.next();
    nextItem();
  };

  const moveToPrev = () => {
    carousel.current.prev();
    prevItem();
  };

  const setRef = (node:any) => {
    carousel.current = node;
  };

  const percentage = (usedCapacity / capacity) * 100;

  let strokeColor = '#FF4141';
  if (percentage < 20) {
    strokeColor = '#F1AA1D';
  } if (percentage >= 20 && percentage < 80) {
    strokeColor = '#26D65B';
  }

  return (
    <Card bordered={false} className="dash_card_resp_fnt shadow-none" loading={loading}>
      <div className="position-relative">
        <Typography.Title level={3} className="r-bold">{cardName}</Typography.Title>
        <Carousel arrows={false} dots={false} ref={setRef}>
          {name
        && (
        <div>
          <div className="d-flex justify-content-center align-items-center fac_cap_progress">
            <Progress
              strokeColor={strokeColor}
                        // strokecolor (20% - #26D65B) (80% - #FF4141)
              className="progress_width"
              type="circle"
              percent={percentage}
              format={() => (
                <div className="secondary fs-16">
                  <Icon className="icon-20 mb-2" iconRef="#facilities" /><br />
                  {`${usedCapacity}/${capacity}`}
                  <Button onClick={routeHandler} className="d-flex r-bold justify-content-center p-0 primary mt-3 m-auto border-0 shadow-none bg-transparent">
                    View
                  </Button>
                </div>
              )}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Typography.Title level={5} className="text-capitalize mb-0">
                {name}
              </Typography.Title>
              <Typography.Paragraph className="mb-0 d-flex align-items-center secondary">
                {location}
                <Icon className="icon-13 ms-2" iconRef="#location" />
              </Typography.Paragraph>
            </div>
          </div>
        </div>
        )}
          {!name && <NoData subTitle="facilities" size={147} />}
        </Carousel>
        {name
      && (
      <div className="position-absolute end-0 bottom-0">
        {totalFacilityCount
          ? <span className="r-bold primary me-3 fs-16">{index}/<span className="secondary">{totalFacilityCount}</span></span>
          : ''}
        <Button onClick={moveToPrev} className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg me-2 primary"><Icon className="icon-12" iconRef="#arrow_left" /></Button>
        <Button onClick={moveToNext} className="icn_cir_wdt rounded-circle p-0 border-0 gray_bg primary"><Icon className="icon-12" iconRef="#arrow_right" /></Button>
      </div>
      )}
      </div>
    </Card>
  );
}

export default FacilityCount;
