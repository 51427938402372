/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Card, Col, Divider, Row, Typography,
} from 'antd';
import './CreateProduct.less';
import 'react-phone-number-input/style.css';
import {
  ConfirmModal,
  Icon, ImageCard, PreviewButton, Toast, UploadDocument,
} from '../../Components';
import { Product } from '../../Global/interface';

interface IProductImage{
  product: Product
  moveToNextTab: () => void
  goToProductListing: () => void
  isUploadVisible: boolean,
  isDocUploading: boolean,
  uploadImages: (documents: any) => any
  showUploadModal: () => void,
  closeUploadModal:() => void
  removeImages: (documents: any) => any
  productImage: boolean
  viewPublishConfirnModal: () => void
  setProductMasterImage: (image: string) => any
  masterImage: string
  isLoading: boolean
  showPreviewPublishBtn: boolean
}

function ProductImage(props: IProductImage) {
  const {
    product, moveToNextTab, goToProductListing, isUploadVisible, isDocUploading, productImage,
    uploadImages, showUploadModal, closeUploadModal, removeImages, viewPublishConfirnModal,
    setProductMasterImage, masterImage, isLoading, showPreviewPublishBtn,
  } = props;
  const [uploadedImages, setUploadedImages] = useState<any>(null);

  useEffect(() => {
    setUploadedImages(product?.images);
  }, [product]);

  const removeImageConfirm = (image: any) => {
    ConfirmModal('Delete Image', 'Do you want to delete this image?', () => removeImages([image?.fileUrl]));
  };

  const onContinueHandler = () => {
    if (!productImage) Toast.warning('Please add a product image'); // if no image uploaded
    else if (!masterImage) Toast.warning('Please choose a master image'); // if no master image chosen
    else moveToNextTab();// if image present go to next tab
  };

  const masterImageHandler = (image: string) => { setProductMasterImage(image); };
  return (
    <Card className="create_card">
      <Row
        gutter={{
          xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
        }}
        className="m-0"
      >
        <Col lg={4} md={24} sm={24} xs={24}>
          <Typography.Title className="m-0" level={5}>
            Images
          </Typography.Title>
        </Col>
        <Col lg={20} md={24} sm={24} xs={24}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Typography.Text className="m-0 create_para me-2 me-sm-0">
              Add your product images
            </Typography.Text>
            <Button className="d-flex ml-auto r-bold align-items-center back_link p-0" type="link" onClick={showUploadModal}>
              Upload
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Button>
            <UploadDocument
              isModalVisible={isUploadVisible}
              handleModelCancel={closeUploadModal}
              uploadDocuments={uploadImages}
              uploadedDocuments={uploadedImages}
              isLoading={isDocUploading}
              acceptedExtension=".jpeg,.png,.jpg"
            />
          </div>
          { uploadedImages?.length ? (
            <Row gutter={16}>
              <Col xs={24} lg={24} xl={24} xxl={20}>
                <div className="off-white-bg border-radius p-2">
                  <div className="d-flex flex-wrap w-100 p_img_wrapper_ht">
                    { uploadedImages?.map((
                      image: any,
                    ) => (
                      <ImageCard
                        key={image?.fileUrl}
                        image={image}
                        removeImage={() => removeImageConfirm(image)}
                        showMasterImage
                        value={masterImage === image?.fileUrl ? 1 : 0}
                        showDeleteButton={!(product?.published || product?.variants?.length)
                          || masterImage !== image?.fileUrl}
                        masterImageHandler={() => masterImageHandler(image?.fileUrl)}
                      />
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          ) : <></>}
          <Divider className="mt-3 border-top" />
          <div className="d-sm-flex justify-content-between mt-sm-4">
            <div className="d-sm-flex">
              <Button disabled={isLoading} htmlType="submit" className="main_btn me-3 fs-16 r-bold mt-3 mt-sm-0" onClick={onContinueHandler}>
                Continue
              </Button>
              <Button disabled={isLoading} type="text" className="cancel_btn fs-16 r-bold mt-3 mt-sm-0" onClick={goToProductListing}>
                Close
              </Button>
            </div>
            <PreviewButton
              visibility={showPreviewPublishBtn}
              viewPublishConfirnModal={viewPublishConfirnModal}
              publishButtonText={product?.published ? 'Unpublish Product' : 'Publish Product'}
              buttonColor={product?.published ? 'yellow' : 'green'}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default observer(ProductImage);
