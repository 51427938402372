/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, action, makeObservable, runInAction,
} from 'mobx';
import Constant from '../Global/Constant';
import { CategoryData, ProductCategory, ProductCategoryQueryParams } from '../Global/interface';
import categoryService from '../Services/categoryService';

export interface ICategoryStoreProps {
    categories: CategoryData,
    category: ProductCategory,
    getCategories: (queryParams: ProductCategoryQueryParams) => any,
    createCategory: (category: ProductCategory) => any
    updateCategory: (category: ProductCategory, id: string) => any
   }

class CategoryStore {
    categories: CategoryData | null = null;

    category: ProductCategory | null = null;

    constructor() {
      makeObservable(this, {
        categories: observable,
      });
    }

    getCategories = async (queryParams: ProductCategoryQueryParams) => {
      try {
        const response: any = await categoryService.getCategories(queryParams);
        if (response && response?.data) {
          runInAction(() => {
            this.categories = response?.data;
          });
        }
        return (null);
      } catch (error : any) {
        return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      }
    }

    createCategory = async (category: ProductCategory) => {
      try {
        await categoryService.createCategory(category);
        return Promise.resolve(null);
      } catch (error: any) {
        return Promise.reject(
          error?.response?.data?.error?.message || Constant.defaultErrorMessage,
        );
      }
    }

    updateCategory = async (category: ProductCategory, id: string) => {
      try {
        await categoryService.updateCategory(category, id);
        return Promise.resolve(null);
      } catch (error: any) {
        return Promise.reject(
          error?.response?.data?.error?.message || Constant.defaultErrorMessage,
        );
      }
    }

    getCategoryById = async (id: string) => new Promise((resolve, reject) => {
      categoryService.getCategoryById(id)
        .then((response: any) => {
          if (response && response.data) {
            this.category = response.data.category;
          }
          resolve(null);
        })
        .catch((error : any) => {
          reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
        });
    })
}

export default new CategoryStore();
