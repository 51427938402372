/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  Divider,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import './VehicleInfoCard.less';
import { Icon, ModelInfo } from '../../Components';
import { Address, Vehicle } from '../../Global/interface';
import Utility from '../../Global/Utility';

const { TabPane } = Tabs;
export interface InfoCardProps {
  vehicle: Vehicle
  location?: Address | null,
  isLoading?: boolean,
  model?: any
}

VehicleInfoCard.defaultProps = {
  isLoading: false,
  location: '',
  model: null,
};

function VehicleInfoCard(props: InfoCardProps) {
  const {
    vehicle, location, isLoading, model,
  } = props;

  const isLocationPresent = location?.country && location?.state && location?.city;
  const hideTab = !isLocationPresent
  && !(vehicle?.registration_doc?.length > 0 || vehicle?.insurance_doc?.length > 0);
  const locationOrDoc = isLocationPresent
   && (vehicle?.registration_doc?.length > 0 || vehicle?.insurance_doc?.length > 0);
  return (
    <Card className="mt-3 mt-lg-0 info_ht" loading={isLoading}>
      {!hideTab
      && (
      <Tabs defaultActiveKey="1" className={locationOrDoc ? 'custom_tab custom_tab_pad' : 'custom_tab_info custom_tab_pad'}>
        <TabPane tab="Info" key="1">
          <ModelInfo vehicle={vehicle} model={model} />
        </TabPane>
        { (vehicle?.registration_doc?.length > 0 || vehicle?.insurance_doc?.length > 0) && (
        <TabPane tab="Docs" key="2">
          <div className="detail_card_pad">
            { vehicle?.registration_doc?.length > 0
            && (
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <Typography.Title className="mb-0" level={4}>Registration</Typography.Title>
              <Typography.Text className="r-bold">
                Exp - {Utility.getFormattedDate(vehicle?.registration_exp_date)}
              </Typography.Text>
            </div>
            )}
            { vehicle?.registration_doc?.map((document: any, dataIndex: number) => (
              <div className="d-flex justify-content-between align-items-center fs-16 secondary doc_download_brd">
                <span className="text-truncate" title={document.fileName}>{dataIndex + 1}. {document.fileName}</span>
                <Tooltip title="download document">
                  <a href={document?.fileUrl} className="small_icon_bg icn_32 rounded-circle d-flex justify-content-center align-items-center p-0">
                    <Icon className="icon-14" iconRef="#download" />
                  </a>
                </Tooltip>
              </div>
            ))}
            { vehicle?.insurance_doc?.length > 0
            && (
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <Typography.Title className="mb-0" level={4}>Insurance</Typography.Title>
              <Typography.Text className="r-bold">
                Exp - {Utility.getFormattedDate(vehicle?.insurance_exp_date)}
              </Typography.Text>
            </div>
            )}
            { vehicle?.insurance_doc?.map((document: any, dataIndex: number) => (
              <div className="d-flex justify-content-between align-items-center fs-16 secondary doc_download_brd">
                <span className="text-truncate" title={document.fileName}>{dataIndex + 1}. {document.fileName}</span>
                <Tooltip title="download document">
                  <a href={document?.fileUrl} className="small_icon_bg icn_32 rounded-circle d-flex justify-content-center align-items-center p-0">
                    <Icon className="icon-14" iconRef="#download" />
                  </a>
                </Tooltip>
              </div>
            ))}
          </div>
        </TabPane>
        ) }
        { isLocationPresent
        && (
        <TabPane tab="Location" key="3">
          <Typography.Title level={4}>Location</Typography.Title>
          <Typography.Paragraph className="m-0 secondary">{location?.address_line_1}</Typography.Paragraph>
          <Typography.Paragraph className="m-0 secondary">{location?.address_line_2}</Typography.Paragraph>
          <Typography.Paragraph className="m-0 secondary">{location?.city}</Typography.Paragraph>
          <Typography.Paragraph className="m-0 secondary">{location?.state}</Typography.Paragraph>
          <Typography.Paragraph className="m-0 secondary">{location?.zip_code}</Typography.Paragraph>
          <Typography.Paragraph className="m-0 secondary">{location?.country}</Typography.Paragraph>
        </TabPane>
        )}
      </Tabs>
      )}
      {hideTab && (
        <div>
          <Typography.Title level={2}>Info</Typography.Title>
          <Divider className="border-top mt-2" />
          <ModelInfo vehicle={vehicle} model={model} />
        </div>
      ) }
    </Card>
  );
}
export default observer(VehicleInfoCard);
