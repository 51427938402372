/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Button,
  Card,
  Steps,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { ConfirmModal } from '../../Components';

export interface FulfillStepsProps {
    currentDot: number,
    actionFn: (action: string) => void,
}

const { Step } = Steps;

function FullfillSteps(props: FulfillStepsProps) {
  const { currentDot, actionFn } = props;

  const nextConfirmModalText = currentDot === 0 ? 'prepare order' : 'in transit';

  const undoConfirmModalText = currentDot === 1 ? 'prepare order' : 'in transit';

  const undoFn = () => ConfirmModal('Undo', `Do you want to undo ${undoConfirmModalText}?`, () => actionFn('UNDO'), 'Undo',
    'Black');

  const nextFn = () => ConfirmModal('Start', `Do you want to start ${nextConfirmModalText}?`, () => actionFn('START'), 'Start',
    'Black');

  const fulFillFn = () => ConfirmModal('Fulfill Order', 'Do you want to fulfill this order?', () => actionFn('START'), 'FulFill',
    'Black');

  return (
    <Card className="mt-3">
      <Typography.Title level={3} className="mb-4">Fulfill Order</Typography.Title>
      <Steps progressDot current={currentDot} direction="vertical" className="custom_steps">
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('primary r-bold mb-0', { disable_clr: currentDot === 0 })}
            >Prepare Order
            </Typography.Title>
)}
          description={(
            <div className="d-sm-flex align-items-center justify-content-between">
              <Typography.Paragraph className={classNames('secondary fs-16 m-0', { disable_clr: currentDot === 0 })}>
                Pack the product and prepare to delivery
              </Typography.Paragraph>
              <div>
                <Button
                  className="border-0 shadow-none border-radius text-white r-bold green_bg h-40"
                  hidden={currentDot !== 0}
                  onClick={nextFn}
                >
                  Start
                </Button>
                <Button className="bg-transparent border-0 shadow-none secondary r-bold px-3" onClick={undoFn} hidden={currentDot !== 1}>Undo</Button>
              </div>
            </div>
                      )}
        />
        <Step
          title={(<Typography.Title level={5} className={classNames('primary r-bold mb-0', { disable_clr: (currentDot <= 1) })}>In Transit</Typography.Title>)}
          description={(
            <div className="d-sm-flex align-items-center justify-content-between">
              <Typography.Paragraph className={classNames('secondary fs-16 m-0', { disable_clr: (currentDot <= 1) })}>
                Product in transit and out for delivery
              </Typography.Paragraph>
              <div>
                <Button className="bg-transparent border-0 shadow-none secondary r-bold px-3" onClick={undoFn} hidden={currentDot !== 2}>Undo</Button>
                <Button className="border-0 shadow-none border-radius text-white r-bold green_bg h-40" hidden={currentDot !== 1} onClick={nextFn}>Start</Button>
              </div>
            </div>
                      )}
        />
        <Step
          title={<Typography.Title level={5} className={classNames('r-bold mb-0', { disable_clr: true })}>Delivered</Typography.Title>}
          description={(
            <div className="d-sm-flex align-items-center justify-content-between">
              <Typography.Paragraph className={classNames('fs-16 m-0', { disable_clr: true })}>Product delivered to the billing address</Typography.Paragraph>
              <Button className="border-0 shadow-none border-radius text-white r-bold green_bg h-40" hidden={currentDot !== 2} onClick={fulFillFn}>Fulfill Order</Button>
            </div>
                      )}
        />
      </Steps>
    </Card>
  );
}

export default FullfillSteps;
