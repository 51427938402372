/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { Button } from 'antd';
import React, { useState } from 'react';
import Constant from '../../Global/Constant';
import RequestConfirmation from '../RequestConfirmation';

interface IStartCancelRequest {
  updateRequest: (action: any) => void
  isLoading: boolean
}

const { START, DECLINE } = Constant.serviceRequestActions;

function StartCancelRequest(props: IStartCancelRequest) {
  const { updateRequest, isLoading } = props;
  const [isDeclineRequest, setIsDeclineRequest] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const startTaskHandler = () => {
    setIsModalVisible(true);
    setIsDeclineRequest(false);
  };

  const declineTaskHandler = () => {
    setIsModalVisible(true);
    setIsDeclineRequest(true);
  };

  const modelOkHanlder = (textInput?: string) => {
    if (isDeclineRequest) updateRequest({ action: DECLINE, comment: textInput });
    else updateRequest({ action: START });
  };

  const modelCancelHandler = () => {
    setIsModalVisible(false);
    setIsDeclineRequest(false);
  };

  const startTask = 'Start Task';
  const declineTask = 'Decline Task';
  return (
    <div className="d-flex flex-wrap flex-sm-nowrap">
      <Button
        className="fs-16 dark_green_bg  me-3 mb-3 mb-sm-0 fs-16 white border-radius border-0 pt-1 pb-1 start_btn_resp r-bold h-40"
        onClick={startTaskHandler}
      >
        {startTask}
      </Button>
      <Button
        onClick={declineTaskHandler}
        className="d-flex h-40 border-radius red_bg white border-0 r-bold align-items-center"
      >
        {declineTask}
      </Button>
      <RequestConfirmation
        isLoading={isLoading}
        modalVisibility={isModalVisible}
        okButtonText={isDeclineRequest ? declineTask : startTask}
        onCancel={modelCancelHandler}
        onOk={modelOkHanlder}
        title={isDeclineRequest ? declineTask : startTask}
        text={isDeclineRequest ? 'Are you sure you want to decline this task?' : 'Are you sure you want to start this task?'}
        isCancelRequest={isDeclineRequest}
      />
    </div>
  );
}

export default StartCancelRequest;
