/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Input, Modal, Form, Button, Typography,
} from 'antd';
import { inject, observer } from 'mobx-react';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import { UserStoreProps } from '../../Store/userStore';
import { Icon, Toast } from '..';

const title = <div className="text-capitalize r-bold">change password</div>;

interface Props {
  showPasswordModal: boolean,
  closePasswordModal: () => void,
  userStore?:UserStoreProps,
}

function ResetPasswordModal(props: Props) {
  const {
    showPasswordModal, closePasswordModal, userStore,
  } = props;
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    try {
      const password = {
        old_password: values?.current_password,
        password: values?.new_password,
      };
      await userStore?.resetPassword(password);
    } catch (error: any) {
      Toast.error(error);
    }
  };
  const cancelHandler = () => {
    closePasswordModal();
    form.resetFields();
  };

  return (
    <Modal
      title={title}
      visible={showPasswordModal}
      onCancel={cancelHandler}
      footer={null}
      className="cmn_modal_wrapper"
      width={400}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Form layout="vertical" className="login-form set_form" form={form} onFinish={handleSubmit} validateTrigger={['onBlur', 'onSubmit', 'onChange']}>
        <Form.Item
          label={<div className="r-medium text-uppercase secondary">current password</div>}
          name="current_password"
          className="mb-3"
          normalize={Utility.trimSpacesInValues}
          rules={[
            {
              required: true,
              message: Constant.currentPasswordRequiredError,
            },
            {
              type: 'string',
              pattern: Constant.passwordRegex,
              message: Constant.invalidPassword,
            },
          ]}
        >
          <Input.Password placeholder="Enter current password" maxLength={Constant.passwordLength} className="inp_style inp_transparent pt-0 pb-0" allowClear />
        </Form.Item>
        <Form.Item
          label={<div className="r-medium text-uppercase secondary">new password</div>}
          name="new_password"
          className="mb-3"
          normalize={Utility.trimSpacesInValues}
          rules={[
            {
              required: true,
              message: Constant.newPasswordRequiredError,
            },
            {
              type: 'string',
              pattern: Constant.passwordRegex,
              message: Constant.invalidPassword,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (getFieldValue('current_password') !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(Constant.oldNewPasswordSameError));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter new password" maxLength={Constant.passwordLength} className="inp_style inp_transparent pt-0 pb-0" allowClear />
        </Form.Item>

        <Form.Item
          label={<div className="r-medium text-uppercase secondary">confirm password</div>}
          name="confirm_password"
          className="mb-4"
          normalize={Utility.trimSpacesInValues}
          rules={[
            {
              required: true,
              message: Constant.confirmPasswordRequiredError,
            },
            {
              type: 'string',
              pattern: Constant.passwordRegex,
              message: Constant.invalidPassword,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(Constant.passwordMatchError));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm your password" maxLength={Constant.passwordLength} className="inp_style inp_transparent pt-0 pb-0" allowClear />
        </Form.Item>
        <Typography.Paragraph className="secondary fs-16">
          Use minimum 6 characters, include uppercase, lowercase and special characters.
        </Typography.Paragraph>
        <Button htmlType="submit" block type="primary" className="main_btn red_bg w-auto border-0 r-bold fs-16"> Change Password </Button>
      </Form>
    </Modal>
  );
}

export default inject('userStore')(observer(ResetPasswordModal));
