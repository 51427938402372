import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const messaging = getMessaging(app);

const publicKey = process.env.REACT_APP_FIREBASE_FCM_PUBLIC_KEY;

export const getFirebaseToken = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
    } else {
      // setTokenFound(false);
    }
  } catch (error) {
    // console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});
