/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import {
  Button,
  Col, Divider, Form, Row, Select, Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Constant from '../../Global/Constant';
import { Contract } from '../../Global/interface';
import Utility from '../../Global/Utility';

const { Option } = Select;

interface ParkVehicleProps {
  contracts: Array<Contract>,
  parkVehicle: (planId: string) => void,
  cancelPark: () => void,
  isLoading: boolean
}

function ParkVehicle(props: ParkVehicleProps) {
  const {
    contracts, parkVehicle, cancelPark, isLoading,
  } = props;
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const options = contracts?.map((item: any) => (
    <Option value={item?._id}>
      <Typography.Title level={5} className="d-flex align-items-center r-medium primary mb-0 mt-2 cursor-pointer">
        <span
          className="text-ellipsis"
        >{Object.keys(item?.contract)?.length !== 0 && `${item?.contract?.plan} Plan - ${Utility?.reverseDuration(item?.duration, item?.duration_type)} ${Constant?.durationTypeName.get(item?.duration_type)}`}
        </span>
      </Typography.Title>
      <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
        {item?.facility?.name} - {item?.contract_code}
      </Typography.Paragraph>
    </Option>
  ));

  const selectPlan = (value: any) => setSelectedPlan(value);
  const park = () => parkVehicle(selectedPlan);

  const planFilterOption = (input: any, option: any) => (
    option?.children[0]?.props?.children?.props?.children?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
      || option?.children[1]?.props?.children[2]?.toLowerCase()
        .indexOf(input?.toLowerCase()) >= 0);

  return (
    <div>
      <Form layout="vertical" onFinish={park} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <div className="mb-3">
          <Typography.Title level={5} className="r-blod">Park vehicle on</Typography.Title>
          <Row gutter={16} className="mb-3">
            <Col lg={11} sm={11} xs={24}>
              <Form.Item
                label={<div><Typography.Text className="text-uppercase r-medium secondary">PLAN</Typography.Text><br /></div>}
                className="mandatory"
                name="country"
                rules={[{
                  required: true,
                  message: 'Plan is required.',
                }]}
              >
                <Select
                  showSearch
                  value={selectedPlan}
                  onSelect={selectPlan}
                  placeholder="Select a plan"
                  className="slct_style w-75 w-xs-100 mt-2"
                  filterOption={planFilterOption}
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-2 mb-4 border-top" />
        </div>
        <div className="d-flex mt-4">
          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
            Park
          </Button>
          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelPark}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ParkVehicle;
