/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable, runInAction } from 'mobx';
import Constant from '../Global/Constant';
import { clientCount } from '../Global/interface';
import clientService from '../Services/clientService';
import dashboardShopService from '../Services/dashboardShopService';

export interface IDashboardStore {
    clientCount: clientCount,
    orderDetails: any
    inventoryDetails: any
    getDashboardData: () => any
    getOrderCount: (query: any) => any
    getInventoryDetails: () => any
}

class DashboardStore {
  clientCount: clientCount = { count: [], thisMonth: 0 };

  orderDetails: any = []

  inventoryDetails: any = []

  constructor() {
    makeObservable(this, {
      clientCount: observable,
      inventoryDetails: observable,
      orderDetails: observable,
    });
  }

  getDashboardData = async () => {
    try {
      const response = await clientService.getClientCount();
      runInAction(() => {
        this.clientCount = {
          count: response?.data?.count,
          thisMonth: response?.data?.thisMonthCount,
        };
      });
      return null;
    } catch (error: any) {
      return (
        error?.response?.data?.error?.message || Constant.defaultErrorMessage
      );
    }
  }

  getOrderCount = async (query: any) => {
    try {
      const response = await dashboardShopService.getOrderCount(query);
      runInAction(() => {
        this.orderDetails = response?.data?.orderDetails;
      });
      return null;
    } catch (error: any) {
      return (
        error?.response?.data?.error?.message || Constant.defaultErrorMessage
      );
    }
  }

  getInventoryDetails = async (query: any) => {
    try {
      const response = await dashboardShopService.getInventoryDetails();
      runInAction(() => {
        this.inventoryDetails = response?.data?.inventoryDetails;
      });
      return null;
    } catch (error: any) {
      return (
        error?.response?.data?.error?.message || Constant.defaultErrorMessage
      );
    }
  }
}

export default new DashboardStore();
