/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Breadcrumb,
  Button, Col, Divider, Form, Layout, Row, Spin, Table, Tooltip, Typography,
} from 'antd';
import './ManageCategory.less';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  EditableCell,
  Icon, NoData, Pagination, SortTh, Toast,
} from '../../Components/index';
import Constant from '../../Global/Constant';
import { ICategoryStoreProps } from '../../Store/category';
import rules from './manageCategoryForm.validation';
import { ProductCategory } from '../../Global/interface';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IManageCategory {
  categoryStore: ICategoryStoreProps
}

function ManageCategory(props: IManageCategory) {
  const history = useHistory();
  const { categoryStore } = props;
  const { categories } = categoryStore;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [data, setData] = useState<any>();
  const [editingKey, setEditingKey] = useState('');
  const [orderBy, setOrderBy] = useState<any>('createdAt');
  const [order, setOrder] = useState<any>(-1);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const showNodata = categories?.total <= 0 && !isCreateMode && !isLoading;
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const categoryData = categories?.categories?.map(
      (category: ProductCategory, index: number) => ({
        number: ((pageNumber - 1) * Constant.categoryPerPage) + (index + 1),
        key: category?._id,
        name: category?.name,
        code: category?.code,
        in_use: category?.category_in_use,
      }),
    );
    setData(categoryData);
  }, [categories]);

  const isEditing = (record: any) => record?.key === editingKey;
  const goToProductCategoryDetails = (record: any) => {
    history.push(`${Routes.create_product_category}/${record?.key}`);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      width: '10%',
      editable: false,
    },
    {
      title: editingKey === '' ? <SortTh title="Category Name" dataIndex="name" orderBy={order} order={orderBy} /> : 'Category Name',
      dataIndex: 'name',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
      editable: true,
      maxLength: 50,
      rules: rules?.name,
      placeholder: 'name',
      inputType: 'text',
    },
    {
      title: editingKey === '' ? <SortTh title="Category Code" dataIndex="code" orderBy={order} order={orderBy} /> : 'Category Code',
      dataIndex: 'code',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      editable: true,
      maxLength: 6,
      rules: rules?.code,
      placeholder: 'code',
      inputType: 'text',

    },

    {
      title: 'In Use',
      dataIndex: 'in_use',
      editable: false,
    },
  ];

  const sortTable = (dataIndex: string) => {
    if (editingKey === '') {
      let sort = -1;
      if (order === -1 && dataIndex === orderBy) sort = 1;
      else if (dataIndex !== orderBy) setOrderBy(dataIndex);
      setOrder(sort);
      getCategories(pageNumber, sort, dataIndex);
    }
  };

  const mergedColumns: any = columns.map((col) => {
    if (!col?.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: 'text',
        dataIndex: col?.dataIndex,
        title: col?.title,
        editing: isEditing(record),
        maxLength: col?.maxLength,
        placeholder: col?.placeholder,
        rules: col?.rules,
      }),
    };
  });

  const getCategories = async (page: number, sort: number, sort_by: string) => {
    setIsLoading(true);
    try {
      await categoryStore.getCategories({
        page, limit: Constant.categoryPerPage, order: sort, orderBy: sort_by,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  useEffect(() => {
    getCategories(pageNumber, order, orderBy);
  }, []);

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getCategories(page, order, orderBy);
  };

  const handleAdd = () => {
    history.push(Routes.create_product_category);
    const newData = {
      number: '',
      key: 'new',
      name: '',
      code: '',
      in_use: 0,
    };
    // const updatedData = data?.map((item: any) => {
    //   const temp = item;
    //   temp.number += 1;
    //   return temp;
    // });
    setData([newData, ...data]);
    setEditingKey('new');
    setIsCreateMode(true);
  };

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Breadcrumb className="secondary text-uppercase r-medium">
              <Breadcrumb.Item>
                <Link to={Routes.products}>products</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>manage category</Breadcrumb.Item>
            </Breadcrumb>
            <Divider className="mt-3 mb-3 border-top" />
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24 d-flex align-items-center border-bottom-0">
              Manage Category
              { editingKey === '' && (
              <Tooltip title="Create Category">
                <Button disabled={editingKey !== ''} onClick={handleAdd} className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle">
                  <Icon className="icon-13" iconRef="#add" />
                </Button>
              </Tooltip>
              ) }
            </Typography.Title>
            { !showNodata
            && (
            <Form form={form} component={false}>
              <Table
                onRow={(record) => ({ onClick: () => goToProductCategoryDetails(record) })}
                className="table_wrapper manage_category_listing vertical_align_table"
                rowClassName="cursor-pointer"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                dataSource={data}
                columns={mergedColumns}
                pagination={false}
                size="small"
                scroll={{ x: '800px' }}
              />
            </Form>
            )}
            <Pagination
              total={categories?.total}
              pageSize={Constant.categoryPerPage}
              currentPage={pageNumber}
              onChange={pageHandler}
              disabled={editingKey !== ''}
            />
            { showNodata
          && (
          <div>
            <NoData
              heading="Empty"
              buttonHandler={handleAdd}
              buttonText="Create"
              shopText="Create your first product category"
            />
          </div>
          )}
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage product category</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('categoryStore')(observer(ManageCategory));
