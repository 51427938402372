/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Modal,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Icon, Toast } from '../../../Components';
import Utility from '../../../Global/Utility';

interface ModalProps {
  modalVisibility: boolean;
  onOk: (comment?: string) => void;
  onCancel: () => void;
  isReject: boolean,
  isLoading: boolean,
}

function RequestModal(props: ModalProps) {
  const [textInput, setTextInput] = useState('');
  const {
    modalVisibility,
    onOk,
    onCancel,
    isReject,
    isLoading,
  } = props;

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(event?.target?.value);
  };

  const onModelClose = () => {
    setTextInput('');
    onCancel();
  };

  const onModelOk = () => {
    // if (Utility.validateText(textInput)) {
    //   return Toast.error('Special characters are not allowed.');
    // }
    onOk(textInput);
    setTextInput('');
    return '';
  };

  return (
    <Modal
      title={isReject ? 'Reject Request' : 'Accept Request'}
      centered
      visible={modalVisibility}
      onOk={onModelOk}
      confirmLoading={isLoading}
      onCancel={onModelClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      okText={isReject ? 'Delete Request' : 'Accept Request'}
      okButtonProps={{ className: isReject ? 'd-flex h-40 border-radius red_bg white border-0 r-bold align-items-center' : 'main_btn d-flex r-bold align-items-center', disabled: isLoading }}
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
)}
    >
      {isReject
        ? (
          <div><span className="secondary">Are you sure want to reject this request?</span>
            <div className="mt-3 mb-2 r-medium">NOTE:</div>
            <TextArea className="inp_style area_ht" placeholder="Add a note" autoSize={{ minRows: 2, maxRows: 6 }} onChange={onChange} value={textInput} />
          </div>
        )
        : (
          <span className="secondary">Are you sure you want to accept this request?</span>
        )}
    </Modal>
  );
}

export default observer(RequestModal);
