/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable } from 'mobx';
import countryService from '../Services/countryService';
import Constant from '../Global/Constant';

class CountryStore {
  countries: any = null;

  constructor() {
    makeObservable(this, {
      countries: observable,
    });
  }

  getCountryData = async () => {
    try {
      const response = await countryService.getCountryData();
      const countryMap = new Map();
      response?.data?.countries.forEach((c: any) => {
        const stateMap = new Map();
        c.states.forEach((s: any) => {
          stateMap.set(s.state, {
            cities: s.cities,
          });
        });
        countryMap.set(c.country, stateMap);
      });
      this.countries = countryMap;
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new CountryStore();
