/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { OrderQueryParams } from '../Global/interface';
import api from './api';

class OrderService {
    getOrders = (queryParams: OrderQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/orders/list?${query}`;
      return api.get(url);
    };

    getCancelOrderRequests = (queryParams: OrderQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/orders/cancel-order/list?${query}`;
      return api.get(url);
    };

    getReturnOrderRequests = (queryParams: OrderQueryParams) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/shop/orders/return-order/list?${query}`;
      return api.get(url);
    };

    createOrder = async (order: any) => {
      try {
        const response = await api.post('/shop/orders', order);
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }

    listOrderActivities = (queryParams: any) => {
      const query = queryString.stringify(queryParams, { skipNull: true });
      return api.get(`/shop/orders/activities?${query}`);
    }

    getOrderById = (orderId: string) => api.get(`/shop/orders/${orderId}`);

    getCancelRequestById = (requestId: string) => api.get(`/shop/orders/cancel-order/${requestId}`);

    getReturnRequestById = (requestId: string) => api.get(`/shop/orders/return-order/${requestId}`);

    purchaseOrder = async (orderId: string, data: any) => api.put(`/shop/orders/purchase/${orderId}`, data)

    updateOrderStatus = async (orderId: string, data: any) => api.put(`shop/orders/change-state/${orderId}`, data)

    updateCancelRequestStatus = async (requestId: string, data: any) => api.put(`shop/orders/cancel-order/${requestId}`, data)

    updateReturnStatus = async (requestId: string, data: any) => api.put(`shop/orders/return-order/${requestId}`, data)

    rescheduleOrder = async (requestId: string, data: any) => api.put(`shop/orders/return-order/reschedule/${requestId}`, data)

    uploadFile = async (formData: any) => api.put('shop/orders/return-order/upload-image', formData)

    createCancelOrderRequest = async (orderId: string) => api.post(`shop/orders/cancel-order/${orderId}`, {})

    createReturnOrderRequest = async (orderId: string, params: any) => api.post(`shop/orders/return-order/${orderId}`, params)

    deleteOrder = async (orderId: string) => api.delete(`shop/orders/${orderId}`)

    downloadInvoice = async (orderId: string) => api.get(`shop/orders/invoice/${orderId}`)

    createQuickOrder = async (orderItems: any) => api.post('/shop/orders/quick-order', orderItems)
}
export default new OrderService();
