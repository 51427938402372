/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Col,
  Typography,
  Row,
  Divider,
  Button,
  InputNumber,
  Checkbox,
  Tooltip,
} from 'antd';
import {
  Icon, RenderItem, ServiceReqDateStatus, ServiceReqPaymentMethod, StartCancelRequest, Toast,
} from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

const { FUEL_FILL_TYPE } = Constant;
const { START, COMPLETE, PAYMENT_DONE } = Constant.serviceRequestActions;
const { GATEWAY, POS, CASH } = Constant.payment_mode;
const { OPEN, PAYMENT_PENDING, IN_PROGRESS } = Constant.serviceRequestStatus;

interface Props {
    statusName: string
    IconRef: any
    request: any,
    fillFuelStatus: any,
    clientEmail: string
    clientPhone: string
    date: number
    isLoading: boolean
    updateServiceRequest: (id: string, action: any) => void
    sendPaymentLink: (requestId: string, data: any) => void
    updateVehicleStatus: (fuel_filled: number, value: number, id?: string) => void
}

function FuelFill(props: Props) {
  const {
    statusName, IconRef, request, clientEmail, clientPhone, date, isLoading,
    fillFuelStatus, updateServiceRequest, updateVehicleStatus, sendPaymentLink,
  } = props;
  const isRequestStarted = request?.status === OPEN;
  const isReqInProgress = request?.status === IN_PROGRESS;
  const isPaymentPending = request?.status === PAYMENT_PENDING;

  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const onCheckboxHandler = () => setIsStatusChecked(!isStatusChecked);
  const [filledQuantity, setFilledQuantity] = useState(0);
  const [price, setPrice] = useState(0);

  const additionalAmount = (request?.fuel_unit_price || 0) * request?.request_info?.fuel_filled;
  const totalAmount = Utility.getTotalAmount(
    request?.price, request?.delivery_charge, additionalAmount,
  );

  const onChangeHandler = (value: number) => setFilledQuantity(value);
  const onPriceChangeHandler = (value: number) => setPrice(value);

  const updateRequest = (data: any) => updateServiceRequest(request?._id, data);

  const fuelLevel = Utility.getFuelLevel(request?.request_info?.requested_fuel_level);
  const fuelFillDetails = `${FUEL_FILL_TYPE.get(request?.request_info?.requested_fuel_type)} - ${fuelLevel}`;

  const onFinishHandler = () => {
    if (!filledQuantity) return Toast.error(Constant.invalidFuelQuantity);
    if (!price) return Toast.error(Constant.invalidPrice);

    updateVehicleStatus(
      filledQuantity, Number(request?.request_info?.requested_fuel_level), request?._id,
    );
    updateServiceRequest(request?._id, {
      action: COMPLETE, fuel_filled: filledQuantity, unit_price: price,
    });
    return null;
  };

  const completeHandler = async (data: any) => {
    if (data?.payment_mode === GATEWAY) {
      sendPaymentLink(request?._id, data);
    } else {
      updateServiceRequest(request?._id,
        { action: PAYMENT_DONE, payment_mode: data?.payment_mode, email: data?.email });
    }
  };

  return (
    <>
      { request && (
      <div className="last_brd_0 border-bottom pb-3 mb-4">
        <div>
          <Row gutter={16}>
            <Col lg={9} md={9} sm={9} xs={24}>
              <div className="d-flex align-items-center fs-16">
                <div className="icn_bg_44 primary rounded-circle d-flex align-items-center
                     justify-content-center me-3"
                ><Icon className="icon-20" iconRef={IconRef} />
                </div>
                <div className="lh-sm r-bold fs-16">
                  <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                  {statusName}
                  ({fuelFillDetails})
                </div>
              </div>
            </Col>
            <ServiceReqDateStatus
              isLoading={isLoading}
              isRequestStarted={isRequestStarted}
              scheduled_time={request?.scheduled_time}
              updateRequest={updateRequest}
            />
          </Row>
          { (isRequestStarted || isReqInProgress) && (
          <Row gutter={[16, 16]}>
            <Col lg={9} md={9} sm={9} xs={24}>
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2 text-truncate">Filled Fuel Quantity</Typography.Text>
              <div className="position-relative">
                <InputNumber
                  className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                  disabled={isRequestStarted}
                  onChange={onChangeHandler}
                  min={0}
                  max={999999}
                  maxLength={6}
                  placeholder="Enter Quantity"
                />
                <Typography.Text className="position-absolute inactive unit">L</Typography.Text>
              </div>
            </Col>
            <Col lg={15} md={15} sm={15} xs={24}>
              <Row gutter={[16, 16]}>
                <Col lg={15} md={15} sm={15} xs={24}>
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2 text-truncate">Fuel Unit Price</Typography.Text>
                  <div className="position-relative">
                    <InputNumber
                      className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                      disabled={isRequestStarted}
                      onChange={onPriceChangeHandler}
                      min={0}
                      max={9999999}
                      maxLength={7}
                      step="0.01"
                      precision={2}
                      placeholder="Enter Price"
                    />
                    <Typography.Text className="position-absolute inactive unit">KD</Typography.Text>
                  </div>
                </Col>
                <Col lg={9} md={9} sm={9} xs={24}>
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2 text-truncate">Total Amount</Typography.Text>
                  <Tooltip title={(price * filledQuantity) || 0}>
                    <div className="secondary-invert secondary_bg fs-16 border-radius h-40 d-flex align-items-center px-3"><span className="text-truncate me-1">{(price * filledQuantity) || 0}</span> KD </div>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col lg={9} md={9} sm={9} xs={24}>
              { !isRequestStarted && (
              <Checkbox
                className="chkbox_style primary r-regular fs-16 mt-3"
                onChange={onCheckboxHandler}
                checked={isStatusChecked}
              >Fuel Filled
              </Checkbox>
              ) }
              {fillFuelStatus?.last_updated_time
          && (
          <Typography.Paragraph className="fs-16 mt-2 inactive">
            Last updated - {Utility.getFormattedDate(fillFuelStatus?.last_updated_time)}
            ({`${FUEL_FILL_TYPE.get(fillFuelStatus?.fuel_type)} - ${Utility.getFuelLevel(fillFuelStatus?.value)}`})
          </Typography.Paragraph>
          )}
            </Col>
            <Col lg={15} md={15} sm={15} xs={24}>
              { request && !isRequestStarted
        && (
        <Button
          className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
          disabled={!isStatusChecked}
          onClick={onFinishHandler}
        >Finish Task
        </Button>
        )}
            </Col>
          </Row>
          )}
          <RenderItem isTrue={isPaymentPending}>
            <ServiceReqPaymentMethod
              paymentHeading={`${statusName}(${fuelFillDetails}-${request?.request_info?.fuel_filled}L)(${totalAmount}KD)`}
              email={clientEmail}
              finishHandler={completeHandler}
              okButtonText="Complete Request"
              phone={clientPhone}
              date={date}
              padding={false}
            />
          </RenderItem>
        </div>
      </div>
      ) }
    </>
  );
}

export default FuelFill;
