/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Tooltip } from 'antd';
import Icon from '../Icon';
import Constant from '../../Global/Constant';

interface ServiceIconProps {
  service: string
}

function ServiceIcon(props: ServiceIconProps) {
  const { service } = props;
  return (
    <Tooltip title={Constant?.SERVICES?.get(service)}>
      <div className="d-flex align-items-center fs-16 mb-2 me-2">
        <div className={`icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center ${Constant?.statusImageClass?.get(service)}`}>
          <Icon className="icon-20" iconRef={Constant?.statusImage?.get(service) as string} />
        </div>
      </div>
    </Tooltip>
  );
}

export default ServiceIcon;
