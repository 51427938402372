/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Col,
  Typography,
  Row,
  InputNumber,
  Checkbox,
  Radio,
  Slider,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Action, Icon,
} from '..';
import RenderItem from '../RenderItem';
import Toast from '../Toast';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

interface ServiceFuelFillProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  checkboxValue: boolean,
  checkboxHandler: (value: any, service: string) => void
  service: string,
  completed: boolean,
  completeService: (value: any, service: string) => void
  savedValue: any,
  updateRequestInfo: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string

}

function ServiceFuelFill(props: ServiceFuelFillProps) {
  const {
    isInfoRequired, requestInfo, started, saved, checkboxValue, checkboxHandler, service,
    completed, completeService, savedValue, updateRequestInfo, vehicleStatus, visitStatus,
  } = props;

  const [quantity, setQuantity] = useState<any>(null);
  const [unitPrice, setUnitPrice] = useState<any>(null);
  const [showAction, setShowAction] = useState<boolean>(false);
  const [requestedValue, setRequestedValue] = useState<any>(null);
  const [requestedType, setRequestedType] = useState<any>('');
  const [boxChecked, setBoxChecked] = useState<boolean>(false);

  useEffect(() => {
    // populate value
    if (savedValue) {
      setQuantity(savedValue?.value);
      setUnitPrice(savedValue?.unitPrice);
    }
  }, [savedValue]);

  const handleQuantity = (value: any) => setQuantity(value);
  const handleUnitPrice = (value: any) => setUnitPrice(value);
  const handleAccept = () => {
    if (!quantity || !unitPrice) return Toast.error('Please add quantity and unit price.');
    const value = { quantity, unitPrice };
    return completeService(value, service);
  };
  const handleReject = () => {
    setQuantity(null);
    setUnitPrice(null);
    setBoxChecked(false);
    setShowAction(false);
  };

  const handleCheckbox = (checked: boolean) => {
    setShowAction(checked);
    setBoxChecked(checked);
  };

  const updateRequestedValue = (value: any) => {
    setRequestedValue(value);
    updateRequestInfo({ requested_fuel_type: requestedType, requested_fuel_level: value }, service);
  };

  useEffect(() => {
    if (!savedValue?.value && !savedValue?.unitPrice && !saved) {
      updateRequestInfo({ requested_fuel_type: 'ULTRA', requested_fuel_level: 0 }, service);
      setRequestedValue(0);
      setRequestedType('ULTRA');
    }
  }, []);

  const updateRequestedType = (value: any) => {
    setRequestedType(value);
    updateRequestInfo({ requested_fuel_type: value, requested_fuel_level: requestedValue }, service);
  };

  const showRequestedInfo = (!started && isInfoRequired
  && !(requestInfo?.requested_fuel_level >= 0 && requestInfo?.requested_fuel_type)) || !saved;

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <Row gutter={[16, 16]}>
        <Col lg={9} md={9} sm={9} xs={24}>
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <Checkbox
              className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
              disabled={completed}
              defaultChecked={checkboxValue}
              onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
            />
            <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
              <Icon className="icon-20" iconRef="#fuel" />
            </div>

            Fuel Fill
            <RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
          </div>
        </Col>
        <Col lg={15} md={15} sm={15} xs={24} className="d-flex flex-column">
          <div className="mt-3 mt-sm-0 order-2 order-sm-1">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Paragraph className="fs-16 mb-0 inactive">
                {vehicleStatus?.last_updated_time
                  ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                  : ''}
              </Typography.Paragraph>
              <RenderItem isTrue={started && showAction && !completed}>
                <Action onAccept={handleAccept} onReject={handleReject} />
              </RenderItem>
            </div>
            <RenderItem isTrue={started || requestInfo || isInfoRequired}>
              <Typography.Text className="primary r-medium text-uppercase d-block mb-2 mt-2">SERVICE REQUEST</Typography.Text>
            </RenderItem>
            {requestInfo
            && (
            <Typography.Paragraph className="fs-16 mb-0 r-bold primary">
              {Constant?.FUEL_FILL_TYPE?.get(requestInfo?.requested_fuel_type)} - {Utility?.getFuelLevel(requestInfo?.requested_fuel_level)}
            </Typography.Paragraph>
            )}
          </div>
          {/* <RenderItem isTrue={started && ((requestInfo && isInfoRequired && saved) || !isInfoRequired)}> */}
          <RenderItem isTrue={started && saved}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Row gutter={[16, 16]}>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2 text-truncate">Filled Fuel Quantity</Typography.Text>
                  <div className="position-relative">
                    <InputNumber
                      className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                      min={0}
                      max={999999}
                      maxLength={6}
                      placeholder="Enter Quantity"
                      value={quantity}
                      disabled={completed || visitStatus === 'OPEN'}
                      onChange={(value: any) => handleQuantity(value)}
                    />
                    <Typography.Text className="position-absolute inactive unit">L</Typography.Text>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12} xs={24}>
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2 text-truncate">Fuel Unit Price</Typography.Text>
                  <div className="position-relative">
                    <InputNumber
                      className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                      min={0}
                      max={9999999}
                      maxLength={7}
                      step="0.01"
                      precision={2}
                      placeholder="Enter Price"
                      value={unitPrice}
                      disabled={completed || visitStatus === 'OPEN'}
                      onChange={(value: any) => handleUnitPrice(value)}
                    />
                    <Typography.Text className="position-absolute inactive unit">KD</Typography.Text>
                  </div>
                </Col>
              </Row>
              <Checkbox
                disabled={completed || visitStatus === 'OPEN'}
                defaultChecked={completed}
                checked={boxChecked || completed}
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                onChange={(e) => handleCheckbox(e.target?.checked)}
              >Fuel filled
              </Checkbox>
            </div>
          </RenderItem>
          <RenderItem isTrue={showRequestedInfo}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Radio.Group className="cust_radio_btn" defaultValue="ULTRA" onChange={(e) => updateRequestedType(e?.target?.value)}>
                <Radio className="radio_inp primary" value="ULTRA">Ultra</Radio>
                <Radio className="radio_inp primary" value="SUPER">Super</Radio>
                <Radio className="radio_inp primary" value="PREMIUM">Premium</Radio>
              </Radio.Group>
              <Slider
                trackStyle={{ height: '12px', background: Utility?.sliderColor(requestedValue), borderRadius: '25px' }}
                handleStyle={{
                  width: '24px', height: '24px', marginTop: '-6px', background: '#F2F2F2', boxShadow: '0px 2px 2px #00000029', border: '1px solid #E3E3E3',
                }}
                onChange={(value: any) => updateRequestedValue(value)}
                step={1}
                defaultValue={0}
                max={10}
                tipFormatter={null}
                className="cmn_slider w-50"
              />
            </div>
          </RenderItem>
        </Col>
      </Row>
    </div>
  );
}

export default ServiceFuelFill;
