/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Spin, Upload } from 'antd';
import { Icon, Toast } from '..';
import Constant from '../../Global/Constant';

interface Props {
    isLoading: boolean;
    image: any
    handleOnChangeImage: (info: any) => void;
    removeImage: (file: any) => void,
    acceptedExtension?: string,
    className?: string,
    uploadButtonIcon?: any,
}

const UploadImage = (props: Props) => {
  const {
    isLoading,
    handleOnChangeImage,
    removeImage,
    image,
    acceptedExtension,
    className,
    uploadButtonIcon,
  } = props;

  const customRequest = () => false;

  const uploadButton = (
    <div>
      <Icon className="icon-40" iconRef={uploadButtonIcon} />
    </div>
  );

  const beforeHandle = (fileInfo: any) => {
    const fileExt = fileInfo?.file?.name?.split('.').pop();
    if (!acceptedExtension?.includes(fileExt.toLowerCase())) {
      Toast.error(`Only files with following extensions are allowed: ${acceptedExtension}.`);
      return;
    }
    if ((fileInfo?.file?.size / 1024 / 1024 > Constant.maximumFileSize)) {
      Toast.error(`File size exceeded the limit: ${Constant.maximumFileSize} MB.`);
      return;
    }
    handleOnChangeImage(fileInfo);
  };

  return (
    <Spin spinning={isLoading}>
      <Upload
        name="avatar"
        listType="picture-card"
        className={className}
        showUploadList={false}
        maxCount={1}
        onChange={beforeHandle}
        onRemove={removeImage}
        accept={acceptedExtension}
        customRequest={customRequest}
      >
        {image?.fileUrl ? (
          <img
            src={image?.fileUrl}
            alt="avatar"
            style={{ width: '100%' }}
          />
        ) : uploadButton}
        <div className="img_upload">
          <div className="create_image p-0 rounded-circle justify-content-center d-flex align-items-center">
            <Icon className="icon-40" iconRef="#upload" />
          </div>
        </div>
      </Upload>
    </Spin>
  );
};

UploadImage.defaultProps = {
  acceptedExtension: '.jpeg,.png,.jpg',
  className: 'avatar-uploader pro_pic_avatar',
  uploadButtonIcon: '#User',
};

export default UploadImage;
