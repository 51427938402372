/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Col, Layout, Row, Skeleton, Table, Tooltip, Typography,
} from 'antd';
import './RequestListing.less';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import {
  Icon,
  Info,
  LinkButton,
  NoData, Pagination, RenderItem, Search,
  SortTh, StatusLabel, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import { serviceRequestData } from '../../Global/interface';
import { IServiceStore } from '../../Store/serviceRequestStore';
import Routes from '../../Global/Routes';

const {
  DECLINED,
} = Constant.serviceRequestStatus;

interface ServiceReqHistoryProps{
  serviceStore: IServiceStore
 }

function ServiceReqHistory(props: ServiceReqHistoryProps) {
  const { serviceStore } = props;
  const { servicesRequests } = serviceStore;

  const [isLoading, setIsLoading] = useState(false);
  const [requestPage, setRequestPage] = useState(Constant.defaultPageNumber);
  const [requestKeyword, setRequestKeyword] = useState<string>('');
  const [requestOrderBy, setRequestOrderBy] = useState(-1);
  const [requestOrder, setRequestOrder] = useState('updatedAt');

  const [collapsed, setCollapsed] = useState(false);

  const requestColumn = [
    {
      title: <SortTh title="Request ID" dataIndex="request_code" order={requestOrder} orderBy={requestOrderBy} />,
      dataIndex: 'request_code',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortServiceRequest(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Timestamp" dataIndex="updatedAt" order={requestOrder} orderBy={requestOrderBy} />,
      dataIndex: 'updatedAt',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortServiceRequest(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Request',
      dataIndex: 'service',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'vehicle_no',
      ellipsis: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const sortServiceRequest = (dataIndex: string) => {
    let sortBy = -1;
    if (requestOrderBy === -1 && dataIndex === requestOrder) sortBy = 1;
    else if (dataIndex !== requestOrder) setRequestOrder(dataIndex);
    setRequestOrderBy(sortBy);
    getRequests(requestPage, dataIndex, requestKeyword, sortBy);
  };

  const getRequests = async (
    page: number,
    order: string,
    keyword?: string,
    orderBy?: number,
  ) => {
    setIsLoading(true);
    try {
      await serviceStore.getRequests({
        page,
        keyword,
        order,
        orderBy,
        status: DECLINED,
        limit: Constant.serviceRequest,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getLocation = (request: serviceRequestData) => {
    if (request?.address) return request?.address?.city;
    if (request?.facility) return `${request?.facility?.name} - ${request?.facility?.address}`;
    return request?.contract?.address?.city;
  };

  const requestDataSource = servicesRequests?.service_request?.map(
    (request: serviceRequestData) => ({
      request_code: request?.request_code,
      updatedAt: Utility.getRelativeDate(request?.updatedAt),
      service: Constant.SERVICES.get(request?.service),
      vehicle: (
        <div className="text-capitalize text-truncate">
          {request?.model?.company} {request?.model?.model}
        </div>
      ),
      vehicle_no: request?.vehicle?.vehicle_no || '-',
      location: getLocation(request),
      status:
  <div className="d-flex align-items-center">
    <StatusLabel
      className="label_danger"
      text="Declined"
    />
    <RenderItem isTrue={!!request?.comment}>
      <Tooltip title={request?.comment}>
        <div className="lh_0">
          <Icon className="icon-16 ms-2" iconRef="#info" />
        </div>
      </Tooltip>
    </RenderItem>
  </div>,
      rowKey: request?.vehicle?._id,
    }),
  );

  const locale = {
    emptyText: isLoading ? (
      <Skeleton loading={isLoading} />
    ) : (
      <NoData subTitle="requests" />
    ),
  };

  const serviceSearchHandler = useCallback(
    debounce((nextValue: string, order: string, orderBy: number) => {
      setRequestPage(Constant.defaultPageNumber);
      getRequests(Constant.defaultPageNumber, order, nextValue, orderBy);
    }, Constant.debaounceTime),
    [],
  );

  const onSearchHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event?.target;
    setRequestKeyword(value);
    serviceSearchHandler(value, requestOrder, requestOrderBy);
  };

  const pageHandler = (page: number) => {
    setRequestPage(page);
    getRequests(page, requestOrder, requestKeyword, requestOrderBy);
  };

  useEffect(() => {
    getRequests(requestPage, requestOrder, requestKeyword, requestOrderBy);
  }, []);

  return (
    <Layout.Content className="listing_wrapper request_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <LinkButton
            route={Routes.request}
            text="Back"
            leftArrow
          />
          <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
            <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
              Service Request History
            </Typography.Title>
          </div>
          <div className="request_listing">
            {/* add pb-65 to request_listing class when alert shown */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-sm-flex align-items-center flex-fill">
                <h4 className="mb-0 table-header r-bold">Declined Requests</h4>
                <Search
                  value={requestKeyword}
                  placeholder="Search"
                  titleToolTip="Search Requests"
                  size="small"
                  onChange={onSearchHandler}
                />
              </div>
            </div>
            <Table
              columns={requestColumn}
              dataSource={requestDataSource}
              pagination={false}
              locale={locale}
              size="small"
              scroll={{ x: '1000px' }}
              className="table_wrapper"
            />
            <Pagination
              total={servicesRequests?.total?.counts}
              currentPage={requestPage}
              pageSize={Constant.serviceRequest}
              onChange={pageHandler}
            />
          </div>
        </Col>
        <Info
          setCollapsed={setCollapsed}
          infoDetail="See the service requests history"
        />
      </Row>
    </Layout.Content>
  );
}

export default inject('serviceStore')(observer(ServiceReqHistory));
