/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button,
  Divider,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
  Typography,
} from 'antd';

import { Icon } from '..';

interface RefundModelProps {
    isVisible: boolean,
    handleCancel: any,
    onOk: any,
    isGatewayDisabled: boolean,
    isLoading: boolean
}

function RefundModel(props: RefundModelProps) {
  const {
    isVisible, handleCancel, onOk, isGatewayDisabled, isLoading,
  } = props;

  const [selection, setSelection] = useState<number>(1);

  const handleRefundChange = (e: RadioChangeEvent) => setSelection(e?.target?.value);
  const okHandle = () => {
    onOk(selection);
    setSelection(1);
  };

  const closeModal = () => {
    setSelection(1);
    handleCancel();
  };
  return (

    <Modal
      title="Refund request"
      visible={isVisible}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      className="cmn_modal_wrapper"
      width={400}
      centered
      closeIcon={(
        <Button disabled={isLoading} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Spin spinning={isLoading}>
        <Typography.Paragraph className="secondary fs-16">Choose how you want to refund the request</Typography.Paragraph>
        <Radio.Group className="cust_radio_btn w-100" onChange={handleRefundChange} defaultValue={1}>
          <Space direction="vertical" className="w-100">
            <Radio value={1}>Refund using cash</Radio>
            <Divider className="m-0 border-top" />
            <Radio value={2} disabled={isGatewayDisabled}>Refund using MyFatoorah</Radio>
          </Space>
        </Radio.Group>
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button block type="primary" className="primary_bg text-white h-40 w-auto border-0 border-radius r-bold fs-16" onClick={okHandle} disabled={isLoading}>
            {selection === 1 ? 'Complete' : 'Initiate Refund'}
          </Button>
        </div>
      </Spin>
    </Modal>
  );
}

export default RefundModel;
