/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Col, Dropdown, Layout, Spin, Menu, Row, Table, Tooltip, Typography,
} from 'antd';
import classNames from 'classnames';
import {
  Icon, NoData, Pagination, SortTh, Status, Toast,
} from '../../Components/index';
import './Product.less';
import Constant from '../../Global/Constant';
import { IProductStoreProps } from '../../Store/productStore';
import { Product } from '../../Global/interface';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IProducts {
  productStore: IProductStoreProps
}

function Products(props: IProducts) {
  const history = useHistory();
  const { productStore } = props;
  const { products } = productStore;
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState<any>('createdAt');
  const [order, setOrder] = useState<any>(-1);
  const showNodata = products?.total <= 0;
  const [collapsed, setCollapsed] = useState(false);

  const sortTable = (dataIndex: string) => {
    let sort = -1;
    if (order === -1 && dataIndex === orderBy) sort = 1;
    else if (dataIndex !== orderBy) setOrderBy(dataIndex);
    setOrder(sort);
    getProducts(pageNumber, sort, dataIndex);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      width: '10%',
    },
    {
      title: <SortTh title="Category" dataIndex="category.name" orderBy={order} order={orderBy} />,
      dataIndex: 'category',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('category.name');
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Product" dataIndex="name" orderBy={order} order={orderBy} />,
      dataIndex: 'name',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Variants',
      dataIndex: 'variants',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const data = products?.products?.map((product: Product, index: number) => ({
    number: ((pageNumber - 1) * Constant.productPerPage) + (index + 1),
    key: product?._id,
    category: <div className="text-capitalize text-truncate">{product?.category?.name}</div>,
    name: <div className="text-capitalize text-truncate">{product?.name}</div>,
    price: `${product?.price} KD`,
    variants: product?.variants?.length,
    status: <Status
      statusClassName={Constant.productClassName}
      status={product?.published}
      statusText={Constant.productStatusText}
    />,
  }));

  const getProducts = async (page: number, sort: number, sortBy: string) => {
    setIsLoading(true);
    try {
      await productStore.getProducts({
        page, limit: Constant.productPerPage, order: sort, orderBy: sortBy,
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    productStore?.initialize();
    getProducts(pageNumber, order, orderBy);
  }, []);

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getProducts(page, order, orderBy);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={Routes.manage_category} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
          Manage Category
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const goToProductCreate = () => history.push(Routes.create_product);

  const goToProductUpdate = (record: any) => { history.push(`${Routes.create_product}/${record?.key}`); };

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between mb-3 mb-xl-0">
              <Typography.Title className="m-0 header_main mb-xl-2 mb-0 pb-xl-3 pb-0 fs-xs-24 d-flex align-items-center border-bottom-0">
                Products
                <Tooltip title="Create Product">
                  <Button onClick={goToProductCreate} className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle">
                    <Icon className="icon-13" iconRef="#add" />
                  </Button>
                </Tooltip>
              </Typography.Title>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            { !showNodata
            && (
            <Table
              className="table_wrapper product_listing"
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={data}
              pagination={false}
              onRow={(record) => ({ onClick: () => goToProductUpdate(record) })}
              size="small"
              scroll={{ x: '800px' }}
            />
            )}
            <Pagination
              total={products?.total}
              pageSize={Constant.productPerPage}
              currentPage={pageNumber}
              onChange={pageHandler}
            />
            { showNodata
          && (
            <div>
              <NoData buttonHandler={goToProductCreate} heading="Empty" buttonText="Create" shopText="Create your first product" />
            </div>
          )}
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the products</Paragraph>
            </Typography>
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-xl-5">Action</h6>
              <Tooltip title="Manage Category">
                <Link to={Routes.manage_category} className="d-flex ml-auto mb-3 r-bold align-items-center primary p-0">
                  Manage Category
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('productStore')(observer(Products));
