/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Typography,
} from 'antd';
import {
  Action, Icon, RenderItem, ServiceReqDateStatus, ServiceReqPaymentMethod, StartCancelRequest,
} from '../../Components';
import wheel1 from '../../Assets/Images/ic-t1.svg';
import wheel2 from '../../Assets/Images/ic-t2.svg';
import wheel3 from '../../Assets/Images/ic-t3.svg';
import wheel4 from '../../Assets/Images/ic-t4.svg';
import twowheel1 from '../../Assets/Images/ic-front-wheel.svg';
import twowheel2 from '../../Assets/Images/ic-back-wheel.svg';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import Toast from '../Toast';

interface IWheelPressureDetail{
  tirePressure: number,
  wheelText: string
}

const WheelPressureDetail = (props: IWheelPressureDetail) => {
  const { tirePressure, wheelText } = props;
  return (
    <div className="me-sm-3 r-bold fs-16 lh-sm border-right border-right-xs-0 pe-3 mb-3">
      <Typography.Text className="r-medium fs-14 secondary text-uppercase">{wheelText}</Typography.Text><br />
      {tirePressure} PSI
    </div>
  );
};

interface Props {
  statusName: String
  IconRef: any
  unitLabel: String,
  checkBoxText: String,
  pressureStatus: any,
  request?: any
  updateServiceRequest: (id: string, action: any) => void
  updateVehicleStatus: (statusData: any, id?: string) => void
  wheels: number
  clientEmail?: string
  clientPhone?: string
  date?: number
  price?: number
  isLoading: boolean
  sendPaymentLink?: (requestId: string, data: any) => void
}

TirePressure.defaultProps = {
  request: null,
  price: null,
  clientEmail: null,
  clientPhone: null,
  date: null,
  sendPaymentLink: () => {},
};
const { OPEN, PAYMENT_PENDING } = Constant.serviceRequestStatus;
const { COMPLETE, PAYMENT_DONE } = Constant.serviceRequestActions;
const { GATEWAY } = Constant.payment_mode;
const {
  psi, bar, barToPsi, psiToBar,
} = Constant;

function TirePressure(props: Props) {
  const {
    unitLabel, checkBoxText, wheels,
    statusName, IconRef, pressureStatus, request, updateServiceRequest, updateVehicleStatus,
    clientEmail, clientPhone, date, sendPaymentLink, price, isLoading,
  } = props;

  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [tirePressureValue, setTirePressureValue] = useState(pressureStatus?.value);
  const [tirePressureUnit, setTirePressureUnit] = useState<string>(psi);
  const isRequestStarted = request?.status === OPEN;

  const onConfirm = () => {
    setIsStatusChecked(false);
    if (tirePressureValue) {
      if (tirePressureUnit === bar) tirePressureConverter(tirePressureValue, barToPsi);
      updateVehicleStatus(tirePressureValue);
    } else {
      Toast.error(Constant.invalidTirePressure);
    }
  };

  const radioHandler = (event: any) => {
    const { value } = event?.target;
    if (value === bar) tirePressureConverter(tirePressureValue, psiToBar);
    else if (value === psi) tirePressureConverter(tirePressureValue, barToPsi);
    setTirePressureUnit(value);
  };

  const tirePressureConverter = (presentValue: any, metricValue: number) => {
    const currentValue = presentValue;
    if (presentValue?.wheel_1) currentValue.wheel_1 = presentValue?.wheel_1 * metricValue;
    if (presentValue?.wheel_2) currentValue.wheel_2 = presentValue?.wheel_2 * metricValue;
    if (presentValue?.wheel_3) currentValue.wheel_3 = presentValue?.wheel_3 * metricValue;
    if (presentValue?.wheel_4) currentValue.wheel_4 = presentValue?.wheel_4 * metricValue;
    setTirePressureValue(currentValue);
  };

  const onCancel = () => {
    setIsStatusChecked(false);
    setTirePressureValue(pressureStatus?.value);
  };

  const onCheckboxHandler = () => setIsStatusChecked(!isStatusChecked);

  const updateRequest = (data: any) => updateServiceRequest(request?._id, data);

  const onChangeHandler = (
    value: any, wheel: string,
  ) => {
    const obj = {
      ...tirePressureValue,
      [wheel]: value,
    };
    setTirePressureValue(obj);
  };

  const onFinishHandler = () => {
    if (tirePressureValue) {
      if (tirePressureUnit === bar) tirePressureConverter(tirePressureValue, barToPsi);
      updateVehicleStatus(tirePressureValue, request?._id);
      updateServiceRequest(request?._id, { action: COMPLETE });
    } else {
      Toast.error(Constant.invalidTirePressure);
    }
  };

  useEffect(() => {
    if (tirePressureUnit === psi) setTirePressureValue(pressureStatus?.value);
    else if (tirePressureUnit === bar) tirePressureConverter(pressureStatus?.value, psiToBar);
  }, [pressureStatus?.value]);

  const isPaymentPending = request?.status === PAYMENT_PENDING;

  const completeHandler = async (data: any) => {
    if (data?.payment_mode === GATEWAY) {
      if (sendPaymentLink) sendPaymentLink(request?._id, data);
    } else {
      updateServiceRequest(request?._id,
        { action: PAYMENT_DONE, payment_mode: data?.payment_mode, email: data?.email });
    }
  };

  const totalAmount = Utility.getTotalAmount(request?.price, request?.delivery_charge);

  return (
    <div>
      { wheels > 0 && (
      <div className="border-bottom pb-3 mb-4">
        { request && (
        <div>
          <Row gutter={16}>
            <Col lg={9} md={9} sm={9} xs={24}>
              <div className="d-flex align-items-center fs-16">
                <div className="icn_bg_44 primary rounded-circle d-flex align-items-center
                     justify-content-center me-3"
                ><Icon className="icon-20" iconRef={IconRef} />
                </div>
                <div className="lh-sm r-bold fs-16">
                  <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                  {statusName}
                </div>
              </div>
              <RenderItem isTrue={!isPaymentPending}>
                <div className="metrices_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">{unitLabel}</Typography.Text>
                  <Radio.Group
                    value={tirePressureUnit}
                    buttonStyle="solid"
                    className="radio_tab gray_bg w-100 border-radius p-0"
                    onChange={radioHandler}
                  >
                    <Radio.Button value={psi} className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">PSI</Radio.Button>
                    <Radio.Button value={bar} className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">BAR</Radio.Button>
                  </Radio.Group>
                </div>
              </RenderItem>
            </Col>
            <ServiceReqDateStatus
              isLoading={isLoading}
              isRequestStarted={isRequestStarted}
              scheduled_time={request?.scheduled_time}
              updateRequest={updateRequest}
            >
              <RenderItem isTrue={!!request?.requested_tire_pressure}>
                <div className="d-sm-flex flex-wrap mb-3 border-bottom">
                  <RenderItem isTrue={!!request?.requested_tire_pressure?.wheel_1}>
                    <WheelPressureDetail
                      wheelText="WHEEL 1"
                      tirePressure={request?.requested_tire_pressure?.wheel_1}
                    />
                  </RenderItem>
                  <RenderItem isTrue={wheels > 1 && !!request?.requested_tire_pressure?.wheel_2}>
                    <WheelPressureDetail
                      wheelText="WHEEL 2"
                      tirePressure={request?.requested_tire_pressure?.wheel_2}
                    />
                  </RenderItem>
                  <RenderItem isTrue={wheels > 2 && !!request?.requested_tire_pressure?.wheel_3}>
                    <WheelPressureDetail
                      wheelText="WHEEL 3"
                      tirePressure={request?.requested_tire_pressure?.wheel_3}
                    />
                  </RenderItem>
                  <RenderItem isTrue={wheels > 3 && !!request?.requested_tire_pressure?.wheel_4}>
                    <WheelPressureDetail
                      wheelText="WHEEL 4"
                      tirePressure={request?.requested_tire_pressure?.wheel_4}
                    />
                  </RenderItem>
                </div>
              </RenderItem>
            </ServiceReqDateStatus>
          </Row>
        </div>
        ) }
        <RenderItem isTrue={!isPaymentPending}>
          <Row gutter={16}>
            <Col lg={9} md={9} sm={9} xs={24}>
              { !request && (
              <div>
                <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
                  <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3"><Icon className="icon-20" iconRef={IconRef} /></div>
                  {statusName}
                </div>
                <div className="metrices_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
                  <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">{unitLabel}</Typography.Text>
                  <Radio.Group
                    value={tirePressureUnit}
                    buttonStyle="solid"
                    className="radio_tab gray_bg w-100 border-radius p-0"
                    onChange={radioHandler}
                  >
                    <Radio.Button value={psi} className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">PSI</Radio.Button>
                    <Radio.Button value={bar} className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">BAR</Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              )}
            </Col>
            <Col lg={10} md={10} sm={10} xs={24}>
              <div className="mb-3">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 1</Typography.Text>
                <Row gutter={16}>
                  <Col lg={18} md={18} sm={18} xs={18}>
                    <div className="position-relative">
                      <InputNumber
                        className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                        value={tirePressureValue?.wheel_1}
                        disabled={isRequestStarted}
                        onChange={(value: number) => onChangeHandler(value, 'wheel_1')}
                        min={0}
                        max={99999}
                        maxLength={5}
                        precision={2}
                      />
                      <Typography.Text className="position-absolute inactive unit">{tirePressureUnit}</Typography.Text>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={6}>
                    <img src={wheels === 2 ? twowheel1 : wheel1} alt="tire_pressure_img" />
                  </Col>
                </Row>
              </div>
              { wheels > 1 && (
              <div className="mb-3">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 2</Typography.Text>
                <Row gutter={16}>
                  <Col lg={18} md={18} sm={18} xs={18}>
                    <div className="position-relative">
                      <InputNumber
                        className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                        value={tirePressureValue?.wheel_2}
                        disabled={isRequestStarted}
                        onChange={(value: number) => onChangeHandler(value, 'wheel_2')}
                        min={0}
                        max={99999}
                        maxLength={5}
                        precision={2}
                      />
                      <Typography.Text className="position-absolute inactive unit">{tirePressureUnit}</Typography.Text>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={6}>
                    <img src={wheels === 2 ? twowheel2 : wheel2} alt="tire_pressure_img" />
                  </Col>
                </Row>
              </div>
              )}
              { wheels > 2 && (
              <div className="mb-3">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 3</Typography.Text>
                <Row gutter={16}>
                  <Col lg={18} md={18} sm={18} xs={18}>
                    <div className="position-relative">
                      <InputNumber
                        className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                        onChange={(value: number) => onChangeHandler(value, 'wheel_3')}
                        value={tirePressureValue?.wheel_3}
                        disabled={isRequestStarted}
                        min={0}
                        max={99999}
                        maxLength={5}
                        precision={2}
                      />
                      <Typography.Text className="position-absolute inactive unit">{tirePressureUnit}</Typography.Text>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={6}>
                    <img src={wheel3} alt="tire_pressure_img" />
                  </Col>
                </Row>
              </div>
              )}
              { wheels > 3 && (
              <div className="mb-3">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 4</Typography.Text>
                <Row gutter={16}>
                  <Col lg={18} md={18} sm={18} xs={18}>
                    <div className="position-relative">
                      <InputNumber
                        className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
                        type="text"
                        onChange={(value: number) => onChangeHandler(value, 'wheel_4')}
                        value={tirePressureValue?.wheel_4}
                        disabled={isRequestStarted}
                        min={0}
                        max={99999}
                        maxLength={5}
                        precision={2}
                      />
                      <Typography.Text className="position-absolute inactive unit">{tirePressureUnit}</Typography.Text>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={6}>
                    <img src={wheel4} alt="tire_pressure_img" />
                  </Col>
                </Row>
                {pressureStatus?.last_updated_time && <Typography.Paragraph className="fs-16 mt-2 inactive">Last updated - {Utility.getFormattedDate(pressureStatus?.last_updated_time)}</Typography.Paragraph>}
              </div>
              )}
              { !isRequestStarted && (
              <Checkbox className="chkbox_style primary r-regular fs-16" onChange={onCheckboxHandler} checked={isStatusChecked}>
                {checkBoxText}
              </Checkbox>
              ) }
              { request && !isRequestStarted
          && (
          <Button
            className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
            disabled={!isStatusChecked}
            onClick={onFinishHandler}
          >Finish Task
          </Button>
          )}
            </Col>
            { !request && isStatusChecked && (
            <Col
              lg={4}
              className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
            ><Action onAccept={onConfirm} onReject={onCancel} />
            </Col>
            )}
          </Row>
        </RenderItem>
        <RenderItem isTrue={isPaymentPending}>
          <ServiceReqPaymentMethod
            paymentHeading={`${statusName} (${totalAmount || price} KD)`}
            email={clientEmail as string}
            finishHandler={completeHandler}
            okButtonText="Complete Request"
            phone={clientPhone as string}
            date={date as number}
          />
        </RenderItem>
      </div>
      )}
    </div>
  );
}
export default TirePressure;
