/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useCallback } from 'react';
import {
  AutoComplete,
  Button, Input, List,
} from 'antd';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Icon, NoData, Toast } from '..';
import Constant from '../../Global/Constant';
import {
  Client, Employee, VehicleData,
} from '../../Global/interface';
import { AuthStoreProps } from '../../Store/authStore';
import Routes from '../../Global/Routes';
import { ISearchStore } from '../../Store/searchStore';
import './AppSearch.less';

interface AppSearchProps{
    searchStore?: ISearchStore
    authStore?: AuthStoreProps
}

function AppSearch(props: AppSearchProps) {
  const { authStore, searchStore } = props;
  const history = useHistory();
  const [search, setSearch] = useState<boolean>(false);
  const [value, setValue] = useState('');
  const isSuperAdmin = authStore?.currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);
  const options: Array<any> = [];
  let client;
  let vehicle;
  let employee;

  const goToDetailPage = (route: any) => history.push(route);

  const renderTitle = (title: string) => (
    <span className="text-uppercase r-medium">
      {title}
    </span>
  );

  const renderItem = (
    title: string, route: any, id: string, code: string, image: any, isVehicle: boolean,
  ) => ({
    route,
    value: id,
    text: title,
    label: (
      <div className="d-flex text-capitalize align-items-center">
        <div className={classnames('srch_drp_icn rounded-circle justify-content-center d-flex align-items-center me-3', { bg_none_search: isVehicle === true && image })}>
          {image
            ? <img className={classnames('search_pr_pic vh_icn', { vh_icn: isVehicle === true }, { 'rounded-circle': isVehicle === false })} src={image} alt="img" />
            : <Icon className="icon-14" iconRef={isVehicle ? '#wheel' : '#User'} /> }
        </div>
        <div className="text-truncate">
          <span className="text-truncate" title={title}>
            {title}
          </span>
          <div className="mb-0 text-truncate" title={code}> {code}</div>
        </div>
      </div>
    ),
  });

  if (searchStore?.clients?.length) {
    client = {
      label: renderTitle('clients'),
      options:
          searchStore?.clients?.map(
            (clients: Client) => renderItem(`${clients?.first_name} ${clients?.middle_name || ''} ${clients?.last_name}`, `${Routes.client_detail}/${clients?._id}`, clients?._id, clients?.phone, clients?.profile?.fileUrl, false),
          ),
    };
  }

  if (searchStore?.vehicles?.length) {
    vehicle = {
      label: renderTitle('vehicles'),
      options:
          searchStore?.vehicles?.map((vehicles: VehicleData) => renderItem(`${vehicles?.model?.company?.name} ${vehicles?.model?.model?.name}`, `${Routes.vehicleDetails}/${vehicles?._id}`, vehicles?._id, vehicles?.vehicle_no, vehicles?.image?.fileUrl, true)),
    };
  }

  if (searchStore?.employees?.length) {
    employee = {
      label: renderTitle('employees'),
      options:
          searchStore?.employees?.map((employees: Employee) => renderItem(`${employees?.first_name} ${employees?.middle_name || ''} ${employees?.last_name}`, `${Routes.employees_detail}/${employees?._id}`, employees?._id, employees?.phone, employees?.profile?.fileUrl, false)),
    };
  }

  if (client && client?.options.length) options.push(client);
  if (vehicle && vehicle?.options.length) options.push(vehicle);
  if (isSuperAdmin && employee && employee?.options.length) options.push(employee);

  const getSearchResult = async (keyword: string) => {
    try {
      if (keyword) await searchStore?.getSearchResult(keyword);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const debounceSearch = useCallback(
    debounce((nextValue: any) => getSearchResult(nextValue), Constant.debaounceTime),
    [],
  );

  const onSearch = (value: any) => {
    debounceSearch(value);
    setValue(value);
    if (!value && searchStore) {
      searchStore?.initialize();
    }
  };

  const onSelect = (value: any, option: any) => {
    goToDetailPage(option?.route);
    setSearch(false);
    setValue('');
  };

  const searchBtnHandler = () => setSearch(true);
  const closeSearch = () => setSearch(false);

  const placeholder = isSuperAdmin ? 'Search client, vehicle, employee' : 'Search client, vehicle';
  return (
    <List.Item key="search" className="p-0 h_rgt_menu d-flex align-items-center border-0">
      {search && (
      <div className="resp_search body_scroll_none">
        <div className="d-flex d-sm-none justify-content-end">
          <Button className="d-flex justify-content-center align-items-center text-center p-0 modal_close mt-0 mb-2" type="link" shape="circle" onClick={closeSearch}>
            <Icon className="icon-10" iconRef="#close" />
          </Button>
        </div>
        <div className="position-relative">
          <Button type="link" className="position-absolute top-0 left-0 h-100 z-99">
            <Icon className="icon-14" iconRef="#Search" />
          </Button>
          <AutoComplete
            dropdownClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={330}
            value={value}
            allowClear
            style={{ width: 330 }}
            className="d-flex auto-cmp clr_bg_trans"
            options={options}
            onSelect={onSelect}
            onChange={onSearch}
            notFoundContent={value ? <NoData subTitle="search result" size={75} /> : ''}
            autoFocus
          >
            <Input.Search className="serch_inp" size="large" placeholder={placeholder} />
          </AutoComplete>
        </div>
      </div>
      )}
      {!search && (
      <Button onClick={searchBtnHandler} type="link">
        <Icon className="icon-14" iconRef="#Search" />
      </Button>
      )}
    </List.Item>
  );
}

export default inject('searchStore', 'authStore')(observer(AppSearch));
