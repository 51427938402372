/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  InputNumber,
  Radio,
  Row,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Action, Icon, RenderItem, Toast,
} from '../../Components';
import wheel1 from '../../Assets/Images/ic-t1.svg';
import wheel2 from '../../Assets/Images/ic-t2.svg';
import wheel3 from '../../Assets/Images/ic-t3.svg';
import wheel4 from '../../Assets/Images/ic-t4.svg';
import twowheel1 from '../../Assets/Images/ic-front-wheel.svg';
import twowheel2 from '../../Assets/Images/ic-back-wheel.svg';
import WheelInfo from './WheelInfo';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface ServiceTirePressureProps {
  isInfoRequired: boolean,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  checkboxValue: boolean,
  checkboxHandler: (value: any, service: string) => void
  service: string,
  completed: boolean,
  completeService: (value: any, service: string) => void
  savedValue: any,
  updateRequestInfo: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string
  wheels: any
}

function ServiceTirePressure(props: ServiceTirePressureProps) {
  const {
    isInfoRequired, requestInfo, started, saved, checkboxValue, checkboxHandler, service,
    completed, completeService, savedValue, updateRequestInfo, vehicleStatus, visitStatus,
    wheels,
  } = props;
  const {
    psi, bar, barToPsi, psiToBar,
  } = Constant;

  const [showAction, setShowAction] = useState<boolean>(false);
  const [boxChecked, setBoxChecked] = useState<boolean>(false);
  const [tirePressureUnit, setTirePressureUnit] = useState<string>(psi);

  const [tire_1, setTire_1] = useState<any>(null);
  const [tire_2, setTire_2] = useState<any>(null);
  const [tire_3, setTire_3] = useState<any>(null);
  const [tire_4, setTire_4] = useState<any>(null);

  const [actual_tire_1, setActual_Tire_1] = useState<any>(null);
  const [actual_tire_2, setActual_Tire_2] = useState<any>(null);
  const [actual_tire_3, setActual_Tire_3] = useState<any>(null);
  const [actual_tire_4, setActual_Tire_4] = useState<any>(null);

  const [showTireInput, setShowTireInput] = useState<boolean>(false);

  useEffect(() => {
    // populate vehicle status value
    if (vehicleStatus) {
      let valueToShow = {
        wheel_1: vehicleStatus?.value?.wheel_1,
        wheel_2: vehicleStatus?.value?.wheel_2,
        wheel_3: vehicleStatus?.value?.wheel_3,
        wheel_4: vehicleStatus?.value?.wheel_4,
      };
      if (tirePressureUnit === bar) {
        valueToShow = convertToMetric(valueToShow, psiToBar);
      }
      setActual_Tire_1(valueToShow?.wheel_1);
      setActual_Tire_2(valueToShow?.wheel_2);
      setActual_Tire_3(valueToShow?.wheel_3);
      setActual_Tire_4(valueToShow?.wheel_4);
    } else {
      setActual_Tire_1(null);
      setActual_Tire_2(null);
      setActual_Tire_3(null);
      setActual_Tire_4(null);
    }
  }, [vehicleStatus]);

  const handleAccept = () => {
    const value = {
      wheel_1: actual_tire_1,
      wheel_2: actual_tire_2,
      wheel_3: actual_tire_3,
      wheel_4: actual_tire_4,
    };

    if (Utility?.checkTirePressureValid(value, wheels)) return Toast.error('Please add all wheel info.');

    let updatedValue = value;
    if (tirePressureUnit === bar) {
      updatedValue = convertToMetric(value, barToPsi);
    }
    return completeService(updatedValue, service);
  };

  const handleReject = () => {
    resetTires();
    setBoxChecked(false);
    setShowAction(false);
  };

  const handleCheckbox = (checked: boolean) => {
    setShowAction(checked);
    setBoxChecked(checked);
  };

  const resetTires = () => {
    setTire_1(null);
    setTire_2(null);
    setTire_3(null);
    setTire_4(null);
  };

  const convertToMetric = (tirePressures: any, metric: number) => {
    if (tirePressures?.wheel_1) tirePressures.wheel_1 *= metric;
    if (tirePressures?.wheel_2) tirePressures.wheel_2 *= metric;
    if (tirePressures?.wheel_3) tirePressures.wheel_3 *= metric;
    if (tirePressures?.wheel_4) tirePressures.wheel_4 *= metric;
    return tirePressures;
  };

  const updateRequestedValue = (value: any, wheelNo: string) => {
    let updatedValues: {[key: string]: number} = {
      wheel_1: tire_1,
      wheel_2: tire_2,
      wheel_3: tire_3,
      wheel_4: tire_4,
    };
    updatedValues[wheelNo] = value;

    if (tirePressureUnit === bar) {
      updatedValues = convertToMetric(updatedValues, barToPsi);
    } else if (value === psi) {
      updatedValues = convertToMetric(updatedValues, psiToBar);
    }

    updateRequestInfo(updatedValues, service);
  };

  useEffect(() => {
    const value = {
      wheel_1: requestInfo?.wheel_1,
      wheel_2: requestInfo?.wheel_2,
      wheel_3: requestInfo?.wheel_3,
      wheel_4: requestInfo?.wheel_4,
    };

    if (!requestInfo || Utility?.checkTirePressureValid(value, wheels)) {
      updateRequestInfo('REGULAR', service);
    }
  }, []);

  const handleInnerCheckbox = (value: string) => {
    if (value === 'REGULAR') {
      setShowTireInput(false);
      resetTires();
      updateRequestInfo('REGULAR', service);
      // handle added value remove
    } else {
      updateRequestInfo({
        wheel_1: null,
        wheel_2: null,
        wheel_3: null,
        wheel_4: null,
      }, service);
      setShowTireInput(true);
    }
  };

  const tirePressureConverter = (presentValue: any, metricValue: number) => {
    if (presentValue?.wheel_1) setTire_1(presentValue?.wheel_1 * metricValue);
    if (presentValue?.wheel_2) setTire_2(presentValue?.wheel_2 * metricValue);
    if (presentValue?.wheel_3) setTire_3(presentValue?.wheel_3 * metricValue);
    if (presentValue?.wheel_4) setTire_4(presentValue?.wheel_4 * metricValue);
  };

  const tirePressureConverterActual = (presentValue: any, metricValue: number) => {
    if (presentValue?.wheel_1) setActual_Tire_1(presentValue?.wheel_1 * metricValue);
    if (presentValue?.wheel_2) setActual_Tire_2(presentValue?.wheel_2 * metricValue);
    if (presentValue?.wheel_3) setActual_Tire_3(presentValue?.wheel_3 * metricValue);
    if (presentValue?.wheel_4) setActual_Tire_4(presentValue?.wheel_4 * metricValue);
  };

  const radioHandler = (event: any) => {
    const { value } = event?.target;
    const requestedValue = {
      wheel_1: tire_1,
      wheel_2: tire_2,
      wheel_3: tire_3,
      wheel_4: tire_4,
    };

    const actualValue = {
      wheel_1: actual_tire_1,
      wheel_2: actual_tire_2,
      wheel_3: actual_tire_3,
      wheel_4: actual_tire_4,
    };

    if (value === bar) {
      tirePressureConverter(requestedValue, psiToBar);
      tirePressureConverterActual(actualValue, psiToBar);
    } else if (value === psi) {
      tirePressureConverter(requestedValue, barToPsi);
      tirePressureConverterActual(actualValue, barToPsi);
    }
    setTirePressureUnit(value);
  };

  const showRequestedInfo = (!started && isInfoRequired
  && !(requestInfo?.wheel_1 >= 0 && requestInfo?.wheel_2 >= 0
     && requestInfo?.wheel_3 >= 0 && requestInfo?.wheel_4 >= 0)) || !saved;

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <Checkbox
              className="chkbox_style checkbox_disable_after primary r-regular fs-16 me-3"
              defaultChecked={checkboxValue}
              disabled={completed}
              onChange={(e: CheckboxChangeEvent) => checkboxHandler(e?.target?.checked, service)}
            />
            <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
              <Icon className="icon-20" iconRef="#tire" />
            </div>
            Tire Pressure <RenderItem isTrue={!saved}><sup className="yellow_notf rounded-circle yellow_bg ms-2 mt-2" /></RenderItem>
          </div>
          <RenderItem isTrue={started && !(requestInfo === 'REGULAR' && !saved)}>
            <div className="metrices_wrap metrices_service_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">METRICS</Typography.Text>
              <Radio.Group
                value={tirePressureUnit}
                buttonStyle="solid"
                className="radio_tab gray_bg w-100 border-radius p-0"
                onChange={radioHandler}
              >
                <Radio.Button value="psi" className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">PSI</Radio.Button>
                <Radio.Button value="bar" className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">BAR</Radio.Button>
              </Radio.Group>
            </div>
          </RenderItem>
        </Col>
        <Col lg={15} md={15} sm={15} xs={24} className="d-flex flex-column">
          <div className="mt-3 mt-sm-0 order-2 order-sm-1">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Paragraph className="fs-16 mb-0 inactive">
                {vehicleStatus?.last_updated_time
                  ? `Last updated - ${Utility?.getFormattedDate(vehicleStatus?.last_updated_time)}`
                  : ''}
              </Typography.Paragraph>
              <RenderItem isTrue={started && showAction && !completed}>
                <Action onAccept={handleAccept} onReject={handleReject} />
              </RenderItem>
            </div>
            <RenderItem isTrue={(!saved || started)}>
              <Typography.Text className="primary r-medium text-uppercase d-block mb-2 mt-2">SERVICE REQUEST</Typography.Text>
            </RenderItem>
            <RenderItem isTrue={saved && requestInfo && requestInfo !== 'REGULAR'}>
              <WheelInfo wheelInfo={requestInfo} wheelNo={wheels} />
            </RenderItem>
          </div>
          <RenderItem isTrue={started && saved}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Row gutter={[16, 16]}>
                <RenderItem isTrue={wheels > 0}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 1</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            disabled={completed || visitStatus === 'OPEN'}
                            value={actual_tire_1}
                            onChange={(value: number) => setActual_Tire_1(value)}
                            maxLength={5}
                            precision={2}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheels === 2 ? twowheel1 : wheel1} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 1}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 2</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            value={actual_tire_2}
                            onChange={(value: number) => setActual_Tire_2(value)}
                            disabled={completed || visitStatus === 'OPEN'}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheels === 2 ? twowheel2 : wheel2} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 2}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 3</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            maxLength={5}
                            disabled={completed || visitStatus === 'OPEN'}
                            precision={2}
                            value={actual_tire_3}
                            onChange={(value: number) => setActual_Tire_3(value)}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheel3} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 3}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 4</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            type="text"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            disabled={completed || visitStatus === 'OPEN'}
                            value={actual_tire_4}
                            onChange={(value: number) => setActual_Tire_4(value)}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheel4} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
              </Row>
              <Checkbox
                disabled={completed || visitStatus === 'OPEN'}
                defaultChecked={completed}
                checked={boxChecked || completed}
                className="chkbox_style checkbox_disable_after primary r-regular fs-16 mt-3"
                onChange={(e) => handleCheckbox(e.target?.checked)}
              >Tire Pressure status updated
              </Checkbox>
            </div>
          </RenderItem>
          <RenderItem isTrue={showRequestedInfo && !saved}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Radio.Group className="cust_radio_btn" defaultValue="REGULAR" onChange={(e) => handleInnerCheckbox(e?.target?.value)}>
                <Radio className="radio_inp primary" value="REGULAR">Regular</Radio>
                <Radio className="radio_inp primary" value="CUSTOM">Custom</Radio>
              </Radio.Group>
            </div>
          </RenderItem>
          <RenderItem isTrue={showTireInput && !saved}>
            <div className="border-top mt-3 pt-sm-3 order-1 order-sm-2 border-xs-bottom-0">
              <Row gutter={[16, 16]}>
                <RenderItem isTrue={wheels > 0}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 1</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            value={tire_1}
                            onChange={(value) => {
                              setTire_1(value);
                              updateRequestedValue(value, 'wheel_1');
                            }}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheels === 2 ? twowheel1 : wheel1} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 1}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 2</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            value={tire_2}
                            onChange={(value) => {
                              setTire_2(value);
                              updateRequestedValue(value, 'wheel_2');
                            }}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheels === 2 ? twowheel2 : wheel2} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 2}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 3</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            value={tire_3}
                            onChange={(value) => {
                              setTire_3(value);
                              updateRequestedValue(value, 'wheel_3');
                            }}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheel3} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
                <RenderItem isTrue={wheels > 3}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Wheel 4</Typography.Text>
                    <Row gutter={16}>
                      <Col lg={18} md={18} sm={18} xs={18}>
                        <div className="position-relative">
                          <InputNumber
                            className="off-white-bg border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none"
                            type="text"
                            min={0}
                            max={99999}
                            maxLength={5}
                            precision={2}
                            value={tire_4}
                            onChange={(value) => {
                              setTire_4(value);
                              updateRequestedValue(value, 'wheel_4');
                            }}
                          />
                          <Typography.Text className="position-absolute inactive unit text-uppercase">{tirePressureUnit}</Typography.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={6}>
                        <img src={wheel4} alt="tire_pressure_img" />
                      </Col>
                    </Row>
                  </Col>
                </RenderItem>
              </Row>
            </div>
          </RenderItem>
        </Col>
      </Row>
    </div>
  );
}
export default ServiceTirePressure;
