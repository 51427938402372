/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button, Card, Steps, Tooltip, Typography,
} from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import { Action, ConfirmModal, DateTimeModal } from '../../Components';
import RejectModal from './RejectModal';
import Utility from '../../Global/Utility';
import rule from './return-form.validation';

export interface ReturnStepsProps {
  currentDot: number;
  scheduled_pickup_time: {
    scheduled_from: Date,
    scheduled_to: Date
  };
  payment_status: boolean
  isRefundDisabled: boolean
  handleRefund: () => any
  updateReturnStatus: (action: string, actionDetail?: any) => Promise<void>,
  rescheduleOrder: (actionDetail?: any) => Promise<void>
  handleRejectSubmit: (images: any, comment: string) => void
}
const { Step } = Steps;

function ReturnSteps(props: ReturnStepsProps) {
  const {
    currentDot, scheduled_pickup_time, payment_status, isRefundDisabled, handleRefund,
    updateReturnStatus, rescheduleOrder, handleRejectSubmit,
  } = props;

  const [dateModalVisible, setDateModalVisible] = useState<boolean>(false);
  const [isRejectModalVisible, setIsRejectModalVisible] = useState<boolean>(false);

  const isApprovalStep = currentDot === 0;
  const isScheduleStep = currentDot === 1;
  const isVerifyStep = currentDot === 2;
  const isRefundStep = currentDot >= 3;

  const handleDateModalCancel = () => setDateModalVisible(false);
  const handleDateModalOpen = () => setDateModalVisible(true);

  const handleProductReject = () => setIsRejectModalVisible(true);
  const handleProductRejectCancel = () => setIsRejectModalVisible(false);

  const handleReject = (images: any, comment: string) => {
    handleRejectSubmit(images, comment);
    setIsRejectModalVisible(false);
  };

  const acceptRequest = () => {
    ConfirmModal('Accept Request', 'Do you want to accept this request?', () => updateReturnStatus('approve'), 'Accept',
      'Black');
  };

  const rejectRequest = () => {
    ConfirmModal('Reject Request', 'Do you want to reject this request?', () => updateReturnStatus('reject'), 'Reject');
  };

  const handleCollect = () => {
    ConfirmModal('Collect', 'Do you want to collect this product?', () => updateReturnStatus('product_approved'), 'Collect',
      'Black');
  };

  const handleSchdulerSubmit = (scheduled_from: number, scheduled_to: number) => {
    if (isScheduleStep) updateReturnStatus('schedule', { scheduled_from, scheduled_to });
    else rescheduleOrder({ scheduled_from, scheduled_to });
    setDateModalVisible(false);
  };

  return (
    <Card className="mt-3">
      <Typography.Title level={3} className="mb-4">
        Return Order
      </Typography.Title>
      <Steps
        progressDot
        current={currentDot}
        direction="vertical"
        className="custom_steps"
      >
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('primary r-bold mb-0', {
                disable_clr: isApprovalStep,
              })}
            >
              Approval
            </Typography.Title>
          )}
          description={(
            <div className="d-sm-flex align-items-center justify-content-between">
              <Typography.Paragraph
                className={classNames('secondary fs-16 m-0', {
                  disable_clr: isApprovalStep,
                })}
              >
                Approve or reject the refund the order
              </Typography.Paragraph>
              {isApprovalStep && (
                <Action
                  onAccept={acceptRequest}
                  onReject={rejectRequest}
                />
              )}
            </div>
          )}
        />
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('primary r-bold mb-0', {
                disable_clr: currentDot <= 1,
              })}
            >
              Schedule Pickup
            </Typography.Title>
          )}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph
                  className={classNames('secondary fs-16 m-0', {
                    disable_clr: currentDot <= 1,
                  })}
                >
                  Choose date and time for the pickup
                </Typography.Paragraph>
                {isScheduleStep && !scheduled_pickup_time && (
                  <Button className="border-0 shadow-none border-radius text-white r-bold green_bg h-40" onClick={handleDateModalOpen}>
                    Schedule
                  </Button>
                )}
                {isVerifyStep && !!scheduled_pickup_time && (
                  <Button className="border-0 shadow-none border-radius text-white r-bold green_bg h-40" onClick={handleDateModalOpen}>
                    Reschedule
                  </Button>
                )}
              </div>
              {currentDot >= 1 && !!scheduled_pickup_time && (
                <div
                  className={classNames('d-flex align-items-center flex-wrap', {
                    disable_clr: currentDot <= 1,
                  })}
                >
                  <div className="off-white-bg secondary px-2 py-1 rounded-pill me-2 mt-2 mt-sm-0">
                    {Utility.getFormattedDate(scheduled_pickup_time?.scheduled_from)}
                  </div>
                  <div className="off-white-bg secondary px-2 py-1 rounded-pill mt-2 mt-sm-0">
                    {Utility.getFormattedTime(scheduled_pickup_time?.scheduled_from)}
                    -{Utility.getFormattedTime(scheduled_pickup_time?.scheduled_to)}
                  </div>
                </div>
              )}
            </div>
          )}
        />
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('primary r-bold mb-0', {
                disable_clr: currentDot <= 2,
              })}
            >
              Verify Product
            </Typography.Title>
          )}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph
                  className={classNames('secondary fs-16 m-0', {
                    disable_clr: currentDot <= 2,
                  })}
                >
                  Check the product condition for pickup
                </Typography.Paragraph>
                {isVerifyStep && (
                  <div>
                    <Button onClick={handleProductReject} className="border-0 shadow-none border-radius secondary r-bold bg-transparent h-40 me-2">
                      Reject
                    </Button>
                    <Button onClick={handleCollect} className="border-0 shadow-none border-radius text-white r-bold green_bg h-40">
                      Collect
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        />
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('r-bold mb-0', {
                disable_clr: currentDot <= 3,
              })}
            >
              Refund
            </Typography.Title>
          )}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph
                  className={classNames('fs-16 m-0', {
                    disable_clr: currentDot <= 3,
                  })}
                >
                  Refund the order
                </Typography.Paragraph>
                <div>
                  {isRefundStep && payment_status && (
                    <>
                      <Tooltip title={isRefundDisabled ? 'Refund already initiated' : 'Refund'}>
                        <Button disabled={isRefundDisabled} onClick={handleRefund} className={isRefundDisabled ? 'refund_disabled_btn' : 'border-0 shadow-none border-radius text-white r-bold green_bg h-40'}>
                          Refund
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </Steps>
      <DateTimeModal
        title={isScheduleStep ? 'Schedule Pickup' : 'Reschedule Pickup'}
        buttonText={isScheduleStep ? 'Schedule' : 'Reschedule'}
        redButton={!isScheduleStep}
        isModalVisible={dateModalVisible}
        dateRule={rule.dateRule}
        timeFromRule={rule.timeRule}
        timeToRule={rule.timeRule}
        disabledDate={(current: any) => moment().add(-1, 'days') >= current}
        handleCancel={handleDateModalCancel}
        handleSubmitBtn={handleSchdulerSubmit}
        scheduledTime={scheduled_pickup_time}
      />
      <RejectModal
        handleCancel={handleProductRejectCancel}
        isRejectModalVisible={isRejectModalVisible}
        handleRejectSubmit={handleReject}
      />
    </Card>
  );
}

export default ReturnSteps;
