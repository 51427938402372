/* eslint-disable import/extensions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Carousel,
  Col,
  Dropdown,
  InputNumber,
  Layout,
  Menu,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  Action,
  Icon, NoData, Pagination, RenderItem, Search, SortTh, Toast,
} from '../../Components';
import './Inventory.less';
import { IProductStoreProps } from '../../Store/productStore';
import Constant from '../../Global/Constant';
import { ProductCategory, Variants } from '../../Global/interface';
import { ICategoryStoreProps } from '../../Store/category';
import Routes from '../../Global/Routes';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface IInventory {
  productStore: IProductStoreProps
  categoryStore: ICategoryStoreProps
}

const { Option } = Select;

function Inventory(props: IInventory) {
  const { productStore, categoryStore } = props;
  let { variants } = productStore;
  const { total } = productStore;
  const { categories } = categoryStore;
  const [keyword, setKeyword] = useState('');
  const [seachFieldPresent, setseachFieldPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [order, setOrder] = useState<number>(-1);
  const [categoryFilter, setCategoryFilter] = useState<string>();
  const [varaintId, setVaraintId] = useState('');
  const [addInput, setAddInput] = useState(0);
  const [removeInput, setRemoveInput] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  const carouselData = categories?.categories?.map((category: ProductCategory) => (
    <div className="px-1">
      <Card className="off-white-bg shadow-none">
        <div>
          <Typography.Title level={1} className="primary fs-44 mb-2">
            {category?.category_in_use}
          </Typography.Title>
          <Typography.Title level={3} className="my-0 primary text-ellipsis">
            {category?.name}
          </Typography.Title>
          {/* <Typography.Paragraph className="fs-16 secondary mb-0">Average sales : 300/month
          </Typography.Paragraph> */}
        </div>
      </Card>
    </div>
  ));

  const getCategories = async () => {
    setIsLoading(true);
    try {
      await categoryStore.getCategories({});
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getProducts = async () => {
    setIsLoading(true);
    try {
      await productStore.getProducts({ limit: 10 });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getVariants = async (
    page: number, sort: number, sortBy: string,
    searchKeyword?: string, category?: string,
  ) => {
    setIsLoading(true);
    try {
      await productStore.getVariants({
        page,
        limit: Constant.variantsPerPage,
        keyword: searchKeyword,
        order: sort,
        orderBy: sortBy,
        category,
      });
      setseachFieldPresent(!!searchKeyword);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const updateVariant = async (variant: Variants, variantId: string) => {
    setIsLoading(true);
    try {
      await productStore.updateVariant(variant, variantId);
      variants = variants?.map((item: Variants) => {
        if (variantId === item?._id) {
          const temp = item;
          temp.quantity = variant?.quantity;
          return temp;
        }
        return item;
      });
      resetField();
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVariants(pageNumber, order, orderBy);
    getCategories();
    getProducts();
  }, []);

  const columns = [
    {
      title: 'No',
      dataIndex: 'number',
      width: '10%',
    },
    {
      title: <SortTh title="Category" dataIndex="category.name" orderBy={order} order={orderBy} />,
      dataIndex: 'category',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('category.name');
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Product" dataIndex="product.name" orderBy={order} order={orderBy} />,
      dataIndex: 'product',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('product.name');
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Variant" dataIndex="color.name" orderBy={order} order={orderBy} />,
      dataIndex: 'variant',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('color.name');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
    },
    {
      title: 'Add',
      dataIndex: 'add',
    },
    {
      title: 'Remove',
      dataIndex: 'remove',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '12%',
    },
  ];

  const sortTable = (dataIndex: string) => {
    let sort = -1;
    if (order === -1 && dataIndex === orderBy) sort = 1;
    else if (dataIndex !== orderBy) setOrderBy(dataIndex);
    setOrder(sort);
    getVariants(pageNumber, sort, dataIndex, keyword, categoryFilter);
  };

  const addChangeHandler = (variant: Variants, value: number) => {
    setVaraintId(variant?._id);
    setAddInput(value);
  };

  const removeChangeHandler = (variant: Variants, value: number) => {
    setVaraintId(variant?._id);
    setRemoveInput(value);
  };

  const onSave = (variant: Variants) => {
    const quantity = variant?.quantity + (addInput - removeInput);
    if (quantity > Constant.variantQuantityMax) return Toast.error('Stock limit exceeded maximum.');
    if (quantity >= 0) {
      const data: any = {
        quantity,
      };
      updateVariant(data, varaintId);
    } else {
      Toast.error('Invalid Stock');
    }
    return '';
  };

  const resetField = () => {
    setVaraintId('');
    setAddInput(0);
    setRemoveInput(0);
  };

  const data = variants?.map((variant: Variants, index: number) => (
    {
      number: ((pageNumber - 1) * Constant.variantsPerPage) + (index + 1),
      category: <div className="text-capitalize text-truncate">{variant?.category?.name}</div>,
      product: <div className="text-capitalize text-truncate">{variant?.product?.name}</div>,
      variant: <div className="text-capitalize text-truncate">{variant?.color?.name}/{variant?.size}</div>,
      stock: variant?.quantity,
      add: <InputNumber
        disabled={!!(varaintId && varaintId !== variant?._id)}
        value={varaintId === variant?._id ? addInput : undefined}
        placeholder="0"
        className="inp_style"
        onChange={(value: number) => addChangeHandler(variant, value)}
        maxLength={10}
        min={0}
        max={9999999999}
        precision={0}
      />,
      remove: <InputNumber
        disabled={!!(varaintId && varaintId !== variant?._id)}
        value={varaintId === variant?._id ? removeInput : undefined}
        placeholder="0"
        className="inp_style"
        onChange={(value: number) => removeChangeHandler(variant, value)}
        maxLength={10}
        min={0}
        max={9999999999}
        precision={0}
      />,
      action: !!(varaintId && varaintId === variant?._id) && (
      <Action
        onAccept={() => onSave(variant)}
        onReject={resetField}
        acceptToolTipText="Save"
        rejectToolTipText="Cancel"
      />
      ),
    }));

  const searchDebouncing = useCallback(
    debounce((
      page: number, nextValue: string, sort: number,
      sortBy: string, category?: string,
    ) => {
      setPageNumber(page);
      getVariants(page, sort, sortBy, nextValue, category);
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (event: any) => {
    const { value: nextValue } = event.target;
    setKeyword(nextValue);
    searchDebouncing(
      Constant.defaultPageNumber, nextValue, order, orderBy, categoryFilter,
    );
  };

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getVariants(page, order, orderBy, keyword, categoryFilter);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading /> : <NoData subTitle="products" />
    ),
  };

  const showSearch = seachFieldPresent || total !== 0;

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={' '} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0">
          Scan & Manage
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  const Options = categories?.categories?.map((category: ProductCategory) => (
    <Option value={category?._id}>{category?.name}</Option>
  ));

  const handleChange = (value: string) => {
    setPageNumber(Constant.defaultPageNumber);
    setCategoryFilter(value);
    getVariants(Constant.defaultPageNumber, order, orderBy, keyword, value);
  };

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Typography.Title className="m-0 header_main border-bottom-0 fs-xs-24 d-flex align-items-center">
                Inventory
              </Typography.Title>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <RenderItem isTrue={categories?.total > 0}>
              <Card bodyStyle={{
                paddingLeft: '4px', paddingRight: '4px', paddingTop: '8px', paddingBottom: '8px',
              }}
              >
                <Carousel
                  className="inventory_slick"
                  slidesToShow={categories?.total > 4 ? 4 : categories?.total}
                  slidesToScroll={1}
                  arrows
                  infinite
                  dots={false}
                  nextArrow={<div><Icon className="icon-14 primary" iconRef="#arrow_right" /></div>}
                  prevArrow={<div><Icon className="icon-14 primary" iconRef="#arrow_left" /></div>}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: categories?.total > 3 ? 3 : categories?.total,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: categories?.total > 2 ? 2 : categories?.total,
                        slidesToScroll: 1,
                        initialSlide: 1,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: categories?.total > 1 ? 1 : categories?.total,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                >{carouselData}
                </Carousel>
              </Card>
            </RenderItem>
            <div className="d-flex justify-content-between align-items-sm-center mt-4 mb-3">
              <div className="d-sm-flex align-items-center flex-fill">
                <h4 className="mb-0 table-header r-bold">Products</h4>
                <Select defaultValue="" className="slct_style ms-sm-3 mt-2 mt-sm-0 inv_cat_select" style={{ width: 150 }} onChange={handleChange}>
                  <Option value="">All Category</Option>
                  {Options}
                </Select>
              </div>
              <Search
                value={keyword}
                placeholder="Search"
                titleToolTip="Search Inventory"
                size="small"
                onChange={handleSearchTextChange}
                visibility={showSearch}
              />
            </div>
            <Table
              className="table_wrapper inventory_listing"
              rowClassName="cursor-pointer"
              columns={columns}
              dataSource={data}
              pagination={false}
              size="small"
              scroll={{ x: '800px' }}
              locale={locale}
            />
            <Pagination
              total={total}
              pageSize={Constant.variantsPerPage}
              currentPage={pageNumber}
              onChange={pageHandler}
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>Manage the product inventory</Paragraph>
            </Typography>
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-xl-5">Action</h6>
              <Tooltip title="Scan & Manage">
                <Link to={Routes.scan_manage} className="d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0 primary">
                  Scan & Manage
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Link>
              </Tooltip>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('productStore', 'categoryStore')(observer(Inventory));
