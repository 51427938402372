/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Form, Input, InputNumber, Radio,
} from 'antd';

function EditableCell(props: any) {
  const {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    maxLength,
    min,
    max,
    placeholder,
    rules,
    radioData,
    ...restProps
  } = props;

  let inputNode;

  if (inputType === 'number') {
    inputNode = (
      <InputNumber
        placeholder={`Enter ${placeholder}`}
        maxLength={maxLength}
        min={min}
        max={max}
        className="inp_style inp_num_style white_bg_resp"
      />
    );
  } else if (inputType === 'text') {
    inputNode = (
      <Input
        maxLength={maxLength}
        placeholder={`Enter ${placeholder}`}
        className="inp_style white_bg_resp"
      />
    );
  } else if (inputType === 'radio') {
    inputNode = (
      <Radio.Group className="cust_radio_btn" defaultValue={radioData.length ? radioData[0]?.value : ''}>
        {
          radioData.length && radioData?.map((item: any) => (
            <Radio
              className="radio_inp"
              value={item?.value}
            >{item?.displayText}
            </Radio>
          ))
        }
      </Radio.Group>
    );
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
