import React from 'react';

interface ISection{
    children: React.ReactNode
}

function Section(props: ISection) {
  const { children } = props;
  return (
    <>
      <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
      <div className="v_tab d-flex flex-column">
        {children}
      </div>
    </>
  );
}

export default Section;
