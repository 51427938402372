import React from 'react';
import { observer } from 'mobx-react';
import {
  Layout,
  Typography,
} from 'antd';

function Users() {
  return (
    <Layout.Content>
      <Typography.Title className="m-0" level={4}>
        Users
      </Typography.Title>
    </Layout.Content>
  );
}

export default observer(Users);
