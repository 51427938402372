/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button, Card, Typography } from 'antd';

interface NextVisitProps {
  showModal: () => void,
}

function NextVisit(props: NextVisitProps) {
  const { showModal } = props;
  return (
    <Card bordered={false} className="detail_card_pad">
      <Typography.Title level={3}>Next Service Visit</Typography.Title>
      <Button htmlType="button" className="main_btn me-3 fs-16 r-bold" onClick={showModal}>
        Schedule
      </Button>
      <div className="d-flex justify-content-between align-items-center text-secondary">
        <Typography.Paragraph className="mb-0">05/06/2022</Typography.Paragraph>
      </div>
    </Card>
  );
}

export default NextVisit;
