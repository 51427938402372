/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import constant from '../../Global/Constant';
import Utility from '../../Global/Utility';

const rule: any = {
  name: [{
    required: true,
    message: constant.nameRequired,
  }, {
    type: 'string',
    pattern: /^[A-Za-z][A-Za-z .-]*$/,
    message: constant.splOrNumberOnlyError,
  }],
  middle_name: [{
    type: 'string',
    pattern: /^[A-Za-z][A-Za-z .-]*$/,
    message: constant.splOrNumberOnlyError,
  }],
  last_name: [{
    required: true,
    message: constant.lastNameRequired,
  }, {
    type: 'string',
    pattern: /^[A-Za-z][A-Za-z .-]*$/,
    message: constant.splOrNumberOnlyError,
  }],
  gender: [{
    required: true,
    message: constant.genderRequired,
  }],
  date: [{
    required: true,
    message: constant.dateRequired,
  }],
  email: [{
    required: true,
    message: constant.emailRequired,
  }, {
    type: 'email',
    message: constant.invalidEmail,
  }],
  phone: [
    {
      validator: Utility?.phoneOptionalValidator, // phone update
    },
  ],
  country: [{
    required: true,
    message: constant.countryRequired,
  }],
  state: [{
    required: true,
    message: constant.stateRequired,
  }],
  city: [{
    required: true,
    message: constant.cityRequired,
  }],
  addressLine1: [{
    type: 'string',
    pattern: /([A-Za-z])/,
    message: constant.invalidAddressLine1,
  }],
  addressLine2: [{
    type: 'string',
    pattern: /([A-Za-z])/,
    message: constant.invalidAddressLine2,
  }],
  zip_code: [{
    pattern: /^[0-9]{4,}$/,
    message: constant.zipMinimumError,
  }],
  image_url: [{
    required: true,
    message: constant.profileRequired,
  }],
};

export default rule;
