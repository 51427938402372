/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import {
  Col, DatePicker, Form, Input, Row, Select, Spin,
} from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { inject, observer } from 'mobx-react';
import ColorPicker from '../../Components/ColorPicker';
import Icon from '../../Components/Icon';
import Rules from '../../Pages/CreateModel/CreateModelform.validation';
import modelService from '../../Services/modelService';
import { Toast } from '../../Components';

const { Option } = Select;
interface Props {
    isModalVisible: boolean;
    handleModelCancel: () => void;
    handleOnFinish: (modelId: string, category: string) => void;
}

const CreateModel = (props: any) => {
  const {
    isModalVisible, handleModelCancel, handleOnFinish, masterStore,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const { vehicleCategories } = masterStore;

  const getDisabledYear = (current:any) => current && moment(current).year() > moment().year();
  const handleColorChange = (color:any) => form.setFieldsValue({ color });

  const handleCancel = () => {
    form.resetFields();
    handleModelCancel();
  };

  const createModel = async (value : any) => {
    try {
      const {
        company, model, category, color, manufacture_year, color_name,
      } = value;
      const modelForm = {
        category,
        company,
        model,
        color,
        color_name,
        manufacture_year: moment(manufacture_year)?.year(),
      };
      setIsLoading(true);
      const response = await modelService.createModel(modelForm);
      setIsLoading(false);
      form.resetFields();
      handleOnFinish(response?.data?.id, category);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  return (
    <Modal
      title="Create new model"
      centered
      footer={null}
      className="doc_modal_wrapper cmn_modal_wrapper"
      visible={isModalVisible}
      maskClosable={!isLoading}
      onCancel={handleCancel}
      destroyOnClose
      width={700}
      closeIcon={(
        <Button disabled={isLoading} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" onFinish={createModel} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xxl={10}>
              <Form.Item label="vehicle category" className="mandatory" name="category" rules={Rules?.category}>
                <Select
                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                  placeholder="Choose"
                  className="slct_style"
                  dropdownClassName="slct_drp_dwn border-radius"
                >
                  {vehicleCategories?.map((vehicleCategory: any) => (
                    <Option key={vehicleCategory?._id} value={vehicleCategory?.category}>
                      {vehicleCategory?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="Manufacturer" className="mandatory" name="company" rules={Rules?.company}>
                <Input placeholder="Manufacturer" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="year" className="mandatory" name="manufacture_year" rules={Rules?.manufactureYear}>
                <DatePicker
                  className="inp_style date_picker"
                  picker="year"
                  format="YYYY"
                  disabledDate={getDisabledYear}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="model" className="mandatory" name="model" rules={Rules?.model}>
                <Input placeholder="Model" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <ColorPicker
                onSelect={handleColorChange}
                defaultColor=""
                placeholder="Pick the color"
                inputRules={Rules?.color_name}
                colorRules={Rules?.color}
                disabled={false}
                label="vehicle color"
                pickerName="color"

              />
            </Col>
          </Row>
          <div className="d-flex mt-4 justify-content-right">
            <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
              Create Model
            </Button>
            <Button type="text" className="cancel_btn fs-16 r-bold" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default inject('masterStore')(observer(CreateModel));
