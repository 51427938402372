/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Button, Card, Col, DatePicker, Divider, Form, Modal, Row, TimePicker, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import classnames from 'classnames';
import React from 'react';
import { CustomPrice, Icon } from '../../Components';
import Constant from '../../Global/Constant';
import {
  Address, Client, ContractPlan, Facility,
} from '../../Global/interface';
import Utility from '../../Global/Utility';
import docicon from '../../Assets/Images/ic_delete_mini.svg';

const { Title, Paragraph } = Typography;

interface IApprovalModal {
    duration: any
    contractPlan: ContractPlan
    requestedDate: any
    client: Client
    modalVisibility: boolean,
    onModelOk: (price: any) => void,
    onModelClose: () => void;
    isLoading: boolean,
    facility: Facility
    address: Address
    duration_type: string
}

function ApprovalModal(props: IApprovalModal) {
  const {
    duration, duration_type, contractPlan, client, requestedDate, modalVisibility, onModelOk,
    isLoading, onModelClose, facility, address,
  } = props;

  const [form] = useForm();
  const isVaultPlan = contractPlan?.plan_type === 'VAULT';
  const location = isVaultPlan ? `${facility?.name} - ${facility?.address} ` : address?.state;

  const termDetails = Utility.getContractPlanTerm(
    isVaultPlan, contractPlan?.terms, duration, duration_type, facility?._id,
  );

  const Ok = async () => {
    await form.validateFields();
    const { price, custom_amount } = form.getFieldsValue(true);
    onModelOk({ price, custom_amount });
  };
  return (
    <Modal
      title="Plan Accept"
      centered
      visible={modalVisibility}
      onOk={Ok}
      confirmLoading={isLoading}
      onCancel={onModelClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper approval_modal_wrapper"
      okText="Accept Request"
      okButtonProps={{ className: 'main_btn d-flex r-bold align-items-center', disabled: isLoading, htmlType: 'submit' }}
      destroyOnClose
      width={936}
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
)}
      footer={[
        <Row gutter={[16, 16]} className="justify-content-between align-items-center">
          <Col lg={18} sm={16} xs={24}>
            <div className="d-flex align-items-center position-relative">
              <div className="large_icn_bg p-0 rounded-circle justify-content-center d-flex align-items-center sec_info_img position-absolute">
                {client?.profile?.fileUrl
                  ? <img className="h_user_btn p-0 rounded-circle overflow-hidden" src={client?.profile?.fileUrl} alt="User" />
                  : <Icon className="icon-40" iconRef="#User" /> }
              </div>
              <div className="sec_info_pad d-flex align-items-center w-100 app_lg_icon_ht">
                <Typography className="w-100 text-start">
                  <Title
                    level={3}
                    className="text-truncate primary r-bold mb-0 text-capitalize"
                    title={Utility.getUserName(client)}
                  >
                    {Utility.getUserName(client)}
                  </Title>
                  <Paragraph className="secondary mb-0">{Utility.getFormattedDate(requestedDate)}</Paragraph>
                </Typography>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={8} xs={24} className="justify-content-sm-end d-flex">
            <Button key="submit" type="primary" className="main_btn dark_green_bg d-flex r-bold align-items-center" onClick={Ok}>
              Approve Plan
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div className="position-realtive">
        <div
          className={classnames('plan_info_img exo_fnt position-absolute position-sm-relative d-flex align-items-center justify-content-start ps-2', { 'fs-60': (Utility?.reverseDuration(duration, duration_type) as number >= 10) })}
        >
          {Utility?.reverseDuration(duration, duration_type)}<sub className="r-medium secondary fs-16 roboto position-absolute">{Constant?.durationTypeName.get(duration_type)}</sub>
        </div>
        <div className="plan_info_renew_pad plan_price_wrap_ht approval_plan_modal mt-3 mt-sm-0 d-flex flex-wrap align-items-center">
          <Row gutter={16} className="w-100">
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className={classnames('skew-transform text-uppercase w-max-content px-1 fs-12 mb-2', { plan_label_green: contractPlan?.plan_type === 'RESIDENTIAL', plan_label_yellow: contractPlan?.plan_type === 'VAULT' })}>
                {contractPlan?.plan_type === 'RESIDENTIAL'
            && <Typography.Text className="white r-italic">home service</Typography.Text>}
                {contractPlan?.plan_type === 'VAULT'
            && <Typography.Text className="white r-italic">parking service</Typography.Text>}
              </div>
              <Typography.Title level={3} className="mb-0 fs-xs-20 text-truncate w-md-50">{contractPlan?.plan} Plan</Typography.Title>
              <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2 d-flex align-items-center">
                <Icon className="icon-13 secondary me-1 min-w-13" iconRef="#location" />
                <span className="d-block text-truncate">{location}</span>
              </Typography.Paragraph>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Form form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} className="approval_price">
                <CustomPrice
                  rule={[{
                    validator: (field:any, value:any, callback:Function) => {
                      if (!value) callback(Constant.priceRequired);
                      else if (value < 1) callback(Constant.invalidAmount);
                      callback();
                    },
                  }]}
                  termAmount={termDetails?.price as number}
                  form={form}
                />
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      {/* <Divider className="border-top" />
      <div className="d-flex justify-content-between mb-2">
        <Typography.Text className="m-0 create_para me-2 me-sm-0 secondary r-medium">
          SCHEDULE VISITS
        </Typography.Text>
        <Button className="d-flex ml-auto
        h-max r-bold align-items-center back_link primary p-0" type="link">
          Add Visits (13 more)
          <div className="ms-2 d-flex justify-content-center
          align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col lg={6} md={8} sm={12} xs={24}>
          <Card className="shadow-none off-white-bg" bodyStyle={{ padding: 12 }}>
            <div className="d-flex justify-content-between mb-2">
              <Typography.Title level={5}
              className="primary r-bold mb-0 text-truncate">Service Visit 1</Typography.Title>
            </div>
            <DatePicker
              className="inp_style date_picker white_bg mb-2"
              dropdownClassName="date_picker_drp_dwn"
              showToday={false}
              format="DD-MM-YYYY"
              suffixIcon={(
                <Icon className="icon-16 primary" iconRef="#calendar" />
                )}
            />
            <TimePicker
              use12Hours
              className="inp_style w-100 h-40 shadow-none time_picker white_bg"
              popupClassName="custom_time_picker"
              suffixIcon={(
                <Icon className="icon-16 primary" iconRef="#clock" />
                )}
            />
          </Card>
        </Col>
        <Col lg={6} md={8} sm={12} xs={24}>
          <Card className="shadow-none off-white-bg" bodyStyle={{ padding: 12 }}>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <Typography.Title level={5}
              className="primary r-bold mb-0 text-truncate">Service Visit 1</Typography.Title>
              <Button className="doc_iconms-2 h-max
              d-flex justify-content-end align-items-center
              p-0 pe-2 border-0 shadow-none bg-transparent">
                <img src={docicon} alt="doc_close" />
              </Button>
            </div>
            <DatePicker
              className="inp_style date_picker white_bg mb-2"
              dropdownClassName="date_picker_drp_dwn"
              showToday={false}
              format="DD-MM-YYYY"
              suffixIcon={(
                <Icon className="icon-16 primary" iconRef="#calendar" />
                )}
            />
            <TimePicker
              use12Hours
              className="inp_style w-100 h-40 shadow-none time_picker white_bg"
              popupClassName="custom_time_picker"
              suffixIcon={(
                <Icon className="icon-16 primary" iconRef="#clock" />
                )}
            />
          </Card>
        </Col>
      </Row> */}
      <Divider className="border-top mb-0" />
    </Modal>
  );
}

export default ApprovalModal;
