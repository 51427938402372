/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import axios from 'axios';
import { observable, makeObservable, runInAction } from 'mobx';
import Constant from '../Global/Constant';
import { clientCount, planCount, facilityDetails } from '../Global/interface';
import clientService from '../Services/clientService';
import requestService from '../Services/requestService';
import contractService from '../Services/contractService';
import facilityService from '../Services/facilityService';

class DashboardStore {
  planReqCount: number = 0;

  planReqUrgentCount: number = 0;

  waitingReqCount: number = 0;

  employeeReqCount: number = 0;

  employeeReqUrgentCount: number = 0;

  serviceReqCount: number = 0;

  serviceReqUrgentCount: number = 0;

  clientCount: clientCount = { count: [], thisMonth: 0 };

  facility: facilityDetails | null = null;

  expiredPlans: planCount | null = null;

  expiringPlans: planCount | null= null;

  constructor() {
    makeObservable(this, {
      planReqCount: observable,
      waitingReqCount: observable,
      serviceReqCount: observable,
      employeeReqCount: observable,
      clientCount: observable,
      facility: observable,
      expiredPlans: observable,
      expiringPlans: observable,
      planReqUrgentCount: observable,
      employeeReqUrgentCount: observable,
      serviceReqUrgentCount: observable,
    });
  }

  getFacilityDetails = async (queryParams : any) => new Promise((resolve, reject) => {
    facilityService.getFacilities(queryParams)
      .then((response: any) => {
        if (response && response.data) {
          runInAction(() => {
            this.facility = response?.data;
          });
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getDashboardData = async (queryParams: any) => {
    try {
      const promises = [
        requestService.getPlanReqCount(queryParams?.reqQuery),
        clientService.getClientCount(),
        requestService.getEmployeeReqCount(queryParams?.reqQuery),
        contractService.getPlansCount(queryParams?.expiredQuery),
        contractService.getPlansCount(queryParams?.expiringQuery),
        requestService.getPlanReqCount(queryParams?.waitingQuery),
        requestService.getServiceReqCount(queryParams?.reqQuery),
      ];
      const responses = await axios.all(promises);
      if (responses) {
        runInAction(() => {
          this.planReqCount = responses[0]?.data?.count;
          this.planReqUrgentCount = responses[0]?.data?.urgentRequestCount;
          this.clientCount = {
            count: responses[1]?.data?.count,
            thisMonth: responses[1]?.data?.thisMonthCount,
          };
          this.employeeReqCount = responses[2]?.data?.count;
          this.employeeReqUrgentCount = responses[2]?.data?.urgentRequestCount;
          this.expiredPlans = responses[3]?.data;
          this.expiringPlans = responses[4]?.data;
          this.waitingReqCount = responses[5]?.data?.count;
          this.serviceReqCount = responses[6]?.data?.count;
          this.serviceReqUrgentCount = responses[6]?.data?.urgentRequestCount;
        });
      }
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getEmployeeDashboardData = async (queryParams: any) => {
    try {
      const promises = [
        contractService.getPlansCount(queryParams?.expiredQuery),
        contractService.getPlansCount(queryParams?.expiringQuery),
        requestService.getServiceReqCount(queryParams?.reqQuery),
      ];
      const responses = await axios.all(promises);
      if (responses) {
        this.expiredPlans = responses[0]?.data;
        this.expiringPlans = responses[1]?.data;
        this.serviceReqCount = responses[2]?.data?.count;
        this.serviceReqUrgentCount = responses[2]?.data?.urgentRequestCount;
      }
      return null;
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new DashboardStore();
