/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  useParams, Link, useHistory, useLocation,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Radio,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import debounce from 'lodash.debounce';
import { Element } from 'react-scroll';
import classnames from 'classnames';
import queryString from 'query-string';
import moment from 'moment';

import { useForm } from 'antd/lib/form/Form';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';

import {
  UserCard, ContactCard, Address, Icon, Pagination, Search, NoData,
  Toast, SortTh, Status, AddPlan, StatusLabel, RenderItem, Section, ScrollLink, ButtonIcon,
  Info,
  ConfirmAdminPin,
} from '../../Components';
import ConfirmModal from '../../Components/ConfirmModal';
import clientService from '../../Services/clientService';
import requestService from '../../Services/requestService';
import contractService from '../../Services/contractService';
import ActivateAccount from '../../Components/ActivateAccount';
import { ContractData, Facility, Vehicle } from '../../Global/interface';

import './Clients.less';
import planStore from '../../Store/planStore';
import { AuthStoreProps } from '../../Store/authStore';
import { IClientStore } from '../../Store/clientStore';
import { MasterStoreProps } from '../../Store/masterStore';

const { contractTypes } = Constant;
const { ACTIVE, EXPIRED, PAYMENT_PENDING } = contractTypes;
const contractStatus = new Map([
  ['REQUESTED', 'Requested'],
  ['APPROVED', 'Payment Pending'],
]);

interface IClientDetails{
  authStore: AuthStoreProps,
  clientStore: IClientStore,
  masterStore: MasterStoreProps
}

function ClientDetails(props: IClientDetails) {
  const { authStore, clientStore, masterStore } = props;

  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const isFromClientHistory = queryParams?.deleted;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowConfirmPin, setIsShowConfirmPin] = useState<boolean>(false);
  const [vehiclePageNo, setVehiclePageNo] = useState<number>(Constant.defaultPageNumber);
  const [vehicleKeyword, setVehicleKeyword] = useState<string>('');
  const [vehicleOrder, setVehicleOrder] = useState('model.company');
  const [vehicleOrderBy, setVehicleOrderBy] = useState(-1);
  const [transactionPageNo, setTransactionPageNo] = useState<number>(Constant.defaultPageNumber);
  const [transactionOrder, setTransactionOrder] = useState('');
  const [transactionOrderBy, setTransactionOrderBy] = useState(1);
  const [contractPageNo, setContractPageNo] = useState<number>(Constant.defaultPageNumber);
  const [contractKeyword, setContractKeyword] = useState<string>('');
  const [contractOrder, setContractOrder] = useState('contract_code');
  const [contractOrderBy, setContractOrderBy] = useState(-1);
  const [contractPlanType, setContractPlanType] = useState(ACTIVE);
  const [sharedVehicle, setSharedVehicle] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPlanModel, setShowPlanModel] = useState(false);
  const [isPlanPaid, setIsPlanPaid] = useState(false);
  const [isPlanClosed, setIsPlanClosed] = useState(false);
  const [isVehicleInside, setIsVehicleInside] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [selectedTerm, setSelectedTerm] = useState<any>(null);
  const [termAmount, setTermAmount] = useState<any>(null);
  const [facilities, setFacilities] = useState<Array<Facility>>([]);
  const [terms, setTerms] = useState<any>(null);
  const [form] = useForm();

  const { id } = useParams<any>();
  const history = useHistory();
  const {
    vehicles, activeContracts, contracts, client, memberCountDetails, clientTransactions,
  } = clientStore;

  const memberCount = memberCountDetails?.sharedMemberTotal?.length
  && memberCountDetails?.sharedMemberTotal[0]?.count;

  const accessCount = memberCountDetails?.sharedAccessTotal?.length
  && memberCountDetails?.sharedAccessTotal[0]?.count;

  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);

  const vehicleColumn = [
    {
      title: <SortTh className="srt_arw_position" title="Make" dataIndex="model.company" order="model.company" orderBy={vehicleOrderBy} />,
      dataIndex: 'company',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('model.company');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      ellipsis: true,
    },
    {
      title: 'Sub Model',
      dataIndex: 'sub_model',
      ellipsis: true,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      ellipsis: true,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      ellipsis: true,
    },
    {
      title: 'Plan',
      dataIndex: 'contract',
      ellipsis: true,
    },
    {
      title: 'Number',
      dataIndex: 'number',
      ellipsis: true,
    },
  ];

  if (sharedVehicle) {
    vehicleColumn.splice(2, 1); // remove year from column
    vehicleColumn.push({
      title: 'Client',
      dataIndex: 'client',
      ellipsis: true,
    }, {
      title: 'Access',
      dataIndex: 'access',
      ellipsis: true,
    });
  }
  const sortTable = (dataIndex: string) => {
    const nextSort = vehicleOrderBy === -1 ? 1 : -1;
    setVehicleOrderBy(nextSort);
    setVehicleOrder(dataIndex);
    getClientVehicle(sharedVehicle, id, vehiclePageNo, vehicleKeyword, nextSort, dataIndex);
  };
  const TransactionColumn = [
    {
      title: <SortTh className="srt_arw_position" title="Transactions" dataIndex="payment_type" order="payment_type" orderBy={transactionOrderBy} />,
      dataIndex: 'payment_type',
      onHeaderCell: () => ({
        onClick: () => {
          sortTransactionTable('payment_type');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      ellipsis: true,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      ellipsis: true,
    },
  ];

  const sortTransactionTable = (dataIndex: string) => {
    const nextSort = transactionOrderBy === -1 ? 1 : -1;
    setTransactionOrderBy(nextSort);
    setTransactionOrder(dataIndex);
    getClientTransactions(id, transactionPageNo, nextSort, dataIndex);
  };

  const columndata = [
    {
      title: <SortTh title="Plan ID" dataIndex="contract_code" order="contract_code" orderBy={contractOrderBy} />,
      dataIndex: 'contract_code',
      onHeaderCell: () => ({
        onClick: () => {
          sortContractTable('contract_code');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      dataIndex: 'planType',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      dataIndex: 'planTerm',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: 'Amount',
      dataIndex: 'price',
      ellipsis: true,
    },
    {
      title: contractPlanType === PAYMENT_PENDING ? 'Status' : 'Due Date',
      dataIndex: contractPlanType === PAYMENT_PENDING ? 'status' : 'due_date',
      ellipsis: true,
    },
  ];

  const sortContractTable = (dataIndex: string) => {
    const nextSort = contractOrderBy === -1 ? 1 : -1;
    setContractOrderBy(nextSort);
    setContractOrder(dataIndex);
    getClientContracts(id, contractPlanType, contractPageNo, contractKeyword, nextSort, dataIndex);
  };

  const getClientById = async (cid: string) => {
    setIsLoading(true);
    try {
      await clientStore.getClientById(cid);
    } catch (error: any) {
      Toast.error(error);
      history.goBack();
    }
    setIsLoading(false);
  };
  const getClientVehicle = async (
    shared_vehicle: boolean, clientId: string,
    page: number, keyword?: string, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientVehicle({
      clientId,
      page,
      limit: Constant.clientDetailsPage,
      keyword,
      order,
      orderBy,
      shared_vehicle,
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const getClientTransactions = async (
    clientId: string, page: number, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientTransactions({
      client: clientId, page, limit: Constant.clientDetailsPage, order, orderBy, status: 'PAYMENT_SUCCESS',
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const getClientContracts = async (
    clientId: string, contract_status: string, page: number,
    keyword?: string, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientContracts({
      client: clientId,
      contract_status,
      page,
      limit: Constant.clientDetailsPage,
      keyword,
      order,
      orderBy,
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const empDeleteRequest = async () => {
    await requestService.createEmployeeRequest({
      target_id: id, request: Constant.employeeRequest.CLIENT_DELETE,
    })
      .then(() => history.push(`${Routes.clients}`))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const deleteClient = async () => {
    await clientService.deleteClient(id)
      .then(() => history.push(`${Routes.clients}`))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const goToClientEdit = () => history.push(`${Routes.create_client}/${id}`);

  const viewDeleteConfirmModal = () => {
    if (isSuperAdmin) {
      ConfirmModal('Delete Account', 'Do you want to delete this client?', deleteClient);
    } else {
      ConfirmModal('Request for Account Deletion', 'Do you want to delete this client?', empDeleteRequest, 'Request', '#ff4141', 'del_modal_wrap modal_heading_pad');
    }
  };

  const showConfirmPin = () => setIsShowConfirmPin(true);
  const closeConfirmPin = () => setIsShowConfirmPin(false);

  const disableDeleteBtn = !!((!isSuperAdmin
    && clientStore?.client?.delete_requested));

  const iscolor = disableDeleteBtn ? '#7777' : '';

  const isClientDeleted = client?.deleted;
  const isAccountDisabled = client?.disabled;

  const searchVehicle = useCallback(
    debounce((nextValue: any, orderBy: number, order: string, shared_Vehicle: boolean) => {
      setVehiclePageNo(Constant.defaultPageNumber);
      getClientVehicle(
        shared_Vehicle, id, Constant.defaultPageNumber, nextValue, orderBy, order,
      );
    }, Constant.debaounceTime),
    [],
  );

  const onVehicleSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setVehicleKeyword(nextValue);
    searchVehicle(nextValue, vehicleOrderBy, vehicleOrder, sharedVehicle);
  };

  const downloadInvoice = async (orderId: string) => {
    try {
      setIsLoading(true);
      const response = await contractService?.downloadInvoice(orderId);
      const url = await Utility.createObjectUrlFromUrl(response?.data?.url);
      Utility.downloadFile(url, `Invoice-${orderId}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const vehicleTableData = vehicles?.data?.map((vehicle: Vehicle) => ({
    company: <span className="text-capitalize text-truncate d-block">{vehicle?.model?.company}</span>,
    model: <span className="text-capitalize text-truncate d-block">{vehicle?.model?.model}</span>,
    sub_model: <span className="text-capitalize text-truncate d-block">{vehicle?.model?.sub_model || '-'}</span>,
    year: vehicle?.model?.manufacture_year || '-',
    color: vehicle?.color ? <span className="veh_color d-inline-block me-1 rounded-circle align-middle sm" style={{ backgroundColor: vehicle?.color || '' }} /> : '-',
    number: vehicle?.vehicle_no || '-',
    id: vehicle?._id,
    client: `${vehicle?.clientDetail?.first_name} ${vehicle?.clientDetail?.middle_name || ''} ${vehicle?.clientDetail?.last_name}`,
    access: vehicle?.role,
    clientId: id,
    contract:
  <div className="d-flex align-items-center">
    {vehicle?.contract?.contract_code
      ? (
        <>
          <span className="text-truncate" title={`${vehicle?.contract?.contract_code}`}>
            {`${vehicle?.contract?.contract_code}`}
          </span>
          <Tooltip
            title={
              `${vehicle?.contractPlan?.plan} Plan`
            }
          >
            <div className="lh_0">
              <Icon className="icon-16 ms-2" iconRef="#info" />
            </div>
          </Tooltip>
        </>
      ) : '-'}
  </div>,
  }));

  const getPaymentText = (transaction: any) => {
    let transactionText;
    if (transaction?.payment_type === Constant.activities.SERVICE_REQUEST) {
      transactionText = Constant?.SERVICES?.get(transaction?.serviceRequest?.service);
      return `${transactionText} - ${transaction?.vehicle_model?.company} ${transaction?.vehicle_model?.model}`;
    }
    if (transaction?.payment_type === Constant.activities.SERVICE_VISIT) {
      return `Service Visit - ${transaction?.contractPlan?.plan} Plan`;
    }
    transactionText = Constant.paymentType?.get(transaction?.payment_type);
    return `${transactionText} - ${transaction?.contractPlan?.plan} Plan`;
  };

  const transactionTableData = clientTransactions?.data?.map((transaction: any) => ({
    payment_type: <span className="text-capitalize text-truncate">{getPaymentText(transaction)}</span>,
    date: Utility.getFormattedDate(transaction?.payment_done_date),
    time: Utility.getFormattedTime(transaction?.payment_done_date),
    amount: <span className="text-capitalize">{transaction?.total_amount} KD</span>,
    invoice: (
      <Tooltip title="download invoice">
        <Button onClick={() => downloadInvoice(transaction?.order_id)} className="rounded-circle ms-2 p-2 d-flex justify-content-center align-items-center text-center">
          <Icon className="icon-14" iconRef="#download" />
        </Button>
      </Tooltip>),
  }));

  const intializeState = () => {
    setContractPageNo(Constant.defaultPageNumber);
    setContractKeyword('');
    setContractOrderBy(-1);
    setContractOrder('contract_code');
    setContractPlanType(ACTIVE);
    setVehicleKeyword('');
    setVehicleOrder('model.company');
    setVehicleOrderBy(-1);
    setVehiclePageNo(Constant.defaultPageNumber);
    setTransactionOrder('');
    setTransactionOrderBy(-1);
    setTransactionPageNo(Constant.defaultPageNumber);
    setSharedVehicle(false);
  };

  useEffect(() => {
    intializeState();
    getClientById(id);
    getClientVehicle(sharedVehicle, id, Constant.defaultPageNumber, '', -1, 'model.company');
    getClientTransactions(id, Constant.defaultPageNumber, -1, '');
    getClientContracts(
      id, ACTIVE, Constant.defaultPageNumber, '', -1, 'contract_code',
    );
  }, [id]);

  const contractSearch = useCallback(
    debounce((nextValue: any, contractPlan: string, orderBy: number, order: string) => {
      setContractPageNo(Constant.defaultPageNumber);
      getClientContracts(
        id, contractPlan, Constant.defaultPageNumber, nextValue, orderBy, order,
      );
    }, Constant.debaounceTime),
    [],
  );

  const onContractSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setContractKeyword(nextValue);
    contractSearch(nextValue, contractPlanType, contractOrderBy, contractOrder);
  };

  const contractTabHandler = (event: any) => {
    setContractPageNo(Constant.defaultPageNumber);
    setContractKeyword('');
    setContractOrderBy(-1);
    setContractPlanType(event?.target?.value);
    getClientContracts(
      id, event?.target?.value, Constant.defaultPageNumber, '', -1, 'contract_code',
    );
  };

  const contractTableData = contracts?.data?.map((contract: ContractData) => ({
    id: contract?._id,
    contract_code: contract?.contract_code,
    planType: <div className="text-truncate">{contract?.contract[0]?.plan}</div>,
    planTerm: Utility?.getFullDuration(contract?.duration, contract?.duration_type),
    due_date: Utility.getFormattedDate(contract?.due_date),
    price: `${contract?.price} KD`,
    vehicle:
  <div className="d-flex align-items-center w-100">
    {contract?.model?.company
      ? (
        <>
          <span className="text-truncate w-100" title={`${contract?.model?.company} ${contract?.model?.model}`}>
            {`${contract?.model?.company} ${contract?.model?.model}`}
          </span>
          <Tooltip
            title={
              contract?.active_vehicle[0]?.vehicle_no || contract?.active_vehicle[0]?.vehicle_code
            }
          >
            <div className="lh_0">
              <Icon className="icon-16 ms-2" iconRef="#info" />
            </div>
          </Tooltip>
        </>
      ) : '-'}
  </div>,
    status: <Status
      status={contract?.contract_status}
      statusClassName={Constant.contractStatusColor}
      statusText={contractStatus}
    />,

  }));

  const gotoSharedAccess = () => history.push(`${Routes.shared_access}/${id}`);
  const gotoSharedMember = () => history.push(`${Routes.shared_member}/${id}`);
  const gotoVehicleDetailPage = (record:any) => { history.push(`${Routes.vehicleDetails}/${record?.id}${sharedVehicle ? `?shared=${record?.clientId}` : ''}`); };
  const gotoPlanDetailPage = (record:any) => history.push(`${Routes.contract_detail}/${record?.id}?from=CLIENT&id=${id}`);

  const contractPageHandler = (page: number) => {
    setContractPageNo(page);
    getClientContracts(
      id, contractPlanType, page, contractKeyword, contractOrderBy, contractOrder,
    );
  };

  const vehiclePageHandler = (page: number) => {
    setVehiclePageNo(page);
    getClientVehicle(
      sharedVehicle, id, page, vehicleKeyword, vehicleOrderBy, vehicleOrder,
    );
  };

  const transactionPageHandler = (page: number) => {
    setTransactionPageNo(page);
    getClientTransactions(
      id, page, transactionOrderBy, transactionOrder,
    );
  };

  const gotoVehicleCreate = () => history.push(`${Routes.client}/${id}${Routes.create_vehicle}`);

  const activeBtnHandler = () => {
    setIsModalVisible(true);
  };

  const setIntialData = async () => {
    try {
      await masterStore.getContractPlans();
      await masterStore.getFacilities();
    } catch (error: any) {
      Toast.error(error);
    }
  };

  useEffect(() => {
    setIntialData();
  }, []);

  const createContract = async (values: any) => {
    try {
      setIsLoading(true);
      const {
        facility, start_date, plan_duration, contract_plan, plan_paid, parked_date,
        vehicle, price, duration_type, custom_amount, closed_date,
      } = values;

      if (parked_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (parked_date > dueDate || parked_date < start_date) {
          setIsLoading(false);
          return Toast.error(Constant.parkedDateError);
        }
      }

      if (closed_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (closed_date < dueDate) {
          setIsLoading(false);
          return Toast.error(Constant.closedDateError);
        }
      }

      const startDate = new Date(start_date);

      const contractForm: any = {
        plan_paid,
        plan_duration,
        duration_type,
        contract_plan,
        facility,
        vehicle,
        price,
        custom_amount,
        start_date: startDate.getTime(),
      };

      if (closed_date) {
        contractForm.closed_date = new Date(closed_date).getTime();
      }
      if (parked_date) {
        contractForm.parked_date = new Date(parked_date).getTime();
      }

      if (Utility.checkIfToday(startDate)) {
        contractForm.sendPlanInvoice = true;
      }
      await clientService.createContract(id, contractForm);
      closePlanModel();
      getClientById(id);
      getClientContracts(
        id, contractPlanType, contractPageNo, contractKeyword, contractOrderBy, contractOrder,
      );
      getClientVehicle(sharedVehicle, id, vehiclePageNo, vehicleKeyword,
        vehicleOrderBy, vehicleOrder);
      if (plan_paid) getClientTransactions(id, Constant.defaultPageNumber, -1, 'payment_done_date');
      setIsLoading(false);
      return null;
    } catch (error: any) {
      setIsLoading(false);
      return Toast.error(error);
    }
  };

  const addPlan = () => setShowPlanModel(true);
  const paidCheckBoxHandler = () => setIsPlanPaid(!isPlanPaid);
  const closedCheckBoxHandler = () => {
    setIsPlanClosed(!isPlanClosed);
    setIsPlanPaid(!isPlanClosed);
  };
  const vehicleCheckboxHandler = () => setIsVehicleInside(!isVehicleInside);
  const handleTermChange = (value: any) => {
    setSelectedTerm(value);
    const termDetails = terms?.filter((term: any) => term?._id === value);
    setTermAmount(termDetails[0]?.price);
  };

  const vehicleRadiobtnHandler = (event: any) => {
    setVehiclePageNo(Constant.defaultPageNumber);
    setSharedVehicle(event?.target?.value);
    getClientVehicle(
      event?.target?.value, id,
      Constant.defaultPageNumber,
      vehicleKeyword,
      vehicleOrderBy,
      vehicleOrder,
    );
  };

  const sendInvitation = async (values: any) => {
    setIsLoading(true);
    await clientService.activateAccount(id, values)
      .then(() => {
        getClientById(id);
        setIsModalVisible(false);
      })
      .catch((error: any) => Toast.error(error));
    setIsLoading(false);
  };

  const onCancel = () => setIsModalVisible(false);

  const closePlanModel = () => {
    setShowPlanModel(false);
    setIsPlanClosed(false);
    setIsPlanPaid(false);
    setIsVehicleInside(false);
    setSelectedTerm(null);
    setSelectedPlan('');
    form.resetFields();
    setTerms(null);
  };

  const handlePlanTypeChange = (planId: string) => {
    form.resetFields(['facility', 'plan_duration']);
    setFacilities([]);
    setTerms(null);
    setSelectedTerm(null);
    setSelectedPlan(planId);
  };

  const fetchPlanDetails = async (planId: string) => {
    try {
      setIsLoading(true);
      await planStore?.getPlanById(planId);
      setIsLoading(false);
      if (planStore?.plan?.plan_type === 'VAULT') {
        setFacilities(planStore?.planTerms?.map((item: any) => item?.facility));
      } else {
        setTerms(planStore?.planTerms[0]?.termDetails);
      }
    } catch (error: any) {
      setIsLoading(false);
      Toast?.error(error);
    }
  };

  const handleFacilityChange = (facilityId: string) => {
    form.resetFields(['plan_duration']);
    const selectedFacility = planStore?.planTerms?.find(
      (item: any) => item?.facility?._id === facilityId,
    );
    setTerms(selectedFacility?.termDetails);
    setSelectedTerm(null);
  };

  useEffect(() => {
    if (selectedPlan) fetchPlanDetails(selectedPlan);
  }, [selectedPlan]);

  const getUserCurrentStatus = () => {
    if (isClientDeleted) return 'DELETED';
    if (isAccountDisabled) return 'DISABLED';
    if (!client?.is_invitation_send && client?.is_invited) return 'NOT_INVITED';
    if (!client?.active && client?.is_invitation_send) return 'PENDING_SIGNUP';
    return '';
  };

  const clientStatus = getUserCurrentStatus();

  const enableDisableClient = async (action: any) => {
    try {
      setIsLoading(true);
      await clientStore.enableDisableClient(id, action);
      getClientById(id);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const enableBtnHandler = () => {
    if (isAccountDisabled) {
      ConfirmModal('Enable Account',
        'Do you want to enable this account?', () => enableDisableClient({ action: 'ENABLE' }), 'Enable now', '#26D65B');
    } else {
      ConfirmModal('Disable Account',
        'Do you want to disable this account?', () => enableDisableClient({ action: 'DISABLE' }), 'Disable now', '#F1AA1D');
    }
  };

  const gotoClientListing = () => history.push(Routes.clients);
  const hardDeleteClient = async (admin_pin: number|null) => {
    try {
      setIsLoading(true);
      await clientStore?.deleteAccountPermanently(id, { super_admin_pin: admin_pin });
      closeConfirmPin();
      gotoClientListing();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const deleteBtnText = isSuperAdmin ? 'Delete Account' : 'Request for Delete';
  const deleteSpan = <span className="text-truncate w-65">Delete Account Permanently</span>;
  const menu = (
    <Menu className="box_shadow border-radius">
      {/* <Menu.Item key="6">
        <RenderItem isTrue={isSuperAdmin}>
          <ButtonIcon
            onClick={showConfirmPin}
            text={deleteSpan}
            tooltipText="Delete Account Permanently"
            showIcon
            iscolor={iscolor}
            className="d-flex ml-auto r-bold
            align-items-center delete-btn p-0 m-0 h-auto resp_req_btn"
          />
        </RenderItem>
      </Menu.Item> */}
      <RenderItem isTrue={!isClientDeleted}>
        <Menu.Item key="0">
          <Tooltip title={disableDeleteBtn ? 'Request for deleting the client is already made' : ''}>
            <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto resp_req_btn" style={{ color: iscolor }} disabled={disableDeleteBtn} onClick={viewDeleteConfirmModal}>
              { isSuperAdmin ? 'Delete Account'
                : 'Request for Delete' }
              {!disableDeleteBtn && (
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
              ) }
            </Button>
          </Tooltip>
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={!isClientDeleted}>
        <Menu.Item key="2">
          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoVehicleCreate}>
            Add Vehicle
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={!isClientDeleted && isSuperAdmin}>
        <Menu.Item key="4">
          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={addPlan}>
            Add Plan
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={!isClientDeleted && isSuperAdmin && !client?.active}>
        <Menu.Item key="3">
          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={activeBtnHandler}>
            { client?.email ? 'Reinvite' : 'Activate Account'}
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Menu.Item>
      </RenderItem>
      <RenderItem isTrue={isSuperAdmin && !isClientDeleted}>
        <Menu.Item key="5">
          <Button
            className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto"
            onClick={enableBtnHandler}
          >
            {isAccountDisabled ? 'Enable' : 'Disable'} Account
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Menu.Item>
      </RenderItem>
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper clnt_detail">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <RenderItem isTrue={!isFromClientHistory}>
                    <Link to={Routes.clients}>Clients</Link>
                  </RenderItem>
                  <RenderItem isTrue={isFromClientHistory}>
                    <Link to={Routes.client_history}>Clients History</Link>
                  </RenderItem>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{client?.client_code}</Breadcrumb.Item>
              </Breadcrumb>
              <RenderItem isTrue={!isClientDeleted}>
                {/* || isSuperAdmin [patch] */}
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </RenderItem>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                    {client?.client_code}
                  </Typography.Title>
                  <StatusLabel
                    className={`${Constant.userStatusClassName.get(clientStatus)} ms-2 ms-sm-3`}
                    text={Constant.userStatusText.get(clientStatus) as string}
                  />
                </div>
                <RenderItem isTrue={!isClientDeleted && isSuperAdmin}>
                  <ButtonIcon
                    onClick={goToClientEdit}
                    text="Edit Information"
                    showIcon={false}
                    className="main_btn fs-16 r-bold mt-2 mt-sm-0"
                  />
                </RenderItem>
              </div>
              <Row gutter={16}>
                <Col lg={8} md={8} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={client?.profile?.fileUrl}
                      name={(client?.first_name && client?.last_name) ? `${client?.first_name} ${client?.middle_name || ''} ${client?.last_name}` : ''}
                      cardName="Client"
                      id={client?.client_code}
                      contractCount={activeContracts}
                      sharedAccessCount={accessCount}
                      membersCount={memberCount}
                      handleAccessClick={gotoSharedAccess}
                      handleMemberClick={gotoSharedMember}
                    />
                  </Card>
                  {/* phone update */}
                  {(client?.phone || client?.email)
                  && (
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard contactDetails={client} />
                  </Card>
                  )}
                  <Card bordered={false} className="detail_card_pad">
                    <Address addressInfo={client?.address} />
                  </Card>

                </Col>
                <Col lg={16} md={16} xs={24} className="mt-3 mt-md-0">
                  <Element name="Client Plans">
                    <Card className="table_card_title">
                      <div className="d-xxl-flex d-xl-block d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0 justify-content-between">
                        <div className="d-sm-flex align-items-center srch_p_resp">
                          <h4 className="mb-0 table-header r-bold text-truncate" title="Client Plans">Client Plans</h4>
                          <Search
                            value={contractKeyword}
                            placeholder="Search"
                            titleToolTip="Search Client Plans"
                            size="small"
                            onChange={onContractSearch}
                          />
                        </div>
                        <Radio.Group
                          onChange={contractTabHandler}
                          value={contractPlanType}
                          buttonStyle="solid"
                          className="radio_tab client_plan_tab d-flex gray_bg w-100 border-radius ms-xxl-4 ms-xl-0 ms-sm-4 mt-xxl-0 mt-xl-3 mt-sm-0 mt-3"
                        >
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={ACTIVE}>ACTIVE</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={PAYMENT_PENDING}>PENDING</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={EXPIRED}>EXPIRED</Radio.Button>
                        </Radio.Group>
                      </div>
                      <Table
                        columns={columndata}
                        dataSource={contractTableData}
                        pagination={false}
                        size="small"
                        rowClassName="cursor-pointer"
                        className={classnames('table_wrapper ins_table_wrapper', { client_detail_listing: contractPlanType !== PAYMENT_PENDING }, { client_detail_st_listing: contractPlanType === PAYMENT_PENDING })}
                        locale={{
                          emptyText: (
                            <NoData subTitle="plans" size={105} />),
                        }}
                        onRow={(record) => ({ onClick: () => gotoPlanDetailPage(record) })}
                        scroll={{ x: '730px' }}
                      />
                    </Card>
                    <Pagination
                      total={contracts?.total}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={contractPageNo}
                      onChange={contractPageHandler}
                    />
                  </Element>
                  <Element name="Vehicles">
                    <Card className="table_card_title mt-3">
                      <div className="d-xxl-flex d-xl-block d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0 justify-content-between">
                        <div className="d-sm-flex align-items-center srch_p_resp">
                          <h4 className="mb-0 table-header r-bold">Vehicles</h4>
                          <Search
                            value={vehicleKeyword}
                            placeholder="Search"
                            titleToolTip="Search Vehicle"
                            size="small"
                            onChange={onVehicleSearch}
                          />
                        </div>
                        <Radio.Group
                          onChange={vehicleRadiobtnHandler}
                          defaultValue={sharedVehicle}
                          buttonStyle="solid"
                          className="radio_tab gray_bg w-100 border-radius mt-3 mt-sm-0 mt-xl-3 mt-xxl-0"
                        >
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value={false}>Personal</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value>Shared</Radio.Button>
                        </Radio.Group>
                      </div>
                      <Table
                        columns={vehicleColumn}
                        dataSource={vehicleTableData}
                        pagination={false}
                        size="small"
                        rowClassName="cursor-pointer"
                        className={classnames('table_wrapper ins_table_wrapper', { client_veh_p_listing: sharedVehicle === false }, { client_veh_sh_listing: sharedVehicle === true })}
                        locale={{
                          emptyText: (
                            (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="vehicles" size={105} />),
                        }}
                        onRow={(record) => ({ onClick: () => gotoVehicleDetailPage(record) })}
                      />
                    </Card>
                    <Pagination
                      total={vehicles?.total}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={vehiclePageNo}
                      onChange={vehiclePageHandler}
                    />
                  </Element>
                  <Element name="Transactions">
                    <Card className="table_card_title mt-3">
                      <div className="d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0">
                        <h4 className="mb-0 table-header r-bold">Transactions</h4>
                      </div>
                      <Table
                        columns={TransactionColumn}
                        dataSource={transactionTableData}
                        pagination={false}
                        size="small"
                        className="transaction_listing table_wrapper ins_table_wrapper"
                        scroll={{ x: '500px' }}
                        locale={{
                          emptyText: (
                            (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="transactions" size={105} />),
                        }}
                      />
                    </Card>
                    <Pagination
                      total={
                        clientTransactions?.total
                        && clientTransactions?.total[0]
                        && clientTransactions?.total[0]?.counts
}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={transactionPageNo}
                      onChange={transactionPageHandler}
                    />
                  </Element>
                </Col>
              </Row>
            </div>
          </Col>
          <Info
            setCollapsed={setCollapsed}
            infoDetail={isClientDeleted ? 'See the client details' : 'See and manage the client'}
          >
            <>
              <div className="d-none d-xl-block">
                <Section>
                  <ScrollLink to="Client Plans" text="Plans" />
                  <ScrollLink text="Vehicles" />
                  <ScrollLink text="Transactions" />
                </Section>
              </div>
              <RenderItem isTrue={(!isClientDeleted)}>
                {/* || isSuperAdmin [patch] */}
                <div className="disabled_btn">
                  <h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
                  {/* <RenderItem isTrue={isSuperAdmin}>
                    <ButtonIcon
                      onClick={showConfirmPin}
                      text={deleteSpan}
                      tooltipText="Delete Account Permanently"
                      showIcon
                      iscolor={iscolor}
                      className="d-flex ml-auto
                       mb-3 r-bold align-items-center delete-btn p-0 h-auto"
                    />
                  </RenderItem> [patch] */}
                  <RenderItem isTrue={!isClientDeleted}>
                    <ButtonIcon
                      onClick={viewDeleteConfirmModal}
                      text={deleteBtnText}
                      tooltipText={disableDeleteBtn ? 'Request for deleting the client is already made' : deleteBtnText}
                      showIcon={!disableDeleteBtn}
                      disableBtn={disableDeleteBtn}
                      iscolor={iscolor}
                      className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto"
                    />
                  </RenderItem>
                  <RenderItem isTrue={!isClientDeleted}>
                    <ButtonIcon
                      onClick={gotoVehicleCreate}
                      text="Add Vehicle"
                    />
                  </RenderItem>
                  <RenderItem isTrue={isSuperAdmin && !isClientDeleted}>
                    <ButtonIcon
                      onClick={addPlan}
                      text="Add Plan"
                    />
                  </RenderItem>
                  <RenderItem isTrue={isSuperAdmin && !client?.active && !isClientDeleted}>
                    <ButtonIcon
                      onClick={activeBtnHandler}
                      text={client?.is_invitation_send ? 'Reinvite' : 'Activate Account'}
                    />
                  </RenderItem>
                  <RenderItem isTrue={isSuperAdmin && !isClientDeleted}>
                    <ButtonIcon
                      onClick={enableBtnHandler}
                      text={`${isAccountDisabled ? 'Enable' : 'Disable'} Account`}
                    />
                  </RenderItem>
                </div>
              </RenderItem>
            </>
          </Info>
        </Row>
        <ActivateAccount
          isModalVisible={isModalVisible}
          is_invitation_send={client?.is_invitation_send as boolean}
          onOk={sendInvitation}
          onCancel={onCancel}
          email={client?.email as string}
          isLoading={isLoading}
        />
        <AddPlan
          isModalVisible={showPlanModel}
          onOk={createContract}
          onCancel={closePlanModel}
          isLoading={isLoading}
          planTypes={masterStore?.vaultContractPlans}
          facilities={facilities}
          isPlanPaid={isPlanPaid}
          isVehicleInside={isVehicleInside}
          vehicles={vehicles?.data}
          selectedPlan={selectedPlan}
          handleFacilityChange={handleFacilityChange}
          handlePlanChange={handlePlanTypeChange}
          paidCheckBoxHandler={paidCheckBoxHandler}
          vehicleCheckboxHandler={vehicleCheckboxHandler}
          terms={terms}
          form={form}
          selectedTerm={selectedTerm}
          handleTermSelection={handleTermChange}
          termAmount={termAmount}
          closedCheckBoxHandler={closedCheckBoxHandler}
          isPlanClosed={isPlanClosed}
        />
        <ConfirmAdminPin
          isModalVisible={isShowConfirmPin}
          onCancel={closeConfirmPin}
          onOk={hardDeleteClient}
          isLoading={isLoading}
          title={Constant.hardDeleteHeading}
          description={Constant.hardDeleteClientMsg}
          okButtonText="Continue"
          okButtonStyle="red_bg h-40 border-radius me-3 fs-16 r-bold white border-0"
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('clientStore', 'authStore', 'masterStore')(observer(ClientDetails));
