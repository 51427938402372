/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button, Modal } from 'antd';
import './QRCodeModal.less';
import { Icon } from '..';
import Constant from '../../Global/Constant';

const QRCode = require('qrcode.react');

interface Props {
  id: string
  isQRCodeModal: boolean
  vehicleNo: any
  closeQRCodeModal: () => void

}

function QRCodeModal(props: Props) {
  const {
    id, vehicleNo, isQRCodeModal, closeQRCodeModal,
  } = props;

  const handleDownload = () => {
    const canvas = document.getElementById('QRcode') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.download = `${vehicleNo}.png`;
    link.href = canvas.toDataURL();
    link.click();
  };
  return (
    <div>
      <Modal
        title="Vehicle QR"
        visible={isQRCodeModal}
        onCancel={closeQRCodeModal}
        footer={null}
        centered
        className="cmn_modal_wrapper"
        closeIcon={(
          <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
            <Icon className="icon-10" iconRef="#close" />
          </Button>
                      )}
      >
        <div className="d-flex flex-column align-items-center">
          <QRCode
            value={JSON.stringify({ subject: Constant?.QRcodeTypes?.VEHICLE, target: id })}
            id="QRcode"
            bgColor="#ffffff"
            fgColor="#000000"
            size={450}
            level="H"
            includeMargin
            renderAs="canvas"
            // imageSettings={{
            //   src: logo,
            //   x: null,
            //   y: null,
            //   height: 50,
            //   width: 80,
            //   excavate: true,
            // }}
          />
          <Button type="text" style={{ fontSize: '25px' }} className="h-auto" onClick={handleDownload}>Download QR</Button>
        </div>
      </Modal>
    </div>
  );
}

export default QRCodeModal;
