/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form, Input, InputNumber, Modal, Row, Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import productimage from '../../Assets/Images/product_ph.svg';
import {
  Action, ColorPicker, Icon, ImageCard, Toast, UploadDocument,
} from '../../Components';
import rules from './manageProductForm.validation';
import { Product, Variants } from '../../Global/interface';
import ConfirmModal from '../../Components/ConfirmModal';
import productStore from '../../Store/productStore';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface IVariantForm {
    product: Product
    variant?: Variants
    createVariant: (variant: Variants) => any
    updateVariant?: (variant: Variants) => any
    removeVariantList?: () => void
    duplicateVariantForm?: (data: Variants) => void
    deleteVariant?: () => void
    isLoading: boolean
    getProductById: () => void
    updateVariantEditable: (value: boolean) => void
    isVariantEditable: boolean
}

VariantForm.defaultProps = {
  variant: null,
  updateVariant: () => {},
  removeVariantList: () => {},
  duplicateVariantForm: () => {},
  deleteVariant: () => {},
};

function VariantForm(props: IVariantForm) {
  const {
    product, variant, isLoading, getProductById, updateVariantEditable,
    createVariant, removeVariantList, updateVariant, duplicateVariantForm, deleteVariant,
    isVariantEditable,
  } = props;
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedVariantImages, setSelectedVariantImages] = useState<Array<string>>([]);
  const [isImageUploadVisible, setIsImageUploadVisible] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(false);

  const showModal = () => { setIsModalVisible(true); };

  const handleCancel = () => {
    setIsModalVisible(false);
    if (variant) { setSelectedVariantImages(variant?.images); }
  };

  const handleColorChange = (code:any) => form.setFieldsValue({ color_code: code });

  const onReject = () => {
    if (!variant?._id && removeVariantList) removeVariantList();
    else {
      setFormFieldValues();
      setIsEdit(false);
      updateVariantEditable(false);
    }
  };
  const onAccept = () => {
    form.validateFields().then((values) => {
      const params: any = values;
      params.sku = `${product?.category?.code}-${values?.sku}`;
      params.images = selectedVariantImages;
      if (variant?._id && updateVariant) {
        updateVariant(params);
        setIsEdit(false);
        updateVariantEditable(false);
      } else createVariant(params);
    });
  };

  const setFormFieldValues = () => {
    if (variant) {
      const variantSku = variant?.sku?.substring(variant?.sku?.indexOf('-') + 1);
      form.setFieldsValue({
        sku: variantSku,
        color_name: variant?.color?.name,
        color_code: variant?.color?.code,
        size: variant?.size,
        price: variant?.price,
        quantity: variant?.quantity,
      });
      setSelectedVariantImages(variant?.images);
    }
  };

  useEffect(() => {
    setFormFieldValues();
  }, [variant]);

  const checkBoxHandler = (checked: any, imageUrl: string) => {
    if (checked) {
      setSelectedVariantImages((images) => [...images, imageUrl]);
    } else {
      setSelectedVariantImages(
        selectedVariantImages.filter((item: string) => item !== imageUrl),
      );
    }
  };

  const copyBtnHandler = () => {
    const data: any = {
      color: {
        name: variant?.color?.name,
        code: variant?.color?.code,
      },
      price: variant?.price,
      quantity: variant?.quantity,
      images: variant?.images,
    };
    if (duplicateVariantForm) duplicateVariantForm(data);
  };

  const viewDeleteConfirmModal = () => {
    ConfirmModal('Delete Variant', 'Do you want to delete this variant?', deleteVariant);
  };

  const disableInputFields = !!(variant?._id && !isEdit);

  const editBtnHandler = () => {
    setIsEdit(true);
    updateVariantEditable(true);
  };

  const uploadImages = async (documents: any) => {
    try {
      setIsImageUploading(true);
      const fileFormData = new FormData();
      documents.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      await productStore?.uploadImages(fileFormData, product?._id);
      getProductById();
      closeImageUploadModal();
      setIsImageUploading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const closeImageUploadModal = () => {
    setIsImageUploadVisible(false);
    setIsModalVisible(true);
  };

  const openImageUploadModal = () => {
    setIsImageUploadVisible(true);
    setIsModalVisible(false);
  };
  return (
    <div className="varient-p-list">
      <Form layout="vertical" form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Row gutter={[16, 16]}>
          <Col lg={5} md={5} sm={12} xs={24}>
            <Button className="d-flex justify-content-center align-items-center border-radius border-color h-100 off-white-bg min-ht-150 w-100 border-0 max-ht-160" onClick={showModal}>
              <img src={selectedVariantImages[0] || productimage} alt="product-img" width={selectedVariantImages?.length ? '100%' : '66px'} className="h-100 object-fit-contain" />
              {selectedVariantImages.length > 0 && <div className="icn_32 secondary position-absolute bottom-0 end-0 me-3 mb-2 gray_bg rounded-circle d-flex align-items-center justify-content-center">{selectedVariantImages.length}</div> }
            </Button>
          </Col>
          <Col xxl={15} xl={19} lg={19} md={19} sm={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col lg={8} md={8} sm={8} xs={24}>
                <ColorPicker
                  key={variant?._id}
                  onSelect={handleColorChange}
                  defaultColor={variant?.color?.code || ''}
                  placeholder="Pick the color"
                  colorRules={rules.color_code}
                  inputRules={rules.color_name}
                  disabled={disableInputFields}
                  pickerName="color_code"
                  label="COLOR"
                />
              </Col>
              <Col lg={8} md={8} sm={8} xs={24}>
                <Form.Item normalize={Utility.trimSpacesInValues} label="size" className="mandatory mb-0" name="size" rules={rules.variant_size}>
                  <Input placeholder="Enter size" className="inp_style" maxLength={30} disabled={disableInputFields} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={8} xs={24}>
                <Form.Item label="PRICE" name="price" className="mandatory mb-0" required={false} rules={rules.product_price}>
                  <InputNumber placeholder="Enter price" disabled={disableInputFields} className="inp_style w-100 inp_num_style pe-5 shadow-none" maxLength={10} max={9999999999} min={0} />
                </Form.Item>
                <span className="position-absolute end-0 top-41 inactive me-4 pe-2 d-flex align-items-center">KD</span>
              </Col>
              <Col lg={8} md={8} sm={8} xs={24}>
                <Form.Item label="sku" className="mandatory mb-0" normalize={Utility.trimSpacesInValues} name="sku" rules={rules.code}>
                  <Input disabled={disableInputFields} addonBefore={`${product?.category?.code}-`} className="inp_style overflow-hidden sku_style" maxLength={10} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={8} xs={24}>
                <Form.Item label="quantity" className="mandatory mb-0" name="quantity" rules={rules.quantity}>
                  <InputNumber
                    disabled={disableInputFields}
                    placeholder="Enter quantity"
                    className="inp_style w-100 inp_num_style pe-5 shadow-none"
                    maxLength={10}
                    min={0}
                    max={Constant.variantQuantityMax}
                    precision={0}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} sm={8} xs={24} className="d-flex align-items-end justify-content-start justify-content-sm-end">
                <Form.Item className="mb-0">
                  {(!variant?._id || isEdit) && (
                  <Action
                    onAccept={onAccept}
                    onReject={onReject}
                    acceptToolTipText="Save"
                    rejectToolTipText="Cancel"
                    disabled={isLoading}
                  />
                  )}
                  {(variant?._id && !isEdit) && (
                  <div className="d-flex">
                    <div title="Edit">
                      <Button disabled={isVariantEditable} onClick={editBtnHandler} className="size-40 p-0 border-radius secondary off-white-bg d-flex align-items-center justify-content-center border-0 me-2">
                        <Icon className="icon-16" iconRef="#edit" />
                      </Button>
                    </div>
                    <div title="Duplicate">
                      <Button disabled={isVariantEditable} onClick={copyBtnHandler} className="size-40 ms-1 p-0 border-radius secondary off-white-bg d-flex align-items-center justify-content-center border-0 me-2">
                        <Icon className="icon-16" iconRef="#copy" />
                      </Button>
                    </div>
                    <div title="Delete">
                      <Button disabled={isVariantEditable} onClick={viewDeleteConfirmModal} className="item_delete p-0 border-radius red d-flex align-items-center justify-content-center border-0 ms-1">
                        <Icon className="icon-16" iconRef="#delete" />
                      </Button>
                    </div>
                  </div>
                  )}

                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <UploadDocument
          isModalVisible={isImageUploadVisible}
          handleModelCancel={closeImageUploadModal}
          uploadDocuments={uploadImages}
          uploadedDocuments={product?.images}
          isLoading={isImageUploading}
          acceptedExtension=".jpeg,.png"
        />
        <Modal
          title={disableInputFields ? 'Variant Images' : 'Choose Variant Images'}
          visible={isModalVisible}
          destroyOnClose
          onCancel={handleCancel}
          footer={null}
          className="cmn_modal_wrapper"
          width={800}
          centered
          closeIcon={(
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
              <Icon className="icon-10" iconRef="#close" />
            </Button>
                      )}
        >
          <div className="off-white-bg border-radius p-2">
            <div className="d-flex flex-wrap w-100 p_img_wrapper_ht">
              {product?.images.map((image: any) => (
                <ImageCard
                  image={image}
                  paddingLeft={0}
                  paddingRight={0}
                  showCheckBox
                  disabled={disableInputFields}
                  showDeleteButton={false}
                  defaultChecked={selectedVariantImages?.includes(image?.fileUrl)}
                  checkBoxHandler={(
                    event: any,
                  ) => checkBoxHandler(event?.target?.checked, image?.fileUrl)}
                />
              ))}
              {!product?.images.length && <div>Add your product images</div>}
            </div>
          </div>
          {!disableInputFields
          && (
          <div className="d-flex justify-content-between align-items-center mt-4">
            <Button htmlType="submit" block type="primary" className="main_btn w-auto border-0 r-bold fs-16" onClick={() => setIsModalVisible(false)}>
              Confirm
            </Button>
            <Button
              type="text"
              className="bg-transparent h-40 fs-16 r-bold"
              onClick={openImageUploadModal}
            >
              Upload Image
            </Button>
          </div>
          )}
        </Modal>
      </Form>
    </div>
  );
}

export default VariantForm;
