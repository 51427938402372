/* eslint-disable react/no-danger */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button, Modal,
} from 'antd';
import classNames from 'classnames';
import { Icon } from '..';

interface IPreviewButton{
  visibility?: boolean
  showPreview?: boolean
  disablePublishButton?: boolean
  viewPublishConfirnModal: () => void
  publishButtonText: string
  buttonColor: string
  description?: any
}

PreviewButton.defaultProps = {
  visibility: false,
  showPreview: false,
  disablePublishButton: false,
  description: null,
};

function PreviewButton(props: IPreviewButton) {
  const {
    visibility, showPreview, viewPublishConfirnModal, publishButtonText, buttonColor,
    disablePublishButton, description,
  } = props;
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  return (
    <div className="d-sm-flex">
      { showPreview && (
        <Button className="gray_bg border-radius border-0 primary me-3 fs-16 r-bold h-40 mt-3 mt-sm-0" onClick={() => setShowPreviewModal(true)}>
          <Icon className="icon-18 me-2" iconRef="#eye" />Preview
        </Button>
      ) }
      {visibility
        ? (
          <Button type="text" disabled={disablePublishButton} className={classNames('text-white border-radius fs-16 r-bold h-40 mt-3 mt-sm-0', { yellow_bg: buttonColor === 'yellow' }, { green_bg: buttonColor === 'green' })} onClick={viewPublishConfirnModal}>
            {publishButtonText}
          </Button>
        ) : <></>}
      <Modal
        title="Preview"
        visible={showPreviewModal}
        onCancel={() => setShowPreviewModal(false)}
        destroyOnClose
        footer={null}
        centered
        width={812}
        className="cmn_modal_wrapper"
        bodyStyle={{ height: 500, overflowY: 'auto', marginTop: 16 }}
        closeIcon={(
          <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
            <Icon className="icon-10" iconRef="#close" />
          </Button>
                      )}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Modal>
    </div>
  );
}

export default PreviewButton;
