/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import {
  Col, Divider, Row, Typography,
} from 'antd';
import React from 'react';
import { RenderItem, StartCancelRequest } from '..';
import Utility from '../../Global/Utility';

interface IServiceReqDateStatus{
    isRequestStarted: boolean,
     updateRequest: (data: any) => any,
     isLoading: boolean,
     scheduled_time: number,
     children?: React.ReactNode
     statusText?: string
}

ServiceReqDateStatus.defaultProps = {
  statusText: 'In Progress',
  children: <></>,
};

function ServiceReqDateStatus(props: IServiceReqDateStatus) {
  const {
    isRequestStarted, updateRequest, isLoading, scheduled_time, statusText, children,
  } = props;
  return (
    <Col lg={15} md={15} sm={15} xs={24}>
      <Row className="justify-content-between" gutter={[16, 16]}>
        <Col
          xxl={isRequestStarted ? 11 : 15}
          xl={isRequestStarted ? 24 : 15}
          lg={isRequestStarted ? 24 : 15}
          md={isRequestStarted ? 24 : 15}
          sm={isRequestStarted ? 24 : 15}
          xs={24}
        >
          <RenderItem isTrue={!!scheduled_time}>
            <div className="d-flex justify-content-between justify-content-sm-start mt-3 mt-sm-0 mb-3 mb-sm-0">
              <div className="me-3 r-bold fs-16 lh-sm">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Date</Typography.Text><br />
                {Utility?.getFormattedDate(scheduled_time)}
              </div>
              <div className="r-bold fs-16 lh-sm">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Time</Typography.Text><br />
                {Utility?.getFormattedTime(scheduled_time)}
              </div>
            </div>
          </RenderItem>
        </Col>
        <RenderItem isTrue={isRequestStarted}>
          <Col xxl={13} xl={24} lg={24} md={24} sm={24} xs={24} className="d-flex align-items-start">
            <StartCancelRequest
              updateRequest={updateRequest}
              isLoading={!!isLoading}
            />
          </Col>
        </RenderItem>
        <RenderItem isTrue={!isRequestStarted}>
          <Col lg={9} md={9} sm={9} xs={24} className="d-flex justify-content-sm-end align-items-start text-truncate">
            <div className="label_warning rounded-pill pt-1 pb-1">{statusText}</div>
          </Col>
        </RenderItem>
      </Row>
      <Divider className="mt-3 mb-3 border-bottom border-xs-bottom" />
      {children}
    </Col>
  );
}

export default ServiceReqDateStatus;
