/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Button } from 'antd';
import { Address } from '../../Global/interface';
import { Icon } from '../../Components';

interface AddressSelectionProps{
    localIndex: number,
    index: number,
    address: Address | undefined
    chooseAddress: (address: Address | undefined, index: number) => any
}

function AddressSelection(props: AddressSelectionProps) {
  const {
    localIndex, index, address, chooseAddress,
  } = props;

  return (
    <>
      {index !== localIndex
    && (
    <Button className="d-flex ml-auto mt-3 r-bold align-items-center justify-content-end primary w-100 p-0" type="link" onClick={() => chooseAddress(address, localIndex)}>
      Set As default
      <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
        <Icon className="icon-8" iconRef="#arrow_right" />
      </div>
    </Button>
    )}
      {index === localIndex
    && (
    <div className="d-flex justify-content-between align-items-center mt-3">
      <div className="off-white-bg secondary rounded-pill px-2 py-1 d-flex align-items-center justify-content-center fs-16">
        Default
      </div>
    </div>
    )}
    </>
  );
}

export default AddressSelection;
