/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import ServiceAttachment from './ServiceAttachment';
import ServiceFuelFill from './ServiceFuelFill';
import ServiceMileage from './ServiceMileage';
import ServiceSlider from './ServiceSlider';
import ServiceTirePressure from './ServiceTirePressure';
import ServiceWash from './ServiceWash';

interface ServiceCheckListProps {
  service: string,
  isInfoRequired: any,
  requestInfo: any,
  started: boolean,
  saved: boolean,
  checkboxValue: boolean,
  checkboxHandler: (value: any, service: string) => void,
  completed: boolean,
  savedValue: any,
  completeService: (value: any, service: string) => void
  updateRequestInfo: (value: any, service: string) => void
  vehicleStatus: any
  visitStatus: string
  wheels?: number
}

ServiceCheckList.defaultProps = {
  wheels: 0,
};

function ServiceCheckList(props: ServiceCheckListProps) {
  const {
    service,
    requestInfo,
    isInfoRequired,
    started,
    saved,
    checkboxValue,
    completed,
    savedValue,
    checkboxHandler,
    completeService,
    updateRequestInfo,
    vehicleStatus,
    visitStatus,
    wheels,
  } = props;

  switch (service) {
    case 'FUEL_FILL': return (
      <ServiceFuelFill
        completed={completed}
        checkboxValue={checkboxValue}
        isInfoRequired={isInfoRequired}
        requestInfo={requestInfo}
        started={started}
        service={service}
        savedValue={savedValue}
        saved={saved}
        checkboxHandler={checkboxHandler}
        completeService={completeService}
        updateRequestInfo={updateRequestInfo}
        vehicleStatus={vehicleStatus}
        visitStatus={visitStatus}

      />
    );
    case 'MILEAGE_CHECK': return (
      <ServiceMileage
        completed={completed}
        checkboxValue={checkboxValue}
        isInfoRequired={isInfoRequired}
        requestInfo={requestInfo}
        started={started}
        service={service}
        savedValue={savedValue}
        saved={saved}
        checkboxHandler={checkboxHandler}
        completeService={completeService}
        vehicleStatus={vehicleStatus}
        visitStatus={visitStatus}
      />
    );
    case 'FLAT_STOPPERS':
    case 'COVER':
    case 'BATTERY_TENDER':
      return (
        <ServiceAttachment
          completed={completed}
          checkboxValue={checkboxValue}
          isInfoRequired={isInfoRequired}
          requestInfo={requestInfo}
          started={started}
          service={service}
          savedValue={savedValue}
          saved={saved}
          checkboxHandler={checkboxHandler}
          completeService={completeService}
          vehicleStatus={vehicleStatus}
          visitStatus={visitStatus}
        />
      );
    case 'TIRE_PRESSURE_CHECK': return (
      <ServiceTirePressure
        completed={completed}
        checkboxValue={checkboxValue}
        isInfoRequired={isInfoRequired}
        requestInfo={requestInfo}
        started={started}
        service={service}
        savedValue={savedValue}
        saved={saved}
        checkboxHandler={checkboxHandler}
        completeService={completeService}
        updateRequestInfo={updateRequestInfo}
        vehicleStatus={vehicleStatus}
        visitStatus={visitStatus}
        wheels={wheels}
      />
    );
    case 'ENGINE':
    case 'FLUID':
    case 'WINDSHIELD':
      return (
        <ServiceSlider
          completed={completed}
          checkboxValue={checkboxValue}
          isInfoRequired={isInfoRequired}
          requestInfo={requestInfo}
          started={started}
          service={service}
          savedValue={savedValue}
          saved={saved}
          checkboxHandler={checkboxHandler}
          completeService={completeService}
          vehicleStatus={vehicleStatus}
          visitStatus={visitStatus}
        />
      );
    case 'STANDARD_WASH':
    case 'DETAIL_WASH':
      return (
        <ServiceWash
          completed={completed}
          checkboxValue={checkboxValue}
          isInfoRequired={isInfoRequired}
          requestInfo={requestInfo}
          started={started}
          service={service}
          savedValue={savedValue}
          saved={saved}
          checkboxHandler={checkboxHandler}
          completeService={completeService}
          vehicleStatus={vehicleStatus}
          visitStatus={visitStatus}
        />
      );
    default: return <div />;
  }
}

export default ServiceCheckList;
