/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable */
import React from 'react';
import { Breadcrumb, Button } from 'antd';

function BreadCrumb(props: any) {
    const { itemsArray, handleOnClick,  } = props;

    const handleBreadcrumbClick = (id: any, type: string, index: number ) => {
        if(index !== (itemsArray?.length - 1)) {
            handleOnClick(id, type)
        }
    } 

    return (
        <Breadcrumb className="text-uppercase secondary gray_bg px-4 py-3 vehicle_breadcrumb">
            {itemsArray?.map((item: any, index: number) => {
                return <Breadcrumb.Item>
                    <Button type='text' onClick={() => handleBreadcrumbClick(item?.id, item?.type, index)}>{item?.title}</Button>
                </Breadcrumb.Item>
            })}
        </Breadcrumb>
    );
}

export default BreadCrumb;
