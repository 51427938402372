/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import api from './api';

class FileService {
  uploadFile = async (formData : any) => {
    try {
      const response = await api.post('/files', formData);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
export default new FileService();
