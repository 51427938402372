/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  vehicle_category: [{
    required: true,
    message: Constant.vehicleCategoryRequired,
  }],
  model: [{
    required: true,
    message: Constant.vehicleModelRequired,
  }],
  make: [{
    required: true,
    message: Constant.vehicleMakeRequired,
  }],
  sub_model: [{
    required: true,
    message: Constant.vehicleSubModelRequired,
  }],
  year: [{
    required: true,
    message: Constant.vehicleYearRequired,
  }],
  country: [{
    required: true,
    message: Constant.countryRequired,
  }],
  state: [{
    required: true,
    message: Constant.stateRequired,
  }],
  city: [{
    required: true,
    message: Constant.cityRequired,
  }],
  addressLine1: [{
    type: 'string',
    pattern: /([A-Za-z])/,
    message: Constant.invalidAddressLine1,
  }],
  addressLine2: [{
    type: 'string',
    pattern: /([A-Za-z])/,
    message: Constant.invalidAddressLine2,
  }],
  zip_code: [{
    pattern: /^[0-9]{4,}$/,
    message: Constant.zipMinimumError,
  }],
  color: [{
    required: true,
    message: Constant.vehicleColorRequired,
  }],
  color_name: [{
    required: true,
    message: Constant.vehicleColorNameRequired,
  }],
};

export default rule;
