/* eslint-disable import/no-unresolved */
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '..';

interface ILinkButton{
    route: string
    text: string
    className?: string
    leftArrow?: boolean
    leftClassName?: string
    tooltipText?: string
}

LinkButton.defaultProps = {
  className: 'd-flex ml-auto mb-3 r-bold align-items-center p-0 link_text',
  leftArrow: false,
  leftClassName: 'd-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary',
  tooltipText: '',
};

function LinkButton(props: ILinkButton) {
  const {
    route, text, className, leftArrow, leftClassName, tooltipText,
  } = props;

  if (leftArrow) {
    return (
      <Tooltip title={tooltipText || text}>
        <Link className={leftClassName} to={route}>
          <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_left" />
          </div>
          {text}
        </Link>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipText || text}>
      <Link to={route} className={className}>
        {text}
        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
          <Icon className="icon-8" iconRef="#arrow_right" />
        </div>
      </Link>
    </Tooltip>

  );
}

export default LinkButton;
