/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { Icon } from '../../Components';
import modelOthers from '../../Assets/Images/modelOthers.png';

export interface InfoCardProps {
  images: Array<any>,
  emptyImageText: string,
  removeImageFromList: (index: number) => void;
}
function VehicleImageDisplay(props: InfoCardProps) {
  const { images, emptyImageText, removeImageFromList } = props;
  return (
    <div className="d-flex flex-wrap">
      {images?.length
        ? images?.map((image: any, index: number) => (
          <div className="off-white-bg border-radius ps-3 pe-3 park_unpark_up_image d-flex align-items-center position-relative me-2 mb-2">
            <img src={image?.fileUrl} className="img-fluid" alt="vehicle" />
            <div className="position-absolute cursor-pointer red_bg text-white rounded-circle icon-24 up_img_close">
              <Button onClick={() => { removeImageFromList(index); }}>
                <Icon className="icon-8" iconRef="#close" />
              </Button>
            </div>
          </div>
        ))
        : <div className="d-flex align-items-center justify-content-center m-auto mt-4 flex-column"><img src={modelOthers} alt="vehicle" /><br /><span className="mt-3">{emptyImageText}</span></div>}
    </div>
  );
}
export default observer(VehicleImageDisplay);
