/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button, Card, Steps, Tooltip, Typography,
} from 'antd';
import classNames from 'classnames';

import { ConfirmModal } from '../../Components';
import RejectModal from '../OrderReturnDetails/RejectModal';

export interface ReturnStepsProps {
  currentDot: number;
  payment_status: boolean
  isRefundDisabled: boolean
  handleRefund: () => any
  updateReturnStatus: (action: string, actionDetail?: any) => Promise<void>,
  handleRejectSubmit: (images: any, comment: string) => void
}
const { Step } = Steps;

function ReturnSteps(props: ReturnStepsProps) {
  const {
    currentDot, payment_status, isRefundDisabled, handleRefund,
    updateReturnStatus, handleRejectSubmit,
  } = props;

  const [isRejectModalVisible, setIsRejectModalVisible] = useState<boolean>(false);

  const isVerifyStep = currentDot === 0;
  const isRefundStep = currentDot >= 1;

  const handleProductReject = () => setIsRejectModalVisible(true);
  const handleProductRejectCancel = () => setIsRejectModalVisible(false);

  const handleReject = (images: any, comment: string) => {
    handleRejectSubmit(images, comment);
    setIsRejectModalVisible(false);
  };

  const handleCollect = () => {
    ConfirmModal('Collect', 'Do you want to collect this product?', () => updateReturnStatus('product_approved'), 'Collect',
      'Black');
  };

  return (
    <Card className="mt-3">
      <Typography.Title level={3} className="mb-4">
        Return Order
      </Typography.Title>
      <Steps
        progressDot
        current={currentDot}
        direction="vertical"
        className="custom_steps"
      >
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('primary r-bold mb-0', {
                disable_clr: currentDot <= 2,
              })}
            >
              Verify product
            </Typography.Title>
          )}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph
                  className={classNames('secondary fs-16 m-0', {
                    disable_clr: currentDot <= 2,
                  })}
                >
                  Check the product condition for pickup
                </Typography.Paragraph>
                {isVerifyStep && (
                  <div>
                    <Button onClick={handleProductReject} className="border-0 shadow-none border-radius secondary r-bold bg-transparent h-40 me-2">
                      Reject
                    </Button>
                    <Button onClick={handleCollect} className="border-0 shadow-none border-radius text-white r-bold green_bg h-40">
                      Collect
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        />
        <Step
          title={(
            <Typography.Title
              level={5}
              className={classNames('r-bold mb-0', {
                disable_clr: currentDot <= 3,
              })}
            >
              Refund
            </Typography.Title>
          )}
          description={(
            <div>
              <div className="d-sm-flex align-items-center justify-content-between">
                <Typography.Paragraph
                  className={classNames('fs-16 m-0', {
                    disable_clr: currentDot <= 3,
                  })}
                >
                  Refund the order
                </Typography.Paragraph>
                <div>
                  {isRefundStep && payment_status && (
                    <>
                      <Tooltip title={isRefundDisabled ? 'Refund already initiated' : 'Refund'}>
                        <Button disabled={isRefundDisabled} onClick={handleRefund} className="border-0 shadow-none border-radius text-white r-bold primary_bg h-40 me-2">
                          Refund
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </Steps>
      <RejectModal
        handleCancel={handleProductRejectCancel}
        isRejectModalVisible={isRejectModalVisible}
        handleRejectSubmit={handleReject}
      />
    </Card>
  );
}

export default ReturnSteps;
