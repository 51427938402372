/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Typography,
  Upload,
  Input,
} from 'antd';

import {
  Icon, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';

const { TextArea } = Input;

interface IRejectModal {
    isRejectModalVisible: boolean
    handleRejectSubmit: (images: any, comment: string) => any
    handleCancel: () => any
}

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function RejectModal(props: IRejectModal) {
  const { isRejectModalVisible, handleCancel, handleRejectSubmit } = props;
  const [selectedImage, setSelectedImages] = useState<Array<any>>([]);
  const [base64Images, setBase64Images] = useState<Array<any>>([]);

  const [textInput, setTextInput] = useState('');

  const handleModalCancel = () => {
    setBase64Images([]);
    setSelectedImages([]);
    handleCancel();
  };

  const acceptedExtension = '.jpeg,.png,.jpg';
  const customRequest = () => false;

  const imageSelectHandle = async (fileInfo: any) => {
    const fileExt = fileInfo?.file?.name?.split('.').pop();
    if (!acceptedExtension?.includes(fileExt.toLowerCase())) {
      Toast.error(`Only files with following extensions are allowed: ${acceptedExtension}.`);
      return;
    }
    if ((fileInfo?.file?.size / 1024 / 1024 > Constant.maximumFileSize)) {
      Toast.error(`File size exceeded the limit: ${Constant.maximumFileSize} MB.`);
      return;
    }

    const selectedFile = fileInfo?.fileList[fileInfo?.fileList?.length - 1];
    const selectedFileNames = selectedImage?.map((file:any) => file?.name);
    if (selectedFileNames?.includes(selectedFile?.name)) {
      Toast.error(Constant?.fileExistError);
      return;
    }

    const covertedImage: any = await getBase64(fileInfo?.file?.originFileObj);
    setSelectedImages(fileInfo?.fileList);
    setBase64Images([...base64Images, covertedImage]);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(event?.target?.value);
  };

  const removeImage = (index: number) => {
    setBase64Images(
      base64Images.filter((value : any, actualIndex:number) => actualIndex !== index),
    );
    setSelectedImages(
      selectedImage?.filter((value : any, actualIndex:number) => actualIndex !== index),
    );
  };

  const handleSubmit = () => {
    if (!textInput && !selectedImage?.length) return Toast.error('Please give a reason for rejection or upload an image');

    if (textInput && !(/^[a-zA-Z0-9 ]*[a-zA-Z0-9 @&()?,.'"!?#$:/`-]*$/.test(textInput))) {
      return Toast.error('Special characters are not allowed.');
    }
    handleRejectSubmit(selectedImage, textInput);
    return '';
  };

  return (
    <Modal
      title="Reject Refund"
      visible={isRejectModalVisible}
      onCancel={handleModalCancel}
      footer={null}
      className="cmn_modal_wrapper"
      width={400}
      centered
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                    )}
    >
      <Typography.Paragraph className="secondary fs-16">Are you sure want to reject this refund?</Typography.Paragraph>
      <div className="d-flex flex-wrap mb-3">
        <Upload
          className="custom_upload size-82 d-flex align-items-center justify-content-center me-2 mb-2"
          showUploadList={false}
          accept={acceptedExtension}
          customRequest={customRequest}
          onChange={imageSelectHandle}
          disabled={selectedImage?.length > 5}
        >
          <Icon className="icon-24 inactive" iconRef="#camera" />
        </Upload>
        { base64Images?.length > 0
        && base64Images.map((image: any, index: number) => (
          <div className="size-82 off-white-bg position-relative border-radius d-flex align-items-center justify-content-center p-3 me-2 mb-2">
            <Button onClick={() => removeImage(index)} className="gray_bg primary d-flex justify-content-center align-items-center text-center p-0 position-absolute refund_img_close" type="link" shape="circle">
              <Icon className="icon-8" iconRef="#close" />
            </Button>
            <img src={image} alt="product" className="w-100" />
          </div>
        )) }
      </div>
      <Typography.Text className="r-medium secondary mb-2 d-block">REASON FOR REJECTION</Typography.Text>
      <TextArea autoSize={{ minRows: 4, maxRows: 6 }} onChange={onChangeHandler} className="inp_style" />
      <div className="d-flex justify-content-between align-items-center mt-4">
        <Button onClick={handleSubmit} htmlType="submit" block type="primary" className="red_bg text-white h-40 w-auto border-0 border-radius r-bold fs-16">
          Reject
        </Button>
      </div>
    </Modal>
  );
}

export default RejectModal;
