/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Card, Divider, Typography,
} from 'antd';
import React from 'react';
import classnames from 'classnames';
import { ServicesListing } from '..';

interface PlanCardProps {
  planType: string,
  planName: string,
  planId: string,
  servicesList: Array<any>
  gotoPlanDetails: (id: string) => any,
}

function PlanCard(props: PlanCardProps) {
  const {
    planType, planName, planId, gotoPlanDetails, servicesList,
  } = props;

  const gotoDetails = () => gotoPlanDetails(planId);

  return (
    <Card className="plan_listing_card cursor-pointer" bodyStyle={{ padding: 0 }} onClick={gotoDetails}>
      <div className="p-3">
        <div className={classnames('skew-transform text-uppercase w-max-content px-1 fs-12 mb-2', { plan_label_green: planType === 'RESIDENTIAL', plan_label_yellow: planType === 'VAULT' })}>
          {planType === 'RESIDENTIAL'
          && <Typography.Text className="white r-italic">home service</Typography.Text>}
          {planType === 'VAULT'
          && <Typography.Text className="white r-italic">parking service</Typography.Text>}
        </div>
        <Typography.Title level={3} className="mb-0 text-truncate text-capitalize">{planName}</Typography.Title>
      </div>
      <Divider className="dashed-border m-0" />
      <ServicesListing serviceList={servicesList} />
    </Card>
  );
}

export default PlanCard;
